import React, { useEffect, useState } from 'react';
import { Col, Row,Modal } from 'react-bootstrap';
import { GET_SIZING } from '../../constants/constants';
import { useHistory } from 'react-router-dom';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CustomDropdownMySizings } from '../../components/CustomDropdown/CustomDropdownMySizings';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import { CustomInputFieldNavPages } from '../../components/CustomInputField/CustomInputFieldNavPages';
import MySizingsSearchResults from './MySizingsSearchResults';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import axios from 'axios';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';
import { POST_SAVED_SIZINGS_FILTERED } from '../../constants/constants';
import { connect } from 'react-redux';
import CustomTranslationText from '../../components/CustomTranslation/CustomTranslationText';
import {
  fluidState,
  clearMySizings,
  advancedLayout,
  mySizingResults,
  errorMessage,
  getSizingData,
  getSizingFormattedData,
  toggleFlow,
  toggleDensity,
  toggleViscosity,
  selectTechnology,
  defaultTechnology,
  selectBasicPage,
  allowPrefBaseRef, 
  compositionMethodFlag,
  savedPreferencesFlag
} from '../../redux/actions';
import ErrorModal from '../../components/CustomModals/ErrorModal';



const MySizings = React.memo((props) => {
  let history = useHistory();
  props.allowPrefBaseRef(false)
  props.compositionMethodFlag(true)
  const dateFormat = 'MM/dd/yyyy'; // 'dd/MM/yyyy';
  const [mySizingsTimeframe, setMySizingsTimeframe] = useState(CustomTranslationText('XXSPECIFY_MMI_ALL', props),);
  const [mySizingsFilter, setMySizingsFilter] = useState('CREATED DATE');
  const [mySizingsSearchBy, setMySizingsSearchBy] = useState('PA REFERENCE ID');
  const [mySizingsSearchFilter, setMySizingsSearchFilter] = useState('IS');
  const [textFieldValue, setTextFieldValue] = useState('');
  const [transaction_id, setTransactionId] = useState('');
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [searchSizings, setSearchSizings] = useState(false);
  const [clearMySizingsTable, setClearMySizingsTable] = useState(false);
  const [getSizingRowInfo, setGetSizingRowInfo] = useState('');
  const sizingsValues = [
    CustomTranslationText('XXSPECIFY_MMI_ALL', props),
    CustomTranslationText('XXSPECIFY_MMI_7_DAYS', props),
    CustomTranslationText('XXSPECIFY_MMI_14_DAYS', props),
    CustomTranslationText('XXSPECIFY_MMI_30_DAYS', props),
    CustomTranslationText('XXSPECIFY_MMI_60_DAYS', props),
    CustomTranslationText('XXSPECIFY_MMI_90_DAYS', props),
  ];
  const filterBy = [
    CustomTranslationText('XXSPECIFY_MMI_PR_CRTD_DATE', props),
    CustomTranslationText('XXSPECIFY_MMI_PR_UPDATED_DATE', props)
  ];
  const searchBy = [
    CustomTranslationText('XXSPECIFY_MMI_PA_REF_ID', props),
    CustomTranslationText('XXSPECIFY_MMI_LBL_SIZING_NAME', props),
    props.email!==''?props.email.search(new RegExp('Emerson', "i"))!== -1?CustomTranslationText('XXSPECIFY_MMI_LBL_CREATED_BY', props).toUpperCase():'':''
  ];
  const searchFilter = [
    CustomTranslationText('XXSPECIFY_MMI_IS', props), 
    CustomTranslationText('XXSPECIFY_MMI_CONTAINS', props),
    CustomTranslationText('XXSPECIFY_MMI_STARTS_WITH', props), 
    CustomTranslationText('XXSPECIFY_MMI_IS_NOT', props), 
    CustomTranslationText('XXSPECIFY_MMI_ENDS_WITH', props), 
    ];
  const handleMySizingsTimeframe = (key) =>
    setMySizingsTimeframe(sizingsValues[key ? key : 0]);

  const handleMySizingsFilter = (key) =>
    setMySizingsFilter(filterBy[key ? key : 0]);

  const handleMySizingsSearchBy = (key) =>
    setMySizingsSearchBy(searchBy[key ? key : 0]);

  const handleMySizingsSearchFilter = (key) =>
    setMySizingsSearchFilter(filterInputValue(searchFilter[key ? key : 0]));

  const handleTextField = (key) => setTextFieldValue(key);

  const { clear_my_sizings, clearMySizings } = props;

  const handleErrorClose = () => props.errorMessage(false);

  const handleErrorShow = () => props.error_message;

  const filterInputValue = (input, type) => {
    switch (type) {
      case 'timeFrame': {
        let [first, ...second] = input.split(' ');
        return input === CustomTranslationText('XXSPECIFY_MMI_ALL', props) ? CustomTranslationText('XXSPECIFY_MMI_ALL', props) : first;
      }
      case 'dateFilter':
        return input ===  CustomTranslationText('XXSPECIFY_MMI_PR_CRTD_DATE', props).toUpperCase()? 'created' : 'updated';
      case 'searchBy':
        return input === CustomTranslationText('XXSPECIFY_MMI_PA_REF_ID', props).toUpperCase()
          ? CustomTranslationText('XXSPECIFY_MMI_PA_REF_ID', props).toUpperCase()
          :  input===   CustomTranslationText('XXSPECIFY_MMI_LBL_SIZING_NAME', props).toUpperCase()?
          'APPLICATION_NAME':
          CustomTranslationText('XXSPECIFY_MMI_LBL_CREATED_BY', props).toUpperCase();
      case 'searchFilter': {
        if (input === CustomTranslationText('XXSPECIFY_MMI_IS', props)) {
          return CustomTranslationText('XXSPECIFY_MMI_IS', props);
        } else if (input === CustomTranslationText('XXSPECIFY_MMI_CONTAINS', props).toUpperCase()) {
          return CustomTranslationText('XXSPECIFY_MMI_CONTAINS', props).toUpperCase();
        } else if (input === CustomTranslationText('XXSPECIFY_MMI_STARTS_WITH', props).toUpperCase()) {
          return CustomTranslationText('XXSPECIFY_MMI_STARTS_WITH', props);
        } else if (input ===  CustomTranslationText('XXSPECIFY_MMI_IS_NOT', props).toUpperCase()) {
          return CustomTranslationText('XXSPECIFY_MMI_IS_NOT', props);
        } else if (input === CustomTranslationText('XXSPECIFY_MMI_ENDS_WITH', props).toUpperCase()) {
          return CustomTranslationText('XXSPECIFY_MMI_ENDS_WITH', props);
        } else {
          return '';
        }
      }
      default:
        return input;
    }
  };
  const handleClose = (data) => {setShow(false)
    if(data==='edit'){
     setGetSizingRowInfo({transaction_id:transaction_id})
     
    }
    else{
      setTransactionId('')
    }
    };
  useEffect(() => {
    props.savedPreferencesFlag(false)
    if (searchSizings) {
      const data = JSON.stringify({
        email_id:props.email,//'shawn.eason@emerson.com', //<-- for testing,
        age_days: filterInputValue(mySizingsTimeframe, 'timeFrame'),
        filter_type: filterInputValue(mySizingsFilter, 'dateFilter'),
        begin_date: startDate
          ? [
              `${startDate.getMonth() + 1}`.padStart(2, '0'),
              `${startDate.getDate()}`.padStart(2, '0'),
              startDate.getFullYear(),
            ].join('/')
          : '',
        end_date: endDate
          ? [
              `${endDate.getMonth() + 1}`.padStart(2, '0'),
              `${endDate.getDate()}`.padStart(2, '0'),
              endDate.getFullYear(),
            ].join('/')
          : '',
        search_type: textFieldValue
          ? filterInputValue(mySizingsSearchBy, 'searchBy')===CustomTranslationText('XXSPECIFY_MMI_LBL_CREATED_BY', props).toUpperCase()?
          'CREATED_BY':filterInputValue(mySizingsSearchBy, 'searchBy')
          : '',
        created_by:mySizingsSearchBy===CustomTranslationText('XXSPECIFY_MMI_LBL_CREATED_BY', props).toUpperCase()?textFieldValue:'',
        search_operator: textFieldValue
          ? filterInputValue(mySizingsSearchFilter, 'searchFilter')
          : '',
        search_value: textFieldValue,
      });

      setClearMySizingsTable(false);

      const fetchData = async () => {
        await axios({
          method: 'post',
          url: `${process.env.REACT_APP_BASE_URL}${POST_SAVED_SIZINGS_FILTERED}`,
          headers: {
            'Content-Type': 'application/json',
          },
          xsrfHeaderName: 'X-CSRFToken',
          xsrfCookieName: 'csrftoken',
          data: data,
        })
          .then(function (response) {
            props.mySizingResults(response.data.data);
          })
          .catch(function (error) {
            console.log('error', error);
          });
      };
      fetchData();
    }
    setSearchSizings(false);
  }, [searchSizings]);

  useEffect(() => {
    if (clear_my_sizings) {
      setMySizingsTimeframe(sizingsValues[0]);
      setMySizingsFilter(filterBy[0]);
      setMySizingsSearchBy(searchBy[0]);
      setMySizingsSearchFilter(filterInputValue(searchFilter[0]));
      setStartDate(undefined);
      setEndDate(undefined);
      setTextFieldValue('');
      setClearMySizingsTable(true);
      clearMySizings(false);
    }
  }, [clear_my_sizings, clearMySizings]);

  useEffect(() => {
    if (getSizingRowInfo !== '') {
      props.getSizingData([]);
      const fetchData = async () => {
        await axios({
          method: 'post',
          url: `${process.env.REACT_APP_BASE_URL}${GET_SIZING}`,
          headers: {
            'Content-Type': 'application/json',
          },
          xsrfHeaderName: 'X-CSRFToken',
          xsrfCookieName: 'csrftoken',
          data: getSizingRowInfo,
        }).then(async function (response) {
            await props.advancedLayout(true);
            await history.push('/advanced');
            await props.getSizingData(response.data.sizing_details);

        })
          .catch(function (error) {
            console.log('error', error);
          });
      };
      fetchData();
    }
  }, [getSizingRowInfo]);

 
  const dropdowns = (
    id,
    label,
    chosenOption,
    dropdownArray,
    eventHandler,
    width,
    required,
    resultsLabel
  ) => {
    const labelCss = resultsLabel ? '42px' : '6px';
    return (
      <CustomDropdownMySizings
        id={id}
        label={label}
        dropdownArray={dropdownArray}
        eventHandler={eventHandler}
        chosenOption={chosenOption}
        width={width}
        required={required}
        labelCss={labelCss}
      />
    );
  };

  const searchButton = () => {
    return (
      <CustomButton
        classNames='btn btn-green btn-my-sizings btn-search'
        label={CustomTranslationText('XXSPECIFY_MMI_PR_SRCH', props).toUpperCase()}
        onClick={() => search()}
        size='lg'
      />
    );
  };

  const search = () => {
    setSearchSizings(true);
  };

  const textField = (id, onChange, value) => {
    return (
      <CustomInputFieldNavPages
        name='my-sizings-input-field'
        id={id}
        onChange={onChange}
        value={value}
        borderOutlined={true}
      />
    );
  };

  const dateFields = (isStart) => {
    return isStart ? (
      <DatePicker
        className='my-sizing-date-input'
        dateFormat={dateFormat}
        selected={startDate}
        onChange={(date) => setStartDate(date)}
      />
    ) : (
      <DatePicker
        className='my-sizing-date-input'
        dateFormat={dateFormat}
        selected={endDate}
        onChange={(date) => setEndDate(date)}
      />
    );
  };

  return (
    <>
      <ErrorModal
        show={props.error_message}
        handleClose={handleErrorClose}
        handleShow={handleErrorShow}
      />
                <Modal centered show={show} animation={false}>
        <Modal.Header className='modal-header-fpa' closeButton>
          <Modal.Title className="modal-title-confirmation"><CustomTranslation data="XXSPECIFY_MMI_PR_LBL_MESSAGE" /> </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-fpa'>

          <p className="info-text"><CustomTranslation data="XXSPECIFY_MMI_LBL_NEW_SIZING" /></p>
         
        </Modal.Body>
        <Modal.Footer>
        <CustomButton
              classNames="btn btn-close-modal btn-green"
              label="EDIT"
              onClick={() => handleClose('edit')}
              arrow="right"
              size="lg"
            />
          <CustomButton
              classNames="btn btn-close-modal btn-green"
              label="OK"
              onClick={() => handleClose('ok')}
              // onClick={handleClose('ok')}
              arrow="right"
              size="lg"
            />
 
        </Modal.Footer>
      </Modal>
      <div className='my-sizings'>
        <h4 className='title'>{CustomTranslationText('XXSPECIFY_MMI_PR_SZ_LIST', props)}</h4>
        <div className='my-sizings-page'>
          <div className='my-sizings-panel'>
            <div className='input-section'>
              <Row className='search-container'>
                <Col md={4} sm={12}>
                  {dropdowns(
                    'my-sizing-timeframe',
                    CustomTranslationText('XXSPECIFY_MMI_PR_SZ_IN_LST', props),
                    mySizingsTimeframe,
                    sizingsValues,
                    handleMySizingsTimeframe,
                    '150px',
                    true
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={4} sm={12}>
                  {dropdowns(
                    'my-sizing-filter',
                    CustomTranslationText('XXSPECIFY_MMI_PR_FLT', props),
                    mySizingsFilter,
                    filterBy,
                    handleMySizingsFilter,
                    '200px',
                    true
                  )}
                </Col>
                <Col md={1} sm={12}>
                  <span className='date-label'>{CustomTranslationText('XXSPECIFY_MMI_PR_BTW', props)}</span>
                </Col>
                <Col md={3} sm={12}>
                  {dateFields(true)}
                </Col>
                <Col md={1} sm={12}>
                  <span className='date-label'>{CustomTranslationText('XXSPECIFY_MMI_PR_AND_DATE', props)}</span>
                </Col>
                <Col md={3} sm={12}>
                  {dateFields(false)}
                </Col>
              </Row>
              <Row>
                <Col md={4} sm={12}>
                  {dropdowns(
                    'my-sizing-search-by',
                    CustomTranslationText('XXSPECIFY_MMI_PR_SRCH_BY', props),
                    mySizingsSearchBy,
                    searchBy,
                    handleMySizingsSearchBy,
                    '200px',
                    true
                  )}
                </Col>
                <Col md={1} sm={12}></Col>
                <Col md={3} sm={12}>
                  {dropdowns(
                    'my-sizing-search-filter',
                    '',
                    mySizingsSearchFilter,
                    searchFilter,
                    handleMySizingsSearchFilter,
                    '150px',
                    true
                  )}
                </Col>
                <Col md={1} sm={12}>
                  <span className='date-label'>
                    {CustomTranslationText('XXSPECIFY_MMI_PR_VAL', props)}
                  </span>
                </Col>
                <Col md={3} sm={12}>
                  {textField(
                    'my-sizings-input-field',
                    (e) => handleTextField(e.target.value),
                    textFieldValue
                  )}
                </Col>
                <Col md={12} sm={12}>
                  {searchButton()}
                </Col>
              </Row>
            </div>
            <MySizingsSearchResults
              startDate={startDate}
              endDate={endDate}
              results={props.my_sizing_results}
              clearMySizingsTable={clearMySizingsTable}
              setGetSizingRowInfo={setGetSizingRowInfo}
              setShow={setShow}
              setTransactionId={setTransactionId}
            />
          </div>
        </div>
      </div>
    </>
  );
});

const mapStateToProps = (state) => ({
  clear_my_sizings: state.MySizings.clear_my_sizings,
  error_message: state.ErrorMessage.error_message,
  get_sizing_data: state.MySizings.get_sizing_data,
  my_sizing_results: state.MySizings.my_sizing_results,
  email:state.AppHeader.email,
  translation_data: state.TranslationData.translation_data,
});

export default connect(mapStateToProps, {
  clearMySizings,
  errorMessage,
  advancedLayout,
  getSizingData,
  mySizingResults,
  getSizingFormattedData,
  toggleFlow,
  toggleDensity,
  toggleViscosity,
  selectTechnology,
  defaultTechnology,
  selectBasicPage,
  allowPrefBaseRef,
  compositionMethodFlag,
  fluidState,
  savedPreferencesFlag
})(MySizings);
