import React from 'react';
import './radiobutton.scss';

export const CustomRadiobutton = props => {
  const { name, id, text, onChange, checked, disabled ,onClick} = props;
  const label = text ? text : id;
  return (
    <>
      <input
        type="radio"
        name={name}
        id={id}
        checked={checked}
        className="custom-radiobutton"
        onChange={onChange}
        disabled={disabled}
        onClick={onClick}
      />
      <label htmlFor={id} className="custom-radiobutton-label">{label}</label>
    </>
  )
};