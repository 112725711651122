import React from 'react';
import img from '../../images/loading.gif';
import './loader.css';
import { connect } from 'react-redux';
import CustomTranslationText from '../CustomTranslation/CustomTranslationText';

const CustomLoader = (props) => {
    if (props.phase) {
        return (
            <div className="loaderDiv">
                <img src={img} alt={CustomTranslationText('XXSPECIFY_MMI_PR_LOAD', props)} className={props.loaderClass} />
                <p><label className={props.loaderText} >{props.subTitle}</label></p>
            </div>
        );
    }
    return null;
};

const mapStateToProps = (state) => ({
    translation_data: state.TranslationData.translation_data,
  });
  
  export default connect(mapStateToProps, {
    
  })(CustomLoader);
  