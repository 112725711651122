import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Dropdown, FormControl } from 'react-bootstrap';
import './dropdown.scss';


export const CustomDropdown = (props) => {
  const {
    title,
    id,
    text,
    dropdownArray,
    eventHandler,
    disabled,
    borderRequired,
    borderError,
    active,
    styles,
    clear,
    postScript,
    btnDropdownStyles,
    showDisabledText,
    lowercaseValues,
    width,
    number,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [value, setValue] = useState('');
  const searchInputRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (activeIndex >= 0 && menuRef.current) {
      let scrollToIndex = activeIndex === 0 ? 0 : activeIndex + 1
      const itemElement = menuRef.current.children[scrollToIndex];
      if (itemElement) {
        itemElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  }, [activeIndex, options]);

  // we are using this method to get the index of a value because dropdownArray can be an array of strings or it can also be an array of objects
  const getIndexFromValue = (item) => {
    let index = 0;
    if (item?.value) {
      index = dropdownArray.indexOf(item)
    } else {
      dropdownArray.map((i, j) => {
        if (i.value && i.value === item) {
          index = j
        } else if (i === item) {
          index = j
        }
      })
    }

    index = index !== -1 ? index : 0
    return index;
  }

  const setDefaults = () => {
    setActiveIndex(getIndexFromValue(text));
    setOptions([...dropdownArray])
    setValue('')
  }

  useEffect(() => {
    setDefaults()
  }, [dropdownArray, text])

  const handleInputChange = (e) => {
    let value = e.target.value
    const filtered = dropdownArray.filter((item) => {
      let option = item.value ? item.value : item
      return option.toLowerCase().includes(value.toLowerCase())
    });
    setOptions(filtered);
    setValue(value.replace(/([.?*+^$[\]\\(){}|-])/g, ''))
    if (value === '') {
      setActiveIndex(getIndexFromValue(text));
    } else {
      setActiveIndex(0);
    }
  };

  const handleKeyDown = (e) => {
    let key = e.which
    if (key == 40 && activeIndex < options.length - 1) {
      setActiveIndex(activeIndex + 1)
      e.preventDefault()
    } else if (key == 38 && activeIndex > 0) {
      setActiveIndex(activeIndex - 1)
      e.preventDefault()
    } else if (key == 13) {
      if (dropdownArray.length > 0) {
        selectOption(activeIndex)
        setIsOpen(false)
        setDefaults()
        e.preventDefault()
      }
    }
  };

  const selectOption = (index) => {
    let deafultIndex = getIndexFromValue(text)
    let selectedIndex = dropdownArray.indexOf(options[index])
    eventHandler(selectedIndex !== -1 ? selectedIndex : deafultIndex)
  }

  // TODO: fix "in" dropdown
  let dropdownText = disabled && text !== "IN" && !showDisabledText ? "" : text ? text : active;
  dropdownText = lowercaseValues ? (dropdownText?.value ? dropdownText.value : dropdownText) : (dropdownText.value ? dropdownText.value.toUpperCase() : dropdownText.toUpperCase());
  const borderOutline = borderError && !disabled ? '2px solid #d31245' : borderRequired && !disabled ? '2px solid #00aa7e' : 'none';
  const btn = btnDropdownStyles || 'btn-dropdown';
  const widthStyle = width ? { width: width + 'px', whiteSpace: 'normal' } : {};

  return (
    <>
      {title ? <div className="dropdown-title">{title}</div> : <span />}
      <span>
        <Dropdown
          style={styles}
          show={isOpen}
          onToggle={setIsOpen}
        >
          <Dropdown.Toggle
            id={id}
            className={btn}
            disabled={disabled}
            style={{ border: borderOutline, styles }}
            variant="none"
          >
            <span className="dropdown-text">{dropdownText}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="dropdown-menu"
            ref={menuRef}
          >
            <FormControl
              type="text"
              placeholder="Search..."
              value={value}
              onKeyDown={handleKeyDown}
              onChange={handleInputChange}
              ref={searchInputRef}
            />

            {options.map((option, index) => (
              <Dropdown.Item
                clear={clear}
                key={index}
                eventKey={index}
                style={widthStyle}
                onClick={e => {
                  setActiveIndex(index);
                  selectOption(index);
                  setDefaults()
                }}
                active={index === activeIndex}
              >
                {number?option.label:lowercaseValues ? (option.value ? option.value : option) : (option.value ? option.value.toUpperCase() : option.toUpperCase())}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </span>
      <span style={{ float: 'right', alignText: 'right' }}>{postScript}</span>
    </>
  );
};
