import { LOGIN_EMAIL } from '../actionTypes';

const initialState = {
  login_mail: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_EMAIL: {
      return {
        ...state,
        login_mail: action.payload.id,
      };
    }
    default: {
      return state;
    }
  }
}
