import React, { useEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "./Download.scss";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Logo from "../../images/emerson-logo.png";

import {
  GET_REPORT_BLOB
} from '../../constants/constants';

const Download = () => {
  
  const [isDue, setIsDue] = useState(false)
 

  const downloadReport = () => {

    const windowUrl = btoa(window.location.search);
    const params = new URLSearchParams(atob(windowUrl));
    var queryString = atob(windowUrl)

    var startIndex = queryString.indexOf("token=") 
    var token;
    if(startIndex ===-1){
      token = null
    }else{
      startIndex = startIndex + 6;
      token = queryString.substring(startIndex)
    }

    const isValidName = typeof params.get("blob_name") === "string"
    const isValidToken = typeof token === "string"
    
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}${GET_REPORT_BLOB}`,
      headers: {
        "Content-Type": "application/json",
      },

      data: { 
        blob_name: isValidName? params.get("blob_name"):'', 
        token: isValidToken? token:''
       },
    })

      .then(async (response) => {
        if(response.data.STATUS ===200){
          let data = Uint8Array.from(atob(response.data.data), (c) => c.charCodeAt(0));
          var file = new Blob([data], {type: 'application/pdf'});
          var fileURL = URL.createObjectURL(file);
          var link = document.createElement("a");
          let fileName = params.get("blob_name")
          link.download = fileName;
          link.href = fileURL;
          link.click();
        }else{
          setIsDue(true)
        } 
      })
      .catch(function (error) {
        console.log("error", error);
        setIsDue(true)
      });
  };




  return (
    <>
      <div className="container-fluid download-page">
        <header className="row py-2 header-download-page">
          <div className="d-flex align-items-right">
            <img src={Logo} alt="logo" className="img-fluid emerson-logo" />
          </div>
        </header>
        <main className={isDue? 'row main-report':'row'}>
          {isDue ?
          <div className='due-date'>The link to your reports has expired</div>:
          <div>
          <button className="btn btn-light col-xs-11 text-left download-btn" onClick={downloadReport}>
            <span className="download-report">Download <FontAwesomeIcon icon={faDownload} className="icon-download" /></span>
          </button>
        </div>
          }
        </main>
        <footer className={isDue? 'footer-due py-4 text-left':'footer py-4 text-left'}>
            <div className="footer-main-text">
                Need Help?
                <div className="text-left">
                    <div>Our customers care team is here to support you 24/7</div>
                    <div>
                    Email:
                    <a href="mailto:CustomerCare@Emerson.com">
                        {" "}
                        CustomerCare@Emerson.com
                    </a>
                    </div>
                    <div>Phone: 1-888-889-9170 (toll free)</div>
                    <div>
                    Live chat:{" "}
                    <a
                        href="https://emersonglobalresponsecenter.custhelp.com/app/chat/chat_launch/request_source/"
                        target="_blank"
                    >
                        Live Chat
                    </a>
                    </div>
                </div>
            </div>
          
        </footer>
      </div>
    </>
  );
};

export default Download;
