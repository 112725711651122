import React from 'react';
import { translationData } from '../../redux/actions';
import { connect } from 'react-redux';
import US_DATA from '../../translation_US_Data.json';

const CustomTranslationText = (text,props) => {
  let data=props.translation_data.length !== 0
  ? props.translation_data
    .filter((name) => name.message_name === text)
    .map((data) => data.message_text).length !== 0
    ? props.translation_data
      .filter((name) => name.message_name === text)
      .map((data) => data.message_text)
    : US_DATA.filter((name) => name.message_name === text).map(
      (data) => data.message_text
    )
  : US_DATA.filter((name) => name.message_name === text).map(
    (data) => data.message_text
  )
  return  data[0]
  
};


export default CustomTranslationText;
