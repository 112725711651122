import {
    ERROR_MESSAGE
} from '../actionTypes';

const initialState = {
    error_message: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ERROR_MESSAGE: {
            return {
                ...state,
                error_message: action.payload.id
            }
        }
        default: {
            return state;
        }
    }
}

