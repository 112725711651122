import React from 'react';
import './checkbox.scss';
import { Col, Container, Row, OverlayTrigger, Overlay, Tooltip } from 'react-bootstrap';

export const CustomCheckbox = props => {
  const { name, id, label, onChange, checked, disabled, value, showToolip, tooltipText} = props;

  return (
    <>
    {showToolip?(
      <OverlayTrigger
      placement="top"
      overlay={showToolip ?  
        <Tooltip id={`${id}`} className='error-tooltip'>
          {tooltipText}
        </Tooltip>
        
        : null}
    >
      <div>
      <input
        checked={checked}
        type="checkbox"
        name={name}
        id={id}
        className="custom-checkbox"
        onChange={onChange}
        disabled={disabled}
        value={value}
      />
      <label htmlFor={id}>{label}</label>
      </div>
      
      </OverlayTrigger>
    ):
    (
      <>
        <input
        checked={checked}
        type="checkbox"
        name={name}
        id={id}
        className="custom-checkbox"
        onChange={onChange}
        disabled={disabled}
        value={value}
      />
      <label htmlFor={id}>{label}</label>
      </>
    )
    
    }
     
    </>
  )
};
