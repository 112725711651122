import {
  TOGGLE_HYGIENIC,
  TOGGLE_DISPLAY_ALL_SENSORS_NO_FILTERS,
  TOGGLE_IN_LINE,
  TOGGLE_FLOW_THROUGH_CHAMBER_SLIPSTREAM,
  TOGGLE_TANK,
  TOGGLE_HYGIENIC_APPLICATION,
  TOGGLE_HIGH_PROCESS_NOISE_SLURRY,
  PRODUCT_FAMILY,
  PRODUCT_FAMILY_NAME,
  LINE_SIZE,
  LINE_SIZE_ERROR,
  PROCESS_LINE_SIZE,
  SCHEDULE,
  TRIGGER_LEVEL,
  TOGGLE_FLANGED,
  TOGGLE_WAFER,
  TOGGLE_REDUCER,
  TOGGLE_DUAL,
  TOGGLE_QUAD,
  TOOGLE_PRESSURE,
  PIPE_ID,
  PIPE_ID_UNITS,
  PRODUCT_FAMILY_UNITS,
  LINE_SIZE_UNITS,
  PROCESS_LINE_SIZE_UNITS,
  SCHEDULE_UNITS,
  CLEAR_APP_REQUIREMENTS,
  RESIZING_FLAG,
  WETTED_MATERIAL,
  WETTED_MATERIAL_VALUE, 
  WETTED_MATERIAL_LABEL,
  WETTED_MATERIAL_UNITS,
  LINE_SIZE_LABEL,
  PROCESS_LINE_SIZE_LABEL,
  SIS_APPLICATION,
  REPORT_CONFIGS
} from '../actionTypes';

const initialState = {
  hygienic: false,
  display_all_sensors_no_filters: false,
  in_line: false,
  flow_through_chamber_slipstream: false,
  tank: false,
  hygienic_application: false,
  high_process_noise_slurry: false,
  product_family: 'All Sensors',
  product_family_name : 'All Sensors',
  line_size: 'All Sizes',
  line_size_label: 'All Sizes',
  line_size_error: false,
  process_line_size: 'Unknown*',
  process_line_size_label: 'Unknown*',
  schedule_value: 'Unknown',
  trigger_level: '4',
  flanged: false,
  wafer: false,
  reducer: false,
  dual: false,
  quad: false,
  pressure: false, 
  pipe_id: null,
  pipe_id_units: 'IN',
  product_family_units: '',
  line_size_units: '',
  process_line_size_units: '',
  schedule_units: '',
  resizing_flag: false,
  wetted_material:'',
  wetted_material_label: '',
  wetted_material_value:'',
  wetted_material_units:'',
  sis_application:'',
  report_configs : []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_HYGIENIC: {
      return {
        ...state,
        hygienic: action.payload.id
      }
    }
    case TOGGLE_DISPLAY_ALL_SENSORS_NO_FILTERS: {
      return {
        ...state,
        display_all_sensors_no_filters: action.payload.id
      }
    }
    case TOGGLE_IN_LINE: {
      return {
        ...state,
        in_line: action.payload.id
      }
    }
    case REPORT_CONFIGS: {
      return {
        ...state,
        report_configs: action.payload.id
      }
    }
    case SIS_APPLICATION: {
      return {
        ...state,
        sis_application: action.payload.id
      }
    }
    case TOGGLE_FLOW_THROUGH_CHAMBER_SLIPSTREAM: {
      return {
        ...state,
        flow_through_chamber_slipstream: action.payload.id
      }
    }
    case TOGGLE_TANK: {
      return {
        ...state,
        tank: action.payload.id
      }
    }
    case TOGGLE_HYGIENIC_APPLICATION: {
      return {
        ...state,
        hygienic_application: action.payload.id
      }
    }
    case TOGGLE_HIGH_PROCESS_NOISE_SLURRY: {
      return {
        ...state,
        high_process_noise_slurry: action.payload.id
      }
    }
    case PRODUCT_FAMILY: {
      return {
        ...state,
        product_family: action.payload.id
      }
    }
    case PRODUCT_FAMILY_NAME: {
      return {
        ...state,
        product_family_name: action.payload.id
      }
    }
    case LINE_SIZE: {
      return {
        ...state,
        line_size: action.payload.id
      }
    }
    case LINE_SIZE_LABEL: {
      return {
        ...state,
        line_size_label: action.payload.id
      }
    }
    case LINE_SIZE_ERROR: {
      return {
        ...state,
        line_size_error: action.payload.id
      }
    }
    case PROCESS_LINE_SIZE: {
      return {
        ...state,
        process_line_size: action.payload.id
      }
    }
    case PROCESS_LINE_SIZE_LABEL: {
      return {
        ...state,
        process_line_size_label: action.payload.id
      }
    }
    case SCHEDULE: {
      return {
        ...state,
        schedule_value: action.payload.id
      }
    }
    case TRIGGER_LEVEL: {
      return {
        ...state,
        trigger_level: action.payload.id
      }
    }
    case TOGGLE_FLANGED: {
      return {
        ...state,
        flanged: action.payload.id
      }
    }
    case TOGGLE_WAFER: {
      return {
        ...state,
        wafer: action.payload.id
      }
    }
    case TOGGLE_REDUCER: {
      return {
        ...state,
        reducer: action.payload.id
      }
    }
    case TOGGLE_DUAL: {
      return {
        ...state,
        dual: action.payload.id
      }
    }
    case TOGGLE_QUAD: {
      return {
        ...state,
        quad: action.payload.id
      }
    }
    case TOOGLE_PRESSURE: {
      return {
        ...state, 
        pressure: action.payload.id
      }
    }
    case PIPE_ID: {
      return {
        ...state,
        pipe_id: action.payload.id
      }
    }
    case PIPE_ID_UNITS: {
      return {
        ...state,
        pipe_id_units: action.payload.id
      }
    }
    case PRODUCT_FAMILY_UNITS: {
      return {
        ...state,
        product_family_units: action.payload.id
      }
    }
    case LINE_SIZE_UNITS: {
      return {
        ...state,
        line_size_units: action.payload.id
      }
    }
    case PROCESS_LINE_SIZE_UNITS: {
      return {
        ...state,
        process_line_size_units: action.payload.id
      }
    }
    case SCHEDULE_UNITS: {
      return {
        ...state,
        schedule_units: action.payload.id
      }
    }
    case WETTED_MATERIAL: {
      return {
        ...state,
        wetted_material: action.payload.id
      }
    }

    case WETTED_MATERIAL_LABEL: {
      return {
        ...state,
        wetted_material_label: action.payload.id
      }
    }

    case WETTED_MATERIAL_VALUE: {
      return {
        ...state,
        wetted_material_value: action.payload.id
      }
    }

    case WETTED_MATERIAL_UNITS:{
      return {
        ...state,
        wetted_material_units: action.payload.id
      }
    }

    case RESIZING_FLAG: {
      return {
        ...state,
        resizing_flag: action.payload.id
      }
    }
    case CLEAR_APP_REQUIREMENTS: {
      return  initialState      
    }
    default: {
      return state;
    }
  }
}
