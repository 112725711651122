import {
  OPERATING_DATA,
  SHOW_MORE_RATES_RESULT_DATA,
  SELECTED_ROW_DATA,
  ADVANCED_LAYOUT,
  WAS_CLICKED,
  FLUID_SOURCE_ARRAY,
  FLUID_STATE_ARRAY,
  CUSTOM_ERRORS,
  SEARCH_PARAMETERS,
  RESET_OPERATING_CONDITIONS,
  SELECTED_TECHNOLOGY,
  RESET_APP,
  IS_SELECTED,
  SELECT_BASIC_PAGE,
  OPERATING_DATA_ERROR_MESSAGES,
  TOGGLE_FLOW,
  TOGGLE_DENSITY,
  TOGGLE_VISCOSITY,
  SELECT_TECHNOLOGY,
  DEFAULT_TECHNOLOGY,
  PREVIOUS_TECHNOLOGY,
  GAS_DENSITY_ERROR,
  DISABLE_SIZING_RESULTS_BUTTON,
  CONVERT_DISABLE_BUTTON,
  OPERATING_CONDITIONS,
  EQUIPMENT_SELECTION,
  TOGGLE_HYGIENIC,
  TOGGLE_DISPLAY_ALL_SENSORS_NO_FILTERS,
  TOGGLE_IN_LINE,
  TOGGLE_FLOW_THROUGH_CHAMBER_SLIPSTREAM,
  TOGGLE_TANK,
  TOGGLE_HYGIENIC_APPLICATION,
  TOGGLE_HIGH_PROCESS_NOISE_SLURRY,
  PRODUCT_FAMILY,
  PRODUCT_FAMILY_NAME,
  LINE_SIZE,
  LINE_SIZE_LABEL,
  LINE_SIZE_ERROR,
  PROCESS_LINE_SIZE,
  PROCESS_LINE_SIZE_LABEL,
  SCHEDULE,
  TRIGGER_LEVEL,
  TOGGLE_FLANGED,
  TOGGLE_WAFER,
  TOGGLE_REDUCER,
  TOGGLE_DUAL,
  TOGGLE_QUAD,
  TOOGLE_PRESSURE,
  PIPE_ID,
  PIPE_ID_UNITS,
  FLUID_STATE,
  FLUID_SOURCE,
  REVERT_FLAG,
  SET_USERINFO,
  PICK_FLUIDS_FROM_DB,
  PICK_FLUIDS_COOLPROPS_FLAG,
  PICK_GAS_FROM_DB,
  CONDUCTIVITY,
  FLUID_SERVICE,
  CUSTOM_FLUID_NAME,
  STEAM_PRESSURE_TEMP,
  NATURAL_GAS_COMPOSE,
  NATURAL_GAS_NAME_BORDER_ERROR,
  NATURAL_GAS_COMPOSITION_NAME,
  COMPOSITION_METHOD,
  DATABASE_GASSES,
  RELATIVE_DENSITY,
  MOLE_PERCENT_CO2,
  HEATING_VALUE,
  HEATING_VALUE_DROPDOWN,
  MOLE_PERCENT_H2,
  MOLE_PERCENT_CO,
  MOLE_ARRAY_REDUX,
  SELECTED_DATABASE_NATURAL_GAS,
  AVAILABLE_COMPONENTS,
  ALLOW_PREF_BASE_REF,
  SELECTED_COMPONENTS,
  UNITS_FLOW_RATE,
  UNITS_GAS_FLOW_RATE,
  MIN_FLOW_RATE,
  MIN_FLOW_RATE_VALUE,
  NORMAL_FLOW_RATE,
  MAX_FLOW_RATE,
  FULL_FLOW_RATE,
  UNITS_LINE_PRESSURE,
  MIN_LINE_PRESSURE,
  MIN_LINE_PRESSURE_VALUE,
  NORMAL_LINE_PRESSURE,
  NORMAL_LINE_PRESSURE_VALUE,
  MAX_LINE_PRESSURE,
  FULL_LINE_PRESSURE,
  FULL_LINE_PRESSURE_VALUE,
  UNITS_TEMPERATURE,
  MIN_TEMPERATURE,
  MIN_TEMPERATURE_VALUE,
  NORMAL_TEMPERATURE,
  NORMAL_TEMPERATURE_VALUE,
  MAX_TEMPERATURE,
  MAX_TEMPERATURE_VALUE,
  FULL_TEMPERATURE,
  FULL_TEMPERATURE_VALUE,
  UNITS_AMBIENT,
  MIN_AMBIENT,
  NORMAL_AMBIENT,
  MAX_AMBIENT,
  FULL_AMBIENT,
  UNITS_DENSITY,
  MIN_DENSITY,
  NORMAL_DENSITY,
  DENSITY_SG,
  DENSITY_SG_VALUE,
  MINIMUM_DENSITY_ERROR,
  NORMAL_DENSITY_ERROR,
  MAXIMUM_DENSITY_ERROR,
  NORMAL_DENSITY_MIN_MAX_ERRORS,
  MAX_DENSITY_MIN_MAX_ERRORS,
  NORMAL_DENSITY_TYPE_ERRRORS,
  MAX_DENSITY_TYPE_ERRORS,
  NORMAL_VISCOSITY_ERROR,
  MIN_VISCOSITY_CONVERTION_ERROR,
  NORMAL_VISCOSITY_CONVERTION_ERROR,
  MAX_VISCOSITY_CONVERTION_ERROR,
  MAXIMUM_VISCOSITY_ERROR,
  NORMAL_VISCOSITY_MIN_MAX_ERRORS,
  MAX_VISCOSITY_MIN_MAX_ERRORS,
  NORMAL_VISCOSITY_TYPE_ERRORS,
  MAX_VISCOSITY_TYPE_ERRORS,
  MAX_DENSITY,
  UNITS_VISCOSITY,
  MIN_VISCOSITY,
  NORMAL_VISCOSITY,
  MAX_VISCOSITY,
  UNITS_VAPOR,
  MIN_VAPOR,
  MAX_FLOW_ACC,
  UNITS_MAX_PRESSURE_DROP,
  MAX_PRESSURE_DROP,
  UNITS_SONIC_VELOCITY,
  SONIC_VELOCITY,
  SONIC_VELOCITY_MAP,
  UNITS_GAS_PRESSURE,
  GAS_PRESSURE,
  UNITS_GAS_TEMPERATURE,
  GAS_TEMPERATURE,
  UNITS_GAS_DENSITY,
  UNITS_GAS_DENSITY_SG,
  GAS_DENSITY,
  GAS_DENSITY_VALUE,
  RESET_RESIZING_FLAG,
  RESIZING_DISCARD_FLAG,
  UNITS_PRESSURE_ATMOSPHERIC,
  PRESSURE_ATMOSPHERIC,
  UNITS_MINIMUM_ACCURACY,
  UNITS_DENSITY_HEADING,
  NON_NEWTONIAN_LIQUID,
  NON_NEWTONIAN_LIQUID_FLAG,
  REVERT_NON_NEWTONIAN_CHECKBOX_FLAG,
  CALCULATE_DENSITY_VARIATION,
  K_FLOW_CONSISTENCY_INDEX,
  N_FLOW_BEHAVIOR,
  COMPRESSIBILITY,
  BASE_COMPRESSIBILITY,
  BASE_COMPRESSIBILITY_ERROR,
  GAS_CUSTOM_COMPRESSIBILITY_ERROR,
  SPECIFIC_GRAVITY,
  SPECIFIC_GRAVITY_ERROR,
  MOLECULAR_WEIGHT,
  MOLECULAR_WEIGHT_ERROR,
  BASE_REFERENCE_CONDITIONS,
  APPLICATION,
  SERVICE,
  SENSOR_TAG,
  TRANSMITTER_TAG,
  PROJECT_NAME,
  FETCH_DROPDOWN_DATA,
  FLOW_RATE_UNIT_FOR_LIQUID_SLURRY_PREF,
  FLOW_RATE_UNIT_FOR_GAS_PREF,
  TEMPERATURE_UNIT_PREF,
  AMBIENT_TEMPERATURE_UNIT_PREF,
  LINE_PRESSURE_UNIT_PREF,
  DENSITY_UNIT_PREF,
  VISCOSITY_UNIT_PREF,
  VAPOR_PRESSURE_UNIT_PREF,
  ATMOSPHERIC_UNIT_PREF,
  GAS_ONLY_PRESSURE_UNIT_PREF,
  GAS_ONLY_PRESSURE_PREF,
  GAS_ONLY_TEMPERATURE_UNIT_PREF,
  GAS_ONLY_TEMPERATURE_PREF,
  GAS_ONLY_DENSITY_UNIT_PREF,
  SONIC_VELOCITY_UNIT_PREF,
  FLOW_RATE_DECIMAL_FACTOR,
  FLOW_RATE_DECIMAL,
  TEMPERATURE_DECIMAL_FACTOR,
  TEMPERATURE_DECIMAL,
  LINE_PRESSURE_DECIMAL_FACTOR,
  LINE_PRESSURE_DECIMAL,
  DECIMAL_FACTOR_PREF,
  ALL_OTHER_DECIMAL,
  UOM_AUTO_CONVERSION_PREF,
  REFRESH_PREF,
  RESIZING_FLAG,
  REQUEST_SIZING_RESULTS,
  COMPARE_MODEL_NAMES,
  DISPLAY_SIZING_RESULTS,
  COMPARE_SIZING_RESULTS,
  COMPARE_DISABLE_BUTTON,
  SIZING_RESULTS_DATA,
  LANGUAGE_LIST,
  LOGIN_EMAIL,
  PICK_FLUIDS_FROM_DB_UNITS,
  CONDUCTIVITY_UNITS,
  FLUID_SERVICE_UNITS,
  FLUID_SERVICE_KEY,
  FLUID_SELECTION_FLAG,
  FLUID_SELECTION_SECTION,
  FLUID_STATE_UNITS,
  FLUID_SOURCE_UNITS,
  SAVED_PREFERENCES,
  UNITS_DATA,
  PRODUCT_FAMILY_UNITS,
  LINE_SIZE_UNITS,
  PROCESS_LINE_SIZE_UNITS,
  SCHEDULE_UNITS,
  CLEAR_MY_SIZINGS,
  TRANSLATION_DATA,
  USER_LANGUAGE,
  CLEAR_UOM,
  ERROR_MESSAGE,
  NATURAL_GAS_COMPONENTS,
  SIZING_ERROR_MESSAGE,
  APPLICATION_NAME_ERROR,
  REPORT_FLAG,
  REPORT_CONFIGS,
  INTERNET_MODE,
  TRANSLATION_ERROR,
  CLEAR_ALL_FIELD,
  CONFIG_WARNING_MSG,
  SONIC_VELOCITY_WARNING_MSG,
  AMBIENT_TEMP_WARNING_MSG,
  HIGH_TEMP_WARNING_MSG,
  HIGH_TEMPERATURE_ERROR_MSG,
  MOLE_PERCENT_N2,
  CLEAR_APP_REQUIREMENTS,
  CLEAR_EQ_SELECTION,
  CLEAR_MEAS_TYPE,
  CLEAR_FLUID_SELECTION,
  CLEAR_PROC_VARIABLES,
  CLEAR_PREF_VARIABLES,
  CLEAR_PROC_VARIABLES_VALUES,
  PRODUCT_IMAGE_URL,
  PRODUCT_IMAGE_URL_LOAD,
  PROCEED_LOAD,
  GET_SIZING_DATA,
  MY_SIZING_RESULTS,
  GET_SIZING_FORMATTED_DATA,
  TUBE_VELOCITY_UNIT,
  SHOW_MORE_RATES_DATA,
  SHOW_MORE_RATES_LOADER,
  DATABASE_GASSES_LIST,
  QUERY_PARAMS,
  FLOW_RATE_MIN_CLASS_LIST,
  FLOW_RATE_NORM_CLASS_LIST,
  FLOW_RATE_MAX_CLASS_LIST,
  FLOW_RATE_FULL_CLASS_LIST,
  FLOW_RATE_MIN_ERRORS,
  FLOW_RATE_NORM_ERRORS,
  FLOW_RATE_MAX_ERRORS,
  FLOW_RATE_FULL_ERRORS,
  FLOW_RATE_DROPDOWN_ARRAY,
  FLOW_RATE_GAS_DROPDOWN_ARRAY,
  LINE_PRESS_MIN_CLASS_LIST,
  LINE_PRESS_NORM_CLASS_LIST,
  LINE_PRESS_MAX_CLASS_LIST,
  LINE_PRESS_FULL_CLASS_LIST,
  LINE_PRESS_MIN_ERRORS,
  LINE_PRESS_NORM_ERRORS,
  LINE_PRESS_MAX_ERRORS,
  LINE_PRESS_FULL_ERRORS,
  LINE_PRESS_DROPDOWN_ARRAY,
  TEMPERATURE_MIN_CLASS_LIST,
  TEMPERATURE_NORM_CLASS_LIST,
  TEMPERATURE_MAX_CLASS_LIST,
  TEMPERATURE_FULL_CLASS_LIST,
  TEMPERATURE_MIN_ERRORS,
  TEMPERATURE_NORM_ERRORS,
  TEMPERATURE_MAX_ERRORS,
  TEMPERATURE_FULL_ERRORS,
  TEMPERATURE_DROPDOWN_ARRAY,
  AMBIENT_TEMPERATURE_MIN_CLASS_LIST,
  AMBIENT_TEMPERATURE_NORM_CLASS_LIST,
  AMBIENT_TEMPERATURE_MAX_CLASS_LIST,
  AMBIENT_TEMPERATURE_FULL_CLASS_LIST,
  AMBIENT_TEMPERATURE_MIN_ERRORS,
  AMBIENT_TEMPERATURE_NORM_ERRORS,
  AMBIENT_TEMPERATURE_MAX_ERRORS,
  AMBIENT_TEMPERATURE_FULL_ERRORS,
  AMBIENT_TEMPERATURE_DROPDOWN_ARRAY,
  REQUIRED_FIELDS,
  FLOW_ACCURACY,
  FLOW_ACCURACY_VALUE,
  DENSITY_ACCURACY,
  MASS_FLOW,
  SAVED_SIZING_LOAD,
  CUSTOM_BORDER_ERROR,
  NAVBAR_FLAG,
  MAX_LINE_PRESSURE_VALUE,
  MIN_DENSITY_VALUE,
  NORMAL_DENSITY_VALUE,
  MAX_DENSITY_VALUE,
  MIN_VISCOSITY_VALUE,
  NORMAL_VISCOSITY_VALUE,
  MAX_VISCOSITY_VALUE,
  MIN_VAPOR_VALUE,
  SONIC_VELOCITY_VALUE,
  COOLPROPS_VISC,
  WETTED_MATERIAL,
  WETTED_MATERIAL_VALUE,
  WETTED_MATERIAL_LABEL,
  WETTED_MATERIAL_UNITS,
  WETTED_MATERIAL_PREF,
  WARNING_LIMIT,
  LINE_PRESS_ROW_PSGI,
  TEMP_ROW_F,
  SET_CALC_FLAG,
  SIZING_RESULTS_CLICKED,
  SIS_APPLICATION,
  TOTAL_MOLE,
  GAS_TEMPERATURE_ERROR,
  GAS_PRESSURE_ERROR,
  COMPOSITION_METHOD_SECTION,
  COMPOSITION_METHOD_FLAG,
  SET_DEFAULT_ROW,
  SAVED_PREFERENCES_FLAG,
  MEASUREMENT_TYPE_CONTAINER_CLASS,
  SONIC_VELOCITY_ENTERED,
  IS_APPLIED,
  PRODUCT_IMAGE_API_LOAD,
} from "./actionTypes";

export const operatingData = (id) => ({
  type: OPERATING_DATA,
  payload: { id },
});

export const massFlowAccuracy = (id) => ({
  type: MASS_FLOW,
  payload: { id },
});

export const flowAccuracy = (id) => ({
  type: FLOW_ACCURACY,
  payload: { id },
});
export const flowAccuracyValue = (id) => ({
  type: FLOW_ACCURACY_VALUE,
  payload: { id },
});
export const sisApplication = (id) => ({
  type: SIS_APPLICATION,
  payload: { id },
});
export const densityAccuracy = (id) => ({
  type: DENSITY_ACCURACY,
  payload: { id },
});
export const clearProcVariables = () => ({
  type: CLEAR_PROC_VARIABLES,
});

export const clearPrefVariables = () => ({
  type: CLEAR_PREF_VARIABLES,
});

export const clearProcVariablesValues = () => ({
  type: CLEAR_PROC_VARIABLES_VALUES,
});

export const clearFluidSection = () => ({
  type: CLEAR_FLUID_SELECTION,
});

export const clearMeasType = () => ({
  type: CLEAR_MEAS_TYPE,
});

export const clearEqSelection = () => ({
  type: CLEAR_EQ_SELECTION,
});

export const clearAppRequirements = () => ({
  type: CLEAR_APP_REQUIREMENTS,
});

export const advancedLayout = (id) => ({
  type: ADVANCED_LAYOUT,
  payload: { id },
});

export const selectedTechnology = (id) => ({
  type: SELECTED_TECHNOLOGY,
  payload: { id },
});

export const totalMole = (id) => ({
  type: TOTAL_MOLE,
  payload: { id },
});
export const searchParameters = (id) => ({
  type: SEARCH_PARAMETERS,
  payload: { id },
});

export const wasClicked = (id) => ({
  type: WAS_CLICKED,
  payload: { id },
});

export const applyChanges = (id) => ({
  type: IS_APPLIED,
  payload: { id },
});

export const resetApp = (id) => ({
  type: RESET_APP,
  payload: { id },
});

export const selectBasicPage = (id) => ({
  type: SELECT_BASIC_PAGE,
  payload: { id },
});

export const toggleFlow = (id) => ({
  type: TOGGLE_FLOW,
  payload: { id },
});

export const toggleDensity = (id) => ({
  type: TOGGLE_DENSITY,
  payload: { id },
});

export const toggleViscosity = (id) => ({
  type: TOGGLE_VISCOSITY,
  payload: { id },
});

export const selectTechnology = (id) => ({
  type: SELECT_TECHNOLOGY,
  payload: { id },
});

export const defaultTechnology = (id) => ({
  type: DEFAULT_TECHNOLOGY,
  payload: { id },
});

export const previousTechnology = (id) => ({
  type: PREVIOUS_TECHNOLOGY,
  payload: { id },
});

export const disableSizingResultsButton = (id) => ({
  type: DISABLE_SIZING_RESULTS_BUTTON,
  payload: { id },
});

export const convertDisableButton = (id) => ({
  type: CONVERT_DISABLE_BUTTON,
  payload: { id },
});

export const operatingConditions = (id) => ({
  type: OPERATING_CONDITIONS,
  payload: { id },
});

export const resetOperatingConditions = (id) => ({
  type: RESET_OPERATING_CONDITIONS,
  payload: { id },
});

export const equipmentSelection = (id) => ({
  type: EQUIPMENT_SELECTION,
  payload: { id },
});

export const toggleHygienic = (id) => ({
  type: TOGGLE_HYGIENIC,
  payload: { id },
});

export const customBorderError = (id) => ({
  type: CUSTOM_BORDER_ERROR,
  payload: { id },
});

export const minimumDensityError = (id) => ({
  type: MINIMUM_DENSITY_ERROR,
  payload: { id },
});

export const normalDensityError = (id) => ({
  type: NORMAL_DENSITY_ERROR,
  payload: { id },
});

export const maximumDensityError = (id) => ({
  type: MAXIMUM_DENSITY_ERROR,
  payload: { id },
});

export const normalDensityMinMaxErrors = (id) => ({
  type: NORMAL_DENSITY_MIN_MAX_ERRORS,
  payload: { id },
});

export const maxDensityMinMaxErrors = (id) => ({
  type: MAX_DENSITY_MIN_MAX_ERRORS,
  payload: { id },
});

export const normalDensityTypeErrors = (id) => ({
  type: NORMAL_DENSITY_TYPE_ERRRORS,
  payload: { id },
});

export const maxDensityTypeErrors = (id) => ({
  type: MAX_DENSITY_TYPE_ERRORS,
  payload: { id },
});

export const normalViscosityError = (id) => ({
  type: NORMAL_VISCOSITY_ERROR,
  payload: { id },
});

export const minViscosityConvertionError = (id) => ({
  type: MIN_VISCOSITY_CONVERTION_ERROR,
  payload: { id },
});

export const normalViscosityConvertionError = (id) => ({
  type: NORMAL_VISCOSITY_CONVERTION_ERROR,
  payload: { id },
});

export const maxViscosityConvertionError = (id) => ({
  type: MAX_VISCOSITY_CONVERTION_ERROR,
  payload: { id },
});

export const setReportConfigs = (id) => ({
  type: REPORT_CONFIGS,
  payload: { id },
});

export const maximumViscosityError = (id) => ({
  type: MAXIMUM_VISCOSITY_ERROR,
  payload: { id },
});

export const normalViscosityMinMaxErrors = (id) => ({
  type: NORMAL_VISCOSITY_MIN_MAX_ERRORS,
  payload: { id },
});

export const maxViscosityMinMaxErrors = (id) => ({
  type: MAX_VISCOSITY_MIN_MAX_ERRORS,
  payload: { id },
});

export const normalViscosityTypeErrors = (id) => ({
  type: NORMAL_VISCOSITY_TYPE_ERRORS,
  payload: { id },
});

export const maxViscosityTypeErrors = (id) => ({
  type: MAX_VISCOSITY_TYPE_ERRORS,
  payload: { id },
});

export const toggleDisplayAllSensorsNoFilters = (id) => ({
  type: TOGGLE_DISPLAY_ALL_SENSORS_NO_FILTERS,
  payload: { id },
});

export const toggleInLine = (id) => ({
  type: TOGGLE_IN_LINE,
  payload: { id },
});

export const setOperatingDataErrorMessages = (id) => ({
  type: OPERATING_DATA_ERROR_MESSAGES,
  payload: { id },
});
export const toggleFlowThroughChamberSlipstream = (id) => ({
  type: TOGGLE_FLOW_THROUGH_CHAMBER_SLIPSTREAM,
  payload: { id },
});

export const toggleTank = (id) => ({
  type: TOGGLE_TANK,
  payload: { id },
});

export const toggleHygienicApplication = (id) => ({
  type: TOGGLE_HYGIENIC_APPLICATION,
  payload: { id },
});

export const navbarFlag = (id) => ({
  type: NAVBAR_FLAG,
  payload: { id },
});

export const toggleHighProcessNoiseSlurry = (id) => ({
  type: TOGGLE_HIGH_PROCESS_NOISE_SLURRY,
  payload: { id },
});

export const productFamily = (id) => ({
  type: PRODUCT_FAMILY,
  payload: { id },
});

export const productFamilyName = (id) => ({
  type: PRODUCT_FAMILY_NAME,
  payload: { id },
});

export const lineSize = (id) => ({
  type: LINE_SIZE,
  payload: { id },
});

export const lineSizeLabel = (id) => ({
  type: LINE_SIZE_LABEL,
  payload: { id },
});

export const lineSizeError = (id) => ({
  type: LINE_SIZE_ERROR,
  payload: { id },
});

export const processLineSize = (id) => ({
  type: PROCESS_LINE_SIZE,
  payload: { id },
});

export const processLineSizeLabel = (id) => ({
  type: PROCESS_LINE_SIZE_LABEL,
  payload: { id },
});

export const scheduleDropdown = (id) => ({
  type: SCHEDULE,
  payload: { id },
});

export const triggerLevel = (id) => ({
  type: TRIGGER_LEVEL,
  payload: { id },
});

export const toggleFlanged = (id) => ({
  type: TOGGLE_FLANGED,
  payload: { id },
});

export const toggleWafer = (id) => ({
  type: TOGGLE_WAFER,
  payload: { id },
});

export const toggleReducer = (id) => ({
  type: TOGGLE_REDUCER,
  payload: { id },
});

export const toggleDual = (id) => ({
  type: TOGGLE_DUAL,
  payload: { id },
});

export const toggleQuad = (id) => ({
  type: TOGGLE_QUAD,
  payload: { id },
});

export const tooglePressure = (id) => ({
  type: TOOGLE_PRESSURE,
  payload: { id },
});

export const pipeId = (id) => ({
  type: PIPE_ID,
  payload: { id },
});

export const pipeIdUnits = (id) => ({
  type: PIPE_ID_UNITS,
  payload: { id },
});

export const fluidState = (id) => ({
  type: FLUID_STATE,
  payload: { id },
});

export const fluidSource = (id) => ({
  type: FLUID_SOURCE,
  payload: { id },
});

export const revertFlag = (id) => ({
  type: REVERT_FLAG,
  payload: { id },
});

export const fluidSourceArray = (id) => ({
  type: FLUID_SOURCE_ARRAY,
  payload: { id },
});

export const fluidStateArray = (id) => ({
  type: FLUID_STATE_ARRAY,
  payload: { id },
});

export const pickFluidsFromDB = (id) => ({
  type: PICK_FLUIDS_FROM_DB,
  payload: { id },
});

export const pickFluidsCoolpropsFlag = (id) => ({
  type: PICK_FLUIDS_COOLPROPS_FLAG,
  payload: { id },
});
export const coolPropsVisc = (id) => ({
  type: COOLPROPS_VISC,
  payload: { id },
});
export const pickGasFromDB = (id) => ({
  type: PICK_GAS_FROM_DB,
  payload: { id },
});

export const conductivityDropdown = (id) => ({
  type: CONDUCTIVITY,
  payload: { id },
});

export const fluidService = (id) => ({
  type: FLUID_SERVICE,
  payload: { id },
});

export const fluidServiceKey = (id) => ({
  type: FLUID_SERVICE_KEY,
  payload: { id },
});

export const customFluidName = (id) => ({
  type: CUSTOM_FLUID_NAME,
  payload: { id },
});

export const steamPressureTemp = (id) => ({
  type: STEAM_PRESSURE_TEMP,
  payload: { id },
});

export const naturalGasCompose = (id) => ({
  type: NATURAL_GAS_COMPOSE,
  payload: { id },
});

export const naturalGasBorderError = (id) => ({
  type: NATURAL_GAS_NAME_BORDER_ERROR,
  payload: { id },
});

export const naturalGasCompositionName = (id) => ({
  type: NATURAL_GAS_COMPOSITION_NAME,
  payload: { id },
});

export const compositionMethod = (id) => ({
  type: COMPOSITION_METHOD,
  payload: { id },
});

export const databaseGasses = (id) => ({
  type: DATABASE_GASSES,
  payload: { id },
});
export const databaseGassesList = (id) => ({
  type: DATABASE_GASSES_LIST,
  payload: { id },
});

export const relativeDensity = (id) => ({
  type: RELATIVE_DENSITY,
  payload: { id },
});

export const molePercentCO2 = (id) => ({
  type: MOLE_PERCENT_CO2,
  payload: { id },
});
export const moleArrayRedux = (id) => ({
  type: MOLE_ARRAY_REDUX,
  payload: { id },
});
export const selectedDatabaseNaturalGas = (id) => ({
  type: SELECTED_DATABASE_NATURAL_GAS,
  payload: { id },
});
export const heatingValue = (id) => ({
  type: HEATING_VALUE,
  payload: { id },
});

export const heatingValueDropdown = (id) => ({
  type: HEATING_VALUE_DROPDOWN,
  payload: { id },
});

export const molePercentH2 = (id) => ({
  type: MOLE_PERCENT_H2,
  payload: { id },
});
export const molePercentN2 = (id) => ({
  type: MOLE_PERCENT_N2,
  payload: { id },
});

export const molePercentCO = (id) => ({
  type: MOLE_PERCENT_CO,
  payload: { id },
});

export const availableComponents = (id) => ({
  type: AVAILABLE_COMPONENTS,
  payload: { id },
});

export const allowPrefBaseRef = (id) => ({
  type: ALLOW_PREF_BASE_REF,
  payload: { id },
});

export const selectedComponents = (id) => ({
  type: SELECTED_COMPONENTS,
  payload: { id },
});

export const unitsFlowRate = (id) => ({
  type: UNITS_FLOW_RATE,
  payload: { id },
});

export const unitsGasFlowRate = (id) => ({
  type: UNITS_GAS_FLOW_RATE,
  payload: { id },
});

export const minFlowRate = (id) => ({
  type: MIN_FLOW_RATE,
  payload: { id },
});

export const minFlowRateValue = (id) => ({
  type: MIN_FLOW_RATE_VALUE,
  payload: { id },
});

export const normalFlowRate = (id) => ({
  type: NORMAL_FLOW_RATE,
  payload: { id },
});

export const maxFlowRate = (id) => ({
  type: MAX_FLOW_RATE,
  payload: { id },
});

export const fullFlowRate = (id) => ({
  type: FULL_FLOW_RATE,
  payload: { id },
});

export const unitsLinePressure = (id) => ({
  type: UNITS_LINE_PRESSURE,
  payload: { id },
});

export const minLinePressure = (id) => ({
  type: MIN_LINE_PRESSURE,
  payload: { id },
});

export const minLinePressureValue = (id) => ({
  type: MIN_LINE_PRESSURE_VALUE,
  payload: { id },
});

export const normalLinePressure = (id) => ({
  type: NORMAL_LINE_PRESSURE,
  payload: { id },
});

export const normalLinePressureValue = (id) => ({
  type: NORMAL_LINE_PRESSURE_VALUE,
  payload: { id },
});

export const maxLinePressure = (id) => ({
  type: MAX_LINE_PRESSURE,
  payload: { id },
});

export const maxLinePressureValue = (id) => ({
  type: MAX_LINE_PRESSURE_VALUE,
  payload: { id },
});

export const fullLinePressure = (id) => ({
  type: FULL_LINE_PRESSURE,
  payload: { id },
});

export const fullLinePressureValue = (id) => ({
  type: FULL_LINE_PRESSURE_VALUE,
  payload: { id },
});

export const unitsTemperature = (id) => ({
  type: UNITS_TEMPERATURE,
  payload: { id },
});

export const minTemperature = (id) => ({
  type: MIN_TEMPERATURE,
  payload: { id },
});

export const minTemperatureValue = (id) => ({
  type: MIN_TEMPERATURE_VALUE,
  payload: { id },
});

export const normalTemperature = (id) => ({
  type: NORMAL_TEMPERATURE,
  payload: { id },
});

export const normalTemperatureValue = (id) => ({
  type: NORMAL_TEMPERATURE_VALUE,
  payload: { id },
});

export const maxTemperature = (id) => ({
  type: MAX_TEMPERATURE,
  payload: { id },
});

export const maxTemperatureValue = (id) => ({
  type: MAX_TEMPERATURE_VALUE,
  payload: { id },
});

export const fullTemperature = (id) => ({
  type: FULL_TEMPERATURE,
  payload: { id },
});

export const fullTemperatureValue = (id) => ({
  type: FULL_TEMPERATURE_VALUE,
  payload: { id },
});

export const unitsAmbient = (id) => ({
  type: UNITS_AMBIENT,
  payload: { id },
});

export const minAmbient = (id) => ({
  type: MIN_AMBIENT,
  payload: { id },
});

export const normalAmbient = (id) => ({
  type: NORMAL_AMBIENT,
  payload: { id },
});

export const maxAmbient = (id) => ({
  type: MAX_AMBIENT,
  payload: { id },
});

export const fullAmbient = (id) => ({
  type: FULL_AMBIENT,
  payload: { id },
});

export const unitsDensity = (id) => ({
  type: UNITS_DENSITY,
  payload: { id },
});

export const minDensity = (id) => ({
  type: MIN_DENSITY,
  payload: { id },
});

export const minDensityValue = (id) => ({
  type: MIN_DENSITY_VALUE,
  payload: { id },
});

export const normalDensity = (id) => ({
  type: NORMAL_DENSITY,
  payload: { id },
});

export const normalDensityValue = (id) => ({
  type: NORMAL_DENSITY_VALUE,
  payload: { id },
});

export const densitySG = (id) => ({
  type: DENSITY_SG,
  payload: { id },
});

export const densitySGValue = (id) => ({
  type: DENSITY_SG_VALUE,
  payload: { id },
});

export const maxDensity = (id) => ({
  type: MAX_DENSITY,
  payload: { id },
});

export const maxDensityValue = (id) => ({
  type: MAX_DENSITY_VALUE,
  payload: { id },
});

export const unitsViscosity = (id) => ({
  type: UNITS_VISCOSITY,
  payload: { id },
});

export const minViscosity = (id) => ({
  type: MIN_VISCOSITY,
  payload: { id },
});

export const minViscosityValue = (id) => ({
  type: MIN_VISCOSITY_VALUE,
  payload: { id },
});

export const normalViscosity = (id) => ({
  type: NORMAL_VISCOSITY,
  payload: { id },
});

export const normalViscosityValue = (id) => ({
  type: NORMAL_VISCOSITY_VALUE,
  payload: { id },
});

export const maxViscosity = (id) => ({
  type: MAX_VISCOSITY,
  payload: { id },
});

export const maxViscosityValue = (id) => ({
  type: MAX_VISCOSITY_VALUE,
  payload: { id },
});

export const unitsVapor = (id) => ({
  type: UNITS_VAPOR,
  payload: { id },
});

export const minVapor = (id) => ({
  type: MIN_VAPOR,
  payload: { id },
});

export const minVaporValue = (id) => ({
  type: MIN_VAPOR_VALUE,
  payload: { id },
});

export const maxFlowAcc = (id) => ({
  type: MAX_FLOW_ACC,
  payload: { id },
});

export const unitsMaxPressureDrop = (id) => ({
  type: UNITS_MAX_PRESSURE_DROP,
  payload: { id },
});

export const maxPressureDrop = (id) => ({
  type: MAX_PRESSURE_DROP,
  payload: { id },
});

export const unitsSonicVelocity = (id) => ({
  type: UNITS_SONIC_VELOCITY,
  payload: { id },
});

export const sonicVelocity = (id) => ({
  type: SONIC_VELOCITY,
  payload: { id },
});

export const sonicVelocityMap = (id) => ({
  type: SONIC_VELOCITY_MAP,
  payload: { id },
});

export const sonicVelocityValue = (id) => ({
  type: SONIC_VELOCITY_VALUE,
  payload: { id },
});

export const unitsGasPressure = (id) => ({
  type: UNITS_GAS_PRESSURE,
  payload: { id },
});

export const gasPressure = (id) => ({
  type: GAS_PRESSURE,
  payload: { id },
});

export const gasPressureError = (id) => ({
  type: GAS_PRESSURE_ERROR,
  payload: { id },
});

export const unitsGasTemperature = (id) => ({
  type: UNITS_GAS_TEMPERATURE,
  payload: { id },
});

export const gasTemperature = (id) => ({
  type: GAS_TEMPERATURE,
  payload: { id },
});

export const gasTemperatureError = (id) => ({
  type: GAS_TEMPERATURE_ERROR,
  payload: { id },
});

export const unitsGasDensity = (id) => ({
  type: UNITS_GAS_DENSITY,
  payload: { id },
});

export const unitsGasDensitySG = (id) => ({
  type: UNITS_GAS_DENSITY_SG,
  payload: { id },
});

export const savedPreferencesFlag = (id) => ({
  type: SAVED_PREFERENCES_FLAG,
  payload: { id },
});

export const gasDensity = (id) => ({
  type: GAS_DENSITY,
  payload: { id },
});

export const gasDensityValue = (id) => ({
  type: GAS_DENSITY_VALUE,
  payload: { id },
});

export const resetResizingFlag = (id) => ({
  type: RESET_RESIZING_FLAG,
  payload: { id },
});

export const resizingDiscardFlag = (id) => ({
  type: RESIZING_DISCARD_FLAG,
  payload: { id },
});

export const setUserInfo = (id) => ({
  type: SET_USERINFO,
  payload: { id },
});

export const gasDensityError = (id) => ({
  type: GAS_DENSITY_ERROR,
  payload: { id },
});

export const unitsPressureAtmospheric = (id) => ({
  type: UNITS_PRESSURE_ATMOSPHERIC,
  payload: { id },
});

export const pressureAtmospheric = (id) => ({
  type: PRESSURE_ATMOSPHERIC,
  payload: { id },
});

export const unitsMinimumAccuracy = (id) => ({
  type: UNITS_MINIMUM_ACCURACY,
  payload: { id },
});

export const unitsDensityHeading = (id) => ({
  type: UNITS_DENSITY_HEADING,
  payload: { id },
});

export const nonNewtonianLiquid = (id) => ({
  type: NON_NEWTONIAN_LIQUID,
  payload: { id },
});

export const nonNewtonianLiquiFlag = (id) => ({
  type: NON_NEWTONIAN_LIQUID_FLAG,
  payload: { id },
});

export const revertNonNewtonianCheckboxFlag = (id) => ({
  type: REVERT_NON_NEWTONIAN_CHECKBOX_FLAG,
  payload: { id },
});

export const calculateDensityVariation = (id) => ({
  type: CALCULATE_DENSITY_VARIATION,
  payload: { id },
});

export const kFlowConsistencyIndex = (id) => ({
  type: K_FLOW_CONSISTENCY_INDEX,
  payload: { id },
});

export const isSelected = (id) => ({
  type: IS_SELECTED,
  payload: { id },
});

export const nFlowBehavior = (id) => ({
  type: N_FLOW_BEHAVIOR,
  payload: { id },
});

export const gasCompressibility = (id) => ({
  type: COMPRESSIBILITY,
  payload: { id },
});

export const baseCompressibility = (id) => ({
  type: BASE_COMPRESSIBILITY,
  payload: { id },
});

export const baseCompressibilityError = (id) => ({
  type: BASE_COMPRESSIBILITY_ERROR,
  payload: { id },
});

export const gasCustomCompressibilityError = (id) => ({
  type: GAS_CUSTOM_COMPRESSIBILITY_ERROR,
  payload: { id },
});

export const specificGravity = (id) => ({
  type: SPECIFIC_GRAVITY,
  payload: { id },
});

export const specificGravityError = (id) => ({
  type: SPECIFIC_GRAVITY_ERROR,
  payload: { id },
});

export const molecularWeight = (id) => ({
  type: MOLECULAR_WEIGHT,
  payload: { id },
});

export const molecularWeightError = (id) => ({
  type: MOLECULAR_WEIGHT_ERROR,
  payload: { id },
});

export const baseReferenceConditions = (id) => ({
  type: BASE_REFERENCE_CONDITIONS,
  payload: { id },
});

export const customErrors = (id) => ({
  type: CUSTOM_ERRORS,
  payload: { id },
});

export const sizingApplication = (id) => ({
  type: APPLICATION,
  payload: { id },
});

export const productImageUrl = (id) => ({
  type: PRODUCT_IMAGE_URL,
  payload: { id },
});

export const showMoreRatesLoader = (id) => ({
  type: SHOW_MORE_RATES_LOADER,
  payload: { id },
});

export const showMoreRatesData = (id) => ({
  type: SHOW_MORE_RATES_DATA,
  payload: { id },
});

export const showMoreRatesResultData = (id) => ({
  type: SHOW_MORE_RATES_RESULT_DATA,
  payload: { id },
});

export const productImageUrlLoad = (id) => ({
  type: PRODUCT_IMAGE_URL_LOAD,
  payload: { id },
});

export const productImageApiLoad = (id) => ({
  type: PRODUCT_IMAGE_API_LOAD,
  payload: { id },
});

export const proceedLoad = (id) => ({
  type: PROCEED_LOAD,
  payload: { id },
});

export const sizingService = (id) => ({
  type: SERVICE,
  payload: { id },
});

export const sensorTag = (id) => ({
  type: SENSOR_TAG,
  payload: { id },
});

export const transmitterTag = (id) => ({
  type: TRANSMITTER_TAG,
  payload: { id },
});

export const projectName = (id) => ({
  type: PROJECT_NAME,
  payload: { id },
});

export const dropdownData = (id) => ({
  type: FETCH_DROPDOWN_DATA,
  payload: { id },
});

export const flowRateUnitLiquidSlurryPref = (id) => ({
  type: FLOW_RATE_UNIT_FOR_LIQUID_SLURRY_PREF,
  payload: { id },
});

export const flowRateUnitGasPref = (id) => ({
  type: FLOW_RATE_UNIT_FOR_GAS_PREF,
  payload: { id },
});

export const temperatureUnitPref = (id) => ({
  type: TEMPERATURE_UNIT_PREF,
  payload: { id },
});

export const ambientTemperatureUnitPref = (id) => ({
  type: AMBIENT_TEMPERATURE_UNIT_PREF,
  payload: { id },
});

export const linePressureUnitPref = (id) => ({
  type: LINE_PRESSURE_UNIT_PREF,
  payload: { id },
});

export const densityUnitPref = (id) => ({
  type: DENSITY_UNIT_PREF,
  payload: { id },
});

export const viscosityUnitPref = (id) => ({
  type: VISCOSITY_UNIT_PREF,
  payload: { id },
});

export const vaporPressureUnitPref = (id) => ({
  type: VAPOR_PRESSURE_UNIT_PREF,
  payload: { id },
});

export const atmosphericUnitPref = (id) => ({
  type: ATMOSPHERIC_UNIT_PREF,
  payload: { id },
});

export const gasOnlyPressureUnitPref = (id) => ({
  type: GAS_ONLY_PRESSURE_UNIT_PREF,
  payload: { id },
});

export const gasOnlyPressurePref = (id) => ({
  type: GAS_ONLY_PRESSURE_PREF,
  payload: { id },
});

export const gasOnlyTemperatureUnitPref = (id) => ({
  type: GAS_ONLY_TEMPERATURE_UNIT_PREF,
  payload: { id },
});

export const gasOnlyTemperaturePref = (id) => ({
  type: GAS_ONLY_TEMPERATURE_PREF,
  payload: { id },
});

export const gasOnlyDensityUnitPref = (id) => ({
  type: GAS_ONLY_DENSITY_UNIT_PREF,
  payload: { id },
});

export const sonicVelocityUnitPref = (id) => ({
  type: SONIC_VELOCITY_UNIT_PREF,
  payload: { id },
});

export const flowRateDecimalFactorPref = (id) => ({
  type: FLOW_RATE_DECIMAL_FACTOR,
  payload: { id },
});

export const flowRateDecimal = (id) => ({
  type: FLOW_RATE_DECIMAL,
  payload: { id },
});

export const temperatureDecimalFactorPref = (id) => ({
  type: TEMPERATURE_DECIMAL_FACTOR,
  payload: { id },
});

export const temperatureDecimal = (id) => ({
  type: TEMPERATURE_DECIMAL,
  payload: { id },
});

export const linePressureDecimalFactorPref = (id) => ({
  type: LINE_PRESSURE_DECIMAL_FACTOR,
  payload: { id },
});

export const linePressureDecimal = (id) => ({
  type: LINE_PRESSURE_DECIMAL,
  payload: { id },
});

export const decimalFactorPref = (id) => ({
  type: DECIMAL_FACTOR_PREF,
  payload: { id },
});

export const allOtherDecimal = (id) => ({
  type: ALL_OTHER_DECIMAL,
  payload: { id },
});

export const uomAutoConversionPref = (id) => ({
  type: UOM_AUTO_CONVERSION_PREF,
  payload: { id },
});

export const refreshPref = (id) => ({
  type: REFRESH_PREF,
  payload: { id },
});

export const resizingFlag = (id) => ({
  type: RESIZING_FLAG,
  payload: { id },
});

export const requestSizingResults = (id) => ({
  type: REQUEST_SIZING_RESULTS,
  payload: { id },
});

export const compareModelNames = (id) => ({
  type: COMPARE_MODEL_NAMES,
  payload: { id },
});

export const displaySizingResults = (id) => ({
  type: DISPLAY_SIZING_RESULTS,
  payload: { id },
});

export const languageList = (id) => ({
  type: LANGUAGE_LIST,
  payload: { id },
});

export const loginEmail = (id) => ({
  type: LOGIN_EMAIL,
  payload: { id },
});

export const compareSizingResults = (id) => ({
  type: COMPARE_SIZING_RESULTS,
  payload: { id },
});

export const compareDisableButton = (id) => ({
  type: COMPARE_DISABLE_BUTTON,
  payload: { id },
});

export const sizingResultsData = (id) => ({
  type: SIZING_RESULTS_DATA,
  payload: { id },
});

export const selectedRowData = (id) => ({
  type: SELECTED_ROW_DATA,
  payload: { id },
});

export const defaultRow = (id) => ({
  type: SET_DEFAULT_ROW,
  payload: { id },
});

export const translationData = (id) => ({
  type: TRANSLATION_DATA,
  payload: { id },
});

export const pickFluidsFromDbUnits = (id) => ({
  type: PICK_FLUIDS_FROM_DB_UNITS,
  payload: { id },
});

export const conductivityUnits = (id) => ({
  type: CONDUCTIVITY_UNITS,
  payload: { id },
});

export const fluidServiceUnits = (id) => ({
  type: FLUID_SERVICE_UNITS,
  payload: { id },
});

export const fluidSelectionFlag = (id) => ({
  type: FLUID_SELECTION_FLAG,
  payload: { id },
});

export const compositionMethodFlag = (id) => ({
  type: COMPOSITION_METHOD_FLAG,
  payload: { id },
});

export const fluidSelectionSection = (id) => ({
  type: FLUID_SELECTION_SECTION,
  payload: { id },
});

export const compositionMethodSection = (id) => ({
  type: COMPOSITION_METHOD_SECTION,
  payload: { id },
});

export const fluidStateUnits = (id) => ({
  type: FLUID_STATE_UNITS,
  payload: { id },
});

export const fluidSourceUnits = (id) => ({
  type: FLUID_SOURCE_UNITS,
  payload: { id },
});

export const savedPreferences = (id) => ({
  type: SAVED_PREFERENCES,
  payload: { id },
});

export const unitsData = (id) => ({
  type: UNITS_DATA,
  payload: { id },
});

export const productFamilyUnits = (id) => ({
  type: PRODUCT_FAMILY_UNITS,
  payload: { id },
});

export const lineSizeUnits = (id) => ({
  type: LINE_SIZE_UNITS,
  payload: { id },
});

export const processLineSizeUnits = (id) => ({
  type: PROCESS_LINE_SIZE_UNITS,
  payload: { id },
});

export const scheduleUnits = (id) => ({
  type: SCHEDULE_UNITS,
  payload: { id },
});

export const clearMySizings = (id) => ({
  type: CLEAR_MY_SIZINGS,
  payload: { id },
});

export const userLanguage = (id) => ({
  type: USER_LANGUAGE,
  payload: { id },
});

export const clearUom = (id) => ({
  type: CLEAR_UOM,
  payload: { id },
});

export const errorMessage = (id) => ({
  type: ERROR_MESSAGE,
  payload: { id },
});

export const naturalGasComponents = (id) => ({
  type: NATURAL_GAS_COMPONENTS,
  payload: { id },
});

export const sizingErrorMessage = (id) => ({
  type: SIZING_ERROR_MESSAGE,
  payload: { id },
});

export const ApplicationNameError = (id) => ({
  type: APPLICATION_NAME_ERROR,
  payload: { id },
});

export const reportFlag = (id) => ({
  type: REPORT_FLAG,
  payload: { id },
});

export const internetMode = (id) => ({
  type: INTERNET_MODE,
  payload: { id },
});

export const transError = (id) => ({
  type: TRANSLATION_ERROR,
  payload: { id },
});

export const clearAllField = (id) => ({
  type: CLEAR_ALL_FIELD,
  payload: { id },
});

export const configWarningMsg = (id) => ({
  type: CONFIG_WARNING_MSG,
  payload: { id },
});
export const sonicVelocityWarningMsg = (id) => ({
  type: SONIC_VELOCITY_WARNING_MSG,
  payload: { id },
});
export const sonicVelocityEntered = (id) => ({
  type: SONIC_VELOCITY_ENTERED,
  payload: { id },
});
export const ambTempWarningMsg = (id) => ({
  type: AMBIENT_TEMP_WARNING_MSG,
  payload: { id },
});

export const highTempWarningMsg = (id) => ({
  type: HIGH_TEMP_WARNING_MSG,
  payload: { id },
});

export const highTemperatureErrorMsg = (id) => ({
  type: HIGH_TEMPERATURE_ERROR_MSG,
  payload: { id },
});

export const getSizingData = (id) => ({
  type: GET_SIZING_DATA,
  payload: { id },
});

export const mySizingResults = (id) => ({
  type: MY_SIZING_RESULTS,
  payload: { id },
});

export const getSizingFormattedData = (id) => ({
  type: GET_SIZING_FORMATTED_DATA,
  payload: { id },
});

export const tubeVelocityUnit = (id) => ({
  type: TUBE_VELOCITY_UNIT,
  payload: { id },
});

export const queryParams = (id) => ({
  type: QUERY_PARAMS,
  payload: { id },
});

export const savedSizingLoad = (id) => ({
  type: SAVED_SIZING_LOAD,
  payload: { id },
});
export const flowRateMinClassList = (id) => ({
  type: FLOW_RATE_MIN_CLASS_LIST,
  payload: { id },
});

export const flowRateNormClassList = (id) => ({
  type: FLOW_RATE_NORM_CLASS_LIST,
  payload: { id },
});

export const flowRateMaxClassList = (id) => ({
  type: FLOW_RATE_MAX_CLASS_LIST,
  payload: { id },
});

export const flowRateFullClassList = (id) => ({
  type: FLOW_RATE_FULL_CLASS_LIST,
  payload: { id },
});

export const flowRateMinErrors = (id) => ({
  type: FLOW_RATE_MIN_ERRORS,
  payload: { id },
});

export const flowRateNormErrors = (id) => ({
  type: FLOW_RATE_NORM_ERRORS,
  payload: { id },
});

export const flowRateMaxErrors = (id) => ({
  type: FLOW_RATE_MAX_ERRORS,
  payload: { id },
});

export const flowRateFullErrors = (id) => ({
  type: FLOW_RATE_FULL_ERRORS,
  payload: { id },
});

export const flowRateGasDropdownArray = (id) => ({
  type: FLOW_RATE_GAS_DROPDOWN_ARRAY,
  payload: { id },
});

export const flowRateDropdownArray = (id) => ({
  type: FLOW_RATE_DROPDOWN_ARRAY,
  payload: { id },
});

export const linePressMinClassList = (id) => ({
  type: LINE_PRESS_MIN_CLASS_LIST,
  payload: { id },
});

export const linePressNormClassList = (id) => ({
  type: LINE_PRESS_NORM_CLASS_LIST,
  payload: { id },
});

export const linePressMaxClassList = (id) => ({
  type: LINE_PRESS_MAX_CLASS_LIST,
  payload: { id },
});

export const linePressFullClassList = (id) => ({
  type: LINE_PRESS_FULL_CLASS_LIST,
  payload: { id },
});

export const linePressMinErrors = (id) => ({
  type: LINE_PRESS_MIN_ERRORS,
  payload: { id },
});

export const linePressNormErrors = (id) => ({
  type: LINE_PRESS_NORM_ERRORS,
  payload: { id },
});

export const linePressMaxErrors = (id) => ({
  type: LINE_PRESS_MAX_ERRORS,
  payload: { id },
});

export const linePressFullErrors = (id) => ({
  type: LINE_PRESS_FULL_ERRORS,
  payload: { id },
});

export const linePressDropdownArray = (id) => ({
  type: LINE_PRESS_DROPDOWN_ARRAY,
  payload: { id },
});

export const temperatureMinClassList = (id) => ({
  type: TEMPERATURE_MIN_CLASS_LIST,
  payload: { id },
});

export const temperatureNormClassList = (id) => ({
  type: TEMPERATURE_NORM_CLASS_LIST,
  payload: { id },
});

export const temperatureMaxClassList = (id) => ({
  type: TEMPERATURE_MAX_CLASS_LIST,
  payload: { id },
});

export const temperatureFullClassList = (id) => ({
  type: TEMPERATURE_FULL_CLASS_LIST,
  payload: { id },
});

export const temperatureMinErrors = (id) => ({
  type: TEMPERATURE_MIN_ERRORS,
  payload: { id },
});

export const temperatureNormErrors = (id) => ({
  type: TEMPERATURE_NORM_ERRORS,
  payload: { id },
});

export const wettedMaterial = (id) => ({
  type: WETTED_MATERIAL,
  payload: { id },
});

export const wettedMaterialValue = (id) => ({
  type: WETTED_MATERIAL_VALUE,
  payload: { id },
});

export const wettedMaterialLabel = (id) => ({
  type: WETTED_MATERIAL_LABEL,
  payload: { id },
});

export const wettedMaterialUnits = (id) => ({
  type: WETTED_MATERIAL_UNITS,
  payload: { id },
});

export const wetterMaterialPref = (id) => ({
  type: WETTED_MATERIAL_PREF,
  payload: { id },
});

export const warningLimit = (id) => ({
  type: WARNING_LIMIT,
  payload: { id },
});

export const linePressRow = (id) => ({
  type: LINE_PRESS_ROW_PSGI,
  payload: { id },
});

export const setCalculationFlag = (id) => ({
  type: SET_CALC_FLAG,
  payload: { id },
});

export const sizingResultsClicked = (id) => ({
  type: SIZING_RESULTS_CLICKED,
  payload: { id },
});

export const tempRowF = (id) => ({
  type: TEMP_ROW_F,
  payload: { id },
});

export const temperatureMaxErrors = (id) => ({
  type: TEMPERATURE_MAX_ERRORS,
  payload: { id },
});

export const temperatureFullErrors = (id) => ({
  type: TEMPERATURE_FULL_ERRORS,
  payload: { id },
});

export const temperatureDropdownArray = (id) => ({
  type: TEMPERATURE_DROPDOWN_ARRAY,
  payload: { id },
});

export const ambientTemperatureMinClassList = (id) => ({
  type: AMBIENT_TEMPERATURE_MIN_CLASS_LIST,
  payload: { id },
});

export const ambientTemperatureNormClassList = (id) => ({
  type: AMBIENT_TEMPERATURE_NORM_CLASS_LIST,
  payload: { id },
});

export const ambientTemperatureMaxClassList = (id) => ({
  type: AMBIENT_TEMPERATURE_MAX_CLASS_LIST,
  payload: { id },
});

export const ambientTemperatureFullClassList = (id) => ({
  type: AMBIENT_TEMPERATURE_FULL_CLASS_LIST,
  payload: { id },
});

export const ambientTemperatureMinErrors = (id) => ({
  type: AMBIENT_TEMPERATURE_MIN_ERRORS,
  payload: { id },
});

export const ambientTemperatureNormErrors = (id) => ({
  type: AMBIENT_TEMPERATURE_NORM_ERRORS,
  payload: { id },
});

export const ambientTemperatureMaxErrors = (id) => ({
  type: AMBIENT_TEMPERATURE_MAX_ERRORS,
  payload: { id },
});

export const ambientTemperatureFullErrors = (id) => ({
  type: AMBIENT_TEMPERATURE_FULL_ERRORS,
  payload: { id },
});
export const ambientTemperatureDropdownArray = (id) => ({
  type: AMBIENT_TEMPERATURE_DROPDOWN_ARRAY,
  payload: { id },
});

export const requiredFields = (id) => ({
  type: REQUIRED_FIELDS,
  payload: { id },
});

export const measurementTypeContainerClass = (id) => ({
  type: MEASUREMENT_TYPE_CONTAINER_CLASS,
  payload: { id },
});
