import {
  CLEAR_MY_SIZINGS,
  GET_SIZING_DATA,
  MY_SIZING_RESULTS,
  GET_SIZING_FORMATTED_DATA
} from '../actionTypes';

const initialState = {
  clear_my_sizings: false,
  get_sizing_data: [],
  my_sizing_results: [],
  get_Sizing_formatted_Data: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CLEAR_MY_SIZINGS: {
      return {
        ...state,
        clear_my_sizings: action.payload.id
      }
    }
    case GET_SIZING_DATA: {
      return {
        ...state,
        get_sizing_data: action.payload.id
      }
    }
    case MY_SIZING_RESULTS:{
      return {
        ...state,
        my_sizing_results: action.payload.id
      }
    }
    case GET_SIZING_FORMATTED_DATA:{
      return {
        ...state,
        get_Sizing_formatted_Data : action.payload.id
      }
    }
    default: {
      return state;
    }
  }
}

