import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown, Form, ListGroup } from 'react-bootstrap';
import '../../styles/App.scss';
import Logo from '../../images/emerson-logo.png';
import {
  loginEmail,
  languageList,
  translationData,
  userLanguage,
  internetMode
} from '../../redux/actions';
import { connect } from 'react-redux';
import axios from 'axios';
import { OFFLINE_SYNC } from '../../constants/constants';
import LanguageModal from './LanguageModal';
const AppMode = window.email ? window.email : "";

const AppHeader = (props) => {
  const localData = localStorage.getItem("AppLogin");
  const [modalShow, setModalShow] = useState(false);
  const [language, setLanguage] = useState("English");
  const [languagePass, setLanguagePass] = useState("English");

  const LanguageChange = (data) => {
    setLanguagePass(data);
    setModalShow(true);
    props.userLanguage(data)
  };
  const testFun = () => setLanguage(languagePass.label);

  const internetCheck = () => {
    if (navigator.onLine) {
      props.internetMode("Online")
    } else {
      props.internetMode("")
    }
  }

  const scheduleTask = () => {
    
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}${OFFLINE_SYNC}`,
      headers: {
        "Content-Type": "application/json",
      },
      xsrfHeaderName: "X-CSRFToken",
      xsrfCookieName: "csrftoken",
      data: {
        language_code: 'US',
      },
    })
      .then((response) => {
        const success = response;
      })
  };


  useEffect(() => {
    setInterval(() => {
      internetCheck();
    }, 4000)
   // setInterval(() => {
   //   scheduleTask();
   // }, 240000)
  }, [])

  return (
    <>
      <LanguageModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        loadlanguage={languagePass}
        testFun={() => testFun()}
      />
      <Navbar className='AppHeader'>
        <Navbar.Brand>
          <img
            src={Logo}
            width={120}
            height={75}
            className='d-inline-block align-top'
            alt='Emerson'
          />
        </Navbar.Brand>
        <Nav className='mr-auto'></Nav>

        {AppMode === "Offline" ? (
          !localData ? (
            ""
          ) : (
              <ListGroup className='HeaderList'>
                <ListGroup.Item>
                  <Form inline>
                    View in: {language} [
                  <NavDropdown
                      title='Change'
                      id='basic-nav-dropdown'
                      className='justify-content-end HeaderDropDown'
                    >
                      {props.language_list.map((option, index) => (
                        <NavDropdown.Item
                          key={index}
                          onClick={() => LanguageChange(option)}
                        >
                          {option.label} |{" "}
                          <span className='languageText'>
                            {option.description}
                          </span>
                        </NavDropdown.Item>
                      ))}
                    </NavDropdown>
                  ]
                </Form>
                </ListGroup.Item>
                <ListGroup.Item>
                  {AppMode === "Offline" ? localData : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  {props.internet_check ? <span><i className="OnlineCircle"></i>&nbsp;You are online</span> :
                    <span><i className="OfflineCircle"></i>You are offline</span>}
                </ListGroup.Item>
              </ListGroup>
            )
        ) : (
            ""
          )}
      </Navbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  language_list: state.LanguageList.language_list,
  login_mail: state.OfflineFlowLogin.login_mail,
  translation_data: state.TranslationData.translation_data,
  internet_check: state.AppHeader.internet_mode,
});
export default connect(mapStateToProps, {
  languageList,
  loginEmail,
  translationData,
  userLanguage,
  internetMode,
})(AppHeader);
