
  
  export const getLanguageCode = (language_code,locale) => {
      let result;
      switch (language_code) {
        case 'US':
          result = 'en-us'; break;
        case 'F':
          result = 'fr-fr'; break;
        case "I":
          result =  'it-it'; break;
        case 'ESA':
          result =  'es-es'; break;
        case 'D':
          result =  'de-de'; break;
        case 'JA':
          result =  'ja-jp'; break;
        case 'KO':
          result =  'ko-kr'; break;
        case 'PTB':
          result =  'pt-br'; break;
        case 'RU':
          result =  'ru-ru'; break;
        case 'ZHS':
          result =  'zh-cn'; break;
        default: result = 'en-us';
      }
      return locale?locale:result;
    } 
  
  