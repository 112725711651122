import {
  FLOW_RATE_UNIT_FOR_LIQUID_SLURRY_PREF,
  FLOW_RATE_UNIT_FOR_GAS_PREF,
  TEMPERATURE_UNIT_PREF,
  AMBIENT_TEMPERATURE_UNIT_PREF,
  LINE_PRESSURE_UNIT_PREF,
  DENSITY_UNIT_PREF,
  VISCOSITY_UNIT_PREF,
  VAPOR_PRESSURE_UNIT_PREF,
  ATMOSPHERIC_UNIT_PREF,
  GAS_ONLY_PRESSURE_UNIT_PREF,
  GAS_ONLY_PRESSURE_PREF,
  GAS_ONLY_TEMPERATURE_UNIT_PREF,
  GAS_ONLY_TEMPERATURE_PREF,
  GAS_ONLY_DENSITY_UNIT_PREF,
  SONIC_VELOCITY_UNIT_PREF,
  DECIMAL_FACTOR_PREF,
  ALL_OTHER_DECIMAL,
  FLOW_RATE_DECIMAL_FACTOR,
  WETTED_MATERIAL_PREF,
  FLOW_RATE_DECIMAL,
  TEMPERATURE_DECIMAL_FACTOR,
  TEMPERATURE_DECIMAL,
  LINE_PRESSURE_DECIMAL_FACTOR,
  LINE_PRESSURE_DECIMAL,
  UOM_AUTO_CONVERSION_PREF,
  REFRESH_PREF,
  SAVED_PREFERENCES,
  CLEAR_PREF_VARIABLES,
  QUERY_PARAMS,
  SAVED_PREFERENCES_FLAG
} from '../actionTypes';

const initialState = {
  flow_rate_decimal: '4',
  temperature_decimal: '4',
  line_pressure_decimal: '4',
  all_other_decimal: '4',
  flow_rate_unit_for_liquid_slurry_pref: 'USGPH',
  flow_rate_unit_for_gas_pref: 'KG/HR',
  temperature_unit_pref: 'F',
  ambient_temperature_unit_pref: 'F',
  line_pressure_unit_pref: 'PSIG',
  density_unit_pref: 'LB/GAL(US)',
  viscosity_unit_pref: 'CP',
  vapor_pressure_unit_pref: 'PSIA',
  atmospheric_unit_pref: 'PSIA',
  gas_only_pressure_unit_pref: 'BAR-A',
  gas_only_pressure_pref: '1.01325348872',
  gas_only_temperature_unit_pref: 'C',
  gas_only_temperature_pref: '15.555555555555555',
  gas_only_density_unit_pref: 'KG/M3',
  sonic_velocity_unit_pref: 'FT/SEC',
  decimal_factor_pref: 'up to 4 places',
  flow_rate_decimal_factor: 'up to 4 places',
  temperature_decimal_factor:'up to 4 places',
  line_pressure_decimal_factor: 'up to 4 places',
  uom_auto_conversion_pref: 'ENABLED',
  wetted_material_pref: '316L',
  refresh_pref: false,
  saved_preferences: {},
  query_params:{}, 
  saved_preferences_flag : false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FLOW_RATE_UNIT_FOR_LIQUID_SLURRY_PREF: {
      return {
        ...state,
        flow_rate_unit_for_liquid_slurry_pref: action.payload.id,
      };
    }
    case FLOW_RATE_UNIT_FOR_GAS_PREF: {
      return {
        ...state,
        flow_rate_unit_for_gas_pref: action.payload.id,
      };
    }
    case TEMPERATURE_UNIT_PREF: {
      return {
        ...state,
        temperature_unit_pref: action.payload.id,
      };
    }
    case AMBIENT_TEMPERATURE_UNIT_PREF: {
      return {
        ...state,
        ambient_temperature_unit_pref: action.payload.id,
      };
    }
    case LINE_PRESSURE_UNIT_PREF: {
      return {
        ...state,
        line_pressure_unit_pref: action.payload.id,
      };
    }
    case DENSITY_UNIT_PREF: {
      return {
        ...state,
        density_unit_pref: action.payload.id,
      };
    }
    case VISCOSITY_UNIT_PREF: {
      return {
        ...state,
        viscosity_unit_pref: action.payload.id,
      };
    }
    case VAPOR_PRESSURE_UNIT_PREF: {
      return {
        ...state,
        vapor_pressure_unit_pref: action.payload.id,
      };
    }
    case QUERY_PARAMS: {
      return {
        ...state,
        query_params: action.payload.id,
      };
    }
    case ATMOSPHERIC_UNIT_PREF: {
      return {
        ...state,
        atmospheric_unit_pref: action.payload.id,
      };
    }
    case GAS_ONLY_PRESSURE_UNIT_PREF: {
      return {
        ...state,
        gas_only_pressure_unit_pref: action.payload.id,
      };
    }
    case GAS_ONLY_PRESSURE_PREF: {
      return {
        ...state,
        gas_only_pressure_pref: action.payload.id,
      };
    }
    case GAS_ONLY_TEMPERATURE_UNIT_PREF: {
      return {
        ...state,
        gas_only_temperature_unit_pref: action.payload.id,
      };
    }
    case GAS_ONLY_TEMPERATURE_PREF: {
      return {
        ...state,
        gas_only_temperature_pref: action.payload.id,
      };
    }
    case GAS_ONLY_DENSITY_UNIT_PREF: {
      return {
        ...state,
        gas_only_density_unit_pref: action.payload.id,
      };
    }
    case SONIC_VELOCITY_UNIT_PREF: {
      return {
        ...state,
        sonic_velocity_unit_pref: action.payload.id,
      };
    }
    case FLOW_RATE_DECIMAL_FACTOR: {
      return {
        ...state,
        flow_rate_decimal_factor: action.payload.id,
      };
    }
    case FLOW_RATE_DECIMAL: {
      return {
        ...state,
        flow_rate_decimal: action.payload.id,
      };
    }
    case TEMPERATURE_DECIMAL_FACTOR: {
      return {
        ...state,
        temperature_decimal_factor: action.payload.id,
      };
    }
    case TEMPERATURE_DECIMAL: {
      return {
        ...state,
        temperature_decimal: action.payload.id,
      };
    }
    case LINE_PRESSURE_DECIMAL_FACTOR: {
      return {
        ...state,
        line_pressure_decimal_factor: action.payload.id,
      };
    }
   case LINE_PRESSURE_DECIMAL: {
    return {
      ...state,
      line_pressure_decimal: action.payload.id,
    };
  }
    case DECIMAL_FACTOR_PREF: {
      return {
        ...state,
        decimal_factor_pref: action.payload.id,
      };
    }
    case ALL_OTHER_DECIMAL: {
      return {
        ...state,
        all_other_decimal: action.payload.id,
      };
    }
    case UOM_AUTO_CONVERSION_PREF: {
      return {
        ...state,
        uom_auto_conversion_pref: action.payload.id,
      };
    }
    case REFRESH_PREF: {
      return {
        ...state,
        refresh_pref: action.payload.id,
      };
    }

    case WETTED_MATERIAL_PREF: {
      return { 
        ...state,
        wetted_material_pref: action.payload.id,
      }
    }

    case SAVED_PREFERENCES: {
      return {
        ...state,
        saved_preferences: action.payload.id
      }
    }
    case SAVED_PREFERENCES_FLAG: {
      return {
        ...state,
        saved_preferences_flag: action.payload.id
      }
    }
    case CLEAR_PREF_VARIABLES: {
      return initialState
    }
    
    default: {
      return state;
    }
  }
}
