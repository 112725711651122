import React from 'react';
import {
  Modal
} from 'react-bootstrap';
import '../../styles/App.scss';
import './modal.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CustomButton } from '../CustomButton/CustomButton';
import CustomTranslation from '../CustomTranslation/CustomTranslation';

const ChangeOperatingConditionsModal = props => {
  return (
    <>
      <Modal  size='md'centered aria-labelledby="contained-modal-title-vcenter" show={props.show} onHide={props.handleClose} animation={false}>
        <Modal.Header className='modal-header-fpa' closeButton>
          <Modal.Title className="modal-title"><CustomTranslation data="XXSPECIFY_MMI_SR_LBL_RESULT" /></Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-fpa'>
          <p className="main-text"><CustomTranslation data="XXSPECIFY_MMI_SR_LBL_DISCARD" />?</p>       
        </Modal.Body>
        <Modal.Footer>
        <CustomButton
              classNames="btn btn-close-modal btn-green"
              label="Apply"
              onClick={props.handleApply}
              arrow="right"
              size="lg"
            />
          <CustomButton
              classNames="btn btn-close-modal btn-green"
              label="Discard"
              onClick={props.handleDiscard}
              arrow="right"
              size="lg"
            />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChangeOperatingConditionsModal;