import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CustomButton } from './components/CustomButton/CustomButton';
const NotFound = () => {
  let history = useHistory();
  return (
    <section className="container">
      <div className='button-nav'>
       <CustomButton
                classNames='btn btn-nav btn-arrow btn-white'
                label="BACK TO SIZING"
                onClick={() => history.push('/')}
                variant='outline-dark'
                arrow='right'
                size='lg'
                position='right'
              /></div>
      <h1 >
        <i className="fas fa-exclamation-triangle" />404 Page Not Found
      </h1>
      <p className=""></p>
    </section>
  );  
};

export default NotFound;