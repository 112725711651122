import React, { useState, useRef, useEffect } from 'react';
import './toggleSwitch.scss';
import { connect } from 'react-redux';
import { advancedLayout } from '../../redux/actions';

const CustomToggleSwitch = (props) => {
  const { name, id, label, onChange, checked, Basic } = props;
  const [defaultChecked, setDefaultChecked] = useState(true);
  const advBasicToggle = useRef(props.advanced_layout);

  useEffect(() => {
    advBasicToggle.current = props.advanced_layout;
    setDefaultChecked(props.advanced_layout);
  }, [props.advanced_layout]);
  return (
    <div className='toggle-switch'>
      <input
        type='checkbox'
        className='toggle-switch-checkbox'
        name='{name}'
        onChange={onChange}
        checked={!defaultChecked}
        defaultChecked={!props.advanced_layout}
        id={id}
      />

      <label className='toggle-switch-label' htmlFor={id}>
        <span data-yes={Basic} data-no={Basic} className='toggle-switch-inner' />
        <span className='toggle-switch-switch' />
      </label>
    </div>
  );
};

const mapStateToProps = (state) => ({
  advanced_layout: state.Layout.advanced_layout,
});

export default connect(mapStateToProps, {
  advancedLayout,
})(CustomToggleSwitch);
