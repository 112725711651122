import React, { useState } from 'react';
import {
  Col,
  Row
} from 'react-bootstrap';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import {
} from '../../redux/actions';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import CustomModal from '../../components/CustomModals/UploadModal';


const ToolkitUpload = () => {

  const [fileName, setFileName] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onFileSearch = event => {
    setFileName(event.target.files[0].name);
  }

  const uploadSizing = () => {
    setShow(true);
  }

  const downloadTemplate = () => {
    // TBD
  }

  return (
    <>
      <CustomModal show={show} handleClose={handleClose} handleShow={handleShow} />
      <div className="toolkit-upload">
        <h4 className="title">Toolkit Upload</h4>
        <div className="upload-page">
          <div className="toolkit-panel">
            <div className="input-section">
              <Row className="search-container">
                <Col md={8} sm={6}>
                  <span className="file-name">{fileName}</span>
                </Col>
                <Col md={4} sm={6}>
                  <label className="upload-button btn-green" htmlFor="upload">SEARCH</label>
                  <input id="upload" type="file" onChange={onFileSearch} />
                </Col>
              </Row>
              <Row className="download-upload">
                <Col md={4} sm={6}>
                  <CustomButton
                    classNames="btn btn-toolkit btn-green btn-download"
                    label="DOWNLOAD TEMPLATE"
                    onClick={() => downloadTemplate()}
                    size="lg"
                  />
                </Col>
                <Col md={4} sm={0}>
                </Col>
                <Col md={4} sm={6}>
                  <CustomButton
                    classNames="btn btn-toolkit btn-green btn-upload"
                    label="UPLOAD SIZING"
                    onClick={() => uploadSizing()}
                    size="lg"
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
});

export default connect(
  mapStateToProps,
  {
  }
)(ToolkitUpload);
