import React from 'react';
import {
} from 'react-bootstrap';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

export const RightNavListRequiredText = props => {
  const { required, disabled, label, value, unitsDropdown } = props;
  const valueExists = !isNaN(parseFloat(value));
  const textColor = required && !valueExists ? 'text-red' : 'text-normal';
  const showLabel = !disabled && (required || valueExists);

  return (
    <>
      {!showLabel ||
        <li className={textColor}>{label}: {valueExists ? parseFloat(value).toFixed(4) : ''} {valueExists && unitsDropdown ? unitsDropdown : ''}
        </li>
      }
    </>
  )
};
