import React, { useState, useEffect, useRef } from "react";
import { Container, Col, Row } from "react-bootstrap";
import "../../styles/App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import SizingResults from "../SizingResults/SizingResults";
import MeasurementType from "../MeasurementType";
import EquipmentSelection from "../EquipmentSelection";
import FluidSelection from "../FluidSelection/FluidSelection";
import ApplicationRequirements from "../ApplicationRequirements";
import ProcessVariables from "../ProcessVariables/ProcessVariables";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import ChangeOperatingConditionsModal from "../../components/CustomModals/ChangeOperatingConditionsModal";
import SizingResultErrorModal from "../../components/CustomModals/SizingResultErrorModal";
import { useMediaQuery } from "react-responsive";
import { usePrevious } from "../../UtilityFuntions/usePrevious";
import { connect } from "react-redux";
import {
  setOperatingDataErrorMessages,
  disableSizingResultsButton,
  requestSizingResults,
  displaySizingResults,
  sizingErrorMessage,
  wasClicked,
  operatingConditions,
  resetOperatingConditions,
  searchParameters,
  flowRateNormClassList,
  flowRateMinClassList,
  flowRateMaxClassList,
  flowRateFullClassList,
  flowRateFullErrors,
  linePressMinClassList,
  linePressNormClassList,
  linePressMaxClassList,
  linePressFullClassList,
  linePressFullErrors,
  temperatureMinClassList,
  temperatureNormClassList,
  temperatureMaxClassList,
  temperatureFullClassList,
  ambientTemperatureMinClassList,
  ambientTemperatureNormClassList,
  ambientTemperatureMaxClassList,
  ambientTemperatureFullClassList,
  lineSizeError,
  flowRateMinErrors,
  flowRateNormErrors,
  flowRateMaxErrors,
  linePressMinErrors,
  linePressNormErrors,
  linePressMaxErrors,
  temperatureMinErrors,
  temperatureNormErrors,
  temperatureMaxErrors,
  temperatureFullErrors,
  ambientTemperatureMinErrors,
  ambientTemperatureNormErrors,
  ambientTemperatureMaxErrors,
  ambientTemperatureFullErrors,
  savedSizingLoad,
  getSizingData,
  minimumDensityError,
  normalDensityError,
  maximumDensityError,
  normalViscosityError,
  naturalGasBorderError,
  maximumViscosityError,
  gasDensityError,
  gasTemperatureError,
  gasPressureError,
  customBorderError,
  gasCustomCompressibilityError,
  molecularWeightError,
  specificGravityError,
  baseCompressibilityError,
  revertFlag,
  applyChanges,
  showMoreRatesData,
  selectedRowData,
  sizingResultsData,
  queryParams,
  warningLimit,
  setCalculationFlag,
  sizingResultsClicked,
  resizingDiscardFlag,
  measurementTypeContainerClass,
} from "../../redux/actions";
import CustomTranslation from "../../components/CustomTranslation/CustomTranslation";
import CustomTranslationText from "../../components/CustomTranslation/CustomTranslationText";
const AdvancedPage = (props) => {
  const [missingValuesState, setMissingValue] = useState([]);
  const [errorValues, setErrorValues] = useState([]);
  const apiErrors = useRef([]);
  const [searchFields, setSearchFields] = useState({});
  const [isApplied, setIsApplied] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const scrollDestination = useRef(null);
  const opDiff = useRef([]);
  const [showChangeOperatingConditionsModal, setShowChangeOperatingConditionsModal] = useState(false);
  const handleCloseChangeOperatingConditionsModal = () => setShowChangeOperatingConditionsModal(false);
  const handleShowChangeOperatingConditionsModal = () => setShowChangeOperatingConditionsModal(true);
  const requiredErrorField = "border-required border-error oc-input";
  const winHeight = useMediaQuery({ query: `(max-height: 800px)` });

  useEffect(() => {
    setIsDisabled(props.disable_sizing_results_button);
  }, [props.disableSizingResultsButton, props.disable_sizing_results_button]);
  const handleCloseOC = () => {
    setShowChangeOperatingConditionsModal(false);
    executeScroll();
  };

  const executeScroll = () => {
    scrollDestination.current.scrollIntoView();
  };

  const handleDiscardSizingInput = () => {
    let diffTable = opDiff.current;
    props.resetOperatingConditions({ diff: diffTable, Fields: searchFields });
    handleCloseChangeOperatingConditionsModal();
    setIsDirty(false);
    props.applyChanges(false);
    opDiff.current = [];
    executeScroll();
  };
  const handleApply = async () => {
    await sizingResults();
    await handleCloseChangeOperatingConditionsModal();
    await setIsDirty(false);
    props.applyChanges(true);
    if (Object.keys(props.get_sizing_data).length > 0) {
      let query_params = { ...props.query_params };
      query_params.pa_ref_id = props.get_sizing_data[0].transaction_id;
      props.queryParams(query_params);
    }
    await props.getSizingData([]);
  };

  useEffect(() => {
    window.addEventListener("scroll", pop);
    return () => window.removeEventListener("scroll", pop);
  });

  const pop = () => {
    if (window.scrollY > (winHeight ? 1300 : 1050) && isDirty && props.display_sizing_results === true) {
      handleShowChangeOperatingConditionsModal();
      props.revertFlag(true);
    } else {
      handleCloseChangeOperatingConditionsModal();
    }
  };
  // clear error array on clear
  useEffect(() => {
    setErrorValues([]);
    setMissingValue([]);
    apiErrors.current = [];
  }, [props.clear_all_field]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (props.normal_viscosity_convertion_error === true && props.was_clicked === true) {
        let missingValues = [];
        if (props.min_viscosity_convertion_error) {
          missingValues.push("XXSPECIFY_MMI_PRE_VISC_VALD");
        }

        if (props.normal_viscosity_convertion_error) {
          missingValues.push("XXSPECIFY_MMI_PRE_VISC_VALD");
          //props.normalViscosityError(true);
        }

        if (props.max_viscosity_convertion_error) {
          missingValues.push("XXSPECIFY_MMI_PRE_VISC_VALD");
        }
        if (missingValues.length > 0) {
          if (props.min_viscosity_convertion_error || props.normal_viscosity_convertion_error || props.max_viscosity_convertion_error) {
            setMissingValue(["XXSPECIFY_MMI_PRE_VISC_VALD"]);
          }
        }
        props.sizingErrorMessage(true);
        props.requestSizingResults(false);
        props.displaySizingResults(false);
        props.requestSizingResults(false);
        props.displaySizingResults(false);
        //sizingResults()
        //props.sizingErrorMessage(true);
      }
    }, 1000);
    return () => {
      // clears timeout before running the new effect
      clearTimeout(timeout);
    };
  }, [props.normal_viscosity_convertion_error, props.min_viscosity_convertion_error, props.max_viscosity_convertion_error]);
  function areNotEqual() {
    if (props.was_clicked === false) {
      let diff = [];
      const keys = Object.keys(props.operating_conditions);

      if (keys.length > 1) {
        for (const key of keys) {
          const val1 = props.operating_conditions[key];
          const val2 = searchFields[key];
          if (val1 !== val2) {
            diff.push(key);
          }
        }

        opDiff.current = diff;
      }
    }
  }

  useEffect(() => {
    if (!props.resizing_discard_flag) {
      areNotEqual();
    }
    if (opDiff.current.length === 0) {
      setIsDirty(false);
      props.applyChanges(false);
    } else {
      setIsDirty(true);
      props.applyChanges(true);
    }
  }, [props.operating_conditions]);

  useEffect(() => {
    if (!props.resizing_discard_flag) {
      opDiff.current = [];
      setSearchFields(props.operating_conditions);
    }
  }, [props.resizing_discard_flag]);

  useEffect(() => {
    setIsDirty(false);
    opDiff.current = [];
    props.resetOperatingConditions({});
    setSearchFields({});
    props.requestSizingResults(false);
    props.displaySizingResults(false);

    //.searchParameters({})
  }, [props.saved_preferences]);

  useEffect(() => {
    if (props.saved_sizing_load) {
      setIsDirty(false);
      opDiff.current = [];
      props.resetOperatingConditions({});
      props.requestSizingResults(true);
      props.wasClicked(true);
      props.displaySizingResults(true);
      handleCloseChangeOperatingConditionsModal();
      props.savedSizingLoad(false);
      setTimeout(() => {
        opDiff.current = [];
      }, 4000);
    }
  }, [props.saved_sizing_load]);
  useEffect(() => {
    if (props.was_clicked === true && isApplied) {
      // Sets search fields to be compared against updating operating_conditions)
      setSearchFields(props.operating_conditions);
      // Sets Post-Button results
      props.searchParameters(props.operating_conditions);
    }
  }, [props.was_clicked, props.operating_conditions]);
  useEffect(() => {
    if (Object.keys(props.get_sizing_data).length > 0 && props.saved_sizing_load) {
      // Sets search fields to be compared against updating operating_conditions)
      props.sizingResultsClicked(true);
      props.setCalculationFlag(true);
      sizingResults();
      props.sizingResultsClicked(false);
      setSearchFields(props.operating_conditions);
      // Sets Post-Button results
      props.searchParameters(props.operating_conditions);
      props.resizingDiscardFlag(true);
    }
  }, [props.get_sizing_data, props.saved_sizing_load]);

  const updateTableErrors = (updateId, updateError, updateClass) => {
    switch (updateId) {
      case "flow-rate-min":
        props.flowRateMinErrors(updateError);
        props.flowRateMinClassList(updateClass);
        break;
      case "flow-rate-normal":
        props.flowRateNormErrors(updateError);
        props.flowRateNormClassList(updateClass);
        break;
      case "flow-rate-max":
        props.flowRateMaxErrors(updateError);
        props.flowRateMaxClassList(updateClass);
        break;
      case "flow-rate-full":
        props.flowRateFullErrors(updateError);
        props.flowRateFullClassList(updateClass);
        break;
      case "line-press-min":
        props.linePressMinErrors(updateError);
        props.linePressMinClassList(updateClass);
        break;
      case "line-press-normal":
        props.linePressNormErrors(updateError);
        props.linePressNormClassList(updateClass);
        break;
      case "line-press-max":
        props.linePressMaxErrors(updateError);
        props.linePressMaxClassList(updateClass);
        break;
      case "line-press-full":
        props.linePressFullErrors(updateError);
        props.linePressFullClassList(updateClass);
        break;
      case "temp-min":
        props.temperatureMinErrors(updateError);
        props.temperatureMinClassList(updateClass);
        break;
      case "temp-normal":
        props.temperatureNormErrors(updateError);
        props.temperatureNormClassList(updateClass);
        break;
      case "temp-max":
        props.temperatureMaxErrors(updateError);
        props.temperatureMaxClassList(updateClass);
        break;
      case "temp-full":
        props.temperatureFullErrors(updateError);
        props.temperatureFullClassList(updateClass);
        break;
      case "ambient-min":
        props.ambientTemperatureMinErrors(updateError);
        props.ambientTemperatureMinClassList(updateClass);
        break;
      case "ambient-normal":
        props.ambientTemperatureNormErrors(updateError);
        props.ambientTemperatureNormClassList(updateClass);
        break;
      case "ambient-max":
        props.ambientTemperatureMaxErrors(updateError);
        props.ambientTemperatureMaxClassList(updateClass);
        break;
      case "ambient-full":
        props.ambientTemperatureFullErrors(updateError);
        props.ambientTemperatureFullClassList(updateClass);
        break;
      default:
    }
  };

  const handleResultClick = async () => {
    let missingValues = [];
    if (!(props.flow || props.density || props.viscosity)) {
      props.measurementTypeContainerClass("selection-container border-error");
      missingValues.push("XXSPECIFY_MMI_PR_ENT_MAND_FLD");
      setMissingValue(missingValues);
      props.sizingErrorMessage(true);
      props.sizingResultsClicked(true);
      await props.setCalculationFlag(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      props.sizingResultsClicked(true);
      await props.setCalculationFlag(true);
    }
  };

  useEffect(() => {
    if (!props.calculation_call && props.sizing_results_clicked) {
      sizingResults();
      props.sizingResultsClicked(false);
    }
  }, [props.calculation_call]);

  const sizingResults = async () => {
    await setIsApplied(true);
    setIsDirty(false);
    opDiff.current = [];
    await setMissingValue([]);
    await setErrorValues([]);
    await props.sizingResultsData([]);
    await props.showMoreRatesData([]);
    await props.selectedRowData([]);
    apiErrors.current = [];
    await props.wasClicked(true);

    if (props.operating_data_error_messages.length > 0) {
      await setErrorMessage();
    }

    if (props.select_technology.toLowerCase() === "coriolis") {
      await coriolisErrorMessage();
    } else if (props.select_technology.toLowerCase() === "magnetic") {
      await magVtxErrorMessage();
    } else if (props.select_technology.toLowerCase() === "vortex") {
      await magVtxErrorMessage();
    } else if (props.select_technology.toLowerCase() === "viscosity") {
      await viscosityErrorMessage();
    } else if (props.select_technology.toLowerCase() === "density") {
      await viscosityErrorMessage();
    }
    await setIsApplied(false);
    //await props.wasClicked(false);
  };

  const setErrorMessage = () => {
    let errorArray = [];
    props.operating_data_error_messages.map((c) => {
      errorArray.push(c);
      apiErrors.current = errorArray;
      setErrorValues([...apiErrors.current]);
    });
  };

  const coriolisErrorMessage = async () => {
    let missingValues = [];
    if (!props.normal_flow_rate) {
      missingValues.push("XXSPECIFY_MMI_MC_LBL_ERRMSG4");
      updateTableErrors("flow-rate-normal", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
    }
    if (!props.normal_line_pressure) {
      missingValues.push("XXSPECIFY_MMI_MC_LBL_ERRMSG5");
      updateTableErrors("line-press-normal", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
    }
    if (!props.normal_temperature) {
      missingValues.push("XXSPECIFY_MMI_MC_LBL_ERRMSG6");
      updateTableErrors("temp-normal", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
    }
    if (!props.normal_ambient) {
      missingValues.push("XXSPECIFY_MMI_MC_LBL_ERRMSG7");
      updateTableErrors("ambient-normal", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
    }
    if (props.calculate_density_variation) {
      if (!props.min_line_pressure) {
        missingValues.push("XXSPECIFY_MMI_PR_ENT_MAND_FLD");
        updateTableErrors("line-press-min", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
      }
      if (!props.min_temperature) {
        missingValues.push("XXSPECIFY_MMI_PR_ENT_MAND_FLD");
        updateTableErrors("temp-min", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
      }

      if (!props.max_line_pressure) {
        missingValues.push("XXSPECIFY_MMI_PR_ENT_MAND_FLD");
        updateTableErrors("line-press-max", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
      }
      if (!props.max_temperature) {
        missingValues.push("XXSPECIFY_MMI_PR_ENT_MAND_FLD");
        updateTableErrors("temp-max", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
      }
      if (props.fluid_state.toLowerCase() === "gas" && props.fluid_source.toLowerCase() === "custom") {
        if (props.units_density_heading === "Density") {
          if (!props.min_density) {
            missingValues.push("XXSPECIFY_MMI_PR_ENT_MAND_FLD");
            props.minimumDensityError(true);
          }

          if (!props.max_density) {
            missingValues.push("XXSPECIFY_MMI_PR_ENT_MAND_FLD");
            props.maximumDensityError(true);
          }
        }
      }
    }

    if (props.base_reference_conditions) {
      if (!props.gas_pressure) {
        missingValues.push("XXSPECIFY_MMI_PR_ENT_MAND_FLD");
        props.gasPressureError(true);
      }

      if (!props.gas_temperature) {
        missingValues.push("XXSPECIFY_MMI_PR_ENT_MAND_FLD");
        props.gasTemperatureError(true);
      }
    }

    if (props.fluid_source.toLowerCase() === "custom") {
      if (!props.custom_fluid_name) {
        missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
        props.customBorderError(true);
      }
      if (!props.normal_viscosity && !props.non_newtonian_liquid) {
        missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
        props.normalViscosityError(true);
      }

      if (props.min_viscosity_convertion_error) {
        missingValues.push("XXSPECIFY_MMI_PRE_VISC_VALD");
      }

      if (props.normal_viscosity_convertion_error) {
        missingValues.push("XXSPECIFY_MMI_PRE_VISC_VALD");
      }

      if (props.max_viscosity_convertion_error) {
        missingValues.push("XXSPECIFY_MMI_PRE_VISC_VALD");
      }

      if (props.normal_density_min_max_errors && props.fluid_source.toLowerCase() === "custom" && props.units_density_heading === "Density") {
        missingValues.push(props.normal_density_type_errors);
      }

      if (props.max_density_min_max_errors && props.fluid_source.toLowerCase() === "custom" && props.units_density_heading === "Density") {
        missingValues.push(props.max_density_type_errors);
      }

      if (props.normal_viscosity_min_max_errors && props.fluid_source.toLowerCase() === "custom" && !props.non_newtonian_liquid) {
        missingValues.push(props.normal_viscosity_type_errors);
      }

      if (props.max_viscosity_min_max_errors && props.fluid_source.toLowerCase() === "custom" && !props.non_newtonian_liquid) {
        missingValues.push(props.max_viscosity_type_errors);
      }

      if (props.units_density_heading === "Density") {
        if (!props.normal_density) {
          missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
          props.normalDensityError(true);
        }
        if (!props.normal_viscosity && !props.non_newtonian_liquid) {
          missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
          props.normalViscosityError(true);
        }

        if (props.base_reference_conditions) {
          if (!props.gas_density) {
            missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
            props.gasDensityError(true);
          }
        }
      } else if (
        props.units_density_heading === "Molecular Weight" ||
        (props.units_density_heading === "Specific Gravity" && props.fluid_state.toLowerCase() === "gas")
      ) {
        if (!props.compressibility) {
          missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
          props.gasCustomCompressibilityError(true);
        }
        if (props.units_density_heading === "Molecular Weight" && !props.molecular_weight) {
          missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
          props.molecularWeightError(true);
        }

        if (props.units_density_heading === "Specific Gravity" && !props.specific_gravity) {
          missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
          props.specificGravityError(true);
        }

        if (!props.normal_viscosity) {
          missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
          props.normalViscosityError(true);
        }

        if (!props.base_compressibility) {
          missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
          props.baseCompressibilityError(true);
        }
      }
    }

    if (props.fluid_source.toLowerCase() === "natural gas" && props.natural_gas_compose) {
      if (!props.natural_gas_composition_name) {
        missingValues.push("XXSPECIFY_MMI_PR_ENT_MAND_FLD");
        props.naturalGasBorderError(true);
      }
    }

    if (missingValues.length > 0) {
      setMissingValue(["XXSPECIFY_MMI_PR_ENT_MAND_FLD"]);

      if (props.min_viscosity_convertion_error || props.normal_viscosity_convertion_error || props.max_viscosity_convertion_error) {
        setMissingValue(["XXSPECIFY_MMI_PRE_VISC_VALD"]);
      }
      if (props.normal_density_min_max_errors && props.fluid_source.toLowerCase() === "custom" && props.units_density_heading === "Density") {
        setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107"]);
      }
      if (props.max_density_min_max_errors && props.fluid_source.toLowerCase() === "custom" && props.units_density_heading === "Density") {
        if (props.max_density_type_errors === CustomTranslationText("XXSPECIFY_MMI_MC_LBL_ERRMSG108", props)) {
          setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
          if (props.normal_density_min_max_errors && props.fluid_source.toLowerCase() === "custom") {
            setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
          }
        } else {
          setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG106"]);
        }
      }

      if (props.normal_viscosity_min_max_errors && props.fluid_source.toLowerCase() === "custom" && !props.non_newtonian_liquid) {
        setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG110"]);

        if (props.normal_density_min_max_errors && props.units_density_heading === "Density") {
          setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG110"]);
        }
        if (props.max_density_min_max_errors && props.units_density_heading === "Density") {
          if (props.max_density_type_errors === CustomTranslationText("XXSPECIFY_MMI_MC_LBL_ERRMSG108", props)) {
            setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
            if (props.normal_density_min_max_errors && props.fluid_source.toLowerCase() === "custom" && props.units_density_heading === "Density") {
              setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG110", "XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
            }
          } else {
            setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG110", "XXSPECIFY_MMI_MC_LBL_ERRMSG106"]);
          }
        }
      }

      if (props.max_viscosity_min_max_errors && props.fluid_source.toLowerCase() === "custom" && !props.non_newtonian_liquid) {
        if (props.max_viscosity_type_errors === CustomTranslationText("XXSPECIFY_MMI_MC_LBL_ERRMSG111", props)) {
          setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG111"]);
          if (props.normal_viscosity_min_max_errors && props.fluid_source.toLowerCase() === "custom") {
            setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG110", "XXSPECIFY_MMI_MC_LBL_ERRMSG111"]);
            if (props.normal_density_min_max_errors && props.units_density_heading === "Density") {
              setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG110", "XXSPECIFY_MMI_MC_LBL_ERRMSG111"]);
            }
            if (props.max_density_min_max_errors && props.units_density_heading === "Density") {
              if (props.max_density_type_errors === CustomTranslationText("XXSPECIFY_MMI_MC_LBL_ERRMSG108", props)) {
                setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
                if (
                  props.normal_density_min_max_errors &&
                  props.fluid_source.toLowerCase() === "custom" &&
                  props.units_density_heading === "Density"
                ) {
                  setMissingValue([
                    "XXSPECIFY_MMI_MC_LBL_ERRMSG110",
                    "XXSPECIFY_MMI_MC_LBL_ERRMSG111",
                    "XXSPECIFY_MMI_MC_LBL_ERRMSG107",
                    "XXSPECIFY_MMI_MC_LBL_ERRMSG108",
                  ]);
                }
              } else {
                setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG110", "XXSPECIFY_MMI_MC_LBL_ERRMSG111", "XXSPECIFY_MMI_MC_LBL_ERRMSG106"]);
              }
            }
          } else {
            if (props.normal_density_min_max_errors && props.units_density_heading === "Density") {
              setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG111"]);
            }
            if (props.max_density_min_max_errors && props.fluid_source.toLowerCase() === "custom" && props.units_density_heading === "Density") {
              if (props.max_density_type_errors === CustomTranslationText("XXSPECIFY_MMI_MC_LBL_ERRMSG108", props)) {
                setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
                if (
                  props.normal_density_min_max_errors &&
                  props.fluid_source.toLowerCase() === "custom" &&
                  props.units_density_heading === "Density"
                ) {
                  setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG111", "XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
                }
              } else {
                setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG111", "XXSPECIFY_MMI_MC_LBL_ERRMSG106"]);
              }
            }
          }
        } else {
          setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG109"]);
          if (props.max_density_min_max_errors && props.units_density_heading === "Density") {
            if (props.max_density_type_errors === CustomTranslationText("XXSPECIFY_MMI_MC_LBL_ERRMSG108", props)) {
              setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
              if (props.normal_density_min_max_errors && props.fluid_source.toLowerCase() === "custom") {
                setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG108", "XXSPECIFY_MMI_MC_LBL_ERRMSG109"]);
              }
            } else {
              setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG106", "XXSPECIFY_MMI_MC_LBL_ERRMSG109"]);
            }
          } else if (props.normal_density_min_max_errors && props.units_density_heading === "Density") {
            setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG109"]);
          }
        }
      }
    }

    if (missingValues.length > 0 || props.operating_data_error_messages.length > 0) {
      props.sizingErrorMessage(true);
      props.requestSizingResults(false);
      props.displaySizingResults(false);
    } else {
      var temp = false;
      var line_press = false;

      line_press = props.line_press_row !== undefined && props.line_press_row !== "" ? props.line_press_row.some((el) => el > 1450) : false;
      temp = props.temp_row !== undefined && props.temp_row !== "" ? props.temp_row.some((el) => el > 350) : false;

      line_press = props.line_press_row !== undefined && props.line_press_row !== "" ? props.line_press_row.some((el) => el > 1450) : false;
      temp = props.temp_row !== undefined && props.temp_row !== "" ? props.temp_row.some((el) => el > 350) : false;

      if (props.wetted_material_value !== "ALL_MATERIALS") {
        if ((line_press || temp) && !props.display_all_sensors_no_filters) {
          setMissingValue(["XXSPECIFY_MMI_WETMTL_WRN_LBL"]);
          props.sizingErrorMessage(true);
          props.warningLimit(true);
        } else {
          props.warningLimit(false);
        }
      } else {
        props.sizingErrorMessage(false);
        props.warningLimit(false);
      }
      //props.sizingErrorMessage(false);
      props.requestSizingResults(true);
      props.displaySizingResults(true);
    }
  };

  const magVtxErrorMessage = () => {
    let missingValues = [];
    if (!props.min_flow_rate) {
      missingValues.push("XXSPECIFY_MMI_MC_LBL_ERRMSG2");
      updateTableErrors("flow-rate-min", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
    }
    if (!props.max_flow_rate) {
      missingValues.push("XXSPECIFY_MMI_MC_LBL_ERRMSG3");
      updateTableErrors("flow-rate-max", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
    }
    if (!props.normal_line_pressure && props.steam_pressure_temp !== "Based on Saturated Temperature") {
      missingValues.push("XXSPECIFY_MMI_MC_LBL_ERRMSG5");
      updateTableErrors("line-press-normal", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
    }
    if (!props.normal_ambient) {
      missingValues.push("XXSPECIFY_MMI_MC_LBL_ERRMSG7");
      updateTableErrors("ambient-normal", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
    }
    if (props.select_technology.toLowerCase() !== "vortex") {
      if (props.normal_density_min_max_errors && props.fluid_source.toLowerCase() === "custom" && props.units_density_heading === "Density") {
        missingValues.push(props.normal_density_type_errors);
      }

      if (props.max_density_min_max_errors && props.fluid_source.toLowerCase() === "custom" && props.units_density_heading === "Density") {
        missingValues.push(props.max_density_type_errors);
      }
    }

    if (props.select_technology.toLowerCase() === "vortex") {
      if (props.normal_viscosity_min_max_errors && props.fluid_source.toLowerCase() === "custom") {
        missingValues.push(props.normal_viscosity_type_errors);
      }

      if (props.max_viscosity_min_max_errors && props.fluid_source.toLowerCase() === "custom") {
        missingValues.push(props.max_viscosity_type_errors);
      }
    }

    if (!props.normal_temperature && props.steam_pressure_temp !== "Based on Saturated Pressure") {
      missingValues.push("XXSPECIFY_MMI_MC_LBL_ERRMSG6");
      updateTableErrors("temp-normal", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
    }
    if (props.fluid_source.toLowerCase() === "custom") {
      if (!props.custom_fluid_name) {
        missingValues.push("XXSPECIFY_MMI_PR_ENT_MAND_FLD");
        props.customBorderError(true);
      }

      if (props.units_density_heading === "Density") {
        if (!props.normal_density) {
          missingValues.push("XXSPECIFY_MMI_PR_ENT_MAND_FLD");
          props.normalDensityError(true);
        }
        if (props.base_reference_conditions) {
          if (!props.gas_density) {
            missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
            props.gasDensityError(true);
          }
        }
      } else if (
        props.units_density_heading === "Molecular Weight" ||
        (props.units_density_heading === "Specific Gravity" && props.fluid_state.toLowerCase() === "gas")
      ) {
        if (props.units_density_heading === "Specific Gravity" && !props.specific_gravity) {
          missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
          props.specificGravityError(true);
        }

        if (!props.normal_viscosity) {
          missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
          props.normalViscosityError(true);
        }

        if (!props.compressibility) {
          missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
          props.gasCustomCompressibilityError(true);
        }
      }

      if (props.select_technology.toLowerCase() === "vortex") {
        if (!props.normal_viscosity && !props.non_newtonian_liquid) {
          missingValues.push("XXSPECIFY_MMI_PR_ENT_MAND_FLD");
          props.normalViscosityError(true);
        }
      }
    }
    if (props.fluid_source.toLowerCase() === "natural gas" && props.natural_gas_compose) {
      if (!props.natural_gas_composition_name) {
        missingValues.push("XXSPECIFY_MMI_PR_ENT_MAND_FLD");
        props.naturalGasBorderError(true);
      }
    }

    if (props.base_reference_conditions) {
      if (!props.gas_pressure) {
        missingValues.push("XXSPECIFY_MMI_PR_ENT_MAND_FLD");
        props.gasPressureError(true);
      }

      if (!props.gas_temperature) {
        missingValues.push("XXSPECIFY_MMI_PR_ENT_MAND_FLD");
        props.gasTemperatureError(true);
      }
    }
    if (missingValues.length > 0) {
      setMissingValue(["XXSPECIFY_MMI_PR_ENT_MAND_FLD"]);

      if (props.normal_density_min_max_errors && props.fluid_source.toLowerCase() === "custom" && props.units_density_heading === "Density") {
        setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107"]);
      }
      if (props.max_density_min_max_errors && props.fluid_source.toLowerCase() === "custom" && props.units_density_heading === "Density") {
        if (props.max_density_type_errors === CustomTranslationText("XXSPECIFY_MMI_MC_LBL_ERRMSG108", props)) {
          setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
          if (props.normal_density_min_max_errors && props.fluid_source.toLowerCase() === "custom") {
            setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
          }
        } else {
          setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG106"]);
        }
      }

      if (props.select_technology.toLowerCase() === "vortex") {
        if (props.normal_viscosity_min_max_errors && props.fluid_source.toLowerCase() === "custom" && !props.non_newtonian_liquid) {
          setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG110"]);

          if (props.normal_density_min_max_errors && props.units_density_heading === "Density") {
            setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG110"]);
          }
          if (props.max_density_min_max_errors && props.units_density_heading === "Density") {
            if (props.max_density_type_errors === CustomTranslationText("XXSPECIFY_MMI_MC_LBL_ERRMSG108", props)) {
              setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
              if (props.normal_density_min_max_errors && props.fluid_source.toLowerCase() === "custom" && props.units_density_heading === "Density") {
                setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG110", "XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
              }
            } else {
              setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG110", "XXSPECIFY_MMI_MC_LBL_ERRMSG106"]);
            }
          }
        }

        if (props.max_viscosity_min_max_errors && props.fluid_source.toLowerCase() === "custom" && !props.non_newtonian_liquid) {
          if (props.max_viscosity_type_errors === CustomTranslationText("XXSPECIFY_MMI_MC_LBL_ERRMSG111", props)) {
            setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG111"]);
            if (props.normal_viscosity_min_max_errors && props.fluid_source.toLowerCase() === "custom") {
              setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG110", "XXSPECIFY_MMI_MC_LBL_ERRMSG111"]);
              if (props.normal_density_min_max_errors && props.units_density_heading === "Density") {
                setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG110", "XXSPECIFY_MMI_MC_LBL_ERRMSG111"]);
              }
              if (props.max_density_min_max_errors && props.units_density_heading === "Density") {
                if (props.max_density_type_errors === CustomTranslationText("XXSPECIFY_MMI_MC_LBL_ERRMSG108", props)) {
                  setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
                  if (
                    props.normal_density_min_max_errors &&
                    props.fluid_source.toLowerCase() === "custom" &&
                    props.units_density_heading === "Density"
                  ) {
                    setMissingValue([
                      "XXSPECIFY_MMI_MC_LBL_ERRMSG110",
                      "XXSPECIFY_MMI_MC_LBL_ERRMSG111",
                      "XXSPECIFY_MMI_MC_LBL_ERRMSG107",
                      "XXSPECIFY_MMI_MC_LBL_ERRMSG108",
                    ]);
                  }
                } else {
                  setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG110", "XXSPECIFY_MMI_MC_LBL_ERRMSG111", "XXSPECIFY_MMI_MC_LBL_ERRMSG106"]);
                }
              }
            } else {
              if (props.normal_density_min_max_errors && props.units_density_heading === "Density") {
                setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG111"]);
              }
              if (props.max_density_min_max_errors && props.fluid_source.toLowerCase() === "custom" && props.units_density_heading === "Density") {
                if (props.max_density_type_errors === CustomTranslationText("XXSPECIFY_MMI_MC_LBL_ERRMSG108", props)) {
                  setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
                  if (
                    props.normal_density_min_max_errors &&
                    props.fluid_source.toLowerCase() === "custom" &&
                    props.units_density_heading === "Density"
                  ) {
                    setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG111", "XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
                  }
                } else {
                  setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG111", "XXSPECIFY_MMI_MC_LBL_ERRMSG106"]);
                }
              }
            }
          } else {
            setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG109"]);
            if (props.max_density_min_max_errors && props.units_density_heading === "Density") {
              if (props.max_density_type_errors === CustomTranslationText("XXSPECIFY_MMI_MC_LBL_ERRMSG108", props)) {
                setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
                if (props.normal_density_min_max_errors && props.fluid_source.toLowerCase() === "custom") {
                  setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG108", "XXSPECIFY_MMI_MC_LBL_ERRMSG109"]);
                }
              } else {
                setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG106", "XXSPECIFY_MMI_MC_LBL_ERRMSG109"]);
              }
            } else if (props.normal_density_min_max_errors && props.units_density_heading === "Density") {
              setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG109"]);
            }
          }
        }
      }
    }
    if (missingValues.length > 0 || props.operating_data_error_messages.length > 0) {
      props.sizingErrorMessage(true);
      props.requestSizingResults(false);
      props.displaySizingResults(false);
    } else {
      props.sizingErrorMessage(false);
      props.requestSizingResults(true);
      props.displaySizingResults(true);
    }
  };

  const viscosityErrorMessage = () => {
    let missingValues = [];
    setErrorValues([]);
    if (props.fluid_state.toLowerCase() !== "gas") {
      if (props.tank === false) {
        if (!props.normal_flow_rate) {
          missingValues.push("XXSPECIFY_MMI_MC_LBL_ERRMSG4");
          updateTableErrors("flow-rate-normal", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
        }
        if (!props.max_flow_rate) {
          missingValues.push("XXSPECIFY_MMI_MC_LBL_ERRMSG3");
          updateTableErrors("flow-rate-max", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
        }
      }
    }
    if (!props.normal_line_pressure) {
      missingValues.push("XXSPECIFY_MMI_MC_LBL_ERRMSG5");
      updateTableErrors("line-press-normal", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
    }
    if (!props.max_line_pressure) {
      missingValues.push("XXSPECIFY_MMI_MC_LBL_ERRMSG17");
      updateTableErrors("line-press-max", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
    }

    if (!props.normal_temperature) {
      missingValues.push("XXSPECIFY_MMI_MC_LBL_ERRMSG6");
      updateTableErrors("temp-normal", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
    }
    if (!props.max_temperature) {
      missingValues.push("XXSPECIFY_MMI_MC_LBL_ERRMSG18");
      updateTableErrors("temp-max", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
    }
    if (!props.normal_ambient) {
      missingValues.push("XXSPECIFY_MMI_MC_LBL_ERRMSG7");
      updateTableErrors("ambient-normal", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
    }
    if (!props.max_ambient) {
      missingValues.push("XXSPECIFY_MMI_MC_LBL_ERRMSG19");
      updateTableErrors("ambient-max", [CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props)], requiredErrorField);
    }
    if (props.fluid_source.toLowerCase() === "natural gas" && props.natural_gas_compose) {
      if (!props.natural_gas_composition_name) {
        missingValues.push("XXSPECIFY_MMI_PR_ENT_MAND_FLD");
        props.naturalGasBorderError(true);
      }
    }

    if (props.normal_density_min_max_errors && props.fluid_source.toLowerCase() === "custom" && props.units_density_heading === "Density") {
      missingValues.push(props.normal_density_type_errors);
    }

    if (props.max_density_min_max_errors && props.fluid_source.toLowerCase() === "custom" && props.units_density_heading === "Density") {
      missingValues.push(props.max_density_type_errors);
    }

    if (
      props.normal_viscosity_min_max_errors &&
      props.fluid_source.toLowerCase() === "custom" &&
      !props.non_newtonian_liquid &&
      props.fluid_state.toLowerCase() !== "gas"
    ) {
      missingValues.push(props.normal_viscosity_type_errors);
    }

    if (
      props.max_viscosity_min_max_errors &&
      props.fluid_source.toLowerCase() === "custom" &&
      !props.non_newtonian_liquid &&
      props.fluid_state.toLowerCase() !== "gas"
    ) {
      missingValues.push(props.max_viscosity_type_errors);
    }

    if (props.fluid_state.toLowerCase() === "liquid" || props.fluid_state.toLowerCase() === "slurry") {
      if (
        ((props.line_size.toLowerCase() === "select line size" || props.line_size.toLowerCase() === "") && !props.tank) ||
        (props.line_size.toLowerCase() === "" && !props.tank && !props.select_technology.toLowerCase() === "density")
      ) {
        missingValues.push("XXSPECIFY_MMI_MC_LBL_ERRMSG6");
        missingValues.push("XXSPECIFY_MMI_MC_LBL_ERRMSG23");
        props.lineSizeError(true);
        setErrorValues([...apiErrors.current, <CustomTranslation data="XXSPECIFY_MMI_MC_LBL_ERRMSG23" />]);
      }
    }
    if (props.fluid_source.toLowerCase() === "custom") {
      if (!props.custom_fluid_name) {
        missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
        props.customBorderError(true);
      }

      if (props.min_viscosity_convertion_error) {
        missingValues.push("XXSPECIFY_MMI_PRE_VISC_VALD");
      }

      if (props.normal_viscosity_convertion_error) {
        missingValues.push("XXSPECIFY_MMI_PRE_VISC_VALD");
        //props.normalViscosityError(true);
      }

      if (props.max_viscosity_convertion_error) {
        missingValues.push("XXSPECIFY_MMI_PRE_VISC_VALD");
      }

      if (props.units_density_heading === "Density") {
        if (!props.normal_density) {
          missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
          props.normalDensityError(true);
        }
      } else if (
        props.units_density_heading === "Molecular Weight" ||
        (props.units_density_heading === "Specific Gravity" && props.fluid_state.toLowerCase() === "gas")
      ) {
        if (!props.compressibility) {
          missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
          props.gasCustomCompressibilityError(true);
        }
        if (props.units_density_heading === "Molecular Weight" && !props.molecular_weight) {
          missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
          props.molecularWeightError(true);
        }

        if (props.units_density_heading === "Specific Gravity" && !props.specific_gravity) {
          missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
          props.specificGravityError(true);
        }
      }

      if (props.fluid_state.toLowerCase() === "liquid" || props.fluid_state.toLowerCase() === "slurry") {
        if (!props.max_density && props.units_density_heading !== "Specific Gravity") {
          missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
          props.maximumDensityError(true);
        }
        if (!props.normal_viscosity && !props.non_newtonian_liquid) {
          missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
          props.normalViscosityError(true);
        }
        if (!props.max_viscosity && !props.non_newtonian_liquid) {
          missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
          props.maximumViscosityError(true);
        }
      }
      if (props.base_reference_conditions) {
        if (!props.gas_density) {
          missingValues.push("XXSPECIFY_MMI_SR_LBL_ERRMSG13");
          props.gasDensityError(true);
        }
      }

      if (missingValues.length > 0) {
        setMissingValue(["XXSPECIFY_MMI_PR_ENT_MAND_FLD"]);
        if (props.min_viscosity_convertion_error || props.normal_viscosity_convertion_error || props.max_viscosity_convertion_error) {
          setMissingValue(["XXSPECIFY_MMI_PRE_VISC_VALD"]);
        }
      }
    }

    if (missingValues.length > 0) {
      setMissingValue(["XXSPECIFY_MMI_PR_ENT_MAND_FLD"]);

      if (props.normal_density_min_max_errors && props.fluid_source.toLowerCase() === "custom" && props.units_density_heading === "Density") {
        setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107"]);
      }
      if (props.max_density_min_max_errors && props.fluid_source.toLowerCase() === "custom" && props.units_density_heading === "Density") {
        if (props.max_density_type_errors === CustomTranslationText("XXSPECIFY_MMI_MC_LBL_ERRMSG108", props)) {
          setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
          if (props.normal_density_min_max_errors && props.fluid_source.toLowerCase() === "custom") {
            setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
          }
        } else {
          setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG106"]);
        }
      }

      if (props.normal_viscosity_min_max_errors && props.fluid_source.toLowerCase() === "custom" && !props.non_newtonian_liquid) {
        setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG110"]);

        if (props.normal_density_min_max_errors && props.units_density_heading === "Density") {
          setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG110"]);
        }
        if (props.max_density_min_max_errors && props.units_density_heading === "Density") {
          if (props.max_density_type_errors === CustomTranslationText("XXSPECIFY_MMI_MC_LBL_ERRMSG108", props)) {
            setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
            if (props.normal_density_min_max_errors && props.fluid_source.toLowerCase() === "custom" && props.units_density_heading === "Density") {
              setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG110", "XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
            }
          } else {
            setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG110", "XXSPECIFY_MMI_MC_LBL_ERRMSG106"]);
          }
        }
      }

      if (props.max_viscosity_min_max_errors && props.fluid_source.toLowerCase() === "custom" && !props.non_newtonian_liquid) {
        if (props.max_viscosity_type_errors === CustomTranslationText("XXSPECIFY_MMI_MC_LBL_ERRMSG111", props)) {
          setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG111"]);
          if (props.normal_viscosity_min_max_errors && props.fluid_source.toLowerCase() === "custom") {
            setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG110", "XXSPECIFY_MMI_MC_LBL_ERRMSG111"]);
            if (props.normal_density_min_max_errors && props.units_density_heading === "Density") {
              setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG110", "XXSPECIFY_MMI_MC_LBL_ERRMSG111"]);
            }
            if (props.max_density_min_max_errors && props.units_density_heading === "Density") {
              if (props.max_density_type_errors === CustomTranslationText("XXSPECIFY_MMI_MC_LBL_ERRMSG108", props)) {
                setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
                if (
                  props.normal_density_min_max_errors &&
                  props.fluid_source.toLowerCase() === "custom" &&
                  props.units_density_heading === "Density"
                ) {
                  setMissingValue([
                    "XXSPECIFY_MMI_MC_LBL_ERRMSG110",
                    "XXSPECIFY_MMI_MC_LBL_ERRMSG111",
                    "XXSPECIFY_MMI_MC_LBL_ERRMSG107",
                    "XXSPECIFY_MMI_MC_LBL_ERRMSG108",
                  ]);
                }
              } else {
                setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG110", "XXSPECIFY_MMI_MC_LBL_ERRMSG111", "XXSPECIFY_MMI_MC_LBL_ERRMSG106"]);
              }
            }
          } else {
            if (props.normal_density_min_max_errors && props.units_density_heading === "Density") {
              setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG111"]);
            }
            if (props.max_density_min_max_errors && props.fluid_source.toLowerCase() === "custom" && props.units_density_heading === "Density") {
              if (props.max_density_type_errors === CustomTranslationText("XXSPECIFY_MMI_MC_LBL_ERRMSG108", props)) {
                setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
                if (
                  props.normal_density_min_max_errors &&
                  props.fluid_source.toLowerCase() === "custom" &&
                  props.units_density_heading === "Density"
                ) {
                  setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG111", "XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
                }
              } else {
                setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG111", "XXSPECIFY_MMI_MC_LBL_ERRMSG106"]);
              }
            }
          }
        } else {
          setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG109"]);
          if (props.max_density_min_max_errors && props.units_density_heading === "Density") {
            if (props.max_density_type_errors === CustomTranslationText("XXSPECIFY_MMI_MC_LBL_ERRMSG108", props)) {
              setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG108"]);
              if (props.normal_density_min_max_errors && props.fluid_source.toLowerCase() === "custom") {
                setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG108", "XXSPECIFY_MMI_MC_LBL_ERRMSG109"]);
              }
            } else {
              setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG106", "XXSPECIFY_MMI_MC_LBL_ERRMSG109"]);
            }
          } else if (props.normal_density_min_max_errors && props.units_density_heading === "Density") {
            setMissingValue(["XXSPECIFY_MMI_MC_LBL_ERRMSG107", "XXSPECIFY_MMI_MC_LBL_ERRMSG109"]);
          }
        }
      }
    }

    if (missingValues.length > 0 || props.operating_data_error_messages.length > 0) {
      props.sizingErrorMessage(true);
      props.requestSizingResults(false);
      props.displaySizingResults(false);
    } else {
      props.sizingErrorMessage(false);
      props.requestSizingResults(true);
      props.displaySizingResults(true);
    }
  };

  const handlesizingErrorShow = () => props.sizing_error_message;
  const handlesizingErrorClose = () => {
    props.sizingErrorMessage(false);
    //setMissingValue([]);
    //setErrorValues([]);
  };
  return (
    <>
      <ChangeOperatingConditionsModal
        show={showChangeOperatingConditionsModal}
        handleApply={handleApply}
        handleDiscard={handleDiscardSizingInput}
        handleClose={handleCloseOC}
      />
      <Container className="advanced-page" ref={scrollDestination}>
        <SizingResultErrorModal
          show={props.sizing_error_message}
          handleClose={handlesizingErrorClose}
          handleShow={handlesizingErrorShow}
          missingInputValues={missingValuesState}
          errorValues={errorValues}
          warning_limit={props.warning_limit}
        />
        <div id="sizing-input-section" className="LineBottom">
          <h5>
            <CustomTranslation data="XXSPECIFY_MMI_SR_TAB_SIZ_IP" />
          </h5>
          <Row>
            <Col md={4} sm={12} className="col-background">
              <MeasurementType />
              {props.select_technology !== "Vortex" ? <EquipmentSelection /> : <span />}
              <ApplicationRequirements />
            </Col>
            <Col md={8} sm={12} className="col-background">
              <FluidSelection />
              <ProcessVariables />
            </Col>

            <span className="sizing-results-button">
              <CustomButton
                classNames="btn btn-nav btn-green"
                label={<CustomTranslation data="XXSPECIFY_MMI_SR_BTN_SIZ_RES" />}
                onClick={() => handleResultClick()}
                disabled={isDisabled}
                size="lg"
                spinner={props.calculation_call}
              />
            </span>
          </Row>
        </div>
        <div id="sizing-results-section">
          {props.display_sizing_results && !isApplied ? (
            <>
              {/*TODO: Hardcoding these results for the short-term, will be hooked up to the UI in a */}
              {/* future ticket*/}
              <SizingResults
                onClickSizingResults={props.onClickSizingResults}
                technology={props.operating_conditions.select_technology}
                tech_info={{
                  language: "US",
                  language_code: "US",
                  trans_language: "US",
                  minFlowRate: "",
                  maxFlowRate: "",
                  normalFlowRate: 50,
                  flowRateUnit: "USGPM",
                  min_temp_val: 0,
                  normal_temp_val: 50,
                  temperature_unit: "F",
                  fluidState: "LIQUID",
                  maxFlowAccuracy: 3,
                  isChkNoFilter: "True",
                  normal_density: 62.4347,
                  normal_density_unit: "LB/FT3",
                  isChkNonNewtonLiquid: "False",
                  line_pressure_unit: "PSIG",
                  k_flow_consistency_text: 10,
                  n_flow_behavior_text: 10,
                  viscocity: 1.3057,
                  viscocity_unit: "CP",
                }}
                language_info={{
                  language: "US",
                  language_code: "US",
                  trans_language: "US",
                }}
              />
            </>
          ) : (
            <span />
          )}
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  fluid_state: state.FluidSelection.fluid_state,
  disable_sizing_results_button: state.ProcessVariables.disable_sizing_results_button,
  units_data: state.DropdownData.units_data,
  select_technology: state.MeasurementType.select_technology,
  display_sizing_results: state.SizingResults.display_sizing_results,
  sizing_results_button: state.SizingResults.sizing_results_button,
  flow: state.MeasurementType.flow,
  density: state.MeasurementType.density,
  viscosity: state.MeasurementType.viscosity,
  measurement_type_container_class: state.MeasurementType.measurement_type_container_class,
  sizing_error_message: state.SizingResultErrorMessage.sizing_error_message,
  normal_viscosity: state.ProcessVariables.normal_viscosity,
  units_density_heading: state.ProcessVariables.units_density_heading,
  min_flow_rate: state.ProcessVariables.min_flow_rate,
  normal_flow_rate: state.ProcessVariables.normal_flow_rate,
  max_flow_rate: state.ProcessVariables.max_flow_rate,
  full_flow_rate: state.ProcessVariables.full_flow_rate,
  min_line_pressure: state.ProcessVariables.min_line_pressure,
  normal_line_pressure: state.ProcessVariables.normal_line_pressure,
  max_line_pressure: state.ProcessVariables.max_line_pressure,
  full_line_pressure: state.ProcessVariables.full_line_pressure,
  min_temperature: state.ProcessVariables.min_temperature,
  normal_temperature: state.ProcessVariables.normal_temperature,
  max_temperature: state.ProcessVariables.max_temperature,
  full_temperature: state.ProcessVariables.full_temperature,
  units_temperature: state.ProcessVariables.units_temperature,
  min_ambient: state.ProcessVariables.min_ambient,
  normal_ambient: state.ProcessVariables.normal_ambient,
  max_ambient: state.ProcessVariables.max_ambient,
  full_ambient: state.ProcessVariables.full_ambient,
  min_density: state.ProcessVariables.min_density,
  normal_density: state.ProcessVariables.normal_density,
  max_density: state.ProcessVariables.max_density,
  min_viscosity: state.ProcessVariables.min_viscosity,
  max_viscosity: state.ProcessVariables.max_viscosity,
  operating_data_error_messages: state.ProcessVariables.operating_data_error_messages,
  units_ambient: state.ProcessVariables.units_ambient,
  operating_conditions: state.ProcessVariables.operating_conditions,
  saved_preferences: state.Preferences.saved_preferences,
  tank: state.ApplicationRequirements.tank,
  line_size: state.ApplicationRequirements.line_size,
  display_all_sensors_no_filters: state.ApplicationRequirements.display_all_sensors_no_filters,
  fluid_selection: state.FluidSelection,
  get_sizing_data: state.MySizings.get_sizing_data,
  was_clicked: state.SizingResults.was_clicked,
  search_parameters: state.SizingResults.search_parameters,
  sizing_results_data: state.SizingResults.sizing_results_data,
  saved_sizing_load: state.SizingResults.saved_sizing_load,
  reset_operating_conditions: state.ProcessVariables.reset_operating_conditions,
  required_fields: state.ProcessVariables.required_fields,
  molecular_weight: state.ProcessVariables.molecular_weight,
  specific_gravity: state.ProcessVariables.specific_gravity,
  min_viscosity_convertion_error: state.ProcessVariables.min_viscosity_convertion_error,
  normal_viscosity_convertion_error: state.ProcessVariables.normal_viscosity_convertion_error,
  max_viscosity_convertion_error: state.ProcessVariables.max_viscosity_convertion_error,
  calculate_density_variation: state.ProcessVariables.calculate_density_variation,
  translation_data: state.TranslationData.translation_data,
  clear_all_field: state.ClearAllField.clear_all_field,
  fluid_source: state.FluidSelection.fluid_source,
  custom_fluid_name: state.FluidSelection.custom_fluid_name,
  gas_density: state.ProcessVariables.gas_density,
  base_reference_conditions: state.ProcessVariables.base_reference_conditions,
  steam_pressure_temp: state.FluidSelection.steam_pressure_temp,
  non_newtonian_liquid: state.ProcessVariables.non_newtonian_liquid,
  custom_border_error: state.FluidSelection.custom_border_error,
  compressibility: state.ProcessVariables.compressibility,
  base_compressibility: state.ProcessVariables.base_compressibility,
  pick_fluids_from_db: state.FluidSelection.pick_fluids_from_db,
  query_params: state.Preferences.query_params,
  natural_gas_composition_name: state.FluidSelection.natural_gas_composition_name,
  natural_gas_compose: state.FluidSelection.natural_gas_compose,
  wetted_material_value: state.ApplicationRequirements.wetted_material_value,
  line_press_row: state.ProcessVariables.line_press_row_psig,
  temp_row: state.ProcessVariables.temp_row_f,
  warning_limit: state.SizingResultErrorMessage.warning_limit,
  normal_density_min_max_errors: state.ProcessVariables.normal_density_min_max_errors,
  max_density_min_max_errors: state.ProcessVariables.max_density_min_max_errors,
  normal_density_type_errors: state.ProcessVariables.normal_density_type_errors,
  max_density_type_errors: state.ProcessVariables.max_density_type_errors,
  normal_viscosity_min_max_errors: state.ProcessVariables.normal_viscosity_min_max_errors,
  max_viscosity_min_max_errors: state.ProcessVariables.max_viscosity_min_max_errors,
  normal_viscosity_type_errors: state.ProcessVariables.normal_viscosity_type_errors,
  max_viscosity_type_errors: state.ProcessVariables.max_viscosity_type_errors,
  calculation_call: state.ProcessVariables.calculation_call,
  sizing_results_clicked: state.ProcessVariables.sizing_results_clicked,
  resizing_discard_flag: state.ProcessVariables.resizing_discard_flag,
  gas_pressure: state.ProcessVariables.gas_pressure,
  gas_temperature: state.ProcessVariables.gas_temperature,
});

export default connect(mapStateToProps, {
  disableSizingResultsButton,
  sizingErrorMessage,
  requestSizingResults,
  displaySizingResults,
  wasClicked,
  operatingConditions,
  resetOperatingConditions,
  searchParameters,
  flowRateNormClassList,
  flowRateMinClassList,
  flowRateMaxClassList,
  flowRateFullClassList,
  flowRateFullErrors,
  linePressMinClassList,
  linePressNormClassList,
  linePressMaxClassList,
  linePressFullClassList,
  linePressFullErrors,
  temperatureMinClassList,
  temperatureNormClassList,
  temperatureMaxClassList,
  temperatureFullClassList,
  ambientTemperatureMinClassList,
  ambientTemperatureNormClassList,
  ambientTemperatureMaxClassList,
  ambientTemperatureFullClassList,
  lineSizeError,
  flowRateMinErrors,
  flowRateNormErrors,
  flowRateMaxErrors,
  linePressMinErrors,
  linePressNormErrors,
  linePressMaxErrors,
  temperatureMinErrors,
  temperatureNormErrors,
  temperatureMaxErrors,
  temperatureFullErrors,
  ambientTemperatureMinErrors,
  ambientTemperatureNormErrors,
  ambientTemperatureMaxErrors,
  ambientTemperatureFullErrors,
  savedSizingLoad,
  getSizingData,
  setOperatingDataErrorMessages,
  minimumDensityError,
  normalDensityError,
  maximumDensityError,
  normalViscosityError,
  maximumViscosityError,
  gasDensityError,
  gasTemperatureError,
  gasPressureError,
  customBorderError,
  gasCustomCompressibilityError,
  molecularWeightError,
  specificGravityError,
  baseCompressibilityError,
  revertFlag,
  applyChanges,
  showMoreRatesData,
  selectedRowData,
  sizingResultsData,
  queryParams,
  naturalGasBorderError,
  warningLimit,
  setCalculationFlag,
  sizingResultsClicked,
  resizingDiscardFlag,
  measurementTypeContainerClass,
})(AdvancedPage);
