
// TODO: mostly redundant with getUnitsKeyValue, refactor to remove this function
export const getUnitsValue = (keyIndex, unit, unitData) => {
  if (keyIndex && unitData) {
    keyIndex = keyIndex.key ? keyIndex.key : keyIndex;
    let result;
    switch (unit) {
      case 'flowRateLiquidSlurry':
        result = unitData.flowRateLiquidSlurry.find(({key}) => key === keyIndex); break;
      case 'flowRateGas':
        result =  unitData.flowRateGas.find(({key}) => key === keyIndex); break;
      case 'density':
        result =  unitData.density.find(({key}) => key === keyIndex); break;
      case 'nonVaporPressure':
        result =  unitData.nonVaporPressure.find(({key}) => key === keyIndex); break;
      case 'sonicVelocity':
        result =  unitData.sonicVelocity.find(({key}) => key === keyIndex); break;
      case 'temperature':
        result =  unitData.temperature.find(({key}) => key === keyIndex); break;
      case 'vaporPressure':
        result =  unitData.vaporPressure.find(({key}) => key === keyIndex); break;
      case 'viscosity':
        result =  unitData.viscosity.find(({key}) => key === keyIndex); break;
      default: result = '';
    }
    return result ? result.value : '';
  } else {
    return '';
  }
}
