import {
  SELECTED_ROW_DATA,
  APPLICATION,
  SERVICE,
  SENSOR_TAG,
  TRANSMITTER_TAG,
  PROJECT_NAME,
  DISPLAY_SIZING_RESULTS,
  REQUEST_SIZING_RESULTS,
  COMPARE_SIZING_RESULTS,
  COMPARE_DISABLE_BUTTON,
  SIZING_RESULTS_DATA,
  PRODUCT_IMAGE_URL,
  CONFIG_WARNING_MSG,
  SONIC_VELOCITY_WARNING_MSG,
  SONIC_VELOCITY_ENTERED,
  AMBIENT_TEMP_WARNING_MSG,
  HIGH_TEMP_WARNING_MSG,
  HIGH_TEMPERATURE_ERROR_MSG,
  SHOW_MORE_RATES_DATA,
  SHOW_MORE_RATES_RESULT_DATA,
  SHOW_MORE_RATES_LOADER,
  PRODUCT_IMAGE_URL_LOAD,
  PRODUCT_IMAGE_API_LOAD,
  COMPARE_MODEL_NAMES,
  TUBE_VELOCITY_UNIT,
  WAS_CLICKED,
  SEARCH_PARAMETERS,
  SAVED_SIZING_LOAD,
  FLOW_ACCURACY,
  DENSITY_ACCURACY,
  MASS_FLOW, 
  NAVBAR_FLAG,
  PROCEED_LOAD,
  FLOW_ACCURACY_VALUE,
  SET_DEFAULT_ROW,
  IS_APPLIED
} from '../actionTypes';

const initialState = {
  application: '',
  mass_flow:{},
  navbar_flag:'',
  sizing_service: '',
  sensor_tag: '',
  transmitter_tag: '',
  project_name: '',
  flow_accuracy_value:'',
  show_more_rates_data:[],
  show_more_rates_result_data:[],
  compare_model_names: [],
  request_sizing_results: false,
  display_sizing_results: false,
  compare_sizing_results: [],
  compare_disable_button: false,
  sizing_results_data: [],
  product_image_url:[],
  config_warning_msg:false,
  sonic_velocity_warning_msg:false,
  sonic_vel_entered:false,
  amb_temp_warning_msg:false,
  high_temperature_warning_msg:false,
  high_temperature_error_msg: false,
  product_image_url_load: false,
  product_image_api_load: false,
  tube_velocity_unit: '',
  selected_row_data:[],
  was_clicked: false,
  is_applied : false,
  saved_sizing_load:false,
  flow_accuracy:'',
  density_accuracy:'',
  proceed_load:false,
  search_parameters: {
    select_technology: "Coriolis",
    flanged: false,
    wafer: false,
    reducer: false,
    dual: false,
    quad: false
  },
  default_row : true
};

export default function (state = initialState, action) {
  switch (action.type) {
    case APPLICATION: {
      return {
        ...state,
        application: action.payload.id
      }
    }
    case SAVED_SIZING_LOAD: {
      return {
        ...state,
        saved_sizing_load: action.payload.id
      }
    }
    case MASS_FLOW: {
      return {
        ...state,
        mass_flow: action.payload.id
      }
    }
    case PRODUCT_IMAGE_URL: {
      return {
        ...state,
        product_image_url: action.payload.id
      }
    }
    case CONFIG_WARNING_MSG: {
      return {
        ...state,
        config_warning_msg: action.payload.id
       }
    }
    case SONIC_VELOCITY_WARNING_MSG: {
      return {
        ...state,
        sonic_velocity_warning_msg: action.payload.id
       }
    }
    case SONIC_VELOCITY_ENTERED: {
      return {
        ...state,
        sonic_vel_entered: action.payload.id
       }
    }
    case AMBIENT_TEMP_WARNING_MSG: {
      return {
        ...state,
        amb_temp_warning_msg: action.payload.id
       }
    } 
    case HIGH_TEMP_WARNING_MSG:{
      return {
        ...state, 
        high_temperature_warning_msg: action.payload.id
      }
    }
    case HIGH_TEMPERATURE_ERROR_MSG: {
      return {
        ...state, 
        high_temperature_error_msg: action.payload.id
      }
    }
    case PROCEED_LOAD: {
      return { 
        ...state,
        proceed_load: action.payload.id
      }
    }
    case PRODUCT_IMAGE_URL_LOAD: {
      return {
        ...state,
        product_image_url_load: action.payload.id
      }
    }
    case PRODUCT_IMAGE_API_LOAD: {
      return {
        ...state,
        product_image_api_load: action.payload.id
      }
    }
    case SERVICE: {
      return {
        ...state,
        sizing_service: action.payload.id
      }
    }
    case SENSOR_TAG: {
      return {
        ...state,
        sensor_tag: action.payload.id
      }
    }
    case FLOW_ACCURACY: {
      return {
        ...state,
        flow_accuracy: action.payload.id
      }
    }
    case DENSITY_ACCURACY: {
      return {
        ...state,
        density_accuracy: action.payload.id
      }
    }
    case FLOW_ACCURACY_VALUE: {
      return {
        ...state,
       flow_accuracy_value: action.payload.id
      }
    }
    case TRANSMITTER_TAG: {
      return {
        ...state,
        transmitter_tag: action.payload.id
      }
    }
    case PROJECT_NAME: {
      return {
        ...state,
        project_name: action.payload.id
      }
    }
    case SELECTED_ROW_DATA: {
      return {
        ...state,
        selected_row_data: action.payload.id
      }
    }
    case REQUEST_SIZING_RESULTS: {
      return {
        ...state,
        request_sizing_results: action.payload.id
      }
    }
    case COMPARE_MODEL_NAMES: {
      return {
        ...state,
        compare_model_names: action.payload.id
      }
    }
    case DISPLAY_SIZING_RESULTS: {
      return {
        ...state,
        display_sizing_results: action.payload.id
      }
    }
    case SEARCH_PARAMETERS: {
      return {
        ...state,
        search_parameters: action.payload.id
      }
    }
    case WAS_CLICKED: {
      return {
        ...state,
        was_clicked: action.payload.id
      }
    }
    case IS_APPLIED: {
      return {
        ...state,
        is_applied: action.payload.id
      }
    }
    case COMPARE_SIZING_RESULTS: {
      return {
        ...state,
        compare_sizing_results: action.payload.id
      }
    }
    case COMPARE_DISABLE_BUTTON: {
      return {
        ...state,
        compare_disable_button: action.payload.id
      }
    }
    case SIZING_RESULTS_DATA: {
      return {
        ...state,
        sizing_results_data: action.payload.id
      }
    }

    case SET_DEFAULT_ROW: {
      return {
        ...state,
        default_row: action.payload.id
      }
    }

    case TUBE_VELOCITY_UNIT: {
      return {
        ...state,
        tube_velocity_unit: action.payload.id
      }
    }
    case SHOW_MORE_RATES_DATA: {
      return {
        ...state,
        show_more_rates_data: action.payload.id
      }
    }
    case SHOW_MORE_RATES_RESULT_DATA: {
      return {
        ...state,
        show_more_rates_result_data: action.payload.id
      }
    }
    case NAVBAR_FLAG:{
      return{
        ...state, 
        navbar_flag: action.payload.id
      }
    }
    case SHOW_MORE_RATES_LOADER:{
      return { 
        ...state, 
        show_more_rates_loader: action.payload.id
        }
      }  
    default: {
      return state;
    }
  }
}

