import React from 'react';
import { translationData } from '../../redux/actions';
import { connect } from 'react-redux';
import US_DATA from '../../translation_US_Data.json';

const CustomTranslation = (props) => {

  return (
    <span
      dangerouslySetInnerHTML={{
        __html:
          props.translation_data.length !== 0
            ? props.translation_data
              .filter((name) => name.message_name === props.data)
              .map((data) => data.message_text).length !== 0
              ? props.translation_data
                .filter((name) => name.message_name === props.data)
                .map((data) => data.message_text)
              : US_DATA.filter((name) => name.message_name === props.data).map(
                (data) => data.message_text
              )
            : US_DATA.filter((name) => name.message_name === props.data).map(
              (data) => data.message_text
            ),
      }}
    ></span>
  );
};

const mapStateToProps = (state) => ({
  translation_data: state.TranslationData.translation_data,
});

export default connect(mapStateToProps, {
  translationData,
})(CustomTranslation);
