import React,{useEffect} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import { equipmentSelection, translationData,selectBasicPage } from '../redux/actions';
import { CustomRadiobutton } from '../components/CustomRadiobutton/CustomRadiobutton';
import CustomTranslation from '../components/CustomTranslation/CustomTranslation';
import CustomTranslationText from '../components/CustomTranslation/CustomTranslationText';
import US_DATA from '../translation_US_Data.json';

const FLOW_METER_TEXT = "Flow Meter (Includes Sensor and Transmitter)";
const EquipmentSelection = (props) => {
  let select_technology_id =
    props.select_technology === "Coriolis" ?
      "XXSPECIFY_MMI_MC_RDIO_CORIOLIS" :
      props.select_technology === "Density" ?
        "XXSPECIFY_MMI_MC_TXT_BRCDEN" :
        props.select_technology === "Magnetic" ?
          "XXSPECIFY_MMI_MC_RDIO_MAGNETIC" :
          props.select_technology === "Viscosity" ?
            "XXSPECIFY_MMI_MC_RDIO_VSY" :
            props.select_technology === "Vortex" ?
              "XXSPECIFY_MMI_MC_RDIO_VORTEX" :
              "";

  const FLOW_TEXT = props.translation_data.length !== 0
    ? props.translation_data
      .filter((name) => name.message_name === "XXSPECIFY_MMI_MC_HEAD_FMS")
      .map((data) => data.message_text).length !== 0
      ? props.translation_data
        .filter((name) => name.message_name === "XXSPECIFY_MMI_MC_HEAD_FMS")
        .map((data) => data.message_text)
      : US_DATA.filter((name) => name.message_name === "XXSPECIFY_MMI_MC_HEAD_FMS").map(
        (data) => data.message_text
      )
    : US_DATA.filter((name) => name.message_name === "XXSPECIFY_MMI_MC_HEAD_FMS").map(
      (data) => data.message_text
    );
  
  let select_technology = props.translation_data.length !== 0
    ? props.translation_data
      .filter((name) => name.message_name === select_technology_id)
      .map((data) => data.message_text).length !== 0
      ? props.translation_data
        .filter((name) => name.message_name === select_technology_id)
        .map((data) => data.message_text)
      : US_DATA.filter((name) => name.message_name === select_technology_id).map(
        (data) => data.message_text
      )
    : US_DATA.filter((name) => name.message_name === select_technology_id).map(
      (data) => data.message_text
    );
  // let meterLabel = `${props.select_technology} ${FLOW_METER_TEXT}`;
  let flow_text= CustomTranslationText('XXSPECIFY_MMI_MC_CHKBX_FLOW',props);
  let meterLabel = `${select_technology} ${flow_text} ${FLOW_TEXT}`;
  let sensorLabel = <CustomTranslation data="XXSPECIFY_MMI_MC_TXT_SPSENSOR" />;

  if (
    props.select_technology.toLowerCase() === "coriolis") {
    meterLabel = `${select_technology} ${flow_text} ${FLOW_TEXT}`;
    sensorLabel = <CustomTranslation data="XXSPECIFY_MMI_MC_TXT_SPSENSOR" />;
  } else if ( props.select_technology.toLowerCase() === "density" ||
  props.select_technology.toLowerCase() === "viscosity"){
    meterLabel = `${select_technology} ${flow_text} ${FLOW_TEXT}`;
    sensorLabel = <CustomTranslation data="XXSPECIFY_MMI_MC_TXT_SPSENSOR" />;
  }
  else if (props.select_technology.toLowerCase() === "magnetic") {
    meterLabel = <CustomTranslation data="XXSPECIFY_MMI_MC_RDIO_MAGSYS" />;
    sensorLabel = <CustomTranslation data="XXSPECIFY_MMI_MC_TXT_SENSOR" />;
  }

  const defaultMeter = () => {
    let returnValue = ""
    if (props.equipment_selection&&props.select_technology!=='') {
      if (props.select_technology.toLowerCase() === 'magnetic') {
        if (props.equipment_selection === 'Sensor') {
          returnValue = false
        } else {
          returnValue = "Magmeter System"
        }
      } else {
        if (props.equipment_selection === 'Sensor Only / MVDSolo') {
          returnValue = false
        } else {
          returnValue = `${props.select_technology} ${FLOW_METER_TEXT}`
        }
      } 
    } else if(props.select_technology!=='') {
      returnValue = `${props.select_technology} ${FLOW_METER_TEXT}`
    }
    return returnValue;
  };

  const defaultSensor = () => {
    return (
      props.equipment_selection === "Sensor Only / MVDSolo" ||
      props.equipment_selection === "Sensor"
    );
  };

  const equipmentSelection = (equip) => {
    props.equipmentSelection(equip);
  };
  useEffect(() => {
    if (Object.keys(props.get_sizing_data).length>0) {
      let data=props.get_sizing_data[0]
    let techonogy= data.flow_meter === 'Y' ? 'radio-flow-meter' :
      data.spare_sensor === 'Y' ? 'radio-sensor-only' : "";
      isChecked(techonogy);
    }
  }, [props.get_sizing_data,props.select_technology]);
  const isChecked = (checked) => {
    if (
      checked === "radio-sensor-only" &&
      props.select_technology.toLowerCase() !== "magnetic"
    ) {
      equipmentSelection("Sensor Only / MVDSolo");
    } else if (
      checked === "radio-sensor-only" &&
      props.select_technology.toLowerCase() === "magnetic"
    ) {
      equipmentSelection("Sensor");
    } else if (
      checked === "radio-flow-meter" &&
      props.select_technology.toLowerCase() !== "magnetic"
    ) {
      equipmentSelection(`${props.select_technology} ${FLOW_METER_TEXT}`);
    } else {
      equipmentSelection("Magmeter System");
    }
  };

  const radioInputButtons = (id, label) => {
    return (
      <>
        <CustomRadiobutton
          text={label}
          type='radio'
          name='equipment-selection'
          id={id}
          className='custom-radiobutton'
          onChange={(e) => isChecked(e.target.id)}
          checked={label === meterLabel ? defaultMeter() : defaultSensor()}
        />
      </>
    );
  };

  return (
    <div className='equipment-selection'>
      <div className='title'>
        <CustomTranslation data='XXSPECIFY_MMI_MC_PANEL2_ET' />
      </div>
      <Container className='selection-container'>
        <Row>
          <Col>{radioInputButtons("radio-flow-meter", meterLabel)}</Col>
        </Row>
        <Row>
          <Col>{radioInputButtons("radio-sensor-only", sensorLabel)}</Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  select_technology: state.MeasurementType.select_technology,
  equipment_selection: state.EquipmentSelection.equipment_selection,
  translation_data: state.TranslationData.translation_data,
  get_sizing_data: state.MySizings.get_sizing_data,
});

export default connect(mapStateToProps, {
  equipmentSelection,
  translationData,
  selectBasicPage
})(EquipmentSelection);
