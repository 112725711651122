import {
  FETCH_DROPDOWN_DATA,
  UNITS_DATA
} from '../actionTypes';

const initialState = {
  dropdown_data: [],
  units_data: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_DROPDOWN_DATA: {
      return {
        ...state,
        dropdown_data: action.payload.id
      }
    }
    case UNITS_DATA: {
      return {
        ...state,
        units_data: action.payload.id
      }
    }
    default: {
      return state;
    }
  }
}
