import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import {
  sizingApplication,
  sizingService,
} from '../../redux/actions'; 
import SizingResultsTableData from './SizingResultsTableData';
import ShowMoreRates from '../ShowMoreRates/ShowMoreRates';
import ViewGraph from '../ViewGraph/ViewGraph';
import InstallationDetails from '../InstallationDetails/InstallationDetails';
import CustomLoader from '../../components/CustomLoader/CustomLoader';
import CustomTranslationText from '../../components/CustomTranslation/CustomTranslationText';

const SizingResults = props => {
  const [showLoader, setShowLoader] = useState(true);
  const { search_parameters } = props

  const isDisabled=()=>{
    let result=
    search_parameters.select_technology ==='Magnetic'? 
    (Object.keys(props.show_more_rates_data).length >0 ?props.show_more_rates_data[0].product===props.selected_row_data.parent_model?true:false:false)
    :search_parameters.select_technology ==='Vortex'? 
    (Object.keys(props.show_more_rates_data).length >0 ?props.show_more_rates_data[0].product===props.selected_row_data.parent_model?true:false:false) :(props.show_more_rates_data.length > 0?props.show_more_rates_data[0].product===props.selected_row_data.product?true:false:false)
    
    return result
    
  }

  return (
    <>
      <div className="sizing-results-header LineBottom">
        <SizingResultsTableData
          onClickSizingResults={props.onClickSizingResults}
          technology={props.technology}
          tech_info={props.tech_info}
          language_info={props.language_info}
          vortex_info={props.vortex_info}
        />
        {props.was_clicked === false ? (
          <>
            {(search_parameters.select_technology === 'Density' && props.selected_row_data.product === 'SGM') ? (

              <ShowMoreRates />

            ) : null}
            {Object.keys(props.show_more_rates_data).length > 0 && props.sizing_results_data.length > 0 && isDisabled() ?
              <>
                <ShowMoreRates />
                {
                  (search_parameters.select_technology === 'Viscosity' && props.selected_row_data.product === 'FVM') ||
                    (search_parameters.select_technology === 'Viscosity' && props.selected_row_data.product === 'HFVM') ||
                    (search_parameters.select_technology === 'Density' && props.selected_row_data.product === 'FDM') || 
                    (search_parameters.select_technology === 'Density' && props.selected_row_data.product === 'GDM') ? (
                    <></>
                  ) : (
                    <>
                      <ViewGraph />
                    </>
                  )}
              </>
              :
              props.density && props.fluid_state.toLowerCase() === 'gas' ?
                <span /> :
                !props.show_more_rates_loader ?
                  <span /> :
                  props.density && props.fluid_state.toLowerCase() === 'gas' ?
                    <span /> :
                    <>
                      <Row className="loader">
                        <Col xl={12} md={12}>
                          <CustomLoader phase={showLoader} loaderText="innerText" loaderClass="innerLoaderImg" subTitle={CustomTranslationText('XXSPECIFY_MMI_PR_PLS_WAIT', props)} />
                        </Col>
                      </Row>
                    </>
            }

          </>
        ) : (props.sizing_results_data.length > 0 ?
          props.show_more_rates_data.length === 0 && !props.tank ?
              props.show_more_rates_loader ?
              <span /> :
              <>
                <Row className="loader">
                  <Col xl={12} md={12}>
                    <CustomLoader phase={showLoader} loaderText="innerText" loaderClass="innerLoaderImg" subTitle={CustomTranslationText('XXSPECIFY_MMI_PR_PLS_WAIT', props)} />
                  </Col>
                </Row>
              </>
            : <span />
          : <span />
        )}

        {
         ((search_parameters.select_technology === 'Viscosity' && props.selected_row_data.product === 'FVM') ||
          (search_parameters.select_technology === 'Viscosity' && props.selected_row_data.product === 'HFVM') ||
          (search_parameters.select_technology === 'Density' && props.selected_row_data.product === 'FDM')) && (
            <>
              <span />
              <InstallationDetails />
            </>
          )}
      </div>
    </>
  )
}
const mapStateToProps = state => ({
  application: state.SizingResults.application,
  was_clicked: state.SizingResults.was_clicked,
  viscosity: state.MeasurementType.viscosity,
  density: state.MeasurementType.density,
  search_parameters: state.SizingResults.search_parameters,
  selected_row_data: state.SizingResults.selected_row_data,
  show_more_rates_data: state.SizingResults.show_more_rates_data,
  fluid_state: state.FluidSelection.fluid_state,
  sizing_results_data: state.SizingResults.sizing_results_data,
  translation_data: state.TranslationData.translation_data,
  tank: state.ApplicationRequirements.tank,
  showMoreRatesLoader: state.SizingResults.showMoreRatesLoader,
});

export default connect(
  mapStateToProps,
  {
    sizingApplication,
    sizingService,
  }
)(SizingResults);
