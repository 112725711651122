import {
  ADVANCED_LAYOUT
} from '../actionTypes';

const initialState = {
  advanced_layout: true
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADVANCED_LAYOUT: {
      return {
        ...state,
        advanced_layout: action.payload.id
      }
    }
    default: {
      return state;
    }
  }
}
