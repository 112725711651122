import { useEffect, useState } from 'react';
import axios from 'axios';


const saveToDatabase = async (objValues,props,totalMoles,totalMoleWeight) => {
    let Results=""
    objValues.natural_gas_name=props.natural_gas_composition_name;
    objValues.composition_method=props.composition_method;
    objValues.total_mole=totalMoles;
    objValues.molecular_weight=totalMoleWeight;
    objValues.methane=objValues.methane?objValues.methane:''
    objValues.nitrogen=objValues.nitrogen?objValues.nitrogen:''
    objValues.carbon_dioxide=objValues.carbon_dioxide?objValues.carbon_dioxide:''
    objValues.ethane=objValues.ethane?objValues.ethane:''
    objValues.propane=objValues.propane?objValues.propane:''
    objValues.water=objValues.water?objValues.water:''
    objValues.hydrogen_sulphide=objValues.hydrogen_sulphide?objValues.hydrogen_sulphide:''
    objValues.hydrogen=objValues.hydrogen?objValues.hydrogen:''
    objValues.carbon_monoxide=objValues.carbon_monoxide?objValues.carbon_monoxide:''
    objValues.oxygen=objValues.oxygen?objValues.oxygen:''
    objValues.i_butane=objValues.i_butane?objValues.i_butane:''
    objValues.n_butane=objValues.n_butane?objValues.n_butane:''
    objValues.i_pentane=objValues.i_pentane?objValues.i_pentane:''
    objValues.n_pentane=objValues.n_pentane?objValues.n_pentane:''
    objValues.n_hexane=objValues.n_hexane?objValues.n_hexane:''
    objValues.n_heptane=objValues.n_heptane?objValues.n_heptane:''
    objValues.n_octane=objValues.n_octane?objValues.n_octane:''
    objValues.n_nonane=objValues.n_nonane?objValues.n_nonane:''
    objValues.n_decane=objValues.n_decane?objValues.n_decane:''
    objValues.helium=objValues.helium?objValues.helium:''
    objValues.argon=objValues.argon?objValues.argon:''
    objValues.relative_density=objValues.relative_density?objValues.relative_density:''
    objValues.mole_carbon_dioxide=objValues.mole_carbon_dioxide?objValues.mole_carbon_dioxide:''
    objValues.heating_value=objValues.heating_value?objValues.heating_value:''
    objValues.heating_value_unit=objValues.heating_value_unit?objValues.heating_value_unit:''
    objValues.mole_hydrogen=objValues.mole_hydrogen?objValues.mole_hydrogen:''
    objValues.mole_nitrogen=objValues.mole_nitrogen?objValues.mole_nitrogen:''
    objValues.mole_carbon_monoxide=objValues.mole_carbon_monoxide?objValues.mole_carbon_monoxide:''
    objValues.email_id=props.email
  

    const fetchData = async () => {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/mmipa/save_custom_natural_gas/`,
        headers: {
          'Content-Type': 'application/json'
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        data: objValues,
      })
        .then(response => {
            Results=response.data.data
        })
        .catch(error => {
          console.log('error', error);
        });
    }
    await fetchData();
    
    return Results.length > 0 ? Results : '';
  };
  
  export default saveToDatabase;