import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Modal } from 'react-bootstrap';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import jsonInputs from '../../JSONForTesting/jsonInputs';
import { CustomDropdown } from '../../components/CustomDropdown/CustomDropdown';
import { CustomInputField } from '../../components/CustomInputField/CustomInputField';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import ErrorModal from '../../components/CustomModals/PreferencesModal';
import GasCompositionValues from './GasCompositionValues';
import GasCompositionCompSelection from './GasCompositionCompSelection';
import CustomTranslationText from '../../components/CustomTranslation/CustomTranslationText';
import {
  naturalGasCompositionName,
  naturalGasBorderError,
  compositionMethod,
  databaseGasses,
  heatingValueDropdown,
  molePercentCO2,
  heatingValue,
  relativeDensity,
  molePercentH2,
  molePercentCO,
  molePercentN2,
  databaseGassesList,
  isSelected,
  availableComponents,
  moleArrayRedux,
  selectedDatabaseNaturalGas, 
  compositionMethodFlag
} from '../../redux/actions';
import axios from 'axios';
import saveToDatabase from './saveToDatabase';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';
import { NGAS_COMP_METHOD_MESSAGES } from '../../constants/constants';


const GasComposition = (props) => {
  const [compositionMethod, setCompositionMethod] = useState('');
  const [data, setData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [databaseGas, setDatabaseGasses] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMolelState, setErrorMolelState] = useState(false);
  const [ngasCompMethodData, setNgasCompMethodData] = useState([]);
  const [compositionErros, setCompositionErros] = useState([]);

  function usePrevious(value) {
    const ref = React.useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  const prevHeatingUnit = usePrevious(props.heating_value_dropdown);

  useEffect(() => {
    let data = {};
    data.email_id = props.email;
    const fetchData = async () => {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/mmipa/get_custom_natural_gas/`,
        headers: {
          'Content-Type': 'application/json',
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        data: data,
      })
        .then(async (response) => {
       let data= typeof(response.data.data)==='object'?
         response.data.data:[]
         props.databaseGassesList(data)
        })
        .catch((error) => {
          console.log('error');
        });
    };

    fetchData();
  }, [showModal]);

  useEffect(() => {
    if (Object.keys(props.get_sizing_data).length > 0) {
     
      let data = props.get_sizing_data[0];
      const fetchData = async () => {
           props.compositionMethod(data.ngas_composition_method)
          }
          fetchData();
    }
  }, [props.get_sizing_data]);


  useEffect(() => {
    if(props.heating_value_dropdown !== prevHeatingUnit && props.heating_value !== ""){
      let payload = {
        "input_units" : prevHeatingUnit,
        "output_units" : props.heating_value_dropdown,
        "input_value" : props.heating_value
      };
      const fetchData = async () => {
        axios({
          method: 'post',
          url: `${process.env.REACT_APP_BASE_URL}/mmipa/heating_value_converter/`,
          headers: {
            'Content-Type': 'application/json',
          },
          xsrfHeaderName: 'X-CSRFToken',
          xsrfCookieName: 'csrftoken',
          data: payload,
        })
          .then((response) => {
            let res = response.data.Data
            let updatedCompData = ngasCompMethodData
            let index = updatedCompData.findIndex(i => i.name === 'heating_value')
            updatedCompData[index].min = res.heating_value_limits.min
            updatedCompData[index].max = res.heating_value_limits.max
            updatedCompData[index].message = res.heating_value_limits.message
            setNgasCompMethodData([...updatedCompData]);
            props.heatingValue(res.converted_value);  
          })
          .catch(function (error) {
            console.log(':::error', error);
          });
      }
        fetchData();
    }
  }, [props.heating_value_dropdown]);

  useEffect(() => {
    if (Object.keys(props.get_sizing_data).length > 0) {
     let data = props.get_sizing_data[0];
        props.compositionMethod(data.ngas_composition_method)
  }
  }, [props.get_sizing_data]);

  useEffect(() => {
      props.naturalGasBorderError(false);
  }, [])

  useEffect(() => {
    let availableComponentsArray = [];
    props.database_gasses_list.map((option) => {
            availableComponentsArray.push(option.ngas_list_name);
          });

    if (availableComponentsArray.length > 0) {
      setDatabaseGasses(availableComponentsArray);      
    }
  }, [props.database_gasses_list, props.databaseGasses]);

  useEffect(() => {
    setCompositionMethod(props.composition_method)
    if (
      compositionMethod === 'ISO 12213, Physical Properties Method (SGERG 88)'
    ) {
      if(!props.composition_method_flag){
      props.heatingValueDropdown('MJ/M3');
      }
    }
    if (
      compositionMethod === 'AGA Report No.8 Gross Characterization Method 1'
    ) {
      if(!props.composition_method_flag){
      props.heatingValueDropdown('BTU/FT3');
      }
    }
}, [compositionMethod, props.composition_method]);

useEffect(() => {
  if(props.composition_method === 'ISO 12213, Physical Properties Method (SGERG 88)' ||
     props.composition_method ==='AGA Report No.8 Gross Characterization Method 1'){
    if(props.composition_method_flag){
      setTimeout(() => {
      props.compositionMethodFlag(false)
      }, 500);
    }
  }
}, [props.composition_method_flag])


  useEffect(() => {
    setCompositionMethod(props.composition_method);
    const languageCode = props.user_language && props.user_language.value
    ? props.user_language.value
    : 'US';
    if (props.composition_method) {
      const fetchData = async () => {
        const data = {
          language:  props.query_params.language?props.query_params.language:languageCode,
          composition_method: props.composition_method,
        };
        await axios({
          method: 'post',
          url: `${process.env.REACT_APP_BASE_URL}${NGAS_COMP_METHOD_MESSAGES}`,
          headers: {
            'Content-Type': 'application/json',
          },
          xsrfHeaderName: 'X-CSRFToken',
          xsrfCookieName: 'csrftoken',
          data: data,
        })
          .then((response) => {
            setNgasCompMethodData(response.data.data);
          })
          .catch((error) => {
            console.log('error', error);
          });
      };
      fetchData();
    }
  
  }, [props.composition_method])

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      loadNewGas()  
    } 
  }, [data])
  useEffect(() => {
    if (props.mole_array_redux !== data && Object.keys(data).length > 0) {
      setData(props.mole_array_redux)
    }
  },[props.mole_array_redux])
  // replace this with API
  const optionsCompositionMethod = jsonInputs.data
    .find((x) => x['composition_method'])
    ['composition_method'].map((x) => x.value);

    useEffect(() => {
     let data=  showModal?
      props.naturalGasCompositionName(props.database_gasses):'';
    }, [props.database_gasses])
    
  const handleNaturalGasNameChange = (event) =>
    props.naturalGasCompositionName(event.target.value);

  const handleCompositionMethodChange = (key) => {
    if(optionsCompositionMethod[key] == 'Pick One'){
      props.compositionMethod('');
    }else{
      props.compositionMethod(optionsCompositionMethod[key]);
    }
     props.relativeDensity("")
     props.molePercentCO2("");
     props.molePercentN2("");
     props.heatingValue("");
     props.molePercentH2("");
     props.molePercentCO("");
     setCompositionErros([])
  };
  const handlePickFromDatabase = () => {
    setShowModal(!showModal);
  };
  const handleDatabaseGassesChange = (key) =>{
    props.databaseGasses(databaseGas[key ? key : 0]);
    };
  const handleClose = () => setShowModal(false);
  const handleSelect = () => {
    processNewGas()
  };
  const processNewGas =  () => {
    let data=props.database_gasses_list.filter((option) => {
      return option.ngas_list_name===props.database_gasses
    })

    if (data.length === 0) {
      setShowModal(false)
    } else {
      setShowModal(false);
      setData(data[0])
      props.moleArrayRedux(data[0])
      }
  }
  const loadNewGas = async () => {
    let key=optionsCompositionMethod.findIndex((option)=> option===data.composition_method)
    let relativeDensityVar = data.relative_density.toString(); 
    let carbonDioxideVar = data.carbon_dioxide.toString();
    let nitrogenVar = data.nitrogen.toString();
    let hydrogenVar = data.hydrogen.toString();
    let carbonMonoxideVar = data.carbon_monoxide.toString();
    let ngasListNameVar = data.ngas_list_name.toString();
    handleCompositionMethodChange(key)
    await props.relativeDensity(relativeDensityVar)
    await props.molePercentCO2(carbonDioxideVar);
    await props.molePercentN2(nitrogenVar)
    await props.heatingValue(carbonDioxideVar);
    await props.molePercentH2(hydrogenVar);
    await props.molePercentCO(carbonMonoxideVar);
    await props.naturalGasCompositionName(ngasListNameVar)
    await props.isSelected(true)  
    await props.isSelected(false)  
  }
  
  const buttonPickFromDBStyle = {
    maxWidth: '300px',
    borderRadius: 0,
    letterSpacing: '.5px',
    fontSize: '14px',
    height: '50px',
    marginTop: '33px',
  };

  const compMethodDropdownStyles = {
    width: '100% !important',
    borderRadius: 0,
  };

  const modalNaturalGasNamesStyles = {
    height: '30px !important', //TODO not reading this height right
    width: '200px',
  };

  const compDefaultText = compositionMethod ? compositionMethod : 'Pick One';

  const handleCompositionErrors = (id, name, val) => {
    let obj ={}
    obj.id = id
    if(val === ''){
      obj.errMsg = CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0',props)
    }else{
      obj.errMsg = ''
    }
     ngasCompMethodData.map(i => {
      if(i.name == name){
        if(val === ''){
          obj.errMsg = CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0',props)
        }else if(!(i.min <= parseFloat(val) && parseFloat(val) <= i.max)){
          obj.errMsg = i.message
        }else {
          obj.errMsg = ''
        }
      }
     });
     return obj;
  }

  const validateCompositions = () => {
    const nitrogen =   {
      id : 'mole-n2-input',
      name: 'nitrogen',
      val : props.mole_percent_N2
    };

    const heatingValue = {
      id : 'heating-value-input',
      name: 'heating_value',
      val : props.heating_value
    };

    const carbonMonoxide =   {
      id : 'mole-co-input',
      name: 'carbon_monoxide',
      val : props.mole_percent_CO
    };

    const compositionsArray = [
      {
        id : 'relative-density-input',
        name: 'relative_density',
        val : props.relative_density
      },
      {
        id : 'mole-co2-input',
        name: 'carbon_dioxide',
        val : props.mole_percent_CO2
      },
      {
        id : 'more-h2-input',
        name: 'hydrogen',
        val : props.mole_percent_H2
      }
    ]

    if(compositionMethod === 'AGA Report No.8 Gross Characterization Method 1'){
      compositionsArray.push(heatingValue)
      compositionsArray.push(carbonMonoxide)
    }else if(compositionMethod === 'AGA Report No.8 Gross Characterization Method 2'){
      compositionsArray.push(nitrogen)
      compositionsArray.push(carbonMonoxide)
    }else if(compositionMethod === 'ISO 12213, Physical Properties Method (SGERG 88)'){
      compositionsArray.push(heatingValue)
    }

    let compErrors = []
    compositionsArray.map(i => {
      let obj = handleCompositionErrors(i.id, i.name, i.val)
      compErrors.push(obj)
    })
    let checkErrorMsg = false;
    compErrors.map(composition => {
      if(composition.errMsg !== ""){
        checkErrorMsg = true
      }
    })
    setCompositionErros([...compErrors])
    return checkErrorMsg;
  }

  const SaveToDatabase = async () => {
    let errs = validateCompositions();
    if(!errs){
      const objValues = {};
      if (props.natural_gas_composition_name != '') {
        objValues.relative_density = props.relative_density
          ? props.relative_density
          : '';
        objValues.mole_carbon_dioxide = props.mole_percent_CO2
          ? props.mole_percent_CO2
          : '';
        objValues.heating_value = props.heating_value ? props.heating_value : '';
        objValues.heating_value_unit = props.heating_value_dropdown
          ? props.heating_value_dropdown
          : '';
        objValues.mole_hydrogen = props.mole_percent_H2
          ? props.mole_percent_H2
          : '';
        objValues.mole_nitrogen = props.mole_percent_N2 ? props.mole_percent_N2 : '';
        objValues.mole_carbon_monoxide = props.mole_percent_CO
          ? props.mole_percent_CO
          : '';

          const Results = await saveToDatabase(objValues, props, '', '');
          if (Results) {
            setErrorMolelState(true);
            setErrorMessage(Results);
          }
      } else if (props.natural_gas_composition_name === '') {
        setErrorMolelState(true);
        setErrorMessage(CustomTranslationText('XXSPECIFY_MMI_PR_NAT_NAME_LBL',props));
      }
    }
  };

  const handleErrorClose = () => {
    setErrorMolelState(false);
    setErrorMessage('');
  };

  const handleErrorShow = () => {
    setErrorMolelState(true);
  };

  return (
    <>
      <ErrorModal
        show={errorMolelState}
        handleClose={handleErrorClose}
        handleShow={handleErrorShow}
        message={errorMessage}
      />
      <Modal show={showModal} onHide={handleClose} centered animation={false}>
        <Modal.Header className='modal-header-fpa' closeButton>
          <Modal.Title className='modal-title'>
            <CustomTranslation data='XXSPECIFY_MMI_PR_DB_GASES' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-fpa'>
          <CustomDropdown
            clear={false}
            title=''
            id={'modal-natural-gas-names'}
            text={props.database_gasses}
            dropdownArray={databaseGas}
            eventHandler={handleDatabaseGassesChange}
            disabled={false}
            borderRequired={true}
            borderError={false}
            active=''
            styles={modalNaturalGasNamesStyles}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='outline-dark'
            onClick={handleClose}
            className='btn-modal btn-arrow'
            disabled={false}
          >
            <CustomTranslation data='XXSPECIFY_MMI_PR_CANCEL' />
            <span className='right-arrow' />
          </Button>
          <Button
            onClick={handleClose}
            className='btn-modal btn-delete-select-modal'
          >
            <CustomTranslation data='XXSPECIFY_MMI_PR_DELETE' />
          </Button>
          <Button
            onClick={handleSelect}
            className='btn-modal btn-delete-select-modal'
          >
            <CustomTranslation data='XXSPECIFY_MMI_PR_SELECT' />
          </Button>
        </Modal.Footer>
      </Modal>
      <Row>
        <Col md={6} sm={6} xs={12}>
          <CustomInputField
            name={<CustomTranslation data='XXSPECIFY_MMI_COM_NAT_GAS_NAME' />}
            size='lg'
            disabled={false}
            borderRequired={true}
            borderError={!props.natural_gas_composition_name && props.natural_gas_name_border_error}
            id='natural-gas-name-inputfield'
            labelClassName='natural-gas-label'
            fieldClassName='natural-gas-fieldname'
            onChange={handleNaturalGasNameChange}
            value={props.natural_gas_composition_name}
          />
        </Col>
        <Col md={6} sm={6} xs={12}>
          <CustomButton
            classNames='btn-arrow'
            label={<CustomTranslation data='XXSPECIFY_MMI_COM_PICK_FROM_DB' />}
            onChange={handlePickFromDatabase}
            onClick={handlePickFromDatabase}
            toggle={false}
            size='lg'
            variant='outline-dark'
            arrow='right'
            disabled={false}
            styles={buttonPickFromDBStyle}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={11}>
          <CustomDropdown
            title={CustomTranslationText('XXSPECIFY_MMI_COM_CMPO_MTD',props)}
            id='composition-method-dropdown'
            dropdownArray={optionsCompositionMethod}
            eventHandler={handleCompositionMethodChange}
            disabled={false}
            borderRequired={true}
            borderError={false}
            text={compDefaultText}
            styles={compMethodDropdownStyles}
          />
        </Col>
      </Row>
      {compositionMethod ===
        'AGA Report No.8 Gross Characterization Method 1' ||
      compositionMethod === 'AGA Report No.8 Gross Characterization Method 2' ||
      compositionMethod ===
        'ISO 12213, Physical Properties Method (SGERG 88)' ? (
        <>
          <GasCompositionValues compositionErros={compositionErros} handleCompositionErrors={handleCompositionErrors}/>{' '}
          <Row>
            <Col sm={12}>
              <CustomButton
                classNames='btn btn-small-fpa btn-arrow btn-custom-gas btn-white-fpa'
                label={CustomTranslationText('XXSPECIFY_MMI_COM_SAVE_TO_DB',props)}
                onClick={SaveToDatabase}
                variant='outline-dark'
                arrow='right'
                size='sm'
              />
            </Col>
          </Row>
        </>
      ) : compositionMethod ===
          'AGA Report No.8 Detail Characterization Method' ||
        compositionMethod === 'ISO 12213, Molar Composition Method' ? (
        <GasCompositionCompSelection ngasCompMethodData={ngasCompMethodData} />
      ) : (
        <span />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  natural_gas_composition_name:
    state.FluidSelection.natural_gas_composition_name,
  composition_method: state.FluidSelection.composition_method,
  database_gasses: state.FluidSelection.database_gasses,
  database_gasses_list: state.FluidSelection.database_gasses_list,
  natural_gas_components: state.FluidSelection.natural_gas_components,
  relative_density: state.FluidSelection.relative_density,
  mole_percent_CO2: state.FluidSelection.mole_percent_CO2,
  heating_value: state.FluidSelection.heating_value,
  mole_percent_H2: state.FluidSelection.mole_percent_H2,
  mole_percent_N2: state.FluidSelection.mole_percent_N2,
  mole_percent_CO: state.FluidSelection.mole_percent_CO,
  get_sizing_data: state.MySizings.get_sizing_data,
  heating_value_dropdown: state.FluidSelection.heating_value_dropdown,
  email:state.AppHeader.email,
  translation_data: state.TranslationData.translation_data,
  get_sizing_data: state.MySizings.get_sizing_data,
  selected_database_natural_gas: state.FluidSelection.selected_database_natural_gas,
  mole_array_redux: state.FluidSelection.mole_array_redux,
  natural_gas_name_border_error: state.FluidSelection.natural_gas_name_border_error,
  composition_method_flag: state.FluidSelection.composition_method_flag,
  query_params: state.Preferences.query_params,
  user_language: state.AppHeader.user_language
});

export default connect(mapStateToProps, {
  naturalGasCompositionName,
  naturalGasBorderError,
  compositionMethod,
  databaseGasses,
  isSelected,
  heatingValueDropdown,
  molePercentCO2,
  heatingValue,
  relativeDensity,
  molePercentH2,
  molePercentCO,
  molePercentN2,
  databaseGassesList,
  moleArrayRedux,
  selectedDatabaseNaturalGas,
  compositionMethodFlag,
})(GasComposition);
