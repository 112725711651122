import React from 'react';
import Alert from '../../images/Alert.png';
import './tooltip.scss';

const NgasComposionMethodErrorMessage = props => {

  return (
    <div>
      {props.errorTip ? (         
          <div className="left">
            <span className="left alert-icon">
              <img
                src={Alert}
                width={12}
                height={12}
                alt='Alert'
              />
            </span>
            <p className='title'>
              {props.message}
            </p>
          </div>
        ) :
         <div className="left">
            <p className='title'>
              {props.message}
            </p>
          </div>
    }
    </div>
  )
}

export default NgasComposionMethodErrorMessage;
