import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import pictureComingSoon from "../../images/pictureComingSoon.png";
import { selectedRowData } from "../../redux/actions";
import InstallationDetailsTable from "./InstallationDetailsTable";
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';
import CustomTranslationText from "../../components/CustomTranslation/CustomTranslationText";
import { jsPDF } from 'jspdf';
import "./Installation.scss";
import "../../styles/App.scss";

const InstallationDetails = (props) => {
  const [rowData, setRowData] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [colums, setColumn] = useState([]);
  const [data, setData] = useState([]);
  const [header, setHeader] = useState({});

  //us colums 
  const [rowDataUS, setRowDataUS] = useState([]);
  const [headerDataUS, setHeaderDataUS] = useState([]);
  const [headerUS, setHeaderUS] = useState({});

  useEffect(() => {
    let row = props.selected_row_data;
    const languageCode = props.user_language && props.user_language.value
      ? props.user_language.value
      : 'US';
    setRowData([]);
    setHeaderData([]);
    setHeader("");
    const fetchData = async () => {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/mmipa/installation/`,
        headers: {
          "Content-Type": "application/json",
        },
        xsrfHeaderName: "X-CSRFToken",
        xsrfCookieName: "csrftoken",
        data:
        {
          "productfamily": row.product,
          "language_code": props.query_params.language ? props.query_params.language : languageCode,
          "tank": props?.tank ? "True" : "False",
          "inline": props.in_line ? "True" : "False",
          "slipstream": props.flow_through_chamber_slipstream ? "True" : "False",
          "flowthrough": props.flow_through_chamber_slipstream ? "True" : "False",
          "fluid_state": props.fluid_state.toUpperCase(),
          "density_norm": props.normal_density ,
          "density_units": props.units_density !== "" ? props.units_density.key : "",
          "specific_gravity": props.specific_gravity !== ''? parseFloat(props.specific_gravity) :'' ,
          "flow_rate": parseInt(props.normal_flow_rate),
          "flow_rate_units": props.units_flow_rate.key,
          "slurry": props.fluid_state.toUpperCase() === "SLURRY" ? "True" : "False",
          "line_size": props.line_size,
          "measurement_type": props.density ? "density" : "viscosity",
          "technology": props.select_technology.toLowerCase(),
          "flow_rate_min": parseInt(props.min_flow_rate),
          "flow_rate_max": parseInt(props.max_flow_rate),
          "flow_rate_norm": parseInt(props.normal_flow_rate),
          "flow_rate_fsd": parseInt(props.full_flow_rate),
          "ref_density": props.gas_density ? parseFloat(props.gas_density) : '',
          "ref_density_units": props.units_gas_density !== undefined ? props.units_gas_density.key : ''
        }
      })
        .then(function (response) {
          setRowData(response.data.Data.DataRow ? response.data.Data.DataRow : []);
          setHeaderData(response.data.Data.InstallationGrid ? response.data.Data.InstallationGrid.HeaderRow : []);
          setHeader(response.data.Data.InstallationHeader ? response.data.Data.InstallationHeader : []);
        })
        .catch(function (error) {
          console.log("error", error);
        });
    };

    //US data 
    const fetchDataUS = async () => {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/mmipa/installation/`,
        headers: {
          "Content-Type": "application/json",
        },
        xsrfHeaderName: "X-CSRFToken",
        xsrfCookieName: "csrftoken",
        data:
        {
          "productfamily": row.product,
          "language_code": "US",
          "tank": props?.tank ? "True" : "False",
          "inline": props.in_line ? "True" : "False",
          "slipstream": props.flow_through_chamber_slipstream ? "True" : "False",
          "flowthrough": props.flow_through_chamber_slipstream ? "True" : "False",
          "fluid_state": props.fluid_state.toUpperCase(),
          "density_norm": props.normal_density,
          "density_units": props.units_density !== "" ? props.units_density.key : "",
          "specific_gravity": props.specific_gravity !== ''?  parseFloat(props.specific_gravity) :'' ,
          "flow_rate": parseInt(props.normal_flow_rate),
          "flow_rate_units": props.units_flow_rate.key,
          "slurry": props.fluid_state.toUpperCase() === "SLURRY" ? "True" : "False",
          "line_size": props.line_size,
          "measurement_type": props.density ? "density" : "viscosity",
          "technology": props.select_technology.toLowerCase(),
          "flow_rate_min": parseInt(props.min_flow_rate),
          "flow_rate_max": parseInt(props.max_flow_rate),
          "flow_rate_norm": parseInt(props.normal_flow_rate),
          "flow_rate_fsd": parseInt(props.full_flow_rate),
          "ref_density": props.gas_density ? parseFloat(props.gas_density) : '',
          "ref_density_units": props.units_gas_density !== undefined ? props.units_gas_density.key : ''
        }
      })
        .then(function (response) {
          setRowDataUS(response.data.Data.DataRow ? response.data.Data.DataRow : []);
          setHeaderDataUS(response.data.Data.InstallationGrid ? response.data.Data.InstallationGrid.HeaderRow : []);
          setHeaderUS(response.data.Data.InstallationHeader ? response.data.Data.InstallationHeader : []);
        })
        .catch(function (error) {
          console.log("error", error);
        });
    };


    if (props.fluid_state.toLowerCase() === 'gas') {
      setRowData([]);
      setHeaderData([]);
      setHeader("");
      setRowDataUS([]);
      setHeaderDataUS([]);
      setHeaderUS("");
    } else if (
      (row.product === "FVM" ||
        row.product === "HFVM" ||
        row.product === "FDM" ||
        row.product === "GDM" ||
        row.product === "SGM")
    ) {
      fetchData();
      fetchDataUS();
    } else {
      setRowData([]);
      setHeaderData([]);
      setHeader("");
      //us data
      setRowDataUS([]);
      setHeaderDataUS([]);
      setHeaderUS("");

    }
  }, [props.selected_row_data]);


  const toPDF = (e)=>{
    e.preventDefault();

    let doc = new jsPDF('portrait', 'pt', [2000, 1400]);
    doc.setFontSize(24);
      doc.text(
        10,
        40,
        props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'? 
        'Installation Details': CustomTranslationText('XXSPECIFY_MMI_ID_LBL_INSTDET', props)
      );
    
    doc.text(
      10,
      90,
      props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'?
      'This is a summary of the Product Advisor results for the following application':
      CustomTranslationText('XXSPECIFY_MMI_ID_LBL_SUMMARY', props)
    )
      
    doc.text(10,150, props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'?
    headerUS.Title:header.Title)

    doc.text(10,180, 
      props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'?
      ('Application Requirements' + ': '+ headerUS.ApplicationRequirements):
      (CustomTranslationText("XXSPECIFY_MMI_MC_HEAD_APPREQ", props) + ': '+header.ApplicationRequirements))

 
    doc.autoTable({
      theme: 'grid',
      startY: 230,
      styles: {
        fontSize: 14,
        halign: 'center',
        cellPadding: 10,
      },

      columnStyles: {
        0: { cellWidth: 250 },
        1: { cellWidth: 250 },
       
      },
      body:[
        [
          props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'? 
          'Application' : CustomTranslationText("XXSPECIFY_MMI_MC_LBL_APPNAME", props), props.application??''
        ],
        [
          props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'? 
          'Service': CustomTranslationText("XXSPECIFY_MMI_MC_LBL_SERVICE", props),props.sizing_service??''
        ],
        [
          props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'? 
          'Fluid State' : CustomTranslationText("XXSPECIFY_MMI_ID_LBL_FLUIDST", props), props.fluid_state? props.fluid_state.toUpperCase():''
        ],
        [
          props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'? 
          'Process Fluid Source':CustomTranslationText("XXSPECIFY_MMI_ID_LBL_FLUIDSO", props), props.fluid_source.toLowerCase()==='custom'? props.fluid_source.toUpperCase():'FLUIDS'
        ],
        [
          props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'? 
          'Process Fluid Name':CustomTranslationText("XXSPECIFY_MMI_EML_PROCFLUDNAME", props),props.fluid_state.toUpperCase() !== 'STEAM'? props.fluid_source.toLowerCase()==='custom'?props.custom_fluid_name?props.custom_fluid_name:'':props.pick_fluids_from_db?props.pick_fluids_from_db: "":''
        ],
        [
          props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'? 
          'Line Size':CustomTranslationText("XXSPECIFY_MMI_MC_LBL_LINES", props), 
          props.process_line_size_units? props.process_line_size_units.filter(c=> c.value === props.line_size)[0].label:props.line_size 
        ],
        [
          props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'? 'Operating Flow Rate': 
          CustomTranslationText("XXSPECIFY_MMI_EML_OPFLOWRATE", props), 
          props.normal_flow_rate + ' ' + (
          props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'?
          (props.units_flow_rate ? props.units_flow_rate.key:''): (props.units_flow_rate ? props.units_flow_rate.value:''))
        ],
        [
          props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'? 'Operating Temperature':
          CustomTranslationText("XXSPECIFY_MMI_ID_LBL_OPTEMP", props), 
          props.normal_temperature +' '+ (props.units_temperature? props.units_temperature.value:'')
        ],
        [
          props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'? 'Operating Pressure':
          CustomTranslationText("XXSPECIFY_MMI_EML_OPPRESSURE", props), 
          props.normal_line_pressure +' '+ (
          props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'?
          (props.units_line_pressure? props.units_line_pressure.key:''):
          (props.units_line_pressure? props.units_line_pressure.value:''))
        ],
        [
          props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'? 'Operating Density':
          CustomTranslationText("XXSPECIFY_MMI_ID_LBL_OPDENSITY", props), 
          parseFloat(props.normal_density).toFixed(props.all_other_decimal) +' '+ (
          props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'?
          (props.units_density? props.units_density.key:''):(props.units_density? props.units_density.value:''))
        ],
        [
          props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'? 'Operating Viscosity':
          CustomTranslationText("XXSPECIFY_MMI_ID_LBL_OPVISCOSI", props), 
          parseFloat(props.normal_viscosity).toFixed(props.all_other_decimal) +' '+ (
          props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'?
          (props.units_viscosity? props.units_viscosity.key:''):(props.units_viscosity? props.units_viscosity.value:''))
        ]
      ]


  
    });

    doc.text(10,685, 
      props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'?
      'Selection Details':
      CustomTranslationText("XXSPECIFY_MMI_SR_LBL_SEL_DET", props))
      try {
        if(props.flow_through_chamber_slipstream){
          doc.autoTable({
            theme: 'grid',
            startY: 720,
            styles: {
              fontSize: 14,
              halign: 'center',
              cellPadding: 10
            },
            columnStyles: {
              0: { cellWidth: 250 },
              1: { cellWidth: 250 },
              2: { cellWidth: 370 },
              3: { cellWidth: 370 },  
            },
  
            body:[
              props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'?
              [ 
                '','',
                 (headerDataUS ? headerDataUS.Column3.Name:'')+ ' \n \n'+ (headerDataUS ? headerDataUS.Column3.Details.Column1:''),
                 (headerDataUS ? headerDataUS.Column4.Name:'')+ ' \n \n'+ (headerDataUS ? headerDataUS.Column4.Details.Column1:''),
              ]:
              [
                '','',
                 (headerData ? headerData.Column3.Name:'')+ ' \n \n'+ (headerData ? headerData.Column3.Details.Column1:''),
                 (headerData ? headerData.Column4.Name:'')+ ' \n \n'+ (headerData ? headerData.Column4.Details.Column1:''),
              ],
              props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'?
              [
                '','', headerDataUS ? headerDataUS.Column3.Details.Column2:''
                ,headerDataUS ? headerDataUS.Column4.Details.Column2:'',
              ]:
              [
                '','', headerData ? headerData.Column3.Details.Column2:''
                ,headerData ? headerData.Column4.Details.Column2:'',
              ],
              props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'?
              [
                'Flow rate' + ' \n ' + (props.units_flow_rate ? props.units_flow_rate.key:''),
                'Velocity' + ' \n '+ tubeVelocity(),
                headerData ? headerData.Column3.Details.Column3:''
                ,headerData ? headerData.Column4.Details.Column3:'',
              ]:
              [
                CustomTranslationText("XXSPECIFY_MMI_ID_LBL_FLOWRATE", props) + ' \n ' + (props.units_flow_rate ? props.units_flow_rate.value:''),
                CustomTranslationText("XXSPECIFY_MMI_ID_LBL_VELOCITY", props) + ' \n '+ tubeVelocity(), 
                headerData ? headerData.Column3.Details.Column3:''
                ,headerData ? headerData.Column4.Details.Column3:'',
              ],
              [
                rowData? rowData[10].Column1:'',
                rowData? rowData[10].Column2 !==null? parseFloat(rowData[10].Column2).toFixed(props.all_other_decimal) :''  :'',
                rowData? rowData[10].Column3 :'',
                rowData? rowData[10].Column4:'',
              ],
              [
                rowData? rowData[9].Column1:'',
                rowData? rowData[9].Column2 !==null? parseFloat(rowData[9].Column2).toFixed(props.all_other_decimal) :''  :'',
                rowData? rowData[9].Column3:'',
                rowData? rowData[9].Column4:'',
              ],
              [
                rowData? rowData[8].Column1:'',
                rowData? rowData[8].Column2 !==null? parseFloat(rowData[8].Column2).toFixed(props.all_other_decimal) :''  :'',
                rowData? rowData[8].Column3:'',
                rowData? rowData[8].Column4:'',
              ],
    
              //aca
              [
                rowData? rowData[7].Column1:'',
                rowData? rowData[7].Column2 !==null? parseFloat(rowData[7].Column2).toFixed(props.all_other_decimal) :''  :'',
                rowData? rowData[7].Column3:'',
                rowData? rowData[7].Column4:'',
              ],
              [
                rowData? rowData[6].Column1:'',
                rowData? rowData[6].Column2 !==null? parseFloat(rowData[6].Column2).toFixed(props.all_other_decimal) :''  :'',
                rowData? rowData[6].Column3:'',
                rowData? rowData[6].Column4:'',
              ],
              [
                rowData? rowData[5].Column1:'',
                rowData? rowData[5].Column2 !==null? parseFloat(rowData[5].Column2).toFixed(props.all_other_decimal) :''  :'',
                rowData? rowData[5].Column3:'',
                rowData? rowData[5].Column4:'',
              ],
              [
                rowData? rowData[4].Column1:'',
                rowData? rowData[4].Column2 !==null? parseFloat(rowData[4].Column2).toFixed(props.all_other_decimal) :''  :'',
                rowData? rowData[4].Column3:'',
                rowData? rowData[4].Column4:'',
              ],
              [
                rowData? rowData[3].Column1:'',
                rowData? rowData[3].Column2 !==null? parseFloat(rowData[3].Column2).toFixed(props.all_other_decimal) :''  :'',
                rowData? rowData[3].Column3:'',
                rowData? rowData[3].Column4:'',

              ],
              [
                rowData? rowData[2].Column1:'',
                rowData? rowData[2].Column2 !==null? parseFloat(rowData[2].Column2).toFixed(props.all_other_decimal) :''  :'',
                rowData? rowData[2].Column3:'',
                rowData? rowData[2].Column4:'',

              ],
              [
                rowData? rowData[1].Column1:'',
                rowData? rowData[1].Column2 !==null? parseFloat(rowData[1].Column2).toFixed(props.all_other_decimal) :''  :'',
                rowData? rowData[1].Column3:'',
                rowData? rowData[1].Column4:'',

              ],
              [
                rowData? rowData[0].Column1:'',
                rowData? rowData[0].Column2 !==null? parseFloat(rowData[0].Column2).toFixed(props.all_other_decimal) :''  :'',
                rowData? rowData[0].Column3:'',
                rowData? rowData[0].Column4:'',

              ],
            ],
            
            
            
      
      
        
          });
        }else{
          if(props.select_technology==='Density'){
            doc.autoTable({
              theme: 'grid',
              startY: 720,
              styles: {
                fontSize: 14,
                halign: 'center',
                cellPadding: 10
              },
              columnStyles: {
                0: { cellWidth: 150 },
                1: { cellWidth: 150 },
                2: { cellWidth: 250 },
                3: { cellWidth: 250 },
                4: { cellWidth: 250 },
                5: { cellWidth: 250 },
              },
    
              body:[
                props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'?
                [ 
                  '','',
                   (headerDataUS ? headerDataUS.Column3.Name:'')+ ' \n \n'+ (headerDataUS ? headerDataUS.Column3.Details.Column1:''),
                   (headerDataUS ? headerDataUS.Column4.Name:'')+ ' \n \n'+ (headerDataUS ? headerDataUS.Column4.Details.Column1:''),
                   (headerDataUS ? headerDataUS.Column5.Name:'')+ ' \n \n'+ (headerDataUS ? headerDataUS.Column5.Details.Column1:''),
                   (headerDataUS ? headerDataUS.Column6.Name:'')+ ' \n \n'+ (headerDataUS ? headerDataUS.Column6.Details.Column1:'')
                ]:
                [
                  '','',
                   (headerData ? headerData.Column3.Name:'')+ ' \n \n'+ (headerData ? headerData.Column3.Details.Column1:''),
                   (headerData ? headerData.Column4.Name:'')+ ' \n \n'+ (headerData ? headerData.Column4.Details.Column1:''),
                   (headerData ? headerData.Column5.Name:'')+ ' \n \n'+ (headerData ? headerData.Column5.Details.Column1:''),
                   (headerData ? headerData.Column6.Name:'')+ ' \n \n'+ (headerData ? headerData.Column6.Details.Column1:'')
                ],
                props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'?
                [
                  '','', headerDataUS ? headerDataUS.Column3.Details.Column2:''
                  ,headerDataUS ? headerDataUS.Column4.Details.Column2:'',
                  headerDataUS ? headerDataUS.Column5.Details.Column2:'',
                  headerDataUS ? headerDataUS.Column6.Details.Column2:''
                ]:
                [
                  '','', headerData ? headerData.Column3.Details.Column2:''
                  ,headerData ? headerData.Column4.Details.Column2:'',
                  headerData ? headerData.Column5.Details.Column2:'',
                  headerData ? headerData.Column6.Details.Column2:''
                ],
                props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'?
                [
                  'Flow rate' + ' \n ' + (props.units_flow_rate ? props.units_flow_rate.key:''),
                  'Velocity' + ' \n '+ tubeVelocity(),
                  headerData ? headerData.Column3.Details.Column3:''
                  ,headerData ? headerData.Column4.Details.Column3:'',
                  headerData ? headerData.Column5.Details.Column3:'',
                  headerData ? headerData.Column6.Details.Column3:''
                ]:
                [
                  CustomTranslationText("XXSPECIFY_MMI_ID_LBL_FLOWRATE", props) + ' \n ' + (props.units_flow_rate ? props.units_flow_rate.value:''),
                  CustomTranslationText("XXSPECIFY_MMI_ID_LBL_VELOCITY", props) + ' \n '+ tubeVelocity(), 
                  headerData ? headerData.Column3.Details.Column3:''
                  ,headerData ? headerData.Column4.Details.Column3:'',
                  headerData ? headerData.Column5.Details.Column3:'',
                  headerData ? headerData.Column6.Details.Column3:''
                ],
                [
                  rowData? rowData[10].Column1:'',
                  rowData? rowData[10].Column2 !==null? parseFloat(rowData[10].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[10].Column3 :'',
                  rowData? rowData[10].Column4:'',
                  rowData? rowData[10].Column5:'',
                  rowData? rowData[10].Column6:'',
                ],
                [
                  rowData? rowData[9].Column1:'',
                  rowData? rowData[9].Column2 !==null? parseFloat(rowData[9].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[9].Column3:'',
                  rowData? rowData[9].Column4:'',
                  rowData? rowData[9].Column5:'',
                  rowData? rowData[9].Column6:'',
                ],
                [
                  rowData? rowData[8].Column1:'',
                  rowData? rowData[8].Column2 !==null? parseFloat(rowData[8].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[8].Column3:'',
                  rowData? rowData[8].Column4:'',
                  rowData? rowData[8].Column5:'',
                  rowData? rowData[8].Column6:'',
                ],
      
                //aca
                [
                  rowData? rowData[7].Column1:'',
                  rowData? rowData[7].Column2 !==null? parseFloat(rowData[7].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[7].Column3:'',
                  rowData? rowData[7].Column4:'',
                  rowData? rowData[7].Column5:'',
                  rowData? rowData[7].Column6:'',
                ],
                [
                  rowData? rowData[6].Column1:'',
                  rowData? rowData[6].Column2 !==null? parseFloat(rowData[6].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[6].Column3:'',
                  rowData? rowData[6].Column4:'',
                  rowData? rowData[6].Column5:'',
                  rowData? rowData[6].Column6:'',
                ],
                [
                  rowData? rowData[5].Column1:'',
                  rowData? rowData[5].Column2 !==null? parseFloat(rowData[5].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[5].Column3:'',
                  rowData? rowData[5].Column4:'',
                  rowData? rowData[5].Column5:'',
                  rowData? rowData[5].Column6:'',
                ],
                [
                  rowData? rowData[4].Column1:'',
                  rowData? rowData[4].Column2 !==null? parseFloat(rowData[4].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[4].Column3:'',
                  rowData? rowData[4].Column4:'',
                  rowData? rowData[4].Column5:'',
                  rowData? rowData[4].Column6:'',
                ],
                [
                  rowData? rowData[3].Column1:'',
                  rowData? rowData[3].Column2 !==null? parseFloat(rowData[3].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[3].Column3:'',
                  rowData? rowData[3].Column4:'',
                  rowData? rowData[3].Column5:'',
                  rowData? rowData[3].Column6:'',
                ],
                [
                  rowData? rowData[2].Column1:'',
                  rowData? rowData[2].Column2 !==null? parseFloat(rowData[2].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[2].Column3:'',
                  rowData? rowData[2].Column4:'',
                  rowData? rowData[2].Column5:'',
                  rowData? rowData[2].Column6:'',
                ],
                [
                  rowData? rowData[1].Column1:'',
                  rowData? rowData[1].Column2 !==null? parseFloat(rowData[1].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[1].Column3:'',
                  rowData? rowData[1].Column4:'',
                  rowData? rowData[1].Column5:'',
                  rowData? rowData[1].Column6:'',
                ],
                [
                  rowData? rowData[0].Column1:'',
                  rowData? rowData[0].Column2 !==null? parseFloat(rowData[0].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[0].Column3:'',
                  rowData? rowData[0].Column4:'',
                  rowData? rowData[0].Column5:'',
                  rowData? rowData[0].Column6:'',
                ],
              ],
              
              
              
        
        
          
            });
          }else{
            doc.autoTable({
              theme: 'grid',
              startY: 720,
              styles: {
                fontSize: 14,
                halign: 'center',
                cellPadding: 10
              },
         
              columnStyles: {
                0: { cellWidth: 250 },
                1: { cellWidth: 250 },
                2: { cellWidth: 250 },
                3: { cellWidth: 250 },
                4: { cellWidth: 250 },
              },
              body:[
                props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'?
                [
                  '','',
                   (headerDataUS ? headerDataUS.Column3.Name:'')+ ' \n \n'+ (headerDataUS ? headerDataUS.Column3.Details.Column1:''),
                   (headerDataUS ? headerDataUS.Column4.Name:'')+ ' \n \n'+ (headerDataUS ? headerDataUS.Column4.Details.Column1:''),
                   (headerDataUS ? headerDataUS.Column5.Name:'')+ ' \n \n'+ (headerDataUS ? headerDataUS.Column5.Details.Column1:''),
                ]:[
                   '','',
                   (headerData ? headerData.Column3.Name:'')+ ' \n \n'+ (headerData ? headerData.Column3.Details.Column1:''),
                   (headerData ? headerData.Column4.Name:'')+ ' \n \n'+ (headerData ? headerData.Column4.Details.Column1:''),
                   (headerData ? headerData.Column5.Name:'')+ ' \n \n'+ (headerData ? headerData.Column5.Details.Column1:''),
                ],
                props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'?
                [
                  '','', headerDataUS ? headerDataUS.Column3.Details.Column2:''
                  ,headerDataUS ? headerDataUS.Column4.Details.Column2:'',
                  headerDataUS ? headerDataUS.Column5.Details.Column2:'',
                ]:
                [
                  '','', headerData ? headerData.Column3.Details.Column2:''
                  ,headerData ? headerData.Column4.Details.Column2:'',
                  headerData ? headerData.Column5.Details.Column2:'',
                ],
                props.query_params.language ==='JA' || props.query_params.language ==='KO' ||props.query_params.language ==='ZHS'?
                [
                  'Flow rate' + ' \n ' + (props.units_flow_rate ? props.units_flow_rate.key:''),
                  "Velocity" + ' \n '+ tubeVelocity(),
                   headerData ? headerData.Column3.Details.Column3:''
                  ,headerData ? headerData.Column4.Details.Column3:'',
                  headerData ? headerData.Column5.Details.Column3:'',
                ]:
                [
                  CustomTranslationText("XXSPECIFY_MMI_ID_LBL_FLOWRATE", props) + ' \n ' + (props.units_flow_rate ? props.units_flow_rate.value:''),
                  CustomTranslationText("XXSPECIFY_MMI_ID_LBL_VELOCITY", props) + ' \n '+ tubeVelocity(),
                   headerData ? headerData.Column3.Details.Column3:''
                  ,headerData ? headerData.Column4.Details.Column3:'',
                  headerData ? headerData.Column5.Details.Column3:'',
                ],
                [
                  rowData? rowData[10].Column1:'',
                  rowData? rowData[10].Column2 !==null? parseFloat(rowData[10].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[10].Column3 :'',
                  rowData? rowData[10].Column4:'',
                  rowData? rowData[10].Column5:'',
                ],
                [
                  rowData? rowData[9].Column1:'',
                  rowData? rowData[9].Column2 !==null? parseFloat(rowData[9].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[9].Column3:'',
                  rowData? rowData[9].Column4:'',
                  rowData? rowData[9].Column5:'',
                ],
                [
                  rowData? rowData[8].Column1:'',
                  rowData? rowData[8].Column2 !==null? parseFloat(rowData[8].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[8].Column3:'',
                  rowData? rowData[8].Column4:'',
                  rowData? rowData[8].Column5:'',
                ],
      
                //aca
                [
                  rowData? rowData[7].Column1:'',
                  rowData? rowData[7].Column2 !==null? parseFloat(rowData[7].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[7].Column3:'',
                  rowData? rowData[7].Column4:'',
                  rowData? rowData[7].Column5:'',
                ],
                [
                  rowData? rowData[6].Column1:'',
                  rowData? rowData[6].Column2 !==null? parseFloat(rowData[6].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[6].Column3:'',
                  rowData? rowData[6].Column4:'',
                  rowData? rowData[6].Column5:'',
                ],
                [
                  rowData? rowData[5].Column1:'',
                  rowData? rowData[5].Column2 !==null? parseFloat(rowData[5].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[5].Column3:'',
                  rowData? rowData[5].Column4:'',
                  rowData? rowData[5].Column5:'',
                ],
                [
                  rowData? rowData[4].Column1:'',
                  rowData? rowData[4].Column2 !==null? parseFloat(rowData[4].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[4].Column3:'',
                  rowData? rowData[4].Column4:'',
                  rowData? rowData[4].Column5:'',
                ],
                [
                  rowData? rowData[3].Column1:'',
                  rowData? rowData[3].Column2 !==null? parseFloat(rowData[3].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[3].Column3:'',
                  rowData? rowData[3].Column4:'',
                  rowData? rowData[3].Column5:'',
                ],
                [
                  rowData? rowData[2].Column1:'',
                  rowData? rowData[2].Column2 !==null? parseFloat(rowData[2].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[2].Column3:'',
                  rowData? rowData[2].Column4:'',
                  rowData? rowData[2].Column5:'',
                ],
                [
                  rowData? rowData[1].Column1:'',
                  rowData? rowData[1].Column2 !==null? parseFloat(rowData[1].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[1].Column3:'',
                  rowData? rowData[1].Column4:'',
                  rowData? rowData[1].Column5:'',
                ],
                [
                  rowData? rowData[0].Column1:'',
                  rowData? rowData[0].Column2 !==null? parseFloat(rowData[0].Column2).toFixed(props.all_other_decimal) :''  :'',
                  rowData? rowData[0].Column3:'',
                  rowData? rowData[0].Column4:'',
                  rowData? rowData[0].Column5:'',
                ],
              ],
              
              
              
        
        
          
            });
          }
        }
      
    } catch (error) {
        console.log('Missing field in the report')
    }













    doc.save('InstallationDetails.pdf');

  
  
  }

  useEffect(() => {
    if (rowData.length > 0 && Object.keys(headerData).length > 0) {
      const headers = viscosity(headerData);
      setColumn(headers);
      let results = rowData.map((item) => newRowMagnetic(item));
      let data = []
      for (let k = results.length - 1; k > 0; k = k - 1) {
        // if (
        //   (results[k].flow_rate !=results[k - 1].flow_rate &&
        //     (results[k].flow_rate ===
        //       parseInt(props.min_flow_rate) ||
        //       results[k].flow_rate ===
        //         parseInt(props.normal_flow_rate) ||
        //       (props.max_flow_rate != '' &&
        //         results[k].flow_rate ==
        //           parseInt(props.max_flow_rate)))) ||
        //   k % 5 === 0
        // ) {
        data.push(results[k]);
        //}

      }
      data.push(results[0])
      setData(data)
    }
  }, [headerData]);

  const viscosity = (header) => {
    return [
      {
        Header: "FLOW RATE",
        accessor: "flow_rate",
        sortType: "basic",
      },
      {
        Header: "VELOCITY",
        accessor: "velosity",
        sortType: "basic",
      },
      {
        Header: header.Column3.Name,
        columns: [
          {
            Header: header.Column3.Details.Column1,
            columns: [
              {
                Header: header.Column3.Details.Column2 ? header.Column3.Details.Column2 : '  ',
                columns: [
                  {
                    Header: header.Column3.Details.Column3.toUpperCase(),
                    accessor: "Column3",
                    Cell: ({ cell: { value } }) => value ? value === "YES" ? <p style={{ color: 'green' }} >{value}</p> : value === "NO" ? <p style={{ color: 'red' }} >{value}</p> : <p >{value}</p> : <p >{value}</p>
                  },
                ],
              },
            ],
          },
        ],
      },
      header.Column4 ?
        {
          Header: header.Column4.Name + ".",
          columns: [
            {
              Header: header.Column4.Details.Column1 + ";",
              columns: [
                {
                  Header: header.Column4.Details.Column2,
                  columns: [
                    {
                      Header: header.Column4.Details.Column3.toUpperCase(),
                      accessor: "Column4",
                      sortType: "basic",
                      Cell: ({ cell: { value } }) => value === "YES" ? <p style={{ color: 'green' }} >{value}</p> : value === "NO" ? <p style={{ color: 'red' }} >{value}</p> : <p >{value}</p>
                    },
                  ],
                },
              ],
            },
          ],
        } : '',
      header.Column5 ?
        {
          Header: header.Column5.Name + "`",
          columns: [
            {
              Header: header.Column5.Details.Column1 + "`",
              columns: [
                {
                  Header: header.Column5.Details.Column2,
                  columns: [
                    {
                      Header: header.Column5.Details.Column3.toUpperCase(),
                      accessor: "Column5",
                      sortType: "basic",
                      Cell: ({ cell: { value } }) => value === "YES" ? <p style={{ color: 'green' }} >{value}</p> : value === "NO" ? <p style={{ color: 'red' }} >{value}</p> : <p >{value}</p>
                    },
                  ],
                },
              ],
            },
          ],
        } : '',
      header.Column6 ?
        {
          Header: header.Column6 ? header.Column6.Name : '',
          columns: [
            {
              Header: header.Column6 ? header.Column6.Details.Column1 : '',
              columns: [
                {
                  Header: header.Column6 ? header.Column6.Details.Column2 : '',
                  columns: [
                    {
                      Header: header.Column6 ? header.Column6.Details.Column3.toUpperCase() : '',
                      accessor: "Column6",
                      sortType: "basic",
                      Cell: ({ cell: { value } }) => value === "YES" ? <p style={{ color: 'green' }} >{value}</p> : value === "NO" ? <p style={{ color: 'red' }} >{value}</p> : <p >{value}</p>
                    },
                  ],
                },
              ],
            },
          ],
        } : '',
    ];
  };

  const tubeVelocity = () => {
    let tubeVel = '';
    if (props.units_data) {
      for (const [key, value] of Object.entries(props.units_data.unitMapping)) {
        if (key === props.units_flow_rate.key) {
          if (value === 'METRIC SYSTEM') {
            tubeVel = 'm/sec'
          } else if (value === 'ENGLISH SYSTEM') {
            tubeVel = 'ft/sec'
          }
        }
      }
    }
    return tubeVel;
  }

  const headers = {
    "flow_rate": props.units_flow_rate.value ? (props.units_flow_rate.value).toUpperCase() : '',
    tube_velocity: tubeVelocity(),
  }

  const newRowMagnetic = (row) => {
    return {
      flow_rate: row.Column1 !== null ? parseFloat(row.Column1) : "--",
      velosity: row.Column2 !== null ? parseFloat(row.Column2).toFixed(props.all_other_decimal) : "--",
      Column3: row.Column3 ? row.Column3.toUpperCase() : "--",
      Column4: row.Column4 !== null && row.Column4 ? row.Column4.toUpperCase() : "--",
      Column5: row.Column5 !== null && row.Column5 ? row.Column5.toUpperCase() : "--",
      Column6: row.Column6 !== null && row.Column6 ? row.Column6.toUpperCase() : "--",

    };
  };

  return (
    <div id="installation-details-section" className="LineBottom">
      {!props.tank && colums.length > 0 && Object.keys(headerData).length > 0 && rowData.length > 0 ? (
        <div>
          <h5 className="ShowMoreHeader">Installation Details</h5>
          <p className="ShowMoreSubHeader">{header.Title}</p>
          <Row>
            <Col md="4" xl="4"></Col>
            <Col md="8" xl="8">
              {header.ImageURL ? (
                <img
                  className="installationImage"
                  src={header.ImageURL}
                  alt={data.model_code}
                  height={"240"}
                  width={"240"}
                />
              ) : (
                <img
                  className="installationImage"
                  src={pictureComingSoon}
                  alt="Loader"
                  height="60"
                  width="60"
                />
              )}
            </Col>
            <Col>

              <InstallationDetailsTable
                columns={colums.filter(data => data != '')}
                data={data}
                tableType="install-table"
                headers={headers}
              />
            </Col>
          </Row>
           <Row>
            <Col>
            </Col>
            <Col>
              <button className="download-button-installation" onClick={toPDF}>
                <CustomTranslation data="XXSPECIFY_MMI_SR_BTN_INSDET" />
              </button>
            </Col>
           
           </Row>
        </div>
      ) : Object.keys(header).length > 0 && (
        <div>
          <h5 className="ShowMoreHeader">Installation Details</h5>
          <p className="ShowMoreSubHeader">{header.Title}</p>
          <Row>
            <Col>
              {(props.tank && header.Tank_ImageURL) ? (
                <img
                  className="installationImage"
                  src={header.Tank_ImageURL}
                  alt={data.model_code}
                  height={"350"}
                  width={"345"}
                />
              ) : (
                <img
                  className="installationImage"
                  src={pictureComingSoon}
                  alt="Loader"
                  height="60"
                  width="60"
                />
              )}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  selected_row_data: state.SizingResults.selected_row_data,
  sonic_velocity: state.ProcessVariables.sonic_velocity,
  units_sonic_velocity: state.ProcessVariables.units_sonic_velocity,
  search_parameters: state.SizingResults.search_parameters,
  viscosity: state.MeasurementType.viscosity,
  tank: state.ApplicationRequirements.tank,
  density: state.MeasurementType.density,
  in_line: state.ApplicationRequirements.in_line,
  flow_through_chamber_slipstream:
    state.ApplicationRequirements.flow_through_chamber_slipstream,
  select_technology: state.MeasurementType.select_technology,
  fluid_state: state.FluidSelection.fluid_state,
  pick_fluids_from_db: state.FluidSelection.pick_fluids_from_db,
  all_other_decimal: state.Preferences.all_other_decimal,
  units_density: state.ProcessVariables.units_density,
  units_flow_rate: state.ProcessVariables.units_flow_rate,
  specific_gravity: state.ProcessVariables.specific_gravity,
  user_language: state.AppHeader.user_language,
  query_params: state.Preferences.query_params,
  normal_density: state.ProcessVariables.normal_density,
  normal_flow_rate: state.ProcessVariables.normal_flow_rate,
  line_size: state.ApplicationRequirements.line_size,
  min_flow_rate: state.ProcessVariables.min_flow_rate,
  max_flow_rate: state.ProcessVariables.max_flow_rate,
  full_flow_rate: state.ProcessVariables.full_flow_rate,
  units_gas_density: state.ProcessVariables.units_gas_density,
  gas_density: state.ProcessVariables.gas_density,
  units_data: state.DropdownData.units_data,
  custom_fluid_name: state.FluidSelection.custom_fluid_name,
  translation_data: state.TranslationData.translation_data,
  process_line_size_units: state.ApplicationRequirements.process_line_size_units,
  normal_temperature: state.ProcessVariables.normal_temperature,
  normal_line_pressure: state.ProcessVariables.normal_line_pressure,
  normal_density: state.ProcessVariables.normal_density,
  normal_viscosity: state.ProcessVariables.normal_viscosity,
  units_temperature: state.ProcessVariables.units_temperature,
  units_line_pressure: state.ProcessVariables.units_line_pressure,
  units_viscosity: state.ProcessVariables.units_viscosity,
  application: state.SizingResults.application,
  fluid_source: state.FluidSelection.fluid_source,
  sizing_service: state.SizingResults.sizing_service,
  flow_through_chamber_slipstream: state.ApplicationRequirements.flow_through_chamber_slipstream
});

export default connect(mapStateToProps, {
  selectedRowData,
})(InstallationDetails);
