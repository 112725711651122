import {
    CLEAR_UOM
} from '../actionTypes';

const initialState = {
    clear_uom: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_UOM: {
            return {
                ...state,
                clear_uom: action.payload.id
            }
        }
        default: {
            return state;
        }
    }
}

