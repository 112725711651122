import React, { useState, useEffect } from "react";
import { Col, Row, Modal, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import { connect } from "react-redux";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import CustomTranslation from "../../components/CustomTranslation/CustomTranslation";
import { CustomRadiobutton } from "../../components/CustomRadiobutton/CustomRadiobutton";
import { selectedRowData, ApplicationNameError, queryParams, reportFlag, proceedLoad } from "../../redux/actions";
import CustomTranslationText from "../../components/CustomTranslation/CustomTranslationText";
import "./ViewGraph.scss";
import ReactToPdf from "react-to-pdf";
import { jsPDF } from "jspdf";
import { getLanguageCode } from "../../UtilityFuntions/getLanguageCode";
import { CustomDropdown } from "../../components/CustomDropdown/CustomDropdown";
import { CustomInputField } from "../../components/CustomInputField/CustomInputField";
import urls from "../../UtilityFuntions/urls";
import { BASE64_IMG_PREFIX } from "../../constants/constants";
const docToPrint = React.createRef(document.getElementById("graph-download"));

const ViewGraph = (props) => {
  const [toggleCompare, setToggleCompare] = useState(false);
  const [file_type, setFileType] = useState("pdf");
  const [downloadLoad, setDownloadload] = useState(false);
  const [graphLoad, setGraphload] = useState(false);
  const [graph_image, setGraphImage] = useState("");
  const [fullGraph, setFullGraph] = useState(false);
  const [load, setLoad] = useState(false);
  const [save_label, setSaveLabel] = useState({});
  const [transmitterList, setTransmitterList] = useState([]);
  const [transmitter_label, setTransmitterLabel] = useState("No Selection");
  const [transmitter_value, setTransmitterValue] = useState("No Selection");
  const [proceed_click, setProceedClick] = useState(false);
  const [loadType, setLoadType] = useState("");
  let report_high_temperature_warning_msg_html_tag = CustomTranslationText("XXSPECIFY_MMI_LBL_F_SERIES", props);
  let report_high_temperature_warning_msg = report_high_temperature_warning_msg_html_tag.replace(/<\/?a[^>]*>|<\/?u[^>]*>/g, "");
  let report_high_temperature_error_msg_html_tag = CustomTranslationText("XXSPECIFY_MMI_LBL_HI_TEMP_MDL", props);
  let report_high_temperature_error_msg = report_high_temperature_error_msg_html_tag.replace(/<\/?a[^>]*>|<\/?u[^>]*>/g, "");
  const {
    min_flow_rate,
    normal_flow_rate,
    max_flow_rate,
    full_flow_rate,

    min_line_pressure,
    normal_line_pressure,
    max_line_pressure,
    full_line_pressure,

    min_temperature,
    normal_temperature,
    max_temperature,
    full_temperature,

    min_ambient,
    normal_ambient,
    max_ambient,
    full_ambient,

    min_density,
    normal_density,
    max_density,
    min_viscosity,
    normal_viscosity,
    max_viscosity,

    units_viscosity,
    units_line_pressure,
    units_density,
    units_ambient,
    units_temperature,
    units_flow_rate,

    all_other_decimal,
    flow_rate_decimal,
    temperature_decimal,
    line_pressure_decimal,
  } = props;
  const generateSizingReport = () => {
    setToggleCompare(!toggleCompare);
  };
  const getCompositionValues = async (value) => {
    let return_value = "";
    return_value = await props.selected_components.filter((data) => data.idValue === value);
    return return_value.length > 0 ? return_value[0].mole : "";
  };
  const tubeVelocity = () => {
    let tubeVel = "";
    if (props.units_data) {
      for (const [key, value] of Object.entries(props.units_data.unitMapping)) {
        if (props.fluid_state.toLowerCase() !== "gas") {
          if (key === props.units_flow_rate.key) {
            if (value === "METRIC SYSTEM") {
              tubeVel = "M/SEC";
            } else if (value === "ENGLISH SYSTEM") {
              tubeVel = "FT/SEC";
            }
          }
        } else {
          if (key === props.units_gas_flow_rate.key) {
            if (value === "METRIC SYSTEM") {
              tubeVel = "M/SEC";
            } else if (value === "ENGLISH SYSTEM") {
              tubeVel = "FT/SEC";
            }
          }
        }
      }
    }
    return tubeVel;
  };

  const modelForSaveSizing = (model) => {
    let array = ["GDM", "FDM", "SGM", "CDM100P", "CDM100M"];
    if (props.select_technology.toLowerCase() === "density") {
      if (props.equipment_selection.includes("Flow Meter") && !array.includes(model)) {
        //Equipment selection
        return "FT_" + model;
      } else {
        return model;
      }
    } else {
      return model;
    }
  };
  const downloadReport = async () => {
    const languageCode = props.user_language && props.user_language.value ? props.user_language.value : "US";
    let type = file_type === "word" ? "DOCX" : "PDF";

    const localData = localStorage.getItem("AppLogin");
    const email = window.email ? window.email : "";
    let source =
      props.fluid_source.toLowerCase() === "database"
        ? "FLUIDS"
        : props.fluid_source.toLowerCase() === "custom"
          ? "SLURRYNAME"
          : props.fluid_source.toLowerCase() === "natural gas"
            ? "GAS_FS"
            : "";
    let fluidservice =
      props.fluid_service === "Normal (0-39 ft/sec or 0-12 m/sec)"
        ? "NORMAL"
        : props.fluid_service === "Preferred (2-20 ft/sec or 0.6 ? 6.1 m/sec)"
          ? "PREFERRED"
          : props.fluid_service === "Abrasive Slurry (3-10 ft/sec or 0.9 ? 3.0 m/sec)"
            ? "ABRASIVE SLURRY"
            : props.fluid_service === "Non-abrasive Slurry (5-15 ft/sec or 1.5-4.6 m/sec)"
              ? "NON-ABRASIVE SLURRY"
              : "NORMAL";
    let flow_meter = "";
    let sensor = "";
    if (props.select_technology.toLowerCase() !== "vortex") {
      flow_meter =
        props.select_technology.toLowerCase() === "magnetic"
          ? props.equipment_selection.includes("Magmeter System")
            ? "Y"
            : "N"
          : props.equipment_selection.includes("Flow Meter")
            ? "Y"
            : "N";
      sensor =
        props.select_technology.toLowerCase() === "magnetic"
          ? props.equipment_selection.includes("Sensor")
            ? "Y"
            : "N"
          : props.equipment_selection.includes("Sensor Only / MVDSolo")
            ? "Y"
            : "N";
    }

    let data = {
      email_id: props.email ? props.email : "flow@istore.com", //'rajeshwari.mulemani@emerson.com',
      mass_flow_to_measure: props.flow ? "Y" : "N",
      density_to_measure: props.density && !props.flow ? "Y" : "N",
      //catentry_id : transmitter_label,
      viscosity_to_measure: props.viscosity ? "Y" : "N",
      attribute1: props.project_name,
      coriolis: props.select_technology === "Coriolis" ? "Y" : "N",
      mag: props.select_technology.toLowerCase() === "magnetic" ? "Y" : "N",
      vortex: props.select_technology === "Vortex" ? "Y" : "N",
      density: props.select_technology === "Density" ? "Y" : "N",
      viscosity: props.select_technology === "Viscosity" ? "Y" : "N",
      warning_msgs: {
        cavitation_msg: props.selected_row_data
          ? props.selected_row_data.cavitation_warning_msg
            ? props.selected_row_data.cavitation_warning_msg
            : null
          : null,
        region_msg: props.selected_row_data
          ? props.selected_row_data.product
            ? props.selected_row_data.product.startsWith("K") || props.selected_row_data.product.endsWith("G")
              ? CustomTranslationText("XXSPECIFY_MMI_SR_LBL_FEWCONT", props)
              : null
            : null
          : null,
        ambient_temperature_mgs:
          props.search_parameters.select_technology.toLowerCase() === "vortex" ||
            props.search_parameters.select_technology.toLowerCase() === "magnetic"
            ? CustomTranslationText("XXSPECIFY_MMI_MAGVTXAMBTMP_LBL", props)
            : null,
        config_msg: props.config_warning_msg ? CustomTranslationText("XXSPECIFY_MMI_MC_LBL_ERRMSG112", props) : null,
        sonic_velocity_warning_msg: props.selected_row_data ? props.selected_row_data.sonic_velocity_warning_msg : "",
        amb_temp_warning_msg: props.selected_row_data ? props.selected_row_data.amb_temp_warning_msg : "",
        high_temperature_warning_msg: props.high_temperature_warning_msg ? report_high_temperature_warning_msg : null,
        high_temperature_error_msg: props.high_temperature_error_msg ? report_high_temperature_error_msg : null,
      },
      dual: props.dual ? "Y" : "N",
      reducer: props.reducer ? "Y" : "N",
      tank: props.tank ? "Y" : "N",
      flanged_wafer: props.flanged ? "Y" : "N",
      quad: props.quad ? "Y" : "N",
      wafer: props.wafer ? "Y" : "N",
      hedge: props.hygienic ? "Y" : "N",
      is_chk_high_pressure: props.pressure ? "Y" : "N",
      no_check: props.display_all_sensors_no_filters ? "Y" : "N",
      mag_hygienic_appl: props.hygienic_application ? "Y" : "N",
      mag_high_press_noise: props.high_process_noise_slurry ? "Y" : "N",
      inline: props.inline ? "Y" : "N",
      slipstream: props.flow_through_chamber_slipstream ? "Y" : "N",
      sensor_selection: props.product_family ? props.product_family : "",
      technology: props.select_technology,
      pipeline_size: props.select_technology === "Magnetic" || props.select_technology === "Vortex" ? props.process_line_size : props.line_size,
      fluid_state: props.fluid_state.toUpperCase(),
      fluid_source: props.fluid_state.toUpperCase() === "STEAM" ? props.steam_pressure_temp : source,
      mag_cond_5_49_9: props.conductivity === "5.0 - 49.9 microsiemens/cm" ? "Y" : "N",
      mag_cond_50_99_9: props.conductivity === "50.0 - 99.9 microsiemens/cm" ? "Y" : "N",
      mag_cond_100: props.conductivity === "Greater than 100 microsiemens/cm" ? "Y" : "N",
      mag_fluid_service: fluidservice,
      fluid:
        props.fluid_state.toUpperCase() !== "STEAM"
          ? props.fluid_source.toLowerCase() === "custom"
            ? props.custom_fluid_name
              ? props.custom_fluid_name
              : ""
            : props.fluid_source.toLowerCase() === "natural gas"
              ? props.natural_gas_compose
                ? props.natural_gas_composition_name
                : props.pick_gas_from_db
                  ? props.pick_gas_from_db
                  : ""
              : props.pick_fluids_from_db
                ? props.pick_fluids_from_db
                : ""
          : "WATER",
      max_amb_temp: max_ambient !== "" ? parseFloat(max_ambient).toFixed(temperature_decimal) : "",
      min_amb_temp: min_ambient !== "" ? parseFloat(min_ambient).toFixed(temperature_decimal) : "",
      op_amb_temp: normal_ambient !== "" ? parseFloat(normal_ambient).toFixed(temperature_decimal) : "",
      des_amb_temp: full_ambient !== "" ? parseFloat(full_ambient).toFixed(temperature_decimal) : "",
      min_flow: min_flow_rate !== "" ? parseFloat(min_flow_rate).toFixed(flow_rate_decimal) : "",
      op_flow: normal_flow_rate !== "" ? parseFloat(normal_flow_rate).toFixed(flow_rate_decimal) : "",
      max_flow: max_flow_rate !== "" ? parseFloat(max_flow_rate).toFixed(flow_rate_decimal) : "",
      des_flow: full_flow_rate !== "" ? parseFloat(full_flow_rate).toFixed(flow_rate_decimal) : "",
      min_pres: min_line_pressure !== "" ? parseFloat(min_line_pressure).toFixed(line_pressure_decimal) : "",
      op_pres: normal_line_pressure !== "" ? parseFloat(normal_line_pressure).toFixed(line_pressure_decimal) : "",
      max_pres: max_line_pressure !== "" ? parseFloat(max_line_pressure).toFixed(line_pressure_decimal) : "",
      des_pres: full_line_pressure !== "" ? parseFloat(full_line_pressure).toFixed(line_pressure_decimal) : "",
      min_temp: min_temperature !== "" ? parseFloat(min_temperature).toFixed(temperature_decimal) : "",
      op_temp: normal_temperature !== "" ? parseFloat(normal_temperature).toFixed(temperature_decimal) : "",
      max_temp: max_temperature !== "" ? parseFloat(max_temperature).toFixed(temperature_decimal) : "",
      des_temp: full_temperature !== "" ? parseFloat(full_temperature).toFixed(temperature_decimal) : "",
      min_dens: min_density !== "" ? parseFloat(min_density) : "",
      op_dens:
        props.units_density_heading === "Density"
          ? normal_density !== ""
            ? parseFloat(normal_density)
            : ""
          : props.density_specific_gravity !== ""
            ? parseFloat(props.density_specific_gravity)
            : "",
      max_dens: max_density !== "" ? parseFloat(max_density) : "",
      des_dens: 0,
      min_visc: min_viscosity !== "" ? parseFloat(min_viscosity) : "",
      op_visc:
        normal_viscosity !== ""
          ? parseFloat(normal_viscosity)
          : props.select_technology === "Coriolis"
            ? props.show_more_rates_data[0].viscosity
            : "",
      max_visc: max_viscosity !== "" ? parseFloat(max_viscosity) : "",
      des_visc: 0,
      flow_unit: props.fluid_state.toLowerCase() !== "gas" ? units_flow_rate.key : props.units_gas_flow_rate.key,
      pres_unit: units_line_pressure.key,
      temp_unit: units_temperature.key,
      dens_unit:
        props.units_density_heading === "Density"
          ? units_density.key
          : props.densityAccuracyUnits
            ? props.densityAccuracyUnits
            : props.units_gas_density_sg.key,
      visc_unit: units_viscosity.key,
      amb_temp_units: units_ambient.key,
      vapour_pressure_units: props.units_vapor.key,
      sonic_velocity: props.sonic_velocity !== "" ? parseFloat(props.sonic_velocity) : "",
      sonic_velocity_units: (props.units_sonic_velocity.key !== "" && props.units_sonic_velocity.key !== undefined) ? props.units_sonic_velocity.key : "",
      atmospheric_prsure: props.pressure_atmospheric !== "" ? parseFloat(props.pressure_atmospheric) : "",
      atmospheric_prsure_unit: props.units_pressure_atmospheric.key,
      max_flow_accuracy: props.max_flow_acc !== "" ? parseFloat(props.max_flow_acc) : "",
      max_pressure_drop: props.max_pressure_drop !== "" ? parseFloat(props.max_pressure_drop) : "",
      gas_base_ref_temp: props.fluid_state.toLowerCase() === "gas" ? (props.gas_temperature !== "" ? parseFloat(props.gas_temperature) : "") : "",
      gas_base_ref_pressure: props.fluid_state.toLowerCase() === "gas" ? (props.gas_pressure !== "" ? parseFloat(props.gas_pressure) : "") : "",
      gas_base_ref_density: props.fluid_state.toLowerCase() === "gas" ? (props.gas_density !== "" ? parseFloat(props.gas_density) : "") : "",
      gas_base_ref_temp_units: props.fluid_state.toLowerCase() === "gas" ? props.units_gas_temperature.key : "",
      gas_base_ref_pres_units: props.fluid_state.toLowerCase() === "gas" ? props.units_gas_pressure.key : "",
      gas_base_ref_den_units: props.fluid_state.toLowerCase() === "gas" ? props.units_gas_density.key : "",
      vapour_pressure: props.min_vapor !== "" ? parseFloat(props.min_vapor) : "",
      appl: props.application,
      service: props.sizing_service,
      four_corner_flag: props.calculate_density_variation ? "Y" : "N",
      calculate_density_variation: props.calculate_density_variation ? "Y" : "N",
      ngas_composition_method: props.composition_method,
      ngas_list_name: props.natural_gas_composition_name,
      relative_density: props.relative_density,
      mole_carbon_dioxide: props.mole_percent_CO2,
      mole_carbon_monoxide: props.mole_percent_CO,
      mole_hydrogen: props.mole_percent_H2,
      mole_nitrogen: props.mole_percent_N2,
      heating_value: props.heating_value,
      heating_value_uom: props.heating_value_dropdown,
      ngas_composition_flag: props.natural_gas_compose,
      methane: await getCompositionValues("methane"),
      nitrogen: await getCompositionValues("nitrogen"),
      carbon_dioxide: await getCompositionValues("carbon_dioxide"),
      ethane: await getCompositionValues("ethane"),
      propane: await getCompositionValues("propane"),
      water: await getCompositionValues("water"),
      hydrogen_sulfide: await getCompositionValues("hydrogen_sulfide"),
      hydrogen: await getCompositionValues("hydrogen"),
      carbon_monoxide: await getCompositionValues("carbon_monoxide"),
      oxygen: await getCompositionValues("oxygen"),
      butane: await getCompositionValues("butane"),
      n_butane: await getCompositionValues("n_butane"),
      i_butane: await getCompositionValues("i_butane"),
      pentane: await getCompositionValues("pentane"),
      n_pentane: await getCompositionValues("n_pentane"),
      i_pentane: await getCompositionValues("i_pentane"),
      n_hexane: await getCompositionValues("n_hexane"),
      n_heptane: await getCompositionValues("n_heptane"),
      n_octane: await getCompositionValues("n_octane"),
      n_nonane: await getCompositionValues("n_nonane"),
      n_decane: await getCompositionValues("n_decane"),
      helium: await getCompositionValues("helium"),
      argon: await getCompositionValues("argon"),
      total_mole: props.total_mole ?? "",
      molecular_weight: props.molecular_weight ?? "",
      density_col_disp: props.units_density_heading,
      non_newtonian: props.non_newtonian_liquid === true ? "Y" : "N",
      non_newtonian_k: props.k_flow_consistency_index,
      non_newtonian_n: props.n_flow_behavior,
      spec_gravity: props.specific_gravity,
      molec_weight: props.molecular_weight,
      compressibility: props.compressibility,
      transaction_id: props.query_params.pa_ref_id
        ? props.query_params.pa_ref_id
        : Object.keys(props.get_sizing_data).length > 0
          ? props.get_sizing_data[0].transaction_id
          : props.query_params.pa_ref_id,
      header_id: props.query_params.header_id ? props.query_params.header_id : 0,
      line_id: props.query_params.line_id ? props.query_params.line_id : 0,
      model_name:
        props.select_technology.toLowerCase() === "magnetic"
          ? props.selected_row_data.parent_model
          : props.select_technology.toLowerCase() === "vortex"
            ? props.selected_row_data.parent_model
            : props.selected_row_data.product,
      parent_model:
        props.select_technology.toLowerCase() === "magnetic"
          ? props.selected_row_data.parent_model
          : props.select_technology.toLowerCase() === "vortex"
            ? props.selected_row_data.parent_model
            : props.selected_row_data.product,
      dens_acc_units: props.selected_row_data.density_accuracy_units ? props.selected_row_data.density_accuracy_units : "",
      mass_flow_acc_opt_flw: props.selected_row_data.massflowaccuracy_liquid ? props.selected_row_data.massflowaccuracy_liquid : "",
      cryo_acc: props.selected_row_data.cryo_accuracy ? props.selected_row_data.cryo_accuracy : "",
      press_drop_optflw_units: props.selected_row_data.pressure_units ? props.selected_row_data.pressure_units : "",
      press_drop_opt_flow:
        props.selected_row_data.pressure_drop !== undefined
          ? props.selected_row_data.pressure_drop[1] === undefined
            ? ""
            : props.selected_row_data.pressure_drop[1]
          : "",
      wetted_material: props.wetted_material_label ? props.wetted_material_label : "",
      wetted_material_meter:
        props.selected_row_data.wetted_material ?  props.selected_row_data.wetted_material : "",
      velocity_opt_flow:
        props.selected_row_data.tube_velocity !== undefined
          ? props.selected_row_data.tube_velocity[1] === undefined
            ? ""
            : props.selected_row_data.tube_velocity[1]
          : "",
      vel_opt_flw_units: tubeVelocity(),
      us_oiml_cust_transfer: props.selected_row_data.custodytransferwm_oiml ? props.selected_row_data.custodytransferwm_oiml : "",
      flow_rate_unit: props.selected_row_data.flow_rate_unit ? props.selected_row_data.flow_rate_unit : "",
      velocity_unit: tubeVelocity(),
      high_pressure: "N",
      mag_high_press: props.selected_row_data.high_press ? props.selected_row_data.high_press : "",
      mag_line_size_mm: props.selected_row_data.line_size_mm ? props.selected_row_data.line_size_mm : "",
      mag_line_size: props.selected_row_data.line_size_inch ? props.selected_row_data.line_size_inch : "",
      mag_meter_max_flow: props.selected_row_data.mag_meter_max_flow ? props.selected_row_data.mag_meter_max_flow : "",
      mag_meter_min_flow: props.selected_row_data.mag_meter_min_flow ? props.selected_row_data.mag_meter_min_flow : "",
      mag_max_fluid_ser_flow: props.selected_row_data.max_fluid_service_limits ? props.selected_row_data.max_fluid_service_limits : "",
      mag_min_fluid_ser_flow: props.selected_row_data.min_fluid_service_limits ? props.selected_row_data.min_fluid_service_limits : "",
      mag_min_accuracy: props.selected_row_data.min_accurate_flow ? props.selected_row_data.min_accurate_flow : "",
      mag_system: props.selected_row_data.mag_system ? props.selected_row_data.mag_system : "",
      mag_min_span: props.selected_row_data.mag_minimum_span ? props.selected_row_data.mag_minimum_span : "",
      mag_tx: props.selected_row_data.tx ? props.selected_row_data.tx : "",
      mag_flowtube: props.selected_row_data.flow_tube ? props.selected_row_data.flow_tube : "",
      spare_sensor: sensor,
      flow_meter: flow_meter,
      trnsmtr_char: props.selected_row_data.transmitter_characterstic ? props.selected_row_data.transmitter_characterstic : "",
      prsure_loss_at_min_flow: props.selected_row_data.press_loss_at_min_flow ? props.selected_row_data.press_loss_at_min_flow : "",
      prsure_loss_at_opr_flow: props.selected_row_data.press_loss_at_op_flow ? props.selected_row_data.press_loss_at_op_flow : "",
      prsure_loss_at_max_flow: props.selected_row_data.press_loss_at_max_flow ? props.selected_row_data.press_loss_at_max_flow : "",
      meter_max_flow: props.selected_row_data.meter_max_measurable_flow ? props.selected_row_data.meter_max_measurable_flow : "",
      meter_max_acc_flow: props.selected_row_data.meter_max_accurate_flow ? props.selected_row_data.meter_max_accurate_flow : "",
      meter_min_accurate_flow: props.selected_row_data.meter_min_accurate_flow ? props.selected_row_data.meter_min_accurate_flow : "",
      mtr_min_msrble_flow: props.selected_row_data.meter_min_measurable_flow ? props.selected_row_data.meter_min_measurable_flow : "",
      min_measurable_nom_vel: props.selected_row_data.meter_min_measure_nominal_velocity
        ? props.selected_row_data.meter_min_measure_nominal_velocity
        : "",
      min_accurate_nom_vel: props.selected_row_data.min_accurate_nominal_velocity ? props.selected_row_data.min_accurate_nominal_velocity : "",
      min_upstream_pressure: props.selected_row_data.min_upstream_pressure ? props.selected_row_data.min_upstream_pressure : "",
      max_measurable_nom_vel: props.selected_row_data.meter_max_measure_nominal_velocity
        ? props.selected_row_data.meter_max_measure_nominal_velocity
        : "",
      max_accurate_nom_vel: props.selected_row_data.meter_max_accurate_nominal_velocity
        ? props.selected_row_data.meter_max_accurate_nominal_velocity
        : "",
      max_accurate_pipe_vel: props.selected_row_data.max_accurate_pipe_velocity ? props.selected_row_data.max_accurate_pipe_velocity : "",
      min_msrbl_pipe_vel_calc: props.selected_row_data.min_measurable_pipe_velocity ? props.selected_row_data.min_measurable_pipe_velocity : "",
      mtr_vel_min_oprting_vel: props.selected_row_data.meter_vel_at_min_op_vel ? props.selected_row_data.meter_vel_at_min_op_vel : "",
      mtr_rey_min_opr_vel: props.selected_row_data.reynolds_number_at_min_op_velocity
        ? props.selected_row_data.reynolds_number_at_min_op_velocity
        : "",
      min_msrbl_meter_vel: props.selected_row_data.min_measurable_meter_velocity ? props.selected_row_data.min_measurable_meter_velocity : "",
      mtr_rey_min_msrbl_vel: props.selected_row_data.reynolds_number_at_min_measurable_velocity
        ? props.selected_row_data.reynolds_number_at_min_measurable_velocity
        : "",
      sen_min_press_opt_units: props.selected_row_data.line_pressure_unit ? props.selected_row_data.line_pressure_unit : "",
      custody_transfer_flag: props.selected_row_data.custodytransferwm ? props.selected_row_data.custodytransferwm : "",
      full_scale_dsgn_nom_vel: props.selected_row_data.velocity_at_fsd ? props.selected_row_data.velocity_at_fsd : "",
      vortex_process_line_size: props.selected_row_data.size_inch ? props.selected_row_data.size_inch : "",
      single_tube: props.selected_row_data.singletube ? props.selected_row_data.singletube : "",
      self_draining_flag: props.selected_row_data.selfdrain ? props.selected_row_data.selfdrain : "",
      high_temp_flag: props.selected_row_data.hightemp ? props.selected_row_data.hightemp : "",
      batching_short: props.selected_row_data.shortbatch ? props.selected_row_data.shortbatch : "",
      show_more_rates_data: props.show_more_rates_result_data ? props.show_more_rates_result_data : "",
      show_more_rates_graph_data: props.show_more_rates_data ? props.show_more_rates_data[0] : "",
      requested_by: "",
      product:
        props.select_technology.toLowerCase() === "magnetic"
          ? props.selected_row_data.parent_model
          : props.select_technology.toLowerCase() === "vortex"
            ? props.selected_row_data.product_1
            : props.selected_row_data.product,
      net_mass_flow_to_measure: "N",
      concentration_to_measure: "N",
      temperature_to_measure: "N",
      dens_acc_all_rates: (props.density_accuracy && !isNaN(props.density_accuracy)) ? props.density_accuracy : "",
      sen_min_press_opt_cond: 30.863178199234724,
      brc_compressibility: 1,
      budgetary_price: 0,
      vortex_custom_line_id: 0,
      trigger_factor: 0,
      full_opr_max_nom_vel: 0,
      operating_min_nom_vel: 0,
      def_low_flow_cut_off:
        props.select_technology.toLowerCase() === "vortex"
          ? props.selected_row_data.low_flow_cutoff_freq !== undefined
            ? props.selected_row_data.low_flow_cutoff_freq
            : ""
          : "",
      min_upper_range_value: 0,
      vortex_process_linesize_uom: "",

      pf: "test",
      generate_sizing: true,
      density_msurmnt_flag: props.density ? "Y" : "N",
      density_accuracy: (props.density_accuracy && !isNaN(props.density_accuracy)) ? props.density_accuracy : "",
      flow_accuracy: props.flow_accuracy_value ? props.flow_accuracy_value : 0,
      preferred_style: "PeakPerformance",
      live_oil: "N",
      mvdxmtr_flag: "Y",
      warn_flow: "",
      warn_line_press: "",
      warn_temp: "",
      notes: "",
      particle_size: "PS1",
      cryo_accuracy_flag: props.selected_row_data.cryo_accuracy_flag ?? "",
      transmitter: "",
      process_connection: "",
      electronic_interface: "",
      approvals: "",
      vtx_custom_line_id_unit: "inch",

      vortex_schedule: props.schedule_value,

      dual_high_pressure: "N",
      custom_id_flag: "N",

      prepared_by: props.email ? props.email : "flow@istore.com", //'OnlineStore.MMICustomerSupport@emerson.com',

      mag_meter_min_acc_flow: 0,
      mag_meter_min_acc_vel: 0,
      mag_meter_max_velocity: 0,
      mag_op_max_velocity: 0,
      mag_op_norm_velocity: 0,
      mag_op_min_velocity: 0,
      mag_meter_min_velocity: 0,
      mag_transmitter: "N",
      mag_mat_guide_fluid: "",
      mag_mat_guide_conc: "",
      mag_atex_app: "",
      mag_8750wa: "",
      mag_integral_mount: "",
      mag_remote_mount: "",
      mag_liner_material: "",
      Sensor_Tag: "",
      Transmitter_Tag: "",
      Customer_Name: "",
      Model_String: "",
      calibration: "",
      mounting: "",
      model_char: "",

      item3: "",
      item4: "",
      size_char: "",
      size_inch: "",
      size_mm: "",
      suggested_meter: "",
      trnsmtr_int_mount: "",
      item1: "",
      item2: "",
      transaction_number: props.query_params.transaction_num ? props.query_params.transaction_num : "",
      operation: "",
      source_type: props.query_params.source_type,
      from_page: props.query_params.from_page,
      sizing_header_id: 0,
      flow_percentage: 95,
      flow_type: "VTX",
      redundancy: props.sis_application === "redundancy" ? "Y" : "N",
      safety_certified: props.sis_application === "radio-safety" ? "Y" : "N",
    };

    data.model_name = modelForSaveSizing(data.model_name);
    data.parent_model = modelForSaveSizing(data.parent_model);
    data.product = modelForSaveSizing(data.product);
    props.application === "" ? props.ApplicationNameError(true) : props.ApplicationNameError(false);
    props.application === "" ? props.reportFlag(true) : props.reportFlag(false);
    if (props.application !== "") {
      setDownloadload(true);
      const fetchData = async () => {
        await axios({
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}/mmipa/save_sizing/`,
          headers: {
            "Content-Type": "application/json",
          },
          xsrfHeaderName: "X-CSRFToken",
          xsrfCookieName: "csrftoken",
          data: data,
        })
          .then(function (response) {
            props.query_params.pa_ref_id = response.data.Data.pa_reference_id;

            const fetchData = async () => {
              let url = '/mmipa/download_fcs_report/'
              let reqPayload = {
                  format: type,
                  paginatedReportConfiguration: {
                    parameterValues: [
                      {
                        name: "Header1transactionid",
                        value: response.data.Data.pa_reference_id,
                      },
                      {
                        name: "LinesID",
                        value:
                          props.select_technology.toLowerCase() === "vortex"
                            ? props.show_more_rates_data[0].powerbi_graph_id
                            : props.show_more_rates_data[0].powerbi_graph_id,
                      },
                      { name: "LinesTECHNOLOGY", value: props.select_technology.toLowerCase() },
                      {
                        name: "ReportLabels1LANGUAGECODE",
                        value: props.query_params.language ? props.query_params.language : languageCode,
                      },
                    ],
                  },
              }
              if (props.report_configs.length > 0) {
                let filtered_config = props.report_configs.filter(i => i.technology.indexOf('FCS') !== -1)
                if (filtered_config.length > 0 && filtered_config[0].report_generated_for_all === 'HTML'){
                  url = '/mmipa/generate_report_poc_fcs/';
                  reqPayload = {
                    "format": type,
                    "transaction_id": response.data.Data.pa_reference_id,
                    "language": props.query_params.language ? props.query_params.language : languageCode,
                    "email_id": props.email ? props.email : "flow@istore.com",
                    "report_type": "FCS",
                    "quote_number": null
                  }
                }
              }
              await axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_URL}${url}`,
                headers: {
                  "Content-Type": "application/json",
                },
                responseType: "arraybuffer",
                xsrfHeaderName: "X-CSRFToken",
                xsrfCookieName: "csrftoken",
                data : reqPayload,
           
              })
                .then(function (response) {
                  let res = JSON.parse(new TextDecoder().decode(response.data));

                  let data = Uint8Array.from(atob(res.data.pdf_data), (c) => c.charCodeAt(0));

                  var file = new Blob([data], { type: "application/pdf" });
                  var fileURL = URL.createObjectURL(file);
                  var link = document.createElement("a");
                  let fileName = `${res.data.sizing_name + "_" + res.data.model_name + "_FCS"}`;
                  fileName = fileName.replace(/[^a-zA-Z0-9]/g, "_");
                  link.download = fileName;
                  link.href = fileURL;
                  link.click();
                  setDownloadload(false);
                  //toggleWindow()
                })
                .catch(function (error) {
                  console.log("error", error);
                });
            };
            fetchData();
          })
          .catch(function (error) {
            console.log("error", error);
            setDownloadload(false);
          });
      };
      fetchData();
    }
  };
  useEffect(() => {
    if (
      props.search_parameters.select_technology.toLowerCase() === "coriolis" ||
      props.search_parameters.select_technology.toLowerCase() === "density" ||
      props.search_parameters.select_technology.toLowerCase() === "magnetic" ||
      props.search_parameters.select_technology.toLowerCase() === "vortex"
    ) {
      setGraphload(true);
      if (props.show_more_rates_data.length > 0) {
        setGraphImage(BASE64_IMG_PREFIX + props.show_more_rates_data[0].graph_data);
        setGraphload(false);
        setFullGraph(false);
      }
    } else {
      const fetchData = async () => {
        await setGraphload(true);

        await axios({
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}/mmipa/get_power_bi_graph/`,
          headers: {
            "Content-Type": "application/json",
          },
          xsrfHeaderName: "X-CSRFToken",
          xsrfCookieName: "csrftoken",
          data: {
            format: "IMAGE",
            paginatedReportConfiguration: {
              formatSettings: {
                OutputFormat: "PNG",
              },
              parameterValues: [
                {
                  name: "LinesID",
                  value:
                    props.search_parameters.select_technology.toLowerCase() === "vortex"
                      ? props.show_more_rates_data
                        ? props.show_more_rates_data[0].powerbi_graph_id
                        : ""
                      : props.show_more_rates_data[0]
                        ? props.show_more_rates_data[0].powerbi_graph_id
                        : "",
                },
                { name: "LinesTECHNOLOGY", value: props.search_parameters.select_technology.toLowerCase() },
              ],
            },
          },
        })
          .then(async function (response) {
            await setGraphload(false);
            await setFullGraph(true);
            setGraphImage(BASE64_IMG_PREFIX + response.data.Data);
          })
          .catch(async function (error) {
            await setGraphload(false);
            await setFullGraph(false);
          });
      };
      if (Object.keys(props.show_more_rates_data).length > 0) {
        fetchData();
      }
    }
  }, [props.show_more_rates_data]);
  const exportGraph = () => {
    // TBD
  };
  useEffect(() => {
    if (props.query_params.from_page === "BTAB") {
      setSaveLabel("XXSPECIFY_MMI_BTAB_PROCEED");
    } else if (props.query_params.from_page === "PRJWSC") {
      setSaveLabel("XXSPECIFY_MMI_PRJS_PROCEED");
    } else {
      setSaveLabel("XXSPECIFY_MMI_SR_TAB_PROCEED");
    }
  }, []);

  const ModeltransmitterPrefix = (data) => {
    let model_name;
    let array = ["GDM", "FDM", "SGM", "CDM100P", "CDM100M"];
    if (props.select_technology.toLowerCase() === "coriolis") {
      if (props.equipment_selection.includes("Flow Meter")) {
        //Equipment selection
        model_name = data.model_name ? data.model_name : data.parent_model;
        if (model_name.startsWith("K") || model_name.startsWith("LNG") || model_name.startsWith("8750W")) {
          return model_name;
        } else {
          return "FT_" + model_name;
        }
      } else {
        return (model_name = data.model_name ? data.model_name : data.parent_model);
      }
    } else if (props.select_technology.toLowerCase() === "magnetic") {
      //mag
      if (props.equipment_selection.includes("Magmeter System")) {
        //Equipment selection
        model_name = data.model_name ? data.model_name : data.parent_model;
        if (model_name.startsWith("K") || model_name.startsWith("LNG") || model_name.startsWith("8750W")) {
          return model_name;
        } else {
          return "FT_" + model_name;
        }
      } else {
        return (model_name = data.model_name ? data.model_name : data.parent_model);
      }
    } else if (props.select_technology.toLowerCase() === "density") {
      //density
      model_name = data.model_name ? data.model_name : data.parent_model;
      if (props.equipment_selection.includes("Flow Meter") && !array.includes(model_name)) {
        //Equipment selection
        return "FT_" + model_name;
      } else {
        return (model_name = data.model_name ? data.model_name : data.parent_model);
      }
    } else {
      return (model_name = data.model_name ? data.model_name : data.parent_model);
    }
  };

  const handleClose = () => {
    setProceedClick(false);
    var element = document.getElementById("requisitionListContent");
    let data = element ? (element.style.display = "block") : "";
  };

  const handleTransmitterSelection = (key) => {
    setTransmitterLabel(transmitterList[key ? key : 0]["label"]);
    setTransmitterValue(transmitterList[key ? key : 0]["value"]);
  };

  const configure = async (type) => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const query_params = {};
    let lang_id = params.get("langId") ? params.get("langId") : -1;
    let locale = params.get("locale") ? params.get("locale") : "en-us";
    let catalog_id = params.get("catalogId") ? params.get("catalogId") : 20051;
    let store_id = params.get("storeId") ? params.get("storeId") : 20151;
    let my_acct_main = params.get(" myAcctMain") ? params.get(" myAcctMain") : 1;
    let language = getLanguageCode(props.query_params.language, props.query_params.locale);
    const localData = localStorage.getItem("AppLogin");
    const email = props.email ? props.email : "flow@istore.com";
    let source =
      props.fluid_source.toLowerCase() === "database"
        ? "FLUIDS"
        : props.fluid_source.toLowerCase() === "custom"
          ? "SLURRYNAME"
          : props.fluid_source === "Natural Gas"
            ? "GAS_FS"
            : "";
    let fluidservice =
      props.fluid_service === "Normal (0-39 ft/sec or 0-12 m/sec)"
        ? "NORMAL"
        : props.fluid_service === "Preferred (2-20 ft/sec or 0.6 ? 6.1 m/sec)"
          ? "PREFERRED"
          : props.fluid_service === "Abrasive Slurry (3-10 ft/sec or 0.9 ? 3.0 m/sec)"
            ? "ABRASIVE SLURRY"
            : props.fluid_service === "Non-abrasive Slurry (5-15 ft/sec or 1.5-4.6 m/sec)"
              ? "NON-ABRASIVE SLURRY"
              : "NORMAL";
    let flow_meter = "";
    let sensor = "";
    if (props.select_technology.toLowerCase() !== "vortex") {
      flow_meter =
        props.select_technology.toLowerCase() === "magnetic"
          ? props.equipment_selection.includes("Magmeter System")
            ? "Y"
            : "N"
          : props.equipment_selection.includes("Flow Meter")
            ? "Y"
            : "N";
      sensor =
        props.select_technology.toLowerCase() === "magnetic"
          ? props.equipment_selection.includes("Sensor")
            ? "Y"
            : "N"
          : props.equipment_selection.includes("Sensor Only / MVDSolo")
            ? "Y"
            : "N";
    }

    let data = {
      email_id: props.email ? props.email : "flow@istore.com", //'rajeshwari.mulemani@emerson.com',
      mass_flow_to_measure: props.flow ? "Y" : "N",
      density_to_measure: props.density && !props.flow ? "Y" : "N",
      catentry_id: transmitter_label,
      viscosity_to_measure: props.viscosity ? "Y" : "N",
      attribute1: props.project_name,
      coriolis: props.select_technology === "Coriolis" ? "Y" : "N",
      mag: props.select_technology.toLowerCase() === "magnetic" ? "Y" : "N",
      vortex: props.select_technology === "Vortex" ? "Y" : "N",
      density: props.select_technology === "Density" ? "Y" : "N",
      viscosity: props.select_technology === "Viscosity" ? "Y" : "N",
      warning_msgs: {
        cavitation_msg: props.selected_row_data
          ? props.selected_row_data.cavitation_warning_msg
            ? props.selected_row_data.cavitation_warning_msg
            : null
          : null,
        region_msg: props.selected_row_data
          ? props.selected_row_data.product
            ? props.selected_row_data.product.startsWith("K") || props.selected_row_data.product.endsWith("G")
              ? CustomTranslationText("XXSPECIFY_MMI_SR_LBL_FEWCONT", props)
              : null
            : null
          : null,
        ambient_temperature_mgs:
          props.search_parameters.select_technology.toLowerCase() === "vortex" ||
            props.search_parameters.select_technology.toLowerCase() === "magnetic"
            ? CustomTranslationText("XXSPECIFY_MMI_MAGVTXAMBTMP_LBL", props)
            : null,
        config_msg: props.config_warning_msg ? CustomTranslationText("XXSPECIFY_MMI_MC_LBL_ERRMSG112", props) : null,
        sonic_velocity_warning_msg: props.selected_row_data ? props.selected_row_data.sonic_velocity_warning_msg : "",
        amb_temp_warning_msg: props.selected_row_data ? props.selected_row_data.amb_temp_warning_msg : "",
        high_temperature_warning_msg: props.high_temperature_warning_msg ? report_high_temperature_warning_msg : null,
        high_temperature_error_msg: props.high_temperature_error_msg ? report_high_temperature_error_msg : null,
      },
      dual: props.dual ? "Y" : "N",
      reducer: props.reducer ? "Y" : "N",
      tank: props.tank ? "Y" : "N",
      flanged_wafer: props.flanged ? "Y" : "N",
      quad: props.quad ? "Y" : "N",
      wafer: props.wafer ? "Y" : "N",
      hedge: props.hygienic ? "Y" : "N",
      is_chk_high_pressure: props.pressure ? "Y" : "N",
      no_check: props.display_all_sensors_no_filters ? "Y" : "N",
      mag_hygienic_appl: props.hygienic_application ? "Y" : "N",
      mag_high_press_noise: props.high_process_noise_slurry ? "Y" : "N",
      inline: props.inline ? "Y" : "N",
      slipstream: props.flow_through_chamber_slipstream ? "Y" : "N",
      sensor_selection: props.product_family ? props.product_family : "",
      technology: props.select_technology,
      pipeline_size: props.select_technology === "Magnetic" || props.select_technology === "Vortex" ? props.process_line_size : props.line_size,
      fluid_state: props.fluid_state.toUpperCase(),
      fluid_source: props.fluid_state.toUpperCase() === "STEAM" ? props.steam_pressure_temp : source,
      mag_cond_5_49_9: props.conductivity === "5.0 - 49.9 microsiemens/cm" ? "Y" : "N",
      mag_cond_50_99_9: props.conductivity === "50.0 - 99.9 microsiemens/cm" ? "Y" : "N",
      mag_cond_100: props.conductivity === "Greater than 100 microsiemens/cm" ? "Y" : "N",
      mag_fluid_service: fluidservice,
      fluid:
        props.fluid_state.toUpperCase() !== "STEAM"
          ? props.fluid_source.toLowerCase() === "custom"
            ? props.custom_fluid_name
              ? props.custom_fluid_name
              : ""
            : props.fluid_source.toLowerCase() === "natural gas"
              ? props.natural_gas_compose
                ? props.natural_gas_composition_name
                : props.pick_gas_from_db
                  ? props.pick_gas_from_db
                  : ""
              : props.pick_fluids_from_db
                ? props.pick_fluids_from_db
                : ""
          : "WATER",
      max_amb_temp: max_ambient !== "" ? parseFloat(max_ambient).toFixed(temperature_decimal) : "",
      min_amb_temp: min_ambient !== "" ? parseFloat(min_ambient).toFixed(temperature_decimal) : "",
      op_amb_temp: normal_ambient !== "" ? parseFloat(normal_ambient).toFixed(temperature_decimal) : "",
      des_amb_temp: full_ambient !== "" ? parseFloat(full_ambient).toFixed(temperature_decimal) : "",
      min_flow: min_flow_rate !== "" ? parseFloat(min_flow_rate).toFixed(flow_rate_decimal) : "",
      op_flow: normal_flow_rate !== "" ? parseFloat(normal_flow_rate).toFixed(flow_rate_decimal) : "",
      max_flow: max_flow_rate !== "" ? parseFloat(max_flow_rate).toFixed(flow_rate_decimal) : "",
      des_flow: full_flow_rate !== "" ? parseFloat(full_flow_rate).toFixed(flow_rate_decimal) : "",
      min_pres: min_line_pressure !== "" ? parseFloat(min_line_pressure).toFixed(line_pressure_decimal) : "",
      op_pres: normal_line_pressure !== "" ? parseFloat(normal_line_pressure).toFixed(line_pressure_decimal) : "",
      max_pres: max_line_pressure !== "" ? parseFloat(max_line_pressure).toFixed(line_pressure_decimal) : "",
      des_pres: full_line_pressure !== "" ? parseFloat(full_line_pressure).toFixed(line_pressure_decimal) : "",
      min_temp: min_temperature !== "" ? parseFloat(min_temperature).toFixed(temperature_decimal) : "",
      op_temp: normal_temperature !== "" ? parseFloat(normal_temperature).toFixed(temperature_decimal) : "",
      max_temp: max_temperature !== "" ? parseFloat(max_temperature).toFixed(temperature_decimal) : "",
      des_temp: full_temperature !== "" ? parseFloat(full_temperature).toFixed(temperature_decimal) : "",
      min_dens: min_density !== "" ? parseFloat(min_density) : "",
      op_dens:
        props.units_density_heading === "Density"
          ? normal_density !== ""
            ? parseFloat(normal_density)
            : ""
          : props.density_specific_gravity !== ""
            ? parseFloat(props.density_specific_gravity)
            : "",
      max_dens: max_density !== "" ? parseFloat(max_density) : "",
      des_dens: 0,
      min_visc: min_viscosity !== "" ? parseFloat(min_viscosity) : "",
      op_visc:
        normal_viscosity !== ""
          ? parseFloat(normal_viscosity)
          : props.select_technology === "Coriolis"
            ? props.show_more_rates_data[0].viscosity
            : "",
      max_visc: max_viscosity !== "" ? parseFloat(max_viscosity) : "",
      des_visc: 0,
      flow_unit: props.fluid_state.toLowerCase() !== "gas" ? units_flow_rate.key : props.units_gas_flow_rate.key,
      pres_unit: units_line_pressure.key,
      temp_unit: units_temperature.key,
      dens_unit:
        props.units_density_heading === "Density"
          ? units_density.key
          : props.densityAccuracyUnits
            ? props.densityAccuracyUnits
            : props.units_gas_density_sg.key,
      visc_unit: units_viscosity.key,
      amb_temp_units: units_ambient.key,
      vapour_pressure_units: props.units_vapor.key,
      sonic_velocity: props.sonic_velocity !== "" ? parseFloat(props.sonic_velocity) : "",
      sonic_velocity_units: (props.units_sonic_velocity.key !== "" && props.units_sonic_velocity.key !== undefined) ? props.units_sonic_velocity.key : "",
      atmospheric_prsure: props.pressure_atmospheric !== "" ? parseFloat(props.pressure_atmospheric) : "",
      atmospheric_prsure_unit: props.units_pressure_atmospheric.key,
      max_flow_accuracy: props.max_flow_acc !== "" ? parseFloat(props.max_flow_acc) : "",
      max_pressure_drop: props.max_pressure_drop !== "" ? parseFloat(props.max_pressure_drop) : "",
      gas_base_ref_temp: props.fluid_state.toLowerCase() === "gas" ? (props.gas_temperature !== "" ? parseFloat(props.gas_temperature) : "") : "",
      gas_base_ref_pressure: props.fluid_state.toLowerCase() === "gas" ? (props.gas_pressure !== "" ? parseFloat(props.gas_pressure) : "") : "",
      gas_base_ref_density: props.fluid_state.toLowerCase() === "gas" ? (props.gas_density !== "" ? parseFloat(props.gas_density) : "") : "",
      gas_base_ref_temp_units: props.fluid_state.toLowerCase() === "gas" ? props.units_gas_temperature.key : "",
      gas_base_ref_pres_units: props.fluid_state.toLowerCase() === "gas" ? props.units_gas_pressure.key : "",
      gas_base_ref_den_units: props.fluid_state.toLowerCase() === "gas" ? props.units_gas_density.key : "",
      vapour_pressure: props.min_vapor !== "" ? parseFloat(props.min_vapor) : "",
      appl: props.application,
      service: props.sizing_service,
      four_corner_flag: props.calculate_density_variation ? "Y" : "N",
      calculate_density_variation: props.calculate_density_variation ? "Y" : "N",
      ngas_composition_method: props.composition_method,
      ngas_list_name: props.natural_gas_composition_name,
      relative_density: props.relative_density,
      mole_carbon_dioxide: props.mole_percent_CO2,
      mole_carbon_monoxide: props.mole_percent_CO,
      mole_hydrogen: props.mole_percent_H2,
      mole_nitrogen: props.mole_percent_N2,
      heating_value: props.heating_value,
      heating_value_uom: props.heating_value_dropdown,
      ngas_composition_flag: props.natural_gas_compose,
      methane: await getCompositionValues("methane"),
      nitrogen: await getCompositionValues("nitrogen"),
      carbon_dioxide: await getCompositionValues("carbon_dioxide"),
      ethane: await getCompositionValues("ethane"),
      propane: await getCompositionValues("propane"),
      water: await getCompositionValues("water"),
      hydrogen_sulfide: await getCompositionValues("hydrogen_sulfide"),
      hydrogen: await getCompositionValues("hydrogen"),
      carbon_monoxide: await getCompositionValues("carbon_monoxide"),
      oxygen: await getCompositionValues("oxygen"),
      butane: await getCompositionValues("butane"),
      n_butane: await getCompositionValues("n_butane"),
      i_butane: await getCompositionValues("i_butane"),
      pentane: await getCompositionValues("pentane"),
      n_pentane: await getCompositionValues("n_pentane"),
      i_pentane: await getCompositionValues("i_pentane"),
      n_hexane: await getCompositionValues("n_hexane"),
      n_heptane: await getCompositionValues("n_heptane"),
      n_octane: await getCompositionValues("n_octane"),
      n_nonane: await getCompositionValues("n_nonane"),
      n_decane: await getCompositionValues("n_decane"),
      helium: await getCompositionValues("helium"),
      argon: await getCompositionValues("argon"),
      total_mole: props.total_mole ?? "",
      molecular_weight: props.molecular_weight ?? "",
      density_col_disp: props.units_density_heading,
      non_newtonian: props.non_newtonian_liquid === true ? "Y" : "N",
      non_newtonian_k: props.k_flow_consistency_index,
      non_newtonian_n: props.n_flow_behavior,
      spec_gravity: props.specific_gravity,
      molec_weight: props.molecular_weight,
      compressibility: props.compressibility,
      transaction_id: props.query_params.pa_ref_id
        ? props.query_params.pa_ref_id
        : Object.keys(props.get_sizing_data).length > 0
          ? props.get_sizing_data[0].transaction_id
          : props.query_params.pa_ref_id,
      header_id: props.query_params.header_id ? props.query_params.header_id : 0,
      line_id: props.query_params.line_id ? props.query_params.line_id : 0,
      model_name:
        props.select_technology.toLowerCase() === "magnetic"
          ? props.selected_row_data.parent_model
          : props.select_technology.toLowerCase() === "vortex"
            ? props.selected_row_data.parent_model
            : props.selected_row_data.product,
      parent_model:
        props.select_technology.toLowerCase() === "magnetic"
          ? props.selected_row_data.parent_model
          : props.select_technology.toLowerCase() === "vortex"
            ? props.selected_row_data.parent_model
            : props.selected_row_data.product,
      dens_acc_units: props.selected_row_data.density_accuracy_units ? props.selected_row_data.density_accuracy_units : "",
      mass_flow_acc_opt_flw: props.selected_row_data.massflowaccuracy_liquid ? props.selected_row_data.massflowaccuracy_liquid : "",
      cryo_acc: props.selected_row_data.cryo_accuracy ? props.selected_row_data.cryo_accuracy : "",
      press_drop_optflw_units: props.selected_row_data.pressure_units ? props.selected_row_data.pressure_units : "",
      press_drop_opt_flow:
        props.selected_row_data.pressure_drop !== undefined
          ? props.selected_row_data.pressure_drop[1] === undefined
            ? ""
            : props.selected_row_data.pressure_drop[1]
          : "",
      wetted_material: props.wetted_material_label ? props.wetted_material_label : "",
      wetted_material_meter:
        props.selected_row_data.wetted_material ?  props.selected_row_data.wetted_material : "",
      velocity_opt_flow:
        props.selected_row_data.tube_velocity !== undefined
          ? props.selected_row_data.tube_velocity[1] === undefined
            ? ""
            : props.selected_row_data.tube_velocity[1]
          : "",
      vel_opt_flw_units: tubeVelocity(),
      us_oiml_cust_transfer: props.selected_row_data.custodytransferwm_oiml ? props.selected_row_data.custodytransferwm_oiml : "",
      flow_rate_unit: props.selected_row_data.flow_rate_unit ? props.selected_row_data.flow_rate_unit : "",
      velocity_unit: tubeVelocity(),
      high_pressure: "N",
      mag_high_press: props.selected_row_data.high_press ? props.selected_row_data.high_press : "",
      mag_line_size_mm: props.selected_row_data.line_size_mm ? props.selected_row_data.line_size_mm : "",
      mag_line_size: props.selected_row_data.line_size_inch ? props.selected_row_data.line_size_inch : "",
      mag_meter_max_flow: props.selected_row_data.mag_meter_max_flow ? props.selected_row_data.mag_meter_max_flow : "",
      mag_meter_min_flow: props.selected_row_data.mag_meter_min_flow ? props.selected_row_data.mag_meter_min_flow : "",
      mag_max_fluid_ser_flow: props.selected_row_data.max_fluid_service_limits ? props.selected_row_data.max_fluid_service_limits : "",
      mag_min_fluid_ser_flow: props.selected_row_data.min_fluid_service_limits ? props.selected_row_data.min_fluid_service_limits : "",
      mag_min_accuracy: props.selected_row_data.min_accurate_flow ? props.selected_row_data.min_accurate_flow : "",
      mag_system: props.selected_row_data.mag_system ? props.selected_row_data.mag_system : "",
      mag_min_span: props.selected_row_data.mag_minimum_span ? props.selected_row_data.mag_minimum_span : "",
      mag_tx: props.selected_row_data.tx ? props.selected_row_data.tx : "",
      mag_flowtube: props.selected_row_data.flow_tube ? props.selected_row_data.flow_tube : "",
      spare_sensor: sensor,
      flow_meter: flow_meter,
      trnsmtr_char: props.selected_row_data.transmitter_characterstic ? props.selected_row_data.transmitter_characterstic : "",
      prsure_loss_at_min_flow: props.selected_row_data.press_loss_at_min_flow ? props.selected_row_data.press_loss_at_min_flow : "",
      prsure_loss_at_opr_flow: props.selected_row_data.press_loss_at_op_flow ? props.selected_row_data.press_loss_at_op_flow : "",
      prsure_loss_at_max_flow: props.selected_row_data.press_loss_at_max_flow ? props.selected_row_data.press_loss_at_max_flow : "",
      meter_max_flow: props.selected_row_data.meter_max_measurable_flow ? props.selected_row_data.meter_max_measurable_flow : "",
      meter_max_acc_flow: props.selected_row_data.meter_max_accurate_flow ? props.selected_row_data.meter_max_accurate_flow : "",
      meter_min_accurate_flow: props.selected_row_data.meter_min_accurate_flow ? props.selected_row_data.meter_min_accurate_flow : "",
      mtr_min_msrble_flow: props.selected_row_data.meter_min_measurable_flow ? props.selected_row_data.meter_min_measurable_flow : "",
      min_measurable_nom_vel: props.selected_row_data.meter_min_measure_nominal_velocity
        ? props.selected_row_data.meter_min_measure_nominal_velocity
        : "",
      min_accurate_nom_vel: props.selected_row_data.min_accurate_nominal_velocity ? props.selected_row_data.min_accurate_nominal_velocity : "",
      min_upstream_pressure: props.selected_row_data.min_upstream_pressure ? props.selected_row_data.min_upstream_pressure : "",
      max_measurable_nom_vel: props.selected_row_data.meter_max_measure_nominal_velocity
        ? props.selected_row_data.meter_max_measure_nominal_velocity
        : "",
      max_accurate_nom_vel: props.selected_row_data.meter_max_accurate_nominal_velocity
        ? props.selected_row_data.meter_max_accurate_nominal_velocity
        : "",
      max_accurate_pipe_vel: props.selected_row_data.max_accurate_pipe_velocity ? props.selected_row_data.max_accurate_pipe_velocity : "",
      min_msrbl_pipe_vel_calc: props.selected_row_data.min_measurable_pipe_velocity ? props.selected_row_data.min_measurable_pipe_velocity : "",
      mtr_vel_min_oprting_vel: props.selected_row_data.meter_vel_at_min_op_vel ? props.selected_row_data.meter_vel_at_min_op_vel : "",
      mtr_rey_min_opr_vel: props.selected_row_data.reynolds_number_at_min_op_velocity
        ? props.selected_row_data.reynolds_number_at_min_op_velocity
        : "",
      min_msrbl_meter_vel: props.selected_row_data.min_measurable_meter_velocity ? props.selected_row_data.min_measurable_meter_velocity : "",
      mtr_rey_min_msrbl_vel: props.selected_row_data.reynolds_number_at_min_measurable_velocity
        ? props.selected_row_data.reynolds_number_at_min_measurable_velocity
        : "",
      sen_min_press_opt_units: props.selected_row_data.line_pressure_unit ? props.selected_row_data.line_pressure_unit : "",
      custody_transfer_flag: props.selected_row_data.custodytransferwm ? props.selected_row_data.custodytransferwm : "",
      full_scale_dsgn_nom_vel: props.selected_row_data.velocity_at_fsd ? props.selected_row_data.velocity_at_fsd : "",
      vortex_process_line_size: props.selected_row_data.size_inch ? props.selected_row_data.size_inch : "",
      single_tube: props.selected_row_data.singletube ? props.selected_row_data.singletube : "",
      self_draining_flag: props.selected_row_data.selfdrain ? props.selected_row_data.selfdrain : "",
      high_temp_flag: props.selected_row_data.hightemp ? props.selected_row_data.hightemp : "",
      batching_short: props.selected_row_data.shortbatch ? props.selected_row_data.shortbatch : "",
      show_more_rates_data: props.show_more_rates_result_data ? props.show_more_rates_result_data : "",
      show_more_rates_graph_data: props.show_more_rates_data ? props.show_more_rates_data[0] : "",
      requested_by: "",
      product:
        props.select_technology.toLowerCase() === "magnetic"
          ? props.selected_row_data.parent_model
          : props.select_technology.toLowerCase() === "vortex"
            ? props.selected_row_data.product_1
            : props.selected_row_data.product,
      net_mass_flow_to_measure: "N",
      concentration_to_measure: "N",
      temperature_to_measure: "N",
      dens_acc_all_rates: (props.density_accuracy && !isNaN(props.density_accuracy)) ? props.density_accuracy : "",
      sen_min_press_opt_cond: 30.863178199234724,
      brc_compressibility: 1,
      budgetary_price: 0,
      vortex_custom_line_id: 0,
      trigger_factor: 0,
      full_opr_max_nom_vel: 0,
      operating_min_nom_vel: 0,
      def_low_flow_cut_off:
        props.select_technology.toLowerCase() === "vortex"
          ? props.selected_row_data.low_flow_cutoff_freq !== undefined
            ? props.selected_row_data.low_flow_cutoff_freq
            : ""
          : "",
      min_upper_range_value: 0,
      vortex_process_linesize_uom: "",

      pf: "test",
      generate_sizing: false,
      density_msurmnt_flag: props.density ? "Y" : "N",
      density_accuracy: (props.density_accuracy && !isNaN(props.density_accuracy)) ? props.density_accuracy : "",
      flow_accuracy: props.flow_accuracy_value ? props.flow_accuracy_value : 0,
      preferred_style: "PeakPerformance",
      live_oil: "N",
      mvdxmtr_flag: "Y",
      warn_flow: "",
      warn_line_press: "",
      warn_temp: "",
      notes: "",
      particle_size: "PS1",
      cryo_accuracy_flag: props.selected_row_data.cryo_accuracy_flag ?? "",
      transmitter: "",
      process_connection: "",
      electronic_interface: "",
      approvals: "",
      vtx_custom_line_id_unit: "inch",

      vortex_schedule: props.schedule_value,

      dual_high_pressure: "N",
      custom_id_flag: "N",

      prepared_by: props.email ? props.email : "flow@istore.com", //'OnlineStore.MMICustomerSupport@emerson.com',

      mag_meter_min_acc_flow: 0,
      mag_meter_min_acc_vel: 0,
      mag_meter_max_velocity: 0,
      mag_op_max_velocity: 0,
      mag_op_norm_velocity: 0,
      mag_op_min_velocity: 0,
      mag_meter_min_velocity: 0,
      mag_transmitter: "N",
      mag_mat_guide_fluid: "",
      mag_mat_guide_conc: "",
      mag_atex_app: "",
      mag_8750wa: "",
      mag_integral_mount: "",
      mag_remote_mount: "",
      mag_liner_material: "",
      Sensor_Tag: "",
      Transmitter_Tag: "",
      Customer_Name: "",
      Model_String: "",
      calibration: "",
      mounting: "",
      model_char: "",

      item3: "",
      item4: "",
      size_char: "",
      size_inch: "",
      size_mm: "",
      suggested_meter: "",
      trnsmtr_int_mount: "",
      item1: "",
      item2: "",
      transaction_number: props.query_params.transaction_num ? props.query_params.transaction_num : "",
      operation: "",
      source_type: props.query_params.source_type,
      from_page: props.query_params.from_page,
      sizing_header_id: 0,
      flow_percentage: 95,
      flow_type: "VTX",
      redundancy: props.sis_application === "redundancy" ? "Y" : "N",
      safety_certified: props.sis_application === "radio-safety" ? "Y" : "N",
    };
    props.application === "" ? props.ApplicationNameError(true) : props.ApplicationNameError(false);
    props.application === "" ? props.reportFlag(true) : props.reportFlag(false);
    let model_name = ModeltransmitterPrefix(data);
    data.model_name = modelForSaveSizing(data.model_name);
    data.parent_model = modelForSaveSizing(data.parent_model);
    data.product = modelForSaveSizing(data.product);
    if (props.application !== "") {
      setLoad(true);
      setLoadType(type);
      props.proceedLoad(true);
      const fetchData = async () => {
        await axios({
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}/mmipa/save_sizing/`,
          headers: {
            "Content-Type": "application/json",
          },
          xsrfHeaderName: "X-CSRFToken",
          xsrfCookieName: "csrftoken",
          data: data,
        })
        .then(function (response) {
          let redirectUrl = ''
          if (props.query_params.source_type === "QUOTE" || props.query_params.source_type === "ORDER") {
            let new_window = window.open("about:blank", "_self");
            new_window.close();
          } else if (props.query_params.from_page === "BTAB") {
              redirectUrl = `${urls.btabUrl}getsizedetails?sizeid=${response.data.Data.pa_reference_id}&org=MMI&redirecturl=${props.query_params.redirect_url}&email=${props.query_params.email}`
              window.location.href = redirectUrl
          } else if (props.query_params.from_page === "PRJWSC") {
              redirectUrl = `${urls.prjswUrl}commonproductadvisordisplayview/result?pa_ref_id=${response.data.Data.pa_reference_id}`;
              window.location.href = redirectUrl
          } else {
            if (props.query_params.from_page === "Cart") {
              if (type === "popup_proceed") {
                redirectUrl = `${urls.gosUrl}?sizingReferenceId=${response.data.Data.pa_reference_id}&sizingModelNumber=${model_name}&oldOrderItemId=${props.query_params.order_tag}&locale=${locale}&myAcctMain=${my_acct_main}&catalogId=${catalog_id}&storeId=${store_id}&langId=${lang_id}&productId=${transmitter_label}`
                window.location.href = redirectUrl
              } else {
                redirectUrl = `${urls.gosUrl}?sizingReferenceId=${response.data.Data.pa_reference_id}&sizingModelNumber=${model_name}&oldOrderItemId=${props.query_params.order_tag}&locale=${locale}&myAcctMain=${my_acct_main}&catalogId=${catalog_id}&storeId=${store_id}&langId=${lang_id}`
                window.location.href = redirectUrl
              }
            } else {
              if (props.query_params.requisition_id === "" && props.query_params.order_tag === "") {
                if (type === "popup_proceed") {
                  redirectUrl = `${urls.gosUrl}?sizingReferenceId=${response.data.Data.pa_reference_id}&sizingModelNumber=${model_name}&locale=${locale}&myAcctMain=${my_acct_main}&catalogId=${catalog_id}&storeId=${store_id}&langId=${lang_id}&productId=${transmitter_label}`;
                  window.location.href = redirectUrl
                } else {
                  redirectUrl = `${urls.gosUrl}?sizingReferenceId=${response.data.Data.pa_reference_id}&sizingModelNumber=${model_name}&locale=${locale}&myAcctMain=${my_acct_main}&catalogId=${catalog_id}&storeId=${store_id}&langId=${lang_id}`;
                  window.location.href = redirectUrl
                }
              } else {
                let url = encodeURIComponent(
                  props.query_params.redirect_url +
                    `&requisitionListId=${props.query_params.requisition_id}&description=${props.query_params.description}`
                );
                if (type === "popup_proceed") {
                  redirectUrl = `${urls.gosUrl}?sizingReferenceId=${response.data.Data.pa_reference_id}&sizingModelNumber=${model_name}&reqListId=${props.query_params.requisition_id}&orderItemId=${props.query_params.order_tag}&description=${props.query_params.description}&redirectUrl=${url}&locale=${locale}&myAcctMain=${my_acct_main}&catalogId=${catalog_id}&storeId=${store_id}&langId=${lang_id}&productId=${transmitter_label}`;
                  window.location.href = redirectUrl
                } else {
                  redirectUrl = `${urls.gosUrl}?sizingReferenceId=${response.data.Data.pa_reference_id}&sizingModelNumber=${model_name}&reqListId=${props.query_params.requisition_id}&orderItemId=${props.query_params.order_tag}&description=${props.query_params.description}&redirectUrl=${url}&locale=${locale}&myAcctMain=${my_acct_main}&catalogId=${catalog_id}&storeId=${store_id}&langId=${lang_id}`;
                  window.location.href = redirectUrl
                }
              }
            }
          }
          let query_params = { ...props.query_params };
          query_params.pa_ref_id = response.data.Data.pa_reference_id;
          props.queryParams(query_params);
          setLoad(false);
          setLoadType("");
        })
          .catch(function (error) {
            console.log("error", error);
            setLoad(false);
            setLoadType("");
          });
      };

      fetchData();
    }
  };
  const proceed = () => {
    let model_name =
      props.select_technology.toLowerCase() === "magnetic"
        ? props.selected_row_data.parent_model
        : props.select_technology.toLowerCase() === "vortex"
          ? props.selected_row_data.parent_model
          : props.selected_row_data.product;

    if (props.query_params.source_type === "QUOTE" || props.query_params.source_type === "ORDER") {
      configure("proceed");
    } else if (props.select_technology.toLowerCase() === "coriolis" && props.equipment_selection.includes("Flow Meter")) {
      if (model_name.startsWith("K") || model_name.startsWith("LNG") || model_name.startsWith("8750W")) {
        configure("proceed");
      } else {
        setProceedClick(true);
      }
    } else if (props.select_technology.toLowerCase() === "magnetic" && props.equipment_selection.includes("Magmeter System")) {
      if (model_name.startsWith("K") || model_name.startsWith("LNG") || model_name.startsWith("8750W")) {
        configure("proceed");
      } else {
        setProceedClick(true);
      }
    } else {
      configure("proceed");
    }

    let data = {
      model_number: "FT_" + props.selected_row_data.product,
    };
    const fetchData = async () => {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}mmipa/transmitters/`,
        headers: {
          "Content-Type": "application/json",
        },
        xsrfHeaderName: "X-CSRFToken",
        xsrfCookieName: "csrftoken",
        data: data,
      })
        .then(async function (response) {
          let result = [];
          let data = response.data.data.transmitterList.map((c) => {
            result.push({
              label: c.catentryId,
              value: c.name,
            });
          });

          await setTransmitterList(result);
        })
        .catch(function (error) {
          console.log("error", error);
        });
    };
    fetchData();
  };
  const toPdf = (e) => {
    //TBD
    e.preventDefault();
    try {
      let doc = new jsPDF("landscape", "pt", "A4");
      doc.setFontSize(14);

      doc.text(
        10,
        20,
        props.search_parameters.select_technology.toLowerCase() === "vortex" || props.search_parameters.select_technology.toLowerCase() === "magnetic"
          ? props.selected_row_data
            ? props.selected_row_data.short_description !== "" && props.selected_row_data.short_description !== undefined
              ? props.selected_row_data.parent_model + "\n" + props.selected_row_data.short_description
              : props.selected_row_data.parent_model + "\n" + props.selected_row_data.short_desc
            : ""
          : props.selected_row_data.product + "\n" + props.selected_row_data.description
      );
      if (fullGraph) {
        doc.addImage(graph_image, "PNG", 10, 50, 900, 400);
      } else {
        doc.addImage(graph_image, "PNG", 10, 50, 800, 350);
      }

      doc.save("FlowGraph.pdf");
    } catch (error) {
      console.log("there is no image available " + error);
    }
  };
  const toggleWindow = () => {
    setToggleCompare(!toggleCompare);
  };
  const isChecked = (event) => {
    setFileType(event);
  };
  const options = {
    orientation: "portrait",
    unit: "in",
    format: [12, 20],
  };
  const isDisabled = () => {
    try {
      let result =
        props.tank || (props.select_technology === "Density" && props.fluid_state.toLowerCase() === "gas")
          ? false
          : (props.selected_row_data.press_drop_warning_msg !== "" && props.selected_row_data.press_drop_warning_msg !== undefined)
            ? true
            : props.select_technology === "Magnetic"
              ? Object.keys(props.show_more_rates_data).length > 0
                ? props.show_more_rates_data[0].product === props.selected_row_data.parent_model
                  ? false
                  : true
                : true
              : props.select_technology === "Vortex"
                ? Object.keys(props.show_more_rates_data).length > 0
                  ? props.show_more_rates_data[0].product === props.selected_row_data.parent_model
                    ? false
                    : true
                  : true
                : props.show_more_rates_data.length > 0
                  ? props.show_more_rates_data[0].product === props.selected_row_data.product
                    ? false
                    : true
                  : true;

      if ((props.selected_row_data.amb_temp_warning_msg !== "" && props.selected_row_data.amb_temp_warning_msg !== undefined) ||
        ((props.selected_row_data.sonic_velocity_warning_msg !== "" && props.selected_row_data.sonic_velocity_warning_msg !== undefined) && props.sonic_vel_entered)) {
        result = true;
      }
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const radioInputButtons = (id, label) => {
    let checked = id === file_type;

    return (
      <>
        <CustomRadiobutton
          type="radio"
          name="generatereport"
          id={id}
          onChange={(e) => isChecked(id)}
          checked={checked}
          className="custom-radiobutton"
          text={label}
        />
      </>
    );
  };
  let configureText =
    props.query_params.from_page === "BTAB" ? (
      <CustomTranslation data="XXSPECIFY_MMI_LBL_BTAB" />
    ) : props.query_params.from_page === "PRJWSC" ? (
      <CustomTranslation data="XXSPECIFY_MMI_LBL_PRJWS" />
    ) : (
      <CustomTranslation data="XXSPECIFY_MMI_LBL_Configure" />
    );
  let isExcluded = false;
  if (
    props.display_all_sensors_no_filters ||
    props.search_parameters.select_technology.toLowerCase() === "magnetic" ||
    props.search_parameters.select_technology.toLowerCase() === "vortex"
  ) {
    isExcluded = props.config_warning_msg;
  }
  return (
    <div id="view-graph-section">
      <Modal className="compare-modal" show={toggleCompare} size="md" onHide={() => toggleWindow()} animation={false}>
        <Modal.Header className="modal-header-fpa" closeButton>
          <Modal.Title className="modal-title-confirmation">
            <CustomTranslation data="XXSPECIFY_MMI_GR_LBL_DOWNLOAD" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-fpa">
          <Row>
            <Col md={12} lg={4} sm={12}>
              {radioInputButtons("pdf", <CustomTranslation data="XXSPECIFY_MMI_SR_BTN_PDFRADIO" />)}
            </Col>
            <Col md={12} lg={4} sm={12}>
              {radioInputButtons("word", <CustomTranslation data="XXSPECIFY_MMI_SR_BTN_WORDRADIO" />)}
            </Col>
          </Row>

          <button onClick={() => downloadReport()} className="download-button">
            {downloadLoad ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : ""}
            <CustomTranslation data="XXSPECIFY_MMI_SR_BTN_DNLDRPT" />
          </button>
        </Modal.Body>
      </Modal>
      <Modal className="compare-modal" centered show={proceed_click} onHide={handleClose} animation={false}>
        <Modal.Header className="modal-header-fpa-trans" closeButton>
          <Modal.Title className="modal-title">
            <CustomTranslation data={save_label} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-fpa">
          <CustomInputField
            name={<CustomTranslation data="XXSPECIFY_MMI_LBL_Sel_Sensor" />}
            size="lg"
            id="custom-fluid-name-inputfield"
            labelClassName="custom-fluid-label"
            fieldClassName="custom-fluid-fieldname5"
            value={props.select_technology.toLowerCase() === "magnetic" ? props.selected_row_data.parent_model : props.selected_row_data.product}
          />
          <CustomDropdown
            clear={props.clear_all_field}
            title={<CustomTranslation data="XXSPECIFY_MMI_LBL_Pref_Trans" />}
            id={"fluid-state-dropdown"}
            text={transmitter_value}
            btnDropdownStyles={"transmitter-dropdown"}
            dropdownArray={transmitterList}
            eventHandler={handleTransmitterSelection}
            active={transmitter_value}
            width={600}
          />
        </Modal.Body>
        <Modal.Footer>
          {props.query_params.from_page !== "BTAB" && props.query_params.from_page !== "PRJWSC" && (
            <CustomButton
              classNames="btn btn-configure-modal btn-arrow btn-compare btn-white-fpa"
              label={<CustomTranslation data="XXSPECIFY_MMI_LBL_Add_WS" />}
              onClick={handleClose}
              variant="outline-dark"
              arrow="right"
              size="lg"
              disabled={false}
            />
          )}
          <CustomButton
            classNames="btn btn-configure-modal btn-arrow btn-green"
            label={configureText}
            onClick={() => configure("popup_proceed")}
            size="lg"
            spinner={load && loadType === "popup_proceed"}
            disabled={load && loadType === "popup_proceed"}
          />
        </Modal.Footer>
      </Modal>
      <h5>
        <CustomTranslation data="XXSPECIFY_MMI_SR_TAB_VIEW_GR" />
      </h5>
      <div id="graph-download" ref={docToPrint}>
        {props.search_parameters.select_technology.toLowerCase() === "vortex" ||
          props.search_parameters.select_technology.toLowerCase() === "magnetic" ? (
          <>
            <p className="SubHeader">{props.selected_row_data ? props.selected_row_data.parent_model : ""}</p>
            <p className="SubHeader">
              {props.selected_row_data
                ? props.selected_row_data.short_description !== "" && props.selected_row_data.short_description !== undefined
                  ? props.selected_row_data.short_description
                  : props.selected_row_data.short_desc
                : ""}
            </p>
          </>
        ) : (
          <>
            <p className="SubHeader">{props.selected_row_data ? props.selected_row_data.product : ""}</p>
            <p className="SubHeader">{props.selected_row_data ? props.selected_row_data.description : ""}</p>
          </>
        )}

        {graphLoad ? (
          <>
            <Row className="loader">
              <Col xl={12} md={12}>
                <CustomLoader
                  phase={true}
                  loaderText="innerText"
                  loaderClass="innerLoaderImg"
                  subTitle={CustomTranslationText("XXSPECIFY_MMI_PR_PLS_WAIT", props)}
                />
              </Col>
            </Row>{" "}
          </>
        ) : (
          <img className="img-fluid" id="imageGraph" src={graph_image} />
        )}
      </div>
      <Row className="button-row">
        <Col sm={12} className="proceed-button-col-section">
          <span className="viewGraph">
            <button
              onClick={() => downloadReport()}
              disabled={
                downloadLoad ||
                ((props.selected_row_data.press_drop_warning_msg !== "" && props.selected_row_data.press_drop_warning_msg !== undefined)||
                ((props.selected_row_data.sonic_velocity_warning_msg !== "" && props.selected_row_data.sonic_velocity_warning_msg !== undefined)&& props.sonic_vel_entered)||
                (props.selected_row_data.amb_temp_warning_msg !== "" && props.selected_row_data.amb_temp_warning_msg !== undefined)
              )
                  ? true
                  : false ||
                  isExcluded
              }
              className="btn btn-sizing-nav btn-generate-report btn-white-fpa"
            >
              <CustomTranslation data="XXSPECIFY_MMI_SR_BTN_GEN_RPT" />
              {downloadLoad ? <Spinner as="span" animation="border" size="sm" role="status" variant="outline-dark" aria-hidden="true" /> : ""}
            </button>
            {/* <CustomButton
              classNames="btn btn-sizing-nav btn-generate-report btn-white"
              label={<CustomTranslation data="XXSPECIFY_MMI_SR_BTN_GEN_RPT" />}
              onClick={() =>downloadReport()}
              variant="outline-dark"
              size="lg"
            /> */}
            {/* <ReactToPdf targetRef={docToPrint} filename="sensor.pdf" >
    {({toPdf}) => ( 
            <CustomButton
              classNames="btn btn-sizing-nav btn-export-graph btn-white-fpa"
              label={<CustomTranslation data="XXSPECIFY_MMI_GR_BTN_EXPO" />}
              onClick={toPdf}
              variant="outline-dark"
              size="lg"
            /> )}
            </ReactToPdf> */}
            <CustomButton
              classNames="btn btn-sizing-nav btn-export-graph btn-white-fpa"
              label={<CustomTranslation data="XXSPECIFY_MMI_GR_BTN_EXPO" />}
              onClick={toPdf}
              variant="outline-dark"
              size="lg"
            />
            <button
              onClick={() => configure("proceed")}
              className="btn btn-sizing-nav btn-sizing-nav-proceed btn-green btn btn-primary btn-lg"
              disabled={isDisabled() || load || props.proceed_load || isExcluded}
            >
              <CustomTranslation data={save_label} />
              {load ? <Spinner as="span" animation="border" size="sm" role="status" variant="outline-dark" aria-hidden="true" /> : ""}
            </button>
          </span>
        </Col>
      </Row>
    </div>
  );
};
const mapStateToProps = (state) => ({
  sizing_results_data: state.SizingResults.sizing_results_data,
  show_more_rates_result_data: state.SizingResults.show_more_rates_result_data,
  composition_method: state.FluidSelection.composition_method,
  sizing_service: state.SizingResults.sizing_service,
  process_line_size: state.ApplicationRequirements.process_line_size,
  application: state.SizingResults.application,
  compare_sizing_results: state.SizingResults.compare_sizing_results,
  min_flow_rate: state.ProcessVariables.min_flow_rate,
  normal_flow_rate: state.ProcessVariables.normal_flow_rate,
  max_flow_rate: state.ProcessVariables.max_flow_rate,
  full_flow_rate: state.ProcessVariables.full_flow_rate,
  min_line_pressure: state.ProcessVariables.min_line_pressure,
  normal_line_pressure: state.ProcessVariables.normal_line_pressure,
  max_line_pressure: state.ProcessVariables.max_line_pressure,
  full_line_pressure: state.ProcessVariables.full_line_pressure,
  min_temperature: state.ProcessVariables.min_temperature,
  normal_temperature: state.ProcessVariables.normal_temperature,
  max_temperature: state.ProcessVariables.max_temperature,
  full_temperature: state.ProcessVariables.full_temperature,
  min_ambient: state.ProcessVariables.min_ambient,
  normal_ambient: state.ProcessVariables.normal_ambient,
  max_ambient: state.ProcessVariables.max_ambient,
  full_ambient: state.ProcessVariables.full_ambient,
  units_viscosity: state.ProcessVariables.units_viscosity,
  units_line_pressure: state.ProcessVariables.units_line_pressure,
  units_density: state.ProcessVariables.units_density,
  units_flow_rate: state.ProcessVariables.units_flow_rate,
  units_temperature: state.ProcessVariables.units_temperature,
  units_ambient: state.ProcessVariables.units_ambient,
  compare_model_names: state.SizingResults.compare_model_names,
  select_technology: state.MeasurementType.select_technology,
  normal_viscosity: state.ProcessVariables.normal_viscosity,
  normal_density: state.ProcessVariables.normal_density,
  fluid_state: state.FluidSelection.fluid_state,
  show_more_rates_data: state.SizingResults.show_more_rates_data,
  units_gas_density: state.ProcessVariables.units_gas_density,
  units_gas_density_sg: state.ProcessVariables.units_gas_density_sg,
  gas_density: state.ProcessVariables.gas_density,
  min_density: state.ProcessVariables.min_density,
  max_density: state.ProcessVariables.max_density,
  min_viscosity: state.ProcessVariables.min_viscosity,
  max_viscosity: state.ProcessVariables.max_viscosity,
  units_vapor: state.ProcessVariables.units_vapor,
  units_sonic_velocity: state.ProcessVariables.units_sonic_velocity,
  sonic_velocity: state.ProcessVariables.sonic_velocity,
  pressure_atmospheric: state.ProcessVariables.pressure_atmospheric,
  max_flow_acc: state.ProcessVariables.max_flow_acc,
  max_pressure_drop: state.ProcessVariables.max_pressure_drop,
  k_flow_consistency_index: state.ProcessVariables.k_flow_consistency_index,
  n_flow_behavior: state.ProcessVariables.n_flow_behavior,
  gas_pressure: state.ProcessVariables.gas_pressure,
  units_gas_temperature: state.ProcessVariables.units_gas_temperature,
  gas_temperature: state.ProcessVariables.gas_temperature,
  base_reference_conditions: state.ProcessVariables.base_reference_conditions,
  units_gas_pressure: state.ProcessVariables.units_gas_pressure,
  min_vapor: state.ProcessVariables.min_vapor,
  equipment_selection: state.EquipmentSelection.equipment_selection,
  flow: state.MeasurementType.flow,
  density: state.MeasurementType.density,
  viscosity: state.MeasurementType.viscosity,
  reducer: state.ApplicationRequirements.reducer,
  dual: state.ApplicationRequirements.dual,
  quad: state.ApplicationRequirements.quad,
  tank: state.ApplicationRequirements.tank,
  report_configs: state.ApplicationRequirements.report_configs,
  flanged: state.ApplicationRequirements.flanged,
  wafer: state.ApplicationRequirements.wafer,
  hygienic: state.ApplicationRequirements.hygienic,
  pressure: state.ApplicationRequirements.pressure,
  display_all_sensors_no_filters: state.ApplicationRequirements.display_all_sensors_no_filters,
  conductivity: state.FluidSelection.conductivity,
  hygienic_application: state.ApplicationRequirements.hygienic_application,
  high_process_noise_slurry: state.ApplicationRequirements.high_process_noise_slurry,
  in_line: state.ApplicationRequirements.in_line,
  flow_through_chamber_slipstream: state.ApplicationRequirements.flow_through_chamber_slipstream,
  product_family: state.ApplicationRequirements.product_family,
  fluid_source: state.FluidSelection.fluid_source,
  fluid_service: state.FluidSelection.fluid_service,
  pick_fluids_from_db: state.FluidSelection.pick_fluids_from_db,
  calculate_density_variation: state.ProcessVariables.calculate_density_variation,
  natural_gas_composition_name: state.FluidSelection.natural_gas_composition_name,
  relative_density: state.FluidSelection.relative_density,
  mole_percent_CO2: state.FluidSelection.mole_percent_CO2,
  heating_value: state.FluidSelection.heating_value,
  mole_percent_H2: state.FluidSelection.mole_percent_H2,
  mole_percent_CO: state.FluidSelection.mole_percent_CO,
  heating_value_dropdown: state.FluidSelection.heating_value_dropdown,
  mole_percent_N2: state.FluidSelection.mole_percent_N2,
  selected_components: state.FluidSelection.selected_components,
  units_density_heading: state.ProcessVariables.units_density_heading,
  compressibility: state.ProcessVariables.compressibility,
  inline: state.ApplicationRequirements.in_line,
  specific_gravity: state.ProcessVariables.specific_gravity,
  molecular_weight: state.ProcessVariables.molecular_weight,
  units_pressure_atmospheric: state.ProcessVariables.units_pressure_atmospheric,
  search_parameters: state.SizingResults.search_parameters,
  selected_row_data: state.SizingResults.selected_row_data,
  aplication_name_error: state.SizingResultErrorMessage.aplication_name_error,
  report_flag: state.SizingResultErrorMessage.report_flag,
  flow_rate_decimal: state.Preferences.flow_rate_decimal,
  temperature_decimal: state.Preferences.temperature_decimal,
  line_pressure_decimal: state.Preferences.line_pressure_decimal,
  all_other_decimal: state.Preferences.all_other_decimal,
  user_language: state.AppHeader.user_language,
  query_params: state.Preferences.query_params,
  line_size: state.ApplicationRequirements.line_size,
  translation_data: state.TranslationData.translation_data,
  email: state.AppHeader.email,
  pick_gas_from_db: state.FluidSelection.pick_gas_from_db,
  project_name: state.SizingResults.project_name,
  get_sizing_data: state.MySizings.get_sizing_data,
  schedule_value: state.ApplicationRequirements.schedule_value,
  density_specific_gravity: state.ProcessVariables.density_sg,
  units_gas_flow_rate: state.ProcessVariables.units_gas_flow_rate,
  custom_fluid_name: state.FluidSelection.custom_fluid_name,
  flow_rate_decimal: state.Preferences.flow_rate_decimal,
  temperature_decimal: state.Preferences.temperature_decimal,
  line_pressure_decimal: state.Preferences.line_pressure_decimal,
  all_other_decimal: state.Preferences.all_other_decimal,
  search_parameters: state.SizingResults.search_parameters,
  units_data: state.DropdownData.units_data,
  steam_pressure_temp: state.FluidSelection.steam_pressure_temp,
  wetted_material_label: state.ApplicationRequirements.wetted_material_label,
  proceed_load: state.SizingResults.proceed_load,
  flow_accuracy: state.SizingResults.flow_accuracy,
  flow_accuracy_value: state.SizingResults.flow_accuracy_value,
  density_accuracy: state.SizingResults.density_accuracy,
  natural_gas_compose: state.FluidSelection.natural_gas_compose,
  natural_gas_composition_name: state.FluidSelection.natural_gas_composition_name,
  sis_application: state.ApplicationRequirements.sis_application,
  config_warning_msg: state.SizingResults.config_warning_msg,
  high_temperature_warning_msg: state.SizingResults.high_temperature_warning_msg,
  high_temperature_error_msg: state.SizingResults.high_temperature_error_msg,
  sonic_velocity_warning_msg:state.SizingResults.sonic_velocity_warning_msg,
  sonic_vel_entered:state.SizingResults.sonic_vel_entered,
  amb_temp_warning_msg:state.SizingResults.amb_temp_warning_msg
});
export default connect(mapStateToProps, { selectedRowData, ApplicationNameError, queryParams, reportFlag, proceedLoad })(ViewGraph);