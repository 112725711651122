import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomToggleSwitch from '../../components/CustomToggleSwitch/CustomToggleSwitch';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';
import { connect } from 'react-redux';
import CustomTranslationText from '../../components/CustomTranslation/CustomTranslationText';
import {
  resetApp,
  requestSizingResults,
  displaySizingResults,
  advancedLayout,
  translationData,
  clearMySizings,
  clearPrefVariables,
  clearUom,
  clearAllField,
  clearAppRequirements,
  clearMeasType,
  clearEqSelection,
  clearFluidSection,
  clearProcVariables,
  clearProcVariablesValues,
  getSizingData,
  fluidState,
  fluidSource,
  pickFluidsFromDB,
  customFluidName,
  fluidSelectionFlag,  
  sizingResultsClicked,
  toggleFlow,
  naturalGasCompose,
} from '../../redux/actions';
import GreenNavBar from '../AdvancedPage/GreenNavBar';
import US_DATA from '../../translation_US_Data.json';
import urls from '../../UtilityFuntions/urls';


const Header = props => {
  let history = useHistory();
  const headerCss =
    history && history.location && history.location.pathname === '/preferences'
      ? 'header'
      : 'header-sticky';

  const toggle_id = props.section === "advanced" ? "XXSPECIFY_MMI_MC_LBL_ADVANCE" : "XXSPECIFY_MMI_MC_LBL_BASIC";
  const toggleSwitch = () => {
    if (
      history.location.pathname === '/advanced' ||
      history.location.pathname === '/basic'
    ) {

      const toggle_text = props.translation_data.length !== 0
        ? props.translation_data
          .filter((name) => name.message_name === toggle_id)
          .map((data) => data.message_text).length !== 0
          ? props.translation_data
            .filter((name) => name.message_name === toggle_id)
            .map((data) => data.message_text)
          : US_DATA.filter((name) => name.message_name === toggle_id).map(
            (data) => data.message_text
          )
        : US_DATA.filter((name) => name.message_name === toggle_id).map(
          (data) => data.message_text
        );
      return ''
        // <CustomToggleSwitch
        //   checked={props.advanced_layout}
        //   name='toggle-layout'
        //   id='basic-advanced-toggle'
        //   className='custom-toggle'
        //   onChange={e => toggleBasicAdvanced(e.target.checked)}
        //   Basic={toggle_text}
        // />
    
    }
  };

  const toggleBasicAdvanced = checked => {
    if (checked) {
      props.advancedLayout(false);
      history.push('/basic');
    } else {
      props.advancedLayout(true);
      history.push('/advanced');
    }
  };

  const backToSizing = async() => {
    await props.fluidSelectionFlag(false)
    await history.push('/advanced');
    await props.fluidSelectionFlag(true)
    props.sizingResultsClicked(false)
    //await props.clearProcVariablesValues();
  };

  const closeTab = () => {
    // TBD
     if(props.query_params.from_page==='BTAB'){
      let btabRedirect = `${urls.btabUrl}getsizedetails?sizeid=${props.query_params.pa_ref_id}&org=MMI&redirecturl=${props.query_params.redirect_url}&email=${props.query_params.email}`
      window.location.href = btabRedirect
    }
    else if(props.query_params.from_page==='PRJWSC'){
      let prjswRedirect = `${urls.prjswUrl}commonproductadvisordisplayview/result?pa_ref_id=${props.query_params.pa_ref_id}`
      window.location.href = prjswRedirect
    }else{
    window.history.back()
    }
  };

  const clearSelections = async() => {
    await props.clearAllField(true); 
    if (history.location.pathname === '/my_sizings') {
      props.clearMySizings(true);
    } else if (history.location.pathname === '/uom_conversion') {
      props.clearUom(true);
    } else {
      await props.requestSizingResults(false);
      await props.displaySizingResults(false);
      // props.resetApp(true);
      await props.clearAppRequirements();
          await props.getSizingData([]);
      await props.clearMeasType();
      await props.clearEqSelection();
      await props.clearProcVariables();
      //await props.clearFluidSection();
      await props.toggleFlow(false);
     
      await props.fluidState("Liquid");
      await props.fluidSource("Database");
      await props.pickFluidsFromDB("Water");
      props.customFluidName('');
      props.naturalGasCompose(false);
      await document.getElementById("sizing_input").click();
    }
    await props.clearProcVariables()
    if(props.email ===''){
      props.clearPrefVariables()
    }
    await props.clearAllField(false)
    
  };

  const headerNavButtons = () => {
    return (
      <>
        <div className='button-nav'>
          {(history.location.pathname !== '/advanced' &&
            history.location.pathname !== '/basic' &&
            history.location.pathname !== '/uom_conversion' &&
            history.location.pathname !== '/my_sizings' &&
            history.location.pathname !== '/help') || (
              <CustomButton
                classNames='btn btn-nav btn-arrow btn-white-fpa'
                label={<CustomTranslation data='XXSPECIFY_MMI_MC_BTN_CLEAR' />}
                onClick={() => clearSelections()}
                variant='outline-dark'
                arrow='right'
                size='lg'
              />
            )}
          {/* TODO: this will be different criteria than above */}
          {(history.location.pathname !== "/advanced" &&
            history.location.pathname !== "/basic") || (
              <>
                <CustomButton
                  classNames='btn btn-nav btn-arrow btn-white-fpa'
                  label={<CustomTranslation data='XXSPECIFY_MMI_SR_BTN_CLOSE' />}
                  onClick={() => closeTab()}
                  variant='outline-dark'
                  arrow='right'
                  size='lg'
                />
              </>
            )}
          {(history.location.pathname !== "/preferences" &&
            history.location.pathname !== "/uom_conversion" &&
            history.location.pathname !== "/my_sizings" &&
            history.location.pathname !== '/help'&&
            history.location.pathname !== '/report' 
            // history.location.pathname !== '/report-poc'
          ) || (
              <CustomButton
                classNames='btn btn-nav btn-arrow btn-white-fpa'
                label={CustomTranslationText('XXSPECIFY_MMI_SR_BTN_BACKTOSIZ',props).toUpperCase()}
                onClick={() => backToSizing()}
                variant='outline-dark'
                arrow='right'
                size='lg'
              />
            )}
        </div>
      </>
    );
  };

  return (
    <div className={headerCss}>
      <Container fluid>
      <Row className='sizing-and-selection-header'>
        <Col xl={5} md={12}>
          <h1 className='page-heading'>
            <CustomTranslation data='XXSPECIFY_MMI_UC_LINK_HEAD2' />
          </h1>
          <p className='page-description'>
            <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_GDN' />
          </p>
        </Col>
        <Col xl={7} md={12}>
          {history.location.pathname !== '/FlowLogin' ? (
            <div className='navigation'>
              {/* {props.email!==''?props.email.search(new RegExp('Emerson', "i"))!== -1?
               <><Link to={"/report-poc"}>
                <CustomTranslation data='XXSPECIFY_MMI_LBL_REPORTS_POC' />
             </Link>&nbsp;
              |&nbsp;</>:'':''}&nbsp; */}
              {props.email!==''?props.email.search(new RegExp('Emerson', "i"))!== -1?
               <><Link to={"/report"}>
                <CustomTranslation data='XXSPECIFY_MMI_LBL_REPORTS' />
              </Link>&nbsp;
              |&nbsp;</>:'':''}&nbsp;
              
              <Link to={"/preferences"}>
                <CustomTranslation data='XXSPECIFY_MMI_MC_LINK_PREFER' />
              </Link>&nbsp;
              |&nbsp;
              <Link to={"/my_sizings"}>
                <CustomTranslation data='XXSPECIFY_MMI_SS_TBL_TAB' />&nbsp;
              </Link>
              |&nbsp;
              <Link to={"/help"} id='help-id'>
                <CustomTranslation data='XXSPECIFY_MMI_MC_LINK_HELP' />
              </Link>
              &nbsp;
              {toggleSwitch()}
              {headerNavButtons()}
            </div>
          ) : (
            ''
          )}
        </Col>
      </Row>
      <Row>
        {history.location.pathname === '/advanced' ? <GreenNavBar forwardedRef={props.forwardedRef} onClickSizingResults={props.onClickSizingResults} /> : ''}
      </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = state => ({
  advanced_layout: state.Layout.advanced_layout,
  translation_data: state.TranslationData.translation_data,
  email:state.AppHeader.email,
  query_params: state.Preferences.query_params,
});

export default connect(mapStateToProps, {
  resetApp,
  requestSizingResults,
  displaySizingResults,
  advancedLayout,
  clearMySizings,
  clearPrefVariables,
  translationData,
  clearUom,
  clearAllField,
  clearAppRequirements,
  clearMeasType,
  clearEqSelection,
  clearFluidSection,
  getSizingData,
  clearProcVariables,
  clearProcVariablesValues,
  fluidState,
  fluidSource,
  pickFluidsFromDB,
  customFluidName, 
  fluidSelectionFlag,
  sizingResultsClicked,
  toggleFlow,
  naturalGasCompose,
})(Header);


