import React, { useEffect, useState } from 'react';
import {
  Col,
  Container,
  Row
} from 'react-bootstrap';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import { CustomDropdownNavPages } from '../../components/CustomDropdown/CustomDropdownNavPages';
import { CustomInputFieldNavPages } from '../../components/CustomInputField/CustomInputFieldNavPages';
import { connect } from 'react-redux';
import axios from 'axios';
import { UOM_CONVERSION } from '../../constants/constants';
import { decimalPlaceNumeric } from '../../UtilityFuntions/decimalFactor';
import { clearUom, errorMessage } from '../../redux/actions';
import ErrorModal from '../../components/CustomModals/ErrorModal';


const UomConversion = props => {
  const [flowRateValueTo, setFlowRateValueTo] = useState('');
  const [flowRateValueFrom, setFlowRateValueFrom] = useState('');
  const [flowRateDropdownTo, setFlowRateDropdownTo] = useState({ key: 'USGPH', value: 'Gallons(US)/min' });
  const [flowRateDropdownFrom, setFlowRateDropdownFrom] = useState({ key: 'USGPH', value: 'Gallons(US)/min' });
  const [flowRateGasValueTo, setFlowRateGasValueTo] = useState('');
  const [flowRateGasValueFrom, setFlowRateGasValueFrom] = useState('');
  const [flowRateGasDropdownTo, setFlowRateGasDropdownTo] = useState({ key: 'SCFH', value: 'SCF/hr' });
  const [flowRateGasDropdownFrom, setFlowRateGasDropdownFrom] = useState({ key: 'SCFH', value: 'SCF/hr' });
  const [linePressureValueTo, setLinePressureValueTo] = useState('');
  const [linePressureValueFrom, setLinePressureValueFrom] = useState('');
  const [linePressureDropdownTo, setLinePressureDropdownTo] = useState({ key: 'PSIG', value: 'psig' });
  const [linePressureDropdownFrom, setLinePressureDropdownFrom] = useState({ key: 'PSIG', value: 'psig' });
  const [temperatureValueTo, setTemperatureValueTo] = useState('');
  const [temperatureValueFrom, setTemperatureValueFrom] = useState('');
  const [temperatureDropdownTo, setTemperatureDropdownTo] = useState({ key: 'F', value: 'F' });
  const [temperatureDropdownFrom, setTemperatureDropdownFrom] = useState({ key: 'F', value: 'F' });
  const [densityValueTo, setDensityValueTo] = useState('');
  const [densityValueFrom, setDensityValueFrom] = useState('');
  const [densityDropdownTo, setDensityDropdownTo] = useState({ key: 'LB/FT3', value: 'lb/ft3' });
  const [densityDropdownFrom, setDensityDropdownFrom] = useState({ key: 'LB/FT3', value: 'lb/ft3' });
  const [viscosityValueTo, setViscosityValueTo] = useState('');
  const [viscosityValueFrom, setViscosityValueFrom] = useState('');
  const [viscosityDropdownTo, setViscosityDropdownTo] = useState({ key: 'CP', value: 'cP' });
  const [viscosityDropdownFrom, setViscosityDropdownFrom] = useState({ key: 'CP', value: 'cP' });
  const { clear_uom, clearUom } = props;

  // TODO: current bug that when key should be 0, it is undefined
  const handleFlowRateValueFrom = key => setFlowRateValueFrom(key);
  const handleFlowRateDropdownTo = key => setFlowRateDropdownTo(props.units_data.flowRateLiquidSlurry[key ? key : 0]);
  const handleFlowRateDropdownFrom = key => setFlowRateDropdownFrom(props.units_data.flowRateLiquidSlurry[key ? key : 0]);
  const handleFlowRateGasValueFrom = key => setFlowRateGasValueFrom(key);
  const handleFlowRateGasDropdownTo = key => setFlowRateGasDropdownTo(props.units_data.flowRateGas[key ? key : 0]);
  const handleFlowRateGasDropdownFrom = key => setFlowRateGasDropdownFrom(props.units_data.flowRateGas[key ? key : 0]);
  const handleLinePressureValueFrom = key => setLinePressureValueFrom(key);
  const handleLinePressureDropdownTo = key => setLinePressureDropdownTo(props.units_data.nonVaporPressure[key ? key : 0]);
  const handleLinePressureDropdownFrom = key => setLinePressureDropdownFrom(props.units_data.nonVaporPressure[key ? key : 0]);
  const handleTemperatureValueFrom = key => setTemperatureValueFrom(key);
  const handleTemperatureDropdownTo = key => setTemperatureDropdownTo(props.units_data.temperature[key ? key : 0]);
  const handleTemperatureDropdownFrom = key => setTemperatureDropdownFrom(props.units_data.temperature[key ? key : 0]);
  const handleDensityValueFrom = key => setDensityValueFrom(key);
  const handleDensityDropdownTo = key => setDensityDropdownTo(props.units_data.density[key ? key : 0]);
  const handleDensityDropdownFrom = key => setDensityDropdownFrom(props.units_data.density[key ? key : 0]);
  const handleViscosityValueFrom = key => setViscosityValueFrom(key);
  const handleViscosityDropdownTo = key => setViscosityDropdownTo(props.units_data.viscosity[key ? key : 0]);
  const handleViscosityDropdownFrom = key => setViscosityDropdownFrom(props.units_data.viscosity[key ? key : 0]);

  const handleErrorClose = () => {
    props.errorMessage(false);
    // window.location.reload(false);
  }
  const handleErrorShow = () => props.error_message;

  const setInitialValues = () => {
    setFlowRateValueTo('');
    setFlowRateValueFrom('');
    setFlowRateDropdownTo({ key: 'USGPH', value: 'Gallons(US)/min' });
    setFlowRateDropdownFrom({ key: 'USGPH', value: 'Gallons(US)/min' });
    setFlowRateGasValueTo('');
    setFlowRateGasValueFrom('');
    setFlowRateGasDropdownTo({ key: 'SCFH', value: 'SCF/hr' });
    setFlowRateGasDropdownFrom({ key: 'SCFH', value: 'SCF/hr' });
    setLinePressureValueTo('');
    setLinePressureValueFrom('');
    setLinePressureDropdownTo({ key: 'PSIG', value: 'psig' });
    setLinePressureDropdownFrom({ key: 'PSIG', value: 'psig' });
    setTemperatureValueTo('');
    setTemperatureValueFrom('');
    setTemperatureDropdownTo({ key: 'F', value: 'F' });
    setTemperatureDropdownFrom({ key: 'F', value: 'F' });
    setDensityValueTo('');
    setDensityValueFrom('');
    setDensityDropdownTo({ key: 'LB/FT3', value: 'lb/ft3' });
    setDensityDropdownFrom({ key: 'LB/FT3', value: 'lb/ft3' });
    setViscosityValueTo('');
    setViscosityValueFrom('');
    setViscosityDropdownTo({ key: 'CP', value: 'cP' });
    setViscosityDropdownFrom({ key: 'CP', value: 'cP' });
  }

  useEffect(() => {
    if (clear_uom) {
      setInitialValues();
      clearUom(false);
    }
  }, [clear_uom, clearUom]);

  const decimalPlaces = props.saved_preferences
    ? decimalPlaceNumeric(props.saved_preferences.decimalfactor)
    : undefined;

  const convertValues = () => {
  
    const data = JSON.stringify({
      'frm_flow_rate': flowRateValueFrom,
      'frm_flow_rate_unit': flowRateDropdownFrom.key,
      'frm_flow_rate_gas': flowRateGasValueFrom,
      'frm_flow_rate_gas_unit': flowRateGasDropdownFrom.key,
      'frm_line_pressure': linePressureValueFrom,
      'frm_line_pressure_unit': linePressureDropdownFrom.key,
      'frm_temp': temperatureValueFrom,
      'frm_temp_unit': temperatureDropdownFrom.key,
      'frm_density': densityValueFrom,
      'frm_dens_unit': densityDropdownFrom.key,
      'frm_visc': viscosityValueFrom,
      'frm_visc_unit': viscosityDropdownFrom.key,
      'to_flow_rate_unit': flowRateDropdownTo.key,
      'to_flow_rate_gas_unit': flowRateGasDropdownTo.key,
      'to_line_pressure_unit': linePressureDropdownTo.key,
      'to_temp_unit': temperatureDropdownTo.key,
      'to_dens_unit': densityDropdownTo.key,
      'to_visc_unit': viscosityDropdownTo.key,
    });

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}${UOM_CONVERSION}`,
      headers: {
        'Content-Type': 'application/json',
      },
      xsrfHeaderName: 'X-CSRFToken',
      xsrfCookieName: 'csrftoken',
      data: data,
    })
      .then(function (response) {
        setDensityValueTo(
          response.data.data.density ? response.data.data.density.toFixed(decimalPlaces) : ''
        );
        setFlowRateValueTo(
          response.data.data.flow_rate ? response.data.data.flow_rate.toFixed(decimalPlaces) : ''
        );
        setFlowRateGasValueTo(
          response.data.data.flow_rate_gas ? response.data.data.flow_rate_gas.toFixed(decimalPlaces) : ''
        );
        setLinePressureValueTo(
          response.data.data.line_pressure ? response.data.data.line_pressure.toFixed(decimalPlaces) : ''
        );
        setTemperatureValueTo(
          response.data.data.temperature ? response.data.data.temperature.toFixed(decimalPlaces) : ''
        );
        setViscosityValueTo(
          response.data.data.viscosity ? response.data.data.viscosity.toFixed(decimalPlaces) : ''
        );
      })
      .catch(function (error) {
        console.log('error', error);
      });
  }

  const dropdownLabel = (id, label) => {
    return (
      <Col md={4} sm={12}>
        <label htmlFor={id} className="uom-label">{label}</label>
      </Col>
    )
  }

  const textField = (id, onChange, value, disabled) => {
    return (
      <Col md={3} sm={12}>
        <CustomInputFieldNavPages
          name="uom-input-form-control"
          id={id}
          onChange={onChange}
          value={value}
          disabled={disabled}
          borderOutlined={!disabled}
        />
      </Col>
    )
  }

  return (
    <>
      <ErrorModal show={props.error_message} handleClose={handleErrorClose} handleShow={handleErrorShow} />
      <Container className="uom-conversion">
        <h4 className="title">Unit of Measure Converter (UOM)</h4>
        <div className="upload-page">
          <div className="uom-panel">
            <Row>
              <Col md={5}>
                <h6 className="column-heading">FROM</h6>
                <Row className="uom-row">
                  {dropdownLabel('uom-flow-rate-from', 'FLOW RATE')}
                  {textField('uom-flow-rate-from', e => handleFlowRateValueFrom(e.target.value), flowRateValueFrom)}
                  <UnitsDropdown
                    id={'uom-flow-rate-from'}
                    name={'uom-dropdown'}
                    dropdownArray={props.units_data.flowRateLiquidSlurry}
                    eventHandler={handleFlowRateDropdownFrom}
                    chosenOption={flowRateDropdownFrom}
                  />
                </Row>
                <Row className="uom-row">
                  {dropdownLabel('uom-flow-rate-gas-from', 'FLOW RATE GAS')}
                  {textField('uom-flow-rate-gas-from', e => handleFlowRateGasValueFrom(e.target.value), flowRateGasValueFrom)}
                  <UnitsDropdown
                    id={'uom-flow-rate-gas-from'}
                    name={'uom-dropdown'}
                    dropdownArray={props.units_data.flowRateGas}
                    eventHandler={handleFlowRateGasDropdownFrom}
                    chosenOption={flowRateGasDropdownFrom}
                  />
                </Row>
                <Row className="uom-row">
                  {dropdownLabel('uom-line-pressure-from', 'LINE PRESSURE')}
                  {textField('uom-line-pressure-from', e => handleLinePressureValueFrom(e.target.value), linePressureValueFrom)}
                  <UnitsDropdown
                    id={'uom-line-pressure-from'}
                    name={'uom-dropdown'}
                    dropdownArray={props.units_data.nonVaporPressure}
                    eventHandler={handleLinePressureDropdownFrom}
                    chosenOption={linePressureDropdownFrom}
                  />
                </Row>
                <Row className="uom-row">
                  {dropdownLabel('uom-temperature-from', 'TEMPERATURE')}
                  {textField('uom-temperature-from', e => handleTemperatureValueFrom(e.target.value), temperatureValueFrom)}
                  <UnitsDropdown
                    id={'uom-temperature-from'}
                    name={'uom-dropdown'}
                    dropdownArray={props.units_data.temperature}
                    eventHandler={handleTemperatureDropdownFrom}
                    chosenOption={temperatureDropdownFrom}
                  />
                </Row>
                <Row className="uom-row">
                  {dropdownLabel('uom-density-from', 'DENSITY')}
                  {textField('uom-density-from', e => handleDensityValueFrom(e.target.value), densityValueFrom)}
                  <UnitsDropdown
                    id={'uom-density-from'}
                    name={'uom-dropdown'}
                    dropdownArray={props.units_data.density}
                    eventHandler={handleDensityDropdownFrom}
                    chosenOption={densityDropdownFrom}
                  />
                </Row>
                <Row className="uom-row">
                  {dropdownLabel('uom-viscosity-from', 'VISCOSITY')}
                  {textField('uom-viscosity-from', e => handleViscosityValueFrom(e.target.value), viscosityValueFrom)}
                  <UnitsDropdown
                    id={'uom-viscosity-from'}
                    name={'uom-dropdown'}
                    dropdownArray={props.units_data.viscosity}
                    eventHandler={handleViscosityDropdownFrom}
                    chosenOption={viscosityDropdownFrom}
                  />
                </Row>
              </Col>
              <Col md={2}>
                <div className="uom-button-section">
                  <CustomButton
                    classNames="btn btn-converter btn-green"
                    label="CONVERT"
                    onClick={() => convertValues()}
                    size="lg"
                  />
                </div>
              </Col>
              <Col md={5}>
                <h6 className="column-heading">TO</h6>
                <Row className="uom-row">
                  {dropdownLabel('uom-flow-rate-to', 'FLOW RATE')}
                  {textField('uom-flow-rate-to', null, flowRateValueTo, true)}
                  <UnitsDropdown
                    id={'uom-flow-rate-to'}
                    name={'uom-dropdown'}
                    dropdownArray={props.units_data.flowRateLiquidSlurry}
                    eventHandler={handleFlowRateDropdownTo}
                    chosenOption={flowRateDropdownTo}
                  />
                </Row>
                <Row className="uom-row">
                  {dropdownLabel('uom-flow-rate-gas-to', 'FLOW RATE GAS')}
                  {textField('uom-flow-rate-gas-to', null, flowRateGasValueTo, true)}
                  <UnitsDropdown
                    id={'uom-flow-rate-gas-to'}
                    name={'uom-dropdown'}
                    dropdownArray={props.units_data.flowRateGas}
                    eventHandler={handleFlowRateGasDropdownTo}
                    chosenOption={flowRateGasDropdownTo}
                  />
                </Row>
                <Row className="uom-row">
                  {dropdownLabel('uom-line-pressure-to', 'LINE PRESSURE')}
                  {textField('uom-line-pressure-to', null, linePressureValueTo, true)}
                  <UnitsDropdown
                    id={'uom-line-pressure-to'}
                    name={'uom-dropdown'}
                    dropdownArray={props.units_data.nonVaporPressure}
                    eventHandler={handleLinePressureDropdownTo}
                    chosenOption={linePressureDropdownTo}
                  />
                </Row>
                <Row className="uom-row">
                  {dropdownLabel('uom-temperature-to', 'TEMPERATURE')}
                  {textField('uom-temperature-to', null, temperatureValueTo, true)}
                  <UnitsDropdown
                    id={'uom-temperature-to'}
                    name={'uom-dropdown'}
                    dropdownArray={props.units_data.temperature}
                    eventHandler={handleTemperatureDropdownTo}
                    chosenOption={temperatureDropdownTo}
                  />
                </Row>
                <Row className="uom-row">
                  {dropdownLabel('uom-density-to', 'DENSITY')}
                  {textField('uom-density-to', null, densityValueTo, true)}
                  <UnitsDropdown
                    id={'uom-density-to'}
                    name={'uom-dropdown'}
                    dropdownArray={props.units_data.density}
                    eventHandler={handleDensityDropdownTo}
                    chosenOption={densityDropdownTo}
                  />
                </Row>
                <Row className="uom-row">
                  {dropdownLabel('uom-viscosity-to', 'VISCOSITY')}
                  {textField('uom-viscosity-to', null, viscosityValueTo, true)}
                  <UnitsDropdown
                    id={'uom-viscosity-to'}
                    name={'uom-dropdown'}
                    dropdownArray={props.units_data.viscosity}
                    eventHandler={handleViscosityDropdownTo}
                    chosenOption={viscosityDropdownTo}
                  />
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
}

const UnitsDropdown = props => {
  return (
    <>
      <Col md={5} sm={12}>
        <CustomDropdownNavPages
          id={props.id}
          name={props.name}
          dropdownArray={props.dropdownArray}
          eventHandler={props.eventHandler}
          chosenOption={props.chosenOption}
        />
      </Col>
    </>
  )
};

const mapStateToProps = state => ({
  units_data: state.DropdownData.units_data,
  saved_preferences: state.Preferences.saved_preferences,
  clear_uom: state.ClearUom.clear_uom,
  error_message: state.ErrorMessage.error_message,
});

export default connect(mapStateToProps, { clearUom, errorMessage })(UomConversion);
