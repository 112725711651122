import React from 'react';
import {
  Container,
  Row
} from 'react-bootstrap';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import OperatingConditionsData from './OperatingConditionsData';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';

const ProcessVariables = () => {
  return (
    <>
      <div className="process-variables">
        <div className="title"><CustomTranslation data='XXSPECIFY_MMI_MC_PANEL4_PV' /></div>
        <Container className="selection-container">
          <form>
            <Row>
              <OperatingConditionsData />
            </Row>
          </form>
        </Container>
      </div>
    </>
  )
}

export default ProcessVariables;
