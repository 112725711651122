import React, {useEffect, useState} from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import jsonInputs from "../../JSONForTesting/jsonInputs";
import { CustomInputField } from "../../components/CustomInputField/CustomInputField";
import {
  molePercentCO2,
  heatingValue,
  relativeDensity,
  molePercentH2,
  molePercentCO,
  molePercentN2,
  heatingValueDropdown
} from "../../redux/actions";
import { CustomDropdown } from "../../components/CustomDropdown/CustomDropdown";
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';

const GasCompositionValues = props => {
  const { compositionErros, handleCompositionErrors } = props
  const [compErrors , setCompErrors] = useState(compositionErros)

useEffect(()=>{
    setCompErrors([...compositionErros])
  }, [compositionErros])

  const optionsHeatingValues
    = jsonInputs.data.find(x => x['heating_values'])['heating_values'].map(x => x.value);

  const handleRelativeDensityChange = event =>  {
    props.relativeDensity(event.target.value);
    updateErrorMsg(event.target.id, 'relative_density', event.target.value)
  }
  const handleMolePercentCO2Change = event =>  {
    props.molePercentCO2(event.target.value);
    updateErrorMsg(event.target.id, 'carbon_dioxide', event.target.value)
  }
  const handleMolePercentN2Change = event =>  {
    props.molePercentN2(event.target.value);
    updateErrorMsg(event.target.id, 'nitrogen', event.target.value)
  }
  const handleHeatingValueChange = event =>  {
    props.heatingValue(event.target.value);
    updateErrorMsg(event.target.id, 'heating_value', event.target.value)
  }
  const handleMolePercentH2Change = event =>  {
    props.molePercentH2(event.target.value);
    updateErrorMsg(event.target.id, 'hydrogen', event.target.value)
  }
  const handleMolePercentCOChange = event =>  {
    props.molePercentCO(event.target.value);
    updateErrorMsg(event.target.id,'carbon_monoxide', event.target.value)
  }
  const handleHeatingValueDropdownChange = (key) => {let changekey=key?key:0 ;props.heatingValueDropdown(optionsHeatingValues[changekey]) }



  useEffect(() => {
    if (Object.keys(props.get_sizing_data).length > 0) {
      let data = props.get_sizing_data[0];
      props.relativeDensity(data.relative_density);
      props.molePercentCO2(data.mole_carbon_dioxide);
      props.molePercentN2(data.mole_nitrogen);
      props.heatingValue(data.heating_value);
      props.molePercentH2(data.mole_hydrogen);
      props.molePercentCO(data.mole_carbon_monoxide);
      props.heatingValueDropdown(data.heating_value_uom)
    }
  }, [props.get_sizing_data]);

  const dropdownStyles = {
    maxWidth: '150px',
    marginTop: '35px'
  };

  const getErrorMsg = (id) => {
    let errMsg = ''
    compErrors.map(composition => {
      if(composition.id === id)
      errMsg = composition.errMsg
    })
    return errMsg
  }

  const updateErrorMsg = (id, name, val) => {
    let updatedCompErrors = compErrors
    const obj =  handleCompositionErrors(id, name, val)

    let compositionIndex =  updatedCompErrors.findIndex(comp => comp.id === id)
    if(compositionIndex === -1){
      updatedCompErrors.push(obj)
    }else{
      updatedCompErrors.map(comp => {
        if(comp.id === id){
          comp.errMsg = obj.errMsg
        }
      })
    }
     setCompErrors([...updatedCompErrors])
  }

  return (
    <>
    
      <Row>
        <Col sm={12}>
          <div className="composition-input">
            <CustomInputField
              name={<CustomTranslation data='XXSPECIFY_MMI_COM_REL_DEN' />}
              size="lg"
              disabled={false}
              borderRequired={true}
              id="relative-density-input"
              labelClassName="composition-input-label"
              fieldClassName="composition-input-fieldname"
              onChange={handleRelativeDensityChange}
              value={props.relative_density}
              postScript={<CustomTranslation data='XXSPECIFY_MMI_MC_LBL_SG60F14.7' />}   
              isError={getErrorMsg('relative-density-input') !== ''}
              errorText={getErrorMsg('relative-density-input')}
              borderError={getErrorMsg('relative-density-input') !== ''}           
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div className="composition-input">
            <CustomInputField
              name={<CustomTranslation data='XXSPECIFY_MMI_COM_MOLE_CO2' />}
              size="lg"
              disabled={false}
              borderRequired={true}
              id="mole-co2-input"
              labelClassName="composition-input-label"
              fieldClassName="composition-input-fieldname"
              onChange={handleMolePercentCO2Change}
              value={props.mole_percent_CO2}
              isError={getErrorMsg('mole-co2-input') !== ''}
              errorText={getErrorMsg('mole-co2-input')}
              borderError={getErrorMsg('mole-co2-input') !== ''}

            />
          </div>
        </Col>
      </Row>
      {props.composition_method==='AGA Report No.8 Gross Characterization Method 2'?"":
      <Row>
        <Col sm={3}>
          <div className="composition-input">
            <CustomInputField
              name={<CustomTranslation data='XXSPECIFY_MMI_COM_HEATING_VAL' />}
              size="lg"
              disabled={false}
              borderRequired={true}
              id="heating-value-input"
              labelClassName="composition-input-label"
              fieldClassName="composition-input-fieldname"
              onChange={handleHeatingValueChange}
              value={props.heating_value}
              isError={getErrorMsg('heating-value-input') !== ''}
              errorText={getErrorMsg('heating-value-input')}
              borderError={getErrorMsg('heating-value-input') !== ''}
              
            />
          </div>
        </Col>
        <Col sm={5} >
          <CustomDropdown
            id="heating-value-dropdown-id"
            text={props.heating_value_dropdown}
            dropdownArray={optionsHeatingValues}
            eventHandler={handleHeatingValueDropdownChange}
            disabled={false}
            borderRequired={true}
            borderError={false}
            styles={dropdownStyles}
            active=""
            postScript={<CustomTranslation data='XXSPECIFY_MMI_MC_LBL_SG60F14.7' />}
          />
        </Col>
      </Row>}
      {props.composition_method==='AGA Report No.8 Gross Characterization Method 2'?
      <Row>
        <Col sm={12} >
          <div className="composition-input">
            <CustomInputField
              name="Mole % Nitrogen (N2)"
              size="lg"
              disabled={false}
              borderRequired={true}
              id="mole-n2-input"
              labelClassName="composition-input-label"
              fieldClassName="composition-input-fieldname"
              onChange={handleMolePercentN2Change}
              value={props.mole_percent_N2}
              isError={getErrorMsg('mole-n2-input') !== ''}
              errorText={getErrorMsg('mole-n2-input')}
              borderError={getErrorMsg('mole-n2-input') !== ''}
            />
          </div>
        </Col>
      </Row>:''}
      <Row>
        <Col sm={12} >
          <div className="composition-input">
            <CustomInputField
              name={<CustomTranslation data='XXSPECIFY_MMI_COM_MOLE_H' />}
              size="lg"
              disabled={false}
              borderRequired={true}
              id="more-h2-input"
              labelClassName="composition-input-label"
              fieldClassName="composition-input-fieldname"
              onChange={handleMolePercentH2Change}
              value={props.mole_percent_H2}
              isError={getErrorMsg('more-h2-input') !== ''}
              errorText={getErrorMsg('more-h2-input')}
              borderError={getErrorMsg('more-h2-input') !== ''}
            />
          </div>
        </Col>
      </Row>
      {props.composition_method != "ISO 12213, Physical Properties Method (SGERG 88)" &&
      <Row>
        <Col sm={12} >
          <div className="composition-input">
            <CustomInputField
              name={<CustomTranslation data='XXSPECIFY_MMI_COM_MOLE_CO' />}
              size="lg"
              disabled={false}
              borderRequired={true}
              id="mole-co-input"
              labelClassName="composition-input-label"
              fieldClassName="composition-input-fieldname"
              onChange={handleMolePercentCOChange}
              value={props.mole_percent_CO}
              isError={getErrorMsg('mole-co-input') !== ''}
              errorText={getErrorMsg('mole-co-input')}
              borderError={getErrorMsg('mole-co-input') !== ''}
            />
          </div>
        </Col>
      </Row>
      }
     
    </>
  )
};

const mapStateToProps = state => ({
  mole_percent_N2:state.FluidSelection.mole_percent_N2,
  relative_density: state.FluidSelection.relative_density,
  mole_percent_CO2: state.FluidSelection.mole_percent_CO2,
  heating_value: state.FluidSelection.heating_value,
  mole_percent_H2: state.FluidSelection.mole_percent_H2,
  mole_percent_CO: state.FluidSelection.mole_percent_CO,
  heating_value_dropdown: state.FluidSelection.heating_value_dropdown,
  natural_gas_composition_name: state.FluidSelection.natural_gas_composition_name,
  composition_method: state.FluidSelection.composition_method,
  get_sizing_data: state.MySizings.get_sizing_data,
  translation_data: state.TranslationData.translation_data,
});

export default connect(
  mapStateToProps,
  {
    relativeDensity,
    molePercentCO2,
    heatingValue,
    molePercentH2,
    molePercentN2,
    molePercentCO,
    heatingValueDropdown
  }
)(GasCompositionValues);
