import {
    CLEAR_ALL_FIELD
} from '../actionTypes';

const initialState = {
    clear_all_field: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_ALL_FIELD: {
            return {
                ...state,
                clear_all_field: action.payload.id
            }
        }
        
        default: {
            return state;
        }
    }
}

