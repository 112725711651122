import React, { useEffect, useState } from 'react';
import SizingAndSelection from './SizingAndSelection/SizingAndSelectionPage/SizingAndSelection';
import Download from './SizingAndSelection/Reports/Download';
import './styles/App.scss';

import 'bootstrap/dist/css/bootstrap.min.css';
import AppHeader from './SizingAndSelection/Header/AppHeader';
import CustomTranslation from './components/CustomTranslation/CustomTranslation';
import { useIdleTimer } from 'react-idle-timer';
import ErrorModal from './components/CustomModals/ErrorModal';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import {
  BrowserRouter ,
  Switch,
  Route,
  useLocation,
  useHistory,
} from 'react-router-dom';
import NotFound from './NotFound';

const AppMode = window.email ? window.email : '';
const oktaAuth = new OktaAuth({
  issuer: `${process.env.REACT_APP_OKTA_ISSUER}/oauth2/default`,
  clientId: `${process.env.REACT_APP_OKTA_CLIENT_ID}`,
  redirectUri: `${process.env.REACT_APP_OKTA_CALLBACK_URI}`,
});
const App = () => {
  const [timeoutFlag, setTimeoutFlag] = useState(false);
  const [source_type, setSourceType] = useState('');
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  
console.log(params.get('Source_type'))
  const handleOnIdle = (event) => {
    setTimeoutFlag(true);
  };
  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };
  const history = useHistory();
  const handleOnActive = (event) => {
    //handle with on active
  };
  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await triggerLogin();
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      //setAuthRequiredModalOpen(true);
    }
  };
  //const history = useHistory();
  const handleOnAction = (event) => {
    //handle on Action
  };

  
  const handleErrorClose = () => {
    setTimeoutFlag(false);
    window.open('about:blank', '_self');
  };
  const handleErrorShow = () => timeoutFlag;
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  history.replace(
      toRelativeUrl(originalUri || '/', window.location.origin)
    );
  };
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1800000, //setting idle time to 30 minutes
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 1800000,
  });

  useEffect(() => {
    if (AppMode !== 'Offline') {
      localStorage.removeItem('AppLogin');
    }
    if(params.get('fromPage')==='oracle'){
      setSourceType(atob(params.get('Source_type')))
    }else{
      setSourceType(params.get('Source_type'))
    }
  }, []);
  return (
    <div className="App">
       <BrowserRouter>
     
        <Security
          oktaAuth={oktaAuth}
        >
           <ErrorModal
            message={<CustomTranslation data="XXSPECIFY_MMI_TIMEOUT_MSG" />}
            show={timeoutFlag}
            handleClose={handleErrorClose}
            handleShow={handleErrorShow}
          />
          <Switch>
         

          <Route
            path={`${process.env.PUBLIC_URL}/callback`}
            component={LoginCallback}
          />
          {source_type==='QUOTE'||source_type==='ORDER'?
          <SecureRoute
            exact
            path={process.env.PUBLIC_URL + '/'}
            component={SizingAndSelection}
          />
          :
            <Route exact path="/" component={SizingAndSelection} />}
            <Route
              exact
              path="/catalog/CommonProductAdvisorDisplayView"
              component={SizingAndSelection}
            />
             <Route
              exact
              path="/integrations/gos/CommonProductAdvisorDisplayView"
              component={SizingAndSelection}
            />
             <Route
              exact
              path="/CommonProductAdvisorDisplayView"
              component={SizingAndSelection}
            />
            <Route
              exact
              path="/reports/downloads"
              component={Download}
            />
            {/* <Route exact path="/callback" /> */}
            <Route path="*" component={NotFound} />
          </Switch>
        </Security>
      
      </BrowserRouter>
    </div>
  );
};

export default App;
