import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  requiredFields,
  setOperatingDataErrorMessages,
  flowRateNormClassList,
  flowRateMinClassList,
  flowRateMaxClassList,
  flowRateFullClassList,
  linePressMinClassList,
  linePressNormClassList,
  linePressMaxClassList,
  linePressFullClassList,
  temperatureMinClassList,
  temperatureNormClassList,
  temperatureMaxClassList,
  temperatureFullClassList,
  ambientTemperatureMinClassList,
  ambientTemperatureNormClassList,
  ambientTemperatureMaxClassList,
  ambientTemperatureFullClassList,
  flowRateMinErrors,
  flowRateNormErrors,
  flowRateMaxErrors,
  linePressMinErrors,
  linePressNormErrors,
  linePressMaxErrors,
  linePressFullErrors,
  temperatureMinErrors,
  temperatureNormErrors,
  temperatureMaxErrors,
  temperatureFullErrors,
  ambientTemperatureMinErrors,
  ambientTemperatureNormErrors,
  ambientTemperatureMaxErrors,  
} from '../../redux/actions';
import CustomTranslationText from '../../components/CustomTranslation/CustomTranslationText';
const Validation = (props) => {
  const [reqErrors, setReqErrors] = useState([]);
  const [allowApiUpdate, setAllowApiUpdate] = useState(false);
  const reqField = 'border-required oc-input';
  const defaultField = 'border-not-required oc-input';
  const disabledFeild='border-disabled';
  const errorField = 'border-error oc-input';
  const reqErrorField = 'border-required border-error oc-input'
  const defaultError = '';

  // local error object for storing API errors
  const apiErrorArray = useRef({
    line_pressure_min: '',
    line_pressure_norm: '',
    line_pressure_max: '',
    line_pressure_fsd: '',
    temperature_min: '',
    temperature_norm: '',
    temperature_max: ''
  });

  // clear api error array on clear
  useEffect(() => {
    clearApiArray();
    props.setOperatingDataErrorMessages([]);
  }, [props.clear_all_field])

  // sets required fields on change of technology
  useEffect(() => {
    if (props.select_technology.toLowerCase() === 'coriolis'  && props.calculate_density_variation=== false) {
      props.flowRateMinClassList(defaultField)
      props.flowRateNormClassList(reqField)
      props.flowRateMaxClassList(defaultField)
      props.flowRateFullClassList(defaultField)
      props.linePressMinClassList(defaultField)
      props.linePressNormClassList(reqField)
      props.linePressMaxClassList(defaultField)
      props.linePressFullClassList(defaultField)
      props.temperatureMinClassList(defaultField)
      props.temperatureNormClassList(reqField)
      props.temperatureMaxClassList(defaultField)
      props.temperatureFullClassList(defaultField)
      props.ambientTemperatureMinClassList(defaultField)
      props.ambientTemperatureNormClassList(reqField)
      props.ambientTemperatureMaxClassList(defaultField)
      props.ambientTemperatureFullClassList(defaultField)
    } else if (props.select_technology.toLowerCase() === 'coriolis' && props.calculate_density_variation) {
      props.flowRateMinClassList(defaultField)
      props.flowRateNormClassList(reqField)
      props.flowRateMaxClassList(defaultField)
      props.flowRateFullClassList(defaultField)
      props.linePressMinClassList(reqField)
      props.linePressNormClassList(reqField)
      props.linePressMaxClassList(reqField)
      props.linePressFullClassList(defaultField)  
      props.temperatureMinClassList(reqField)
      props.temperatureNormClassList(reqField)
      props.temperatureMaxClassList(reqField)
      props.temperatureFullClassList(defaultField)
      props.ambientTemperatureMinClassList(defaultField)
      props.ambientTemperatureNormClassList(reqField)
      props.ambientTemperatureMaxClassList(defaultField)
      props.ambientTemperatureFullClassList(defaultField) 
    } 
    else if (props.select_technology.toLowerCase() === 'density' || props.select_technology.toLowerCase() === 'viscosity') {
      if(props.select_technology.toLowerCase()==='density' && props.fluid_state.toLowerCase()=== 'gas' || props.tank === true){
        props.flowRateMinClassList(defaultField)
        props.flowRateNormClassList(defaultField)
        props.flowRateMaxClassList(defaultField)
        props.flowRateFullClassList(defaultField) 
        props.linePressMinClassList(defaultField)
        props.linePressNormClassList(reqField)
        props.linePressMaxClassList(reqField)
        props.linePressFullClassList(defaultField)
        props.temperatureMinClassList(defaultField)
        props.temperatureNormClassList(reqField)
        props.temperatureMaxClassList(reqField)
        props.temperatureFullClassList(defaultField)
        props.ambientTemperatureMinClassList(defaultField)
        props.ambientTemperatureNormClassList(reqField)
        props.ambientTemperatureMaxClassList(reqField)
        props.ambientTemperatureFullClassList(defaultField) 
      }else{
        props.flowRateMinClassList(defaultField)
        props.flowRateNormClassList(reqField)
        props.flowRateMaxClassList(reqField)
        props.flowRateFullClassList(defaultField)
        props.linePressMinClassList(defaultField)
        props.linePressNormClassList(reqField)
        props.linePressMaxClassList(reqField)
        props.linePressFullClassList(defaultField)
        props.temperatureMinClassList(defaultField)
        props.temperatureNormClassList(reqField)
        props.temperatureMaxClassList(reqField)
        props.temperatureFullClassList(defaultField)
        props.ambientTemperatureMinClassList(defaultField)
        props.ambientTemperatureNormClassList(reqField)
        props.ambientTemperatureMaxClassList(reqField)
        props.ambientTemperatureFullClassList(defaultField)
      }
    
    } else if (props.select_technology.toLowerCase() === 'vortex'|| props.select_technology.toLowerCase() === 'magnetic') {
      props.flowRateMinClassList(reqField)
      props.flowRateNormClassList(defaultField)
      props.flowRateMaxClassList(reqField)
      props.flowRateFullClassList(defaultField)
      props.linePressMinClassList(defaultField)
      props.linePressMaxClassList(defaultField)
      props.linePressFullClassList(defaultField)
      props.temperatureMinClassList(defaultField)
      props.temperatureMaxClassList(defaultField)
      props.temperatureFullClassList(defaultField)
      props.ambientTemperatureMinClassList(defaultField)
      props.ambientTemperatureNormClassList(reqField)
      props.ambientTemperatureMaxClassList(defaultField)
      props.ambientTemperatureFullClassList(defaultField)
      if(props.steam_pressure_temp==='Based on Saturated Temperature'&&props.fluid_state.toLowerCase() === 'steam' &&
      props.select_technology.toLowerCase() === 'vortex'){
        props.linePressNormClassList(disabledFeild)
        props.temperatureNormClassList(reqField)
      }else if(props.steam_pressure_temp==='Based on Saturated Pressure'&&props.fluid_state.toLowerCase() === 'steam' &&
      props.select_technology.toLowerCase() === 'vortex'){
        props.temperatureNormClassList(disabledFeild)
        props.linePressNormClassList(reqField)
      }else{
        props.linePressNormClassList(reqField)
        props.temperatureNormClassList(reqField)
      }
    }  else {
      props.flowRateMinClassList(defaultField)
      props.flowRateNormClassList(defaultField)
      props.flowRateMaxClassList(defaultField)
      props.flowRateFullClassList(defaultField)
      props.linePressMinClassList(defaultField)
      props.linePressNormClassList(defaultField)
      props.linePressMaxClassList(defaultField)
      props.linePressFullClassList(defaultField)  
      props.temperatureMinClassList(defaultField)
      props.temperatureNormClassList(defaultField)
      props.temperatureMaxClassList(defaultField)
      props.temperatureFullClassList(defaultField)
      props.ambientTemperatureMinClassList(defaultField)
      props.ambientTemperatureNormClassList(defaultField)
      props.ambientTemperatureMaxClassList(defaultField)
      props.ambientTemperatureFullClassList(defaultField) 
    }

  }, [props.select_technology, props.calculate_density_variation,props.steam_pressure_temp,props.fluid_state, props.tank])


  
  // when table data changes, this will search for fields with 'border-required' and add them to the required fields redux prop
  useEffect(() => {
    let data = [...props.tableData];
    let req = [];
    data.map((item, i) => {
      item.inputs.map((element, key) => {
        let id = element.id;
        let val = element.value;
        let classN = element.className;
        if (classN.includes('border-required')) {
          if (props.required_fields.indexOf(id) === -1) {
            req.push({ id: id, val: val })
          }
        }
      });
    });
    props.requiredFields(req)
  }, [props.tableData]);
  
  //Setting Error messages for validating min max errors on click sizing Results button
  useEffect(() => {
    const data = [...props.tableData];
    const errorMessageFilter = data
      .filter(
        (dd) =>
          dd.lable === 'XXSPECIFY_MMI_MC_LBL_FR' ||
          dd.lable === 'XXSPECIFY_MMI_MC_LBL_LPRES' ||
          dd.lable === 'XXSPECIFY_MMI_MC_LBL_TEMP' ||
          dd.lable === 'XXSPECIFY_MMI_MC_TXT_AMBTEM'
      )
      .map((item) => {
        return item.inputs.filter((c) => {
          return c.errorMessage !== '';
        });
      });
    let result = [
      ...errorMessageFilter[0],
      ...errorMessageFilter[1],
      ...errorMessageFilter[2],
      ...errorMessageFilter[3],
    ];

    let errors = [];
    if (result.length > 0) {
      result.map((c) => {
        if (!c.errorMessage[0].includes([CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props)])) {
          errors.push(c.errorMessage[0]);
        }
      });
      props.setOperatingDataErrorMessages(errors);
    } else {
      props.setOperatingDataErrorMessages([]);
    }
  }, [props.tableData]);

    useEffect(() => {
    let errorArray = [];
    props.required_fields.map((item, i) => {
      if (item.val === '') {
        errorArray.push(item.id);
      }
    });
    setReqErrors(errorArray);
  }, [props.required_fields])

  // Takes the errors array from the calc returns and adds it to the local api error object
  useEffect(() => {
    if (props.calculationResults.errors.length > 0 && props.select_technology !=='') {  
      let error;
      props.calculationResults.errors.map(el => {

        if (el.hasOwnProperty('line_pressure_min')) {
          error = processApiErrorMessage(el.line_pressure_min)
          apiErrorArray.current = {...apiErrorArray.current, line_pressure_min: error}
        }
        if (el.hasOwnProperty('line_pressure_norm') && el.line_pressure_norm  !== 'ERROR') {
          error = processApiErrorMessage(el.line_pressure_norm)
          apiErrorArray.current = {...apiErrorArray.current, line_pressure_norm: error}  
        }
        if ( el.line_pressure_norm  === 'ERROR') {
          apiErrorArray.current ={...apiErrorArray.current, line_pressure_norm: ''}
        }
        if (el.hasOwnProperty('line_pressure_max')) {
          error = processApiErrorMessage(el.line_pressure_max)
          apiErrorArray.current = {...apiErrorArray.current, line_pressure_max: error}
        }
        if(el.hasOwnProperty('line_pressure_fsd')){
          error = processApiErrorMessage(el.line_pressure_fsd)
          apiErrorArray.current = {...apiErrorArray.current, line_pressure_fsd: error}
         
        }
        if (el.hasOwnProperty('temperature_min')) {
          error = processApiErrorMessage(el.temperature_min)
          apiErrorArray.current = {...apiErrorArray.current, temperature_min: error}
        }
        if (el.hasOwnProperty('temperature_norm') && el.temperature_norm  !== 'ERROR') {
          error = processApiErrorMessage(el.temperature_norm)
          apiErrorArray.current = {...apiErrorArray.current, temperature_norm: error}
        }
        if ( el.temperature_norm  === 'ERROR') {
          apiErrorArray.current ={...apiErrorArray.current, temperature_norm: ''}
        }
        if (el.hasOwnProperty('temperature_max')) {
          error = processApiErrorMessage(el.temperature_max)
          apiErrorArray.current = {...apiErrorArray.current, temperature_max: error}
        }
        if(el.hasOwnProperty('temperature_fsd')){
          error = processApiErrorMessage(el.temperature_fsd)
          apiErrorArray.current = {...apiErrorArray.current, temperature_fsd: error}
        }
      })  
      setAllowApiUpdate(true)
    } else {
        // if the results array is empty we clear the local object
        clearApiArray()
    }
  }, [props.calculationResults,props.fluid_source]);

  // adds error messages and changes classes on the operating data
  useEffect(() => {

    if (allowApiUpdate) {  
          //errors must exist in both the local object and the returned array to be updated. Otherwise, they are cleared in the 

          // first if statement checks if procedural errors are present          
          // should possibly be rewritten as: if (!props.line_press_min_errors.includes('Example format: #.#')) to test specifically for min/max errors
         if (!props.line_press_min_errors.includes(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_HINTMSG0', props))) { 
           if (apiErrorArray.current.line_pressure_min.length > 0 && props.calculationResults.errors.some(item => item.hasOwnProperty('line_pressure_min'))) {
              props.linePressMinErrors(apiErrorArray.current.line_pressure_min );
            // if id is in required fields we set class List to required
            if (props.required_fields.find((a) => a.id === 'line-press-min')) {
                props.linePressMinClassList(reqErrorField);
              } else {
                props.linePressMinClassList(errorField);
              }
              
            } else { 
              apiErrorArray.current ={...apiErrorArray.current, line_pressure_min: ''}
              props.linePressMinErrors(defaultError);
              if (props.required_fields.find((a) => a.id === 'line-press-min')) {
                props.linePressMinClassList(reqField)
              } else {
                props.linePressMinClassList(defaultField)
              }
            }
          }

           if (!props.line_press_norm_errors.includes(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_HINTMSG0', props))) {
             if (apiErrorArray.current.line_pressure_norm.length > 0 && props.calculationResults.errors.some(item => item.hasOwnProperty('line_pressure_norm'))) {
              props.linePressNormErrors(apiErrorArray.current.line_pressure_norm);
              if (props.required_fields.find((a) => a.id === 'line-press-normal')) {
                props.linePressNormClassList(reqErrorField);
              } else {
                props.linePressNormClassList(errorField);
              }
            } else {
              apiErrorArray.current ={...apiErrorArray.current, line_pressure_norm: ''}
              props.linePressNormErrors(defaultError);             
              if (props.required_fields.find((a) => a.id === 'line-press-normal')) {
                props.linePressNormClassList(reqField);
              } else {
                if(props.steam_pressure_temp==='Based on Saturated Temperature'&&props.fluid_state.toLowerCase() === 'steam' &&
                  props.select_technology === 'Vortex'){
                             props.linePressNormClassList(disabledFeild)
                    }
                    else{
                      props.linePressNormClassList(defaultField)
                    }

              }
            }
          }else {
            if(props.steam_pressure_temp==='Based on Saturated Temperature'&&props.fluid_state.toLowerCase() === 'steam' &&
                  props.select_technology === 'Vortex'){
                             props.linePressNormClassList(disabledFeild)
                             props.linePressNormErrors(apiErrorArray.current.line_pressure_norm);
                    }
          }

           if (!props.line_press_max_errors.includes(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_HINTMSG0', props))) {
            if (apiErrorArray.current.line_pressure_max.length > 0 && props.calculationResults.errors.some(item => item.hasOwnProperty('line_pressure_max'))) {
            props.linePressMaxErrors(apiErrorArray.current.line_pressure_max );
            // if id is in required fields we set class List to required
            if (props.required_fields.find((a) => a.id === 'line-press-max')) {
              props.linePressMaxClassList(reqErrorField);
            } else {
              props.linePressMaxClassList(errorField);
            }
          } else  {
            apiErrorArray.current ={...apiErrorArray.current, line_pressure_max: ''}

            props.linePressMaxErrors(defaultError);
            if (props.required_fields.find((a) => a.id === 'line-press-max' )) {
              props.linePressMaxClassList(reqField);
            } else {
              props.linePressMaxClassList(defaultField);
            }
          }}

          if (!props.line_press_full_errors.includes(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_HINTMSG0', props))) {
            if (apiErrorArray.current.line_pressure_fsd.length > 0 && props.calculationResults.errors.some(item => item.hasOwnProperty('line_pressure_fsd'))) {
                props.linePressFullErrors(apiErrorArray.current.line_pressure_fsd );
                // if id is in required fields we set class List to required
                if (props.required_fields.find((a) => a.id === 'line-press-full')) {
                    props.linePressFullClassList(reqErrorField);
                } else {
                  props.linePressFullClassList(errorField);
                }
            } 
            else { 
              apiErrorArray.current ={...apiErrorArray.current, line_pressure_fsd: ''}
              props.linePressFullErrors(defaultError);
              if (props.required_fields.find((a) => a.id === 'line-press-full')) {
                props.linePressFullClassList(reqField)
              } else {
                props.linePressFullClassList(defaultField)
              }
            }

          }

           if (!props.temperature_min_errors.includes(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_HINTMSG0', props))) {
             if (apiErrorArray.current.temperature_min.length > 0 && props.calculationResults.errors.some(item => item.hasOwnProperty('temperature_min'))) {
              props.temperatureMinErrors(apiErrorArray.current.temperature_min);
              // if id is in required fields we set class List to required
              if (props.required_fields.find((a) => a.id === 'temp-min')) {
                props.temperatureMinClassList(reqErrorField);
              } else {
                props.temperatureMinClassList(errorField);
              }

              props.temperatureMinClassList(errorField);
            } else {
              apiErrorArray.current ={...apiErrorArray.current, temperature_min: ''}
              props.temperatureMinErrors(defaultError);
              if ((props.required_fields.find((a) => a.id === 'temp-min'))) {
                props.temperatureMinClassList(reqField);
              } else {
                props.temperatureMinClassList(defaultField);
              }
            }
          }

           if (!props.temperature_norm_errors.includes(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_HINTMSG0', props))) {
             if (apiErrorArray.current.temperature_norm.length > 0 && props.calculationResults.errors.some(item => item.hasOwnProperty('temperature_norm'))) {
              props.temperatureNormErrors(apiErrorArray.current.temperature_norm);
              if (props.required_fields.find((a) => a.id === 'line-press-normal')) {
                props.temperatureNormClassList(reqErrorField);
              } else {
                props.temperatureNormClassList(errorField);
              }
            } else {
              apiErrorArray.current ={...apiErrorArray.current, temperature_norm: ''}
              props.temperatureNormErrors(defaultError);
              if (props.required_fields.find((a) => a.id === 'temp-normal')) {
                props.temperatureNormClassList(reqField);
              } else {
                if(props.steam_pressure_temp==='Based on Saturated Pressure'&&props.fluid_state.toLowerCase() === 'steam' &&
                props.select_technology === 'Vortex'){
                  props.temperatureNormClassList(disabledFeild)
  
                }else{
                  props.temperatureNormClassList(defaultField);
                }
              }
            }
          }

           if (!props.temperature_max_errors.includes(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_HINTMSG0', props))) {
             if (apiErrorArray.current.temperature_max.length > 0  && props.calculationResults.errors.some(item => item.hasOwnProperty('temperature_max'))) {
            props.temperatureMaxErrors(apiErrorArray.current.temperature_max);
            // if id is in required fields we set class List to required
            if (props.required_fields.find((a) => a.id === 'temp-max')) {
              props.temperatureMaxClassList(reqErrorField);
            } else {
              props.temperatureMaxClassList(errorField);
            }
          } else  {
            apiErrorArray.current ={...apiErrorArray.current, line_pressure_min: ''}
            props.temperatureMaxErrors(defaultError);
            if (props.required_fields.find((a) => a.id === 'temp-max' )) {
              props.temperatureMaxClassList(reqField);
            } else {
              props.temperatureMaxClassList(defaultField);
            }
          }}
        
          if(!props.temperature_full_errors.includes(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_HINTMSG0', props))){
            if (apiErrorArray.current.temperature_fsd.length > 0 && props.calculationResults.errors.some(item => item.hasOwnProperty('temperature_fsd'))) {
              props.temperatureFullErrors(apiErrorArray.current.temperature_fsd );
              // if id is in required fields we set class List to required
              if (props.required_fields.find((a) => a.id === 'temp-full')) {
                  props.temperatureFullClassList(reqErrorField);
              } else {
                props.temperatureFullClassList(errorField);
              }
            } 
            else { 
            apiErrorArray.current ={...apiErrorArray.current, temperature_fsd: ''}
            props.temperatureFullErrors(defaultError);
            if (props.required_fields.find((a) => a.id === 'temp-full')) {
              props.temperatureFullClassList(reqField)
            } else {
              props.temperatureFullClassList(defaultField)
            }
            }
          }

          props.setApiErrors(false)
          setAllowApiUpdate(false)
        }
        
  }, [apiErrorArray.current, allowApiUpdate]);
  
  // resets the local api error object
  const clearApiArray = () => {
    apiErrorArray.current ={
      line_pressure_min: '',
      line_pressure_norm: '',
      line_pressure_max: '',
      line_pressure_fsd: '',
      temperature_min: '',
      temperature_norm: '',
      temperature_max: '',
      temperature_fsd: '',

    }
    setAllowApiUpdate(true)
  };
  
  const processApiErrorMessage = (arg) => {
    let finalArray = [];
    if (Array.isArray(arg)) {
      const initialArray = arg.splice(0, arg.length - 2);
      const arraySet = [initialArray.join(' ')];
      const lastArray = arg.splice(-2);
      finalArray = [...arraySet, ...lastArray];
    } else {
      finalArray = [arg];
    }
    arg = '';
    return finalArray;
  };
 
  
  return (null);
};

const mapStateToProps = (state) => ({
  fluid_source: state.FluidSelection.fluid_source,
  fluid_state: state.FluidSelection.fluid_state,
  select_technology: state.MeasurementType.select_technology,
  required_fields: state.ProcessVariables.required_fields,
  sizing_error_message: state.SizingResultErrorMessage.sizing_error_message,
  clear_all_field: state.ClearAllField.clear_all_field,
  steam_pressure_temp: state.FluidSelection.steam_pressure_temp,
  calculate_density_variation:
  state.ProcessVariables.calculate_density_variation,
  line_press_min_errors: state.ProcessVariables.line_press_min_errors,
  line_press_norm_errors: state.ProcessVariables.line_press_norm_errors,
  line_press_max_errors: state.ProcessVariables.line_press_max_errors,
  line_press_full_errors: state.ProcessVariables.line_press_full_errors,
  temperature_min_errors: state.ProcessVariables.temperature_min_errors,
  temperature_norm_errors: state.ProcessVariables.temperature_norm_errors,
  temperature_max_errors: state.ProcessVariables.temperature_max_errors,
  temperature_full_errors: state.ProcessVariables.temperature_full_errors,
  translation_data: state.TranslationData.translation_data,
  tank: state.ApplicationRequirements.tank,
});

export default connect(mapStateToProps, {
  requiredFields,
  setOperatingDataErrorMessages,
  flowRateNormClassList,
  flowRateMinClassList,
  flowRateMaxClassList,
  flowRateFullClassList,
  linePressMinClassList,
  linePressNormClassList,
  linePressMaxClassList,
  linePressFullClassList,
  temperatureMinClassList,
  temperatureNormClassList,
  temperatureMaxClassList,
  temperatureFullClassList,
  ambientTemperatureMinClassList,
  ambientTemperatureNormClassList,
  ambientTemperatureMaxClassList,
  ambientTemperatureFullClassList,
  flowRateMinErrors,
  flowRateNormErrors,
  flowRateMaxErrors,
  linePressMinErrors,
  linePressNormErrors,
  linePressMaxErrors,
  linePressFullErrors,
  temperatureMinErrors,
  temperatureNormErrors,
  temperatureMaxErrors,
  temperatureFullErrors,
  ambientTemperatureMinErrors,
  ambientTemperatureNormErrors,
  ambientTemperatureMaxErrors,
})(Validation);
 