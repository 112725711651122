import React from 'react';
import { Button, ToggleButtonGroup, ToggleButton, Spinner } from 'react-bootstrap';
import './button.scss';

export const CustomButton = props => {

  const {
    classNames,
    label,
    toggle,
    type,
    onChange,
    onClick,
    size,
    variant,
    arrow,
    disabled,
    styles,
    spinner
  } = props;
  const buttonArrow =
    arrow === 'right' ? 'right-arrow' : arrow === 'left' ? 'left-arrow' : '';

  if (!toggle) {
    return (
      <span className='customButton'>
        <Button
          className={classNames}
          size={size}
          variant={variant}
          disabled={disabled}
          style={styles}
          onClick={onClick}
        >
          {arrow === 'left-nav' ? (
            <>
              <span className="left-arrow-nav" />
              &nbsp;{label}
            </>
          ) : (
              <span>
                {label}&nbsp;
                <span className={buttonArrow} />
              </span>
            )}
            {spinner && 

              <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            }
        </Button>
      </span>
    );
  } else {
    return (
      <ToggleButtonGroup type={type} className="mb-2" onChange={onChange}>
        <ToggleButton
          className={classNames}
          size={size}
          value={'composeClicked'}
        >
          {label}
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }
};
