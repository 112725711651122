import React from 'react';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, ListGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  equipmentSelection,
  selectBasicPage,
  lineSize,
} from '../../redux/actions';
import RightNavListProcessVariablesLabels from './RightNavListProcessVariablesLabels';
import CustomTranslationText from '../../components/CustomTranslation/CustomTranslationText';
const requiredFields = [
  'normalFlowRate',
  'normalLinePressure',
  'normalTemperature',
  'normalAmbient',
];
const disabledFields = [
  'minDensity',
  'normalDensity',
  'maxDensity',
  'minViscosity',
  'normalViscosity',
  'maxViscosity',
  'minVapor',
];

class RightNavList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processVariablesValid: false,
      requiredFields,
      disabledFields,
    };
  }

  resetState = () => {
    this.setState({
      processVariablesValid: false,
      requiredFields,
      disabledFields,
    });
  };

  selectPanel = (page) => {
    this.props.selectBasicPage(page);
  };

  setProcessVariablesSectionValid = (value) => {
    if (!this.state.processVariablesValid && value) {
      this.setState({ processVariablesValid: true });
    } else if (this.state.processVariablesValid && !value) {
      this.setState({ processVariablesValid: false });
    }
  };

  selectedColor = (page) => {
    if (page === this.props.basic_page) {
      return { backgroundColor: '#dee6ea' };
    } else {
      return { backgroundColor: '#f0f0f0' };
    }
  };

  densityDropdownValid = () => {
    if (this.props.units_density_heading === 'Density') {
      if (this.state.requiredFields.includes('maxDensity')) {
        return (
          !isNaN(parseFloat(this.props.normal_density)) &&
          !isNaN(parseFloat(this.props.max_density))
        );
      } else {
        return !isNaN(parseFloat(this.props.normal_density));
      }
    } else if (this.props.units_density_heading === 'Specific Gravity') {
      return !isNaN(parseFloat(this.props.specific_gravity));
    } else if (this.props.units_density_heading === 'Molecular Weight') {
      return !isNaN(parseFloat(this.props.molecular_weight));
    }
  };

  setDensityDropdownHeading = (required, disabled) => {
    required = required.filter(
      (value) => !['specificGravity', 'molecularWeight'].includes(value)
    );
    required = required.filter((value) => !disabled.includes(value));
    if (this.props.units_density_heading === 'Density') {
      if (this.props.density && !this.props.flow) {
        required = Array.from(
          new Set(required.concat(['normalDensity', 'maxDensity']))
        ).filter((value) => !disabled.includes(value));
      } else {
        required = Array.from(
          new Set(required.concat(['normalDensity']))
        ).filter((value) => !disabled.includes(value));
      }
      disabled = Array.from(
        new Set(disabled.concat(['specificGravity', 'molecularWeight']))
      );
      // required = Array.from(new Set(required.concat(['normalDensity', 'maxDensity']))).filter(value => !disabled.includes(value));
      this.setState({
        requiredFields: required,
        disabledFields: disabled,
      });
    } else if (this.props.units_density_heading === 'Specific Gravity') {
      required = required.filter(
        (value) =>
          ![
            'minDensity',
            'normalDensity',
            'maxDensity',
            'molecularWeight',
          ].includes(value)
      );
      disabled = Array.from(
        new Set(
          disabled.concat([
            'minDensity',
            'normalDensity',
            'maxDensity',
            'molecularWeight',
          ])
        )
      );
      required = Array.from(new Set(required.concat(['specificGravity'])));
      this.setState({
        requiredFields: required,
        disabledFields: disabled,
      });
    } else if (this.props.units_density_heading === 'Molecular Weight') {
      required = required.filter(
        (value) =>
          ![
            'minDensity',
            'normalDensity',
            'maxDensity',
            'specificGravity',
          ].includes(value)
      );
      required = required.filter((value) => !disabled.includes(value));
      this.setState({
        requiredFields: Array.from(
          new Set(required.concat(['molecularWeight']))
        ),
        disabledFields: Array.from(
          new Set(
            disabled.concat([
              'minDensity',
              'normalDensity',
              'maxDensity',
              'specificGravity',
            ])
          )
        ),
      });
    }
  };

  measurementTypeSection = () => {
    let listings;
    if (this.props.flow && this.props.density) {
      listings = `Flow, Density: ${this.props.select_technology}`;
    } else if (this.props.density && this.props.viscosity) {
      listings = `Density, Viscosity: ${this.props.select_technology}`;
    } else if (this.props.flow) {
      listings = `Flow: ${this.props.select_technology}`;
    } else if (this.props.density) {
      listings = `Density: ${this.props.select_technology}`;
    } else if (this.props.viscosity) {
      listings = `Viscosity: ${this.props.select_technology}`;
    } else {
      listings = '';
    }

    return (
      <ListGroup.Item
        onClick={() => this.selectPanel('measurementType')}
        style={this.selectedColor('measurementType')}
      >
        <div
          className={
            !this.props.flow && !this.props.density && !this.props.viscosity
              ? 'color-bar bar-red'
              : 'color-bar bar-green'
          }
        >
          <ul className='inner-list'>
            Measurement Type
            <li className='indent'>{listings}</li>
          </ul>
        </div>
      </ListGroup.Item>
    );
  };

  equipmentSectionSection = () => {
    if (this.props.select_technology !== 'Vortex') {
      return (
        <ListGroup.Item
          onClick={() => this.selectPanel('equipmentSelection')}
          style={this.selectedColor('equipmentSelection')}
        >
          <div
            className={
              !this.props.flow && !this.props.density && !this.props.viscosity
                ? 'color-bar bar-red'
                : 'color-bar bar-green'
            }
          >
            <ul className='inner-list'>
              Equipment Selection
              <li className='indent'>{this.props.equipment_selection}</li>
            </ul>
          </div>
        </ListGroup.Item>
      );
    }
  };

  applicationRequirementsSection = () => {
    if (
      !this.props.flow &&
      this.props.density &&
      this.props.line_size === 'All Sizes'
    ) {
      this.props.lineSize('');
    } else if (
      this.props.flow &&
      this.props.density &&
      (this.props.line_size === 'All Sizes' || !this.props.line_size)
    ) {
      this.props.lineSize('All Sizes');
    }

    let lineSizeLabelColor = 'text-red';
    let appColorBar = 'bar-red';
    let showLabels = true;
    if (!this.props.flow && !this.props.density && !this.props.viscosity) {
      appColorBar = 'bar-red';
      showLabels = false;
    } else if (this.props.line_size || this.props.tank || this.props.flow) {
      lineSizeLabelColor = 'text-normal';
      appColorBar = 'bar-green';
      showLabels = true;
    }

    const lineSizeProductFamily = () => {
      return (
        <>
          {!showLabels ? (
            <span />
          ) : this.props.select_technology === 'Vortex' ||
            this.props.select_technology === 'Magnetic' ? (
            <>
              <li>Process Line Size: {this.props.process_line_size}</li>
              {pipeAndSchedule()}
              {triggerLevel()}
            </>
          ) : (
            <>
              <li>Product Family: {this.props.product_family}</li>
              {noLineSize()}
            </>
          )}
        </>
      );
    };

    const triggerLevel = () => {
      return (
        <>
          {this.props.select_technology === 'Vortex' ? (
            <li>Trigger Level: {this.props.trigger_level}</li>
          ) : (
            <span />
          )}
        </>
      );
    };

    const pipeAndSchedule = () => {
      const schedule =
        this.props.select_technology === 'Vortex' &&
          this.props.process_line_size !== 'Unknown*' ? (
          <div>Schedule: {this.props.schedule}</div>
        ) : (
          <span />
        );
      const pipeId =
        this.props.select_technology === 'Vortex' &&
          this.props.schedule === 'Custom' ? (
          <div>Pipe ID: {this.props.pipe_id}</div>
        ) : (
          <span />
        );

      return (
        <>
          {schedule}
          {pipeId}
        </>
      );
    };

    const noLineSize = () => {
      return (
        <>
          {!this.props.tank ? (
            <li>
              <span className={lineSizeLabelColor}>Line Size:</span>{' '}
              {this.props.line_size}
            </li>
          ) : (
            <span />
          )}
        </>
      );
    };

    return (
      <ListGroup.Item
        onClick={() => this.selectPanel('applicationRequirements')}
        style={this.selectedColor('applicationRequirements')}
      >
        <div className={appColorBar}>
          <ul className='inner-list'>
            Application Requirements
            <li>
              <span className='sublist'>
                <ul>
                  {!this.props.flanged || <li>Flanged</li>}
                  {!this.props.wafer || <li>Wafer</li>}
                  {!this.props.reducer || <li>Reducer</li>}
                  {!this.props.dual || <li>Dual</li>}
                  {!this.props.quad || <li>Quad</li>}
                  {!this.props.hygienic_application || (
                    <li>Hygienic Application</li>
                  )}
                  {!this.props.high_process_noise_slurry || (
                    <li>High Process Noise / Slurry</li>
                  )}
                  {!this.props.hygienic || <li>Hygienic (3A/EHEDG)</li>}
                  {!this.props.display_all_sensors_no_filters || (
                    <li>Display All Sensors with no filters</li>
                  )}
                  {!this.props.in_line || <li>In-Line</li>}
                  {!this.props.flow_through_chamber_slipstream || (
                    <li>Flow Through Chamber / Slip-Stream</li>
                  )}
                  {!this.props.tank || <li>Tank</li>}
                  {lineSizeProductFamily()}
                </ul>
              </span>
            </li>
          </ul>
        </div>
      </ListGroup.Item>
    );
  };

  fluidSelectionSection = () => {
    let customTextColor = '';
    let appColorBar;
    let showLabels;
    if (!this.props.flow && !this.props.density && !this.props.viscosity) {
      appColorBar = 'bar-red';
      showLabels = false;
    } else if (
      this.props.fluid_source.toLowerCase() === 'custom' &&
      !this.props.custom_fluid_name
    ) {
      customTextColor = 'text-red';
      appColorBar = 'bar-red';
      showLabels = true;
    } else {
      appColorBar = 'bar-green';
      showLabels = true;
    }

    const database =
      this.props.fluid_source === CustomTranslationText('XXSPECIFY_MMI_SR_LBL_NATGAS',this.props) ?CustomTranslationText('XXSPECIFY_MMI_SR_LBL_NATGAS',this.props) : 'Database';
    const databaseValue =
      this.props.fluid_source === CustomTranslationText('XXSPECIFY_MMI_SR_LBL_NATGAS',this.props)
        ? this.props.pick_gas_from_db
        : this.props.pick_fluids_from_db;
    return (
      <ListGroup.Item
        onClick={() => this.selectPanel('fluidSelection')}
        style={this.selectedColor('fluidSelection')}
      >
        <div className={appColorBar}>
          <ul className='inner-list'>
            Fluid Selection
            <li>
              <span className='sublist'>
                {!showLabels ? (
                  <span />
                ) : (
                  <ul>
                    <li>Fluid State: {this.props.fluid_state}</li>
                    {this.props.fluid_state.toLowerCase() === 'steam' ? (
                      <li>{this.props.steam_pressure_temp}</li>
                    ) : (
                      <>
                        <li>Fluid Source: {this.props.fluid_source}</li>
                        {this.props.fluid_source.toLowerCase() !== 'custom' ? (
                          <li>
                            {database}: {databaseValue}
                          </li>
                        ) : (
                          <li>
                            <span className={customTextColor}>Custom:</span>{' '}
                            {this.props.custom_fluid_name}
                          </li>
                        )}
                        {!this.props.conductivity || (
                          <li>Conductivity: {this.props.conductivity}</li>
                        )}
                        {!this.props.fluid_service || (
                          <li>Fluid Service: {this.props.fluid_service}</li>
                        )}
                      </>
                    )}
                  </ul>
                )}
              </span>
            </li>
          </ul>
        </div>
      </ListGroup.Item>
    );
  };

  componentDidMount = () => {
    this.resetState();
    this.processVariablesTextRequirements();
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps !== this.props) {
      this.processVariablesTextRequirements();
    }
  };

  validateRequiredFieldsHaveValues = (required, densityDropdownEnabled) => {
    let enteredValues = [];
    for (const field of required) {
      enteredValues.push(this.valuePresent(field));
    }
    if (densityDropdownEnabled) {
      this.setProcessVariablesSectionValid(
        required.every((val) => enteredValues.includes(val)) &&
        this.densityDropdownValid()
      );
    } else {
      this.setProcessVariablesSectionValid(
        required.every((val) => enteredValues.includes(val))
      );
    }
  };

  processVariablesTextRequirements = () => {
    if (this.props.select_technology === 'Coriolis') {
      if (
        (this.props.fluid_state.toLowerCase() === 'liquid' &&
          this.props.fluid_source.toLowerCase() === 'custom') ||
        (this.props.fluid_state.toLowerCase() === 'slurry' &&
          this.props.fluid_source.toLowerCase() === 'custom')
      ) {
        if (this.props.non_newtonian_liquid) {
          const nonNewtonFields = ['normalDensity', 'kFlow', 'nFlow'];
          let required = Array.from(
            new Set(this.state.requiredFields.concat(nonNewtonFields))
          );
          const disabled = ['minViscosity', 'normalViscosity'];
          // remove any disabled fields from required list
          required = required.filter((value) => !disabled.includes(value));
          this.setDensityDropdownHeading(required, disabled);
          this.validateRequiredFieldsHaveValues(required, true);
        } else if (!this.props.non_newtonian_liquid) {
          const newtonFields = ['normalDensity', 'normalViscosity'];
          let required = Array.from(
            new Set(this.state.requiredFields.concat(newtonFields))
          );
          const disabled = ['kFlow', 'nFlow'];
          // remove any disabled fields from required list
          required = required.filter((value) => !disabled.includes(value));
          this.setDensityDropdownHeading(required, disabled);
          this.validateRequiredFieldsHaveValues(required, true);
        }
      } else if (this.props.fluid_state.toLowerCase() === 'gas') {
        if (this.props.calculate_density_variation) {
          const densityFields = [
            'minLinePressure',
            'maxLinePressure',
            'minTemperature',
            'maxTemperature',
          ];
          const required = Array.from(
            new Set(this.state.requiredFields.concat(densityFields))
          );
          this.setState({ requiredFields: required });
          this.validateRequiredFieldsHaveValues(required, true);
        }
        this.setState({
          disabledFields: disabledFields
            .filter((value) => ['minVapor'].includes(value))
            .concat(['gasDensity']),
        });
        if (this.props.fluid_source === CustomTranslationText('XXSPECIFY_MMI_SR_LBL_NATGAS',this.props)) {
          let required = Array.from(
            new Set(this.state.requiredFields.concat(['normalViscosity']))
          );
          const disabled = disabledFields.filter(
            (value) =>
              !['minViscosity', 'normalViscosity', 'maxViscosity'].includes(
                value
              )
          );
          // remove any disabled fields from required list
          required = required.filter((value) => !disabled.includes(value));
          this.setState({
            requiredFields: required,
            disabledFields: disabled,
          });
          this.validateRequiredFieldsHaveValues(required, true);
        } else if (this.props.fluid_source.toLowerCase() === 'custom') {
          const customGas = ['normalViscosity', 'gasDensity'];
          const required = Array.from(
            new Set(requiredFields.concat(customGas))
          );
          const disabled = [];
          this.setDensityDropdownHeading(required, disabled);
          this.validateRequiredFieldsHaveValues(required, true);
        }
      } else {
        this.setState({
          requiredFields: [
            'normalFlowRate',
            'normalLinePressure',
            'normalTemperature',
            'normalAmbient',
          ],
          disabledFields: disabledFields.filter((value) =>
            ['atmosphericPressure'].includes(value)
          ),
        });
        this.validateRequiredFieldsHaveValues(
          [
            'normalFlowRate',
            'normalLinePressure',
            'normalTemperature',
            'normalAmbient',
          ],
          false
        );
      }
    } else if (
      this.props.select_technology === 'Vortex' ||
      this.props.select_technology === 'Magnetic'
    ) {
      // this.setState({requiredFields: ['minFlowRate', 'maxFlowRate', 'normalLinePressure', 'normalTemperature']});
      let required = [];
      if (this.props.select_technology === 'Vortex') {
        required = [
          'minFlowRate',
          'maxFlowRate',
          'normalLinePressure',
          'normalTemperature',
          'atmosphericPressure',
        ];
        this.setState({
          requiredFields: required,
          disabledFields: [
            'minAmbient',
            'normalAmbient',
            'maxAmbient',
            'fullAmbient',
          ],
        });
        this.validateRequiredFieldsHaveValues(required);
      } else {
        required = [
          'minFlowRate',
          'maxFlowRate',
          'normalLinePressure',
          'normalTemperature',
        ];
        this.setState({
          requiredFields: required,
          disabledFields: [
            'minAmbient',
            'normalAmbient',
            'maxAmbient',
            'fullAmbient',
          ],
        });
        this.validateRequiredFieldsHaveValues(required);
      }
    } else if (
      this.props.viscosity ||
      (this.props.density && this.props.fluid_state.toLowerCase() !== 'gas')
    ) {
      let required = Array.from(
        new Set(
          requiredFields.concat([
            'maxFlowRate',
            'maxLinePressure',
            'maxTemperature',
            'maxAmbient',
          ])
        )
      );
      let disabled = [];
      if (
        this.props.fluid_state.toLowerCase() === 'liquid' &&
        this.props.fluid_source.toLowerCase() === 'database'
      ) {
        disabled = disabledFields.filter(
          (value) => !['minDensity'].includes(value)
        );
        // remove any disabled fields from required list
        required = required.filter((value) => !disabled.includes(value));
        this.setState({
          requiredFields: required,
          disabledFields: disabled,
        });
        this.validateRequiredFieldsHaveValues(required);
      } else if (
        this.props.fluid_state.toLowerCase() === 'liquid' &&
        this.props.fluid_source.toLowerCase() === 'custom'
      ) {
        if (this.props.non_newtonian_liquid) {
          required = Array.from(
            new Set(
              required.concat(['normalDensity', 'maxDensity', 'maxViscosity'])
            )
          );
          disabled = Array.from(
            new Set(disabled.concat(['minViscosity', 'normalViscosity']))
          );
          // remove any disabled fields from required list
          required = required.filter((value) => !disabled.includes(value));
          this.setDensityDropdownHeading(required, disabled);
          this.validateRequiredFieldsHaveValues(required, true);
        } else {
          required = Array.from(
            new Set(required.concat(['normalViscosity', 'maxViscosity']))
          ); //'normalDensity', 'maxDensity'
          this.setDensityDropdownHeading(required, []);
          this.validateRequiredFieldsHaveValues(required, true);
        }
      }
    } else if (
      this.props.density &&
      !this.props.viscosity &&
      !this.props.flow &&
      this.props.fluid_state.toLowerCase() === 'gas'
    ) {
      let required = Array.from(
        new Set(
          requiredFields.concat([
            'maxLinePressure',
            'maxTemperature',
            'maxAmbient',
          ])
        )
      ).filter((value) => !['normalFlowRate'].includes(value));
      let disabled = Array.from(
        new Set(
          disabledFields.concat([
            'minFlowRate',
            'normalFlowRate',
            'maxFlowRate',
            'fullFlowRate',
          ])
        )
      );
      if (this.props.fluid_source.toLowerCase() === 'database') {
      }
      this.setState({ requiredFields: required });
    }
  };

  // this will determine if the value passed in is present in redux, and if it is, will return that value
  // back, otherwise will return an empty string.
  valuePresent = (value) => {
    switch (value) {
      case 'minFlowRate':
        return this.props.min_flow_rate ? value : '';
      case 'normalFlowRate':
        return this.props.normal_flow_rate ? value : '';
      case 'maxFlowRate':
        return this.props.max_flow_rate ? value : '';
      case 'minLinePressure':
        return this.props.min_line_pressure ? value : '';
      case 'normalLinePressure':
        return this.props.normal_line_pressure ? value : '';
      case 'maxLinePressure':
        return this.props.max_line_pressure ? value : '';
      case 'minTemperature':
        return this.props.min_temperature ? value : '';
      case 'normalTemperature':
        return this.props.normal_temperature ? value : '';
      case 'maxTemperature':
        return this.props.max_temperature ? value : '';
      case 'minAmbient':
        return this.props.min_ambient ? value : '';
      case 'normalAmbient':
        return this.props.normal_ambient ? value : '';
      case 'maxAmbient':
        return this.props.max_ambient ? value : '';
      case 'minDensity':
        return this.props.min_density ? value : '';
      case 'normalDensity':
        return this.props.normal_density ? value : '';
      case 'maxDensity':
        return this.props.max_density ? value : '';
      case 'minViscosity':
        return this.props.min_viscosity ? value : '';
      case 'normalViscosity':
        return this.props.normal_viscosity ? value : '';
      case 'maxViscosity':
        return this.props.max_viscosity ? value : '';
      case 'kFlow':
        return this.props.k_flow_consistency_index ? value : '';
      case 'nFlow':
        return this.props.n_flow_behavior ? value : '';
      case 'gasDensity':
        return this.props.gas_density ? value : '';
      case 'atmosphericPressure':
        return this.props.pressure_atmospheric ? value : '';
      default:
    }
  };

  processVariablesSection = () => {
    let appColorBar;
    let showLabels;
    if (!this.props.flow && !this.props.density && !this.props.viscosity) {
      appColorBar = 'bar-red';
      showLabels = false;
    } else if (!this.state.processVariablesValid) {
      appColorBar = 'bar-red';
      showLabels = true;
    } else {
      appColorBar = 'bar-green';
      showLabels = true;
    }
    return (
      <ListGroup.Item
        onClick={() => this.selectPanel('processVariables')}
        style={this.selectedColor('processVariables')}
      >
        <div className={appColorBar}>
          <ul className='inner-list'>
            Process Variables
            <li>
              <span className='sublist'>
                {!showLabels ? (
                  <span />
                ) : (
                  <RightNavListProcessVariablesLabels
                    required={this.state.requiredFields}
                    disabled={this.state.disabledFields}
                  />
                )}
              </span>
            </li>
          </ul>
        </div>
      </ListGroup.Item>
    );
  };

  render = () => {
    return (
      <div className='right-nav-selection'>
        <Container>
          <ListGroup>
            {this.measurementTypeSection()}
            {this.equipmentSectionSection()}
            {this.applicationRequirementsSection()}
            {this.fluidSelectionSection()}
            {this.processVariablesSection()}
          </ListGroup>
        </Container>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  flow: state.MeasurementType.flow,
  density: state.MeasurementType.density,
  viscosity: state.MeasurementType.viscosity,
  select_technology: state.MeasurementType.select_technology,
  equipment_selection: state.EquipmentSelection.equipment_selection,
  basic_page: state.BasicPageRightNavList.basic_page,
  flanged: state.ApplicationRequirements.flanged,
  wafer: state.ApplicationRequirements.wafer,
  reducer: state.ApplicationRequirements.reducer,
  dual: state.ApplicationRequirements.dual,
  quad: state.ApplicationRequirements.quad,
  hygienic: state.ApplicationRequirements.hygienic,
  display_all_sensors_no_filters:
    state.ApplicationRequirements.display_all_sensors_no_filters,
  hygienic_application: state.ApplicationRequirements.hygienic_application,
  high_process_noise_slurry:
    state.ApplicationRequirements.high_process_noise_slurry,
  in_line: state.ApplicationRequirements.in_line,
  flow_through_chamber_slipstream:
    state.ApplicationRequirements.flow_through_chamber_slipstream,
  product_family: state.ApplicationRequirements.product_family,
  line_size: state.ApplicationRequirements.line_size,
  process_line_size: state.ApplicationRequirements.process_line_size,
  tank: state.ApplicationRequirements.tank,
  trigger_level: state.ApplicationRequirements.trigger_level,
  pipe_id: state.ApplicationRequirements.pipe_id,
  schedule: state.ApplicationRequirements.schedule,
  fluid_state: state.FluidSelection.fluid_state,
  fluid_source: state.FluidSelection.fluid_source,
  pick_fluids_from_db: state.FluidSelection.pick_fluids_from_db,
  pick_gas_from_db: state.FluidSelection.pick_gas_from_db,
  conductivity: state.FluidSelection.conductivity,
  fluid_service: state.FluidSelection.fluid_service,
  custom_fluid_name: state.FluidSelection.custom_fluid_name,
  steam_pressure_temp: state.FluidSelection.steam_pressure_temp,
  min_flow_rate: state.ProcessVariables.min_flow_rate,
  normal_flow_rate: state.ProcessVariables.normal_flow_rate,
  max_flow_rate: state.ProcessVariables.max_flow_rate,
  min_line_pressure: state.ProcessVariables.min_line_pressure,
  min_temperature: state.ProcessVariables.min_temperature,
  min_ambient: state.ProcessVariables.min_ambient,
  normal_line_pressure: state.ProcessVariables.normal_line_pressure,
  normal_temperature: state.ProcessVariables.normal_temperature,
  normal_ambient: state.ProcessVariables.normal_ambient,
  max_line_pressure: state.ProcessVariables.max_line_pressure,
  max_temperature: state.ProcessVariables.max_temperature,
  max_ambient: state.ProcessVariables.max_ambient,
  units_flow_rate: state.ProcessVariables.units_flow_rate,
  units_line_pressure: state.ProcessVariables.units_line_pressure,
  units_temperature: state.ProcessVariables.units_temperature,
  units_ambient: state.ProcessVariables.units_ambient,
  units_full: state.ProcessVariables.units_full,
  full_flow_rate: state.ProcessVariables.full_flow_rate,
  full_line_pressure: state.ProcessVariables.full_line_pressure,
  full_temperature: state.ProcessVariables.full_temperature,
  full_ambient: state.ProcessVariables.full_ambient,
  max_pressure_drop: state.ProcessVariables.max_pressure_drop,
  max_flow_acc: state.ProcessVariables.max_flow_acc,
  units_density: state.ProcessVariables.units_density,
  min_density: state.ProcessVariables.min_density,
  normal_density: state.ProcessVariables.normal_density,
  max_density: state.ProcessVariables.max_density,
  units_viscosity: state.ProcessVariables.units_viscosity,
  min_viscosity: state.ProcessVariables.min_viscosity,
  normal_viscosity: state.ProcessVariables.normal_viscosity,
  max_viscosity: state.ProcessVariables.max_viscosity,
  gas_density: state.ProcessVariables.gas_density,
  units_gas_density: state.ProcessVariables.units_gas_density,
  sonic_velocity: state.ProcessVariables.sonic_velocity,
  compressibility: state.ProcessVariables.compressibility,
  units_sonic_velocity: state.ProcessVariables.units_sonic_velocity,
  units_gas_pressure: state.ProcessVariables.units_gas_pressure,
  gas_pressure: state.ProcessVariables.gas_pressure,
  units_gas_temperature: state.ProcessVariables.units_gas_temperature,
  gas_temperature: state.ProcessVariables.gas_temperature,
  non_newtonian_liquid: state.ProcessVariables.non_newtonian_liquid,
  k_flow_consistency_index: state.ProcessVariables.k_flow_consistency_index,
  n_flow_behavior: state.ProcessVariables.n_flow_behavior,
  min_vapor: state.ProcessVariables.min_vapor,
  units_vapor: state.ProcessVariables.units_vapor,
  pressure_atmospheric: state.ProcessVariables.pressure_atmospheric,
  units_pressure_atmospheric: state.ProcessVariables.units_pressure_atmospheric,
  specific_gravity: state.ProcessVariables.specific_gravity,
  molecular_weight: state.ProcessVariables.molecular_weight,
  units_density_heading: state.ProcessVariables.units_density_heading,
  calculate_density_variation:
    state.ProcessVariables.calculate_density_variation,
    translation_data: state.TranslationData.translation_data,
});

export default connect(mapStateToProps, {
  equipmentSelection,
  selectBasicPage,
  lineSize,
})(RightNavList);
