import { GAS_DENSITY_VALUES } from '../../constants/constants';
import {
  UNITS_FLOW_RATE,
  UNITS_GAS_FLOW_RATE,
  MIN_FLOW_RATE,
  MIN_FLOW_RATE_VALUE,
  NORMAL_FLOW_RATE,
  MAX_FLOW_RATE,
  FULL_FLOW_RATE,
  UNITS_LINE_PRESSURE,
  MIN_LINE_PRESSURE,
  MIN_LINE_PRESSURE_VALUE,
  NORMAL_LINE_PRESSURE,
  NORMAL_LINE_PRESSURE_VALUE,
  MAX_LINE_PRESSURE,
  MAX_LINE_PRESSURE_VALUE,
  FULL_LINE_PRESSURE,
  FULL_LINE_PRESSURE_VALUE,
  UNITS_TEMPERATURE,
  MIN_TEMPERATURE,
  MIN_TEMPERATURE_VALUE,
  NORMAL_TEMPERATURE,
  NORMAL_TEMPERATURE_VALUE,
  MAX_TEMPERATURE,
  MAX_TEMPERATURE_VALUE,
  FULL_TEMPERATURE,
  FULL_TEMPERATURE_VALUE,
  UNITS_AMBIENT,
  MIN_AMBIENT,
  NORMAL_AMBIENT,
  MAX_AMBIENT,
  FULL_AMBIENT,
  UNITS_DENSITY,
  MIN_DENSITY,
  MIN_DENSITY_VALUE,
  NORMAL_DENSITY,
  NORMAL_DENSITY_VALUE,
  DENSITY_SG,
  DENSITY_SG_VALUE,
  MINIMUM_DENSITY_ERROR,
  NORMAL_DENSITY_ERROR,
  MAXIMUM_DENSITY_ERROR,
  NORMAL_DENSITY_MIN_MAX_ERRORS,
  MAX_DENSITY_MIN_MAX_ERRORS,
  NORMAL_DENSITY_TYPE_ERRRORS,
  MAX_DENSITY_TYPE_ERRORS,
  NORMAL_VISCOSITY_ERROR,
  MIN_VISCOSITY_CONVERTION_ERROR,
  NORMAL_VISCOSITY_CONVERTION_ERROR,
  MAX_VISCOSITY_CONVERTION_ERROR,
  MAXIMUM_VISCOSITY_ERROR,
  NORMAL_VISCOSITY_MIN_MAX_ERRORS,
  MAX_VISCOSITY_MIN_MAX_ERRORS,
  NORMAL_VISCOSITY_TYPE_ERRORS,
  MAX_VISCOSITY_TYPE_ERRORS,  
  GAS_CUSTOM_COMPRESSIBILITY_ERROR,
  MAX_DENSITY,
  MAX_DENSITY_VALUE,
  UNITS_VISCOSITY,
  MIN_VISCOSITY,
  MIN_VISCOSITY_VALUE,
  NORMAL_VISCOSITY,
  NORMAL_VISCOSITY_VALUE,
  MAX_VISCOSITY,
  MAX_VISCOSITY_VALUE,
  UNITS_VAPOR,
  MIN_VAPOR,
  MIN_VAPOR_VALUE,
  MAX_FLOW_ACC,
  UNITS_MAX_PRESSURE_DROP,
  MAX_PRESSURE_DROP,
  UNITS_SONIC_VELOCITY,
  SONIC_VELOCITY,
  SONIC_VELOCITY_MAP,
  SONIC_VELOCITY_VALUE,
  UNITS_PRESSURE_ATMOSPHERIC,
  PRESSURE_ATMOSPHERIC,
  UNITS_MINIMUM_ACCURACY,
  UNITS_DENSITY_HEADING,
  NON_NEWTONIAN_LIQUID,
  REVERT_NON_NEWTONIAN_CHECKBOX_FLAG,
  NON_NEWTONIAN_LIQUID_FLAG,
  CALCULATE_DENSITY_VARIATION,
  K_FLOW_CONSISTENCY_INDEX,
  N_FLOW_BEHAVIOR,
  COMPRESSIBILITY,
  BASE_COMPRESSIBILITY,
  BASE_COMPRESSIBILITY_ERROR,
  SPECIFIC_GRAVITY,
  SPECIFIC_GRAVITY_ERROR,
  MOLECULAR_WEIGHT,
  MOLECULAR_WEIGHT_ERROR,
  UNITS_GAS_PRESSURE,
  GAS_PRESSURE,
  GAS_PRESSURE_ERROR,
  UNITS_GAS_TEMPERATURE,
  GAS_TEMPERATURE,
  GAS_TEMPERATURE_ERROR,
  UNITS_GAS_DENSITY,
  UNITS_GAS_DENSITY_SG,
  OPERATING_DATA_ERROR_MESSAGES,
  GAS_DENSITY,
  RESET_RESIZING_FLAG,
  GAS_DENSITY_VALUE,
  BASE_REFERENCE_CONDITIONS,
  CLEAR_PROC_VARIABLES,
  CLEAR_PROC_VARIABLES_VALUES,
  PREVIOUS_TECHNOLOGY,
  SELECTED_TECHNOLOGY,
  DISABLE_SIZING_RESULTS_BUTTON,
  CONVERT_DISABLE_BUTTON, 
  OPERATING_CONDITIONS,
  RESET_OPERATING_CONDITIONS,
  FLOW_RATE_MIN_CLASS_LIST,
  FLOW_RATE_NORM_CLASS_LIST,
  FLOW_RATE_MAX_CLASS_LIST,
  FLOW_RATE_FULL_CLASS_LIST,
  FLOW_RATE_MIN_ERRORS,
  FLOW_RATE_NORM_ERRORS,
  FLOW_RATE_MAX_ERRORS,
  FLOW_RATE_FULL_ERRORS,
  FLOW_RATE_DROPDOWN_ARRAY,
  FLOW_RATE_GAS_DROPDOWN_ARRAY,
  LINE_PRESS_MIN_CLASS_LIST,
  LINE_PRESS_NORM_CLASS_LIST,
  LINE_PRESS_MAX_CLASS_LIST,
  LINE_PRESS_FULL_CLASS_LIST,
  LINE_PRESS_MIN_ERRORS,
  LINE_PRESS_NORM_ERRORS,
  LINE_PRESS_MAX_ERRORS,
  LINE_PRESS_FULL_ERRORS,
  LINE_PRESS_DROPDOWN_ARRAY,
  TEMPERATURE_MIN_CLASS_LIST,
  TEMPERATURE_NORM_CLASS_LIST,
  TEMPERATURE_MAX_CLASS_LIST,
  TEMPERATURE_FULL_CLASS_LIST,
  TEMPERATURE_MIN_ERRORS,
  TEMPERATURE_NORM_ERRORS,
  TEMPERATURE_MAX_ERRORS,
  TEMPERATURE_FULL_ERRORS,
  TEMPERATURE_DROPDOWN_ARRAY,
  AMBIENT_TEMPERATURE_MIN_CLASS_LIST,
  AMBIENT_TEMPERATURE_NORM_CLASS_LIST,
  AMBIENT_TEMPERATURE_MAX_CLASS_LIST,
  AMBIENT_TEMPERATURE_FULL_CLASS_LIST,
  AMBIENT_TEMPERATURE_MIN_ERRORS,
  AMBIENT_TEMPERATURE_NORM_ERRORS,
  AMBIENT_TEMPERATURE_MAX_ERRORS,
  AMBIENT_TEMPERATURE_FULL_ERRORS,
  AMBIENT_TEMPERATURE_DROPDOWN_ARRAY,
  CUSTOM_ERRORS,
  REQUIRED_FIELDS,
  GAS_DENSITY_ERROR,
  LINE_PRESS_ROW_PSGI,
  TEMP_ROW_F,
  SET_CALC_FLAG,
  SIZING_RESULTS_CLICKED,
  RESIZING_DISCARD_FLAG,
  ALLOW_PREF_BASE_REF,
} from '../actionTypes';

const initialState = {
  units_flow_rate: {key: 'USGPH', flow_type:'volume',value: 'Gallons(US)/hr'},
  units_gas_flow_rate: {key: 'KG/HR',flow_type:'mass', value: 'kg/hr'},
  min_flow_rate: '',
  min_flow_rate_value:'',
  normal_flow_rate: '',
  max_flow_rate: '',
  full_flow_rate: '',
  units_line_pressure: {key: 'PSIG', value: 'psig'},
  min_line_pressure: '',
  min_line_pressure_value:'',
  normal_line_pressure: '',
  normal_line_pressure_value:'',
  max_line_pressure: '',
  max_line_pressure_value:'',
  full_line_pressure: '',
  full_line_pressure_value:'',
  units_temperature: {key: 'F', value: 'F'},
  min_temperature: '',
  min_temperature_value:'',
  normal_temperature: '',
  normal_temperature_value:'',
  max_temperature: '',
  max_temperature_value:'',
  full_temperature: '',
  full_temperature_value:'',
  units_ambient: {key: 'F', value: 'F'},
  min_ambient: '',
  normal_ambient: '',
  max_ambient: '',
  full_ambient: '',
  calculate_density_variation: false,
  previous_technology: 'None',
  selected_technology: 'Coriolis',
  disable_sizing_results_button: false,
  convert_disable_button: false,
  operating_conditions: {select_technology: 'coriolis'},
  reset_operating_conditions: {},
  operating_data_error_messages:[],
  units_density: {key: 'LB/GAL(US)', value: 'lb/Gallon(US)'},
  min_density: '',
  min_density_value: '',
  normal_density: '',
  normal_density_value: '',
  density_sg:'',
  density_sg_value:'',
  minimum_density_error:false,
  normal_density_error:false,
  maximum_density_error:false,
  normal_density_min_max_errors: false, 
  max_density_min_max_errors: false,
  normal_density_type_errors: '',
  max_density_type_errors: '',
  normal_viscosity_error:false,
  min_viscosity_convertion_error: false,
  normal_viscosity_convertion_error: false,
  max_viscosity_convertion_error: false,
  maximum_viscosity_error:false,
  normal_viscosity_min_max_errors:false,
  max_viscosity_min_max_errors:false,
  normal_viscosity_type_errors:'',
  max_viscosity_type_errors:'',
  gas_density_error:false,
  max_density: '',
  max_density_value: '',
  units_viscosity: {key: 'CP', value: 'cP'},
  min_viscosity: '',
  min_viscosity_value: '',
  normal_viscosity : '',
  normal_viscosity_value : '',
  max_viscosity: '',
  max_viscosity_value: '',
  units_vapor: {key: 'PSIA', value: 'psia'},
  min_vapor: '',
  min_vapor_value: '',
  max_flow_acc: '', 
  units_max_pressure_drop: {key: 'PSIG', value: 'psig'},
  max_pressure_drop: '',
  units_sonic_velocity: {key:'FT/SEC', value: 'ft/sec'},
  sonic_velocity: '',
  sonic_velocity_map: {},
  sonic_velocity_value: '',
  units_pressure_atmospheric: {key: 'PSIA', value: 'psia'},
  pressure_atmospheric: '',
  units_minimum_accuracy: '+/- 1.0%',
  units_density_heading: 'Density',
  non_newtonian_liquid: false,
  revert_non_newtonian_checkbox_flag: false,
  non_newtonian_liquid_flag: false,
  k_flow_consistency_index: '',
  n_flow_behavior: '',
  compressibility: '',
  base_compressibility:'1.000000000', 
  base_compressibility_error:false,
  gas_custom_compressibility_error: false,
  specific_gravity: '',
  specific_gravity_error:false,
  molecular_weight: '',
  molecular_weight_error: false,
  custom_errors:[],
  units_gas_pressure: {key: 'BAR-A', value: 'bar-a'},
  gas_pressure: '1.01325348872',
  gas_pressure_error: false,
  units_gas_temperature: {key: 'C', value: 'C'},
  gas_temperature: '15.555555555555555',
  gas_temperature_error:false,
  units_gas_density: {key: 'KG/M3', value: 'kg/m3'},
  units_gas_density_sg:{key: 'KG/M3', value: 'kg/m3'},
  gas_density: '',
  gas_density_value:'',
  reset_resizing_flag:false,
  line_press_row_psig:'', 
  calculation_call: false,
  sizing_results_clicked: false,
  temp_row_f:'',
  resizing_discard_flag:false,
  allow_pref_base_ref: true,
  // errors
  // calculations api
  base_reference_conditions: false,
  flow_rate_min_class_list: 'border-not-required oc-input',
  flow_rate_norm_class_list: 'border-not-required oc-input',
  flow_rate_max_class_list: 'border-not-required oc-input',
  flow_rate_full_class_list: 'border-not-required oc-input',
  flow_rate_min_errors: '',
  flow_rate_norm_errors: '',
  flow_rate_max_errors: '',
  flow_rate_full_errors: '',
  flow_rate_dropdown_array: [
    {
        "key": "USBPD",
        "flow_type": "VOL",
        "value": "Barrels(Oil)/day"
    },
    {
        "key": "USBPH",
        "flow_type": "VOL",
        "value": "Barrels(Oil)/hr"
    },
    {
        "key": "USBPM",
        "flow_type": "VOL",
        "value": "Barrels(Oil)/min"
    },
    {
        "key": "USBPS",
        "flow_type": "VOL",
        "value": "Barrels(Oil)/sec"
    },
    {
        "key": "BEER BBL/D (31 USG)",
        "flow_type": "VOL",
        "value": "Beerbarrels/day"
    },
    {
        "key": "BEER BBL/H (31 USG)",
        "flow_type": "VOL",
        "value": "Beerbarrels/hr"
    },
    {
        "key": "BEER BBL/M (31 USG)",
        "flow_type": "VOL",
        "value": "Beerbarrels/min"
    },
    {
        "key": "BEER BBL/S (31 USG)",
        "flow_type": "VOL",
        "value": "Beerbarrels/sec"
    },
    {
        "key": "FT3/DAY",
        "flow_type": "VOL",
        "value": "ft3/day"
    },
    {
        "key": "FT3/HR",
        "flow_type": "VOL",
        "value": "ft3/hr"
    },
    {
        "key": "FT3/MIN",
        "flow_type": "VOL",
        "value": "ft3/min"
    },
    {
        "key": "FT3/SEC",
        "flow_type": "VOL",
        "value": "ft3/sec"
    },
    {
        "key": "USGPD",
        "flow_type": "VOL",
        "value": "Gallons(US)/day"
    },
    {
        "key": "USGPH",
        "flow_type": "VOL",
        "value": "Gallons(US)/hr"
    },
    {
        "key": "USGPM",
        "flow_type": "VOL",
        "value": "Gallons(US)/min"
    },
    {
        "key": "USGPS",
        "flow_type": "VOL",
        "value": "Gallons(US)/sec"
    },
    {
        "key": "G/HR",
        "flow_type": "MASS",
        "value": "grams/hr"
    },
    {
        "key": "G/MIN",
        "flow_type": "MASS",
        "value": "grams/min"
    },
    {
        "key": "G/SEC",
        "flow_type": "MASS",
        "value": "grams/sec"
    },
    {
        "key": "IMPGPD",
        "flow_type": "VOL",
        "value": "IMPGAL/day"
    },
    {
        "key": "IMPGPH",
        "flow_type": "VOL",
        "value": "IMPGAL/hr"
    },
    {
        "key": "IMPGPM",
        "flow_type": "VOL",
        "value": "IMPGAL/min"
    },
    {
        "key": "IMPGPS",
        "flow_type": "VOL",
        "value": "IMPGAL/sec"
    },
    {
        "key": "KG/DAY",
        "flow_type": "MASS",
        "value": "kg/day"
    },
    {
        "key": "KG/HR",
        "flow_type": "MASS",
        "value": "kg/hr"
    },
    {
        "key": "KG/MIN",
        "flow_type": "MASS",
        "value": "kg/min"
    },
    {
        "key": "KG/SEC",
        "flow_type": "MASS",
        "value": "kg/sec"
    },
    {
        "key": "LB/DAY",
        "flow_type": "MASS",
        "value": "lb/day"
    },
    {
        "key": "LB/HR",
        "flow_type": "MASS",
        "value": "lb/hr"
    },
    {
        "key": "LB/MIN",
        "flow_type": "MASS",
        "value": "lb/min"
    },
    {
        "key": "LB/SEC",
        "flow_type": "MASS",
        "value": "lb/sec"
    },
    {
        "key": "L/DAY",
        "flow_type": "VOL",
        "value": "litres/day"
    },
    {
        "key": "L/HR",
        "flow_type": "VOL",
        "value": "litres/hr"
    },
    {
        "key": "L/MIN",
        "flow_type": "VOL",
        "value": "litres/min"
    },
    {
        "key": "L/SEC",
        "flow_type": "VOL",
        "value": "litres/sec"
    },
    {
        "key": "M3/DAY",
        "flow_type": "VOL",
        "value": "m3/day"
    },
    {
        "key": "M3/HR",
        "flow_type": "VOL",
        "value": "m3/hr"
    },
    {
        "key": "M3/MIN",
        "flow_type": "VOL",
        "value": "m3/min"
    },
    {
        "key": "M3/SEC",
        "flow_type": "VOL",
        "value": "m3/sec"
    },
    {
        "key": "CC/HR",
        "flow_type": "VOL",
        "value": "millilitres/hr"
    },
    {
        "key": "CC/MIN",
        "flow_type": "VOL",
        "value": "millilitres/min"
    },
    {
        "key": "CC/SEC",
        "flow_type": "VOL",
        "value": "millilitres/sec"
    },
    {
        "key": "TON/DAY",
        "flow_type": "MASS",
        "value": "ton(US)Short/day"
    },
    {
        "key": "TON/HR",
        "flow_type": "MASS",
        "value": "ton(US)Short/hr"
    },
    {
        "key": "TON/MIN",
        "flow_type": "MASS",
        "value": "ton(US)Short/min"
    },
    {
        "key": "TON(M)/DAY",
        "flow_type": "MASS",
        "value": "tonne(Metric)/day"
    },
    {
        "key": "TON(M)/HR",
        "flow_type": "MASS",
        "value": "tonne(Metric)/hr"
    },
    {
        "key": "TON(M)/MIN",
        "flow_type": "MASS",
        "value": "tonne(Metric)/min"
    }
],
  flow_rate_gas_dropdown_array: [],
  line_press_min_class_list: 'border-not-required oc-input',
  line_press_norm_class_list: 'border-not-required oc-input',
  line_press_max_class_list: 'border-not-required oc-input',
  line_press_full_class_list: 'border-not-required oc-input',
  line_press_min_errors: '',
  line_press_norm_errors: '',
  line_press_max_errors: '',
  line_press_full_errors: '',
  line_press_dropdown_array: [
    {
        "key": "ATM-A",
        "value": "atm-a"
    },
    {
        "key": "ATM-G",
        "value": "atm-g"
    },
    {
        "key": "ATM-G",
        "value": "atm-g"
    },
    {
        "key": "BAR-A",
        "value": "bar-a"
    },
    {
        "key": "BAR-G",
        "value": "bar-g"
    },
    {
        "key": "FTH2O@68F-A",
        "value": "ftH2O@68F-a"
    },
    {
        "key": "FTH2O@68F-G",
        "value": "ftH2O@68F-g"
    },
    {
        "key": "FTH2O@68F-G",
        "value": "ftH2O@68F-g"
    },
    {
        "key": "G/CM2-A",
        "value": "g/cm2-a"
    },
    {
        "key": "G/CM2-G",
        "value": "g/cm2-g"
    },
    {
        "key": "G/CM2-G",
        "value": "g/cm2-g"
    },
    {
        "key": "INH2O-A",
        "value": "inH2O-a"
    },
    {
        "key": "INH2O-G",
        "value": "inH2O-g"
    },
    {
        "key": "INH2O-G",
        "value": "inH2O-g"
    },
    {
        "key": "INH2O@60F-A",
        "value": "inH2O@60F-a"
    },
    {
        "key": "INH2O@60F-G",
        "value": "inH2O@60F-g"
    },
    {
        "key": "INH2O@68F-A",
        "value": "inH2O@68F-a"
    },
    {
        "key": "INH2O@68F-G",
        "value": "inH2O@68F-g"
    },
    {
        "key": "INHG@0C-A",
        "value": "inHg@0C-a"
    },
    {
        "key": "INHG@0C-G",
        "value": "inHg@0C-g"
    },
    {
        "key": "KG/CM2-A",
        "value": "kg/cm2-a"
    },
    {
        "key": "KG/CM2-G",
        "value": "kg/cm2-g"
    },
    {
        "key": "KPA-A",
        "value": "kPa-a"
    },
    {
        "key": "KPA-G",
        "value": "kPa-g"
    },
    {
        "key": "MBAR-A",
        "value": "mbar-a"
    },
    {
        "key": "MBAR-G",
        "value": "mbar-g"
    },
    {
        "key": "MH2O@4C-A",
        "value": "mH2O@4C-a"
    },
    {
        "key": "MH2O@4C-G",
        "value": "mH2O@4C-g"
    },
    {
        "key": "MMH2O-A",
        "value": "mmH2O-a"
    },
    {
        "key": "MMH2O-G",
        "value": "mmH2O-g"
    },
    {
        "key": "MMH2O-G",
        "value": "mmH2O-g"
    },
    {
        "key": "MMH2O@68F-A",
        "value": "mmH2O@68F-a"
    },
    {
        "key": "MMH2O@68F-G",
        "value": "mmH2O@68F-g"
    },
    {
        "key": "MMH2O@68F-G",
        "value": "mmH2O@68F-g"
    },
    {
        "key": "MMHG@0C-A",
        "value": "mmHg@0C-a"
    },
    {
        "key": "MMHG@0C-G",
        "value": "mmHg@0C-g"
    },
    {
        "key": "MMHG@0C-G",
        "value": "mmHg@0C-g"
    },
    {
        "key": "MPA-A",
        "value": "MPa-a"
    },
    {
        "key": "MPA-G",
        "value": "MPa-g"
    },
    {
        "key": "PA-A",
        "value": "Pa-a"
    },
    {
        "key": "PA-G",
        "value": "Pa-g"
    },
    {
        "key": "PSIA",
        "value": "psia"
    },
    {
        "key": "PSIG",
        "value": "psig"
    },
    {
        "key": "TORR-A",
        "value": "Torr-a"
    },
    {
        "key": "TORR-G",
        "value": "Torr-g"
    },
    {
        "key": "TORR-G",
        "value": "Torr-g"
    }
],
  temperature_min_class_list: 'border-not-required oc-input',
  temperature_norm_class_list: 'border-not-required oc-input',
  temperature_max_class_list: 'border-not-required oc-input',
  temperature_full_class_list: 'border-not-required oc-input',
  temperature_min_errors: '',
  temperature_norm_errors: '',
  temperature_max_errors: '',
  temperature_full_errors: '',
  temperature_dropdown_array: [
    {
        "key": "C",
        "value": "C"
    },
    {
        "key": "F",
        "value": "F"
    },
    {
        "key": "K",
        "value": "K"
    },
    {
        "key": "R",
        "value": "R"
    }
],
  ambient_temperature_min_class_list: 'border-not-required oc-input',
  ambient_temperature_norm_class_list: 'border-not-required oc-input',
  ambient_temperature_max_class_list: 'border-not-required oc-input',
  ambient_temperature_full_class_list: 'border-not-required oc-input',
  ambient_temperature_min_errors: '',
  ambient_temperature_norm_errors: '',
  ambient_temperature_max_errors: '',
  ambient_temperature_full_errors: '',
  ambient_temperature_dropdown_array: [
    {
        "key": "C",
        "value": "C"
    },
    {
        "key": "F",
        "value": "F"
    },
    {
        "key": "K",
        "value": "K"
    },
    {
        "key": "R",
        "value": "R"
    }
  ],
  required_fields: [
    {
        "id": "flow-rate-normal",
        "val": ""
    },
    {
        "id": "line-press-normal",
        "val": ""
    },
    {
        "id": "temp-normal",
        "val": ""
    },
    {
        "id": "ambient-normal",
        "val": ""
    }
]
};

export default function (state = initialState, action) {
  switch (action.type) {
    
    case OPERATING_CONDITIONS: {
      return {
        ...state,
        operating_conditions:action.payload.id
      }
    }
    case RESET_OPERATING_CONDITIONS: {
      return {
        ...state,
        reset_operating_conditions:action.payload.id
      }
    }
    case SELECTED_TECHNOLOGY: {
      return {
        ...state,
        selected_technology:action.payload.id
      }
    }
    case PREVIOUS_TECHNOLOGY: {
      return {
        ...state,
        previous_technology:action.payload.id
      }
    }
    case OPERATING_DATA_ERROR_MESSAGES: {
      return {
        ...state,
        operating_data_error_messages:action.payload.id
      }
    }
    case CUSTOM_ERRORS: {
      return {
        ...state,
        custom_errors:action.payload.id
      }
    }
    case DISABLE_SIZING_RESULTS_BUTTON: {
      return {
        ...state,
        disable_sizing_results_button: action.payload.id
      }
    }

    case CONVERT_DISABLE_BUTTON : {
      return {
        ...state,
        convert_disable_button: action.payload.id
      }
    }

    case UNITS_FLOW_RATE: {
      return {
        ...state,
        units_flow_rate: action.payload.id
      }
    }
    case UNITS_GAS_FLOW_RATE: {
      return {
        ...state,
        units_gas_flow_rate: action.payload.id
      }
    }
    case MIN_FLOW_RATE: {
      return {
        ...state,
        min_flow_rate: action.payload.id
      }
    }
    case MIN_FLOW_RATE_VALUE: {
      return {
        ...state,
        min_flow_rate_value: action.payload.id
      }
    }
    case NORMAL_FLOW_RATE: {
      return {
        ...state,
        normal_flow_rate: action.payload.id
      }
    }
    case MAX_FLOW_RATE: {
      return {
        ...state,
        max_flow_rate: action.payload.id
      }
    }
    case FULL_FLOW_RATE: {
      return {
        ...state,
        full_flow_rate: action.payload.id
      }
    }
    case UNITS_LINE_PRESSURE: {
      return {
        ...state,
        units_line_pressure: action.payload.id
      }
    }
    case MIN_LINE_PRESSURE: {
      return {
        ...state,
        min_line_pressure: action.payload.id
      }
    }
    case MIN_LINE_PRESSURE_VALUE: {
      return {
        ...state,
        min_line_pressure_value: action.payload.id
      }
    }
    case GAS_DENSITY_ERROR: {
      return {
        ...state,
        gas_density_error: action.payload.id
      }
    }
    case NORMAL_LINE_PRESSURE: {
      return {
        ...state,
        normal_line_pressure: action.payload.id
      }
    }

    case NORMAL_LINE_PRESSURE_VALUE: {
      return {
        ...state,
        normal_line_pressure_value: action.payload.id
      }
    }

    case MAX_LINE_PRESSURE: {
      return {
        ...state,
        max_line_pressure: action.payload.id
      }
    }
    case MAX_LINE_PRESSURE_VALUE: {
      return {
        ...state,
        max_line_pressure_value: action.payload.id
      }
    }
    case FULL_LINE_PRESSURE: {
      return {
        ...state,
        full_line_pressure: action.payload.id
      }
    }
    case FULL_LINE_PRESSURE_VALUE: {
      return {
        ...state,
        full_line_pressure_value: action.payload.id
      }
    }
    case UNITS_TEMPERATURE: {
      return {
        ...state,
        units_temperature: action.payload.id
      }
    }
    case MIN_TEMPERATURE: {
      return {
        ...state,
        min_temperature: action.payload.id
      }
    }
    case MIN_TEMPERATURE_VALUE: {
      return {
        ...state,
        min_temperature_value: action.payload.id
      }
    }
    case NORMAL_TEMPERATURE: {
      return {
        ...state,
        normal_temperature: action.payload.id
      }
    }
    case NORMAL_TEMPERATURE_VALUE: {
      return {
        ...state,
        normal_temperature_value: action.payload.id
      }
    }
    case MAX_TEMPERATURE: {
      return {
        ...state,
        max_temperature: action.payload.id
      }
    }
    case MAX_TEMPERATURE_VALUE: {
      return {
        ...state,
        max_temperature_value: action.payload.id
      }
    }
    case FULL_TEMPERATURE: {
      return {
        ...state,
        full_temperature: action.payload.id
      }
    }
    case FULL_TEMPERATURE_VALUE: {
      return {
        ...state,
        full_temperature_value: action.payload.id
      }
    }
    case UNITS_AMBIENT: {
      return {
        ...state,
        units_ambient: action.payload.id
      }
    }
    case MIN_AMBIENT: {
      return {
        ...state,
        min_ambient: action.payload.id
      }
    }
    case NORMAL_AMBIENT: {
      return {
        ...state,
        normal_ambient: action.payload.id
      }
    }
    case MAX_AMBIENT: {
      return {
        ...state,
        max_ambient: action.payload.id
      }
    }
    case FULL_AMBIENT: {
      return {
        ...state,
        full_ambient: action.payload.id
      }
    }

    case UNITS_DENSITY: {
      return {
        ...state,
        units_density: action.payload.id
      }
    }
    case MIN_DENSITY: {
      return {
        ...state,
        min_density: action.payload.id
      }
    }
    case MIN_DENSITY_VALUE: {
      return {
        ...state,
        min_density_value: action.payload.id
      }
    }
    case NORMAL_DENSITY: {
      return {
        ...state,
        normal_density: action.payload.id
      }
    }
    case NORMAL_DENSITY_VALUE: {
      return {
        ...state,
        normal_density_value: action.payload.id
      }
    }
    case DENSITY_SG: {
      return { 
        ...state, 
        density_sg: action.payload.id
      }
    }
    case DENSITY_SG_VALUE: {
      return { 
        ...state, 
        density_sg_value: action.payload.id
      }
    }
    case MAX_DENSITY: {
      return {
        ...state,
        max_density: action.payload.id
      }
    }
    case MAX_DENSITY_VALUE: {
      return {
        ...state,
        max_density_value: action.payload.id
      }
    }
    case UNITS_VISCOSITY: {
      return {
        ...state,
        units_viscosity: action.payload.id
      }
    }
    case MIN_VISCOSITY: {
      return {
        ...state,
        min_viscosity: action.payload.id
      }
    }
    case MIN_VISCOSITY_VALUE: {
      return {
        ...state,
        min_viscosity_value: action.payload.id
      }
    }
    case NORMAL_VISCOSITY: {
      return {
        ...state,
        normal_viscosity: action.payload.id
      }
    }
    case NORMAL_VISCOSITY_VALUE: {
      return {
        ...state,
        normal_viscosity_value: action.payload.id
      }
    }
    case MAX_VISCOSITY: {
      return {
        ...state,
        max_viscosity: action.payload.id
      }
    }
    case MAX_VISCOSITY_VALUE: {
      return {
        ...state,
        max_viscosity_value: action.payload.id
      }
    }
    case UNITS_VAPOR: {
      return {
        ...state,
        units_vapor: action.payload.id
      }
    }
    case MIN_VAPOR: {
      return {
        ...state,
        min_vapor: action.payload.id
      }
    }
    case MIN_VAPOR_VALUE: {
      return {
        ...state,
        min_vapor_value: action.payload.id
      }
    }
    case MAX_FLOW_ACC: {
      return {
        ...state,
        max_flow_acc: action.payload.id
      }
    }
    case UNITS_MAX_PRESSURE_DROP: {
      return {
        ...state,
        units_max_pressure_drop: action.payload.id
      }
    }
    case MAX_PRESSURE_DROP: {
      return {
        ...state,
        max_pressure_drop: action.payload.id
      }
    }
    case UNITS_SONIC_VELOCITY: {
      return {
        ...state,
        units_sonic_velocity: action.payload.id
      }
    }
    case SONIC_VELOCITY: {
      return {
        ...state,
        sonic_velocity: action.payload.id
      }
    }
    case SONIC_VELOCITY_MAP: {
      return {
        ...state,
        sonic_velocity_map: action.payload.id
      }
    }
    case SONIC_VELOCITY_VALUE: {
      return {
        ...state,
        sonic_velocity_value: action.payload.id
      }
    }
    case UNITS_PRESSURE_ATMOSPHERIC: {
      return {
        ...state,
        units_pressure_atmospheric: action.payload.id
      }
    }
    case PRESSURE_ATMOSPHERIC: {
      return {
        ...state,
        pressure_atmospheric: action.payload.id
      }
    }
    case UNITS_MINIMUM_ACCURACY: {
      return {
        ...state,
        units_minimum_accuracy: action.payload.id
      }
    }
    case UNITS_DENSITY_HEADING: {
      return {
        ...state,
        units_density_heading: action.payload.id
      }
    }
    case NON_NEWTONIAN_LIQUID: {
      return {
        ...state,
        non_newtonian_liquid: action.payload.id
      }
    }

    case REVERT_NON_NEWTONIAN_CHECKBOX_FLAG: {
      return {
        ...state,
        revert_non_newtonian_checkbox_flag: action.payload.id
      }
    }

    case NON_NEWTONIAN_LIQUID_FLAG: {
      return {
        ...state,
        non_newtonian_liquid_flag: action.payload.id
      }
    }

    case K_FLOW_CONSISTENCY_INDEX: {
      return {
        ...state,
        k_flow_consistency_index: action.payload.id
      }
    }
    case N_FLOW_BEHAVIOR: {
      return {
        ...state,
        n_flow_behavior: action.payload.id
      }
    }
    case COMPRESSIBILITY: {
      return {
        ...state,
        compressibility: action.payload.id
      }
    }

    case BASE_COMPRESSIBILITY: {
      return{ 
        ...state, 
        base_compressibility: action.payload.id
      }
    }

    case BASE_COMPRESSIBILITY_ERROR:{
      return {
        ...state,
        base_compressibility_error: action.payload.id
      }
    }
  
    case GAS_CUSTOM_COMPRESSIBILITY_ERROR: {
      return {
        ...state,
        gas_custom_compressibility_error: action.payload.id
      }
    }
    case SPECIFIC_GRAVITY: {
      return {
        ...state,
        specific_gravity: action.payload.id
      }
    }
    case MOLECULAR_WEIGHT: {
      return {
        ...state,
        molecular_weight: action.payload.id
      }
    }
    
    case MOLECULAR_WEIGHT_ERROR: {
      return {
        ...state, 
        molecular_weight_error: action.payload.id
      }
    }

    case SPECIFIC_GRAVITY_ERROR: {
      return{ 
        ...state, 
        specific_gravity_error: action.payload.id
      }
    }

    case MINIMUM_DENSITY_ERROR: {
      return {
        ...state,
        minimum_density_error: action.payload.id
      }
    }
    case NORMAL_DENSITY_ERROR: {
      return {
        ...state,
        normal_density_error: action.payload.id
      }
    }
    case MAXIMUM_DENSITY_ERROR: {
      return {
        ...state,
        maximum_density_error: action.payload.id
      }
    }
    case NORMAL_DENSITY_MIN_MAX_ERRORS:{
      return {
        ...state,
        normal_density_min_max_errors: action.payload.id
      }
    }
    case MAX_DENSITY_MIN_MAX_ERRORS:{
      return {
        ...state, 
        max_density_min_max_errors: action.payload.id
      }
    }
    case NORMAL_DENSITY_TYPE_ERRRORS: {
      return {
        ...state,
        normal_density_type_errors: action.payload.id
      }
    }
    case MAX_DENSITY_TYPE_ERRORS:{
      return{
        ...state,
        max_density_type_errors: action.payload.id
      }
    }
    case NORMAL_VISCOSITY_ERROR: {
      return {
        ...state,
        normal_viscosity_error: action.payload.id
      }
    }

    case MIN_VISCOSITY_CONVERTION_ERROR: {
      return {
        ...state,
        min_viscosity_convertion_error:action.payload.id

      }
    }

    case NORMAL_VISCOSITY_CONVERTION_ERROR: {
      return {
        ...state,
        normal_viscosity_convertion_error:action.payload.id

      }
    }

    case MAX_VISCOSITY_CONVERTION_ERROR: {
      return {
        ...state,
        max_viscosity_convertion_error:action.payload.id

      }
    }
    case MAXIMUM_VISCOSITY_ERROR: {
      return {
        ...state,
        maximum_viscosity_error: action.payload.id
      }
    }

    case NORMAL_VISCOSITY_MIN_MAX_ERRORS: {
      return {
        ...state, 
        normal_viscosity_min_max_errors: action.payload.id
      }
    }

    case MAX_VISCOSITY_MIN_MAX_ERRORS: {
      return {
        ...state, 
        max_viscosity_min_max_errors: action.payload.id
      }
    }

    case NORMAL_VISCOSITY_TYPE_ERRORS: {
      return {
        ...state, 
        normal_viscosity_type_errors: action.payload.id
      }
    }

    case MAX_VISCOSITY_TYPE_ERRORS: {
      return { 
        ...state,
        max_viscosity_type_errors: action.payload.id
      }
    }

    case UNITS_GAS_PRESSURE: {
      return {
        ...state,
        units_gas_pressure: action.payload.id
      }
    }
    case GAS_PRESSURE: {
      return {
        ...state,
        gas_pressure: action.payload.id
      }
    }

    case GAS_PRESSURE_ERROR: {
      return {
        ...state,
        gas_pressure_error: action.payload.id
      }
    }

    case UNITS_GAS_TEMPERATURE: {
      return {
        ...state,
        units_gas_temperature: action.payload.id
      }
    }
    case GAS_TEMPERATURE: {
      return {
        ...state,
        gas_temperature: action.payload.id
      }
    }

    case GAS_TEMPERATURE_ERROR: {
      return {
        ...state,
        gas_temperature_error: action.payload.id
      }
    }

    case UNITS_GAS_DENSITY: {
      return {
        ...state,
        units_gas_density: action.payload.id
      }
    }

    case UNITS_GAS_DENSITY_SG: {
      return {
        ...state,
        units_gas_density_sg: action.payload.id
      }
    }
    
    case GAS_DENSITY: {
      return {
        ...state,
        gas_density: action.payload.id
      }
    }
    case GAS_DENSITY_VALUE: {
      return {
        ...state,
        gas_density_value: action.payload.id
      }
    }
    case RESET_RESIZING_FLAG: {
      return {
        ...state,
        reset_resizing_flag: action.payload.id
      }
    }

    case RESIZING_DISCARD_FLAG: {
      return{
        ...state, 
        resizing_discard_flag: action.payload.id
      }
    }

    case ALLOW_PREF_BASE_REF: {
      return{
        ...state,
        allow_pref_base_ref: action.payload.id
      }
    }
    
    case BASE_REFERENCE_CONDITIONS: {
      return {
        ...state,
        base_reference_conditions: action.payload.id
      }
    }
    case CALCULATE_DENSITY_VARIATION: {
      return {
        ...state,
        calculate_density_variation: action.payload.id
      }
    }
    case FLOW_RATE_MIN_CLASS_LIST: {
      return {
        ...state,
        flow_rate_min_class_list: action.payload.id
      }
    }
    case FLOW_RATE_NORM_CLASS_LIST: {
      return {
        ...state,
        flow_rate_norm_class_list: action.payload.id
      }
    }
    case FLOW_RATE_MAX_CLASS_LIST: {
      return {
        ...state,
        flow_rate_max_class_list: action.payload.id
      }
    }
    case FLOW_RATE_FULL_CLASS_LIST: {
      return {
        ...state,
        flow_rate_full_class_list: action.payload.id
      }
    }
    case FLOW_RATE_MIN_ERRORS: {
      return {
        ...state,
        flow_rate_min_errors: action.payload.id
      }
    }
    case FLOW_RATE_NORM_ERRORS: {
      return {
        ...state,
        flow_rate_norm_errors: action.payload.id
      }
    }
    case FLOW_RATE_MAX_ERRORS: {
      return {
        ...state,
        flow_rate_max_errors: action.payload.id
      }
    }
    case FLOW_RATE_FULL_ERRORS: {
      return {
        ...state,
        flow_rate_full_errors: action.payload.id
      }
    }
    case FLOW_RATE_GAS_DROPDOWN_ARRAY: {
      return {
        ...state,
        flow_rate_gas_dropdown_array: action.payload.id
      }
    }
    case FLOW_RATE_DROPDOWN_ARRAY: {
      return {
        ...state,
        flow_rate_dropdown_array: action.payload.id
      }
    }
    case LINE_PRESS_MIN_CLASS_LIST: {
      return {
        ...state,
        line_press_min_class_list: action.payload.id
      }
    }
    case LINE_PRESS_NORM_CLASS_LIST: {
      return {
        ...state,
        line_press_norm_class_list: action.payload.id
      }
    }
    case LINE_PRESS_MAX_CLASS_LIST: {
      return {
        ...state,
        line_press_max_class_list: action.payload.id
      }
    }
    case LINE_PRESS_FULL_CLASS_LIST: {
      return {
        ...state,
        line_press_full_class_list: action.payload.id
      }
    }
    case LINE_PRESS_MIN_ERRORS: {
      return {
        ...state,
        line_press_min_errors: action.payload.id
      }
    }
    case LINE_PRESS_NORM_ERRORS: {
      return {
        ...state,
        line_press_norm_errors: action.payload.id
      }
    }
    case LINE_PRESS_MAX_ERRORS: {
      return {
        ...state,
        line_press_max_errors: action.payload.id
      }
    }
    case LINE_PRESS_FULL_ERRORS: {
      return {
        ...state,
        line_press_full_errors: action.payload.id
      }
    }
    case LINE_PRESS_DROPDOWN_ARRAY: {
      return {
        ...state,
        line_press_dropdown_array: action.payload.id
      }
    }
    case LINE_PRESS_ROW_PSGI:{
      return {
        ...state,
        line_press_row_psig: action.payload.id
      }
    }
    case SET_CALC_FLAG:{
      return {
        ...state,
        calculation_call: action.payload.id
      }
    }
    case SIZING_RESULTS_CLICKED:{
      return {
        ...state,
        sizing_results_clicked: action.payload.id
      }
    }
    case TEMP_ROW_F:{
      return { 
        ...state, 
        temp_row_f: action.payload.id
      }
    }
    case TEMPERATURE_MIN_CLASS_LIST: {
      return {
        ...state,
        temperature_min_class_list: action.payload.id
      }
    }
    case TEMPERATURE_NORM_CLASS_LIST: {
      return {
        ...state,
        temperature_norm_class_list: action.payload.id
      }
    }
    case TEMPERATURE_MAX_CLASS_LIST: {
      return {
        ...state,
        temperature_max_class_list: action.payload.id
      }
    }
    case TEMPERATURE_FULL_CLASS_LIST: {
      return {
        ...state,
        temperature_full_class_list: action.payload.id
      }
    }
    case TEMPERATURE_MIN_ERRORS: {
      return {
        ...state,
        temperature_min_errors: action.payload.id
      }
    }
    case TEMPERATURE_NORM_ERRORS: {
      return {
        ...state,
        temperature_norm_errors: action.payload.id
      }
    }
    case TEMPERATURE_MAX_ERRORS: {
      return {
        ...state,
        temperature_max_errors: action.payload.id
      }
    }
    case TEMPERATURE_FULL_ERRORS: {
      return {
        ...state,
        temperature_full_errors: action.payload.id
      }
    }
    case TEMPERATURE_DROPDOWN_ARRAY: {
      return {
        ...state,
        temperature_dropdown_array: action.payload.id
      }
    }
    case AMBIENT_TEMPERATURE_MIN_CLASS_LIST: {
      return {
        ...state,
        ambient_temperature_min_class_list: action.payload.id
      }
    }
    case AMBIENT_TEMPERATURE_NORM_CLASS_LIST: {
      return {
        ...state,
        ambient_temperature_norm_class_list: action.payload.id
      }
    }
    case AMBIENT_TEMPERATURE_MAX_CLASS_LIST: {
      return {
        ...state,
        ambient_temperature_max_class_list: action.payload.id
      }
    }
    case AMBIENT_TEMPERATURE_FULL_CLASS_LIST: {
      return {
        ...state,
        ambient_temperature_full_class_list: action.payload.id
      }
    }
    case AMBIENT_TEMPERATURE_MIN_ERRORS: {
      return {
        ...state,
        ambient_temperature_min_errors: action.payload.id
      }
    }
    case AMBIENT_TEMPERATURE_NORM_ERRORS: {
      return {
        ...state,
        ambient_temperature_norm_errors: action.payload.id
      }
    }
    case AMBIENT_TEMPERATURE_MAX_ERRORS: {
      return {
        ...state,
        ambient_temperature_max_errors: action.payload.id
      }
    }
    case AMBIENT_TEMPERATURE_FULL_ERRORS: {
      return {
        ...state,
        ambient_temperature_full_errors: action.payload.id
      }
    }
    case AMBIENT_TEMPERATURE_DROPDOWN_ARRAY: {
      return {
        ...state,
        ambient_temperature_dropdown_array: action.payload.id
      }
    }
    case REQUIRED_FIELDS: {
      return {
        ...state,
        required_fields: action.payload.id
      }
    }

    case CLEAR_PROC_VARIABLES_VALUES:{
      return {
        ...state,
        min_flow_rate: '',
        normal_flow_rate: '',
        max_flow_rate: '',
        full_flow_rate: '',
        min_line_pressure: '',
        normal_line_pressure: '',
        max_line_pressure: '',
        full_line_pressure: '',
        min_temperature: '',
        normal_temperature: '',
        max_temperature: '',
        full_temperature: '',
        min_ambient: '',
        normal_ambient: '',
        max_ambient: '',
        full_ambient: '',
      }
    }

    case CLEAR_PROC_VARIABLES: {
      return initialState
    }
    
    default: {
      return state;
    }
  }
}