export const initialState = {
    productImageUrl: [],
    imageStatus: true,
}

export const Reducer = (state,action) => { 
    switch (action.type) { 
      case 'productImageUrl':
        return { ...state, productImageUrl: action.payload };      
        case 'imageStatus':
        return { ...state, imageStatus: action.payload };  
        default: 
        throw new Error("Unexpected action", );
  }
}; 