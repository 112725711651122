import React, { useState } from 'react';
import '../../styles/App.scss';
import { Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import {
  requestSizingResults,
  displaySizingResults,
  selectBasicPage,
  advancedLayout,
} from '../../redux/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';
const FooterNavPanel = (props) => {
  let history = useHistory();

  const pages = [
    'measurementType',
    'equipmentSelection',
    'applicationRequirements',
    'fluidSelection',
    'processVariables',
  ];

  const [hideShowPrev, setShowHidePrev] = useState('hide');
  const [hideShowNext, setShowHideNext] = useState('show');

  const sizingResults = () => {
    props.advancedLayout(!props.advanced_layout);
    history.push('/advanced');
    props.requestSizingResults(true);
    props.displaySizingResults(true);
  };

  const selectPanel = (direction) => {
    let index = pages.findIndex((page) => page === props.basic_page);
    if (direction === 'next' && index < pages.length - 1) {
      index = index + 1;
    } else if (direction === 'prev' && index > 0) {
      index = index - 1;
    }
    if (index === 0) {
      setShowHidePrev('hide');
      setShowHideNext('show');
    } else if (index === pages.length - 1) {
      setShowHidePrev('show');
      setShowHideNext('hide');
    } else {
      setShowHidePrev('show');
      setShowHideNext('show');
    }
    props.selectBasicPage(pages[index]);
  };

  return (
    <Row className='footer-nav-panel'>
      <Col xl={12} md={12}>
        <span className={`back-nav-button ${hideShowPrev}`}>
          <CustomButton
            classNames='btn btn-green btn-nav left-arrow-nav btn-arrow'
            label={<CustomTranslation data='XXSPECIFY_MMI_MC_BTN_PREV' />}
            size='lg'
            onClick={() => selectPanel('prev')}
            arrow='left-nav'
          />
        </span>
        <span className={`next-nav-button ${hideShowNext}`}>
          <CustomButton
            classNames='btn btn-green btn-nav'
            label={<CustomTranslation data='XXSPECIFY_MMI_MC_BTN_NEXT' />}
            size='lg'
            onClick={() => selectPanel('next')}
          />
        </span>
        <span className='sizing-results-button'>
          <CustomButton
            classNames='btn btn-nav btn-green'
            label={<CustomTranslation data='XXSPECIFY_MMI_SR_BTN_SIZ_RES' />}
            onClick={() => sizingResults()}
            size='lg'
          />
        </span>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  basic_page: state.BasicPageRightNavList.basic_page,
  advanced_layout: state.Layout.advanced_layout,
});

export default connect(mapStateToProps, {
  requestSizingResults,
  displaySizingResults,
  selectBasicPage,
  advancedLayout,
})(FooterNavPanel);
