/* eslint-disable no-loop-func */
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner, Modal } from "react-bootstrap";
import axios from "axios";
import "../../styles/App.scss";
import "./Report.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import { CustomInputField } from "../../components/CustomInputField/CustomInputField";
import { CustomDropdown } from "../../components/CustomDropdown/CustomDropdown";
import {
  equipmentSelection,
  translationData,
  selectBasicPage,
  allowPrefBaseRef,
  compositionMethodFlag,
  savedPreferencesFlag
} from "../../redux/actions";
import CustomTranslation from "../../components/CustomTranslation/CustomTranslation";
import { CustomDropdownReport } from "../../components/CustomDropdown/CustomDropdownReport";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { GET_REFERENCE_IDS } from "../../constants/constants";
import PDFMerger from "pdf-merger-js";
import { jsPDF } from "jspdf";

const Report = (props) => {
  const [report_type, setReportType] = useState("Rollup");
  const [report_array, setReportArray] = useState(["Rollup", "FCS", "CDS"]);
  const [ref_id_list, setRefIdArray] = useState([]);
  const [language, setLanguage] = useState("US");
  const [email, setEmail] = useState(props.email);
  const [email_error, setEmailError] = useState(false);
  const [ref_error, setRefError] = useState(false);
  const [quote_error, setQuoteError] = useState(false);
  const [disable_button, setDisableButton] = useState(false);
  const [quote_number, setQuoteNumber] = useState("");
  const [ref_id, setRefId] = useState("");
  const [ref_id_value, setRefIdValue] = useState("");
  const [sizing_ref_id, setSizingRefId] = useState("");
  const [ref_array, setRefArray] = useState([]);
  const [downloadLoad, setDownloadload] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  useEffect(() => {
    props.savedPreferencesFlag(false)
  }, [email]);
  props.allowPrefBaseRef(false);
  props.compositionMethodFlag(true);
  const handleReportType = (key) => {
    setReportType(report_array[key ? key : 0]);
  };

  const handleLanguage = (key) => {
    setLanguage(props.language_list[key ? key : 0]["value"]);
  };
  const handleRefId = (key) => {
    setRefIdValue(ref_id_list[key ? key : 0]["value"]);
    setRefId(ref_id_list[key ? key : 0]["label"]);
  };
  const handleEmailChange = (key) => {
    setEmail(key.target.value);
  };
  const handleBlur = (key) => {
    if (key.target.value !== "") {
      function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
      }
      setEmailError(
        email.search(new RegExp("Emerson.com", "i")) !== -1
          ? validateEmail(email)
            ? false
            : true
          : true
      );
    } else {
      setEmailError(false);
    }
  };
  const handleQuoteNumberChange = (key) => {
    setQuoteError(false);
    setQuoteNumber(key.target.value);
    if (key.target.value.length > 6) {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/mmipa/get_reference_ids/`,
        headers: {
          "Content-Type": "application/json",
        },
        xsrfHeaderName: "X-CSRFToken",
        xsrfCookieName: "csrftoken",
        data: { 
          quote_number: parseInt(key.target.value),
          report_type : report_type 
        },
      })
        .then(async (response) => {
          let result = [{ value: "All", label: "All" }];
          let result1 = [];
          response.data.Data.map((data) => {
            if(data.transaction_id !== null){
              result1.push(data.transaction_id);
            }else if(data.ui_line_number !== null){
              result1.push(data.ui_line_number + "-" + data.model_number + "-" + data.report_technology);
            }
          });
          setRefArray(result1);
          response.data.Data.map((data) => {
            result.push({
              ...data,
              value: data.transaction_id,
              label:data.transaction_id === null ?  
                data.ui_line_number + "-" + data.model_number + "-" + data.report_technology :
                data.transaction_id + "-" + data.model_name + "-" + data.sizing_name
            });
          });
          setRefId(result[0].label);
          setRefIdValue(result[0].value);
          setRefIdArray(result);
        })
        .catch(function (error) {
          console.log("error", error);
        });
    } else {
      setRefId("");
      setRefIdValue("");
      setRefIdArray([]);
    }
  };
  const downloadApi = async (
    result,
    url,
    ref_id_value,
    language,
    email,
    report_type,
    quote_number
  ) => {
    let data;
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}${url}`,
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "arraybuffer",
      xsrfHeaderName: "X-CSRFToken",
      xsrfCookieName: "csrftoken",
      data:
      (report_type === 'Rollup'|| report_type === 'CDS') && ref_id_value === 'All'
          ? {
              format: "PDF",
              transaction_id: result,
              language: language,
              email_id: email,
              report_type: report_type.toUpperCase(),
              quote_number: parseInt(quote_number),
            }
          : {
              format: "PDF",
              transaction_id: ref_id_value[0] == null ? [ref_id] : ref_id_value,
              language: language,
              email_id: email,
              report_type: report_type.toUpperCase(),
              quote_number: parseInt(quote_number),
            },
    })
      .then(async (response) => {
        //let res = await JSON.parse(new TextDecoder().decode(response.data));
        data = response;
      })
      .catch(async function (error) {
        console.log("error", error);
        setDownloadload(false);
        await setDisableButton(false);
      });
    return data;
  };
  const handleRefIdChange = (key) => {
    setRefError(false);
    setSizingRefId(key.target.value);
  };

  const sendReport = async (report, email) => {
    const fetchData = async () => {
      let formdata = new FormData();
      await formdata.append("recipient", email);
      await formdata.append("pdf_filename", report);
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/mmipa/send_sizing_email/`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        xsrfHeaderName: "X-CSRFToken",
        xsrfCookieName: "csrftoken",
        data: formdata,
      })
        .then(async (response) => {
          console.log(response);
        })
        .catch(function (error) {
          console.log("error", error);
        });
    };
    await fetchData();
  };
  const sendEmail = async (array) => {
    let result = [];
    let result1 = [];
    let result_final = [];
    let data = array;
    for (let i = 0; i < array.length; i + 5) {
      result.push(data.splice(i, i + 5));
    }
    await setDisableButton(true);
    let url =
      report_type === "Rollup"
        ? "/mmipa/download_rollup_report/"
        : report_type === "CDS"
        ? "/mmipa/download_cds_report/"
        : "/mmipa/download_fcs_report/";

    const fetchData = async () => {
      if (
        (report_type === "CDS" || report_type === "Rollup") &&
        ref_id_value === "All"
      ) {
        for (let j = 0; j < result.length; j++) {
          let response = await downloadApi(
            [...result[j]],
            url,
            ref_id_value,
            language,
            email,
            report_type,
            quote_number
          );
          let res = await JSON.parse(new TextDecoder().decode(response.data));
          await result_final.push(...res.data);
        }
      } else if (
        ref_id_value !== "All" &&
        (report_type === "CDS" || report_type === "Rollup")
      ) {
        let response = await downloadApi(
          "",
          url,
          [ref_id_value],
          language,
          email,
          report_type,
          quote_number
        );
        let res = await JSON.parse(new TextDecoder().decode(response.data));
        result_final.push(...res.data);
      } else {
        let response = await downloadApi(
          "",
          url,
          sizing_ref_id,
          language,
          email,
          report_type,
          quote_number
        );
        let res = await JSON.parse(new TextDecoder().decode(response.data));
        let sizingName = res.data.sizing_name;
        sizingName = sizingName.replace(/[^a-zA-Z0-9]/g, "_");
        res.data.sizing_name = sizingName;
        result_final.push(res.data);
      }
    };

    if (
      quote_number === "" &&
      (report_type === "CDS" || report_type === "Rollup")
    ) {
      setQuoteError(true);
    } else if (sizing_ref_id === "" && report_type === "FCS") {
      setRefError(true);
    } else if (email_error || email === "") {
      setEmailError(true);
    } else {
      setDownloadload(true);
      setShow(true);
      await fetchData();
      if ((report_type === "Rollup" || report_type === "CDS")) {
        result_final.map((c) => {
          let data = ref_id_list.find(
            ({ transaction_id , label}) => transaction_id === c.ref_id || label === c.ref_id
          );
          c.s_no = data.ref_sequence;
        });
        result_final.sort((a, b) => {
          return a.s_no - b.s_no;
        });
        
        let files = [];
        var y1 = 60;
        var y2 = 60;
        var x = 50;
        var pdf = new jsPDF("p", "pt", "letter");

        pdf.setFontSize(16);
        pdf.setFont("Arial, serif", "bold");
        pdf.text(10, 20, "Emerson Sizing Summary ");
        pdf.setFontSize(12);
        pdf.setFont("times", "normal");
        pdf.text(
          new Date().toISOString().slice(0, 10),
          600,
          20,
          null,
          null,
          "right"
        );
        result_final.map(async (file, index) => {
          if (index % 45 === 1 && index !== 1) {
            pdf.addPage();
            y1 = 60;
            y2 = 60;
          }
          y1 = +y1 + 15;
          pdf.setTextColor("blue");
          var splitStr1 = file.sizing_name + "_" + file.model_name;
          if (splitStr1.length <= 46) {
            pdf.textWithLink(splitStr1, x, y1, { pageNumber: 1 });
          } else if (splitStr1.length > 46 && splitStr1.length <= 50) {
            pdf.textWithLink(
              file.sizing_name + "_" + file.model_name,
              x,
              y1,
              { pageNumber: 1 }
            );
            y1 = y1 + 15;
          } else if (splitStr1.length > 50 && splitStr1.length <= 54) {
            pdf.textWithLink(
              file.sizing_name + "\n _" + file.model_name,
              x,
              y1,
              { pageNumber: 1 }
            );
            y1 = y1 + 15;
          } else {
            pdf.textWithLink(
              file.sizing_name.slice(0, 45) +
                "\n" +
                file.sizing_name.slice(45) +
                file.model_name +
                "_FCS",
              x,
              y1,
              { pageNumber: 1 }
            );
            y1 = y1 + 15;
          }

          y2 = +y2 + 15;
          pdf.setTextColor("blue");
          var splitStr2 = file.sizing_name + "_" + file.model_name + "_CDS";
          if (splitStr2.length <= 46) {
            pdf.textWithLink(splitStr2, 275 + x, y2, { pageNumber: 1 });
          } else if (splitStr1.length > 46 && splitStr1.length <= 50) {
            pdf.textWithLink(
              file.sizing_name + "_" + file.model_name + "\n _CDS",
              275 + x,
              y2,
              { pageNumber: 1 }
            );
            y2 = y2 + 15;
          } else if (splitStr1.length > 50 && splitStr1.length <= 54) {
            pdf.textWithLink(
              file.sizing_name + "\n_" + file.model_name + "_CDS",
              275 + x,
              y2,
              { pageNumber: 1 }
            );
            y2 = y2 + 15;
          } else {
            pdf.textWithLink(
              file.sizing_name.slice(0, 45) +
                "\n" +
                file.sizing_name.slice(45) +
                file.model_name +
                "_CDS",
              275 + x,
              y2,
              { pageNumber: 1 }
            );
            y2 = y2 + 15;
          }

          pdf.autoTable({
            theme: "plain",
            startY: 35,
            columnStyles: {
              0: { halign: "left", cellWidth: 300 },
            },
            head: [
              [
                "Flow Calculation Summary Report Index",
                "Configuration Data Sheet Report Index",
              ],
            ],
            headStyles: {
              fontSize: 14,
              font: "times",
              fontStyle: "plain",
              textColor: "black",
            },
          });
        });

        var file5 = new Blob([pdf.output("blob")], { type: "application/pdf" });
        if(ref_id_value === "All") {
          files.push(file5);
        }

        result_final.map(async (file) => {
          let data = Uint8Array.from(atob(file.pdf_data), (c) =>
            c.charCodeAt(0)
          );
          let blob = new Blob([data], { type: "application/pdf" });
          await files.push(blob);
        });
        const merger = new PDFMerger();

        for (const file of files) {
          await merger.add(file);
        }

        const mergedPdf = await merger.saveAsBlob();
        let data = report_type === "Rollup" ? "Rollup.pdf" : "CDS.pdf";
        var file = new Blob([mergedPdf], { type: "application/pdf" });
        let report = new File([file], data);
        const url = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.download = report_type === "Rollup" ? "Rollup" : "CDS"
        link.href = url;
        await sendReport(report, email);
        link.click();
        setDownloadload(false);
        await setDisableButton(false);
      } else {
        let data = Uint8Array.from(atob(result_final[0].pdf_data), (c) =>
          c.charCodeAt(0)
        );
        var file = new Blob([data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        let report = new File([file], "FCS.pdf");
        await sendReport(report, email);
        var link = document.createElement("a");
        link.download = `${
          result_final[0].sizing_name +
          "_" +
          result_final[0].model_name +
          "_FCS"
        }`;
        link.href = fileURL;
        await link.click();
        setDownloadload(false);
        await setDisableButton(false);
      }
    }
    ref_id_list.map((data) => {
      let res = data.transaction_id ? result1.push(data.transaction_id) : "";
    });
    setRefArray(result1);
  };

  const dropdowns = (
    title,
    id,
    text,
    dropdownArray,
    eventHandler,
    disabled,
    required,
    error
  ) => {
    return (
      <Col sm={12}>
        <CustomDropdownReport
          title={title}
          id={id}
          text={text}
          dropdownArray={dropdownArray}
          eventHandler={eventHandler}
          disabled={disabled}
          borderRequired={required}
          borderError={error}
        />
      </Col>
    );
  };

  return (
    <Container className="advanced-page">
      <Modal centered show={show} onHide={handleClose} animation={false}>
        <Modal.Header className="modal-header-fpa" closeButton>
          <Modal.Title className="modal-title-confirmation">
            <CustomTranslation data="XXSPECIFY_MMI_PR_LBL_MESSAGE" />{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-fpa">
          <p className="info-text">
            {<CustomTranslation data="XXSPECIFY_MMI_MC_LBL_RPT_DWN" />}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            classNames="btn btn-close-modal btn-green"
            label="OK"
            onClick={handleClose}
            arrow="right"
            size="lg"
          />
        </Modal.Footer>
      </Modal>
      <Row>
        <Col md={12} sm={12} className="col-background">
          <div className="fluid-selection">
            <div className="title">
              <CustomTranslation data="XXSPECIFY_MMI_MC_LBL_MSG_HD_TP" />
            </div>
            <Container className="selection-container">
              <h5 className="update-report">
                <CustomTranslation data="XXSPECIFY_MMI_MC_LBL_MSG_HD" />
              </h5>
              <Row>
                <Col md={12} sm={12} xs={12}>
                  <Row>
                    <Col md={12} sm={12} xs={11}>
                      <CustomDropdown
                        clear={props.clear_all_field}
                        title={
                          <CustomTranslation data="XXSPECIFY_MMI_MC_LBL_RPT_TPE" />
                        }
                        id={"fluid-state-dropdown"}
                        text={report_type}
                        dropdownArray={report_array}
                        eventHandler={handleReportType}
                        active={report_type}
                        width={275}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} sm={10} xs={11}>
                      {report_type === "Rollup" || report_type === "CDS" ? (
                        <CustomInputField
                          name={
                            <CustomTranslation data="XXSPECIFY_MMI_MC_LBL_QUOTE" />
                          }
                          size="lg"
                          disabled={false}
                          borderRequired={true}
                          borderError={quote_error}
                          id="custom-fluid-name-inputfield"
                          labelClassName="custom-fluid-label"
                          fieldClassName="custom-fluid-fieldname"
                          onChange={handleQuoteNumberChange}
                          value={quote_number}
                          errorText="Quote number Required"
                          isError={quote_error}
                        />
                      ) : (
                        <CustomInputField
                          name={
                            <CustomTranslation data="XXSPECIFY_MMI_MC_LBL_SREF_ID" />
                          }
                          size="lg"
                          disabled={false}
                          borderRequired={true}
                          borderError={ref_error}
                          id="custom-fluid-name-inputfield"
                          labelClassName="custom-fluid-label"
                          fieldClassName="custom-fluid-fieldname"
                          onChange={handleRefIdChange}
                          value={sizing_ref_id}
                          errorText="Sizing Ref Id Required"
                          isError={ref_error}
                        />
                      )}
                    </Col>
                  </Row>
                  {report_type === "Rollup" || report_type === "CDS" ? (
                    <Row>
                      <Col md={12} sm={12} xs={11}>
                        <CustomDropdown
                          clear={props.clear_all_field}
                          title={"QUOTE LINE"}
                          id={"fluid-state-dropdown"}
                          number={true}
                          text={ref_id}
                          dropdownArray={ref_id_list}
                          eventHandler={handleRefId}
                          active={ref_id}
                          width={275}
                        />
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  <Row>
                    <Col md={12} sm={10} xs={11}>
                      <CustomInputField
                        name={
                          <CustomTranslation data="XXSPECIFY_MMI_MC_LBL_EMAIL" />
                        }
                        size="lg"
                        disabled={false}
                        borderRequired={false}
                        borderError={email_error}
                        id="custom-fluid-name-inputfield"
                        labelClassName="custom-fluid-label"
                        fieldClassName="custom-fluid-fieldname"
                        onChange={handleEmailChange}
                        onBlur={(e) => handleBlur(e)}
                        errorText="Emerson Email Required"
                        value={email}
                        isError={email_error}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} sm={12} xs={11}>
                      <CustomDropdown
                        clear={props.clear_all_field}
                        title={
                          <CustomTranslation data="XXSPECIFY_MMI_MC_LBL_LANG" />
                        }
                        id={"fluid-state-dropdown"}
                        text={language}
                        dropdownArray={props.language_list}
                        eventHandler={handleLanguage}
                        active={language}
                        width={275}
                      />
                    </Col>
                  </Row>
                </Col>
                <Row>
                  <Col md={12} sm={12}>
                    <CustomButton
                      classNames="btn btn-green btn-my-sizings btn-search btn-email"
                      disabled={disable_button}
                      label={
                        downloadLoad ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <CustomTranslation data="XXSPECIFY_MMI_EMAIL_REPORT" />
                          </>
                        ) : (
                          <CustomTranslation data="XXSPECIFY_MMI_EMAIL_REPORT" />
                        )
                      }
                      onClick={() => sendEmail(ref_array)}
                      size="lg"
                    />
                  </Col>
                </Row>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  select_technology: state.MeasurementType.select_technology,
  equipment_selection: state.EquipmentSelection.equipment_selection,
  translation_data: state.TranslationData.translation_data,
  get_sizing_data: state.MySizings.get_sizing_data,
  language_list: state.LanguageList.language_list,
  email: state.AppHeader.email,
});

export default connect(mapStateToProps, {
  equipmentSelection,
  translationData,
  selectBasicPage,
  allowPrefBaseRef,
  compositionMethodFlag,
  savedPreferencesFlag
})(Report);