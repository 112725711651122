import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import "../styles/App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import SelectTechnology from "./SelectTechnology";
import { connect } from "react-redux";
import {
  toggleFlow,
  toggleDensity,
  toggleViscosity,
  selectTechnology,
  defaultTechnology,
  equipmentSelection,
  toggleDisplayAllSensorsNoFilters,
  toggleInLine,
  toggleFlowThroughChamberSlipstream,
  toggleTank,
  toggleHygienicApplication,
  toggleHighProcessNoiseSlurry,
  toggleFlanged,
  toggleWafer,
  toggleReducer,
  toggleDual,
  toggleHygienic,
  toggleQuad,
  lineSize,
  processLineSize,
  scheduleUnits,
  productFamily,
  triggerLevel,
  fluidState,
  fluidSource,
  pickFluidsFromDB,
  conductivityDropdown,
  fluidService,
  sizingResultsClicked,
  tooglePressure,
  measurementTypeContainerClass,
} from "../redux/actions";
import { CustomCheckbox } from "../components/CustomCheckbox/CustomCheckbox";

import CustomTranslationText from "../components/CustomTranslation/CustomTranslationText";
import CustomTranslation from "../components/CustomTranslation/CustomTranslation";

const MeasurementType = (props) => {
  let flowDisabled = false;
  let viscosityDisabled = true;

  if (props.flow) {
    flowDisabled = false;
    viscosityDisabled = true;
    props.measurementTypeContainerClass("selection-container");
  }
  if (!props.flow) {
    viscosityDisabled = false;
  }
  if (props.viscosity) {
    flowDisabled = true;
    viscosityDisabled = false;
    props.measurementTypeContainerClass("selection-container");
  }
  if (!props.viscosity) {
    flowDisabled = false;
  }
  if (props.density) {
    props.measurementTypeContainerClass("selection-container");
  }

  const resetEquip = () => {
    props.equipmentSelection();
  };

  const toggleFlow = (checked) => {
    clearCheckboxes();
    clearDropdowns();
    props.toggleFlow(checked);
    props.defaultTechnology(true);
    resetEquip();
  };
  useEffect(() => {
    if (Object.keys(props.get_sizing_data).length > 0) {
      props.sizingResultsClicked(true);
      let data = props.get_sizing_data[0];
      if (Object.keys(props.get_sizing_data).length > 0) {
        if (data.massflowtomeasure === "Y" && data.density_msurmnt_flag === "N" && data.viscositytomeasure === "N") {
          clearCheckboxes();
          clearDropdowns();
          props.toggleFlow(true);
          resetEquip();
          props.toggleDensity(false);
          props.toggleViscosity(false);
          let result = data.coriolis === "Y" ? props.defaultTechnology(true) : props.defaultTechnology(false);
        } else if (data.density_msurmnt_flag === "Y" && data.massflowtomeasure === "Y") {
          toggleDensity(true);
          toggleFlow(true);
          toggleViscosity(false);
        } else if (data.density_msurmnt_flag === "Y" && data.viscositytomeasure === "Y") {
          toggleDensity(true);
          toggleFlow(false);
          toggleViscosity(true);
        } else if (data.density_msurmnt_flag === "Y") {
          toggleDensity(true);
          toggleViscosity(false);
          toggleFlow(false);
        } else if (data.viscositytomeasure === "Y") {
          toggleViscosity(true);
          toggleFlow(false);
          toggleDensity(false);
        }
      }

      let techonogy =
        data.coriolis === "Y"
          ? "Coriolis"
          : data.vortex === "Y"
          ? "Vortex"
          : data.mag === "Y"
          ? "Magnetic"
          : data.density === "Y"
          ? "Density"
          : data.viscosity === "Y"
          ? "Viscosity"
          : "";
      props.selectTechnology(techonogy);
    }
  }, [props.get_sizing_data]);
  useEffect(() => {
    if (props.query_params.prdType && props.query_params.from_page === "PDP") {
      let data = props.query_params.prdType;

      const results =
        data === "COR"
          ? (clearCheckboxes(), clearDropdowns(), props.toggleFlow(true), resetEquip(), props.toggleDensity(false), props.toggleViscosity(false))
          : data === "DD" && data === "Y"
          ? (toggleDensity(true), toggleFlow(false), toggleViscosity(true))
          : data === "DD"
          ? (toggleDensity(true), toggleFlow(false))
          : data === "VV"
          ? (toggleViscosity(true), toggleFlow(false))
          : "";
      let techonogy =
        data === "COR"
          ? "Coriolis"
          : data === "VOR"
          ? "Vortex"
          : data === "MAG"
          ? "Magnetic"
          : data === "DD"
          ? "Density"
          : data === "VV"
          ? "Viscosity"
          : "";
      data === "COR" ? props.defaultTechnology(true) : props.defaultTechnology(false);
      props.selectTechnology(techonogy);
    } else {
      if (!props.fluid_selection_section.flow) {
        if (!Object.keys(props.get_sizing_data).length > 0) {
          props.toggleFlow(false);
        }
      }
    }
  }, []);
  const toggleDensity = (checked) => {
    clearCheckboxes();
    clearDropdowns();
    props.toggleDensity(checked);
    props.defaultTechnology(true);
    resetEquip();
  };

  const toggleViscosity = (checked) => {
    clearCheckboxes();
    clearDropdowns();
    props.toggleViscosity(checked);
    props.defaultTechnology(true);
    resetEquip();
  };

  const clearCheckboxes = () => {
    props.toggleHygienic(false);
    props.toggleDisplayAllSensorsNoFilters(false);
    props.toggleInLine(false);
    props.toggleFlowThroughChamberSlipstream(false);
    props.toggleTank(false);
    props.toggleHygienicApplication(false);
    props.toggleHighProcessNoiseSlurry(false);
    props.toggleFlanged(false);
    props.toggleWafer(false);
    props.toggleReducer(false);
    props.toggleDual(false);
    props.toggleQuad(false);
    props.tooglePressure(false);
  };

  const clearDropdowns = () => {
    if (!props.flow) {
      props.lineSize("");
      props.processLineSize("Unknown*");
      props.scheduleUnits("");
      props.productFamily("All Sensors");
      props.triggerLevel("4");
    }
    //props.fluidState("Liquid");
    //props.fluidSource("Database");
    // props.pickFluidsFromDB("Water");
    props.conductivityDropdown("");
    props.fluidService("");
  };

  return (
    <>
      <div className="measurement-type">
        <div className="title">
          <CustomTranslation data="XXSPECIFY_MMI_MC_PANEL1_MT" />
        </div>
        <Container className={props.measurement_type_container_class}>
          <Row>
            <Col md={4} sm={12} xs={12}>
              <CustomCheckbox
                checked={props.flow}
                name="flow"
                id="flow-checkbox"
                className="custom-checkbox"
                onChange={(e) => toggleFlow(e.target.checked)}
                disabled={flowDisabled}
                label={<CustomTranslation data="XXSPECIFY_MMI_MC_CHKBX_FLOW" />}
              />
            </Col>
            <Col xl={4} lg={12} md={12} sm={12}>
              <CustomCheckbox
                checked={props.density}
                name="density"
                id="density-checkbox"
                className="custom-checkbox"
                onChange={(e) => toggleDensity(e.target.checked)}
                disabled={false}
                label={<CustomTranslation data="XXSPECIFY_MMI_MC_CHKBX_DENSITY" />}
              />
            </Col>
            <Col xl={4} lg={12} md={12} sm={12}>
              <CustomCheckbox
                checked={props.viscosity}
                name="viscosity"
                id="viscosity-checkbox"
                className="custom-checkbox"
                onChange={(e) => toggleViscosity(e.target.checked)}
                disabled={viscosityDisabled}
                label={<CustomTranslation data="XXSPECIFY_MMI_MC_CHKBX_VISC" />}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <SelectTechnology checkBoxed={props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  flow: state.MeasurementType.flow,
  density: state.MeasurementType.density,
  viscosity: state.MeasurementType.viscosity,
  select_technology: state.MeasurementType.select_technology,
  default_technology: state.MeasurementType.default_technology,
  equipment_selection: state.EquipmentSelection.equipment_selection,
  get_sizing_data: state.MySizings.get_sizing_data,
  translation_data: state.TranslationData.translation_data,
  query_params: state.Preferences.query_params,
  fluid_selection_flag: state.FluidSelection.fluid_selection_flag,
  fluid_selection_section: state.FluidSelection.fluid_selection_section,
  measurement_type_container_class: state.MeasurementType.measurement_type_container_class,
});

export default connect(mapStateToProps, {
  toggleFlow,
  toggleDensity,
  toggleViscosity,
  selectTechnology,
  defaultTechnology,
  equipmentSelection,
  toggleHygienic,
  toggleDisplayAllSensorsNoFilters,
  toggleInLine,
  toggleFlowThroughChamberSlipstream,
  toggleTank,
  toggleHygienicApplication,
  toggleHighProcessNoiseSlurry,
  toggleFlanged,
  toggleWafer,
  toggleReducer,
  toggleDual,
  toggleQuad,
  lineSize,
  processLineSize,
  scheduleUnits,
  productFamily,
  triggerLevel,
  fluidState,
  fluidSource,
  pickFluidsFromDB,
  conductivityDropdown,
  fluidService,
  sizingResultsClicked,
  tooglePressure,
  measurementTypeContainerClass,
})(MeasurementType);
