import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  MemoryRouter,
  useHistory,
  useLocation,
} from 'react-router-dom';
import Header from '../Header/Header';
import BasicPage from '../BasicPage/BasicPage';
import HelpPage from '../HelpPage/HelpPage';
import AdvancedPage from '../AdvancedPage/AdvancedPage';
import FlowLogin from '../Offline/FlowLogin';
import Preferences from '../Preferences/Preferences';
import ToolkitUpload from '../ToolkitUpload/ToolkitUpload';
import Report from '../Reports/Report';
import ReportPOC from '../Reports/ReportPOC';
import UomConversion from '../UomConversion/UomConversion';
import MySizings from '../MySizings/MySizings';
import axios from 'axios';
import { useOktaAuth } from '@okta/okta-react';
import {
  GET_SAVED_PREF,
  GET_FLUID_STATE,
  GET_LANGUAGES,
  GET_AOL_MESSAGES,
  GET_FLUIDS,
  GET_UNITS,
  GET_CONDUCTIVITY,
  GET_FLUID_SERVICE,
  GET_ALL_LINE_SIZE,
  GET_WETTED_MATERIAL,
  GET_PRODUCT_FAMILY,
  GET_SCHEDULE_DATA,
  GET_NATURAL_GAS_COMP_LIMITS,
  FLOW_RATE_UNIT_GAS,
  DEFAULT_DECIMAL,
  FLOW_RATE_LIQUID_SLURRY_VALUES,
  FLOW_RATE_GAS_VALUES,
  TEMPERATURE_VALUES,
  LINE_PRESSURE_VALUES,
  FLOW_RATE_VALUES,
  AMBIENT_TEMPERATURE_VALUES,
} from '../../constants/constants';
import { getUnitsKeyValue } from '../../UtilityFuntions/getUnitsKeyValue';
import { connect } from 'react-redux';
import {
  selectTechnology,
  refreshPref,
  languageList,
  fluidStateUnits,
  fluidSourceUnits,
  pickFluidsFromDbUnits,
  conductivityUnits,
  fluidServiceUnits,
  savedPreferences,
  unitsData,
  productFamilyUnits,
  scheduleUnits,
  lineSizeUnits,
  processLineSizeUnits,
  translationData,
  errorMessage,
  naturalGasComponents,
  transError,
  advancedLayout,
  getSizingData,
  queryParams,
  setUserInfo,
  navbarFlag,
  unitsFlowRate,
  unitsLinePressure,
  unitsTemperature,
  unitsAmbient,
  unitsDensity,
  unitsViscosity,
  unitsGasFlowRate,
  unitsVapor,
  wettedMaterialUnits,
  allowPrefBaseRef, 
  compositionMethodFlag,
  setReportConfigs
} from '../../redux/actions';
import { GET_SIZING } from '../../constants/constants';
import CustomLoader from '../../components/CustomLoader/CustomLoader';
import ErrorModal from '../../components/CustomModals/ErrorModal';
const email = window.email ? window.email : '';
const transaction_id = window.transaction_id ? window.transaction_id : '';

// This file has a lot of the API calls and routing for the site
const SizingAndSelection = (props) => {
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    const fetchData = async () => {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}${GET_UNITS}`,
        headers: {
          'Content-Type': 'application/json',
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        data: {
          language: query_params.language
            ? query_params.language
            : languageCode,
        },
      })
        .then((response) => {
          getUnits(response.data.data);
        })
        .catch((error) => {
          console.log('error', error);
        });
    };
    fetchData();
  }, [props.refresh_pref, props.clear_all_field]);
  useEffect(() => {

      axios({
        async: false,

        contentType: 'application/json',
        dataType: 'json',
        withCredentials: true,
        crossDomain: true,
        method: 'get',
        url: `https://accessemr.okta.com/api/v1/sessions/me`,
      })
        .then(function (session) {
          if (session.data.status==='ACTIVE') {
            props.setUserInfo(session.data.login);
          }else{
            axios({
              async: false,
      
              contentType: 'application/json',
              dataType: 'json',
              withCredentials: true,
              crossDomain: true,
              method: 'get',
              url: `https://emerson.okta.com/api/v1/sessions/me`,
            })
              .then(function (session) {
                if (session.data.login) {
                  props.setUserInfo(session.data.login);
                }
              })
              .catch((error) => {
                console.log('error', error);
              });
          }
        })
        .catch((error) => {
          axios({
            async: false,
    
            contentType: 'application/json',
            dataType: 'json',
            withCredentials: true,
            crossDomain: true,
            method: 'get',
            url: `https://emerson.okta.com/api/v1/sessions/me`,
          })
            .then(function (session) {
              if (session.data.login) {
                props.setUserInfo(session.data.login);
              }
            })
            .catch((error) => {
              console.log('error', error);
            });
          console.log('error', error);
        });
   
  }, [authState, oktaAuth]);

  let history = useHistory();
  const languageCode =
    props.user_language && props.user_language.value
      ? props.user_language.value
      : 'US';
  const selectTechnology = props.select_technology
    ? props.select_technology
    : '';
  const [showLoader, setShowLoader] = useState(true);
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const query_params = {};
  if (params.get('fromPage') === 'oracle') {
    query_params.from_page = params.get('fromPage');
    query_params.header_id = atob(params.get('header_ID'));
    query_params.line_id = atob(params.get('Line_ID'));
    query_params.source_type = atob(params.get('Source_type'));
    query_params.source = atob(params.get('source'));
    query_params.pa_type = params.get('PA_Type');
    query_params.username = params.get('username');
    query_params.oraid = params.get('oraid');
    query_params.email = atob(params.get('email'));
    query_params.prdType = params.get('prdType');
    query_params.responsibility_id = atob(params.get('responsibility_id'));
    query_params.application_id = atob(params.get('application_id'));
    query_params.org_id = atob(params.get('org_id'));
    query_params.pa_ref_id = atob(params.get('pa_ref_id'));
    query_params.locale = params.get('locale');
    query_params.readonly_flag = atob(params.get('readonly_flag'));
    query_params.status = atob(params.get('status'));
    query_params.configuration_id = atob(params.get('configuration_id'));
    query_params.base_model = atob(params.get('base_model'));
    query_params.action = params.get('action');
    query_params.language = atob(params.get('language'));
    query_params.from_page = params.get('fromPage');
    query_params.transaction_num = atob(params.get('transaction_num'));
  } else {
    query_params.from_page = params.get('fromPage');
    query_params.header_id = params.get('header_ID');
    query_params.line_id = params.get('Line_ID');
    query_params.source_type = params.get('Source_type');
    query_params.source = params.get('source');
    query_params.pa_type = params.get('PA_Type');
    query_params.username = params.get('username');
    query_params.oraid = params.get('oraid');
    query_params.email = params.get('email');
    query_params.prdType = params.get('prdType');
    query_params.responsibility_id = params.get('responsibility_id');
    query_params.application_id = params.get('application_id');
    query_params.org_id = params.get('org_id');
    query_params.pa_ref_id = params.get('pa_ref_id');
    query_params.locale = params.get('locale');
    query_params.readonly_flag = params.get('readonly_flag');
    query_params.status = params.get('status');
    query_params.configuration_id = params.get('configuration_id');
    query_params.base_model = params.get('base_model');
    query_params.action = params.get('action');
    query_params.language = params.get('language');
    query_params.from_page = params.get('fromPage');
    query_params.transaction_num = params.get('transaction_num');
  }

  if (params.get('fromPage') === 'PDP' || params.get('fromPage') === 'tools') {
    query_params.requisition_id = document.getElementById(
      'requisitionListIdTag'
    )
      ? document.getElementById('requisitionListIdTag').value
      : '';
    query_params.order_tag = document.getElementById('orderItemIdTag')
      ? document.getElementById('orderItemIdTag').value
      : '';
    query_params.description = document.getElementById('wsNameTag')
      ? document.getElementById('wsNameTag').value
      : '';
    query_params.redirect_url = document.getElementById('redirectTReqTagUrl')
      ? document.getElementById('redirectTReqTagUrl').value
      : '';
    query_params.tag_name = document.getElementById('wc_tagName')
      ? document.getElementById('wc_tagName').value
      : '';
  } else {
    query_params.requisition_id = params.get('requisitionListId')
      ? params.get('requisitionListId')
      : document.getElementById('requisitionListIdTag')
      ? document.getElementById('requisitionListIdTag').value
      : '';
    query_params.order_tag = params.get('orderItemId')
      ? params.get('orderItemId')
      : document.getElementById('orderItemIdTag')
      ? document.getElementById('orderItemIdTag').value
      : '';
    query_params.description = params.get('description')
      ? params.get('description')
      : document.getElementById('wsNameTag')
      ? document.getElementById('wsNameTag').value
      : '';
    query_params.redirect_url = params.get('redirecturl')
      ? params.get('redirecturl')
      : params.get('redirectUrl')
      ? params.get('redirectUrl')
      : document.getElementById('redirectTReqTagUrl')
      ? document.getElementById('redirectTReqTagUrl').value
      : '';
    query_params.tag_name = params.get('tagName')
      ? params.get('tagName')
      : document.getElementById('wc_tagName')
      ? document.getElementById('wc_tagName').value
      : '';
  }
  props.queryParams(query_params);
  const getUnits = (unitsData) => {
    let flowRateLiquidSlurry = [];
    let flowRateGas = [];
    let temperature = [];
    let nonVaporPressure = [];
    let vaporPressure = [];
    let viscosity = [];
    let density = [];
    let sonicVelocity = [];
    // for tube velocity to get if metric or english units
    let unitMapping = {};

    if (unitsData) {
      unitsData.map((unit) => {
        if (unit.unit_type <= 1 && unit.unit_flag < 2) {
          flowRateLiquidSlurry.push({
            key: unit.lookup_code,
            flow_type: unit.flow_type,
            value: unit.meaning,
          });
        }
        if (unit.unit_type <= 1 && unit.unit_flag <= 2) {
          flowRateGas.push({
            key: unit.lookup_code,
            flow_type: unit.flow_type,
            value: unit.meaning,
          });
        }
        if (unit.unit_type === 4) {
          temperature.push({
            key: unit.lookup_code,
            value: unit.meaning,
          });
        }
        if (unit.unit_type === 5) {
          nonVaporPressure.push({
            key: unit.lookup_code,
            value: unit.meaning,
          });
        }
        if (unit.unit_type === 5 && unit.unit_flag === 1) {
          vaporPressure.push({
            key: unit.lookup_code,
            value: unit.meaning,
          });
        }
        if (unit.unit_type === 8) {
          viscosity.push({
            key: unit.lookup_code,
            value: unit.meaning,
            flow_type: unit.flow_type,
          });
        }
        if (unit.unit_type === 3) {
          density.push({
            key: unit.lookup_code,
            value: unit.meaning,
          });
        }
        if (
          unit.unit_type === 14 &&
          (unit.lookup_code === 'FT/SEC' || unit.lookup_code === 'M/SEC')
        ) {
          sonicVelocity.push({
            key: unit.lookup_code,
            value: unit.meaning,
          });
        }
        unitMapping = {
          ...unitMapping,
          ...{ [unit.lookup_code]: unit.unit_system },
        };
      });

      props.unitsData({
        flowRateLiquidSlurry: flowRateLiquidSlurry.sort(sortDropDownValues),
        flowRateGas: flowRateGas.sort(sortDropDownValues),
        temperature: temperature.sort(sortDropDownValues),
        nonVaporPressure: nonVaporPressure.sort(sortDropDownValues),
        vaporPressure: vaporPressure.sort(sortDropDownValues),
        viscosity: viscosity.sort(sortDropDownValues),
        density: density.sort(sortDropDownValues),
        sonicVelocity: sonicVelocity.sort(sortDropDownValues),
        unitMapping: unitMapping,
      });
    }
  };

  const sortDropDownValues = (a, b) => {
    const bandA = a.value.toUpperCase();
    const bandB = b.value.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  };

  // Get saved preferences on pageload too
  useEffect(() => {
    const fetchData = async () => {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}${GET_SAVED_PREF}`,
        headers: {
          'Content-Type': 'application/json',
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        data: {
          email_id: props.email,
          language: query_params.language
            ? query_params.language
            : languageCode,
        },
      })
        .then(function (response) {
          //props.savedPreferences(response.data.data[0]);
          if (
            response.data.data[0] !== 'E' &&
            (params.get('pa_ref_id') ? params.get('pa_ref_id') === '' : true) &&
            props.units_data
          ) {
            props.savedPreferences(response.data.data[0]);
            props.unitsFlowRate(
              getUnitsKeyValue(
                response.data.data[0].flowrate_unit,
                FLOW_RATE_LIQUID_SLURRY_VALUES,
                props.units_data
              )
            );
            props.unitsTemperature(
              getUnitsKeyValue(
                response.data.data[0].temperature_unit,
                TEMPERATURE_VALUES,
                props.units_data
              )
            );
            props.unitsAmbient(
              getUnitsKeyValue(
                response.data.data[0].ambient_temperature_unit,
                TEMPERATURE_VALUES,
                props.units_data
              )
            );
            props.unitsLinePressure(
              getUnitsKeyValue(
                response.data.data[0].linepressure_unit,
                'nonVaporPressure',
                props.units_data
              )
            );
            props.unitsDensity(
              getUnitsKeyValue(
                response.data.data[0].density_unit,
                'density',
                props.units_data
              )
            );
            props.unitsViscosity(
              getUnitsKeyValue(
                response.data.data[0].viscosity_unit,
                'viscosity',
                props.units_data
              )
            );
            props.unitsGasFlowRate(
              getUnitsKeyValue(
                response.data.data[0].flowrategas_unit,
                FLOW_RATE_GAS_VALUES,
                props.units_data
              )
            );
            props.unitsVapor(
              getUnitsKeyValue(
                response.data.data[0].vaporpressure_unit,
                'vaporPressure',
                props.units_data
              )
            );
          }
        })
        .catch(function (error) {
          console.log('error', error);
        });
    };
    if (props.email !== '' || props.refresh_pref) {
      fetchData();
    }
    props.refreshPref(false);
  }, [
    props.units_data,
    props.email,
    props.clear_all_field,
    props.refresh_pref,
  ]);
  // useEffect(() => {
  //   if (props.refresh_pref) {
  //     const fetchData = async () => {
  //       await axios({
  //         method: 'post',
  //         url: `${process.env.REACT_APP_BASE_URL}${GET_SAVED_PREF}`,
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         xsrfHeaderName: 'X-CSRFToken',
  //         xsrfCookieName: 'csrftoken',
  //         data: {
  //           email_id: props.email,
  //           language: query_params.language?query_params.language:languageCode,
  //         },
  //       })
  //         .then(function (response) {
  //           props.refreshPref(false);
  //           props.savedPreferences(response.data.data[0]);
  //           props.unitsFlowRate(
  //             getUnitsKeyValue(
  //               response.data.data[0].flowrate_unit,
  //               FLOW_RATE_LIQUID_SLURRY_VALUES,
  //               props.units_data
  //             )
  //           )
  //           props.unitsTemperature(
  //             getUnitsKeyValue(
  //               response.data.data[0].temperature_unit,
  //               TEMPERATURE_VALUES,
  //               props.units_data
  //             )
  //           )
  //           props.unitsAmbient(
  //             getUnitsKeyValue(
  //               response.data.data[0].ambient_temperature_unit,
  //               TEMPERATURE_VALUES,
  //               props.units_data
  //             )
  //           )
  //           props.unitsLinePressure(
  //             getUnitsKeyValue(
  //               response.data.data[0].linepressure_unit,
  //               'nonVaporPressure',
  //               props.units_data
  //             )
  //           )
  //           props.unitsDensity(getUnitsKeyValue(
  //             response.data.data[0].density_unit,
  //             'density',
  //             props.units_data
  //           ))
  //           props.unitsViscosity(getUnitsKeyValue(
  //             response.data.data[0].viscosity_unit,
  //             'viscosity',
  //             props.units_data
  //           ))
  //        props.unitsGasFlowRate(
  //                   getUnitsKeyValue(
  //                     response.data.data[0].flowrategas_unit,
  //                     FLOW_RATE_GAS_VALUES,
  //                     props.units_data
  //                   )
  //                 )

  //         })
  //         .catch(function (error) {
  //           props.refreshPref(false);
  //           console.log('error', error);
  //         });
  //     };
  //     fetchData();
  //     props.refreshPref(false);
  //   }
  // }, [props.refresh_pref]);

  useEffect(() => {
    const fetchData = async () => {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}${GET_FLUID_STATE}`,
        headers: {
          'Content-Type': 'application/json',
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        data: {
          email_id: props.email,
          language: query_params.language
            ? query_params.language
            : languageCode,
        },
      })
        .then(function (response) {
          props.fluidStateUnits(response.data.data.fluid_state);
        })
        .catch(function (error) {
          console.log('error', error);
        });
    };
    fetchData();
  }, [props.email]);

  useEffect(() => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}${GET_LANGUAGES}`,
      headers: {
        'Content-Type': 'application/json',
      },
      xsrfHeaderName: 'X-CSRFToken',
      xsrfCookieName: 'csrftoken',
      data: {
        email_id: props.email,
      },
    })
      .then(function (response) {
        props.languageList(response.data.data);
      })
      .catch(function (error) {
        console.log('error', error);
      });
  }, [props.email]);

  useEffect(() => {
    const resultsArray = [];
    const fetchData = async () => {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}${GET_AOL_MESSAGES}`,
        headers: {
          'Content-Type': 'application/json',
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        data: {
          language_code: query_params.language
            ? query_params.language
            : languageCode,
        },
      })
        .then(function (response) {
          props.transError(response.data.errors);
          props.translationData(response.data.data);
          // response.data.data.map((msg) => {
          //   if (
          //     msg.message_name === 'XXSPECIFY_MMI_SR_LBL_DAT_BAS' ||
          //     msg.message_name === 'XXSPECIFY_MMI_SR_LBL_NATGAS' ||
          //     msg.message_name === 'XXSPECIFY_MMI_SR_LBL_CUSTOM'
          //   ) {
          //     resultsArray.push(msg);
          //   }
          // });
          // props.fluidSourceUnits(resultsArray);
        })
        .catch(function (error) {
          console.log('error', error);
        });
    };
    fetchData();
  }, []);

  useEffect(()=>{
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/mmipa/get_report_configs/`,
      headers: {
        "Content-Type": "application/json",
      },
      xsrfHeaderName: "X-CSRFToken",
      xsrfCookieName: "csrftoken",
    })
      .then((response) => {
        if(response.data.data){
          props.setReportConfigs([...response.data.data])
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  }, [])

  useEffect(() => {
    const resultsArray = [];
    const fetchData = async () => {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/mmipa/get_fluid_source_dropdown/`,
        headers: {
          'Content-Type': 'application/json',
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        data: {
          language: query_params.language
            ? query_params.language
            : languageCode,
        },
      })
        .then(function (response) {
          response.data.data.map((msg) => {
            if (
              msg.message_name === 'XXSPECIFY_MMI_SR_LBL_DAT_BAS' ||
              msg.message_name === 'XXSPECIFY_MMI_SR_LBL_NATGAS' ||
              msg.message_name === 'XXSPECIFY_MMI_SR_LBL_CUSTOM'
            ) {
              resultsArray.push(msg);
            }
          });
          props.fluidSourceUnits(resultsArray);
        })
        .catch(function (error) {
          console.log('error', error);
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}${GET_FLUIDS}`,
        headers: {
          'Content-Type': 'application/json',
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        data: {
          email_id: props.email,
          language: query_params.language
            ? query_params.language
            : languageCode,
        },
      })
        .then(function (response) {
          props.pickFluidsFromDbUnits(response.data.data.fluids);
        })
        .catch(function (error) {
          console.log('error', error);
        });
    };
    fetchData();
  }, [props.email, props.clear_all_field]);

  useEffect(() => {
    const fetchData = async () => {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}${GET_CONDUCTIVITY}`,
        headers: {
          'Content-Type': 'application/json',
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        data: {
          language: query_params.language
            ? query_params.language
            : languageCode,
        },
      })
        .then(function (response) {
          props.conductivityUnits(response.data.data);
        })
        .catch(function (error) {
          console.log('error', error);
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}${GET_FLUID_SERVICE}`,
        headers: {
          'Content-Type': 'application/json',
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        data: {
          language: query_params.language
            ? query_params.language
            : languageCode,
        },
      })
        .then(function (response) {
          props.fluidServiceUnits(response.data.data.fluid_service);
        })
        .catch(function (error) {
          console.log('error', error);
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}${GET_UNITS}`,
        headers: {
          'Content-Type': 'application/json',
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        data: {
          language: query_params.language
            ? query_params.language
            : languageCode,
        },
      })
        .then((response) => {
          getUnits(response.data.data);
        })
        .catch((error) => {
          console.log('error', error);
        });
    };
    fetchData();
  }, [props.refresh_pref, props.clear_all_field]);

  useEffect(() => {
    const fetchData = async () => {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}${GET_ALL_LINE_SIZE}`,
        headers: {
          'Content-Type': 'application/json',
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        data: {
          language: query_params.language
            ? query_params.language
            : languageCode,
          technology: props.select_technology ===""? 'Density':props.select_technology ,
        },
      })
        .then((response) => {
          props.processLineSizeUnits(response.data.data.all_line_size);
        })
        .catch((error) => {
          console.log('error', error);
        });
    };
    fetchData();
  }, [props.select_technology]);

  useEffect(() => {
    const fetchData = async () => {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}${GET_WETTED_MATERIAL}`,
        headers: {
          'Content-Type': 'application/json',
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        data: {
          language: query_params.language
            ? query_params.language
            : languageCode,
          technology: 'Coriolis',
        },
      })
        .then((response) => {
          props.wettedMaterialUnits(response.data.data.wetted_material);
        })
        .catch((error) => {
          console.log('error', error);
        });
    };
    //if(props.select_technology ==='Coriolis'){
    fetchData();
    //}
  }, [props.select_technology]);

  useEffect(() => {
    const fetchData = async () => {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}${GET_PRODUCT_FAMILY}`,
        headers: {
          'Content-Type': 'application/json',
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        data: {
          language: query_params.language
            ? query_params.language
            : languageCode,
        },
      })
        .then((response) => {
          if (response.data.data) {
            props.productFamilyUnits(response.data.data);
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    };
    fetchData();
  }, []);

  // const { scheduleUnits, errorMessage, error_message } = props;
  useEffect(() => {
    const fetchData = async () => {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}${GET_SCHEDULE_DATA}`,
        headers: {
          'Content-Type': 'application/json',
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        data: {
          language: query_params.language
            ? query_params.language
            : languageCode,
        },
      })
        .then((response) => {
          if (response.data.data) {
            props.scheduleUnits(response.data.data.pipe_schedule);
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    };
    fetchData();
  }, []); //languageCode, scheduleUnits, errorMessage, error_message

  useEffect(() => {
    const fetchData = async () => {
      await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}${GET_NATURAL_GAS_COMP_LIMITS}`,
        headers: {
          'Content-Type': 'application/json',
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
      })
        .then((response) => {
          if (response.data.data) {
            props.naturalGasComponents(response.data.data);
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    };
    fetchData();
  }, [props.clear_all_field]);
  useEffect(() => {
    let transaction_id = query_params.pa_ref_id;
    const data = {
      transaction_id: transaction_id, // For Testing "I936006"
    };
    const fetchData = async () => {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}${GET_SIZING}`,
        headers: {
          'Content-Type': 'application/json',
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        data: data,
      })
        .then(function (response) {
          props.getSizingData(response.data.sizing_details);
          //  props.advancedLayout(false);
          //   history.push('/basic');
        })
        .catch(function (error) {
          console.log('error', error);
        });
    };
    setTimeout(() => {
      fetchData();
    }, 3000);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 3000);
  }, []);

  const scrollRefID = useRef(null);
  const refs = useRef(null);

  const onClickSizingResults = (button) => {
    props.navbarFlag(button);
    scrollRefID.current.buttonClicked = button;
    executeScroll();
  };

  const scrollToRef = (id) => {
    const yOffset = -220; // to make room for header
    const element = document.getElementById(id.current.buttonClicked);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };
  const executeScroll = () => scrollToRef(scrollRefID);

  const local = localStorage.getItem('AppLogin');

  const handleErrorClose = () => {
    props.errorMessage(false);
    // window.location.reload(false);
  };

  const handleErrorShow = () => props.error_message;

  if (showLoader) {
    return (
      <CustomLoader
        phase={showLoader}
        loaderText="outerText"
        loaderClass="outerloaderImg"
        subTitle="Please wait..."
      />
    );
  }

  return (
    <>
      <ErrorModal
        show={props.error_message}
        handleClose={handleErrorClose}
        handleShow={handleErrorShow}
      />
      <Container fluid className="sizing-and-selection">
        <Router>
          <MemoryRouter
            initialEntries={[
              '/FlowLogin',
              '/advanced',
              '/basic',
              '/preferences',
              '/report',
              // '/report-poc'
            ]}
            initialIndex={!local ? (props.email === 'Offline' ? 0 : 1) : 1}
          >
            <Switch>
              <Route exact path="/FlowLogin" render={() => <FlowLogin />}>
                <Header section={'FlowLogin'} />
                <FlowLogin />
              </Route>
              <Route
                exact
                path="/preferences"
                render={(props) => <Preferences {...props} />}
              >
                <Header section={'preferences'} />
                <Preferences />
              </Route>
              <Route
                exact
                path="/toolkit_upload"
                render={(props) => <ToolkitUpload {...props} />}
              >
                <Header section={'toolkit_upload'} />
                <ToolkitUpload />
              </Route>
              <Route
                exact
                path="/uom_conversion"
                render={(props) => <UomConversion {...props} />}
              >
                <Header section={'uom_conversion'} />
                <UomConversion />
              </Route>
              <Route
                exact
                path="/my_sizings"
                render={(props) => <MySizings {...props} />}
              >
                <Header section={'my_sizings'} />
                <MySizings />
              </Route>
              <Route exact path='/report'
              render={(props)=> <ReportPOC {...props}/>}>
                <Header section={'my_sizings'} />
                <ReportPOC/>
              </Route>
              {/* <Route
                exact
                path="/report"
                render={(props) => <Report {...props} />}
              >
                <Header section={'my_sizings'} />
                <Report />
              </Route> */}
              <Route
                exact
                path="/advanced"
                render={(props) => <AdvancedPage {...props} />}
              >
                <Header
                  section={'advanced'}
                  forwardedRef={scrollRefID}
                  onClickSizingResults={onClickSizingResults}
                />
                <AdvancedPage
                  forwardedRef={refs}
                  onClickSizingResults={onClickSizingResults}
                />
              </Route>
              <Route path="/basic" render={(props) => <BasicPage {...props} />}>
                <Header section={'basic'} />
                <BasicPage />
              </Route>

              <Route
                exact
                path="/help"
                render={(props) => <HelpPage {...props} />}
              >
                <Header section={'help'} />
                <HelpPage  {...props} />
              </Route>
              <Route
                exact
                path="/"
                render={(props) => <AdvancedPage {...props} />}
              >
                <Header section={'advanced'} />
                <AdvancedPage />
              </Route>
            </Switch>
          </MemoryRouter>
        </Router>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  refresh_pref: state.Preferences.refresh_pref,
  language_list: state.LanguageList.language_list,
  translation_data: state.TranslationData.translation_data,
  user_language: state.AppHeader.user_language,
  email: state.AppHeader.email,
  select_technology: state.MeasurementType.select_technology,
  error_message: state.ErrorMessage.error_message,
  selected_technology: state.ProcessVariables.selected_technology,
  clear_all_field: state.ClearAllField.clear_all_field,
  units_data: state.DropdownData.units_data,
});

export default connect(mapStateToProps, {
  selectTechnology,
  refreshPref,
  languageList,
  fluidStateUnits,
  fluidSourceUnits,
  setReportConfigs,
  pickFluidsFromDbUnits,
  conductivityUnits,
  fluidServiceUnits,
  savedPreferences,
  unitsData,
  productFamilyUnits,
  scheduleUnits,
  lineSizeUnits,
  processLineSizeUnits,
  translationData,
  errorMessage,
  naturalGasComponents,
  transError,
  advancedLayout,
  getSizingData,
  queryParams,
  setUserInfo,
  navbarFlag,
  unitsFlowRate,
  unitsLinePressure,
  unitsTemperature,
  unitsAmbient,
  unitsDensity,
  unitsViscosity,
  unitsGasFlowRate,
  unitsVapor,
  wettedMaterialUnits,
  allowPrefBaseRef,
  compositionMethodFlag
})(SizingAndSelection);
