import {
  TOGGLE_FLOW,
  TOGGLE_DENSITY,
  TOGGLE_VISCOSITY,
  SELECT_TECHNOLOGY,
  DEFAULT_TECHNOLOGY,
  CLEAR_MEAS_TYPE,
  MEASUREMENT_TYPE_CONTAINER_CLASS,
} from "../actionTypes";

const initialState = {
  flow: true,
  density: false,
  viscosity: false,
  select_technology: "",
  default_technology: true,
  measurement_type_container_class: "selection-container",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_FLOW: {
      return {
        ...state,
        flow: action.payload.id,
        select_technology: "",
      };
    }
    case TOGGLE_DENSITY: {
      return {
        ...state,
        density: action.payload.id,
        select_technology: "",
      };
    }
    case TOGGLE_VISCOSITY: {
      return {
        ...state,
        viscosity: action.payload.id,
        select_technology: "",
      };
    }
    case SELECT_TECHNOLOGY: {
      return {
        ...state,
        select_technology: action.payload.id,
      };
    }
    case DEFAULT_TECHNOLOGY: {
      return {
        ...state,
        default_technology: action.payload.id,
      };
    }
    case CLEAR_MEAS_TYPE: {
      return initialState;
    }
    case MEASUREMENT_TYPE_CONTAINER_CLASS: {
      return {
        ...state,
        measurement_type_container_class: action.payload.id,
      };
    }
    default: {
      return state;
    }
  }
}
