import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Card, Form } from 'react-bootstrap';
import axios from 'axios';
import { useHistory, Link } from 'react-router-dom';
import '../../styles/App.scss';
import { CustomButton } from '../../components/CustomButton/CustomButton';

import { POST_OFFLINE_LOGIN, GET_LANGUAGES } from '../../constants/constants';
import { loginEmail, languageList, errorMessage } from '../../redux/actions';
import { connect } from 'react-redux';
import ErrorModal from '../../components/CustomModals/ErrorModal';

const email = window.email ? window.email : "";
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const FlowLogin = (props) => {
  const history = useHistory();
  const [Useremail, setUsermail] = useState("");
  const [value, setValue] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const handleErrorClose = () => {
    props.errorMessage(false);
    // window.location.reload(false);
  }
  const handleErrorShow = () => props.error_message;

  const handleInputChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    setUsermail(
      validEmailRegex.test(value) ? "" : "Please enter a valid email"
    );
    setValue(value);
  };

  useEffect(() => {
    Useremail ? setButtonEnabled(true) : setButtonEnabled(false);
  }, []);
  const handleSubmit = () => {
    const data = { email: value };
    localStorage.setItem("AppLogin", value);
    history.push("/advanced");
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}${POST_OFFLINE_LOGIN}`,
      headers: {
        "Content-Type": "application/json",
      },
      xsrfHeaderName: "X-CSRFToken",
      xsrfCookieName: "csrftoken",
      data: data,
    })
      .then((response) => {
        const success = response.data.data;
        props.loginEmail(success);
      })
      .catch((error) => {
        props.loginEmail(error);
        console.log("error", error);
      });
  };

  return (
    <>
      <ErrorModal show={props.error_message} handleClose={handleErrorClose} handleShow={handleErrorShow} />
      <Container className='flowlogin-page'>
        <Row>
          <Col md={{ span: 5, offset: 4 }} className='col-background'>
            <Card>
              <Card.Body>
                <Card.Title>User.Sign In</Card.Title>
                <Form.Group as={Row} controlId='formHorizontalEmail'>
                  <Form.Label column sm={2}>
                    Email
                </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      onChange={handleInputChange}
                      type='email'
                      placeholder='Email'
                    />
                    <Form.Text className='text-muted testColor'>
                      {Useremail}
                    </Form.Text>
                  </Col>
                </Form.Group>
                <CustomButton
                  classNames='bg-submit'
                  label='SUBMIT'
                  variant='none'
                  onClick={() => handleSubmit()}
                  arrow='right'
                  size='md'
                />
                <Form.Text className='text-muted'>
                  Note: Please use your Online Store email to login
              </Form.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => ({
  language_list: state.LanguageList.language_list,
  login_mail: state.OfflineFlowLogin.login_mail,
  error_message: state.ErrorMessage.error_message,
});
export default connect(mapStateToProps, { languageList, loginEmail, errorMessage })(
  FlowLogin
);
