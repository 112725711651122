import {
  EQUIPMENT_SELECTION, CLEAR_EQ_SELECTION
} from '../actionTypes';

const initialState = {
  equipment_selection: 'Coriolis Flow Meter (Includes Sensor and Transmitter)'
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EQUIPMENT_SELECTION: {
      return {
        ...state,
        equipment_selection: action.payload.id
      }
    }
    case CLEAR_EQ_SELECTION: {
      return initialState
    }
    default: {
      return state;
    }
  }
}
