import {
  SELECT_BASIC_PAGE
} from '../actionTypes';

const initialState = {
  basic_page: 'measurementType'
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SELECT_BASIC_PAGE: {
      return {
        ...state,
        basic_page: action.payload.id
      }
    }
    default: {
      return state;
    }
  }
}

