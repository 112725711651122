import { USER_LANGUAGE, INTERNET_MODE,SET_USERINFO } from '../actionTypes';

const initialState = {
  internet_mode: "",
  email:'',
  user_language: {
    label: 'American',
    value: 'US',
    description: 'English',
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LANGUAGE: {
      return {
        ...state,
        user_language: action.payload.id,
      };
    }
    case SET_USERINFO: {
      return {
        ...state,
        email: action.payload.id,
      };
    }
    case INTERNET_MODE: {
      return {
        ...state,
        internet_mode: action.payload.id,
      };
    }
    default: {
      return state;
    }
  }
}
