import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  FLOW_RATE_VALUES,
  FLOW_RATE_GAS_VALUES,
  VAPOR_PRESSURE_VALUES,
  LINE_PRESSURE_VALUES,
  TEMPERATURE_VALUES,
  AMBIENT_TEMPERATURE_VALUES,
  DENSITY_VALUES,
  GAS_DENSITY_VALUES,
  GAS_DENSITY_SG_VALUES,
  VISCOSITY_VALUES,
  VISCOSITY_CP_VALUES,
  SONIC_VELOCITY_VALUES,
  GAS_TEMP_VALUES,
  GAS_PRESSURE_VALUES
} from '../constants/constants';

/////////////////////////////////////////////////////////////
//  input object:
//    unitType: the property or condition being converted
//    value: number to be converted
//    units: the object containing the dropdown keys
//    unitKey: the converting to uom
//    prevUnitKey: the converting from uom
/////////////////////////////////////////////////////////////

const possibleTypes = [
  GAS_TEMP_VALUES,
  FLOW_RATE_VALUES,
  FLOW_RATE_GAS_VALUES,
  LINE_PRESSURE_VALUES,
  TEMPERATURE_VALUES,
  AMBIENT_TEMPERATURE_VALUES,
  DENSITY_VALUES,
  GAS_DENSITY_VALUES,
  GAS_DENSITY_SG_VALUES,
  VISCOSITY_VALUES,
  VISCOSITY_CP_VALUES,
  VAPOR_PRESSURE_VALUES,
  SONIC_VELOCITY_VALUES,
  GAS_PRESSURE_VALUES
];

const useConvertForUom = (objectArray, convertFlag, props) => {
  const [uomResults, setUomResults] = useState([]);
  useEffect(() => {
    if (convertFlag && objectArray.length > 0) {
      let uomData = [];
      objectArray.map((obj) => {
        if (!possibleTypes.includes(obj.unitType)) {
          console.error(`ERROR: unitType ${obj.unitType} not a required type!`);
          return '';
        } else if (obj.unitType === DENSITY_VALUES) {
          return uomData.push(
            JSON.stringify({
              fluid_state: props.fluid_state.toLowerCase(),
              min: props.min_density_value !== ""? 
              props.min_density_value:
              props.min_density.toString(),
              
             // props.min_density ? props.min_density.toString() : '',
              norm: props.normal_density_value !==""? 
              props.normal_density_value:
              props.normal_density.toString(),
              //props.normal_density ? props.normal_density.toString() : '',
              max: props.max_density_value !==""?
              props.max_density_value : 
              props.max_density.toString(),
              // props.max_density ? props.max_density.toString() : '',
              fsd: '',
              input_units:
                obj.unitType === DENSITY_VALUES
                  ? obj.prevUnitKey !== -1
                    ? obj.units[obj.prevUnitKey].key
                    : ''
                  : '',
              output_units:
                obj.unitType === DENSITY_VALUES
                  ? obj.unitKey !== -1
                    ? obj.units[obj.unitKey].key
                    : ''
                  : '',
              density_min: '',
              density_norm: '',
              density_max: '',
              density_units: '',
              viscosity_min: '',
              viscosity_norm: '',
              viscosity_max: '',
              viscosity_units: 'CP',
              ref_density: props.gas_density,
              ref_density_units: props.units_gas_density.key,
            })
          );
        }
        else if (obj.unitType === GAS_DENSITY_VALUES) {
          return uomData.push(
            JSON.stringify({
              fluid_state: props.fluid_state.toLowerCase(),
              min: props.gas_density_value !==""?
              props.gas_density_value:
              props.gas_density.toString(),
              norm: '',
              max: '',
              fsd: '',
              input_units:
                obj.unitType === GAS_DENSITY_VALUES
                  ? obj.prevUnitKey !== -1
                    ? obj.units[obj.prevUnitKey].key
                    : ''
                  : '',
              output_units:
                obj.unitType === GAS_DENSITY_VALUES
                  ? obj.unitKey !== -1
                    ? obj.units[obj.unitKey].key
                    : ''
                  : '',
              density_min: '',
              density_norm: '',
              density_max: '',
              density_units: '',
              viscosity_min: '',
              viscosity_norm: '',
              viscosity_max: '',
              viscosity_units: 'CP',
              ref_density: props.gas_density,
              ref_density_units: props.units_gas_density.key,
            })
          );
        }
        else if (obj.unitType === GAS_DENSITY_SG_VALUES) {
          return uomData.push(
            JSON.stringify({
              fluid_state: props.fluid_state.toLowerCase(),
              min: props.density_specific_gravity_value !==""?
              props.density_specific_gravity_value:
              props.density_specific_gravity.toString(),
              //props.gas_density_value:
              //props.gas_density.toString(),
              norm: '',
              max: '',
              fsd: '',
              input_units:
                obj.unitType === GAS_DENSITY_SG_VALUES
                  ? obj.prevUnitKey !== -1
                    ? obj.units[obj.prevUnitKey].key
                    : ''
                  : '',
              output_units:
                obj.unitType === GAS_DENSITY_SG_VALUES
                  ? obj.unitKey !== -1
                    ? obj.units[obj.unitKey].key
                    : ''
                  : '',
              density_min: '',
              density_norm: '',
              density_max: '',
              density_units: '',
              viscosity_min: '',
              viscosity_norm: '',
              viscosity_max: '',
              viscosity_units: 'CP',
              ref_density: props.gas_density,
              ref_density_units: props.units_gas_density.key,
            })
          );
        }

        else if (obj.unitType === SONIC_VELOCITY_VALUES) {
          return uomData.push(
            JSON.stringify({
              fluid_state: props.fluid_state.toLowerCase(),
              min:props.sonic_velocity_value !==""? 
              props.sonic_velocity_value:
              props.sonic_velocity.toString(),
              //props.sonic_velocity ? props.sonic_velocity.toString() : '',
              norm:  '',
              max:  '',
              fsd: '',
              input_units:
                obj.unitType === SONIC_VELOCITY_VALUES
                  ? obj.prevUnitKey !== -1
                    ? obj.units[obj.prevUnitKey].key
                    : ''
                  : '',
              output_units:
                obj.unitType === SONIC_VELOCITY_VALUES
                  ? obj.unitKey !== -1
                    ? obj.units[obj.unitKey].key
                    : ''
                  : '',
              density_min: '',
              density_norm: '',
              density_max: '',
              density_units: '',
              viscosity_min: '',
              viscosity_norm: '',
              viscosity_max: '',
              viscosity_units: 'CP',
              ref_density: props.gas_density,
              ref_density_units: props.units_gas_density.key,
            })
          );
        } else if (obj.unitType === VISCOSITY_VALUES) {
          return uomData.push(
            JSON.stringify({
              fluid_state: props.fluid_state.toLowerCase(),
              min:props.min_viscosity_value !==''?
              props.min_viscosity_value:
              props.min_viscosity.toString(),
              //props.min_viscosity ? props.min_viscosity.toString() : '',
              norm: props.normal_viscosity_value !==""?
              props.normal_viscosity_value:
              props.normal_viscosity.toString(),
              //props.normal_viscosity
              //  ? props.normal_viscosity.toString()
              //  : '',
              max:props.max_viscosity_value !==""?
              props.max_viscosity_value:
              props.max_viscosity.toString(),
              //props.max_viscosity ? props.max_viscosity.toString() : '',
              fsd: '',
              input_units:
                obj.unitType === VISCOSITY_VALUES
                  ? obj.prevUnitKey !== -1
                    ? obj.units[obj.prevUnitKey].key
                    : ''
                  : '',
              output_units:
                obj.unitType === VISCOSITY_VALUES
                  ? obj.unitKey !== -1
                    ? obj.units[obj.unitKey].key
                    : ''
                  : '',
                  density_min: props.min_density
                  ? props.min_density.toString()
                  : '',
                density_norm: props.normal_density
                  ? props.normal_density.toString()
                  : '',
                density_max: props.max_density
                  ? props.max_density.toString()
                  : '',
              density_units:  props.units_density.key,
              viscosity_min: '',
              viscosity_norm: '',
              viscosity_max: '',
              viscosity_units: 'CP',
              ref_density: '',
              ref_density_units: '',
            })
          );
        } else if(obj.unitType === VISCOSITY_CP_VALUES){
          return uomData.push(
            JSON.stringify({
              fluid_state: props.fluid_state.toLowerCase(),
              min:props.min_viscosity_value !==''?
              props.min_viscosity_value:
              props.min_viscosity.toString(),
              //props.min_viscosity ? props.min_viscosity.toString() : '',
              norm: props.normal_viscosity_value !==""?
              props.normal_viscosity_value:
              props.normal_viscosity.toString(),
              //props.normal_viscosity
              //  ? props.normal_viscosity.toString()
              //  : '',
              max:props.max_viscosity_value !==""?
              props.max_viscosity_value:
              props.max_viscosity.toString(),
              //props.max_viscosity ? props.max_viscosity.toString() : '',
              fsd: '',
              input_units:
                obj.unitType === VISCOSITY_CP_VALUES
                  ? obj.prevUnitKey !== -1
                    ? obj.units[obj.prevUnitKey].key
                    : 'CP'
                  : '',
              output_units:
                obj.unitType === VISCOSITY_CP_VALUES
                  ? obj.unitKey !== -1
                    ? obj.units[obj.unitKey].key
                    : 'CP'
                  : '',
                  density_min: props.min_density
                  ? props.min_density.toString()
                  : '',
                density_norm: props.normal_density
                  ? props.normal_density.toString()
                  : '',
                density_max: props.max_density
                  ? props.max_density.toString()
                  : '',
              density_units: props.units_density.key,
              viscosity_min: '',
              viscosity_norm: '',
              viscosity_max: '',
              viscosity_units: 'CP',
              ref_density: '',
              ref_density_units: '',
            })
          );
        }
        
        else if (
          obj.unitType === FLOW_RATE_VALUES ||
          obj.unitType === FLOW_RATE_GAS_VALUES
        ) {
          return uomData.push(
            JSON.stringify({
              fluid_state: props.fluid_state.toLowerCase(),
              min: props.min_flow_rate_value !==""?
              props.min_flow_rate_value :
              props.min_flow_rate.toString(),
              //props.min_flow_rate ? props.min_flow_rate.toString() : '',
              norm: props.normal_flow_rate
                ? props.normal_flow_rate.toString()
                : '',
              max: props.max_flow_rate ? props.max_flow_rate.toString() : '',
              fsd: props.full_flow_rate ? props.full_flow_rate.toString() : '',
              input_units:
                obj.unitType === FLOW_RATE_VALUES || FLOW_RATE_GAS_VALUES
                  ? obj.prevUnitKey !== -1
                    ? obj.units[obj.prevUnitKey].key
                    : ''
                  : '',
              output_units:
                obj.unitType === FLOW_RATE_VALUES || FLOW_RATE_GAS_VALUES
                  ? obj.unitKey !== -1
                    ? obj.units[obj.unitKey].key
                    : ''
                  : '',
              density_min: props.min_density
                ? props.min_density.toString()
                : '',
              density_norm: props.normal_density
                ? props.normal_density.toString()
                : '',
              density_max: props.max_density
                ? props.max_density.toString()
                : '',
              density_units: props.units_density.key,
              viscosity_min: '',
              viscosity_norm: '',
              viscosity_max: '',
              viscosity_units: 'CP',
              ref_density: props.gas_density,
              ref_density_units: props.units_gas_density.key,
            })
          );
        } else if (
          obj.unitType === LINE_PRESSURE_VALUES
        ) {
          return uomData.push(
            JSON.stringify({
              fluid_state: props.fluid_state.toLowerCase(),
              min: props.min_line_pressure_value !==""?
              props.min_line_pressure_value: 
              props.min_line_pressure.toString(),
              //props.min_line_pressure
              // ? props.min_line_pressure.toString()
              //: props.min_vapor?props.min_vapor.toString():'',
              norm: props.normal_line_pressure_value !==""?
              props.normal_line_pressure_value:
              props.normal_line_pressure.toString(),
              
                //props.normal_line_pressure
                //? props.normal_line_pressure.toString()
               // : '',
              max: props.max_line_pressure_value !==""?
              props.max_line_pressure_value:
              props.max_line_pressure.toString(),

              //props.max_line_pressure
                //? props.max_line_pressure.toString()
                //: '',
              fsd: props.full_line_pressure_value !==""?
              props.full_line_pressure_value:
              props.full_line_pressure.toString(),
              
              //props.full_line_pressure
              //  ? props.full_line_pressure.toString()
              //  : '',
              input_units:
                obj.unitType === LINE_PRESSURE_VALUES ||
                obj.unitType === VAPOR_PRESSURE_VALUES
                  ? obj.prevUnitKey !== -1
                    ? obj.units[obj.prevUnitKey].key
                    : ''
                  : '',
              output_units:
                obj.unitType === LINE_PRESSURE_VALUES ||
                obj.unitType === VAPOR_PRESSURE_VALUES
                  ? obj.unitKey !== -1
                    ? obj.units[obj.unitKey].key
                    : ''
                  : '',
              density_min: props.min_density
                ? props.min_density.toString()
                : '',
              density_norm: props.normal_density
                ? props.normal_density.toString()
                : '',
              density_max: props.max_density
                ? props.max_density.toString()
                : '',
              density_units: props.units_density?props.units_density.key:"",
              viscosity_min: '',
              viscosity_norm: '',
              viscosity_max: '',
              viscosity_units: 'CP',
              ref_density: props.gas_density,
              ref_density_units: props.units_gas_density.key,
            })
          );
        } else if(obj.unitType === VAPOR_PRESSURE_VALUES){
          return uomData.push(
            JSON.stringify({
              fluid_state: props.fluid_state.toLowerCase(),
              min: props.min_vapor_value !==""?
              props.min_vapor_value: 
              props.min_vapor.toString(),
              norm: '',
              max: '',
              fsd:'',
              input_units:
                
                obj.unitType === VAPOR_PRESSURE_VALUES
                  ? obj.prevUnitKey !== -1
                    ? obj.units[obj.prevUnitKey].key
                    : ''
                  : '',
              output_units:
              
                obj.unitType === VAPOR_PRESSURE_VALUES
                  ? obj.unitKey !== -1
                    ? obj.units[obj.unitKey].key
                    : ''
                  : '',
              density_min: props.min_density
                ? props.min_density.toString()
                : '',
              density_norm: props.normal_density
                ? props.normal_density.toString()
                : '',
              density_max: props.max_density
                ? props.max_density.toString()
                : '',
              density_units: props.units_density?props.units_density.key:"",
              viscosity_min: '',
              viscosity_norm: '',
              viscosity_max: '',
              viscosity_units: 'CP',
              ref_density: props.gas_density,
              ref_density_units: props.units_gas_density.key,
            })
          );
        }else if (obj.unitType === TEMPERATURE_VALUES) {
          return uomData.push(
            JSON.stringify({
              fluid_state: props.fluid_state.toLowerCase(),
              min: props.min_temperature_value !==""?
              props.min_temperature_value:
              props.min_temperature.toString(),
              
              //props.min_temperature
              //  ? props.min_temperature.toString()
              //  : '',
              norm: props.normal_temperature_value !==""?
              props.normal_temperature_value:
              props.normal_temperature.toString(),
              //props.normal_temperature
              //  ? props.normal_temperature.toString()
              //  : '',
              max: props.max_temperature_value !==""?
              props.max_temperature_value:
              props.max_temperature.toString(),
              //props.max_temperature
              //  ? props.max_temperature.toString()
              //  : '',
              fsd: props.full_temperature_value !==""?
              props.full_temperature_value:
              props.full_temperature.toString(),
              
              //props.full_temperature
              //  ? props.full_temperature.toString()
              //  : '',
              input_units:
                obj.unitType === TEMPERATURE_VALUES
                  ? obj.prevUnitKey !== -1
                    ? obj.units[obj.prevUnitKey].key
                    : ''
                  : '',
              output_units:
                obj.unitType === TEMPERATURE_VALUES
                  ? obj.unitKey !== -1
                    ? obj.units[obj.unitKey].key
                    : ''
                  : '',
              density_min: props.min_density
                ? props.min_density.toString()
                : '',
              density_norm: props.normal_density
                ? props.normal_density.toString()
                : '',
              density_max: props.max_density
                ? props.max_density.toString()
                : '',
              density_units: props.units_density.key,
              viscosity_min: '',
              viscosity_norm: '',
              viscosity_max: '',
              viscosity_units: 'CP',
              ref_density: props.gas_density,
              ref_density_units: props.units_gas_density.key,
            })
          );
        } else if (obj.unitType === AMBIENT_TEMPERATURE_VALUES) {
          return uomData.push(
            JSON.stringify({
              fluid_state: props.fluid_state.toLowerCase(),
              min: props.min_ambient ? props.min_ambient.toString() : '',
              norm: props.normal_ambient ? props.normal_ambient.toString() : '',
              max: props.max_ambient ? props.max_ambient.toString() : '',
              fsd: props.full_ambient ? props.full_ambient.toString() : '',
              input_units:
                obj.unitType === AMBIENT_TEMPERATURE_VALUES
                  ? obj.prevUnitKey !== -1
                    ? obj.units[obj.prevUnitKey].key
                    : ''
                  : '',
              output_units:
                obj.unitType === AMBIENT_TEMPERATURE_VALUES
                  ? obj.unitKey !== -1
                    ? obj.units[obj.unitKey].key
                    : ''
                  : '',
              density_min: props.min_density
                ? props.min_density.toString()
                : '',
              density_norm: props.normal_density
                ? props.normal_density.toString()
                : '',
              density_max: props.max_density
                ? props.max_density.toString()
                : '',
              density_units: props.units_density.key,
              viscosity_min: '',
              viscosity_norm: '',
              viscosity_max: '',
              viscosity_units: 'CP',
              ref_density: props.gas_density,
              ref_density_units: props.units_gas_density.key,
            })
          );
        }else if( obj.unitType === GAS_TEMP_VALUES){
          return uomData.push(
            JSON.stringify({
              fluid_state: props.fluid_state.toLowerCase(),
              min: '',
              norm: props.gas_temperature ? props.gas_temperature.toString() : '',
              max: '',
              fsd: '',
              input_units:
                obj.unitType === GAS_TEMP_VALUES
                  ? obj.prevUnitKey !== -1
                    ? obj.units[obj.prevUnitKey]
                    : ''
                  : '',
              output_units:
                obj.unitType === GAS_TEMP_VALUES
                  ? obj.unitKey !== -1
                    ? obj.units[obj.unitKey]
                    : ''
                  : '',
              density_min: props.min_density
                ? props.min_density.toString()
                : '',
              density_norm: props.normal_density
                ? props.normal_density.toString()
                : '',
              density_max: props.max_density
                ? props.max_density.toString()
                : '',
              density_units: props.units_density.key,
              viscosity_min: '',
              viscosity_norm: '',
              viscosity_max: '',
              viscosity_units: 'CP',
              ref_density: props.gas_density,
              ref_density_units: props.units_gas_density.key,
            })
          );
        }else if( obj.unitType === GAS_PRESSURE_VALUES){
          return uomData.push(
            JSON.stringify({
              fluid_state: props.fluid_state.toLowerCase(),
              min: '',
              norm: props.gas_pressure ? props.gas_pressure.toString() : '',
              max: '',
              fsd: '',
              input_units:
                obj.unitType === GAS_PRESSURE_VALUES
                  ? obj.prevUnitKey !== -1
                    ? obj.units[obj.prevUnitKey].key
                    : ''
                  : '',
              output_units:
                obj.unitType === GAS_PRESSURE_VALUES
                  ? obj.unitKey !== -1
                    ? obj.units[obj.unitKey].key
                    : ''
                  : '',
              density_min: props.min_density
                ? props.min_density.toString()
                : '',
              density_norm: props.normal_density
                ? props.normal_density.toString()
                : '',
              density_max: props.max_density
                ? props.max_density.toString()
                : '',
              density_units: props.units_density.key,
              viscosity_min: '',
              viscosity_norm: '',
              viscosity_max: '',
              viscosity_units: 'CP',
              ref_density: props.gas_density,
              ref_density_units: props.units_gas_density.key,
            })
          );
        }

      });
      const fetchData = async () => {
        axios({
          method: 'post',
          url: `${process.env.REACT_APP_BASE_URL}/mmipa/unit_converter/`,
          headers: {
            'Content-Type': 'application/json',
          },
          xsrfHeaderName: 'X-CSRFToken',
          xsrfCookieName: 'csrftoken',
          data: uomData[0],
        })
          .then((response) => {
            setUomResults([
              { min: response.data.Data.min },
              { norm: response.data.Data.norm },
              { max: response.data.Data.max },
              { fsd: response.data.Data.fsd },
            ]);
          })
          .catch(function (error) {
            setUomResults([]);
            console.log('error', error);
          });
      };
      fetchData();
    }
  }, [objectArray, convertFlag]);
  return uomResults.length > 0 ? uomResults : '';
};

export default useConvertForUom;
