import React from 'react';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import { RightNavListRequiredText } from './RightNavListRequiredText';

const RightNavListProcessVariablesLabels = props => {
  const { required, disabled } = props;
  return (
    <>
      <ul>
        <RightNavListRequiredText
          required={required.includes('minFlowRate')}
          value={props.min_flow_rate}
          label={'Min Flow Rate'}
          unitsDropdown={props.units_flow_rate.key}
        />
        <RightNavListRequiredText
          required={required.includes('normalFlowRate')}
          value={props.normal_flow_rate}
          label={'Normal Flow Rate'}
          unitsDropdown={props.units_flow_rate.key}
        />
        <RightNavListRequiredText
          required={required.includes('maxFlowRate')}
          value={props.max_flow_rate}
          label={'Max Flow Rate'}
          unitsDropdown={props.units_flow_rate.key}
        />
        <RightNavListRequiredText
          required={required.includes('fullFlowRate')}
          value={props.full_flow_rate}
          label={'Full Scale /Design Flow Rate'}
          unitsDropdown={props.units_flow_rate.key}
        />

        <RightNavListRequiredText
          required={required.includes('minLinePressure')}
          value={props.min_line_pressure}
          label={'Min Line Pressure'}
          unitsDropdown={props.units_line_pressure.key}
        />
        <RightNavListRequiredText
          required={required.includes('normalLinePressure')}
          value={props.normal_line_pressure}
          label={'Normal Line Pressure'}
          unitsDropdown={props.units_line_pressure.key}
        />
        <RightNavListRequiredText
          required={required.includes('maxLinePressure')}
          value={props.max_line_pressure}
          label={'Max Line Pressure'}
          unitsDropdown={props.units_line_pressure.key}
        />
        <RightNavListRequiredText
          required={required.includes('fullLinePressure')}
          value={props.full_line_pressure}
          label={'Full Scale /Design Line Pressure'}
          unitsDropdown={props.units_line_pressure.key}
        />

        <RightNavListRequiredText
          required={required.includes('minTemperature')}
          value={props.min_temperature}
          label={'Min Temperature'}
          unitsDropdown={props.units_temperature.key}
        />
        <RightNavListRequiredText
          required={required.includes('normalTemperature')}
          value={props.normal_temperature}
          label={'Normal Temperature'}
          unitsDropdown={props.units_temperature.key}
        />
        <RightNavListRequiredText
          required={required.includes('maxTemperature')}
          value={props.max_temperature}
          label={'Max Temperature'}
          unitsDropdown={props.units_temperature.key}
        />
        <RightNavListRequiredText
          required={required.includes('fullTemperature')}
          value={props.full_temperature}
          label={'Full Scale /Design Temperature'}
          unitsDropdown={props.units_temperature.key}
        />

        <RightNavListRequiredText
          required={required.includes('minAmbient')}
          value={props.min_ambient}
          label={'Min Ambient Temp.'}
          unitsDropdown={props.units_ambient.key}
        />
        <RightNavListRequiredText
          required={required.includes('normalAmbient')}
          value={props.normal_ambient}
          label={'Normal Ambient Temp.'}
          unitsDropdown={props.units_ambient.key}
        />
        <RightNavListRequiredText
          required={required.includes('maxAmbient')}
          value={props.max_ambient}
          label={'Max Ambient Temp.'}
          unitsDropdown={props.units_ambient.key}
        />
        <RightNavListRequiredText
          required={required.includes('fullAmbient')}
          value={props.full_ambient}
          label={'Full Scale /Design Ambient Temp.'}
          unitsDropdown={props.units_ambient.key}
        />
        {!props.calculate_density_variation || <li className="text-normal">Calculate For Density Variation</li>}

        {/*Fluid Properties*/}
        <RightNavListRequiredText
          required={required.includes('minDensity')}
          disabled={disabled.includes('minDensity')}
          value={props.min_density}
          label={'Min Density'}
          unitsDropdown={props.units_density.key}
        />
        <RightNavListRequiredText
          required={required.includes('normalDensity')}
          disabled={disabled.includes('normalDensity')}
          value={props.normal_density}
          label={'Normal Density'}
          unitsDropdown={props.units_density.key}
        />
        <RightNavListRequiredText
          required={required.includes('maxDensity')}
          disabled={disabled.includes('maxDensity')}
          value={props.max_density}
          label={'Max Density'}
          unitsDropdown={props.units_density.key}
        />

        <RightNavListRequiredText
          required={required.includes('minViscosity')}
          disabled={disabled.includes('minViscosity')}
          value={props.min_viscosity}
          label={'Min Viscosity'}
          unitsDropdown={props.units_viscosity.key}
        />
        <RightNavListRequiredText
          required={required.includes('normalViscosity')}
          disabled={disabled.includes('normalViscosity')}
          value={props.normal_viscosity}
          label={'Normal Viscosity'}
          unitsDropdown={props.units_viscosity.key}
        />
        <RightNavListRequiredText
          required={required.includes('maxViscosity')}
          disabled={disabled.includes('maxViscosity')}
          value={props.max_viscosity}
          label={'Max Viscosity'}
          unitsDropdown={props.units_viscosity.key}
        />

        <RightNavListRequiredText
          required={required.includes('kFlow')}
          disabled={disabled.includes('kFlow')}
          value={props.k_flow_consistency_index}
          label={'K(Flow Consistency Index)'}
        />
        <RightNavListRequiredText
          required={required.includes('nFlow')}
          disabled={disabled.includes('nFlow')}
          value={props.n_flow_behavior}
          label={'n(Flow Behavior)'}
        />
        <RightNavListRequiredText
          required={required.includes('minVapor')}
          disabled={disabled.includes('minVapor')}
          value={props.min_vapor}
          label={'Vapor Pressure'}
          unitsDropdown={props.units_vapor.key}
        />
        <RightNavListRequiredText
          required={required.includes('maxFlowAccuracy')}
          disabled={disabled.includes('maxFlowAccuracy')}
          value={props.max_flow_acc}
          label={'Max Flow Accuracy'}
        />
        <RightNavListRequiredText
          required={required.includes('maxPressureDrop')}
          disabled={disabled.includes('maxPressureDrop')}
          value={props.max_pressure_drop}
          label={'Max Pressure Drop'}
          unitsDropdown={props.units_max_pressure_drop.key}
        />
        <RightNavListRequiredText
          required={required.includes('sonicVelocity')}
          disabled={disabled.includes('sonicVelocity')}
          value={props.sonic_velocity}
          label={'Sonic Velocity'}
          unitsDropdown={props.units_sonic_velocity.key}
        />
        <RightNavListRequiredText
          required={required.includes('compressibility')}
          disabled={disabled.includes('compressibility')}
          value={props.compressibility}
          label={'Compressibility'}
        />
        <RightNavListRequiredText
          required={required.includes('atmosphericPressure')}
          disabled={disabled.includes('atmosphericPressure')}
          value={props.pressure_atmospheric}
          label={'Atmospheric Pressure'}
          unitsDropdown={props.units_pressure_atmospheric.key}
        />
        <RightNavListRequiredText
          required={required.includes('gasPressure')}
          disabled={disabled.includes('gasPressure')}
          value={props.gas_pressure}
          label={'Pressure'}
          unitsDropdown={props.units_gas_pressure.key}
        />
        <RightNavListRequiredText
          required={required.includes('gasTemperature')}
          disabled={disabled.includes('gasTemperature')}
          value={props.gas_temperature}
          label={'Temperature'}
          unitsDropdown={props.units_gas_temperature.key}
        />
        <RightNavListRequiredText
          required={required.includes('gasDensity')}
          disabled={disabled.includes('gasDensity')}
          value={props.gas_density}
          label={'Density'}
          unitsDropdown={props.units_gas_density.key}
        />
        <RightNavListRequiredText
          required={required.includes('specificGravity')}
          disabled={disabled.includes('specificGravity')}
          value={props.specific_gravity}
          label={'Specific Gravity'}
        />
        <RightNavListRequiredText
          required={required.includes('molecularWeight')}
          disabled={disabled.includes('molecularWeight')}
          value={props.molecular_weight}
          label={'Molecular Weight'}
        />

        {!props.non_newtonian_liquid || <li className="text-normal">Non-Newtonian Liquid</li>}
      </ul>
    </>
  )
};

const mapStateToProps = state => ({
  min_flow_rate: state.ProcessVariables.min_flow_rate,
  normal_flow_rate: state.ProcessVariables.normal_flow_rate,
  max_flow_rate: state.ProcessVariables.max_flow_rate,
  min_line_pressure: state.ProcessVariables.min_line_pressure,
  min_temperature: state.ProcessVariables.min_temperature,
  min_ambient: state.ProcessVariables.min_ambient,
  normal_line_pressure: state.ProcessVariables.normal_line_pressure,
  normal_temperature: state.ProcessVariables.normal_temperature,
  normal_ambient: state.ProcessVariables.normal_ambient,
  max_line_pressure: state.ProcessVariables.max_line_pressure,
  max_temperature: state.ProcessVariables.max_temperature,
  max_ambient: state.ProcessVariables.max_ambient,
  units_flow_rate: state.ProcessVariables.units_flow_rate,
  units_line_pressure: state.ProcessVariables.units_line_pressure,
  units_temperature: state.ProcessVariables.units_temperature,
  units_ambient: state.ProcessVariables.units_ambient,
  units_full: state.ProcessVariables.units_full,
  full_flow_rate: state.ProcessVariables.full_flow_rate,
  full_line_pressure: state.ProcessVariables.full_line_pressure,
  full_temperature: state.ProcessVariables.full_temperature,
  full_ambient: state.ProcessVariables.full_ambient,
  units_max_pressure_drop: state.ProcessVariables.units_max_pressure_drop,
  max_pressure_drop: state.ProcessVariables.max_pressure_drop,
  max_flow_acc: state.ProcessVariables.max_flow_acc,
  units_density: state.ProcessVariables.units_density,
  min_density: state.ProcessVariables.min_density,
  normal_density: state.ProcessVariables.normal_density,
  max_density: state.ProcessVariables.max_density,
  units_viscosity: state.ProcessVariables.units_viscosity,
  min_viscosity: state.ProcessVariables.min_viscosity,
  normal_viscosity: state.ProcessVariables.normal_viscosity,
  max_viscosity: state.ProcessVariables.max_viscosity,
  gas_density: state.ProcessVariables.gas_density,
  units_gas_density: state.ProcessVariables.units_gas_density,
  sonic_velocity: state.ProcessVariables.sonic_velocity,
  compressibility: state.ProcessVariables.compressibility,
  units_sonic_velocity: state.ProcessVariables.units_sonic_velocity,
  units_gas_pressure: state.ProcessVariables.units_gas_pressure,
  gas_pressure: state.ProcessVariables.gas_pressure,
  units_gas_temperature: state.ProcessVariables.units_gas_temperature,
  gas_temperature: state.ProcessVariables.gas_temperature,
  non_newtonian_liquid: state.ProcessVariables.non_newtonian_liquid,
  k_flow_consistency_index: state.ProcessVariables.k_flow_consistency_index,
  n_flow_behavior: state.ProcessVariables.n_flow_behavior,
  min_vapor: state.ProcessVariables.min_vapor,
  units_vapor: state.ProcessVariables.units_vapor,
  pressure_atmospheric: state.ProcessVariables.pressure_atmospheric,
  units_pressure_atmospheric: state.ProcessVariables.units_pressure_atmospheric,
  specific_gravity: state.ProcessVariables.specific_gravity,
  molecular_weight: state.ProcessVariables.molecular_weight,
  units_density_heading: state.ProcessVariables.units_density_heading,
  calculate_density_variation: state.ProcessVariables.calculate_density_variation,
});

export default connect(
  mapStateToProps, {}
)(RightNavListProcessVariablesLabels);
