import React from 'react';
import {
  Modal
} from 'react-bootstrap';
import '../../styles/App.scss';
import './modal.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CustomButton } from '../CustomButton/CustomButton';
import CustomTranslation from '../CustomTranslation/CustomTranslation';
const SizingResultErrorMessageModal = props => {

  return (
    <>
      <Modal centered show={props.show} onHide={props.handleClose} animation={false}>
        <Modal.Header className='modal-header-fpa' closeButton>
          <Modal.Title className="modal-title">
            {
              props.warning_limit ?
              <CustomTranslation data='XXSPECIFY_MMI_PR_WARNING' />:
              <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_ERR' /> 
            }
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-fpa'>
          <p className="main-text"><CustomTranslation data='XXSPECIFY_MMI_SR_LBL_MESSAGES' /></p>
          {props.missingInputValues.map(errors => 
          <p className="modal-message"><CustomTranslation data={errors} /></p> )}
          {props.errorValues.map(errors => 
          <p className="modal-message">{errors}</p>)}
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            classNames="btn btn-close-modal btn-green"
            label="OK"
            onClick={props.handleClose}
            arrow="right"
            size="lg"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SizingResultErrorMessageModal;
