import React from 'react';
import ReactDOM from 'react-dom';


class CompareWindow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.containerEl = document.createElement('div');
    this.externalWindow = null;
  }
  render() {
    if (!this.containerEl) {
      return null;
    }
    return ReactDOM.createPortal(this.props.children, this.containerEl);
  }

  componentDidMount() {
    this.externalWindow = window.open('', '', 'width=980,height=700');
    this.externalWindow.document.body.appendChild(this.containerEl);
    this.externalWindow.document.title = 'Sensor Comparison';
    this.externalWindow.addEventListener('beforeunload', () => {
      this.props.closeWindowPortal();
    });
    let styleSheet = this.externalWindow.document.createElement('style');

    // NOTE: the download-button styles are taken from custom-button.scss, and should change
    //  if those do.  In the future this popup should be refactored as a modal and
    //  the styles added to a file.
    styleSheet.textContent = `
      .comparison-popout-page { font-family: Arial, serif; }
      .comparison-table { width: 100%; height: 100%; }
      h1.content-title { font-weight: normal; font-size: 30px; display: inline-block; }
      th { max-width: 120px; text-align: left; }
      tr { border-top: 1px solid #ddd; }
      table { border-collapse: collapse; font-size: 14px; }
      table > tbody > tr > td { padding: 8px; }
      .process-table > tbody > tr > td { text-align: center !important;}
      .download-button {
        float: right;
        border-radius: 0;
        height: 50px;
        font-size: 12px;
        width: 140px;
        letter-spacing: .5px;
        background-color: #00aa7e;
        border: none;
        color: white;
        font-weight: bold;
      }
      .fullDesign{
        width: 20px !important;
        text-align: center;
      }
      .processUnits{
        font-size: 0.8rem;
        font-weight: normal;
        margin-top: 1px;
      }`;
    this.externalWindow.document.head.append(styleSheet);
  }

  componentWillUnmount() {
    this.externalWindow.close();
  }
}

export default CompareWindow;
