import { combineReducers } from 'redux';
import MeasurementType from './MeasurementType';
import BasicPageRightNavList from './BasicPageRightNavList';
import EquipmentSelection from './EquipmentSelection';
import ApplicationRequirements from './ApplicationRequirements';
import FluidSelection from './FluidSelection';
import ProcessVariables from './ProcessVariables';
import Layout from './Layout';
import SizingResults from './SizingResults';
import DropdownData from './DropdownData';
import Preferences from './Preferences';
import LanguageList from './LanguageList';
import OfflineFlowLogin from './OfflineFlowLogin';
import MySizings from './MySizings';
import { RESET_APP } from '../actionTypes';
import TranslationData from './TranslationData';
import AppHeader from './AppHeader';
import ClearUom from './ClearUom';
import ErrorMessage from './ErrorMessage';
import SizingResultErrorMessage from './SizingResultErrorMessage';
import ClearAllField from './ClearAllField';

const appReducer = combineReducers({
  Layout,
  MeasurementType,
  BasicPageRightNavList,
  EquipmentSelection,
  ApplicationRequirements,
  FluidSelection,
  ProcessVariables,
  SizingResults,
  Preferences,
  DropdownData,
  LanguageList,
  OfflineFlowLogin,
  MySizings,
  TranslationData,
  AppHeader,
  ClearUom,
  ErrorMessage,
  SizingResultErrorMessage,
  ClearAllField,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_APP) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
