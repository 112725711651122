import {
    SIZING_ERROR_MESSAGE,
    APPLICATION_NAME_ERROR, 
    REPORT_FLAG,
    WARNING_LIMIT,
} from '../actionTypes';

const initialState = {
    sizing_error_message: false,
    aplication_name_error:false, 
    report_flag: true,
    warning_limit: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SIZING_ERROR_MESSAGE: {
            return {
                ...state,
                sizing_error_message: action.payload.id
            }
        }
        case APPLICATION_NAME_ERROR: {
            return {
                ...state,
                aplication_name_error: action.payload.id
            }
        }
        case REPORT_FLAG: {
            return {
                ...state,
                report_flag : action.payload.id
            }
        }
        case WARNING_LIMIT: {
            return {
                ...state, 
                warning_limit: action.payload.id
            }
        }
        default: {
            return state;
        }
    }
}

