import React, { useState, useEffect, useMemo } from 'react';
import {
  Col,
  Row,
  Modal
} from 'react-bootstrap';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CustomDropdownNavPages } from '../../components/CustomDropdown/CustomDropdownNavPages';
import { CustomInputFieldNavPages } from '../../components/CustomInputField/CustomInputFieldNavPages'
import { CustomButton } from '../../components/CustomButton/CustomButton';
import axios from 'axios';
import CustomTranslationText from '../../components/CustomTranslation/CustomTranslationText';
import PreferencesModal from '../../components/CustomModals/PreferencesModal';
import { SAVE_PREF_URL, GET_PRECISION_DATA, GET_SAVED_PREF} from '../../constants/constants';
import { connect } from 'react-redux';
import { getUnitsKeyValue } from '../../UtilityFuntions/getUnitsKeyValue';
import { useOktaAuth } from '@okta/okta-react';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';
import {
  flowRateUnitLiquidSlurryPref,
  flowRateUnitGasPref,
  temperatureUnitPref,
  ambientTemperatureUnitPref,
  linePressureUnitPref,
  densityUnitPref,
  viscosityUnitPref,
  vaporPressureUnitPref,
  atmosphericUnitPref,
  gasOnlyPressureUnitPref,
  gasOnlyPressurePref,
  gasOnlyTemperatureUnitPref,
  gasOnlyTemperaturePref,
  gasOnlyDensityUnitPref,
  sonicVelocityUnitPref,
  decimalFactorPref,
  flowRateDecimalFactorPref,
  temperatureDecimalFactorPref,
  linePressureDecimalFactorPref,
  uomAutoConversionPref,
  refreshPref,
  savedPreferences,
  errorMessage,
  unitsFlowRate,
  unitsGasFlowRate,
  unitsDensity,
  unitsTemperature,
  unitsAmbient,
  unitsLinePressure,
  unitsViscosity,
  unitsVapor,
  unitsGasDensity,
  unitsGasPressure,
  unitsGasTemperature,
  unitsSonicVelocity,
  unitsPressureAtmospheric,
  wettedMaterial, 
  wettedMaterialValue,
  wettedMaterialLabel,
  allowPrefBaseRef, 
  compositionMethodFlag,
  savedPreferencesFlag
} from '../../redux/actions';
import { decimalPlaceDropdownValues } from '../../UtilityFuntions/decimalFactor';
import { getUnitsValue } from '../../UtilityFuntions/getUnitsValue';
import ErrorModal from '../../components/CustomModals/ErrorModal';
const email = window.email ? window.email : '';

const Preferences = props => {
  const languageCode = props.user_language && props.user_language.value
  ? props.user_language.value
  : "US";
  const [showlink, setLink] = useState(false);
  const { authState, oktaAuth } = useOktaAuth();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const handleClose = () => {setShow(false) ;setLink(false)};
  const handleShow = () => setShow(true);
  const [preferencesFlag, setPreferencesFlag] = useState(true)
  const handleErrorClose = () => {
    props.errorMessage(false);
    // window.location.reload(false);
  }
  const handleErrorShow = () => props.error_message;
  // saved user values
  const [flowRateUnit, setFlowRateUnitLiquidSlurryPref] = useState('');


  const [flowRateUnitGas, setFlowRateUnitGasPref] = useState('');
  const [temperatureUnit, setTemperatureUnitPref] = useState('');
  const [ambientTemperatureUnit, setAmbientTemperatureUnitPref] = useState('');
  const [linePressureUnit, setLinePressureUnitPref] = useState('');
  const [densityUnit, setDensityUnitPref] = useState('');
  const [viscosityUnit, setViscosityUnitPref] = useState('');
  const [vaporPressureUnit, setVaporPressureUnitPref] = useState('');
  const [atmosphericUnit, setAtmosphericUnitPref] = useState('');
  const [gasOnlyPressureUnit, setGasOnlyPressureUnitPref] = useState('');
  const [gasOnlyPressure, setGasOnlyPressurePref] = useState('');
  const [gasOnlyTemperatureUnit, setGasOnlyTemperatureUnitPref] = useState('');
  const [gasOnlyTemperature, setGasOnlyTemperaturePref] = useState('');
  const [gasOnlyDensityUnit, setGasOnlyDensityUnitPref] = useState('');
  const [sonicVelocityUnit, setSonicVelocityUnitPref] = useState('');
  const [decimalFactor, setDecimalFactorPref] = useState('');
  const [flowRateDecimalFactor, setFlowRateDecimalFactorPref] = useState('');
  const [temperatureDecimalFactor, setTemperatureDecimalFactorPref] = useState('');
  const [linePressureDecimalFactor, setLinePressureDecimalFactorPref] = useState('');
  const [uomAutoConversion, setUomAutoConversionPref] = useState('');
  const [wettedMaterial, setWettedMaterial] = useState('');
  const [wettedMaterialKey, setWettedMaterialKey] = useState('');
  const [buttonEnabled, setButtonEnabled] = useState(true);

  // populate dropdowns
  const [flowRateLiquidSlurryUnits, setFlowRateLiquidSlurryUnits] = useState([]);
  const [flowRateGasUnits, setFlowRateGasUnits] = useState([]);
  const [temperatureUnits, setTemperatureUnits] = useState([]);
  const [nonVaporPressureUnits, setNonVaporPressureUnits] = useState([]);
  const [vaporPressureUnits, setVaporPressureUnits] = useState([]);
  const [viscosityUnits, setViscosityUnits] = useState([]);
  const [densityUnits, setDensityUnits] = useState([]);
  const [sonicVelocityUnits, setSonicVelocityUnits] = useState([]);
  const [decimalFactorUnits, setDecimalFactorUnits] = useState([]);
  const [decimalFactorUnitsTranslation, setDecimalFactorUnitsTranslation] = useState([]);
  const [uomAutoConversionUnits, setUomAutoConversionUnits] = useState([]);
  const [wettedMaterialValues, setWettedMaterialValues] = useState([]);

  useEffect(() => {
    props.savedPreferencesFlag(false)
    if(preferencesFlag){
      props.allowPrefBaseRef(false)
    }
  }, [])
  props.compositionMethodFlag(true)
  

  const setInitialValues = results => {
    results.flowrate_unit ? setFlowRateUnitLiquidSlurryPref(results.flowrate_unit) : setFlowRateUnitLiquidSlurryPref(props.flow_rate_unit_for_liquid_slurry_pref);
    results.flowrategas_unit ? setFlowRateUnitGasPref(results.flowrategas_unit) : setFlowRateUnitGasPref(props.flow_rate_unit_for_gas_pref);
    results.temperature_unit ? setTemperatureUnitPref(results.temperature_unit) : setTemperatureUnitPref(props.temperature_unit_pref);
    results.ambient_temperature_unit ? setAmbientTemperatureUnitPref(results.ambient_temperature_unit) : setAmbientTemperatureUnitPref(props.ambient_temperature_unit_pref);
    results.linepressure_unit ? setLinePressureUnitPref(results.linepressure_unit) : setLinePressureUnitPref(props.line_pressure_unit_pref);
    results.density_unit ? setDensityUnitPref(results.density_unit) : setDensityUnitPref(props.density_unit_pref);
    results.viscosity_unit ? setViscosityUnitPref(results.viscosity_unit) : setViscosityUnitPref(props.viscosity_unit_pref);
    results.vaporpressure_unit ? setVaporPressureUnitPref(results.vaporpressure_unit) : setVaporPressureUnitPref(props.vapor_pressure_unit_pref);
    results.atmpress_unit ? setAtmosphericUnitPref(results.atmpress_unit) : setAtmosphericUnitPref(props.atmospheric_unit_pref);
    results.gasonly_pressure_unit ? setGasOnlyPressureUnitPref(results.gasonly_pressure_unit) : setGasOnlyPressureUnitPref(props.gas_only_pressure_unit_pref);
    results.gasonly_pressure ? setGasOnlyPressurePref(results.gasonly_pressure) : setGasOnlyPressurePref(props.gas_only_pressure_pref)
    results.gasonly_temperature_unit ? setGasOnlyTemperatureUnitPref(results.gasonly_temperature_unit) : setGasOnlyTemperatureUnitPref(props.gas_only_temperature_unit_pref);
    results.gasonly_temperature ? setGasOnlyTemperaturePref(results.gasonly_temperature) : setGasOnlyTemperaturePref(props.gas_only_temperature_pref);
    results.gasonly_density_unit ? setGasOnlyDensityUnitPref(results.gasonly_density_unit) : setGasOnlyDensityUnitPref(props.gas_only_density_unit_pref);
    results.sonic_velocity_unit ? setSonicVelocityUnitPref(results.sonic_velocity_unit) : setSonicVelocityUnitPref(props.sonic_velocity_unit_pref);
    results.flow_rate_precision ? setFlowRateDecimalFactorPref(results.flow_rate_precision) : setFlowRateDecimalFactorPref(props.flow_rate_decimal_factor);
    results.temperature_precision ? setTemperatureDecimalFactorPref(results.temperature_precision) : setTemperatureDecimalFactorPref(props.temperature_decimal_factor);
    results.line_pressure_precision ? setLinePressureDecimalFactorPref(results.line_pressure_precision) : setLinePressureDecimalFactorPref(props.line_pressure_decimal_factor);
    results.all_other_decimal_factor ? setDecimalFactorPref(results.all_other_decimal_factor) : setDecimalFactorPref(props.decimal_factor_pref);
    results.auto_con_uom ? setUomAutoConversionPref(results.auto_con_uom) : setUomAutoConversionPref(props.uom_auto_conversion_pref);
    let index = props.wetted_material_units ? props.wetted_material_units.findIndex(i=>i.value===results.wetted_material) : -1;
    let label = index !== -1 ? props.wetted_material_units[index].label : results.wetted_material;
    results.wetted_material ? setWettedMaterial(label) : setWettedMaterial(props.wetted_material_pref);
  }

  useEffect(() => {
    const fetchData = async () => {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}${GET_PRECISION_DATA}`,
        headers: {
          "Content-Type": "application/json",
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        data: {
          "language":  props.query_params.language?props.query_params.language:languageCode,
        }
      })
        .then(function (response) {
          setDecimalFactorUnitsTranslation(response.data.data)
        })
        .catch(function (error) {
          console.log('error', error)
        }) 
    };
    fetchData();
  }, [])

  const savePrefs = () => {
    setPreferencesFlag(false)
    props.allowPrefBaseRef(true)
    const data = JSON.stringify({
      'email_id': props.email,
      'flowrate_unit': flowRateUnit,
      'temperature_unit': temperatureUnit,
      'ambient_temperature_unit': ambientTemperatureUnit,
      'linepressure_unit': linePressureUnit,
      'density_unit': densityUnit,
      'viscosity_unit': viscosityUnit,
      'vaporpressure_unit': vaporPressureUnit,
      'gasonly_density_unit': gasOnlyDensityUnit,
      'gasonly_temperature_unit': gasOnlyTemperatureUnit,
      'sonic_velocity_unit': sonicVelocityUnit,
      'gasonly_pressure_unit': gasOnlyPressureUnit,
      'all_other_decimal_factor': decimalFactor,
      'flow_rate_precision': flowRateDecimalFactor,
      'temperature_precision': temperatureDecimalFactor,
      'line_pressure_precision': linePressureDecimalFactor,
      'last_update_date': '',
      'last_updated_by': '',
      'creation_date': '',
      'created_by': '',
      'last_update_login': '-1',
      'flowrategas_unit': flowRateUnitGas,
      'result_list': 'ALL_RESULTS',
      'atmpress_unit': atmosphericUnit,
      'auto_con_uom': uomAutoConversion,
      'gasonly_pressure': gasOnlyPressure,
      'gasonly_temperature': gasOnlyTemperature,
      'wetted_material' : wettedMaterialKey
    });

    if (props.email!=='') {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}${SAVE_PREF_URL}`,
        headers: {
          'Content-Type': 'application/json'
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        data: data
      })
        .then(response => {
          props.refreshPref(true);
          const success = response.data.data;
          if (success.length > 0) {
            const successMessage = `${success}.`;
            setMessage(successMessage);
            props.savedPreferencesFlag(true);
            props.savedPreferences(JSON.parse(data));
          } else {
            setMessage('No data Returned From Database.');
          }
          setShow(true);
        })
        .catch(error => {
          props.refreshPref(false);
          console.log('error', error);
        });
    }
    else{
      props.flowRateUnitLiquidSlurryPref(flowRateUnit)
      props.unitsFlowRate(getUnitsKeyValue(
        flowRateUnit,
        'flowRateLiquidSlurry',
        props.units_data
      ))
      props.unitsDensity(getUnitsKeyValue(
        densityUnit,
        'density',
        props.units_data
      ))
      props.unitsTemperature(
        getUnitsKeyValue(
          temperatureUnit,
          'temperature',
          props.units_data
        )
      );
      props.unitsGasFlowRate(
        getUnitsKeyValue(
          flowRateUnitGas,
          'flowRateGas',
          props.units_data
        )
      );
      props.unitsAmbient(
        getUnitsKeyValue(
          ambientTemperatureUnit,
          'temperature',
          props.units_data
        )
      );
      props.unitsLinePressure(
        getUnitsKeyValue(
          linePressureUnit,
          'nonVaporPressure',
          props.units_data
        )
      );
      props.unitsViscosity(getUnitsKeyValue(
        viscosityUnit,
        'viscosity',
        props.units_data
      )) 
      props.unitsVapor(getUnitsKeyValue(
        vaporPressureUnit,
        'vaporPressure',
        props.units_data
      ))
      props.unitsSonicVelocity( getUnitsKeyValue(
        sonicVelocityUnit,
        'sonicVelocity',
        props.units_data
      ));
      props.unitsGasPressure(getUnitsKeyValue(
        gasOnlyPressureUnit,
        'vaporPressure',
        props.units_data
      ),)
      props.unitsGasTemperature( getUnitsKeyValue(
        gasOnlyTemperatureUnit,
        'temperature',
        props.units_data
      ));
      props.unitsGasDensity( getUnitsKeyValue(
        gasOnlyDensityUnit,
        'density',
        props.units_data
      ));
      props.unitsPressureAtmospheric( getUnitsKeyValue(
        atmosphericUnit,
        'vaporPressure',
        props.units_data
      ))
      props.flowRateUnitGasPref(flowRateUnitGas)
      props.temperatureUnitPref(temperatureUnit)
      props.ambientTemperatureUnitPref(ambientTemperatureUnit)
      props.linePressureUnitPref(linePressureUnit)
      props.densityUnitPref(densityUnit)
      props.viscosityUnitPref(viscosityUnit)
      props.vaporPressureUnitPref(vaporPressureUnit)
      props.atmosphericUnitPref(atmosphericUnit)
      props.gasOnlyPressureUnitPref(gasOnlyPressureUnit)
      props.gasOnlyPressurePref(gasOnlyPressure)
      props.gasOnlyTemperatureUnitPref(gasOnlyTemperatureUnit)
      props.gasOnlyTemperaturePref(gasOnlyTemperature)
      props.gasOnlyDensityUnitPref(gasOnlyDensityUnit)
      props.sonicVelocityUnitPref(sonicVelocityUnit)
      props.decimalFactorPref(decimalFactor)
      props.flowRateDecimalFactorPref(flowRateDecimalFactor)
      props.temperatureDecimalFactorPref(temperatureDecimalFactor)
      props.linePressureDecimalFactorPref(linePressureDecimalFactor)
      props.uomAutoConversionPref(uomAutoConversion)
      props.savedPreferences(JSON.parse(data));
      setMessage(CustomTranslationText('XXSPECIFY_MMI_PREF_SAVE_TEST', props));
      setShow(true);
      setLink(true)
    }
    if(props.wetted_material_units){
    let indexWettedMaterial =  props.wetted_material_units.findIndex(x=>x.label===wettedMaterial)
    let index = indexWettedMaterial !== -1 ? indexWettedMaterial : 0
    props.wettedMaterial(props.wetted_material_units[index].description)
    props.wettedMaterialValue(props.wetted_material_units[index].value)
    props.wettedMaterialLabel(props.wetted_material_units[index].label)
    }
  }

  //get saved preference values
  useEffect(() => {
    const fetchData = async () => {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}${GET_SAVED_PREF}`,
        headers: {
          'Content-Type': 'application/json',
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        data: {
          email_id: props.email,
          language: props.query_params.language
            ? props.query_params.language
            : languageCode,
        },
      })
        .then(function (response) {
          setInitialValues(response.data.data[0]);
        })
        .catch(function (error) {
          console.log('error', error);
        });
    };
    if (props.email !== '' || props.refresh_pref) {
      fetchData();
    }
    props.refreshPref(false);
  }, []);

  // get the data on page-load
  useEffect(() => {
    if (props.saved_preferences) {
      setInitialValues(props.saved_preferences);
    }
  }, [props.saved_preferences]);

  // useEffect(() => {
  //   email ? setButtonEnabled(true) : setButtonEnabled(false);
  // }, []);
  const login = async () => {
    oktaAuth.signInWithRedirect({ originalUri: '/' });
  };
  useEffect(() => {
    setFlowRateLiquidSlurryUnits(props.units_data.flowRateLiquidSlurry);
    setFlowRateGasUnits(props.units_data.flowRateGas ? props.units_data.flowRateGas : undefined);
    setTemperatureUnits(props.units_data.temperature);
    setNonVaporPressureUnits(props.units_data.nonVaporPressure);
    setVaporPressureUnits(props.units_data.vaporPressure);
    setViscosityUnits(props.units_data.viscosity);
    setDensityUnits(props.units_data.density);
    setSonicVelocityUnits(props.units_data.sonicVelocity);
    setDecimalDropdowns();
    uomAutoConversionDropdown();
    wettedMaterialDropdown();
  }, [decimalFactorUnitsTranslation, props]);

  const setDecimalDropdowns = () => {
    let decimals = [];
    
    if (decimalFactorUnitsTranslation.length > 0) {
      decimalFactorUnitsTranslation.map(unit => {
        decimals.push({key: unit.meaning, value: unit.meaning})
      })
    } else {
      decimalPlaceDropdownValues().map(unit => {
        decimals.push({ key: unit.label, value: unit.label })
      });
    }
    
    setDecimalFactorUnits(decimals)
  }

  const uomAutoConversionDropdown = () => {
    setUomAutoConversionUnits([{ key: 'ENABLED', value: 'ENABLED' }, { key: 'DISABLED', value: 'DISABLED' }]);
  }

  const wettedMaterialDropdown = () => {
    let wetted_material = []
    if(props.wetted_material_units){
      props.wetted_material_units.map((option)=>{
        wetted_material.push({key: option.value, value: option.label})
        })   
    } 
    setWettedMaterialValues([...wetted_material]);
  }

  // TODO: 'key ? key : 0' is a bit of a hack, need to determine why, when key should be zero, it is undefined
  const handleFlowLiquidSlurryPref = key => setFlowRateUnitLiquidSlurryPref(flowRateLiquidSlurryUnits[key ? key : 0].key);
  const handleFlowRateUnitGasPref = key => setFlowRateUnitGasPref(flowRateGasUnits[key ? key : 0].key);
  const handleTemperatureUnitPref = key => setTemperatureUnitPref(temperatureUnits[key ? key : 0].key);
  const handleAmbientTemperatureUnitPref = key => setAmbientTemperatureUnitPref(temperatureUnits[key ? key : 0].key);
  const handleLinePressureUnitPref = key => setLinePressureUnitPref(nonVaporPressureUnits[key ? key : 0].key);
  const handleDensityUnitPref = key => setDensityUnitPref(densityUnits[key ? key : 0].key);
  const handleViscosityUnitPref = key => setViscosityUnitPref(viscosityUnits[key ? key : 0].key);
  const handleVaporPressureUnitPref = key => setVaporPressureUnitPref(vaporPressureUnits[key ? key : 0].key);
  const handleAtmosphericUnitPref = key => setAtmosphericUnitPref(vaporPressureUnits[key ? key : 0].key);
  const handleGasOnlyPressureUnitPref = key => setGasOnlyPressureUnitPref(vaporPressureUnits[key ? key : 0].key);
  const handleGasOnlyPressurePref = key => setGasOnlyPressurePref(key);
  const handleGasOnlyTemperatureUnitPref = key => setGasOnlyTemperatureUnitPref(temperatureUnits[key ? key : 0].key);
  const handleGasOnlyTemperaturePref = key => setGasOnlyTemperaturePref(key);
  const handleGasOnlyDensityUnitPref = key => setGasOnlyDensityUnitPref(densityUnits[key ? key : 0].key);
  const handleSonicVelocityUnitPref = key => setSonicVelocityUnitPref(sonicVelocityUnits[key ? key : 0].key);
  const handleDecimalFactorPref = key => setDecimalFactorPref(decimalFactorUnits[key ? key : 0].key);
  const handleFlowRateDecimalFactorPref = key => setFlowRateDecimalFactorPref(decimalFactorUnits[key ? key : 0].key);
  const handleTemperatureDecimalFactorPref = key => setTemperatureDecimalFactorPref(decimalFactorUnits[key ? key : 0].key);
  const handleLinePressureDecimalFactorPref = key => setLinePressureDecimalFactorPref(decimalFactorUnits[key ? key : 0].key);
  const handleUomAutoConversionPref = key => setUomAutoConversionPref(uomAutoConversionUnits[key ? key : 0].key);
  const handleWettedMaterialPref = key => { 
      setWettedMaterialKey(wettedMaterialValues[key ? key : 0].key);
      setWettedMaterial(wettedMaterialValues[key ? key : 0].value);
  }

  const dropdowns = (id, name, chosenOption, dropdownArray, eventHandler) => {
    return (
      <Col md={3} sm={12}>
        <CustomDropdownNavPages
          id={id}
          name={name}
          dropdownArray={dropdownArray}
          eventHandler={eventHandler}
          chosenOption={chosenOption}
        />
      </Col>
    )
  };

  // TODO useMemo to remove re-rendering
  // const dropdownLabel = useMemo((id, label) => {
  const dropdownLabel = (id, label) => {
    return (
      <Col md={3} sm={12}>
        <label htmlFor={id} className='pref-label'>{label}</label>
      </Col>
    )
  }

  const textField = (name, id, value, onInput) => {
    return (
      <Col md={3} sm={12}>
        <CustomInputFieldNavPages
          name='nav-input-form-control'
          id={id}
          value={value}
          borderOutlined={true}
          onInput={onInput}
        />
      </Col>
    )
  }

  return (
    <>
      <ErrorModal show={props.error_message} handleClose={handleErrorClose} handleShow={handleErrorShow} />
      <Modal centered show={show} onHide={handleClose} animation={false}>
        <Modal.Header className='modal-header-fpa' closeButton>
          <Modal.Title className="modal-title-confirmation"><CustomTranslation data="XXSPECIFY_MMI_PR_LBL_MESSAGE" /> </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-fpa'>
          {/*<p className="main-text">Please attach related File.</p>*/}
          <p className="info-text">{message}</p>
          {/* {showlink?<a href="https://www.emerson.com/catalog/LogonForm?locale=en-us&myAcctMain=1&catalogId=20051&storeId=20151&langId=-1" target="_blank" >click here to login</a>:''} */}
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
              classNames="btn btn-close-modal btn-green"
              label="OK"
              onClick={handleClose}
              arrow="right"
              size="lg"
            />
 
        </Modal.Footer>
      </Modal>
      <div className='preferences'>
        <h4 className='title'><CustomTranslation data='XXSPECIFY_MMI_TOUR_TITLE_12' /></h4>
        <div className='selection-container'>
          <h5 className='update-pref-text'>{CustomTranslationText('XXSPECIFY_MMI_PR_LBL_MSG1',props)}</h5>
          <Row className='pref-row'>
            {dropdownLabel('flow-rate-liquid-slurry', CustomTranslationText('XXSPECIFY_MMI_PR_LBL_FLOUNILIQ',props).toUpperCase())}
            {dropdowns('flow-rate-liquid-slurry', 'pref-dropdown', getUnitsValue(flowRateUnit, 'flowRateLiquidSlurry', props.units_data), flowRateLiquidSlurryUnits, handleFlowLiquidSlurryPref)}
            {/* flow rate */}
            {dropdownLabel('flow-rate-decimal-factor', CustomTranslationText('XXSPECIFY_MMI_PR_LBL_DEC_FR',props).toUpperCase())}
            {dropdowns('flow-rate-decimal-factor', 'pref-dropdown', flowRateDecimalFactor, decimalFactorUnits, handleFlowRateDecimalFactorPref)}
          </Row>
          <Row className='pref-row'>
            {dropdownLabel('flow-rate-gas', CustomTranslationText('XXSPECIFY_MMI_PR_LBL_FLOUNIGAS',props).toUpperCase())}
            {dropdowns('flow-rate-gas', 'pref-dropdown', getUnitsValue(flowRateUnitGas, 'flowRateGas', props.units_data), flowRateGasUnits, handleFlowRateUnitGasPref)}
            {/* line pressure */}
            {dropdownLabel('line-pressure-decimal-factor', CustomTranslationText('XXSPECIFY_MMI_PR_LBL_DEC_LP',props).toUpperCase())}
            {dropdowns('line-pressure-decimal-factor', 'pref-dropdown', linePressureDecimalFactor, decimalFactorUnits, handleLinePressureDecimalFactorPref)}            
          </Row>
          <Row className='pref-row'>
            {dropdownLabel('temperature-unit', CustomTranslationText('XXSPECIFY_MMI_PR_LBL_TEMPUNIT',props).toUpperCase())}
            {dropdowns('temperature-unit', 'pref-dropdown', getUnitsValue(temperatureUnit, 'temperature', props.units_data), temperatureUnits, handleTemperatureUnitPref)}
            {/* temperature */}
            {dropdownLabel('temperature-decimal-factor', CustomTranslationText('XXSPECIFY_MMI_PR_LBL_DEC_TMP',props).toUpperCase())}
            {dropdowns('temperature-decimal-factor', 'pref-dropdown', temperatureDecimalFactor, decimalFactorUnits, handleTemperatureDecimalFactorPref)}          </Row>
          <Row className='pref-row'>
            {dropdownLabel('ambient-temperature-unit', 'AMBIENT TEMPERATURE UNIT')}
            {dropdowns('ambient-temperature-unit', 'pref-dropdown', getUnitsValue(ambientTemperatureUnit, 'temperature', props.units_data), temperatureUnits, handleAmbientTemperatureUnitPref)}
          </Row>
          <Row className='pref-row'>
            {dropdownLabel('line-pressure',CustomTranslationText('XXSPECIFY_MMI_PASS_LBL_LPUNITS',props).toUpperCase() )}
            {dropdowns('line-pressure', 'pref-dropdown', getUnitsValue(linePressureUnit, 'nonVaporPressure', props.units_data), nonVaporPressureUnits, handleLinePressureUnitPref)}
          </Row>
          <Row className='pref-row'>
            {dropdownLabel('density-unit',CustomTranslationText('XXSPECIFY_MMI_PR_LBL_DENSUNI',props).toUpperCase())}
            {dropdowns('density-unit', 'pref-dropdown', getUnitsValue(densityUnit, 'density', props.units_data), densityUnits, handleDensityUnitPref)}
          </Row>
          <Row className='pref-row'>
            {dropdownLabel('viscosity-unit',CustomTranslationText('XXSPECIFY_MMI_PR_LBL_VISCUNI',props).toUpperCase() )}
            {dropdowns('viscosity-unit', 'pref-dropdown', getUnitsValue(viscosityUnit, 'viscosity', props.units_data), viscosityUnits, handleViscosityUnitPref)}
          </Row>
          <Row className='pref-row'>
            {dropdownLabel('vapor-pressure-unit',CustomTranslationText('XXSPECIFY_MMI_PR_LBL_VAPORPRE',props).toUpperCase() )}
            {dropdowns('vapor-pressure-unit', 'pref-dropdown', getUnitsValue(vaporPressureUnit, 'vaporPressure', props.units_data), vaporPressureUnits, handleVaporPressureUnitPref)}
          </Row>
          <Row className='pref-row'>
            {dropdownLabel('atmospheric-unit', CustomTranslationText('XXSPECIFY_MMI_PR_LBL_ATMPRESS',props).toUpperCase())}
            {dropdowns('atmospheric-unit', 'pref-dropdown', getUnitsValue(atmosphericUnit, 'vaporPressure', props.units_data), vaporPressureUnits, handleAtmosphericUnitPref)}
          </Row>
          <Row className='pref-row'>
            {dropdownLabel('gas-pressure-unit',CustomTranslationText('XXSPECIFY_MMI_PR_LBL_BRCPRESS',props).toUpperCase())}
            {dropdowns('gas-pressure-unit', 'pref-dropdown', getUnitsValue(gasOnlyPressureUnit,  'vaporPressure', props.units_data), vaporPressureUnits, handleGasOnlyPressureUnitPref)}
          </Row>
          <Row className='pref-row'>
            {dropdownLabel('gas-pressure', CustomTranslationText('XXSPECIFY_MMI_PR_LBL_GASONLYP',props).toUpperCase())}
            {textField('', 'gas-pressure', gasOnlyPressure, e => handleGasOnlyPressurePref(e.target.value))}
          </Row>
          <Row className='pref-row'>
            {dropdownLabel('gas-temperature-unit', CustomTranslationText('XXSPECIFY_MMI_PR_LBL_BRCTEMP',props).toUpperCase())}
            {dropdowns('gas-temperature-unit', 'pref-dropdown', getUnitsValue(gasOnlyTemperatureUnit, 'temperature', props.units_data), temperatureUnits, handleGasOnlyTemperatureUnitPref)}
          </Row>
          <Row className='pref-row'>
            {dropdownLabel('gas-temperature', CustomTranslationText('XXSPECIFY_MMI_PR_LBL_GASONLYT',props).toUpperCase())}
            {textField('', 'gas-temperature',gasOnlyTemperature, e => handleGasOnlyTemperaturePref(e.target.value))}
          </Row>
          <Row className='pref-row'>
            {dropdownLabel('gas-density-unit', CustomTranslationText('XXSPECIFY_MMI_PR_LBL_BRCDENS',props).toUpperCase())}
            {dropdowns('gas-density-unit', 'pref-dropdown', getUnitsValue(gasOnlyDensityUnit, 'density', props.units_data), densityUnits, handleGasOnlyDensityUnitPref)}
          </Row>
          <Row className='pref-row'>
            {dropdownLabel('sonic-velocity-unit', CustomTranslationText('XXSPECIFY_MMI_PR_LBL_SONIC',props).toUpperCase())}
            {dropdowns('sonic-velocity-unit', 'pref-dropdown', getUnitsValue(sonicVelocityUnit, 'sonicVelocity', props.units_data), sonicVelocityUnits, handleSonicVelocityUnitPref)}
          </Row>
          <Row className='pref-row'>
            {dropdownLabel('decimal-factor', CustomTranslationText('XXSPECIFY_MMI_PR_LBL_DECIMAL',props).toUpperCase())}
            {dropdowns('decimal-factor', 'pref-dropdown', decimalFactor, decimalFactorUnits, handleDecimalFactorPref)}
          </Row>
          <Row className='pref-row'>
            {dropdownLabel('uom-auto-conversion',CustomTranslationText('XXSPECIFY_MMI_PR_LBL_UOMAUTO',props).toUpperCase() )}
            {dropdowns('uom-auto-conversion', 'pref-dropdown',  uomAutoConversion, uomAutoConversionUnits, handleUomAutoConversionPref)}
          </Row>
          <Row className='pref-row'>
            {dropdownLabel('wetted-material',CustomTranslationText('XXSPECIFY_MMI_SR_LBL_WTMTRL',props).toUpperCase() )}
            {dropdowns('wetted-material', 'pref-dropdown',  wettedMaterial, wettedMaterialValues, handleWettedMaterialPref)}
          </Row>
          <Row className='pref-row'>
            <Col md={3} sm={0} />
            <Col md={4} sm={12}>
              <CustomButton
                classNames='btn btn-save-prefs btn-green'
                label= {CustomTranslationText('XXSPECIFY_MMI_PR_BTN_SAVE',props).toUpperCase()}
                onClick={() => savePrefs()}
                size='lg'
                disabled={!buttonEnabled}
              />
            </Col>
            <Col md={3} sm={0} />
          </Row>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  flow_rate_unit_for_liquid_slurry_pref: state.Preferences.flow_rate_unit_for_liquid_slurry_pref,
  flow_rate_unit_for_gas_pref: state.Preferences.flow_rate_unit_for_gas_pref,
  temperature_unit_pref: state.Preferences.temperature_unit_pref,
  ambient_temperature_unit_pref: state.Preferences.ambient_temperature_unit_pref,
  line_pressure_unit_pref: state.Preferences.line_pressure_unit_pref,
  density_unit_pref: state.Preferences.density_unit_pref,
  viscosity_unit_pref: state.Preferences.viscosity_unit_pref,
  vapor_pressure_unit_pref: state.Preferences.vapor_pressure_unit_pref,
  atmospheric_unit_pref: state.Preferences.atmospheric_unit_pref,
  gas_only_pressure_unit_pref: state.Preferences.gas_only_pressure_unit_pref,
  gas_only_pressure_pref: state.Preferences.gas_only_pressure_pref,
  gas_only_temperature_unit_pref: state.Preferences.gas_only_temperature_unit_pref,
  gas_only_temperature_pref: state.Preferences.gas_only_temperature_pref,
  gas_only_density_unit_pref: state.Preferences.gas_only_density_unit_pref,
  sonic_velocity_unit_pref: state.Preferences.sonic_velocity_unit_pref,
  decimal_factor_pref: state.Preferences.decimal_factor_pref,
  flow_rate_decimal_factor: state.Preferences.flow_rate_decimal_factor,
  temperature_decimal_factor: state.Preferences.temperature_decimal_factor,
  line_pressure_decimal_factor: state.Preferences.line_pressure_decimal_factor,
  translation_data: state.TranslationData.translation_data,
  uom_auto_conversion_pref: state.Preferences.uom_auto_conversion_pref,
  saved_preferences: state.Preferences.saved_preferences,
  units_data: state.DropdownData.units_data,
  user_language: state.AppHeader.user_language,
  email: state.AppHeader.email,
  error_message: state.ErrorMessage.error_message,
  query_params: state.Preferences.query_params,
  wetted_material_units: state.ApplicationRequirements.wetted_material_units,
  wetted_material_label: state.ApplicationRequirements.wetted_material_label,
  wetted_material_pref: state.Preferences.wetted_material_pref,
  refresh_pref: state.Preferences.refresh_pref,
});
export default connect(
  mapStateToProps,
  {
    flowRateUnitLiquidSlurryPref,
    flowRateUnitGasPref,
    temperatureUnitPref,
    ambientTemperatureUnitPref,
    linePressureUnitPref,
    densityUnitPref,
    viscosityUnitPref,
    vaporPressureUnitPref,
    atmosphericUnitPref,
    gasOnlyPressureUnitPref,
    gasOnlyPressurePref,
    gasOnlyTemperatureUnitPref,
    gasOnlyTemperaturePref,
    gasOnlyDensityUnitPref,
    sonicVelocityUnitPref,
    decimalFactorPref,
    flowRateDecimalFactorPref,
    temperatureDecimalFactorPref,
    linePressureDecimalFactorPref,
    uomAutoConversionPref,
    refreshPref,
    savedPreferences,
    errorMessage,
    unitsFlowRate,
    unitsGasFlowRate,
    unitsDensity,
    unitsTemperature,
    unitsAmbient,
    unitsLinePressure,
    unitsViscosity,
    unitsVapor,
    unitsGasDensity,
    unitsGasPressure,
    unitsGasTemperature,
    unitsSonicVelocity,
    unitsPressureAtmospheric,
    wettedMaterial, 
    wettedMaterialValue,
    wettedMaterialLabel,
    allowPrefBaseRef, 
    compositionMethodFlag,
    savedPreferencesFlag
  }
)(Preferences);
