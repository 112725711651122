import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Row, Col, Container } from 'react-bootstrap';
import html2pdf from 'html2pdf.js';
import './compare.scss';
import ReactToPdf from 'react-to-pdf';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';
import CustomTranslationText from '../../components/CustomTranslation/CustomTranslationText';
import pictureComingSoon from '../../images/pictureComingSoon.png';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { BASE64_IMG_PREFIX, NO_IMAGE_FOUND } from '../../constants/constants';
const docToPrint = React.createRef(
  document.getElementById('compare-page-download')
);
const SizingResultsCompareWindowContent = ({
  data,
  compare_data,
  processVariables,
  imageList,
  all_other_decimal,
  flow_rate_decimal,
  temperature_decimal,
  line_pressure_decimal,
  props,
  densityAccuracyUnits
}) => {
  const {
    technology,
    min_flow_rate,
    normal_flow_rate,
    max_flow_rate,
    full_flow_rate,

    min_line_pressure,
    normal_line_pressure,
    max_line_pressure,
    full_line_pressure,

    min_temperature,
    normal_temperature,
    max_temperature,
    full_temperature,

    min_ambient,
    normal_ambient,
    max_ambient,
    full_ambient,

    min_density,
    normal_density,
    max_density,
    min_viscosity,
    normal_viscosity,
    max_viscosity,

    units_viscosity,
    units_line_pressure,
    units_density,
    units_ambient,
    units_temperature,
    units_flow_rate,
    process_data,
  } = processVariables;

  const handleImage = (data) => {
    return (
      <>
        {data ? (
          <img
            className="imageCenter"
            src={
              data.image_data !== NO_IMAGE_FOUND
                ? `${BASE64_IMG_PREFIX + data.image_data}`
                : pictureComingSoon
            }
            //crossOrigin="anonymous"
            alt={data.model_code}
            height={'80'}
            width={'80'}
          />
        ) : (
          <img
            className="imageRight"
            src={pictureComingSoon}
            alt={'no image'}
            height={'80'}
            width={'80'}
          />
        )}
      </>
    );
  };
  const options = {
    orientation: 'portrait',
    unit: 'in',
    format: [12, 20],
  };

  const toPdf = (e) => {
    const element = document.getElementById('compare-page-download');
    console.log("::::element",element)
    const options = {
      margin: 0.5,
      filename: 'sensor.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 3 },
      jsPDF: { unit: 'mm', format: 'a2', orientation: 'p' }
    };
    const pdf = html2pdf().from(element).set(options);
    console.log(":::pdf",pdf)
    pdf.save().then(() => {
      console.log(':::PDF saved successfully');
    }).catch(error => {
      console.error(':::Error saving PDF:', error);
    });
  };

  return (
    <>
      {' '}
      <button onClick={toPdf} className="download-button">
        <CustomTranslation data="XXSPECIFY_MMI_SR_BTN_DOWNL" />
      </button>
      <div
        id="compare-page-download"
        className="comparison-popout-page"
        style={{
          width: '100%',
          height: '100%',
        }}
        ref={docToPrint}
      >
        <span className="content-heading">
          <h1 className="content-title">
            <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_SENCOM" />
          </h1>
        </span>
        <div>
          <Row>
            <Col xs="6" md="6" lg="6">
              <table id="process-table" className="process-table" size="small">
                <tbody>
                  <tr>
                    <th>&nbsp;&nbsp;</th>
                    <td>
                      <CustomTranslation data="XXSPECIFY_MMI_MC_LBL_MN" />
                    </td>
                    <td>
                      <CustomTranslation data="XXSPECIFY_MMI_MC_TXT_NOR" />
                    </td>
                    <td>
                      <CustomTranslation data="XXSPECIFY_MMI_MC_LBL_MX" />
                    </td>
                    <td className="fullDesign">
                      <CustomTranslation data="XXSPECIFY_MMI_MC_TXT_FSD" />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_FLOWRATE" />
                      <p className="processUnits">
                        {units_flow_rate !== '' ? units_flow_rate.value : '-'}
                      </p>
                    </th>
                    <td>{min_flow_rate !== '' ? min_flow_rate : '-'}</td>
                    <td>{normal_flow_rate !== '' ? normal_flow_rate : '-'}</td>
                    <td>{max_flow_rate !== '' ? max_flow_rate : '-'}</td>
                    <td>{full_flow_rate !== '' ? full_flow_rate : '-'}</td>
                  </tr>
                  <tr>
                    <th>
                      <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_LINEPRESS" />
                      <p className="processUnits">
                        {units_line_pressure !== ''
                          ? units_line_pressure.value
                          : '-'}
                      </p>
                    </th>
                    <td>
                      {min_line_pressure !== '' ? min_line_pressure : '-'}
                    </td>
                    <td>
                      {normal_line_pressure !== '' ? normal_line_pressure : '-'}
                    </td>
                    <td>
                      {max_line_pressure !== '' ? max_line_pressure : '-'}
                    </td>
                    <td>
                      {full_line_pressure !== '' ? full_line_pressure : '-'}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_TEMP" />
                      <p className="processUnits">
                        {units_temperature !== ''
                          ? units_temperature.value
                          : '-'}
                      </p>
                    </th>
                    <td>{min_temperature !== '' ? min_temperature : '-'}</td>
                    <td>
                      {normal_temperature !== '' ? normal_temperature : '-'}
                    </td>
                    <td>{max_temperature !== '' ? max_temperature : '-'}</td>
                    <td>{full_temperature !== '' ? full_temperature : '-'}</td>
                  </tr>
                  <tr>
                    <th>
                      <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_AMBTEMP" />
                      <p className="processUnits">
                        {units_ambient !== '' ? units_ambient.value : '-'}
                      </p>
                    </th>
                    <td>{min_ambient !== '' ? min_ambient : '-'}</td>
                    <td>{normal_ambient !== '' ? normal_ambient : '-'}</td>
                    <td>{max_ambient !== '' ? max_ambient : '-'}</td>
                    <td>{full_ambient !== '' ? full_ambient : '-'}</td>
                  </tr>
                  <tr>
                    <th>
                      <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_DENSITY" />
                      <p className="processUnits">
                        {units_density !== '' ? densityAccuracyUnits ? densityAccuracyUnits : processVariables.units_density.value : '-'}


                      </p>
                    </th>
                    <td>{min_density !== '' ?  parseFloat(min_density).toFixed(all_other_decimal) : '-'}</td>
                    <td>{normal_density !== '' ? parseFloat(normal_density).toFixed(all_other_decimal) : '-'}</td>
                    <td>{max_density !== '' ? parseFloat(max_density).toFixed(all_other_decimal) : '-'}</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <th>
                      <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_VISC" />
                      <p className="processUnits">
                        {units_viscosity !== '' ? units_viscosity.value : '-'}
                      </p>
                    </th>
                    <td>{min_viscosity !== '' ? parseFloat(min_viscosity).toFixed(all_other_decimal) : '-'}</td>
                    <td>{normal_viscosity !== '' ? parseFloat(normal_viscosity).toFixed(all_other_decimal) : '-'}</td>
                    <td>{max_viscosity !== '' ? parseFloat(max_viscosity).toFixed(all_other_decimal) : '-'}</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <Table bordered className="comparison-table table-responsive">
            <tbody>
              <tr>
                <th>&nbsp;</th>
                <td>{imageList ? handleImage(imageList[0]) : ''}</td>
                <td>{imageList ? handleImage(imageList[1]) : ''}</td>
                {data[2] ? (
                  <td>{imageList ? handleImage(imageList[2]) : ''}</td>
                ) : (
                  ''
                )}
              </tr>
              <tr>
                <th>
                  <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_PRNAME" />
                </th>
                <td>{data[0] !== undefined ? data[0].modelName : ''}</td>
                <td>{data[1] !== undefined ? data[1].modelName : ''}</td>
                {data[2] !== undefined ? <td>{data[2].modelName}</td> : ''}
              </tr>
              <tr>
                <th>
                  <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_PRDES" />
                </th>
                <td>
                  <p>{imageList[0] ? imageList[0].long_description : ''}</p>
                </td>
                <td>
                  <p>{imageList[1] ? imageList[1].long_description : ''}</p>
                </td>
                {data[2] ? (
                  <td>
                    <p>{imageList[2] ? imageList[2].long_description : ''}</p>
                  </td>
                ) : (
                  ''
                )}
              </tr>
              {technology === 'Density' ? (
                <>
                  <tr>
                    <th>
                      <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_DENACC" />
                      <br />
                      <small>({densityAccuracyUnits?densityAccuracyUnits:processVariables.units_density.value})</small>
                    </th>
                    <td>
                      {data[0] !== undefined ? data[0].densityAccuracy : ''}
                    </td>
                    <td>
                      {data[1] !== undefined ? data[1].densityAccuracy : ''}
                    </td>
                    {data[2] !== undefined ? (
                      <td>{data[2].densityAccuracy}</td>
                    ) : (
                      ''
                    )}
                  </tr>
                </>
              ) : (
                <>
                  <tr>
                    <th>
                      <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_FLWACMN" />
                    </th>
                    <td>
                      {technology === 'Magnetic'
                        ? compare_data[0] !== undefined
                          ? parseFloat(
                              compare_data[0].min_flow_accuracy
                            ).toFixed(flow_rate_decimal)
                          : ''
                        : data[0].massMin}
                    </td>
                    <td>
                      {technology === 'Magnetic'
                        ? compare_data[1] !== undefined
                          ? parseFloat(
                              compare_data[1].min_flow_accuracy
                            ).toFixed(flow_rate_decimal)
                          : ''
                        : data[1] !== undefined
                        ? data[1].massMin
                        : ''}
                    </td>
                    {technology === 'Magnetic' ? (
                      compare_data[2] !== undefined ? (
                        <td>
                          {parseFloat(
                            compare_data[2].min_flow_accuracy
                          ).toFixed(flow_rate_decimal)}
                        </td>
                      ) : (
                        ''
                      )
                    ) : data[2] !== undefined ? (
                      <td>{data[2].massMin}</td>
                    ) : (
                      ''
                    )}
                  </tr>
                  <tr>
                    <th>
                      <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_ACCNORM" />
                    </th>
                    <td>
                      {technology === 'Magnetic'
                        ? compare_data[0] !== undefined
                          ? compare_data[0].norm_flow_accuracy !== ''
                            ? parseFloat(
                                compare_data[0].norm_flow_accuracy
                              ).toFixed(flow_rate_decimal)
                            : ''
                          : ''
                        : data[0].massNormal}
                    </td>
                    <td>
                      {technology === 'Magnetic'
                        ? compare_data[1] !== undefined
                          ? compare_data[1].norm_flow_accuracy !== ''
                            ? parseFloat(
                                compare_data[1].norm_flow_accuracy
                              ).toFixed(flow_rate_decimal)
                            : ''
                          : ''
                        : data[1] !== undefined
                        ? data[1].massNormal
                        : ''}
                    </td>
                    {technology === 'Magnetic' ? (
                      compare_data[2] !== undefined ? (
                        <td>
                          {compare_data[2].norm_flow_accuracy !== ''
                            ? parseFloat(
                                compare_data[2].norm_flow_accuracy
                              ).toFixed(flow_rate_decimal)
                            : ''}
                        </td>
                      ) : (
                        ''
                      )
                    ) : data[2] !== undefined ? (
                      <td>{data[2].massNormal}</td>
                    ) : (
                      ''
                    )}
                  </tr>
                  <tr>
                    <th>
                      <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_FLWACCM" />
                    </th>
                    <td>
                      {technology === 'Magnetic'
                        ? compare_data[0] !== undefined
                          ? parseFloat(
                              compare_data[0].max_flow_accuracy
                            ).toFixed(flow_rate_decimal)
                          : ''
                        : data[0].massMax}
                    </td>
                    <td>
                      {technology === 'Magnetic'
                        ? compare_data[1] !== undefined || ''
                          ? parseFloat(
                              compare_data[1].max_flow_accuracy
                            ).toFixed(flow_rate_decimal)
                          : ''
                        : data[1] !== undefined
                        ? data[1].massMax
                        : ''}
                    </td>
                    {technology === 'Magnetic' ? (
                      compare_data[2] !== undefined ? (
                        <td>
                          {parseFloat(
                            compare_data[2].max_flow_accuracy
                          ).toFixed(flow_rate_decimal)}
                        </td>
                      ) : (
                        ''
                      )
                    ) : data[2] !== undefined ? (
                      <td>{data[2].massMax}</td>
                    ) : (
                      ''
                    )}
                  </tr>
                </>
              )}
              <tr>
                <th>
                  <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_PRSDPMN" />
                  <br />
                  <small>
                    ({data[0] !== undefined ? data[0].pressureUnits : ''})
                  </small>
                </th>
                <td>{data[0] !== undefined ? data[0].pressMin : ''}</td>
                <td>{data[1] !== undefined ? data[1].pressMin : ''}</td>
                {data[2] !== undefined ? <td>{data[2].pressMin}</td> : ''}
              </tr>
              <tr>
                <th>
                  <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_PRENORM" />
                  <br />
                  <small>
                    ({data[0] !== undefined ? data[0].pressureUnits : ''})
                  </small>
                </th>
                <td>{data[0] !== undefined ? data[0].pressNormal : ''}</td>
                <td>{data[1] !== undefined ? data[1].pressNormal : ''}</td>
                {data[2] !== undefined ? <td>{data[2].pressNormal}</td> : ''}
              </tr>
              <tr>
                <th>
                  <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_PRSDRPM" />
                  <br />
                  <small>
                    ({data[0] !== undefined ? data[0].pressureUnits : ''})
                  </small>
                </th>
                <td>{data[0] !== undefined ? data[0].pressMax : ''}</td>
                <td>{data[1] !== undefined ? data[1].pressMax : ''}</td>
                {data[2] !== undefined ? <td>{data[2].pressMax}</td> : ''}
              </tr>
              {technology === 'Magnetic' ? (
                <tr>
                  <th>
                    <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_VELMN" />
                    <br />
                    <small>
                      (
                      {compare_data[0] !== undefined
                        ? compare_data[0].velosity_unit
                        : ''}
                      )
                    </small>
                  </th>
                  <td>
                    {compare_data[0] !== undefined
                      ? parseFloat(compare_data[0].velocity_min).toFixed(
                          all_other_decimal
                        )
                      : ''}
                  </td>
                  <td>
                    {compare_data[1] !== undefined
                      ? parseFloat(compare_data[1].velocity_min).toFixed(
                          all_other_decimal
                        )
                      : ''}
                  </td>
                  {compare_data[2] !== undefined ? (
                    <td>
                      {parseFloat(compare_data[2].velocity_min).toFixed(
                        all_other_decimal
                      )}
                    </td>
                  ) : (
                    ''
                  )}
                </tr>
              ) : (
                <tr>
                  <th>
                    <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_VELMN" />
                    <br />
                    <small>
                      ({data[0] !== undefined ? data[0].velocityUnits : ''})
                    </small>
                  </th>
                  <td>{data[0] !== undefined ? data[0].tubeMin : ''}</td>
                  <td>{data[1] !== undefined ? data[1].tubeMin : ''}</td>
                  {data[2] !== undefined ? <td>{data[2].tubeMin}</td> : ''}
                </tr>
              )}
              {technology === 'Magnetic' ? (
                <tr>
                  <th>
                    <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_VELNORM" />
                    <br />
                    <small>
                      (
                      {compare_data[0] !== undefined
                        ? compare_data[0].velosity_unit
                        : ''}
                      )
                    </small>
                  </th>
                  <td>
                    {compare_data[0] !== undefined
                      ? compare_data[0].velocity_norm !== ''
                        ? parseFloat(compare_data[0].velocity_norm).toFixed(
                            all_other_decimal
                          )
                        : ''
                      : ''}
                  </td>
                  <td>
                    {compare_data[1] !== undefined
                      ? compare_data[1].velocity_norm !== ''
                        ? parseFloat(compare_data[1].velocity_norm).toFixed(
                            all_other_decimal
                          )
                        : ''
                      : ''}
                  </td>
                  {compare_data[2] !== undefined ? (
                    <td>
                      {compare_data[2].velocity_norm !== ''
                        ? parseFloat(compare_data[2].velocity_norm).toFixed(
                            all_other_decimal
                          )
                        : ''}
                    </td>
                  ) : (
                    ''
                  )}
                </tr>
              ) : (
                <tr>
                  <th>
                    <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_VELNORM" />
                    <br />
                    <small>
                      ({data[0] !== undefined ? data[0].velocityUnits : ''})
                    </small>
                  </th>
                  <td>{data[1] !== undefined ? data[0].tubeNormal : ''}</td>
                  <td>{data[1] !== undefined ? data[1].tubeNormal : ''}</td>
                  {data[2] !== undefined ? <td>{data[2].tubeNormal}</td> : ''}
                </tr>
              )}
              {technology === 'Magnetic' ? (
                <tr>
                  <th>
                    <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_VELMAX" />
                    <br />
                    <small>
                      (
                      {compare_data[0] !== undefined
                        ? compare_data[0].velosity_unit
                        : ''}
                      )
                    </small>
                  </th>
                  <td>
                    {compare_data[0] !== undefined
                      ? parseFloat(compare_data[0].velocity_max).toFixed(
                          all_other_decimal
                        )
                      : ''}
                  </td>
                  <td>
                    {compare_data[1] !== undefined
                      ? parseFloat(compare_data[1].velocity_max).toFixed(
                          all_other_decimal
                        )
                      : ''}
                  </td>
                  {compare_data[2] !== undefined ? (
                    <td>
                      {parseFloat(compare_data[2].velocity_max).toFixed(
                        all_other_decimal
                      )}
                    </td>
                  ) : (
                    ''
                  )}
                </tr>
              ) : (
                <tr>
                  <th>
                    <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_VELMAX" />
                    <br />
                    <small>
                      ({data[0] !== undefined ? data[0].velocityUnits : ''})
                    </small>
                  </th>
                  <td>{data[0] !== undefined ? data[0].tubeMax : ''}</td>
                  <td>{data[1] !== undefined ? data[1].tubeMax : ''}</td>
                  {data[2] !== undefined ? <td>{data[2].tubeMax}</td> : ''}
                </tr>
              )}
              {technology === 'Density' ? (
                <></>
              ) : (
                <tr>
                  <th>
                    <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_ACCFLOW" />
                    <br />
                    <small>({processVariables.units_flow_rate.value})</small>
                  </th>

                  <td>
                    {data[0] !== undefined
                      ? data[0].minAccurateFlowAtOnePercent
                      : ''}
                  </td>
                  <td>
                    {data[1] !== undefined
                      ? data[1].minAccurateFlowAtOnePercent
                      : ''}
                  </td>
                  {data[2] !== undefined ? (
                    <td>{data[2].minAccurateFlowAtOnePercent}</td>
                  ) : (
                    ''
                  )}
                </tr>
              )}
              <tr>
                <th>
                  <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_PRERA" />
                  <br />
                  <small>({processVariables.units_line_pressure.value})</small>
                </th>
                <td>
                  {technology === 'Magnetic' || technology === 'Vortex' ?  
                  compare_data[0] !== undefined? parseFloat(compare_data[0].max_pressure_rating).toFixed(
                    all_other_decimal
                  ):'':
                  data[0].tubePressureRating !== undefined
                    ? data[0].tubePressureRating
                    : ''}
                </td>
                <td>
                  {technology === 'Magnetic'|| technology === 'Vortex'?  
                  compare_data[1] !== undefined? parseFloat(compare_data[1].max_pressure_rating).toFixed(
                    all_other_decimal
                  ):''
                  :data[1].tubePressureRating !== undefined
                    ? data[1].tubePressureRating
                    : ''}
                </td>
                {data[2] !== undefined ? (
                  <td>
                    {technology === 'Magnetic'|| technology === 'Vortex'?
                    compare_data[2] !== undefined? parseFloat(compare_data[2].max_pressure_rating).toFixed(
                      all_other_decimal
                    ):''
                    :data[2].tubePressureRating !== undefined
                      ? data[2].tubePressureRating
                      : ''}
                  </td>
                ) : (
                  ''
                )}
              </tr>
              {technology === 'Density'? (
                <tr>
                  <th>
                    <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_TEMPLIM" />
                    <br />
                    <small>({processVariables.units_temperature.value})</small>
                  </th>
                  <td>
                    {data[0]
                      ? data[0].tempLimits !== undefined
                        ? data[0].tempLimits
                        : '--'
                      : '--'}
                  </td>
                  <td>
                    {data[1]
                      ? data[1].tempLimits !== undefined
                        ? data[1].tempLimits
                        : '--'
                      : '--'}
                  </td>
                  <td>
                    {data[2]
                      ? data[2].tempLimits !== undefined
                        ? data[2].tempLimits
                        : '--'
                      : '--'}
                  </td>
                </tr>
              ) :   
              technology === 'Vortex' ? (
                <tr>
                  <th>
                    <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_TEMPLIM" />
                    <br />
                    <small>({processVariables.units_temperature.value})</small>
                  </th>
                  <td>
                    {compare_data[0] !== undefined
                      ? compare_data[0].temperature_min !== '' &&
                        compare_data[0].temperature_min !== undefined
                        ? parseFloat(compare_data[0].temperature_min).toFixed(
                            temperature_decimal
                          )
                        : '--'
                      : '--'}{' '}
                    to{' '}
                    {compare_data[0] !== undefined
                      ? compare_data[0].temperature_max !== undefined &&
                        compare_data[0].temperature_max !== ''
                        ? parseFloat(compare_data[0].temperature_max).toFixed(
                            temperature_decimal
                          )
                        : '--'
                      : '--'}
                  </td>
                  <td>
                    {compare_data[1] !== undefined
                      ? compare_data[1].temperature_min !== '' &&
                        compare_data[1].temperature_min !== undefined
                        ? parseFloat(compare_data[1].temperature_min).toFixed(
                            temperature_decimal
                          )
                        : '--'
                      : '--'}{' '}
                    to{' '}
                    {compare_data[1] !== undefined
                      ? compare_data[1].temperature_max !== undefined &&
                        compare_data[1].temperature_max !== ''
                        ? parseFloat(compare_data[1].temperature_max).toFixed(
                            temperature_decimal
                          )
                        : '--'
                      : '--'}
                  </td>
                  {compare_data[2] !== undefined ? (
                    <td>
                      {compare_data[2] !== undefined
                        ? compare_data[2].temperature_min !== '' &&
                          compare_data[2].temperature_min !== undefined
                          ? parseFloat(compare_data[2].temperature_min).toFixed(
                              temperature_decimal
                            )
                          : '--'
                        : '--'}{' '}
                      to{' '}
                      {compare_data[2] !== undefined
                        ? compare_data[2].temperature_max !== undefined &&
                          compare_data[2].temperature_max !== ''
                          ? parseFloat(compare_data[2].temperature_max).toFixed(
                              temperature_decimal
                            )
                          : '--'
                        : '--'}{' '}
                    </td>
                  ) : (
                    ''
                  )}
                </tr>
              ):        
              technology === 'Coriolis' ? (
                <tr>
                  <th>
                    <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_TEMPLIM" />
                    <br />
                    <small>({processVariables.units_temperature.value})</small>
                  </th>
                  <td>
                    {compare_data[0] !== undefined
                      ? compare_data[0].temperature_limits !== '' &&
                        compare_data[0].temperature_limits !== undefined
                        ? 
                            compare_data[0].temperature_limits
                         
                        : '--'
                      : '--'}
                  </td>
                  <td>
                    {compare_data[1] !== undefined
                      ? compare_data[1].temperature_limits !== '' &&
                        compare_data[1].temperature_limits !== undefined
                        ? compare_data[1].temperature_limits
                        : '--'
                      : '--'}{' '}
                  </td>

                  {compare_data[2] !== undefined ? (
                    <td>
                      {compare_data[2] !== undefined
                        ? compare_data[2].temperature_limits !== '' &&
                          compare_data[2].temperature_limits !== undefined
                          ?compare_data[2].temperature_limits
                          : '--'
                        : '--'}{' '}
                    </td>
                  ) : (
                    ''
                  )}
                </tr>
              ) : (
                <tr>
                  <th>
                    <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_TEMPLIM" />
                    <br />
                    <small>({processVariables.units_temperature.value})</small>
                  </th>
                  <td>
                    {compare_data[0] !== undefined
                      ? compare_data[0].temperature_min !== '' &&
                        compare_data[0].temperature_min !== undefined
                        ? parseFloat(compare_data[0].temperature_min).toFixed(
                            temperature_decimal
                          )
                        : '--'
                      : '--'}{' '}
                    to{' '}
                    {compare_data[0] !== undefined
                      ? compare_data[0].temperature_max !== undefined &&
                        compare_data[0].temperature_max !== ''
                        ? parseFloat(compare_data[0].temperature_max).toFixed(
                            temperature_decimal
                          )
                        : '--'
                      : '--'}
                  </td>
                  <td>
                    {compare_data[1] !== undefined
                      ? compare_data[1].temperature_min !== '' &&
                        compare_data[1].temperature_min !== undefined
                        ? parseFloat(compare_data[1].temperature_min).toFixed(
                            temperature_decimal
                          )
                        : '--'
                      : '--'}{' '}
                    to{' '}
                    {compare_data[1] !== undefined
                      ? compare_data[1].temperature_max !== undefined &&
                        compare_data[1].temperature_max !== ''
                        ? parseFloat(compare_data[1].temperature_max).toFixed(
                            temperature_decimal
                          )
                        : '--'
                      : '--'}
                  </td>
                  {compare_data[2] !== undefined ? (
                    <td>
                      {compare_data[2] !== undefined
                        ? compare_data[2].temperature_min !== '' &&
                          compare_data[2].temperature_min !== undefined
                          ? parseFloat(compare_data[2].temperature_min).toFixed(
                              temperature_decimal
                            )
                          : '--'
                        : '--'}{' '}
                      to{' '}
                      {compare_data[2] !== undefined
                        ? compare_data[2].temperature_max !== undefined &&
                          compare_data[2].temperature_max !== ''
                          ? parseFloat(compare_data[2].temperature_max).toFixed(
                              temperature_decimal
                            )
                          : '--'
                        : '--'}{' '}
                    </td>
                  ) : (
                    ''
                  )}
                </tr>
              )}
              {technology !== 'Density' ? (
                <tr>
                  <th>
                    <CustomTranslation data="XXSPECIFY_MMI_COM_LBL_DENACC" />
                    <br />
                    <small>({densityAccuracyUnits?densityAccuracyUnits:processVariables.units_density.value+'kkk'})</small>
                  </th>
                  <td>
                    {data[0] !== undefined ? data[0].densityAccuracy : ''}
                  </td>
                  <td>
                    {data[1] !== undefined ? data[1].densityAccuracy : ''}
                  </td>
                  {data[2] !== undefined ? (
                    <td>{data[2].densityAccuracy}</td>
                  ) : (
                    ''
                  )}
                </tr>
              ) : (
                <></>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

//export default SizingResultsCompareWindowContent;

const mapStateToProps = (state) => ({
  translation_data: state.TranslationData.translation_data,
});
export default connect(mapStateToProps, {})(SizingResultsCompareWindowContent);
