import React from 'react';
import { Dropdown } from 'react-bootstrap';
import './dropdown.scss';

export const CustomDropdownNavPages = (props) => {
  let { title, id, name, chosenOption, dropdownArray, eventHandler } = props;
  dropdownArray = dropdownArray ? dropdownArray : [];

  let chosen = typeof chosenOption === 'object' && chosenOption !== null && Object.keys(chosenOption).length !== 0
    ? chosenOption.value
      : typeof chosenOption !== 'object'
      ? chosenOption
      : '';

  return (
    <>
      {title ? <div className='dropdown-title'>{title}</div> : <span />}
      <span>
        <Dropdown onSelect={eventHandler}>
          <Dropdown.Toggle id={id} className={name} variant='none'>
            <span className='dropdown-text'>{chosen}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu className='dropdown-menu'>
            {dropdownArray.map((option, index) => (
              <Dropdown.Item
                key={index}
                eventKey={index}
                className={`menu-item-${index}`}
                active={option.value === chosen}
              >
                { option.value }
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </span>
    </>
  );
};
