import {
  FLOW_RATE_LIQUID_SLURRY_VALUES,
  FLOW_RATE_GAS_VALUES,
  DENSITY_VALUES,
  VAPOR_PRESSURE_VALUES,
  NON_VAPOR_PRESSURE_VALUES,
  VISCOSITY_VALUES,
  TEMPERATURE_VALUES,
  SONIC_VELOCITY_VALUES,
} from '../constants/constants';

export const getUnitsKeyValue = (keyIndex, unit, unitData) => {
  if (keyIndex && unitData) {
    keyIndex = keyIndex.key ? keyIndex.key : keyIndex;
    let result;
    switch (unit) {
      case FLOW_RATE_LIQUID_SLURRY_VALUES:
        result = unitData.flowRateLiquidSlurry.find(({key}) => key === keyIndex); break;
      case FLOW_RATE_GAS_VALUES:
        result =  unitData.flowRateGas.find(({key}) => key === keyIndex); break;
      case DENSITY_VALUES:
        result =  unitData.density.find(({key}) => key === keyIndex); break;
      case NON_VAPOR_PRESSURE_VALUES:
        result =  unitData.nonVaporPressure.find(({key}) => key === keyIndex); break;
      case SONIC_VELOCITY_VALUES:
        result =  unitData.sonicVelocity.find(({key}) => key === keyIndex); break;
      case TEMPERATURE_VALUES:
        result =  unitData.temperature.find(({key}) => key === keyIndex); break;
      case VAPOR_PRESSURE_VALUES:
        result =  unitData.vaporPressure.find(({key}) => key === keyIndex); break;
      case VISCOSITY_VALUES:
        result =  unitData.viscosity.find(({key}) => key === keyIndex); break;
      default: result = '';
    }
    return result ? result : '';
  } else {
    return '';
  }
}
