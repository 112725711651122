import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { translationData, errorMessage } from '../../redux/actions';
import axios from 'axios';
import { connect } from 'react-redux';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import { GET_AOL_MESSAGES } from '../../constants/constants';
import ErrorModal from '../../components/CustomModals/ErrorModal';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';

const LanguageModal = (props) => {
  const handleErrorClose = () => {
    props.errorMessage(false);
    // window.location.reload(false);
  }
  const handleErrorShow = () => props.error_message;

  const languageHandle = () => {
    props.onHide();
    props.testFun();
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}${GET_AOL_MESSAGES}`,
      headers: {
        "Content-Type": "application/json",
      },
      xsrfHeaderName: "X-CSRFToken",
      xsrfCookieName: "csrftoken",
      data: {
        language_code: props.loadlanguage.value,
      },
    })
      .then((response) => {
        const success = response.data.data;
        props.translationData(success);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  return (
    <>
      <ErrorModal show={props.error_message} handleClose={handleErrorClose} handleShow={handleErrorShow} />
      <Modal
        {...props}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='LanguageModel'
        animation={false}
      >
        <Modal.Header className='modal-header-fpa' closeButton style={{ padding: "0.6rem" }}>
          <Modal.Title className="modal-title"
            style={{ color: "#00aa7e", fontSize: "1em" }}
            id='contained-modal-title-vcenter'
          >
            <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_CONFRM" /> 
        </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-fpa'>
          <p>Do you want to change the default language?</p>
          <span style={{ float: "right" }}>
            <CustomButton
              classNames='btn btn-nav btn-green'
              label='OK'
              onClick={() => languageHandle()}
              size='lg'
            />
            <CustomButton
              classNames='btn btn-nav btn-green'
              label='CANCEL'
              onClick={props.onHide}
              size='lg'
            />
          </span>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  translation_data: state.TranslationData.translation_data,
  error_message: state.ErrorMessage.error_message,
});
export default connect(mapStateToProps, {
  translationData,
  errorMessage,
})(LanguageModal);
