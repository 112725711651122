import React from 'react';
import Alert from '../../images/Alert.png';
import './tooltip.scss';

const FormatOppConditionsErrorMessage = props => {
  const applyDecimalFactor = number => {
    return parseFloat(number).toFixed(props.decimalPlaces);
  }

  return (
    <div>
      {
        props.message === 'ERROR' ? (
          <>
            <span className="left alert-icon">
              <img
                src={Alert}
                width={12}
                height={12}
                alt='Alert'
              />
            </span>
            <div className="title">
              <span>Error</span>
            </div>
            <p>Blank error message goes here</p>
          </>
        ) :
          (
            <>
              <span className="left alert-icon">
                <img
                  src={Alert}
                  width={12}
                  height={12}
                  alt='Alert'
                />
              </span>
              <div className="title">
                <span>Error:</span>
              </div>&nbsp;
              <div className="left">
                <p className='fontsize'>
                  {props.message[0]}
                  <br />
                  {props.message[1]}
                  <br />
                  {props.message[2]}
                </p>
              </div>
            </>
          )
      }
    </div>
  )
}

export default FormatOppConditionsErrorMessage;
