import { LANGUAGE_LIST } from '../actionTypes';

const initialState = {
  language_list: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LANGUAGE_LIST: {
      return {
        ...state,
        language_list: action.payload.id,
      };
    }
    default: {
      return state;
    }
  }
}
