// TODO: break up function, too big

import React, { useState, useEffect, useRef, useCallback } from "react";
import { Table, Col, Row, OverlayTrigger, Tooltip, Dropdown, FormControl, Container } from "react-bootstrap";
import "../../styles/App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect, useSelector } from "react-redux";
import {
  calculateDensityVariation,
  errorMessage,
  fullAmbient,
  fullFlowRate,
  fullLinePressure,
  fullLinePressureValue,
  fullTemperature,
  fullTemperatureValue,
  flowRateDecimalFactorPref,
  flowRateDecimal,
  revertFlag,
  temperatureDecimalFactorPref,
  temperatureDecimal,
  linePressureDecimalFactorPref,
  linePressureDecimal,
  allOtherDecimal,
  maxAmbient,
  maxFlowRate,
  maxLinePressure,
  maxLinePressureValue,
  maxTemperature,
  maxTemperatureValue,
  minAmbient,
  minFlowRate,
  minFlowRateValue,
  minLinePressure,
  minLinePressureValue,
  minTemperature,
  minTemperatureValue,
  normalAmbient,
  normalDensity,
  normalFlowRate,
  normalLinePressure,
  normalLinePressureValue,
  normalTemperature,
  normalTemperatureValue,
  unitsFlowRate,
  unitsLinePressure,
  unitsTemperature,
  unitsAmbient,
  maxDensity,
  minVapor,
  translationData,
  transError,
  baseReferenceConditions,
  pickFluidsFromDB,
  productFamily,
  unitsGasFlowRate,
  sonicVelocity,
  flowRateDropdownArray,
  flowRateGasDropdownArray,
  linePressDropdownArray,
  temperatureDropdownArray,
  normalDensityError,
  gasDensityError,
  flowRateNormClassList,
  flowRateMinClassList,
  flowRateMaxClassList,
  flowRateFullClassList,
  flowRateFullErrors,
  linePressMinClassList,
  linePressNormClassList,
  linePressMaxClassList,
  linePressFullClassList,
  linePressFullErrors,
  temperatureMinClassList,
  temperatureNormClassList,
  temperatureMaxClassList,
  temperatureFullClassList,
  ambientTemperatureMinClassList,
  ambientTemperatureNormClassList,
  ambientTemperatureMaxClassList,
  ambientTemperatureFullClassList,
  flowRateMinErrors,
  flowRateNormErrors,
  flowRateMaxErrors,
  linePressMinErrors,
  linePressNormErrors,
  linePressMaxErrors,
  temperatureMinErrors,
  temperatureNormErrors,
  temperatureMaxErrors,
  temperatureFullErrors,
  ambientTemperatureMinErrors,
  ambientTemperatureNormErrors,
  ambientTemperatureMaxErrors,
  ambientTemperatureFullErrors,
  wasClicked,
} from "../../redux/actions";
import { CustomCheckbox } from "../../components/CustomCheckbox/CustomCheckbox";
import {
  FLOW_RATE_UNIT_GAS,
  DEFAULT_DECIMAL,
  FLOW_RATE_LIQUID_SLURRY_VALUES,
  FLOW_RATE_GAS_VALUES,
  TEMPERATURE_VALUES,
  LINE_PRESSURE_VALUES,
  FLOW_RATE_VALUES,
  AMBIENT_TEMPERATURE_VALUES,
} from "../../constants/constants";
import { decimalPlaceNumeric, fixToDecimalPlaces, reg } from "../../UtilityFuntions/decimalFactor";
import { getUnitsKeyValue } from "../../UtilityFuntions/getUnitsKeyValue";
import CustomTranslation from "../../components/CustomTranslation/CustomTranslation";
import { CustomDropdown } from "../../components/CustomDropdown/CustomDropdown";
import CustomTranslationText from "../../components/CustomTranslation/CustomTranslationText";
import ErrorModal from "../../components/CustomModals/ErrorModal";
import FormatOppConditionsErrorMessage from "../../components/CustomTooltip/FormatOppConditionsErrorMessage";
import useConvertForUom from "../../UtilityFuntions/useConvertForUom";
import { usePrevious } from "../../UtilityFuntions/usePrevious";

const CustomMenu = React.forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
  const [value, setValue] = React.useState("");
  useEffect(() => {
    if (children.length > 0 ? (children[0].props ? children[0].props.clear : false) : false) {
      setValue("");
    }
  }, [children]);
  return (
    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
      <FormControl
        autoFocus
        className="mx-2 my-2 w-auto searchBox"
        placeholder="Search"
        onChange={(e) => setValue(e.target.value.replace(/([.?*+^$[\]\\(){}|-])/g, ""))}
        value={value}
      />
      <ul className="list-unstyled">
        {React.Children.toArray(children).filter((child) => !value || child.props.children.search(new RegExp(value, "i")) !== -1)}
      </ul>
    </div>
  );
});

function OperatingConditions(props) {
  const [technologyValue, setTechnologyValue] = useState("");
  const prevTechnology = usePrevious(technologyValue);
  const [decimalPlaces, setDecimalPlaces] = useState(DEFAULT_DECIMAL);
  const [oppCondition, setOppCondition] = useState("");
  const [uomFlowKey, setUomFlowKey] = useState(-1);
  const [uomPressureKey, setUomPressureKey] = useState(-1);
  const [uomTempKey, setUomTempKey] = useState(-1);
  const [uomTempAmbKey, setUomTempAmbKey] = useState(-1);
  const [uomFlowDropdownKey, setUomFlowDropdownKey] = useState(-1);
  const [prevUomFlowDropdownKey, setPrevUomFlowDropdownKey] = useState(-1);
  const [uomLinePressureDropdownKey, setUomLinePressureDropdownKey] = useState(-1);
  const [prevUomLinePressureDropdownKey, setPrevUomLinePressureDropdownKey] = useState(-1);
  const [uomTemperatureDropdownKey, setUomTemperatureDropdownKey] = useState(-1);
  const [prevUomTemperatureDropdownKey, setPrevUomTemperatureDropdownKey] = useState(-1);
  const [uomAmbientTemperatureDropdownKey, setUomAmbientTemperatureDropdownKey] = useState(-1);
  const [prevUomAmbientTemperatureDropdownKey, setPrevUomAmbientTemperatureDropdownKey] = useState(-1);
  const [showCalculateDensityVariation, setShowCalculateDensityVariation] = useState(false);
  const [ErrorCustom, setErrorCustom] = useState("");
  const [uomFlag, setUomFlag] = useState(false);
  const [uomObjectArray, setUomObjectArray] = useState([]);
  const [operatingdata, setOperatingdata] = useState(props.tableData);
  const [uomAutoConversion, setUomAutoConversion] = useState("");
  const [inputId, setInputId] = useState(null);

  useEffect(() => {
    setOperatingdata(props.tableData);
    //props.setCalcCall(true);
  }, [props.select_technology, props.saved_preferences, props.tableData]);

  // processes changes in decimal preferences for state & display
  // todo: should be two separate components, ideally

  useEffect(() => {
    setDecimalPlaces(
      props.saved_preferences.all_other_decimal_factor
        ? decimalPlaceNumeric(props.saved_preferences.all_other_decimal_factor)
        : decimalPlaceNumeric(props.decimal_factor_pref)
    );
    let flow = decimalPlaceNumeric(
      props.saved_preferences.flow_rate_precision ? props.saved_preferences.flow_rate_precision : props.flow_rate_decimal_factor
    );
    let line = decimalPlaceNumeric(
      props.saved_preferences.line_pressure_precision ? props.saved_preferences.line_pressure_precision : props.line_pressure_decimal_factor
    );
    let temp = decimalPlaceNumeric(
      props.saved_preferences.temperature_precision ? props.saved_preferences.temperature_precision : props.temperature_decimal_factor
    );
    let other = decimalPlaceNumeric(
      props.saved_preferences.all_other_decimal_factor ? props.saved_preferences.all_other_decimal_factor : props.decimal_factor_pref
    );
    // Set flow rate decimal place in redux
    props.flowRateDecimal(flow);
    // Set line pressure decimal place in redux
    props.linePressureDecimal(line);
    // Set temp decimal place in redux
    props.temperatureDecimal(temp);
    // Set all other decimal place in redux
    props.allOtherDecimal(other);

    props.tableData.map((item) => {
      let decimalPlace;
      if (item.lable === "XXSPECIFY_MMI_MC_LBL_FR") {
        decimalPlace = flow;
      } else if (item.lable === "XXSPECIFY_MMI_MC_LBL_LPRES") {
        decimalPlace = line;
      } else if (item.lable === "XXSPECIFY_MMI_MC_LBL_TEMP" || item.lable === "XXSPECIFY_MMI_MC_TXT_AMBTEM") {
        decimalPlace = temp;
      } else {
        decimalPlace = other;
      }

      for (const [index] of item.inputs.entries()) {
        let val = parseInt(item.inputs[index].value);
        let id;
        let value;
        if (!isNaN(val)) {
          id = item.inputs[index].id;
          value = parseFloat(val).toFixed(decimalPlace);
          updateTableData(id, value);
        }
      }
    });
  }, [props.saved_preferences]);

  // does the old job of insert values but with updated inputs for increased use.
  // todo: would like to update errors eventually

  const updateTableData = (updateId, updateValue) => {
    switch (updateId) {
      case "flow-rate-min":
        props.minFlowRate(updateValue);
        //props.minFlowRateValue(updateValue)
        break;
      case "flow-rate-normal":
        props.normalFlowRate(updateValue);
        break;
      case "flow-rate-max":
        props.maxFlowRate(updateValue);
        break;
      case "flow-rate-full":
        props.fullFlowRate(updateValue);
        break;
      case "line-press-min":
        props.minLinePressure(updateValue);
        break;
      case "line-press-normal":
        props.normalLinePressure(updateValue);
        break;
      case "line-press-max":
        props.maxLinePressure(updateValue);
        break;
      case "line-press-full":
        props.fullLinePressure(updateValue);
        break;
      case "temp-min":
        props.minTemperature(updateValue);
        break;
      case "temp-normal":
        props.normalTemperature(updateValue);
        break;
      case "temp-max":
        props.maxTemperature(updateValue);
        break;
      case "temp-full":
        props.fullTemperature(updateValue);
        break;
      case "ambient-min":
        props.minAmbient(updateValue);
        break;
      case "ambient-normal":
        props.normalAmbient(updateValue);
        break;
      case "ambient-max":
        props.maxAmbient(updateValue);
        break;
      case "ambient-full":
        props.fullAmbient(updateValue);
        break;
      default:
    }
  };

  const updateTableErrors = (updateId, updateError, updateClass) => {
    switch (updateId) {
      case "flow-rate-min":
        props.flowRateMinErrors(updateError);
        props.flowRateMinClassList(updateClass);
        break;
      case "flow-rate-normal":
        props.flowRateNormErrors(updateError);
        props.flowRateNormClassList(updateClass);
        break;
      case "flow-rate-max":
        props.flowRateMaxErrors(updateError);
        props.flowRateMaxClassList(updateClass);
        break;
      case "flow-rate-full":
        props.flowRateFullErrors(updateError);
        props.flowRateFullClassList(updateClass);
        break;
      case "line-press-min":
        props.linePressMinErrors(updateError);
        props.linePressMinClassList(updateClass);
        break;
      case "line-press-normal":
        props.linePressNormErrors(updateError);
        props.linePressNormClassList(updateClass);
        break;
      case "line-press-max":
        props.linePressMaxErrors(updateError);
        props.linePressMaxClassList(updateClass);
        break;
      case "line-press-full":
        props.linePressFullErrors(updateError);
        props.linePressFullClassList(updateClass);
        break;
      case "temp-min":
        props.temperatureMinErrors(updateError);
        props.temperatureMinClassList(updateClass);
        break;
      case "temp-normal":
        props.temperatureNormErrors(updateError);
        props.temperatureNormClassList(updateClass);
        break;
      case "temp-max":
        props.temperatureMaxErrors(updateError);
        props.temperatureMaxClassList(updateClass);
        break;
      case "temp-full":
        props.temperatureFullErrors(updateError);
        props.temperatureFullClassList(updateClass);
        break;
      case "ambient-min":
        props.ambientTemperatureMinErrors(updateError);
        props.ambientTemperatureMinClassList(updateClass);
        break;
      case "ambient-normal":
        props.ambientTemperatureNormErrors(updateError);
        props.ambientTemperatureNormClassList(updateClass);
        break;
      case "ambient-max":
        props.ambientTemperatureMaxErrors(updateError);
        props.ambientTemperatureMaxClassList(updateClass);
        break;
      case "ambient-full":
        props.ambientTemperatureFullErrors(updateError);
        props.ambientTemperatureFullClassList(updateClass);
        break;
      default:
    }
  };

  const handleErrorClose = () => {
    props.errorMessage(false);
    setErrorCustom("");
    props.wasClicked(false);
    // window.location.reload(false);
  };
  const handleErrorShow = () => props.error_message;

  // this will rerun the calcCall if the fluid state is changed
  useEffect(() => {
    if (!Object.keys(props.get_sizing_data).length > 0) {
      props.setCalcCall(true);
    }
    if (props.select_technology.toLowerCase() === "density" && props.fluid_state.toLowerCase() === "gas") {
      props.minFlowRate("");
      props.normalFlowRate("");
      props.maxFlowRate("");
      props.fullFlowRate("");
    }
    //this will run calcCall when state is changed from liquid to gas, to trigger calculation API
    if (
      (props.select_technology.toLowerCase() === "coriolis" ||
        props.select_technology.toLowerCase() === "vortex" ||
        props.select_technology.toLowerCase() === "density") &&
      (props.fluid_state.toLowerCase() === "gas" || props.fluid_state.toLowerCase() === "liquid") &&
      (props.fluid_source.toLowerCase() === "database" || (props.fluid_source.toLowerCase() === "natural gas" || (props.natural_gas_compose && props.composition_method !== "")))
    ) {
       setTimeout(() => {
        props.setCalcCall(true);
      }, 500);
    }
  }, [props.fluid_state, props.fluid_source, props.pick_fluids_from_db, props.composition_method, props.pick_gas_from_db, props.get_sizing_data]);

  useEffect(() => {
    if (props.clear_all_field) {
      setErrorCustom("");
      setUomAutoConversion("");
      setUomFlag(false);
      setUomObjectArray([]);
      setUomFlowKey(-1);
      setUomPressureKey(-1);
      setPrevUomFlowDropdownKey(-1);
      setUomFlowDropdownKey(-1);
      setPrevUomLinePressureDropdownKey(-1);
      setUomLinePressureDropdownKey(-1);
      setUomTempKey(-1);
      setUomTempAmbKey(-1);
      setUomTemperatureDropdownKey(-1);
      setPrevUomTemperatureDropdownKey(-1);
      setPrevUomAmbientTemperatureDropdownKey(-1);
      setUomAmbientTemperatureDropdownKey(-1);
      props.setCalcCall(false);
      setOperatingdata(props.tableData);
    }
  }, [props.clear_all_field]);

  const {
    minFlowRate,
    minFlowRateValue,
    normalFlowRate,
    maxFlowRate,
    fullFlowRate,
    minLinePressure,
    minLinePressureValue,
    normalLinePressure,
    normalLinePressureValue,
    maxLinePressure,
    maxLinePressureValue,
    fullLinePressure,
    fullLinePressureValue,
    minTemperature,
    minTemperatureValue,
    normalTemperature,
    normalTemperatureValue,
    maxTemperature,
    maxTemperatureValue,
    fullTemperature,
    fullTemperatureValue,
    minAmbient,
    normalAmbient,
    maxAmbient,
    fullAmbient,
    all_other_decimal,
    flow_rate_decimal,
    temperature_decimal,
    line_pressure_decimal,
  } = props;

  const insertValues = (event) => {
    const pointer = event.target.selectionStart;
    const element = event.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = pointer;
      element.selectionEnd = pointer;
    });
    let id = event.target.id;
    let value = event.target.value;
    updateTableData(id, value);
  };

  useEffect(() => {
    if (props.displayApiErrors) {
      setOperatingdata([...props.tableData]);
    }
  }, [props.displayApiErrors]);

  const buildValueArr = (inputs) => {
    let valueArr = [];
    inputs.forEach((element) => {
      let value = parseFloat(element.value);
      valueArr.push(value);
    });
    return valueArr;
  };

  const maxErrorMessages = (item, index) => {
    let errorArr = [];
    let inputs = item.inputs;
    let valueArr = buildValueArr(inputs);
    let test = false;
    let gt = -1;
    for (let i = 0; i < valueArr.length; i++) {
      if (i === 0) {
        errorArr.push([inputs[i].id, test, gt, i]);
      } else {
        for (let k = 0; k < i; k++) {
          test = nonConsecutive(valueArr[k], valueArr[i]);
          gt = valueArr.findIndex((key) => key > valueArr[i]);
          if (test === true) {
            break;
          }
        }
        errorArr.push([inputs[i].id, test, gt, i]);
      }
    }

    return errorArr;
  };

  function nonConsecutive(value, current) {
    return value > current;
  }

  const getErrorMessageById = (lt, gt) => {
    let error_msgs = props.translation_error;
    switch (lt) {
      case "flow-rate-min":
        break;
      case "flow-rate-normal":
        return error_msgs.normalFlowRate_lt_minFlowRate;
      case "flow-rate-max":
        if (gt === 0) {
          return error_msgs.maxFlowRate_lt_minFlowRate;
        } else {
          return error_msgs.maxFlowRate_lt_normalFlowRate;
        }
      case "flow-rate-full":
        if (gt === 0) {
          return error_msgs.fullFlowRate_lt_minFlowRate;
        } else if (gt === 1) {
          return error_msgs.fullFlowRate_lt_normalFlowRate;
        } else if (gt === 2) {
          return error_msgs.fullFlowRate_lt_maxFlowRate;
        }
        break;
      case "line-press-min":
        break;
      case "line-press-normal":
        return error_msgs.normalLinePressure_lt_minLinePressure;
      case "line-press-max":
        if (gt === 0) {
          return error_msgs.maxLinePressure_lt_minLinePressure;
        } else if (gt === 1) {
          return error_msgs.maxLinePressure_lt_normalLinePressure;
        }
        break;
      case "line-press-full":
        if (gt === 0) {
          return error_msgs.fullLinePressure_lt_minLinePressure;
        } else if (gt === 1) {
          return error_msgs.fullLinePressure_lt_normalLinePressure;
        } else if (gt === 2) {
          return error_msgs.fullLinePressure_lt_maxLinePressure;
        }
        break;
      case "temp-min":
        break;
      case "temp-normal":
        return error_msgs.normalTemp_lt_minTemp;
      case "temp-max":
        if (gt === 0) {
          return error_msgs.maxTemp_lt_minTemp;
        } else if (gt === 1) {
          return error_msgs.maxTemp_lt_normalTemp;
        }
        break;
      case "temp-full":
        if (gt === 0) {
          return error_msgs.fullTempValue_lt_minTemp;
        } else if (gt === 1) {
          return error_msgs.fullTempValue_lt_normalTempValue;
        } else if (gt === 2) {
          return error_msgs.fullTempValue_lt_maxTempValue;
        }
        break;
      case "ambient-min":
        return;
      case "ambient-normal":
        return error_msgs.normalAmbTemp_lt_minAmbTemp;
      case "ambient-max":
        if (gt === 0) {
          return error_msgs.maxAmbTemp_lt_minAmbTemp;
        } else if (gt === 1) {
          return error_msgs.maxAmbTemp_lt_normalAmbTemp;
        }
        break;
      case "ambient-full":
        if (gt === 0) {
          return error_msgs.fullAmbTemp_lt_minAmbTemp;
        } else if (gt === 1) {
          return error_msgs.fullAmbTemp_lt_normalAmbTemp;
        } else if (gt === 2) {
          return error_msgs.fullAmbTemp_lt_maxAmbTemp;
        }
        break;
      default:
        break;
    }
  };

  const decimalContext = (item) => {
    let element = item.lable;
    switch (element) {
      case "XXSPECIFY_MMI_MC_LBL_FR":
        return flow_rate_decimal;
      case "XXSPECIFY_MMI_MC_LBL_LPRES":
        return line_pressure_decimal;
      case "XXSPECIFY_MMI_MC_LBL_TEMP":
        return temperature_decimal;
      case "XXSPECIFY_MMI_MC_TXT_AMBTEM":
        return temperature_decimal;
      default:
        return all_other_decimal;
    }
  };

  //perform an action that trigger the error present in the different rows
  useEffect(() => {
    setTimeout(() => {
      if (props.select_technology.toLowerCase() === "density" && props.fluid_state.toLowerCase() === "gas") {
        document.getElementById("line-press-min").focus({ preventScroll: true });
        document.getElementById("temp-min").focus({ preventScroll: true });
      } else {
        try {
          document.getElementById("flow-rate-min").focus({ preventScroll: true });
          document.getElementById("line-press-min").focus({ preventScroll: true });
          document.getElementById("temp-min").focus({ preventScroll: true });
        } catch (error) {
          //no action
        }
      }

      if (props.select_technology.toLowerCase() !== "vortex" && props.select_technology.toLowerCase() !== "magnetic") {
        try {
          document.getElementById("ambient-min").focus({ preventScroll: true });
        } catch (error) {
          //no action
        }
      }
      try {
        document.getElementById("help-id").focus({ preventScroll: true });
        document.getElementById("help-id").blur();
      } catch (error) {
        //no actions
      }
    }, 500);
  }, [props.select_technology, props.viscosity, props.density, props.fluid_state]);

  useEffect(() => {
    if (props.steam_pressure_temp === "Based on Saturated Temperature") {
      // **
      props.normalLinePressure("");
    }
    if (props.steam_pressure_temp === "Based on Saturated Pressure") {
      props.normalTemperature("");
    }

    setTimeout(() => {
      props.setCalcCall(true);
    }, 1000);
  }, [props.steam_pressure_temp]);
  useEffect(() => {
    if (props.fluid_selection_flag && props.fluid_selection_section.op_data !== undefined) {
      props.normalFlowRate(fixToDecimalPlaces(props.fluid_selection_section.op_data.normal_flow_rate, flow_rate_decimal, reg));
      props.minFlowRate(fixToDecimalPlaces(props.fluid_selection_section.op_data.min_flow_rate, flow_rate_decimal, reg));
      props.maxFlowRate(fixToDecimalPlaces(props.fluid_selection_section.op_data.max_flow_rate, flow_rate_decimal, reg));
      props.fullFlowRate(fixToDecimalPlaces(props.fluid_selection_section.op_data.full_flow_rate, flow_rate_decimal, reg));
      props.minLinePressure(fixToDecimalPlaces(props.fluid_selection_section.op_data.min_line_pressure, line_pressure_decimal, reg));
      props.normalLinePressure(fixToDecimalPlaces(props.fluid_selection_section.op_data.normal_line_pressure, line_pressure_decimal, reg));
      props.maxLinePressure(fixToDecimalPlaces(props.fluid_selection_section.op_data.max_line_pressure, line_pressure_decimal, reg));
      props.fullLinePressure(fixToDecimalPlaces(props.fluid_selection_section.op_data.full_line_pressure, line_pressure_decimal, reg));
      props.minTemperature(fixToDecimalPlaces(props.fluid_selection_section.op_data.min_temperature, temperature_decimal, reg));
      props.normalTemperature(fixToDecimalPlaces(props.fluid_selection_section.op_data.normal_temperature, temperature_decimal, reg));
      props.maxTemperature(fixToDecimalPlaces(props.fluid_selection_section.op_data.max_temperature, temperature_decimal, reg));
      props.fullTemperature(fixToDecimalPlaces(props.fluid_selection_section.op_data.full_temperature, temperature_decimal, reg));
      props.minAmbient(fixToDecimalPlaces(props.fluid_selection_section.op_data.min_ambient, temperature_decimal, reg));
      props.normalAmbient(fixToDecimalPlaces(props.fluid_selection_section.op_data.normal_ambient, temperature_decimal, reg));
      props.maxAmbient(fixToDecimalPlaces(props.fluid_selection_section.op_data.max_ambient, temperature_decimal, reg));
      props.fullAmbient(fixToDecimalPlaces(props.fluid_selection_section.op_data.full_ambient, temperature_decimal, reg));
      props.calculateDensityVariation(props.fluid_selection_section.op_data.calculate_density_variation);
    }
  }, [props.fluid_selection_flag]);

  const handleBlur = (event) => {
    if (!props.clear_all_field) {
      let data = [...props.tableData];

      let id;
      let value;
      data.map((item, index) => {
        if (item.inputs.some((element) => element.id === event.target.id)) {
          const itemIndex = item.inputs.findIndex((key) => key.id === event.target.id);
          let decimalPosition = decimalContext(item);
          if (reg.test(parseFloat(event.target.value))) {
            item.inputs[itemIndex].value = parseFloat(event.target.value).toFixed(decimalPosition);
          } else {
            item.inputs[itemIndex].value = "";
          }
          id = item.inputs[itemIndex].id;
          value = item.inputs[itemIndex].value;
          updateTableData(id, value);

          //FPA-1995 changes

          let idObj = data
            .filter(function (data) {
              return data.lable === "XXSPECIFY_MMI_MC_LBL_LPRES";
            })
            .map(function (data) {
              return data.inputs;
            });

          idObj.forEach(function (item) {
            let newArr = groupBy(item, "value");
          });
          function groupBy(data, key) {
            var arr = [];
            for (var i in data) {
              let hasError = maxErrorMessages(item, itemIndex);
              hasError.forEach((element) => {
                var curr = item.inputs[element[3]];

                if (element[1] === true) {
                  let errMsg = getErrorMessageById(element[0], element[2]);

                  let errorClass;
                  // Checks required_fields to determine if error is in a required field or not. Important for maintaining the required_fields accuracy
                  if (props.required_fields.find((a) => a.id === element[0])) {
                    errorClass = "border-required border-error oc-input";
                  } else {
                    errorClass = "border-not-required border-error oc-input";
                  }
                  updateTableErrors(curr.id, errMsg, errorClass);
                } else if (curr.errorMessage.length > 0) {
                  // If no min/max error is present this checks if it was present. If it was, we clear it and set calc call to true
                  if (
                    curr.errorMessage.includes(CustomTranslationText("XXSPECIFY_MMI_MC_LBL_HINTMSG0", props)) ||
                    curr.errorMessage.includes(CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props))
                  ) {
                    if (props.required_fields.find((a) => a.id === element[0])) {
                      updateTableErrors(curr.id, "", "border-required oc-input");
                    } else {
                      updateTableErrors(curr.id, "", "border-not-required oc-input");
                    }
                  }
                  props.setCalcCall(true);
                } else {
                  props.setCalcCall(false);
                }
              });
            }
            return arr;
          }
        }
      });
      switch (event.target.id) {
        case "flow-rate-min":
          reg.test(parseFloat(event.target.value))
            ? props.minFlowRate(parseFloat(event.target.value).toFixed(flow_rate_decimal))
            : props.minFlowRate("");
          if (props.select_technology.toLowerCase() === "vortex" || props.select_technology.toLowerCase() === "magnetic") {
            props.setCalcCall(true);
          } else {
            props.setCalcCall(false);
          }
          props.minFlowRateValue(event.target.value);
          break;
        case "flow-rate-normal":
          reg.test(parseFloat(event.target.value))
            ? props.normalFlowRate(parseFloat(event.target.value).toFixed(flow_rate_decimal))
            : props.normalFlowRate("");
          props.setCalcCall(false);
          break;
        case "flow-rate-max":
          reg.test(parseFloat(event.target.value))
            ? props.maxFlowRate(parseFloat(event.target.value).toFixed(flow_rate_decimal))
            : props.maxFlowRate("");
          if (props.select_technology.toLowerCase() === "vortex" || props.select_technology.toLowerCase() === "magnetic") {
            props.setCalcCall(true);
          } else {
            props.setCalcCall(false);
          }
          break;
        case "flow-rate-full":
          reg.test(parseFloat(event.target.value))
            ? props.fullFlowRate(parseFloat(props.full_flow_rate).toFixed(flow_rate_decimal))
            : props.fullFlowRate("");
          break;

        case "line-press-min":
          reg.test(parseFloat(event.target.value))
            ? props.minLinePressure(parseFloat(props.min_line_pressure).toFixed(line_pressure_decimal))
            : props.minLinePressure("");
          //commenting below lines to avoid temp and line pressure dependency on setCalcCall call (FPA-1995)

          props.setCalcCall(true);
          reg.test(parseFloat(event.target.value))
            ? props.minLinePressureValue(parseFloat(props.min_line_pressure).toFixed(line_pressure_decimal))
            : props.minLinePressureValue("");
          break;
        case "line-press-normal":
          reg.test(parseFloat(event.target.value))
            ? props.normalLinePressure(parseFloat(props.normal_line_pressure).toFixed(line_pressure_decimal))
            : props.normalLinePressure("");
          reg.test(parseFloat(event.target.value))
            ? props.normalLinePressureValue(parseFloat(props.normal_line_pressure).toFixed(line_pressure_decimal))
            : props.normalLinePressureValue("");
          // below lines to avoid temp and line pressure dependency on setCalcCall call (FPA-1995)
          if (props.fluid_source.toLowerCase() === "custom") {
            setTimeout(() => {
              props.setCalcCall(true);
            }, 300);
          } else if (props.steam_pressure_temp === "Based on Saturated Temperature") {
            props.setCalcCall(false);
          } else {
            setTimeout(() => {
              props.setCalcCall(true);
            }, 300);
            
          }
          break;
        case "line-press-max":
          reg.test(parseFloat(event.target.value))
            ? props.maxLinePressure(parseFloat(props.max_line_pressure).toFixed(line_pressure_decimal))
            : props.maxLinePressure("");
          reg.test(parseFloat(event.target.value))
            ? props.maxLinePressureValue(parseFloat(props.max_line_pressure).toFixed(line_pressure_decimal))
            : props.maxLinePressureValue("");
          props.setCalcCall(true);
          break;
        case "line-press-full":
          reg.test(parseFloat(event.target.value))
            ? props.fullLinePressure(parseFloat(props.full_line_pressure).toFixed(line_pressure_decimal))
            : props.fullLinePressure("");
          reg.test(parseFloat(event.target.value))
            ? props.fullLinePressureValue(parseFloat(props.full_line_pressure).toFixed(line_pressure_decimal))
            : props.fullLinePressureValue("");
          props.setCalcCall(true);
          break;

        case "temp-min":
          reg.test(parseFloat(event.target.value))
            ? props.minTemperature(parseFloat(props.min_temperature).toFixed(temperature_decimal))
            : props.minTemperature("");
          reg.test(parseFloat(event.target.value))
            ? props.minTemperatureValue(parseFloat(props.min_temperature).toFixed(temperature_decimal))
            : props.minTemperatureValue("");
          //commenting below lines to avoid temp and line pressure dependency on setCalcCall call (FPA-1995)
          if (props.maxLinePressure === "") {
            props.setCalcCall(false);
          } else {
            props.setCalcCall(true);
          }
          break;
        case "temp-normal":
          reg.test(parseFloat(event.target.value))
            ? props.normalTemperature(parseFloat(props.normal_temperature).toFixed(temperature_decimal))
            : props.normalTemperature("");
          reg.test(parseFloat(event.target.value))
            ? props.normalTemperatureValue(parseFloat(props.normal_temperature).toFixed(temperature_decimal))
            : props.normalTemperatureValue("");
          if (props.steam_pressure_temp === "Based on Saturated Pressure") {
            props.setCalcCall(false);
          } else if (props.steam_pressure_temp === "Based on Saturated Temperature") {
            setTimeout(() => {
              props.setCalcCall(true);
            }, 700);
          } else {
            props.setCalcCall(true);
          }
          break;
        case "temp-max":
          reg.test(parseFloat(event.target.value))
            ? props.maxTemperature(parseFloat(props.max_temperature).toFixed(temperature_decimal))
            : props.maxTemperature("");
          reg.test(parseFloat(event.target.value))
            ? props.maxTemperatureValue(parseFloat(props.max_temperature).toFixed(temperature_decimal))
            : props.maxTemperatureValue("");
          if (props.max_temperature !== "" && reg.test(parseFloat(event.target.value))) {
            setTimeout(() => {
              props.setCalcCall(true);
            }, 1300);
          }

          break;
        case "temp-full":
          reg.test(parseFloat(event.target.value))
            ? props.fullTemperature(parseFloat(props.full_temperature).toFixed(temperature_decimal))
            : props.fullTemperature("");
          reg.test(parseFloat(event.target.value))
            ? props.fullTemperatureValue(parseFloat(props.full_temperature).toFixed(temperature_decimal))
            : props.fullTemperatureValue("");
          props.setCalcCall(true);
          break;

        case "ambient-min":
          reg.test(parseFloat(event.target.value))
            ? props.minAmbient(parseFloat(props.min_ambient).toFixed(temperature_decimal))
            : props.minAmbient("");

          props.setCalcCall(false);
          break;
        case "ambient-normal":
          reg.test(parseFloat(event.target.value))
            ? props.normalAmbient(parseFloat(props.normal_ambient).toFixed(temperature_decimal))
            : props.normalAmbient("");

          break;
        case "ambient-max":
          reg.test(parseFloat(event.target.value))
            ? props.maxAmbient(parseFloat(props.max_ambient).toFixed(temperature_decimal))
            : props.maxAmbient("");

          break;
        case "ambient-full":
          reg.test(parseFloat(event.target.value))
            ? props.fullAmbient(parseFloat(props.full_ambient).toFixed(temperature_decimal))
            : props.fullAmbient("");
          break;
        default:
      }
    }
  };

  const toggleCalculateDensityVariation = (checked) => {
    props.calculateDensityVariation(checked);
  };

  useEffect(() => {
    //perform an extra api call for vortex to get calculated fields in the UI when back to sizings for a compose gas
    if (!props.composition_method_flag && props.select_technology === "Vortex") {
      setTimeout(() => {
        props.setCalcCall(true);
      }, 1000);
    }
  }, [props.composition_method_flag]);

  //Setting dropdown default select value
  useEffect(() => {
    if (props.units_data && !props.clear_all_field) {
      if (Object.keys(props.get_sizing_data).length > 0 && props.get_sizing_data[0].fluid_state.toLowerCase() === props.fluid_state.toLowerCase()) {
        let data = props.get_sizing_data[0];
        if (props.fluid_state.toLowerCase() === "gas") {
          props.unitsGasFlowRate(getUnitsKeyValue(data.flowunit, FLOW_RATE_GAS_VALUES, props.units_data));
        } else {
          props.unitsFlowRate(getUnitsKeyValue(data.flowunit, FLOW_RATE_LIQUID_SLURRY_VALUES, props.units_data));
        }
        props.unitsTemperature(getUnitsKeyValue(data.tempunit, TEMPERATURE_VALUES, props.units_data));

        props.unitsAmbient(getUnitsKeyValue(data.ambient_temp_units, TEMPERATURE_VALUES, props.units_data));

        props.unitsLinePressure(getUnitsKeyValue(data.presunit, "nonVaporPressure", props.units_data));
      } else {
        if (props.fluid_state.toLowerCase() === "gas") {
          if (!props.revert_flag) {
            props.saved_preferences.flowrategas_unit
              ? props.unitsGasFlowRate(getUnitsKeyValue(props.units_gas_flow_rate, FLOW_RATE_GAS_VALUES, props.units_data))
              : props.unitsGasFlowRate(getUnitsKeyValue(props.units_gas_flow_rate, FLOW_RATE_GAS_VALUES, props.units_data));
          } else {
            props.revertFlag(false);
          }
        } else {
          if (!props.revert_flag) {
            props.saved_preferences.flowrate_unit
              ? props.unitsFlowRate(getUnitsKeyValue(props.units_flow_rate, FLOW_RATE_LIQUID_SLURRY_VALUES, props.units_data))
              : props.unitsFlowRate(getUnitsKeyValue(props.units_flow_rate, FLOW_RATE_LIQUID_SLURRY_VALUES, props.units_data));
            props.saved_preferences.temperature_unit
              ? props.unitsTemperature(getUnitsKeyValue(props.units_temperature, TEMPERATURE_VALUES, props.units_data))
              : props.unitsTemperature(getUnitsKeyValue(props.units_temperature, TEMPERATURE_VALUES, props.units_data));
            props.saved_preferences.ambient_temperature_unit
              ? props.unitsAmbient(getUnitsKeyValue(props.units_ambient, TEMPERATURE_VALUES, props.units_data))
              : props.unitsAmbient(getUnitsKeyValue(props.units_ambient, TEMPERATURE_VALUES, props.units_data));
            props.saved_preferences.linepressure_unit
              ? props.unitsLinePressure(getUnitsKeyValue(props.units_line_pressure, "nonVaporPressure", props.units_data))
              : props.unitsLinePressure(getUnitsKeyValue(props.units_line_pressure, "nonVaporPressure", props.units_data));
          } else {
            props.revertFlag(false);
          }
        }

        props.saved_preferences.auto_con_uom
          ? setUomAutoConversion(props.saved_preferences.auto_con_uom)
          : setUomAutoConversion(props.uom_auto_conversion_pref);
      }
    }
  }, [
    //props.displayApiErrors,
    props.units_data,
    props.fluid_state,
    props.get_sizing_data,
    props.select_technology,
    props.saved_preferences.flowrate_unit,
    props.saved_preferences.flowrategas_unit,
    props.saved_preferences.temperature_unit,
    props.saved_preferences.ambient_temperature_unit,
    props.saved_preferences.linepressure_unit,
    props.saved_preferences.auto_con_uom,
    props.clear_all_field,
  ]);

  // TODO: current bug that when key should be 0, it is undefined, use changeKey
  const getUomErrorMessage = async (from, to) => {
    let cond1 = (from.flow_type === "MASS" || from.flow_type === "VOL") && (to.flow_type === "VOL" || to.flow_type === "MASS");
    let cond2 = (from.flow_type === "MASS" || from.flow_type === "STDVOL") && (to.flow_type === "STDVOL" || to.flow_type === "MASS");
    let cond3 = (from.flow_type === "VOL" || from.flow_type === "STDVOL") && (to.flow_type === "STDVOL" || to.flow_type === "VOL");
    let cond4 = props.normal_flow_rate === "";
    let error_message = [
      CustomTranslationText("XXSPECIFY_MMI_SR_LBL_ERRMSG13", props),
      CustomTranslationText("XXSPECIFY_MMI_MC_LBL_HINTMSG0", props),
      CustomTranslationText("XXSPECIFY_MMI_MC_TXT_INPUT", props),
    ];
    if (cond1 & !cond4) {
      if (props.fluid_source.toLowerCase() === "custom") {
        if (props.normal_density === "") {
          await props.normalDensityError(true);
          await props.normalDensityError(false);
        }
        // await props.gasDensityError(true)
        // await props.gasDensityError(true)
      } else {
        if (props.normal_line_pressure === "") {
          updateTableErrors("line-press-normal", error_message, "border-error oc-input");
        }
        if (props.normal_temperature === "") {
          updateTableErrors("temp-normal", error_message, "border-error oc-input");
        }
      }
    }

    if (cond2 & !cond4) {
      if (props.fluid_source.toLowerCase() === "custom") {
        if (props.gas_density === "") {
          await props.gasDensityError(true);
          await props.gasDensityError(false);
        }
      } else {
        if (props.normal_line_pressure === "") {
          updateTableErrors("line-press-normal", error_message, "border-error oc-input");
        }
        if (props.normal_temperature === "") {
          updateTableErrors("temp-normal", error_message, "border-error oc-input");
        }
      }
    }
    if (cond3 & !cond4) {
      if (props.fluid_source.toLowerCase() === "custom") {
        if (props.gas_density === "") {
          await props.gasDensityError(true);
          await props.gasDensityError(false);
        }
        if (props.normal_density === "") {
          await props.normalDensityError(true);
          await props.normalDensityError(false);
        }
      } else {
        if (props.normal_line_pressure === "") {
          updateTableErrors("line-press-normal", error_message, "border-error oc-input");
        }
        if (props.normal_temperature === "") {
          updateTableErrors("temp-normal", error_message, "border-error oc-input");
        }
      }
    }
  };

  const handleUnitsFlowRateChange = (key, fluid) => {
    const changeKey = key ? parseInt(key) : 0;
    let data = [...operatingdata];
    let from;
    let to;
    const message = "Please provide an operating density, base density, or temperature and pressure where applicable.";
    data.map((item) => {
      if (item.lable === "XXSPECIFY_MMI_MC_LBL_FR") {
        let prev;
        if (fluid.toLowerCase() === "gas") {
          prev = item.units.dropdown_array.findIndex((p) => p.key === FLOW_RATE_UNIT_GAS);
        } else {
          prev = item.units.dropdown_array.findIndex((p) => p.key === props.units_flow_rate.key);
        }

        let key = prevUomFlowDropdownKey < 0 ? prev : uomFlowDropdownKey;
        from = item.units.dropdown_array.filter((item, idx) => idx === key)[0];
        to = item.units.dropdown_array.filter((item, idx) => idx === changeKey)[0];
      }
    });
    let cond4 = false;
    let cond1 = false;
    if (to.flow_type === "STDVOL") {
      props.baseReferenceConditions(true);
    } else {
      props.baseReferenceConditions(false);
    }

    if (fluid.toLowerCase() === "gas") {
      if (prevUomFlowDropdownKey < 0 && props.saved_preferences.flowrategas_unit) {
        // if (cond1 || cond2 || cond3 || cond4 || cond5 || cond6) {
        setPrevUomFlowDropdownKey(props.units_data.flowRateGas.findIndex((p) => p.key === props.saved_preferences.flowrategas_unit));
        setUomFlowDropdownKey(changeKey);
        setUomFlowKey(changeKey);
        setOppCondition(FLOW_RATE_GAS_VALUES);
        props.setCalcCall(true);
      } else if (prevUomFlowDropdownKey < 0) {
        // if (cond1 || cond2 || cond3 || cond4 || cond5 || cond6) {
        data.map((item) => {
          if (item.lable === "XXSPECIFY_MMI_MC_LBL_FR") {
            item.units.text = item.units.dropdown_array.filter((item, idx) => idx === changeKey)[0];
            if (item.units.text.flow_type === "STDVOL") {
              props.baseReferenceConditions(true);
            } else {
              props.baseReferenceConditions(false);
            }

            props.unitsGasFlowRate(item.units.dropdown_array[changeKey]);
            setPrevUomFlowDropdownKey(item.units.dropdown_array.findIndex((p) => p.key === FLOW_RATE_UNIT_GAS));
          }
        });
        setUomFlowDropdownKey(changeKey);
        setUomFlowKey(changeKey);
        setOppCondition(FLOW_RATE_GAS_VALUES);
        props.setCalcCall(true);
      } else {
        // if (cond1 || cond2 || cond3 || cond4 || cond5 || cond6) {
        setPrevUomFlowDropdownKey(uomFlowDropdownKey);
        data.map((item) => {
          if (item.lable === "XXSPECIFY_MMI_MC_LBL_FR") {
            props.unitsGasFlowRate(item.units.dropdown_array[changeKey]);
          }
        });
        setUomFlowDropdownKey(changeKey);
        setUomFlowKey(changeKey);
        setOppCondition(FLOW_RATE_GAS_VALUES);
      }
    } else {
      if (prevUomFlowDropdownKey < 0 && props.saved_preferences.flowrate_unit) {
        //if (cond4 || cond5 || cond3 || cond6) {
        setPrevUomFlowDropdownKey(props.units_data.flowRateLiquidSlurry.findIndex((p) => p.key === props.units_flow_rate.key));
        setUomFlowDropdownKey(changeKey);
        setUomFlowKey(changeKey);
        setOppCondition(FLOW_RATE_VALUES);
      } else if (prevUomFlowDropdownKey < 0) {
        //if (cond4 || cond5 || cond3 || cond6) {
        data.map((item) => {
          if (item.lable === "XXSPECIFY_MMI_MC_LBL_FR") {
            props.unitsFlowRate(item.units.dropdown_array.filter((item, idx) => idx === changeKey)[0]);
            if (item.units.text.flow_type === "STDVOL") {
              props.baseReferenceConditions(true);
            } else {
              props.baseReferenceConditions(false);
            }
            item.units.text = item.units.dropdown_array.filter((item, idx) => idx === changeKey)[0];
            setPrevUomFlowDropdownKey(item.units.dropdown_array.findIndex((p) => p.key === props.units_flow_rate.key));
          }
        });
        setUomFlowDropdownKey(changeKey);
        setUomFlowKey(changeKey);
        setOppCondition(FLOW_RATE_VALUES);
      } else {
        //if (cond4 || cond5 || cond3 || cond6) {
        data.map((item) => {
          if (item.lable === "XXSPECIFY_MMI_MC_LBL_FR") {
            props.unitsFlowRate(item.units.dropdown_array.filter((item, idx) => idx === changeKey)[0]);
          }
        });
        setPrevUomFlowDropdownKey(uomFlowDropdownKey);
        setUomFlowDropdownKey(changeKey);
        setUomFlowKey(changeKey);
        setOppCondition(FLOW_RATE_VALUES);
      }
    }
  };

  const handleUnitsLinePressChange = (key, unit, pref) => {
    let data = [...operatingdata];
    const changeKey = key ? parseInt(key) : 0;
    if (prevUomLinePressureDropdownKey < 0 && pref.linepressure_unit) {
      setPrevUomLinePressureDropdownKey(unit.nonVaporPressure.findIndex((p) => p.key === props.units_line_pressure.key));
    } else if (prevUomLinePressureDropdownKey < 0) {
      data.map((item) => {
        if (item.lable === "XXSPECIFY_MMI_MC_LBL_LPRES") {
          item.units.text = item.units.dropdown_array.filter((item, idx) => idx === changeKey)[0];
          props.unitsLinePressure(item.units.dropdown_array.filter((item, idx) => idx === changeKey)[0]);
          setPrevUomLinePressureDropdownKey(item.units.dropdown_array.findIndex((p) => p.key === props.units_line_pressure.key));
        }
      });
    } else {
      setPrevUomLinePressureDropdownKey(uomLinePressureDropdownKey);
    }
    setUomLinePressureDropdownKey(changeKey);
    setUomPressureKey(changeKey);
    setOppCondition(LINE_PRESSURE_VALUES);
    props.setCalcCall(false);
  };

  const handleUnitsTemperatureChange = (key, unit, pref) => {
    let data = [...operatingdata];
    const changeKey = key ? parseInt(key) : 0;
    if (prevUomTemperatureDropdownKey < 0 && pref.temperature_unit) {
      setPrevUomTemperatureDropdownKey(unit.temperature.findIndex((p) => p.key === props.units_temperature.key));
    } else if (prevUomTemperatureDropdownKey < 0) {
      data.map((item) => {
        if (item.lable === "XXSPECIFY_MMI_MC_LBL_TEMP") {
          item.units.text = item.units.dropdown_array.filter((item, idx) => idx === changeKey)[0];
          props.unitsTemperature(item.units.dropdown_array.filter((item, idx) => idx === changeKey)[0]);
          setPrevUomTemperatureDropdownKey(item.units.dropdown_array.findIndex((p) => p.key === props.units_temperature.key));
        }
      });
    } else {
      setPrevUomTemperatureDropdownKey(uomTemperatureDropdownKey);
    }
    setUomTemperatureDropdownKey(changeKey);
    setUomTempKey(changeKey);
    setOppCondition(TEMPERATURE_VALUES);
    props.setCalcCall(false);
  };
  const handleUnitsAmbientChange = (key, unit, pref) => {
    let data = [...operatingdata];
    const changeKey = key ? parseInt(key) : 0;
    if (prevUomAmbientTemperatureDropdownKey < 0 && pref.ambient_temperature_unit) {
      setPrevUomAmbientTemperatureDropdownKey(unit.temperature.findIndex((p) => p.key === props.units_ambient.key));
    } else if (prevUomAmbientTemperatureDropdownKey < 0) {
      data.map((item) => {
        if (item.lable === "XXSPECIFY_MMI_MC_TXT_AMBTEM") {
          item.units.text = item.units.dropdown_array.filter((item, idx) => idx === changeKey)[0];
          props.unitsAmbient(item.units.dropdown_array.filter((item, idx) => idx === changeKey)[0]);
          setPrevUomAmbientTemperatureDropdownKey(item.units.dropdown_array.findIndex((p) => p.key === props.units_ambient.key));
        }
      });
    } else {
      setPrevUomAmbientTemperatureDropdownKey(uomAmbientTemperatureDropdownKey);
    }
    setUomAmbientTemperatureDropdownKey(changeKey);
    setUomTempAmbKey(changeKey);
    setOppCondition(AMBIENT_TEMPERATURE_VALUES);
    props.setCalcCall(false);
  };

  useEffect(() => {
    if (uomFlowDropdownKey !== prevUomFlowDropdownKey) {
      if (props.fluid_state.toLowerCase() === "gas") {
        props.unitsGasFlowRate(props.flow_rate_gas_dropdown_array[uomFlowKey]);
        setUomFlowDropdownKey(uomFlowKey);
      } else {
        props.unitsFlowRate(props.flow_rate_dropdown_array[uomFlowKey]);
        setUomFlowDropdownKey(uomFlowKey);
      }
    }
  }, [uomFlowKey, props.flow_rate_gas_dropdown_array, props.flow_rate_dropdown_array, uomFlowDropdownKey, prevUomFlowDropdownKey]);

  useEffect(() => {
    if (uomLinePressureDropdownKey !== prevUomLinePressureDropdownKey) {
      props.unitsLinePressure(props.line_press_dropdown_array[uomPressureKey]);
      setUomLinePressureDropdownKey(uomPressureKey);
    }
  }, [uomPressureKey, props.line_press_dropdown_array, uomLinePressureDropdownKey, prevUomLinePressureDropdownKey]);

  useEffect(() => {
    if (uomTemperatureDropdownKey !== prevUomTemperatureDropdownKey) {
      props.unitsTemperature(props.temperature_dropdown_array[uomTempKey]);
      setUomTemperatureDropdownKey(uomTempKey);
    }
  }, [uomTempKey, props.temperature_dropdown_array, uomTemperatureDropdownKey, prevUomTemperatureDropdownKey]);

  useEffect(() => {
    if (uomAmbientTemperatureDropdownKey !== prevUomAmbientTemperatureDropdownKey) {
      props.unitsAmbient(props.temperature_dropdown_array[uomTempAmbKey]);
      setUomAmbientTemperatureDropdownKey(uomTempAmbKey);
    }
  }, [uomTempAmbKey, props.temperature_dropdown_array, uomAmbientTemperatureDropdownKey, prevUomAmbientTemperatureDropdownKey]);

  // determine when to set calculate density variation
  useEffect(() => {
    props.calculateDensityVariation(false);
    if (props.select_technology === "Coriolis") {
      if (props.fluid_state.toLowerCase() === "gas") {
        setShowCalculateDensityVariation(true);
      } else {
        setShowCalculateDensityVariation(false);
      }
    } else {
      setShowCalculateDensityVariation(false);
    }
    setPrevUomTemperatureDropdownKey(-1);
    setUomTemperatureDropdownKey(-1);
    setUomTempKey(-1);
    setPrevUomAmbientTemperatureDropdownKey(-1);
    setUomAmbientTemperatureDropdownKey(-1);
    setUomTempAmbKey(-1);
    setPrevUomLinePressureDropdownKey(-1);
    setUomLinePressureDropdownKey(-1);
    setUomPressureKey(-1);
  }, [props.select_technology, props.fluid_state]);

  //Setting dropdown data
  useEffect(() => {
    if (props.units_data) {
      let data = [...operatingdata];
      data.map((item) => {
        if (item.lable === "XXSPECIFY_MMI_MC_LBL_FR") {
          if (props.fluid_state.toLowerCase() === "gas") {
            //props.baseReferenceConditions(true);
            if (props.calculate_density_variation) {
              toggleCalculateDensityVariation(true);
            }
            props.flowRateGasDropdownArray(props.units_data.flowRateGas);
            item.units.dropdown_array = props.units_data.flowRateGas.filter((value, index, self) => self.indexOf(value) === index);

            item.units.text = props.units_gas_flow_rate
              ? props.units_gas_flow_rate
              : Object.keys(props.saved_preferences).length > 0
              ? props.saved_preferences.flowrategas_unit
              : "";

            if (props.select_technology.toLowerCase() !== "density" && item.units.text.flow_type === "STDVOL") {
              props.baseReferenceConditions(true);
            } else {
              props.baseReferenceConditions(false);
            }
          } else {
            props.baseReferenceConditions(false);
            props.flowRateDropdownArray(props.units_data.flowRateLiquidSlurry);
            item.units.dropdown_array = props.units_data.flowRateLiquidSlurry.filter((value, index, self) => self.indexOf(value) === index);
            item.units.text = props.units_flow_rate;
          }
        }
        if (item.lable === "XXSPECIFY_MMI_MC_LBL_LPRES") {
          // props.unitsLinePressure(props.units_data.nonVaporPressure);
          item.units.dropdown_array = props.units_data.nonVaporPressure.filter((value, index, self) => self.indexOf(value) === index);
          item.units.text = props.units_line_pressure;
        } else if (item.lable === "XXSPECIFY_MMI_MC_LBL_TEMP") {
          item.units.dropdown_array = props.units_data.temperature.filter((value, index, self) => self.indexOf(value) === index);
          item.units.text = props.units_temperature;
        }
        if (item.lable === "XXSPECIFY_MMI_MC_TXT_AMBTEM") {
          item.units.dropdown_array = props.units_data.temperature.filter((value, index, self) => self.indexOf(value) === index);
          item.units.text = props.units_ambient;
        }
      });
      setOperatingdata(data);
      props.temperatureDropdownArray(props.units_data.temperature);
      props.linePressDropdownArray(props.units_data.nonVaporPressure);
    }
  }, [
    props.units_data,
    props.fluid_state,
    props.saved_preferences,
    props.units_gas_flow_rate,
    props.units_flow_rate,
    props.units_line_pressure,
    props.units_temperature,
    props.units_ambient,
    //props.clear_all_field,
    props.select_technology,
  ]);

  const dropdownKeyValues = useCallback(() => {
    setUomFlowDropdownKey(uomFlowKey);
    setUomLinePressureDropdownKey(uomPressureKey);
    setUomTemperatureDropdownKey(uomTempKey);
    setUomAmbientTemperatureDropdownKey(uomTempAmbKey);
  }, [uomFlowKey, uomPressureKey, uomTempKey, uomTempAmbKey]);

  useEffect(() => {
    dropdownKeyValues();
  }, [dropdownKeyValues]);

  const conversionArray = useRef([]);
  const converstionUnits = useRef([]);
  useEffect(() => {
    if (oppCondition === FLOW_RATE_VALUES || oppCondition === FLOW_RATE_GAS_VALUES) {
      if (props.fluid_state.toLowerCase() === "gas") {
        conversionArray.current = [props.min_flow_rate, props.normal_flow_rate, props.max_flow_rate, props.full_flow_rate];
        converstionUnits.current = props.flow_rate_gas_dropdown_array;
      } else {
        conversionArray.current = [props.min_flow_rate, props.normal_flow_rate, props.max_flow_rate, props.full_flow_rate];
        converstionUnits.current = props.flow_rate_dropdown_array;
      }
    } else if (oppCondition === LINE_PRESSURE_VALUES) {
      conversionArray.current = [props.min_line_pressure, props.normal_line_pressure, props.max_line_pressure, props.full_line_pressure];
      converstionUnits.current = props.line_press_dropdown_array;
    } else if (oppCondition === TEMPERATURE_VALUES) {
      conversionArray.current = [props.min_temperature, props.normal_temperature, props.max_temperature, props.full_temperature];
      converstionUnits.current = props.temperature_dropdown_array;
    } else if (oppCondition === AMBIENT_TEMPERATURE_VALUES) {
      conversionArray.current = [props.min_ambient, props.normal_ambient, props.max_ambient, props.full_ambient];
      converstionUnits.current = props.temperature_dropdown_array;
    }
  }, [
    props.min_flow_rate,
    props.normal_flow_rate,
    props.max_flow_rate,
    props.full_flow_rate,
    props.min_line_pressure,
    props.normal_line_pressure,
    props.max_line_pressure,
    props.full_line_pressure,
    props.min_temperature,
    props.normal_temperature,
    props.max_temperature,
    props.full_temperature,
    props.min_ambient,
    props.normal_ambient,
    props.max_ambient,
    props.full_ambient,
    props.select_technology,
    props.fluid_state,
    uomFlowDropdownKey,
    prevUomFlowDropdownKey,
    oppCondition,
    props.flow_rate_dropdown_array,
    props.flow_rate_gas_dropdown_array,
    props.line_press_dropdown_array,
    props.temperature_dropdown_array,
    uomAmbientTemperatureDropdownKey,
    prevUomAmbientTemperatureDropdownKey,
    uomTemperatureDropdownKey,
    prevUomTemperatureDropdownKey,
    uomLinePressureDropdownKey,
    prevUomLinePressureDropdownKey,
  ]);

  //    unitType: the property or condition being converted
  //    value: number to be converted
  //    units: the object containing the dropdown keys
  //    unitKey: the converting to uom
  //    prevUnitKey: the converting from uom
  useEffect(() => {
    // if (oppCondition === FLOW_RATE_VALUES) {
    //   const uomFlowArray = conversionArray.current.map((value) => {
    //     return {

    //       unitType: FLOW_RATE_VALUES,
    //       value: '',
    //       units: props.flow_rate_dropdown_array,
    //       unitKey: uomFlowDropdownKey,
    //       prevUnitKey: prevUomFlowDropdownKey,
    //     };
    //   });

    //   setUomObjectArray(uomFlowArray);
    //   uomAutoConversion === 'ENABLED' ? setUomFlag(true) : setUomFlag(false);
    // } else if (oppCondition === FLOW_RATE_GAS_VALUES) {
    //   const uomFlowGasArray = conversionArray.current.map((value) => {
    //     return {
    //       unitType: FLOW_RATE_GAS_VALUES,
    //       value: '',
    //       units:props.flow_rate_gas_dropdown_array,
    //       unitKey: uomFlowDropdownKey,
    //       prevUnitKey: prevUomFlowDropdownKey,
    //     };
    //   });

    //   setUomObjectArray(uomFlowGasArray);
    //   uomAutoConversion === 'ENABLED' ? setUomFlag(true) : setUomFlag(false);
    // } else
    if (oppCondition === LINE_PRESSURE_VALUES) {
      const uomLineArray = conversionArray.current.map((value) => {
        return {
          unitType: LINE_PRESSURE_VALUES,
          value: "",
          units: props.line_press_dropdown_array,
          unitKey: uomLinePressureDropdownKey,
          prevUnitKey: prevUomLinePressureDropdownKey,
        };
      });

      setUomObjectArray(uomLineArray);
      uomAutoConversion === "ENABLED" ? setUomFlag(true) : setUomFlag(false);
    } else if (oppCondition === TEMPERATURE_VALUES) {
      const uomTemperatureArray = conversionArray.current.map((value) => {
        return {
          unitType: TEMPERATURE_VALUES,
          value: "",
          units: props.temperature_dropdown_array,
          unitKey: uomTemperatureDropdownKey,
          prevUnitKey: prevUomTemperatureDropdownKey,
        };
      });
      setUomObjectArray(uomTemperatureArray);
      uomAutoConversion === "ENABLED" ? setUomFlag(true) : setUomFlag(false);
    } else if (oppCondition === AMBIENT_TEMPERATURE_VALUES) {
      const uomAmbientTemperatureArray = conversionArray.current.map((value) => {
        return {
          unitType: AMBIENT_TEMPERATURE_VALUES,
          value: "",
          units: props.temperature_dropdown_array,
          unitKey: uomAmbientTemperatureDropdownKey,
          prevUnitKey: prevUomAmbientTemperatureDropdownKey,
        };
      });
      setUomObjectArray(uomAmbientTemperatureArray);
      uomAutoConversion === "ENABLED" ? setUomFlag(true) : setUomFlag(false);
    }
  }, [
    oppCondition,
    uomAutoConversion,
    uomFlowDropdownKey,
    prevUomFlowDropdownKey,
    uomLinePressureDropdownKey,
    prevUomLinePressureDropdownKey,
    uomTemperatureDropdownKey,
    prevUomTemperatureDropdownKey,
    uomAmbientTemperatureDropdownKey,
    prevUomAmbientTemperatureDropdownKey,
  ]);

  // Custom hook to do the API call
  let uomResults = useConvertForUom(uomObjectArray, uomFlag, props);

  // this sets the calculated values from the UOM conversion
  const resultsFixDecimalPlaces = useCallback(async () => {
    let operationArray = [...operatingdata];
    if (oppCondition === FLOW_RATE_VALUES) {
      if (uomAutoConversion === "ENABLED") {
        operationArray
          .filter((ftr) => ftr.lable === "XXSPECIFY_MMI_MC_LBL_FR")
          .map((item, index) => {
            if (item.inputs.some((element) => element.id === "flow-rate-min")) {
              const itemIndex = item.inputs.findIndex((key) => key.id === "flow-rate-min");
              item.inputs[itemIndex].value = fixToDecimalPlaces(uomResults[0].min, flow_rate_decimal, reg);
            }
            if (item.inputs.some((element) => element.id === "flow-rate-normal")) {
              const itemIndex = item.inputs.findIndex((key) => key.id === "flow-rate-normal");
              item.inputs[itemIndex].value = fixToDecimalPlaces(uomResults[1].norm, flow_rate_decimal, reg);
            }
            if (item.inputs.some((element) => element.id === "flow-rate-max")) {
              const itemIndex = item.inputs.findIndex((key) => key.id === "flow-rate-max");
              item.inputs[itemIndex].value = fixToDecimalPlaces(uomResults[2].max, flow_rate_decimal, reg);
            }
            if (item.inputs.some((element) => element.id === "flow-rate-full")) {
              const itemIndex = item.inputs.findIndex((key) => key.id === "flow-rate-full");
              item.inputs[itemIndex].value = fixToDecimalPlaces(uomResults[3].fsd, flow_rate_decimal, reg);
            }
          });
      }
      await setOperatingdata(operationArray);
      await minFlowRate(fixToDecimalPlaces(uomResults[0].min, flow_rate_decimal, reg));
      await minFlowRateValue(uomResults[0].min ? uomResults[0].min.toString() : "");

      await normalFlowRate(fixToDecimalPlaces(uomResults[1].norm, flow_rate_decimal, reg));
      await maxFlowRate(fixToDecimalPlaces(uomResults[2].max, flow_rate_decimal, reg));
      await fullFlowRate(fixToDecimalPlaces(uomResults[3].fsd, flow_rate_decimal, reg));
      await props.setCalcCall(true);
      uomResults = [];
      setUomObjectArray([]);
    }
    if (oppCondition === FLOW_RATE_GAS_VALUES) {
      if (uomAutoConversion === "ENABLED") {
        operationArray
          .filter((ftr) => ftr.lable === "XXSPECIFY_MMI_MC_LBL_FR")
          .map((item, index) => {
            if (item.inputs.some((element) => element.id === "flow-rate-min")) {
              const itemIndex = item.inputs.findIndex((key) => key.id === "flow-rate-min");
              item.inputs[itemIndex].value = fixToDecimalPlaces(uomResults[0].min, flow_rate_decimal, reg);
            }
            if (item.inputs.some((element) => element.id === "flow-rate-normal")) {
              const itemIndex = item.inputs.findIndex((key) => key.id === "flow-rate-normal");
              item.inputs[itemIndex].value = fixToDecimalPlaces(uomResults[1].norm, flow_rate_decimal, reg);
            }
            if (item.inputs.some((element) => element.id === "flow-rate-max")) {
              const itemIndex = item.inputs.findIndex((key) => key.id === "flow-rate-max");
              item.inputs[itemIndex].value = fixToDecimalPlaces(uomResults[2].flow_rate, flow_rate_decimal, reg);
            }
            if (item.inputs.some((element) => element.id === "flow-rate-full")) {
              const itemIndex = item.inputs.findIndex((key) => key.id === "flow-rate-full");
              item.inputs[itemIndex].value = fixToDecimalPlaces(uomResults[3].flow_rate, flow_rate_decimal, reg);
            }
          });
      }
      await setOperatingdata(operationArray);
      await minFlowRate(fixToDecimalPlaces(uomResults[0].min, flow_rate_decimal, reg));
      await normalFlowRate(fixToDecimalPlaces(uomResults[1].norm, flow_rate_decimal, reg));
      await maxFlowRate(fixToDecimalPlaces(uomResults[2].max, flow_rate_decimal, reg));
      await fullFlowRate(fixToDecimalPlaces(uomResults[3].fsd, flow_rate_decimal, reg));

      await props.setCalcCall(true);
      uomResults = [];
      setUomObjectArray([]);
    } else if (oppCondition === LINE_PRESSURE_VALUES) {
      if (uomAutoConversion === "ENABLED") {
        operationArray
          .filter((ftr) => ftr.lable === "XXSPECIFY_MMI_MC_LBL_LPRES")
          .map((item, index) => {
            if (item.inputs.some((element) => element.id === "line-press-min")) {
              const itemIndex = item.inputs.findIndex((key) => key.id === "line-press-min");
              item.inputs[itemIndex].value = fixToDecimalPlaces(uomResults[0].min, line_pressure_decimal, reg);
            }
            if (item.inputs.some((element) => element.id === "line-press-normal")) {
              const itemIndex = item.inputs.findIndex((key) => key.id === "line-press-normal");
              item.inputs[itemIndex].value = fixToDecimalPlaces(uomResults[1].norm, line_pressure_decimal, reg);
            }
            if (item.inputs.some((element) => element.id === "line-press-max")) {
              const itemIndex = item.inputs.findIndex((key) => key.id === "line-press-max");
              item.inputs[itemIndex].value = fixToDecimalPlaces(uomResults[2].max, line_pressure_decimal, reg);
            }
            if (item.inputs.some((element) => element.id === "line-press-full")) {
              const itemIndex = item.inputs.findIndex((key) => key.id === "line-press-full");
              item.inputs[itemIndex].value = fixToDecimalPlaces(uomResults[3].fsd, line_pressure_decimal, reg);
            }
          });
      }

      await minLinePressure(fixToDecimalPlaces(uomResults[0].min, line_pressure_decimal, reg));

      await minLinePressureValue(uomResults[0].min ? uomResults[0].min.toString() : "");

      await normalLinePressure(fixToDecimalPlaces(uomResults[1].norm, line_pressure_decimal, reg));

      await normalLinePressureValue(uomResults[1].norm ? uomResults[1].norm.toString() : "");

      await maxLinePressure(fixToDecimalPlaces(uomResults[2].max, line_pressure_decimal, reg));

      await maxLinePressureValue(uomResults[2].max ? uomResults[2].max.toString() : "");

      await fullLinePressure(fixToDecimalPlaces(uomResults[3].fsd, line_pressure_decimal, reg));

      await fullLinePressureValue(uomResults[3].fsd ? uomResults[3].fsd.toString() : "");

      await setOperatingdata(operationArray);
      await props.setCalcCall(true);
      uomResults = [];
      setUomObjectArray([]);
    } else if (oppCondition === TEMPERATURE_VALUES) {
      if (uomAutoConversion === "ENABLED") {
        operationArray
          .filter((ftr) => ftr.lable === "XXSPECIFY_MMI_MC_LBL_TEMP")
          .map((item, index) => {
            if (item.inputs.some((element) => element.id === "temp-min")) {
              const itemIndex = item.inputs.findIndex((key) => key.id === "temp-min");
              item.inputs[itemIndex].value = fixToDecimalPlaces(uomResults[0].min, temperature_decimal, reg);
            }
            if (item.inputs.some((element) => element.id === "temp-normal")) {
              const itemIndex = item.inputs.findIndex((key) => key.id === "temp-normal");
              item.inputs[itemIndex].value = fixToDecimalPlaces(uomResults[1].norm, temperature_decimal, reg);
            }
            if (item.inputs.some((element) => element.id === "temp-max")) {
              const itemIndex = item.inputs.findIndex((key) => key.id === "temp-max");
              item.inputs[itemIndex].value = fixToDecimalPlaces(uomResults[2].max, temperature_decimal, reg);
            }
            if (item.inputs.some((element) => element.id === "temp-full")) {
              const itemIndex = item.inputs.findIndex((key) => key.id === "temp-full");
              item.inputs[itemIndex].value = fixToDecimalPlaces(uomResults[3].fsd, temperature_decimal, reg);
            }
          });
      }
      await setOperatingdata(operationArray);
      await minTemperature(fixToDecimalPlaces(uomResults[0].min, temperature_decimal, reg));
      await minTemperatureValue(uomResults[0].min ? uomResults[0].min.toString() : "");
      await normalTemperature(fixToDecimalPlaces(uomResults[1].norm, temperature_decimal, reg));
      await normalTemperatureValue(uomResults[1].norm ? uomResults[1].norm.toString() : "");

      await maxTemperature(fixToDecimalPlaces(uomResults[2].max, temperature_decimal, reg));

      await maxTemperatureValue(uomResults[2].max ? uomResults[2].max.toString() : "");

      await fullTemperature(fixToDecimalPlaces(uomResults[3].fsd, temperature_decimal, reg));

      await fullTemperatureValue(uomResults[3].fsd ? uomResults[3].fsd.toString() : "");
      await props.setCalcCall(true);
      uomResults = [];
      setUomObjectArray([]);
    } else if (oppCondition === AMBIENT_TEMPERATURE_VALUES) {
      if (uomAutoConversion === "ENABLED") {
        operationArray
          .filter((ftr) => ftr.lable === "XXSPECIFY_MMI_MC_TXT_AMBTEM")
          .map((item, index) => {
            if (item.inputs.some((element) => element.id === "ambient-min")) {
              const itemIndex = item.inputs.findIndex((key) => key.id === "ambient-min");
              item.inputs[itemIndex].value = fixToDecimalPlaces(uomResults[0].min, temperature_decimal, reg);
            }
            if (item.inputs.some((element) => element.id === "ambient-normal")) {
              const itemIndex = item.inputs.findIndex((key) => key.id === "ambient-normal");
              item.inputs[itemIndex].value = fixToDecimalPlaces(uomResults[1].norm, temperature_decimal, reg);
            }
            if (item.inputs.some((element) => element.id === "ambient-max")) {
              const itemIndex = item.inputs.findIndex((key) => key.id === "ambient-max");
              item.inputs[itemIndex].value = fixToDecimalPlaces(uomResults[2].max, temperature_decimal, reg);
            }
            if (item.inputs.some((element) => element.id === "ambient-full")) {
              const itemIndex = item.inputs.findIndex((key) => key.id === "ambient-full");
              item.inputs[itemIndex].value = fixToDecimalPlaces(uomResults[3].fsd, temperature_decimal, reg);
            }
          });
      }
      await setOperatingdata(operationArray);
      await minAmbient(fixToDecimalPlaces(uomResults[0].min, temperature_decimal, reg));
      await normalAmbient(fixToDecimalPlaces(uomResults[1].norm, temperature_decimal, reg));
      await maxAmbient(fixToDecimalPlaces(uomResults[2].max, temperature_decimal, reg));
      await fullAmbient(fixToDecimalPlaces(uomResults[3].fsd, temperature_decimal, reg));
      await props.setCalcCall(true);
    }
    uomResults = [];
    setUomObjectArray([]);
  }, [
    props.flowRateDecimalFactorPref,
    props.temperatureDecimalFactorPref,
    props.linePressureDecimalFactorPref,
    props.saved_preferences,
    uomResults,
    oppCondition,
    minFlowRate,
    minFlowRateValue,
    normalFlowRate,
    maxFlowRate,
    fullFlowRate,
    minLinePressure,
    normalLinePressure,
    maxLinePressure,
    fullLinePressure,
    fullLinePressureValue,
    minTemperature,
    minTemperatureValue,
    normalTemperature,
    maxTemperature,
    maxTemperatureValue,
    fullTemperature,
    fullTemperatureValue,
    minAmbient,
    normalAmbient,
    maxAmbient,
    fullAmbient,
  ]);

  useEffect(() => {
    if (uomResults) {
      setUomFlag(false);
    }
  }, [uomResults]);

  useEffect(() => {
    if (uomResults) {
      resultsFixDecimalPlaces();
    }
  }, [uomResults]);

  useEffect(() => {
    if (props.tank) {
      props.normalFlowRate("");
      props.minFlowRate("");
      props.maxFlowRate("");
    }
  }, [props.tank]);

  let ambientTempRow = true;

  const inputWithTooltip = (errorMessage, className, insertValues, value, id) => {
    let isErr = errorMessage.length > 0;
    return (
      <OverlayTrigger
        trigger={["hover", "focus"]}
        show={inputId === id && isErr}
        overlay={
          <Tooltip id={`tooltip-error-${id}`} className="error-tooltip">
            <FormatOppConditionsErrorMessage message={errorMessage} decimalPlaces={decimalPlaces} />
          </Tooltip>
        }
      >
        <input
          id={id}
          className={[className, "oc-input"].join(" ")}
          onChange={insertValues}
          onBlur={(e) => handleBlur(e)}
          onMouseEnter={() => setInputId(id)}
          onFocus={() => setInputId(id)}
          onMouseLeave={() => setInputId(null)}
          value={value}
          readOnly={className === "border-disabled"}
          autoComplete="off"
        />
      </OverlayTrigger>
    );
  };

  const techFilter = (val) => {
    if (props.tank || (props.fluid_state.toLowerCase() === "gas" && props.select_technology.toLowerCase() === "density")) {
      props.minFlowRate("");
      props.minFlowRateValue("");
      props.normalFlowRate("");
      props.maxFlowRate("");
      return val.filter((list) => list.lable !== "XXSPECIFY_MMI_MC_LBL_FR");
    } else {
      return val;
    }
  };
  const rendertableData = () => {
    return techFilter(operatingdata).map((item, index) => {
      const { inputs, lable, units, id } = item;
      return (
        <tr key={index}>
          <td colSpan="2" className="flow-rate-heading">
            <CustomTranslation data={lable} />
          </td>
          {inputs.map((element, key) => {
            const { errorMessage = "", className, value, id } = element;
            return <td key={key}>{inputWithTooltip(errorMessage, className, insertValues, value, id)}</td>;
          })}
          <td>
            {units.id === "flow-rate-units" ? (
              <CustomDropdown
                eventHandler={(e) => handleUnitsFlowRateChange(e, props.fluid_state)}
                id={units.id}
                btnDropdownStyles={"operatingDropDown"}
                text={units.text.value}
                active={units.text.value}
                dropdownArray={units.dropdown_array}
                clear={props.clear_all_field}
                lowercaseValues={true}
              />
            ) : units.id === "line-press-units" ? (
              <CustomDropdown
                eventHandler={(e) => handleUnitsLinePressChange(e, props.units_data, props.saved_preferences)}
                id={units.id}
                btnDropdownStyles={"operatingDropDown"}
                text={units.text.value}
                active={units.text.value}
                dropdownArray={units.dropdown_array}
                clear={props.clear_all_field}
                lowercaseValues={true}
              />
            ) : units.id === "temp-min-units" ? (
              <CustomDropdown
                eventHandler={(e) => handleUnitsTemperatureChange(e, props.units_data, props.saved_preferences)}
                id={units.id}
                btnDropdownStyles={"operatingDropDown"}
                text={units.text.value}
                active={units.text.value}
                dropdownArray={units.dropdown_array}
                clear={props.clear_all_field}
                lowercaseValues={true}
              />
            ) : units.id === "ambient-units" ? (
              <CustomDropdown
                eventHandler={(e) => handleUnitsAmbientChange(e, props.units_data, props.saved_preferences)}
                id={units.id}
                btnDropdownStyles={"operatingDropDown"}
                text={units.text.value}
                active={units.text.value}
                dropdownArray={units.dropdown_array}
                clear={props.clear_all_field}
                lowercaseValues={true}
              />
            ) : (
              ""
            )}
          </td>
        </tr>
      );
    });
  };
  const rendertableOnSmallScreen = () => {
    return techFilter(operatingdata).map((item, index) => {
      const { inputs, lable, units, id } = item;
      return (
        <Container fluid key={index}>
          <Row auto>
            <Col sm={12} xs={12} className="flow-rate-heading-small">
              <CustomTranslation data={lable} />
            </Col>
          </Row>
          <div className="mt-2"></div>
          <Table borderless className="operating-conditions-table">
            {inputs.map((element, key, index) => {
              const { errorMessage = "", className, value, id } = element;
              return (
                <tbody key={index + id}>
                  <tr>
                    {key === 0 ? (
                      <td>
                        <CustomTranslation data="XXSPECIFY_MMI_MC_LBL_MN" />
                      </td>
                    ) : key === 1 ? (
                      <td>
                        <CustomTranslation data="XXSPECIFY_MMI_MC_LBL_NL" />
                      </td>
                    ) : key === 2 ? (
                      <td>
                        <CustomTranslation data="XXSPECIFY_MMI_MC_LBL_MX" />
                      </td>
                    ) : key === 3 ? (
                      <td>
                        <CustomTranslation data="XXSPECIFY_MMI_MC_TXT_FSD" />
                      </td>
                    ) : (
                      ""
                    )}
                    <td>{inputWithTooltip(errorMessage, className, insertValues, value, id)}</td>
                  </tr>
                </tbody>
              );
            })}
            <tbody>
              <tr>
                <td>
                  {" "}
                  <CustomTranslation data="XXSPECIFY_MMI_MC_DROPDN_UOM" />
                </td>
                <td>
                  {
                    <td className="dropdownMobile">
                      {units.id === "flow-rate-units" ? (
                        <CustomDropdown
                          eventHandler={(e) => handleUnitsFlowRateChange(e, props.fluid_state)}
                          id={units.id}
                          btnDropdownStyles={"operatingDropDown"}
                          text={units.text.value}
                          active={units.text.value}
                          dropdownArray={units.dropdown_array}
                          clear={props.clear_all_field}
                          lowercaseValues={true}
                        />
                      ) : units.id === "line-press-units" ? (
                        <CustomDropdown
                          eventHandler={(e) => handleUnitsLinePressChange(e, props.units_data, props.saved_preferences)}
                          id={units.id}
                          btnDropdownStyles={"operatingDropDown"}
                          text={units.text.value}
                          active={units.text.value}
                          dropdownArray={units.dropdown_array}
                          clear={props.clear_all_field}
                          lowercaseValues={true}
                        />
                      ) : units.id === "temp-min-units" ? (
                        <CustomDropdown
                          eventHandler={(e) => handleUnitsTemperatureChange(e, props.units_data, props.saved_preferences)}
                          id={units.id}
                          btnDropdownStyles={"operatingDropDown"}
                          text={units.text.value}
                          active={units.text.value}
                          dropdownArray={units.dropdown_array}
                          clear={props.clear_all_field}
                          lowercaseValues={true}
                        />
                      ) : units.id === "ambient-units" ? (
                        <CustomDropdown
                          eventHandler={(e) => handleUnitsAmbientChange(e, props.units_data, props.saved_preferences)}
                          id={units.id}
                          btnDropdownStyles={"operatingDropDown"}
                          text={units.text.value}
                          active={units.text.value}
                          dropdownArray={units.dropdown_array}
                          clear={props.clear_all_field}
                          lowercaseValues={true}
                        />
                      ) : (
                        ""
                      )}
                    </td>
                  }
                </td>
              </tr>
            </tbody>
          </Table>
        </Container>
      );
    });
  };
  return (
    <Container fluid>
      <Row>
        <Col lg={12} md={12}>
          <ErrorModal message={ErrorCustom} show={props.error_message} handleClose={handleErrorClose} handleShow={handleErrorShow} />
          <div className="operating-conditions-heading">
            <CustomTranslation data="XXSPECIFY_MMI_MC_LBL_OPT_COND" />{" "}
          </div>
          {showCalculateDensityVariation ? (
            <div className="calculate-density-variation-checkbox">
              <CustomCheckbox
                checked={props.calculate_density_variation}
                name="calculate-density-variation"
                id="calculate-density-variation-checkbox"
                className="custom-checkbox"
                onChange={(e) => toggleCalculateDensityVariation(e.target.checked)}
                disabled={!showCalculateDensityVariation}
                label={<CustomTranslation data="XXSPECIFY_MMI_MC_CHKBX_FRCRNR" />}
              />
            </div>
          ) : (
            <div />
          )}
          <div className="operating-conditions d-none d-sm-block">
            <Table borderless className="operating-conditions-table">
              <thead>
                <tr className="operating-conditions-table-header">
                  <th colSpan="2">&nbsp;</th>
                  <th className="font-weight-normal op-table-heading-min">
                    <CustomTranslation data="XXSPECIFY_MMI_MC_LBL_MN" />
                  </th>
                  <th className="font-weight-normal op-table-heading-norm">
                    <CustomTranslation data="XXSPECIFY_MMI_MC_LBL_NL" />
                  </th>
                  <th className="font-weight-normal op-table-heading-max">
                    <CustomTranslation data="XXSPECIFY_MMI_MC_LBL_MX" />
                  </th>
                  <th className="font-weight-normal op-table-heading-full">
                    <CustomTranslation data="XXSPECIFY_MMI_MC_TXT_FSD" />
                  </th>
                  <th className="font-weight-normal op-table-heading-units flow-unit" colSpan="2">
                    <CustomTranslation data="XXSPECIFY_MMI_MC_DROPDN_UOM" />
                  </th>
                </tr>
              </thead>
              <tbody>{rendertableData()}</tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <div className="d-block d-sm-none">
        <hr></hr>
        {rendertableOnSmallScreen()}
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  flow_rate_decimal_factor: state.Preferences.flow_rate_decimal_factor,
  line_pressure_decimal_factor: state.Preferences.line_pressure_decimal_factor,
  temperature_decimal_factor: state.Preferences.temperature_decimal_factor,
  select_technology: state.MeasurementType.select_technology,
  min_flow_rate: state.ProcessVariables.min_flow_rate,
  min_flow_rate_value: state.ProcessVariables.min_flow_rate_value,
  normal_flow_rate: state.ProcessVariables.normal_flow_rate,
  max_flow_rate: state.ProcessVariables.max_flow_rate,
  full_flow_rate: state.ProcessVariables.full_flow_rate,
  units_flow_rate: state.ProcessVariables.units_flow_rate,
  min_line_pressure: state.ProcessVariables.min_line_pressure,
  min_line_pressure_value: state.ProcessVariables.min_line_pressure_value,
  normal_line_pressure: state.ProcessVariables.normal_line_pressure,
  normal_line_pressure_value: state.ProcessVariables.normal_line_pressure_value,
  max_line_pressure: state.ProcessVariables.max_line_pressure,
  max_line_pressure_value: state.ProcessVariables.max_line_pressure_value,
  full_line_pressure: state.ProcessVariables.full_line_pressure,
  full_line_pressure_value: state.ProcessVariables.full_line_pressure_value,
  min_temperature: state.ProcessVariables.min_temperature,
  min_temperature_value: state.ProcessVariables.min_temperature_value,
  normal_temperature: state.ProcessVariables.normal_temperature,
  get_sizing_data: state.MySizings.get_sizing_data,
  normal_temperature_value: state.ProcessVariables.normal_temperature_value,
  max_temperature: state.ProcessVariables.max_temperature,
  max_temperature_value: state.ProcessVariables.max_temperature_value,
  full_temperature: state.ProcessVariables.full_temperature,
  full_temperature_value: state.ProcessVariables.full_temperature_value,
  min_ambient: state.ProcessVariables.min_ambient,
  normal_ambient: state.ProcessVariables.normal_ambient,
  max_ambient: state.ProcessVariables.max_ambient,
  full_ambient: state.ProcessVariables.full_ambient,
  units_density: state.ProcessVariables.units_density,
  units_viscosity: state.ProcessVariables.units_viscosity,
  units_vapor: state.ProcessVariables.units_vapor,
  calculate_density_variation: state.ProcessVariables.calculate_density_variation,
  fluid_state: state.FluidSelection.fluid_state,
  fluid_source: state.FluidSelection.fluid_source,
  revert_flag: state.FluidSelection.revert_flag,
  pick_fluids_from_db: state.FluidSelection.pick_fluids_from_db,
  saved_preferences: state.Preferences.saved_preferences,
  units_data: state.DropdownData.units_data,
  error_message: state.ErrorMessage.error_message,
  translation_error: state.TranslationData.translation_error,
  translationData: state.TranslationData,
  normal_density: state.ProcessVariables.normal_density,
  clear_all_field: state.ClearAllField.clear_all_field,
  pick_gas_from_db: state.FluidSelection.pick_gas_from_db,
  gas_density: state.ProcessVariables.gas_density,
  units_line_pressure: state.ProcessVariables.units_line_pressure,
  units_temperature: state.ProcessVariables.units_temperature,
  units_ambient: state.ProcessVariables.units_ambient,
  flow: state.MeasurementType.flow,
  density: state.MeasurementType.density,
  viscosity: state.MeasurementType.viscosity,
  tank: state.ApplicationRequirements.tank,
  units_gas_flow_rate: state.ProcessVariables.units_gas_flow_rate,
  reset_operating_conditions: state.ProcessVariables.reset_operating_conditions,
  decimal_factor_pref: state.Preferences.decimal_factor_pref,
  uom_auto_conversion_pref: state.Preferences.uom_auto_conversion_pref,
  flow_rate_decimal: state.Preferences.flow_rate_decimal,
  temperature_decimal: state.Preferences.temperature_decimal,
  line_pressure_decimal: state.Preferences.line_pressure_decimal,
  fluid_selection_flag: state.FluidSelection.fluid_selection_flag,
  all_other_decimal: state.Preferences.all_other_decimal,
  flow_rate_dropdown_array: state.ProcessVariables.flow_rate_dropdown_array,
  flow_rate_gas_dropdown_array: state.ProcessVariables.flow_rate_gas_dropdown_array,
  line_press_dropdown_array: state.ProcessVariables.line_press_dropdown_array,
  temperature_dropdown_array: state.ProcessVariables.temperature_dropdown_array,
  required_fields: state.ProcessVariables.required_fields,
  units_gas_density: state.ProcessVariables.units_gas_density,
  line_press_norm_errors: state.ProcessVariables.line_press_norm_errors,
  line_press_norm_class_list: state.ProcessVariables.line_press_norm_class_list,
  gas_pressure: state.ProcessVariables.gas_pressure,
  translation_data: state.TranslationData.translation_data,
  gas_temperature: state.ProcessVariables.gas_temperature,
  units_sonic_velocity: state.ProcessVariables.units_sonic_velocity,
  sonic_velocity: state.ProcessVariables.sonic_velocity,
  steam_pressure_temp: state.FluidSelection.steam_pressure_temp,
  fluid_selection_section: state.FluidSelection.fluid_selection_section,
  composition_method: state.FluidSelection.composition_method,
  natural_gas_compose: state.FluidSelection.natural_gas_compose
});

export default connect(mapStateToProps, {
  minFlowRate,
  minFlowRateValue,
  normalFlowRate,
  maxFlowRate,
  fullFlowRate,
  unitsFlowRate,
  minLinePressure,
  minLinePressure,
  minLinePressureValue,
  normalLinePressure,
  normalLinePressureValue,
  maxLinePressure,
  maxLinePressureValue,
  fullLinePressure,
  fullLinePressureValue,
  flowRateDecimalFactorPref,
  flowRateDecimal,
  temperatureDecimalFactorPref,
  temperatureDecimal,
  linePressureDecimalFactorPref,
  linePressureDecimal,
  allOtherDecimal,
  unitsLinePressure,
  minTemperature,
  minTemperatureValue,
  normalTemperature,
  normalTemperatureValue,
  maxTemperature,
  maxTemperatureValue,
  fullTemperature,
  fullTemperatureValue,
  unitsTemperature,
  minAmbient,
  normalAmbient,
  maxAmbient,
  fullAmbient,
  unitsAmbient,
  calculateDensityVariation,
  normalDensity,
  maxDensity,
  unitsGasFlowRate,
  minVapor,
  errorMessage,
  translationData,
  transError,
  baseReferenceConditions,
  pickFluidsFromDB,
  productFamily,
  sonicVelocity,
  flowRateDropdownArray,
  flowRateGasDropdownArray,
  linePressDropdownArray,
  temperatureDropdownArray,
  normalDensityError,
  gasDensityError,
  flowRateNormClassList,
  flowRateMinClassList,
  flowRateMaxClassList,
  flowRateFullClassList,
  flowRateFullErrors,
  revertFlag,
  linePressMinClassList,
  linePressNormClassList,
  linePressMaxClassList,
  linePressFullClassList,
  linePressFullErrors,
  temperatureMinClassList,
  temperatureNormClassList,
  temperatureMaxClassList,
  temperatureFullClassList,
  ambientTemperatureMinClassList,
  ambientTemperatureNormClassList,
  ambientTemperatureMaxClassList,
  ambientTemperatureFullClassList,
  flowRateMinErrors,
  flowRateNormErrors,
  flowRateMaxErrors,
  linePressMinErrors,
  linePressNormErrors,
  linePressMaxErrors,
  temperatureMinErrors,
  temperatureNormErrors,
  temperatureMaxErrors,
  temperatureFullErrors,
  ambientTemperatureMinErrors,
  ambientTemperatureNormErrors,
  ambientTemperatureMaxErrors,
  ambientTemperatureFullErrors,
  wasClicked,
})(OperatingConditions);
