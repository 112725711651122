import React from 'react';
import {
  Modal, Table
} from 'react-bootstrap';
import '../../styles/App.scss';
import './modal.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CustomButton } from '../CustomButton/CustomButton';
import CustomTranslation from '../CustomTranslation/CustomTranslation';


const UploadModal = props => {
  return (
    <>
      <Modal centered show={props.show} onHide={props.handleClose} animation={false}>
        <Modal.Header className='modal-header-fpa' closeButton>
          <Modal.Title className="modal-title"><CustomTranslation  data="XXSPECIFY_MMI_PR_LBL_MESSAGE"/></Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-fpa'>
          <p className="main-text">Please attach related File.</p>
          {/*<p className="info-text"></p>*/}
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
              classNames="btn btn-close-modal btn-green"
              label="OK"
              onClick={props.handleClose}
              arrow="right"
              size="lg"
            />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UploadModal;
