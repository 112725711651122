import { TRANSLATION_DATA, TRANSLATION_ERROR } from '../actionTypes';

const initialState = {
  translation_data: [],
  translation_error: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TRANSLATION_DATA: {
      return {
        ...state,
        translation_data: action.payload.id,
      };
    }
    case TRANSLATION_ERROR: {
      return {
        ...state,
        translation_error: action.payload.id,
      };
    }
    default: {
      return state;
    }
  }
}
