export const decimalPlaceNumeric = value => {
  switch (value) {
    case 'up to 1 place': return 1;
    case 'up to 2 places': return 2;
    case 'up to 3 places': return 3;
    case 'up to 4 places': return 4;
    case 'up to 5 places': return 5;
    case 'up to 6 places': return 6;
    case 'up to 7 places': return 7;
    case 'up to 8 places': return 8;
    case 'up to 9 places': return 9;
    case 'up to 10 places': return 10;
    default: return 2;
  }
}

export const decimalPlaceDropdownValues = () => {
  return [{
    'conversionformula': null,
    'conversionformula2': null,
    'flow_type': null,
    'label': 'up to 1 place',
    'language': 'US',
    'unit_flag': null,
    'unit_system': null,
    'unit_type': null,
    'value': 'UP TO 1 PLACE'
  },{
    'conversionformula': null,
    'conversionformula2': null,
    'flow_type': null,
    'label': 'up to 2 places',
    'language': 'US',
    'unit_flag': null,
    'unit_system': null,
    'unit_type': null,
    'value': 'UP TO 2 PLACES'
  },{
    'conversionformula': null,
    'conversionformula2': null,
    'flow_type': null,
    'label': 'up to 3 places',
    'language': 'US',
    'unit_flag': null,
    'unit_system': null,
    'unit_type': null,
    'value': 'UP TO 3 PLACES'
  },{
    'conversionformula': null,
    'conversionformula2': null,
    'flow_type': null,
    'label': 'up to 4 places',
    'language': 'US',
    'unit_flag': null,
    'unit_system': null,
    'unit_type': null,
    'value': 'UP TO 4 PLACES'
  },{
    'conversionformula': null,
    'conversionformula2': null,
    'flow_type': null,
    'label': 'up to 5 places',
    'language': 'US',
    'unit_flag': null,
    'unit_system': null,
    'unit_type': null,
    'value': 'UP TO 5 PLACES'
  },{
    'conversionformula': null,
    'conversionformula2': null,
    'flow_type': null,
    'label': 'up to 6 places',
    'language': 'US',
    'unit_flag': null,
    'unit_system': null,
    'unit_type': null,
    'value': 'UP TO 6 PLACES'
  },{
    'conversionformula': null,
    'conversionformula2': null,
    'flow_type': null,
    'label': 'up to 7 places',
    'language': 'US',
    'unit_flag': null,
    'unit_system': null,
    'unit_type': null,
    'value': 'UP TO 7 PLACES'
  },{
    'conversionformula': null,
    'conversionformula2': null,
    'flow_type': null,
    'label': 'up to 8 places',
    'language': 'US',
    'unit_flag': null,
    'unit_system': null,
    'unit_type': null,
    'value': 'UP TO 8 PLACES'
  },{
    'conversionformula': null,
    'conversionformula2': null,
    'flow_type': null,
    'label': 'up to 9 places',
    'language': 'US',
    'unit_flag': null,
    'unit_system': null,
    'unit_type': null,
    'value': 'UP TO 9 PLACES'
  },{
    'conversionformula': null,
    'conversionformula2': null,
    'flow_type': null,
    'label': 'up to 10 places',
    'language': 'US',
    'unit_flag': null,
    'unit_system': null,
    'unit_type': null,
    'value': 'UP TO 10 PLACES'
  }];
}

export const fixToDecimalPlaces = (value, decimalPlaces, reg) => {
  const maxDecimalPlaces = 12;
  value = ((!value && value !== 0) || Number.isNaN(Number.parseFloat(value))) ? '' : Number.parseFloat(value).toFixed(maxDecimalPlaces);
  if (!value || Number.isNaN(value)) {
    return '';
  } else {
    return reg.test(value)
      ? Number.parseFloat(value).toFixed(decimalPlaces)
      : '';
  }
}

// Check for decimal, will allow the user to enter from 0 up to 12 decimal places
// (10 is the max you can set in preferences)
export const reg = /^[+-]?\d+(\.\d{0,12})?$/;
