import axios from 'axios';

const cancelTokenSources = {};

export const getCancelToken = (tokenKey) => {
  if (cancelTokenSources[tokenKey]) {
    cancelTokenSources[tokenKey].cancel('Previous request canceled');
  }

  cancelTokenSources[tokenKey] = axios.CancelToken.source();
  return cancelTokenSources[tokenKey].token;
};

export const resetCancelToken = (tokenKey) => {
  delete cancelTokenSources[tokenKey];
};