import React from 'react';
import {
  Modal, Table
} from 'react-bootstrap';
import '../../styles/App.scss';
import './modal.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CustomButton } from '../CustomButton/CustomButton';
import { Link } from 'react-router-dom';
import CustomTranslation from '../CustomTranslation/CustomTranslation';

const PreferencesModal = props => {
  return (
    <>
      <Modal centered show={props.show} onHide={props.handleClose} animation={false}>
        <Modal.Header className='modal-header-fpa' closeButton>
          <Modal.Title className="modal-title-confirmation"><CustomTranslation data="XXSPECIFY_MMI_PR_LBL_MESSAGE" /> </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-fpa'>
          {/*<p className="main-text">Please attach related File.</p>*/}
          <p className="info-text">{props.message}</p>
          {props.link?<a href="https://www.emerson.com/catalog/LogonForm?locale=en-us&myAcctMain=1&catalogId=20051&storeId=20151&langId=-1" target="_blank" >click here to login</a>:''}
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
              classNames="btn btn-close-modal btn-green"
              label="OK"
              onClick={props.handleClose}
              arrow="right"
              size="lg"
            />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PreferencesModal;
