
// preferences defaults
export const FLOW_RATE_UNIT_LIQUID = 'USGPH';
export const FLOW_RATE_UNIT_GAS = 'KG/HR';
export const TEMPERATURE_UNIT = 'F';
export const AMBIENT_TEMPERATURE_UNIT = 'F';
export const LINE_PRESSURE_UNIT = 'PSIG';
export const DENSITY_UNIT = 'LB/GAL(US)';
export const VISCOSITY_UNIT = 'CP';
export const VAPOR_PRESSURE_UNIT = 'PSIA';
export const ATMOSPHERIC_UNIT = 'PSIA';
export const GAS_ONLY_PRESSURE_UNIT = 'BAR-A';
export const GAS_ONLY_PRESSURE = '1.01325348872';
export const GAS_ONLY_TEMPERATURE_UNIT = 'C';
export const GAS_ONLY_TEMPERATURE = '15.555555555555555';
export const GAS_ONLY_DENSITY_UNIT = 'KG/M3';
export const SONIC_VELOCITY_UNIT = 'FT/SEC';
export const DECIMAL_FACTOR = 'up to 2 places';
export const UOM_AUTO_CONVERSION = 'ENABLED';

// for DECIMAL_FACTOR
export const DEFAULT_DECIMAL = 4;

// APIs
export const GET_PRECISION_DATA = '/mmipa/get_precision_data/';
export const GET_SAVED_PREF = '/mmipa/get_saved_preferences/';
export const SAVE_PREF_URL = '/mmipa/save_preferences/';
export const GET_UNITS = '/mmipa/units/';
export const GET_FLUID_STATE = '/mmipa/get_fluid_state/';
export const GET_AOL_MESSAGES = '/mmipa/get_aol_messages/';
export const GET_FLUID_SERVICE = '/mmipa/get_fluid_service/';
export const GET_FLUIDS = '/mmipa/get_fluids/';
export const SEARCH_SIZING_RESULTS = '/mmipa/search_sizing_results/';
export const GET_REPORT_BLOB =  '/mmipa/download_blob/';
export const GET_CONDUCTIVITY = '/mmipa/get_conductivity/';
export const GET_LANGUAGES = '/mmipa/get_languages/';
export const POST_OFFLINE_LOGIN = '/mmipa/offline_login/';
export const GET_ALL_LINE_SIZE = '/mmipa/get_all_line_size/';
export const GET_PRODUCT_FAMILY = '/mmipa/get_product_family/';
export const GET_WETTED_MATERIAL = '/mmipa/get_wetted_material/';
export const POST_SAVED_SIZINGS_FILTERED = '/mmipa/saved_sizings_filtered/';
export const UOM_CONVERSION = '/mmipa/uom_conversion/';
export const CALCULATIONS = '/mmipa/calculations/';
export const GET_SCHEDULE_DATA = '/mmipa/get_schedule_data/';
export const GET_NATURAL_GAS_COMP_LIMITS = '/mmipa/get_ngas_comp_limits/';
export const GET_REFERENCE_IDS = '/mmipa/get_reference_ids/';
export const CALCULATE_VALIDATE_PIPE_ID = '/mmipa/calculate_validate_pipe_id/';
export const OFFLINE_SYNC = '/mmipa/offline_synchronization/';
export const GAS_COMPOSITION_NORMALIZE = '/mmipa/normalize/';
export const NGAS_COMP_METHOD_MESSAGES = '/mmipa/ngas_comp_method_messages/';
export const GET_PRODUCT_IMAGE_URL = '/mmipa/get_product_image_url/';
export const GET_SIZING = '/mmipa/get_sizing/';
// general constants
export const FLOW_RATE_LIQUID_SLURRY_VALUES = 'flowRateLiquidSlurry';
export const FLOW_RATE_GAS_VALUES = 'flowRateGas';
export const DENSITY_VALUES = 'density';
export const GAS_DENSITY_VALUES = 'gasDensity';
export const GAS_DENSITY_SG_VALUES = 'gasDensitySG';
export const VISCOSITY_VALUES = 'viscosity';
export const VISCOSITY_CP_VALUES =  'VISCOSITY_CP_VALUES';
export const VORTEX_VALUES = 'vortex';
export const MAGNETIC_VALUES = 'magnetic';
export const NON_VAPOR_PRESSURE_VALUES = 'nonVaporPressure';
export const SONIC_VELOCITY_VALUES = 'sonicVelocity';
export const GAS_TEMP_VALUES = 'gasTemperature';
export const TEMPERATURE_VALUES = 'temperature';
export const GAS_PRESSURE_VALUES = 'gasPressure';
export const VAPOR_PRESSURE_VALUES = 'vaporPressure';
export const LINE_PRESSURE_VALUES = 'linePressure';
export const FLOW_RATE_VALUES = 'flowRate';
export const AMBIENT_TEMPERATURE_VALUES = 'ambientTemperature';
export const MAGMETER_SYSTEM_VALUES = 'Magmeter System';
export const BASE64_IMG_PREFIX= 'data:image/png;base64,';
export const NO_IMAGE_FOUND = 'No image found';
export const PRODUCT_IMAGE = 'productImage';
export const SHOW_MORE_RATES = 'showMoreRates';