import React from 'react';
import {
  Col,
  Row,
  ToggleButtonGroup,
  ToggleButton, 
  Navbar,
  NavDropdown, 
  Container, 
  Nav
} from 'react-bootstrap';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';
import { useState, useEffect } from 'react';
import {navbarFlag } from '../../redux/actions';


const GreenNavBar = props => {
  const [viewGraphCheck, setViewGraphCheck] = useState(false)
  const [installationCheck, setInstallationCheck] = useState(false)


  React.useEffect(() => {
    if (props.display_sizing_results && !props.request_sizing_results) {
      document.getElementById("sizing_results").click();
      //props.onClickSizingResults('sizing-results-section')
    }
  }, [props.display_sizing_results, props.request_sizing_results ]);



  useEffect(() => {
    //view graph
      if(props.flow && props.display_sizing_results && !props.request_sizing_results &&
       props.show_more_rates_data.length !== 0){
        setViewGraphCheck(true)
        setInstallationCheck(false)
      }else if((props.density || props.viscosity) && (props.selected_row_data.meter_type === 'Fork-Density' ||  props.selected_row_data.meter_type === 'Fork-Viscosity') && props.tank){
        setViewGraphCheck(false)
        setInstallationCheck(true)
      }else if(props.density && !props.request_sizing_results && props.fluid_state.toLowerCase() ==='gas'){
        setInstallationCheck(false)
        setViewGraphCheck(false)
      }else if(props.viscosity  && props.fluid_state.toLowerCase() ==='slurry'){
        setInstallationCheck(true)
        setViewGraphCheck(false)
      }else if(props.density && !props.display_sizing_results && props.fluid_state.toLowerCase() ==='slurry'){
        setViewGraphCheck(false);
      }else if( props.density && !props.request_sizing_results && props.selected_row_data.meter_type === 'CORIOLIS'){
        setViewGraphCheck(true)
        setInstallationCheck(false)
      }else if(props.density && !props.request_sizing_results && props.selected_row_data.meter_type === 'Fork-Density'){
        setViewGraphCheck(false)
        setInstallationCheck(true)
      }else if(props.viscosity && !props.request_sizing_results &&  props.selected_row_data.meter_type === 'Fork-Viscosity'){
        setViewGraphCheck(false)
        setInstallationCheck(true)
      }
   
   
  }, [props.display_sizing_results, props.show_more_rates_data, props.selected_row_data, props.select_technology, props.fluid_state])
  





  const clickInstalation = () =>{
    props.onClickSizingResults('installation-details-section')
  }


  return (
    <>
      <Navbar expand="xl" className='green-nav-bar'>
        <span />  
        <Navbar.Toggle aria-controls="toggle-navbar"  className='border border-dark' />
        <Navbar.Collapse id="toggle-navbar">
          <Col xl={2} />
            <ToggleButtonGroup type="radio" name="options" defaultValue={1}>  
              <ToggleButton
                id="sizing_input"
                className="nav-button"
                value={1}
                onClick={
                  props.display_sizing_results ? 
                  ()=> props.onClickSizingResults('sizing-input-section') :
                  ()=>{}
                }
                  
              >
                <CustomTranslation data="XXSPECIFY_MMI_SR_TAB_SIZ_IP" />
              </ToggleButton>
              {props.display_sizing_results && !props.request_sizing_results
                ? <ToggleButton
                  id="sizing_results"
                  className='nav-button'
                  ref={props.forwardedRef}
                  value={2}
                  onClick={() => props.onClickSizingResults('sizing-results-section')}
                >
                  <CustomTranslation data="XXSPECIFY_MMI_SR_TAB_SZNGREWEB" />
                  </ToggleButton>
                : <span />}
              {props.display_sizing_results && !props.request_sizing_results && !props.tank &&
              (props.show_more_rates_data.length !== 0 || (props.fluid_state.toLowerCase() ==='slurry' )) && (props.fluid_state.toLowerCase() === 'gas' && props.density ? false:true)
                ? <ToggleButton
                  id="show_more_result"
                  className="nav-button"
                  value={3}
                  onClick={
                    props.display_sizing_results && !props.request_sizing_results && 
                    props.show_more_rates_data.length !== 0 && (props.fluid_state.toLowerCase() === 'gas' && props.density ? false:true) ?
                    () => props.onClickSizingResults('show-more-rates-section'):
                    ()=>{}
                  }
                >
                  <CustomTranslation data="XXSPECIFY_MMI_SR_TAB_SHOW" />
                  </ToggleButton>
                : <span />}
              {(props.display_sizing_results && !props.request_sizing_results &&
              props.show_more_rates_data.length !== 0 || (props.fluid_state?props.fluid_state.toLowerCase() ==='slurry':false) ) && viewGraphCheck
                
                ? <ToggleButton
                  id="view_graph"
                  className="nav-button"
                  value={4}
                  onClick={
                    props.display_sizing_results && !props.request_sizing_results && 
                    props.show_more_rates_data.length !== 0 && (props.fluid_state.toLowerCase() === 'gas' && props.density ? false:true) ?
                    () => props.onClickSizingResults('view-graph-section'):
                    ()=>{}
                  }
                >
                  <CustomTranslation data="XXSPECIFY_MMI_SR_TAB_VIEW_GR" />
                  </ToggleButton>
                : <span />}
              {props.display_sizing_results && !props.request_sizing_results && 
              (props.fluid_state.toLowerCase()==='slurry' ?props.show_more_rates_data.length !== 0:props.show_more_rates_data.length !== 0)  && installationCheck 
              
                ? <ToggleButton
                  id="instalation_details"
                  className="nav-button"
                  value={4}
                  onClick={clickInstalation}
                >
                  <CustomTranslation data="XXSPECIFY_MMI_SR_BTN_INST_DET" />
                  </ToggleButton>
                : <span />}
            </ToggleButtonGroup>
          <Col lg={2} />
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

const mapStateToProps = state => ({
  display_sizing_results: state.SizingResults.display_sizing_results,
  request_sizing_results: state.SizingResults.request_sizing_results,
  viscosity: state.MeasurementType.viscosity,
  density: state.MeasurementType.density,
  flow: state.MeasurementType.flow,
  show_more_rates_data: state.SizingResults.show_more_rates_data,
  fluid_state: state.FluidSelection.fluid_state,
  in_line: state.ApplicationRequirements.in_line,
  flow_through: state.ApplicationRequirements.flow_through_chamber_slipstream,
  selected_row_data: state.SizingResults.selected_row_data,
  tank: state.ApplicationRequirements.tank,
  select_technology: state.MeasurementType.select_technology,
  flag: state.SizingResults.navbar_flag,
});

export default connect(mapStateToProps, {
  navbarFlag,
})(GreenNavBar);
