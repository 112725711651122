import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";

import './Installation.scss';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';
import { useTable, useSortBy ,useResizeColumns} from "react-table";


const InstallationDetailsTable = ({ columns, data, tableType, headers }) => {
  // let columns=column.filter(data=>data!='')
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    toggleAllRowsSelected,
    state: { pageIndex, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    useResizeColumns
    
  );
  const firstPageRows = rows;
  const tableClasses = `installation-table ${tableType}`;
  return (
    <>
      <Table
        bordered
        hover
        responsive
        className={tableClasses}
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <span className="header-content">
                    <span className="span-center">
                      <TableHeader
                        heading={
                          typeof column.Header === "string"
                            ? column.Header
                            : undefined
                        }
                        headers={headers}
                      />
                    </span>
                  </span>

                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={row.isSelected ? "selected-row" : ""}
              >
                {row.cells.map((cell) => {
                  return (
                    cell.render("Cell")==="YES"?
                    <td style={{color: 'green'}} {...cell.getCellProps()}>{cell.render("Cell")}</td>:
                    <td  {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <br />
    </>
  );
};

const TableHeader = ({ heading, headers }) => {
  const formatHeading = (heading) => {
    switch (heading) {
      case "FLOW RATE":
        return (
          <>
            <CustomTranslation data='XXSPECIFY_MMI_SHR_TBL_FLOWRATE' /><br />
            
            <br />
            <span className="sub-text-uom">{`(${headers.flow_rate})`}</span>
          </>
        );

      case "VELOCITY":
        return (
          <>
            <CustomTranslation data='XXSPECIFY_MMI_SHR_TBL_VELOCITY' />
            <br />
            <span className="sub-text-uom">{headers.tube_velocity}</span>
          </>
        );
      case "NOTRECOMMENDED":
        return (
          <p style={{color: 'red'}}>
          {heading}
          </p>
        );
      case "RECOMMENDED":
        return (
          <p style={{color: 'green'}}>
          {heading}
          </p>
        );
      case "ALLOWABLE":
        return (
          <p style={{color: "#ffc107"}}>
          {heading}
          </p>
        );
      default:
        return heading;
    }
  };

  return <>{formatHeading(heading)}</>;
};


export default (InstallationDetailsTable);
