import React from 'react';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';
import CustomTranslationText from '../../components/CustomTranslation/CustomTranslationText';
import { connect } from 'react-redux';

const SizingResultsProductFamilyComparisons = props => {

  const openLink = () => {
    let url = ''
    if (props.select_technology.toLowerCase() === 'coriolis' 
    || props.select_technology.toLowerCase() === 'density' 
    || props.select_technology.toLowerCase() === 'viscosity') {
      url = CustomTranslationText('XXSPECIFY_MMI_PRDFML_COR_DOC', props)
    } else if (props.select_technology.toLowerCase() === 'vortex') {
      url = CustomTranslationText('XXSPECIFY_MMI_PRDFML_VTX_DOC', props)
    } else if (props.select_technology.toLowerCase() === 'magnetic') {
      url = CustomTranslationText('XXSPECIFY_MMI_PRDFML_MAG_DOC', props)
    }
    window.open(url, 'ProductFamilyComparisons', 'width=900,height=600');
  }

  return (
    <>
      <CustomButton
        classNames="btn btn-product-family-comparisons btn-white-fpa"
        label={<CustomTranslation data='XXSPECIFY_MMI_PRDFML_GUIDE_NAM' /> }
        onClick={() => openLink()}
        size="lg"
      />
    </>
  )
}

const mapStateToProps = state => ({
  translation_data: state.TranslationData.translation_data,
});

export default connect(
  mapStateToProps,
)(SizingResultsProductFamilyComparisons);
