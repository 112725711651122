import React, { useState } from "react";
import {
  FormControl,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "./input.scss";
import FormatOppConditionsErrorMessage from "../../components/CustomTooltip/FormatOppConditionsErrorMessage";

export const CustomInputField = (props) => {
  const {
    name,
    id,
    labelClassName,
    fieldClassName,
    onBlur,
    onChange,
    size,
    value,
    disabled,
    borderRequired,
    borderError,
    postScript,
    isError,
    errorText,
  } = props;
  const [inputId, setInputId] = useState("");
  
  const handleMouseOver = (e) => {
    setInputId(e.target.id);
  };
  const handleMouseLeave = (e) => {
    setInputId("");
  };

  const borderOutline =
    borderError && !disabled
      ? "2px solid #d31245"
      : borderRequired && !disabled
      ? value === "" && isError
        ? "2px solid red"
        : "2px solid #00aa7e"
      : "none";
  let fontSize =
    fieldClassName === "custom-fluid-fieldname5" ? "14px" : "1.25rem";

  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "end" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label className={labelClassName} htmlFor={id}>
          {name}
        </label>
        <OverlayTrigger
          placement="top"
          trigger={["hover", "focus"]}
          show={(isError && id === inputId) || false}
          overlay={
            <Tooltip id={`tooltip-error-${id}`} className="error-tooltip">
              <FormatOppConditionsErrorMessage message={[errorText]} />
            </Tooltip>
          }
        >
          <InputGroup size={size} className={fieldClassName}>
            <FormControl
              className="input-field composition-input-form-control"
              disabled={disabled}
              style={{ border: borderOutline, fontSize: fontSize }}
              id={id}
              onChange={onChange}
              onBlur={onBlur}
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
              value={value}
            />
          </InputGroup>
        </OverlayTrigger>
      </div>
      {postScript && (
        <span style={{ marginBottom: "12px" }} className="post-script">
          {postScript}{" "}
        </span>
      )}
    </div>
  );
};
