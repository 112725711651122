import React,{ useReducer } from "react"; 
import  { DEFAULT_DECIMAL } from '../../constants/constants';

export const initialFluidPropertyState = {
    densityUnit: '',
    viscosityUnit:'',
    vaporPressureUnit:'',
    atmosphericUnit:'',
    gasOnlyPressureUnit:'',
    gasOnlyPressure:'',
    gasOnlyTemperatureUnit:'',
    gasOnlyTemperature:'',
    gasOnlyDensityUnit:'',
    sonicVelocityUnit:'',
    minDensityValue:'',
    normalDensityValue:'',
    maxDensityValue:'',
    minViscosityValue:'',
    normalViscosityValue:'',
    maxViscosityValue:'',
    maxViscosityValue:'',
    minVaporValue:'',
    densityDropdown:'',
    viscosityDropdown:'',
    vaporPressureDropdown:'',
    maxPressureDropDropdown:'',
    densityDropdownUnits:[],
    gasDensityDropdownUnits:[],
    gasDensitySGDropdownUnits:[],
    viscosityDropdownUnits:[],
    vaporPressureDropdownUnits:[],
    maxPressureDropdownUnits:[],
    densityDropdownKey:-1,
    gasDensityDropdownKey:-1,
    temperatureDropdownKey:-1,
    gasDensitySGDropdownKey:-1,
    prevDensityDropdownKey:-1,
    prevTemperatureDropdownKey:-1,
    prevGasDensityDropdownKey:-1,
    prevGasDensitySGDropdownKey:-1,
    viscosityDropdownKey:-1,
    pressureDropdownKey:-1,
    SonicVelocityDropdownKey:-1,
    prevViscosityDropdownKey:-1,
    prevPressureDropdownKey:-1,
    prevSonicVelocityDropdownKey:-1,
    vaporPressureDropdownKey:-1,
    prevVaporPressureDropdownKey:-1,
    decimalPlaces:4,
    uomDensityKey:-1,
    uomGasDensityKey:-1,
    uomGasDensitySGKey: -1,
    uomViscosityKey:-1,
    uomPressureKey:-1,
    uomTemperatureKey:-1,
    uomVaporPressureKey:-1,
    uomSonicVelocityKey:-1,
    oppCondition:'',
    uomFlag:false,
    uomObjectArray:[],
    uomAutoConversion:'',
    showDensityRow: false,
    showVaporPressureRow:false,
    showMaxFlowAccuracyRow:false,
    showPressureAtmosphericRow:false,
    showMinAccuracyRow:false,
    showMaxPressureDropRow:false,
    showViscosityRow:false,
    showSonicVelocityRow:false,
    showNonNewtonianCheckbox:false,
    showCompressibility:false,
    showBaseCompressibilityRow:false,
    densityHeadingDisabled:false,
    baseGasDensityHeadingDisabled:false,
    densityMin:'border-disabled',
    densityNormal:'border-disabled',
    densityMax:'border-disabled',
    viscosityMin:'border-disabled',
    viscosityNormal:'border-disabled',
    viscosityMax:'border-disabled',
    vaporPressureMin:'border-disabled',
    flowAccMax:'border-not-required',
    pressureDropMax:'border-not-required',
    atmosphericPressure:'border-not-required',
    velocitySonic:'border-not-required',
    kFlowConsistencyIndex:'border-required',
    nFlowBehavior:'border-required',
    gasCompressibility:'border-not-required',
    specificGravity:'border-required',
    molecularWeight:'border-required',
    gasPressure:'border-not-required',
    gasTemperature:'border-not-required',
    baseCompressibility: 'border-required',
    gasDensity:'border-disabled',
    gasDensityError:'',
    toggleNonNewtonLiquid : false,
    maxPressureDropdownUnits: [],
    maxPressureDropDropdownUnits:[],
    normalDensityError:  '',
    gasCustomCompressibilityError: '',
    molecularWeightError: '',
    gasCustomCompressibility: 'border-not-required',
    densitySGError: '',
    densitySG:'border-disabled',
    maxDensityError:  '',
    minDensityError:  '',
    normalViscosityError: '',
    gasPressureError: '',
    gasTemperatureError:'',
    minViscosityError: '',
    maxViscosityError: '',
    kFlowConsistencyIndexError: '',
    nFlowBehaviorError: '',
    specificGravityError: '',
    atmosphericPressureError: '',
    selectedTechnology: '',
}

export const FluidPropertyReducer = (state,action) => {
  switch (action.type) { 
    case 'selectedTechnology':
        return { ...state, selectedTechnology: action.payload };  
    case 'specificGravityError':
        return { ...state, specificGravityError: action.payload };   
    case 'atmosphericPressureError':
        return { ...state, atmosphericPressureError: action.payload };    
    case 'kFlowConsistencyIndexError':
        return { ...state, kFlowConsistencyIndexError: action.payload };    
    case 'nFlowBehaviorError':
        return { ...state, nFlowBehaviorError: action.payload };    
    case 'normalViscosityError':
        return { ...state, normalViscosityError: action.payload };   
    case 'minViscosityError':
        return { ...state, minViscosityError: action.payload };
    case 'maxViscosityError':
        return { ...state, maxViscosityError: action.payload }; 
    case 'normalDensityError':
        return { ...state, normalDensityError: action.payload };     
    case 'minDensityError':
        return { ...state, minDensityError: action.payload };     
    case 'maxDensityError':
        return { ...state, maxDensityError: action.payload };        
    case 'densityUnit': 
        return { ...state, densityUnit: action.payload };
    case 'maxPressureDropDropdownUnits': 
        return { ...state, maxPressureDropDropdownUnits: action.payload };
    case 'maxPressureDropdownUnits': 
        return { ...state, maxPressureDropdownUnits: action.payload };
    case 'viscosityUnit':
        return { ...state, viscosityUnit: action.payload };
    case 'vaporPressureUnit':
        return { ...state, vaporPressureUnit: action.payload };
    case 'atmosphericUnit':
        return { ...state, atmosphericUnit: action.payload };
    case 'gasOnlyPressureUnit':
        return { ...state, gasOnlyPressureUnit: action.payload };
    case 'gasOnlyPressure':
        return { ...state, gasOnlyPressure: action.payload };
    case 'gasOnlyTemperatureUnit':
        return { ...state, gasOnlyTemperatureUnit: action.payload };
    case 'gasOnlyTemperature':
        return { ...state, gasOnlyTemperature: action.payload };
    case 'gasOnlyDensityUnit':
        return { ...state, gasOnlyDensityUnit: action.payload };
    case 'sonicVelocityUnit':
        return { ...state, sonicVelocityUnit: action.payload };
    case 'minDensityValue':
        return { ...state, minDensityValue: action.payload };
    case 'normalDensityValue':
        return { ...state, normalDensityValue: action.payload };
    case 'maxDensityValue':
        return { ...state, maxDensityValue: action.payload };
    case 'minViscosityValue':
        return { ...state, minViscosityValue: action.payload };
    case 'normalViscosityValue':
        return { ...state, normalViscosityValue: action.payload };
    case 'maxViscosityValue':
        return { ...state, maxViscosityValue: action.payload };
    case 'minVaporValue':
        return { ...state, minVaporValue: action.payload };
    case 'densityDropdown':
        return { ...state, densityDropdown: action.payload };
    case 'viscosityDropdown':
        return { ...state, viscosityDropdown: action.payload };
    case 'vaporPressureDropdown':
        return { ...state, vaporPressureDropdown: action.payload };
    case 'maxPressureDropDropdown':
        return { ...state, maxPressureDropDropdown: action.payload };
    case 'densityDropdownUnits':
        return { ...state, densityDropdownUnits: action.payload };
    case 'gasDensityDropdownUnits':
        return { ...state, gasDensityDropdownUnits: action.payload };
    case 'gasDensitySGDropdownUnits':
        return { ...state, gasDensitySGDropdownUnits: action.payload };
    case 'viscosityDropdownUnits':
        return { ...state, viscosityDropdownUnits: action.payload };
    case 'vaporPressureDropdownUnits':
        return { ...state, vaporPressureDropdownUnits: action.payload };
    case 'maxPressureDropdownUnits':
        return { ...state, maxPressureDropdownUnits: action.payload };
    case 'densityDropdownKey':
        return { ...state, densityDropdownKey: action.payload };
    case 'gasDensityDropdownKey':
        return { ...state, gasDensityDropdownKey: action.payload };
    case 'gasDensitySGDropdownKey':
        return { ...state, gasDensitySGDropdownKey: action.payload };
    case 'SonicVelocityDropdownKey':
        return { ...state, SonicVelocityDropdownKey: action.payload };
    case 'prevDensityDropdownKey':
        return { ...state, prevDensityDropdownKey: action.payload };
    case 'prevGasDensityDropdownKey':
        return { ...state, prevGasDensityDropdownKey: action.payload };
    case 'prevGasDensitySGDropdownKey':
        return { ...state, prevGasDensitySGDropdownKey: action.payload };
    case 'prevSonicVelocityDropdownKey':
        return { ...state, prevSonicVelocityDropdownKey: action.payload };
    case 'viscosityDropdownKey':
        return { ...state, viscosityDropdownKey: action.payload };
    case 'pressureDropdownKey':
        return { ...state, pressureDropdownKey: action.payload };
    case 'prevViscosityDropdownKey':
        return { ...state, prevViscosityDropdownKey: action.payload };
    case 'prevPressureDropdownKey':
        return { ...state, prevPressureDropdownKey: action.payload };
    case 'vaporPressureDropdownKey':
        return { ...state, vaporPressureDropdownKey: action.payload };
    case 'prevTemperatureDropdownKey':
        return { ...state, prevTemperatureDropdownKey: action.payload };
    case 'temperatureDropdownKey':
        return { ...state, temperatureDropdownKey: action.payload };
    case 'prevVaporPressureDropdownKey':
        return { ...state, prevVaporPressureDropdownKey: action.payload };
    case 'decimalPlaces':
        return { ...state, decimalPlaces: action.payload };
    case 'uomDensityKey':
        return { ...state, uomDensityKey: action.payload };
    case 'uomPressureKey':
        return { ...state, uomPressureKey: action.payload };
    case 'uomGasDensityKey':
        return { ...state, uomGasDensityKey: action.payload };
    case 'uomGasDensitySGKey':
        return { ...state, uomGasDensitySGKey: action.payload };
    case 'uomSonicVelocityKey':
        return { ...state, uomSonicVelocityKey: action.payload };
    case 'uomViscosityKey':
        return { ...state, uomViscosityKey: action.payload };
    case 'uomVaporPressureKey':
        return { ...state, uomVaporPressureKey: action.payload };
    case 'uomTemperatureKey':
        return { ...state, uomTemperatureKey: action.payload };
    case 'oppCondition':
        return { ...state, oppCondition: action.payload };
    case 'uomFlag':
        return { ...state, uomFlag: action.payload };
    case 'uomObjectArray':
        return { ...state, uomObjectArray: action.payload };
    case 'uomAutoConversion':
        return { ...state, uomAutoConversion: action.payload };
    case 'showDensityRow':
        return {...state, showDensityRow: action.payload};
    case 'showVaporPressureRow':
        return { ...state, showVaporPressureRow: action.payload };
    case 'showMaxFlowAccuracyRow':
        return { ...state, showMaxFlowAccuracyRow: action.payload };
    case 'showPressureAtmosphericRow':
        return { ...state, showPressureAtmosphericRow: action.payload };
    case 'showMinAccuracyRow':
        return { ...state, showMinAccuracyRow: action.payload };
    case 'showMaxPressureDropRow':
        return { ...state, showMaxPressureDropRow: action.payload };
    case 'showViscosityRow':
        return { ...state, showViscosityRow: action.payload };
    case 'showSonicVelocityRow':
        return { ...state, showSonicVelocityRow: action.payload };
    case 'showNonNewtonianCheckbox':
        return { ...state, showNonNewtonianCheckbox: action.payload };
    case 'showCompressibility':
        return { ...state, showCompressibility: action.payload };
    case 'showBaseCompressibilityRow':
        return { ...state, showBaseCompressibilityRow: action.payload };
    case 'densityHeadingDisabled':
        return { ...state, densityHeadingDisabled: action.payload };
    case 'baseGasDensityHeadingDisabled':
        return { ...state, baseGasDensityHeadingDisabled: action.payload };    
    case 'densityMin':
        return { ...state, densityMin: action.payload };
    case 'densityNormal':
        return { ...state, densityNormal: action.payload };
    case 'densityMax':
        return { ...state, densityMax: action.payload };
    case 'viscosityMin':
        return { ...state, viscosityMin: action.payload };
    case 'viscosityNormal':
        return { ...state, viscosityNormal: action.payload };
    case 'viscosityMax':
        return { ...state, viscosityMax: action.payload };
    case 'vaporPressureMin':
        return { ...state, vaporPressureMin: action.payload };
    case 'flowAccMax':
        return { ...state, flowAccMax: action.payload };
    case 'pressureDropMax':
        return { ...state, pressureDropMax: action.payload };
    case 'atmosphericPressure':
        return { ...state, atmosphericPressure: action.payload };
    case 'velocitySonic':
        return { ...state, velocitySonic: action.payload };
    case 'kFlowConsistencyIndex':
        return { ...state, kFlowConsistencyIndex: action.payload };
    case 'nFlowBehavior':
        return { ...state, nFlowBehavior: action.payload };
    case 'gasCompressibility':
        return { ...state, gasCompressibility: action.payload };
    case 'baseCompressibility':
        return { ...state, baseCompressibility: action.payload };
    case 'specificGravity':
        return { ...state, specificGravity: action.payload };
    case 'molecularWeight':
        return { ...state, molecularWeight: action.payload };
    case 'molecularWeightError':
        return { ...state, molecularWeightError: action.payload }; 
    case 'gasPressure':
        return { ...state, gasPressure: action.payload };
    case 'gasPressureError':
        return { ...state, gasPressureError: action.payload };
    case 'gasTemperature':
        return { ...state, gasTemperature: action.payload };
    case 'gasTemperatureError':
        return { ...state, gasTemperatureError: action.payload };
    case 'gasDensity':
        return { ...state, gasDensity: action.payload };
    case 'gasDensityError':
        return { ...state, gasDensityError: action.payload };
    case 'toggleNonNewtonLiquid': 
        return { ...state, toggleNonNewtonLiquid: action.payload };
    case 'gasCustomCompressibility':
        return { ...state, gasCustomCompressibility: action.payload };
    case 'gasCustomCompressibilityError':
        return { ...state, gasCustomCompressibilityError: action.payload }; 
    case "resetFluidProperty": 
        return initialFluidPropertyState; 
    default: 
        throw new Error("Unexpected action", );
  }
}; 