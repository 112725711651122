import React, { useEffect, useState, useMemo } from 'react';
import SizingResultsTableStructure from './SizingResultsTableStructure';
import axios from 'axios';
import {
  SEARCH_SIZING_RESULTS,
  DEFAULT_DECIMAL,
} from '../../constants/constants';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';
import CustomTranslationText from '../../components/CustomTranslation/CustomTranslationText';
import { connect } from 'react-redux';

import {
  selectedTechnology,
  requestSizingResults,
  sizingResultsData,
  errorMessage,
  normalFlowRate,
  normalLinePressure,
  normalTemperature,
  normalAmbient,
  lineSize,
  normalViscosity,
  displaySizingResults,
  pipeId,
  tubeVelocityUnit,
  gasDensity,
  unitsGasDensity,
  showMoreRatesData,
  wasClicked,
  getSizingData,
  sizingApplication,
  queryParams,
  selectedRowData,
  compareDisableButton,
  productFamilyName,
  configWarningMsg,
  sonicVelocityWarningMsg,
  sonicVelocityEntered,
  ambTempWarningMsg,
  highTempWarningMsg,
  highTemperatureErrorMsg,
  resizingDiscardFlag, 
  densityAccuracy
} from '../../redux/actions';
import {
  Col,
  Row
} from 'react-bootstrap';
import './CollapsibleCSS.scss';
import variables from '../../styles/variables.scss';
import { CustomCheckbox } from '../../components/CustomCheckbox/CustomCheckbox';
import SizingResultsHeadingFields from './SizingResultsHeadingFields';
import SizingResultsNavButtons from './SizingResultsNavButtons';
import CustomLoader from '../../components/CustomLoader/CustomLoader';
import ErrorModal from '../../components/CustomModals/ErrorModal';
import { decimalPlaceNumeric } from "../../UtilityFuntions/decimalFactor";
import SizingResultsCorrosionGuide from './SizingResultsCorrosionGuide';
import SizingResultsProductFamilyComparisons from './SizingResultsProductFamilyComparisons';

import { Collapse, Card, } from "reactstrap";
import { faChevronDown,faChevronUp, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function usePrevious(value) {
  const ref = React.useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const SizingResultsTableData = props => {
  const [sizingResults, setSizingResults] = useState('');
  const [sizingResultsTable, setSizingResultsTable] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const [decimalPlaces, setDecimalPlaces] = useState(DEFAULT_DECIMAL);
  const [missingValues, setMissingValue] = useState([]);
  const [checkedFilter, setCheckedFilter] = useState([]);
  const [massValues, setMassValues] = useState([]);
  const [tableAllData, setTableAllData] = useState('');
  const [accordionChild, setAccordionChild] = useState(0);
  const [accordionMain, setAccordionMain] = useState(1);
  const [filterLineSize, setFilterLineSize] = useState([]);
  const [tubeVelocityUnits, setTubeVelocityUnits] = useState();
  const [densityAccuracyUnits, setDensityAccuracyUnits] = useState();
  const [exclusionFlag, setExclusionFlag] = useState(false)
  const [family, setFamily] = useState('')
  const [count,setCount]=useState(0);
  const [productFamilyFlag, setProductFamilyFlag] = useState(true);
  const [pressureUnit, setPressureUnit] = useState('')
  const [flowRateUnit, setFlowRateUnit] = useState({})
  const [gasFlowRateUnit, setGasFlowRateUnit] = useState({})

    const handleErrorClose = () => {
    setShowLoader(false);
    props.errorMessage(false);
    // window.location.reload(false);
  }
  
  const handleErrorShow = () => props.error_message;

  useEffect(() => {
    setDecimalPlaces(
      props.saved_preferences.all_other_decimal_factor
        ? decimalPlaceNumeric(props.saved_preferences.all_other_decimal_factor)
        :  decimalPlaceNumeric(props.decimal_factor_pref)
    );
  }, []);

  useEffect(() => {
    if(!props.is_applied){
      setPressureUnit(props.units_line_pressure)
      setFlowRateUnit(props.units_flow_rate)
      setGasFlowRateUnit(props.units_gas_flow_rate)
    }
  }, [props.is_applied]);


  const { max_temperature, full_flow_rate, full_temperature, min_line_pressure, max_line_pressure, full_line_pressure,
    normal_line_pressure, request_sizing_results, min_flow_rate, max_flow_rate, normal_flow_rate, units_flow_rate, 
    min_temperature, normal_temperature, units_temperature, fluid_state, normal_density, units_density, units_line_pressure,
    normal_viscosity, units_viscosity, search_parameters,  select_technology, language_info, vortex_info, requestSizingResults, 
    errorMessage, error_message, sonic_velocity, sonic_velocity_map,  units_sonic_velocity, line_size,calculate_density_variation, non_newtonian_liquid, 
    max_density, min_density, min_vapor, units_vapor, flanged, wafer, reducer, dual,quad, process_line_size_units, max_flow_acc,
    density_specific_gravity, compressibility, base_compressibility, molecular_weight, specific_gravity,
    all_other_decimal, flow_rate_decimal, temperature_decimal, line_pressure_decimal, product_family_name, product_family 
  } = props;

  const prevTechnology = usePrevious(select_technology);

  useEffect(() => {
    if (request_sizing_results && props.was_clicked) {
      ClearAll();
      setTableAllData([]);
      setSizingResults([]);
      setSizingResultsTable([]);

      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl);
      let query_params={...props.query_params}
      if(params.get('fromPage')==='PDP'||params.get('fromPage')==='tools')
      {
        query_params.requisition_id=document.getElementById('requisitionListIdTag')?document.getElementById('requisitionListIdTag').value:'';
        query_params.order_tag=document.getElementById('orderItemIdTag')?document.getElementById('orderItemIdTag').value:'';
        query_params.description=document.getElementById('wsNameTag')?document.getElementById('wsNameTag').value:''
        query_params.redirect_url= document.getElementById('redirectTReqTagUrl')?document.getElementById('redirectTReqTagUrl').value:''
        query_params.tag_name=document.getElementById('wc_tagName')?document.getElementById('wc_tagName').value:''
      }else{
        query_params.requisition_id=params.get('requisitionListId')?params.get('requisitionListId'):
        document.getElementById('requisitionListIdTag')?document.getElementById('requisitionListIdTag').value:'';
        query_params.order_tag=params.get('orderItemId')?params.get('orderItemId'):
        document.getElementById('orderItemIdTag')?document.getElementById('orderItemIdTag').value:'';
        query_params.description=params.get('description')?params.get('description'):
        document.getElementById('wsNameTag')?document.getElementById('wsNameTag').value:''
        query_params.redirect_url=params.get('redirecturl')?params.get('redirecturl'):params.get('redirectUrl')?params.get('redirectUrl'):
        document.getElementById('redirectTReqTagUrl')?document.getElementById('redirectTReqTagUrl').value:''
        query_params.tag_name=params.get('tagName')?params.get('tagName'):
        document.getElementById('wc_tagName')?document.getElementById('wc_tagName').value:''
      }
      props.queryParams(query_params)

      

      const data = {};
      max_flow_rate !== "" && max_flow_rate !== null ? props.tank == true ? data.flow_rate_max = "" : data.flow_rate_max = parseFloat(max_flow_rate) : data.flow_rate_max = "";
      min_flow_rate !== "" && min_flow_rate !== null ? props.tank == true ? data.flow_rate_min = "" : data.flow_rate_min = parseFloat(min_flow_rate) : data.flow_rate_min = "";
      normal_flow_rate !== "" && normal_flow_rate !== null ? props.tank == true ? data.flow_rate_norm = "" : data.flow_rate_norm = parseFloat(normal_flow_rate) : data.flow_rate_norm = "";

      data.flow_rate_fsd = full_flow_rate !== ""&&full_flow_rate !== null ? parseFloat(full_flow_rate) : "";
      data.flow_rate_units =  props.fluid_state.toLowerCase()==="gas"?props.units_gas_flow_rate.key:props.units_flow_rate.key;
      data.temperature_min = min_temperature !== ""&&min_temperature !== null ? parseFloat(min_temperature) : "";
      data.temperature_norm = normal_temperature !== ""&&normal_temperature !== null ? parseFloat(normal_temperature) : "";
      data.temperature_max = max_temperature !== "" &&max_temperature !== null? parseFloat(max_temperature) : "";
      data.temperature_fsd = full_temperature !== "" &&full_temperature !== null? parseFloat(full_temperature) : "";
      data.line_pressure_min = min_line_pressure !== ""&&min_line_pressure !== null ? parseFloat(min_line_pressure) : "";
      data.line_pressure_norm = normal_line_pressure !== ""&&normal_line_pressure !== null ? parseFloat(normal_line_pressure) : "";
      data.line_pressure_max = max_line_pressure !== ""&&max_line_pressure !== null ? parseFloat(max_line_pressure) : "";
      data.line_pressure_fsd = full_line_pressure !== ""&& full_line_pressure !== null ? parseFloat(full_line_pressure) : "";
      data.temperature_units = units_temperature !== "" ? units_temperature.key : "";
      data.line_pressure_units = units_line_pressure !== "" ? units_line_pressure.key : "";
      data.amb_temperature_min = props.min_ambient !=="" && props.min_ambient !==null ? parseFloat(props.min_ambient):'' 
      data.amb_temperature_norm = props.normal_ambient !=="" && props.normal_ambient !==null ? parseFloat(props.normal_ambient):''
      data.amb_temperature_max = props.max_ambient !=="" && props.max_ambient !==null ? parseFloat(props.max_ambient):''
      data.amb_temperature_fsd = props.full_ambient !=="" && props.full_ambient !==null ? parseFloat(props.full_ambient):''
      data.amb_temperature_units = props.units_ambient !== "" ? props.units_ambient.key : "";
      data.language = props.query_params.language?props.query_params.language:language_info.language;
      data.ref_density_units = props.units_gas_density !== "" && props.units_gas_density !== undefined? props.units_gas_density.key : "";
      data.ref_density = props.gas_density !== ""? props.gas_density : "";
      data.fluid_state = fluid_state !== "" ? fluid_state.toUpperCase() : "";
      data.product_family = props.product_family !== "" && props.product_family !== undefined ? props.product_family:''
      data.density_norm = normal_density !== "" && normal_density !== undefined ? parseFloat(normal_density)
      : density_specific_gravity !=="" && density_specific_gravity !== undefined? parseFloat(density_specific_gravity):'' ;
      data.density_units = fluid_state.toLowerCase() ==='gas' && props.fluid_source.toLowerCase()==='custom'  ? 
      props.units_density_heading ==='Density' ?   units_density !== "" && units_density !== undefined ? units_density.key : "LB/GAL(US)":
      props.units_gas_density_sg !=="" && props.units_gas_density_sg !== undefined?props.units_gas_density_sg.key:'': 
      units_density !== "" && units_density !== undefined ? units_density.key : "LB/GAL(US)";
      data.max_pressure_drop = props.max_pressure_drop !== '' && props.max_pressure_drop !== undefined? parseFloat(props.max_pressure_drop) : '';
      data.technology = select_technology.toLowerCase();
      if (select_technology.toLowerCase() === 'coriolis') {
        data.is_chk_no_filter = props.display_all_sensors_no_filters.toString();
        data.hygienic=props.hygienic.toString()
        data.wetted_material = props.wetted_material? props.wetted_material:1;
        data.wetted_material_label= props.wetted_material_label?props.wetted_material_label :'';
        data.wetted_material_value= props.wetted_material_value?props.wetted_material_value :'';
        data.vapor_pressure_units = units_vapor !== "" && units_vapor !== undefined ? units_vapor.key : "PSIA";
        data.max_flow_accuracy = max_flow_acc !== "" && max_flow_acc !== undefined ? parseFloat(max_flow_acc) : '';
        data.density_min = min_density !== "" && min_density !== undefined ? parseFloat(min_density) : "";
        data.density_max = max_density !== "" && max_density !== undefined ? parseFloat(max_density) : "";
        data.calculate_density_variation = calculate_density_variation.toString();
        data.is_chk_non_newton_liquid = non_newtonian_liquid !== "" ? non_newtonian_liquid : "";
        data.k_flow_consistency_text = props.k_flow_consistency_index !== "" ? props.k_flow_consistency_index : "";
        data.n_flow_behavior_text = props.n_flow_behavior !== "" ? props.n_flow_behavior : "";
        data.viscosity = normal_viscosity !== "" || normal_viscosity !== undefined ? parseFloat(normal_viscosity) : "";
        data.viscosity_units = units_viscosity !== "" && units_viscosity !== undefined ? units_viscosity.key : "CP";
        data.converted_value = "";
        data.sonic_velocity = sonic_velocity !== ""? parseFloat(sonic_velocity) : "";
        data.sonic_velocity_map = (Object.keys(sonic_velocity_map).length > 0 && sonic_velocity !== "") ? sonic_velocity_map : {};
        data.sonic_velocity_units = units_sonic_velocity.key !== "" && units_sonic_velocity.key !== undefined? units_sonic_velocity.key : "";
        data.vapour_pressure = min_vapor !== "" || min_vapor !== undefined ? parseFloat(min_vapor) : "";;
        data.compressibility = compressibility !=""|| compressibility != undefined? parseFloat(compressibility):"";
        data.base_compressibility = base_compressibility !="" || base_compressibility != undefined? parseFloat(base_compressibility):"";
        data.molecular_weight = molecular_weight !="" || molecular_weight != undefined? parseFloat(molecular_weight):"";
        data.specific_gravity = specific_gravity !="" || specific_gravity != undefined? parseFloat(specific_gravity):"";
        data.viscosity_min = props.min_viscosity !== ''?parseFloat(props.min_viscosity):'';
        data.viscosity_max = props.max_viscosity !==''? parseFloat(props.max_viscosity):'';
      } else if (select_technology.toLowerCase() === 'vortex') {
        if (fluid_state.toLowerCase() === 'steam') {
          data.steam_pressure_temp = props.steam_pressure_temp==='Based on Saturated Temperature'?"XXSPECIFY_MMI_MC_RDIO_STEMP"
          :props.steam_pressure_temp==='Based on Saturated Pressure'?"XXSPECIFY_MMI_MC_RDIO_SPRES":
          "XXSPECIFY_MMI_MC_RDIO_SUPERHEA"
      }
        data.density_min = min_density !== "" || min_density !== undefined ? parseFloat(min_density) : "";
        data.density_max = max_density !== "" || max_density !== undefined ? parseFloat(max_density) : "";
        data.is_chk_non_newton_liquid = non_newtonian_liquid !== "" ? non_newtonian_liquid : "";
        data.is_chk_no_filter = "false";
        data.k_flow_consistency_text = props.k_flow_consistency_index !== "" ? props.k_flow_consistency_index : "";
        data.n_flow_behavior_text = props.n_flow_behavior !== "" ? props.n_flow_behavior : "";
        data.temperature_fsd = full_temperature !== "" ? parseFloat(full_temperature) : "";
        data.viscosity = normal_viscosity !== "" || normal_viscosity !== undefined ? parseFloat(normal_viscosity) : "";
        data.viscosity_units = units_viscosity !== "" && units_viscosity !== undefined  ? units_viscosity.key : "CP";
        data.trigger_factor = 1;
        data.schedule = props.schedule;
        data.flanged = flanged;
        data.wafer = wafer;
        data.reducer = reducer;
        data.dual = dual; 
        data.quad = quad; 
        data.is_chk_high_pressure = props.pressure
        data.vapour_pressure = min_vapor !== "" || min_vapor !== undefined ? parseFloat(min_vapor) : "";;
        data.vapour_pressure_units = units_vapor !== "" && units_vapor !== undefined  ? units_vapor.key : "";
        data.converted_value = "";
        data.pipe_id = props.pipe_id !== "" ? props.pipe_id : "";
        data.process_line_size = props.process_line_size !== ""? props.process_line_size : ""; 
        data.compressibility = compressibility !=""|| compressibility != undefined? parseFloat(compressibility):"";
        data.base_compressibility = base_compressibility !="" || base_compressibility != undefined? parseFloat(base_compressibility):"";
        data.molecular_weight = molecular_weight !="" || molecular_weight != undefined? parseFloat(molecular_weight):"";
        data.specific_gravity = specific_gravity !="" || specific_gravity != undefined? parseFloat(specific_gravity):"";
        data.fluid_source =props.fluid_source != undefined? props.fluid_source.toLowerCase():'' 
        data.viscosity_min = props.min_viscosity !== ''?parseFloat(props.min_viscosity):'';
      data.viscosity_max = props.max_viscosity !==''? parseFloat(props.max_viscosity):'';  
      } else if (select_technology.toLowerCase() === 'magnetic') {
        data.is_chk_no_filter = props.display_all_sensors_no_filters.toString();
        data.radio_mag_meter = "false";
        data.radio_sensor_model_type = "false";
        data.fluid_service = "NORMAL";
        data.mag_high_pressure = "true";
        data.mag_accuracy = "3_0";
        data.converted_value = "";
        data.hygienic=props.hygienic_application;
         data.high_process_noise_slurry=props.high_process_noise_slurry;
        data.fluid_service = props.fluid_service_key;
        data.process_line_size = props.process_line_size !== ""? props.process_line_size : "";
        data.specific_gravity = specific_gravity !="" || specific_gravity != undefined? parseFloat(specific_gravity):"";
      } else if (select_technology.toLowerCase() === 'density') {
        data.measurement_type = "density";
        data.calculate_density_variation = calculate_density_variation.toString() !== "" ? calculate_density_variation.toString() : "";
        data.is_chk_non_newton_liquid = non_newtonian_liquid !== "" ? non_newtonian_liquid : "";
        data.line_size = line_size !==""? line_size.replace(/\s/g, ''):""; 
        data.line_pressure_units = units_line_pressure !== "" && units_line_pressure !== undefined ? units_line_pressure.key : "";
        data.k_flow_consistency_text = props.k_flow_consistency_index !== "" ? props.k_flow_consistency_index : "";
        data.n_flow_behavior_text = props.n_flow_behavior !== "props.n_flow_behavior" ? props.n_flow_behavior : "";
        data.viscosity = normal_viscosity !== "" || normal_viscosity !== undefined ? parseFloat(normal_viscosity) : "CP";
        data.viscosity_units = units_viscosity !== "" && units_viscosity !== undefined ? units_viscosity.key : "";
        data.is_chk_no_filter = props.display_all_sensors_no_filters.toString();
        data.sonic_velocity = sonic_velocity !== "" && sonic_velocity !== undefined ? parseFloat(sonic_velocity) : "";
        data.sonic_velocity_map = (Object.keys(sonic_velocity_map).length > 0 && sonic_velocity !== "" ) ? sonic_velocity_map : {};
        data.sonic_velocity_units = units_sonic_velocity.key !== ""? units_sonic_velocity.key : "";
        data.calculate_density_variation = calculate_density_variation.toString();
        data.tank=props.tank;
        data.inline=props.in_line;
        data.slip_stream= props.flow_through_chamber_slipstream;
        data.hygienic=props.hygienic
        data.compressibility = compressibility !=""|| compressibility != undefined? parseFloat(compressibility):"";
        data.molecular_weight = molecular_weight !="" || molecular_weight != undefined? parseFloat(molecular_weight):"";
        data.specific_gravity = specific_gravity !="" || specific_gravity != undefined? parseFloat(specific_gravity):"";
        data.viscosity_min = props.min_viscosity !== ''?parseFloat(props.min_viscosity):'';
        data.viscosity_max = props.max_viscosity !==''? parseFloat(props.max_viscosity):'';
      }else if (select_technology.toLowerCase() === 'viscosity'){
        data.measurement_type = "viscosity";
        data.is_chk_non_newton_liquid = non_newtonian_liquid !== "" ? non_newtonian_liquid : "";
        data.k_flow_consistency_text = props.k_flow_consistency_index !== "" && props.k_flow_consistency_index !== undefined ? props.k_flow_consistency_index : "";
        data.n_flow_behavior_text = props.n_flow_behavior !== "props.n_flow_behavior" ? props.n_flow_behavior : "";
        data.viscosity = normal_viscosity !== "" || normal_viscosity !== undefined ? parseFloat(normal_viscosity) : "";
        data.viscosity_units = units_viscosity !== "" && units_viscosity !== undefined? units_viscosity.key : "";
        data.is_chk_no_filter = props.display_all_sensors_no_filters.toString();
        data.tank=props.tank;
        data.inline=props.in_line;
        data.line_size = line_size !==""? line_size.replace(/\s/g, ''):""; 
        data.slip_stream= props.flow_through_chamber_slipstream;
        data.hygienic=props.hygienic;
        data.specific_gravity = specific_gravity !="" || specific_gravity != undefined? parseFloat(specific_gravity):"";
        data.viscosity_min = props.min_viscosity !== ''?parseFloat(props.min_viscosity):'';
        data.viscosity_max = props.max_viscosity !==''? parseFloat(props.max_viscosity):'';
      }
      if (Object.keys(data).length > 0 &&  ((props.fluid_state.toLowerCase()==="liquid" ||props.fluid_state.toLowerCase()==="slurry") && 
      props.fluid_source.toLowerCase()==="custom" && (props.units_density_heading === 'Specific Gravity' ||
      (props.get_sizing_data.length>0 && props.get_sizing_data[0].densty_col_disp !==undefined? props.get_sizing_data[0].densty_col_disp === 'Specific Gravity':false))  ? true: data.density_norm )) {

        const fetchData = async () => {
          await axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}${SEARCH_SIZING_RESULTS}`,
            headers: {
              'Content-Type': 'application/json'
            },
            xsrfHeaderName: 'X-CSRFToken',
            xsrfCookieName: 'csrftoken',
            data: data
          })
            .then(function (response) {
              if(response.data.STATUS===400){
                errorMessage(true);
                setSizingResults([]);
                props.displaySizingResults(false);
              }else{
                setSizingResults(response.data.data);
                if (select_technology.toLowerCase() === 'coriolis') {
                      props.tubeVelocityUnit(response.data.data[0])
                }
              }
              
            })
            .catch(function (error) {
              if (!error_message) {
                errorMessage(true);
              }
              console.log('error', error);
            });
        }
        fetchData();
        props.requestSizingResults(false);        
      }
    }
  }, [request_sizing_results, props.was_clicked,props.get_sizing_data, props.normal_density]);
  
  useEffect(() => {
    if(!Object.keys(props.get_sizing_data).length>0){
      setSizingResults([])
    }
   
  }, [props.saved_preferences]);

  useEffect(() => {
    let results;
    let filtered
    if (((typeof sizingResults) !== 'string' && Object.keys(sizingResults).length>0)) {
      results = setResults();
      if(search_parameters.select_technology.toLowerCase()==='coriolis'){ 
        let count
        if(exclusionFlag){
          count=results.filter((col,i)=>
                   col.first_page===true
          );
        }else{
          count=results.filter((col,i)=>
                   col.first_page===false
          );
        }

        if(count.length ===0){
          count=results
          setCount(count.length)
        }else{
          setCount(count.length)
        }

        let data=results.filter((col,i)=>
                    i>=count.length&&i<10
        );

        const existsInResults = (item) => {
          return results.some(result => result.modelName === item.modelName);
        };

        const uniqueData = data.filter(item => !existsInResults(item));
        results.splice(10, 0, ...uniqueData);
        }
        
      if(search_parameters.select_technology.toLowerCase()==='density'){ 
        let count
        if(exclusionFlag){
          count=results.filter((col,i)=>
                   col.first_page===true
          );
        }else{
          if(props.fluid_state.toLowerCase()!=='gas'){
            count=results.filter((col,i)=>
                   col.first_page===false
          );
          }else{
            count=results.filter((col,i)=>
                   col.first_page===true
          );
          }
          
        }
          if(count.length ===0){
            count=results
            setCount(count.length)
          }else{
            setCount(count.length)
          }


          let data=results.filter((col,i)=>
                      i>=count.length&&i<10
          );

          const existsInResults = (item) => {
            return results.some(result => result.modelName === item.modelName);
          };
  
          const uniqueData = data.filter(item => !existsInResults(item));
          results.splice(10, 0, ...uniqueData);    
      }
    } else {
      results = [];
    }

    if (Object.keys(props.get_sizing_data).length > 0 && (typeof sizingResults) !== 'string' && Object.keys(sizingResults).length > 0) {
      let data = props.get_sizing_data[0]
      let get_data = data.parent_model ?
        ((data.parent_model.includes('FT_') || data.parent_model.includes('DV_')) ? (data.parent_model.slice(3).toString().trim())
          : data.parent_model.toString()) : data.model_name.includes('FT_') ? (data.model_name.slice(3).toString().trim()) : data.model_name.toString();
      let result_data1 = results.filter((data) => (data.modelName === get_data))
      let prev = results ? results.findIndex((p) => p.modelName === get_data) : undefined
      if (result_data1 !== undefined && result_data1.length > 0 && prev !== undefined) {
        results = results.filter((data) => (data.modelName !== get_data));
        results.unshift(result_data1[0])
      }
      setTimeout(() => {
        props.resizingDiscardFlag(false)
      }, 3000);
    } 
    if (sizingResults[0] != undefined) {
      let acc = sizingResults[0].density_accuracy_units === undefined ? '' : sizingResults[0].density_accuracy_units ;
      let tuv = sizingResults[0].tube_velocity_units === undefined ? '' : sizingResults[0].tube_velocity_units;
      setDensityAccuracyUnits(acc);
      setTubeVelocityUnits(tuv);
      if(sizingResults[0].sonic_velocity_warning_msg){
        props.sonicVelocityWarningMsg(true)
        props.sonicVelocityEntered(sizingResults[0].sonic_vel_entered)
      }
      if(sizingResults[0].sonic_vel_entered){
      }
      if(sizingResults[0]?.amb_temp_warning_msg){
        props.ambTempWarningMsg(true)
      }
    }
     setTableAllData(results);

  }, [sizingResults,props.get_sizing_data, exclusionFlag]);

useEffect(() => {
  if(checkedFilter.length >0){
    setExclusionFlag(false)

  }else {
    setExclusionFlag(true)
  }
}, [checkedFilter])

  const setResults = () => {
    let techToCase = search_parameters.select_technology.toLowerCase();
    let results;
    switch (techToCase) {
      case 'coriolis':
        results = sizingResults.map(item => newRowCoriolis(item));
        return results;
      case 'magnetic':
        results =  sizingResults.map(item => newRowMagnetic(item));
        return results;
      case 'vortex':
        results = sizingResults.map(item => newRowVortex(item));
        return results;
      case 'density': 
        results = sizingResults.map(item => newRowDensity(item));
        return results;
      case 'viscosity':
        results = sizingResults.map(item => newRowViscosity(item));
        return results;
      default: 
        results = [];
        return results;
    }
  };

  const unitsLinePressureDropAbsolute = () => {
    const value = pressureUnit.key ? pressureUnit.key : pressureUnit;
    if (value) {
      if (value.endsWith('-G') || value.endsWith('-A')) {
        return (value).substring(0, value.length - 2);
      } else if (value.endsWith('G') || value.endsWith('A')) {
        return (value).substring(0, value.length - 1);
      }
    }
  }


const isCryo = ()=>{
  let celsius_array = []
  let result;
  let fahrenheit = props.temp_row_f??[].filter((el)=>{
    return el !==null
  });
  
  
  if(fahrenheit){
    celsius_array = fahrenheit.map((value)=>((value-32)* 5/9))
      result = celsius_array.some((el)=>{
      return el< -100
    })
    
  } 
  return !result

}

  const tubeVelocity = () => {
    let tubeVel = '';
    if (props.units_data) {
      for (const [key, value] of Object.entries(props.units_data.unitMapping)) {
        if(props.fluid_state.toLowerCase()!=='gas'){
          if (key === flowRateUnit.key) {
            if (value === 'METRIC SYSTEM') {
              tubeVel = 'M/SEC'
            } else if (value === 'ENGLISH SYSTEM') {
              tubeVel = 'FT/SEC'
            }
          }
        }else{
          if (key === gasFlowRateUnit.key) {
            if (value === 'METRIC SYSTEM') {
              tubeVel = 'M/SEC'
            } else if (value === 'ENGLISH SYSTEM') {
              tubeVel = 'FT/SEC'
            }
          }
        }
        
      }
    }
    return tubeVel;
  }
  
  const newRowCoriolis = row => {
    return {
      modelName: row.product?row.product:'',
      material:row.material?row.material:'',
      first_page:row.first_page,
      massflowaccuracy_liquid:row.massflowaccuracy_liquid?row.massflowaccuracy_liquid:'',
      longDescription: row.long_description ? row.long_description : '--',
      massMin: props.fluid_state.toLowerCase()==='gas' ? row.dmf_accuracy && row.dmf_accuracy[0] ? parseFloat(row.dmf_accuracy[0]).toFixed(all_other_decimal):'--': 
      (row.dmf_accuracy && row.dmf_accuracy[0] ? row.flow_accuracy_radio_button && row.flow_accuracy_radio_button.length !==undefined?  row.flow_accuracy_radio_button.includes(row.dmf_accuracy[0])?row.dmf_accuracy[0]:parseFloat(row.dmf_accuracy[0]).toFixed(all_other_decimal) : parseFloat(row.dmf_accuracy[0]).toFixed(all_other_decimal) :'--'),

      massNormal: props.fluid_state.toLowerCase()==='gas' ? row.dmf_accuracy && row.dmf_accuracy[1] ? parseFloat(row.dmf_accuracy[1]).toFixed(all_other_decimal) :'--':  
      (row.dmf_accuracy && row.dmf_accuracy[1] ? row.flow_accuracy_radio_button && row.flow_accuracy_radio_button.length !==undefined ? row.flow_accuracy_radio_button.includes(row.dmf_accuracy[1])?row.dmf_accuracy[1]:parseFloat(row.dmf_accuracy[1]).toFixed(all_other_decimal) : parseFloat(row.dmf_accuracy[1]).toFixed(all_other_decimal) :'--'),
      
      massMax: props.fluid_state.toLowerCase()==='gas' ? row.dmf_accuracy && row.dmf_accuracy[2] ? parseFloat(row.dmf_accuracy[2]).toFixed(all_other_decimal):'--':
      (row.dmf_accuracy && row.dmf_accuracy[2] ?row.flow_accuracy_radio_button && row.flow_accuracy_radio_button.length !==undefined? row.flow_accuracy_radio_button.includes(row.dmf_accuracy[2])?row.dmf_accuracy[2]:parseFloat(row.dmf_accuracy[2]).toFixed(all_other_decimal) : parseFloat(row.dmf_accuracy[2]).toFixed(all_other_decimal) :'--'),

      pressMin: row.pressure_drop && row.pressure_drop[0] && !isNaN(row.pressure_drop[0])
        ? row.pressure_drop[0].toFixed(all_other_decimal) : '--',
      pressNormal: row.pressure_drop && row.pressure_drop[1] && !isNaN(row.pressure_drop[1])
        ? row.pressure_drop[1].toFixed(all_other_decimal) : '--',
      pressMax: row.pressure_drop && row.pressure_drop[2] && !isNaN(row.pressure_drop[2])
        ? row.pressure_drop[2].toFixed(all_other_decimal) : '--',

      tubeMin: row.tube_velocity && row.tube_velocity[0] ? parseFloat(row.tube_velocity[0]).toFixed(all_other_decimal) : '--',
      tubeNormal: row.tube_velocity && row.tube_velocity[1] ? parseFloat(row.tube_velocity[1]).toFixed(all_other_decimal) : '--',
      tubeMax: row.tube_velocity && row.tube_velocity[2] ? parseFloat(row.tube_velocity[2]).toFixed(all_other_decimal) : '--',
      minAccurateFlowAtOnePercent: row.min_accurate_flow_at_1_percent ?  parseFloat(row.min_accurate_flow_at_1_percent).toFixed(flow_rate_decimal) : '--',
      tubePressureRating: row.tubepressurerating  ? 'up to ' + parseFloat(row.tubepressurerating).toFixed(line_pressure_decimal) : '--',
      maxTemperatureRating: row.maxtemperaturerating ? parseFloat(row.maxtemperaturerating).toFixed(temperature_decimal) : '--',
      minTemperatureRating: row.mintemperaturerating ? parseFloat(row.mintemperaturerating).toFixed(temperature_decimal) : '--',
      densityAccuracy: row.density_accuracy && !isNaN(row.density_accuracy) &&isCryo() 
        ? row.density_accuracy.toFixed(all_other_decimal) : '--',
      velocityUnits: tubeVelocity(),
      pressureUnits: unitsLinePressureDropAbsolute(),
      wettedMaterial: row.wetted_material !== null ? row.wetted_material : "--",
      suggestedMeter: '???',
      mlMinMeasurable: '???',
      mlMaxMeasurable: '???',
      mlMinAccurate: '???',
      mlMaxAccurate: '???',
      minAnalogSpan: '???',
      meterMinAccFlow: '???',
      minRecommended: '???',
      maxRecommended: '???',
      defaultFactoryLowFlowCutoff: '???',
      minUpstreamPressure: '???',
      modelDescription: row.description || '--',
      description: row.description || '--',
      productFamily: row.model_family !== null ? row.model_family : "--",
    }
  }

  const coriolis = useMemo(
    () => [
      {
        Header: 'MODEL NAME',
        accessor: 'modelName',
        sortType: 'basic'
      },
      {
        Header: 'MASS FLOW RATE ACCURACY',
        columns: [
          {
            Header: CustomTranslationText('XXSPECIFY_MMI_SRL_TH1_FMIN', props).toUpperCase(),
            accessor: 'massMin',
            sortType: 'basic'
          },
          {
            Header: CustomTranslationText('XXSPECIFY_MMI_SRL_TH1_FNOR', props).toUpperCase(),
            accessor: 'massNormal',
            sortType: 'basic'
          },
          {
            Header: CustomTranslationText('XXSPECIFY_MMI_SR_TH1_FMAX', props).toUpperCase(),
            accessor: 'massMax',
            sortType: 'basic'
          },
        ],
      },
      {
        Header: 'PRESSURE DROP',
        columns: [
          {
            Header: CustomTranslationText('XXSPECIFY_MMI_SR_TH1_PRMIN', props).toUpperCase(),
            accessor: 'pressMin',
            sortType: 'basic'
          },
          {
            Header: CustomTranslationText('XXSPECIFY_MMI_SR_TH1_PRNOR', props).toUpperCase(),
            accessor: 'pressNormal',
            sortType: 'basic'
          },
          {
            Header: CustomTranslationText('XXSPECIFY_MMI_SR_TH1_PRMAX', props).toUpperCase(),
            accessor: 'pressMax',
            sortType: 'basic'
          },
        ],
      },
      {
        Header: 'TUBE VELOCITY',
        columns: [
          {
            Header: CustomTranslationText('XXSPECIFY_MMI_SR_TH1_TVMIN', props).toUpperCase(),
            accessor: 'tubeMin',
            sortType: 'basic'
          },
          {
            Header: CustomTranslationText('XXSPECIFY_MMI_SR_TH1_TVNOR', props).toUpperCase(),
            accessor: 'tubeNormal',
            sortType: 'basic'
          },
          {
            Header: CustomTranslationText('XXSPECIFY_MMI_SR_TH1_TVMAX', props).toUpperCase(),
            accessor: 'tubeMax',
            sortType: 'basic'
          },
        ],
      },
      {
        Header: 'DENSITY ACCURACY',
        accessor: 'densityAccuracy',
        sortType: 'basic'
      },
      {
        Header: 'MODEL DESCRIPTION',
        accessor: 'modelDescription',
        sortType: 'basic'
      },
    ],
    []
  );

  const viscosity = useMemo(
    () => [
      {
        Header: 'MODEL NAME',
        accessor: 'modelName',
        sortType: 'basic'
      },
      {
        Header: 'PRESSURE DROP',
        columns: [
          {
            Header: CustomTranslationText('XXSPECIFY_MMI_SR_TH1_PRMIN', props).toUpperCase(),
            accessor: 'pressMin',
            sortType: 'basic'
          },
          {
            Header: CustomTranslationText('XXSPECIFY_MMI_SR_TH1_PRNOR', props).toUpperCase(),
            accessor: 'pressNormal',
            sortType: 'basic'
          },
          {
            Header: CustomTranslationText('XXSPECIFY_MMI_SR_TH1_PRMAX', props).toUpperCase(),
            accessor: 'pressMax',
            sortType: 'basic'
          },
        ],
      },
      {
        Header: 'TUBE VELOCITY',
        columns: [
          {
            Header: CustomTranslationText('XXSPECIFY_MMI_SR_TH1_TVMIN', props).toUpperCase(),
            accessor: 'tubeMin',
            sortType: 'basic'
          },
          {
            Header: CustomTranslationText('XXSPECIFY_MMI_SR_TH1_TVNOR', props).toUpperCase(),
            accessor: 'tubeNormal',
            sortType: 'basic'
          },
          {
            Header: CustomTranslationText('XXSPECIFY_MMI_SR_TH1_TVMAX', props).toUpperCase(),
            accessor: 'tubeMax',
            sortType: 'basic'
          },
        ],
      },
      {
        Header: 'DENSITY ACCURACY',
        accessor: 'densityAccuracy',
        sortType: 'basic'
      },
      {
        Header: 'MODEL DESCRIPTION',
        accessor: 'modelDescription',
        sortType: 'basic'
      },
    ],
    []
  );

  const newRowVortex = row => {
    return {
      modelName: row.parent_model !== null ? row.parent_model : "--",
      pressMin: parseFloat(row.pressure_drop[0]).toFixed(line_pressure_decimal),
      pressNormal: !isNaN(parseFloat(row.pressure_drop[1])) ? parseFloat(row.pressure_drop[1]).toFixed(line_pressure_decimal): '--',
      pressMax: parseFloat(row.pressure_drop[2]).toFixed(line_pressure_decimal),
      meterStyle: row.prod_type !== null ? row.prod_type : "--",
      lineSize: row.size_inch !== null ? row.size_inch : "--",
      suggestedMeter:CustomTranslationText(row.suggested_meter,props),
      mlMinMeasurable: parseFloat(row.meter_min_measurable_flow).toFixed(flow_rate_decimal),
      mlMaxMeasurable: parseFloat(row.meter_max_measurable_flow).toFixed(flow_rate_decimal),
      mlMinAccurate: parseFloat(row.meter_min_accurate_flow).toFixed(flow_rate_decimal),
      mlMaxAccurate: parseFloat(row.meter_max_accurate_flow).toFixed(flow_rate_decimal),
      minAccurateFlowAtOnePercent: parseFloat(row.meter_min_accurate_flow).toFixed(flow_rate_decimal),
      tubeMin: parseFloat(row.min_nominal_velocity).toFixed(all_other_decimal),
      tubeNormal: !isNaN(parseFloat(row.op_nominal_velocity).toFixed(all_other_decimal),) ? parseFloat(row.op_nominal_velocity).toFixed(all_other_decimal): '--',
      tubeMax: parseFloat(row.max_nominal_velocity).toFixed(all_other_decimal),
      massMin: row.min_flow_accuracy ? parseFloat(row.min_flow_accuracy).toFixed(flow_rate_decimal) : '--',
      massNormal: row.normal_flow_accuracy ? parseFloat(row.normal_flow_accuracy).toFixed(flow_rate_decimal) : '--',
      massMax: parseFloat(row.max_flow_accuracy).toFixed(flow_rate_decimal),
      minAnalogSpan: parseFloat(row.minimum_analog_span).toFixed(all_other_decimal),
      estimatedLowFlowCutoff: parseFloat(row.low_flow_cutoff_freq).toFixed(flow_rate_decimal),
      minUpstreamPressure: parseFloat(row.min_upstream_pressure).toFixed(line_pressure_decimal),
      description: row.short_desc !== null ? row.short_desc : "--",
      productFamily: row.product_family_description ? row.product_family_description.slice(0, row.product_family_description.indexOf(",")) : "--",
      filterLineSize: row.size_inch !== null ? row.size_inch + " inch" : "--",
      velocityUnits: tubeVelocity(),
      pressureUnits: unitsLinePressureDropAbsolute(),
      tempLimits: row.temperature_limits,
      densityAccuracy: row.density_accuracy,
      tubePressureRating: '--'
    }
  }
  const newRowDensity = row => {
    return {
      modelName: row.product!== null ? row.product : "--",
      first_page:row.first_page,
      pressMin: row.pressure_drop&&row.pressure_drop[0]?parseFloat(row.pressure_drop[0]).toFixed(line_pressure_decimal):'--',
      pressNormal: row.pressure_drop&&row.pressure_drop[1]?parseFloat(row.pressure_drop[1]).toFixed(line_pressure_decimal):'--',
      pressMax:row.pressure_drop&&row.pressure_drop[2]? parseFloat(row.pressure_drop[2]).toFixed(line_pressure_decimal):'--',
      tubeMin: row.tube_velocity && row.tube_velocity[0] ? parseFloat(row.tube_velocity[0]).toFixed(all_other_decimal) : '--',
      tubeNormal: row.tube_velocity && row.tube_velocity[1] ? parseFloat(row.tube_velocity[1]).toFixed(all_other_decimal) : '--',
      tubeMax: row.tube_velocity && row.tube_velocity[2] ? parseFloat(row.tube_velocity[2]).toFixed(all_other_decimal) : '--',
      densityAccuracy: row.density_accuracy && !isNaN(row.density_accuracy)
        ? row.density_accuracy.toFixed(all_other_decimal) : '--',
      meterStyle: row.prod_type !== null ? row.prod_type : "--",
      lineSize: row.size_inch !== null ? row.size_inch : "--",
      suggestedMeter: "",
      flowRateRep:row.mass_flow_rate_repeat_liquid!==null?row.mass_flow_rate_repeat_liquid:"--",
      mlMinMeasurable: parseFloat(row.meter_min_measurable_flow).toFixed(flow_rate_decimal),
      mlMaxMeasurable: parseFloat(row.meter_max_measurable_flow).toFixed(flow_rate_decimal),
      mlMinAccurate: parseFloat(row.meter_min_accurate_flow).toFixed(flow_rate_decimal),
      mlMaxAccurate: parseFloat(row.meter_max_accurate_flow).toFixed(flow_rate_decimal),
      minAnalogSpan: parseFloat(row.minimum_analog_span).toFixed(all_other_decimal),
      defaultFactoryLowFlowCutoff: parseFloat(row.low_flow_cutoff_freq).toFixed(flow_rate_decimal),
      minUpstreamPressure: parseFloat(row.min_upstream_pressure).toFixed(line_pressure_decimal),
      modelDescription: row.description? row.description : "--",
      productFamily: row.model_family !== null ?  row.model_family :'--',
      filterLineSize: row.size_inch !== null ? row.size_inch + " inch" : "--",
      velocityUnits: tubeVelocity(),
      pressureUnits: unitsLinePressureDropAbsolute(),
      tempLimits: row.temperature_limits !== null ? row.temperature_limits : '--',
      tubePressureRating: parseFloat(row.max_pressure_rating).toFixed(line_pressure_decimal) !== null ? 'up to ' + parseFloat(row.max_pressure_rating).toFixed(line_pressure_decimal) : '--'
    }
  }
  const newRowViscosity = row => {
    return {
      modelName: row.product!== null ? row.product : "--",
      
      pressMin: row.pressure_drop&&row.pressure_drop[0]?parseFloat(row.pressure_drop[0]).toFixed(line_pressure_decimal):'--',
      pressNormal: row.pressure_drop&&row.pressure_drop[1]?parseFloat(row.pressure_drop[1]).toFixed(line_pressure_decimal):'--',
      pressMax:row.pressure_drop&&row.pressure_drop[2]? parseFloat(row.pressure_drop[2]).toFixed(line_pressure_decimal):'--',
      tubeMin: row.tube_velocity && row.tube_velocity[0] ? parseFloat(row.tube_velocity[0]).toFixed(all_other_decimal) : '--',
      tubeNormal: row.tube_velocity && row.tube_velocity[1] ? parseFloat(row.tube_velocity[1]).toFixed(all_other_decimal) : '--',
      tubeMax: row.tube_velocity && row.tube_velocity[2] ? parseFloat(row.tube_velocity[2]).toFixed(all_other_decimal) : '--',
      densityAccuracy: row.density_accuracy && !isNaN(row.density_accuracy)
        ? row.density_accuracy.toFixed(all_other_decimal) : '--',
      meterStyle: row.prod_type !== null ? row.prod_type : "--",
      lineSize: row.size_inch !== null ? row.size_inch : "--",
      suggestedMeter: "",
      flowRateRep:row.mass_flow_rate_repeat_liquid!==null?row.mass_flow_rate_repeat_liquid:"--",
      mlMinMeasurable: parseFloat(row.meter_min_measurable_flow).toFixed(flow_rate_decimal),
      mlMaxMeasurable: parseFloat(row.meter_max_measurable_flow).toFixed(flow_rate_decimal),
      mlMinAccurate: parseFloat(row.meter_min_accurate_flow).toFixed(flow_rate_decimal),
      mlMaxAccurate: parseFloat(row.meter_max_accurate_flow).toFixed(flow_rate_decimal),
      minAnalogSpan: parseFloat(row.minimum_analog_span).toFixed(all_other_decimal),
      defaultFactoryLowFlowCutoff: parseFloat(row.low_flow_cutoff_freq).toFixed(flow_rate_decimal),
      minUpstreamPressure: parseFloat(row.min_upstream_pressure).toFixed(line_pressure_decimal),
      modelDescription: row.description? row.description : "--",
      productFamily: row.description !== null ? row.description.slice(0, row.description.indexOf(",")) : "--",
      filterLineSize: row.size_inch !== null ? row.size_inch + " inch" : "--",
      velocityUnits: tubeVelocity(),
      pressureUnits: unitsLinePressureDropAbsolute(),
      tempLimits: row.temperature_limits !== null ? row.temperature_limits : '--',
      tubePressureRating: parseFloat(row.max_pressure_rating).toFixed(line_pressure_decimal) !== null ? 'up to ' + parseFloat(row.max_pressure_rating).toFixed(line_pressure_decimal) : '--',
      wettedMaterial: row.wetted_material !== null ? row.wetted_material : "--",
    }
  }
  const vortex = useMemo(
    () => [
      {
        Header: 'MODEL NAME',
        accessor: 'modelName',
        sortType: 'basic'
      },
      {
        Header: 'PRESSURE DROP',
        columns: [
          {
            Header: CustomTranslationText('XXSPECIFY_MMI_SR_TH1_PRMIN', props).toUpperCase(),
            accessor: 'pressMin',
            sortType: 'basic'
          },
          {
            Header: CustomTranslationText('XXSPECIFY_MMI_SR_TH1_PRNOR', props).toUpperCase(),
            accessor: 'pressNormal',
            sortType: 'basic'
          },
          {
            Header: CustomTranslationText('XXSPECIFY_MMI_SR_TH1_PRMAX', props).toUpperCase(),
            accessor: 'pressMax',
            sortType: 'basic'
          },
        ],
      },
      {
        Header: 'METER STYLE',
        accessor: 'meterStyle',
        sortType: 'basic'
      },
      {
        Header: 'LINE SIZE',
        accessor: 'lineSize',
        sortType: 'basic'
      },
      {
        Header: 'SUGGESTED METER',
        accessor: 'suggestedMeter',
        sortType: 'basic'
      },
      {
        Header: 'METER LIMITS',
        columns: [
          {
            Header: 'MIN MEASURABLE',
            accessor: 'mlMinMeasurable',
            sortType: 'basic'
          },
          {
            Header: 'MAX MEASURABLE',
            accessor: 'mlMaxMeasurable',
            sortType: 'basic'
          },
          {
            Header: 'MIN ACCURATE',
            accessor: 'mlMinAccurate',
            sortType: 'basic'
          },
          {
            Header: 'MAX ACCURATE',
            accessor: 'mlMaxAccurate',
            sortType: 'basic'
          },
        ],
      },
      {
        Header: 'MINIMUM ANALOG SPAN (4-20MA)',
        accessor: 'minAnalogSpan',
        sortType: 'basic'
      },
      {
        Header: 'MINIMUM UPSTREAM PRESSURE',
        accessor: 'minUpstreamPressure',
        sortType: 'basic'
      },
      {
        Header: 'ESTIMATED LOW FLOW CUTOFF',
        accessor: 'estimatedLowFlowCutoff',
        sortType: 'basic'
      },
      {
        Header: 'DESCRIPTION',
        accessor: 'description',
        sortType: 'basic'
      },
    ],
    []
  );

  const newRowMagnetic = row => {
    return {
      modelName: row.parent_model !== null ? row.parent_model : "--",
      meterStyle: row.characteristic !== null ? row.characteristic : "--",
      lineSize: row.line_size_inch !== null ? row.line_size_inch : "--",
      mlMinMeasurable: parseFloat(row.mag_meter_min_flow).toFixed(flow_rate_decimal),
      mlMaxMeasurable: parseFloat(row.mag_meter_max_flow).toFixed(flow_rate_decimal),
      minAccurateFlowAtOnePercent: parseFloat(row.min_accurate_flow).toFixed(flow_rate_decimal),
      meterMinAccFlow: parseFloat(row.min_accurate_flow).toFixed(flow_rate_decimal),
      minAnalogSpan: parseFloat(row.mag_minimum_span).toFixed(all_other_decimal),
      minRecommended: parseFloat(row.min_fluid_service_limits).toFixed(all_other_decimal),
      maxRecommended: parseFloat(row.max_fluid_service_limits).toFixed(all_other_decimal),
      modelDescription: row.short_description !== null ? row.short_description : "--",
      productFamily: row.short_description ? row.short_description.slice(0, row.short_description.indexOf(",")) : "--",
      filterLineSize: row.line_size_inch !== null ? row.line_size_inch + "inch" : "--",
      velocityUnits: tubeVelocity(),
      pressureUnits: unitsLinePressureDropAbsolute(),
      suggestedMeter:CustomTranslationText(row.suggested_meter,props)
    }
  }
  const magnetic = useMemo(
    () => [
      {
        Header: 'MODEL NAME',
        accessor: 'modelName',
        sortType: 'basic'
      },
      {
        Header: 'METER STYLE',
        accessor: 'meterStyle',
        sortType: 'basic'
      },
      {
        Header: 'LINE SIZE',
        accessor: 'lineSize',
        sortType: 'basic'
      },
      {
        Header: 'SUGGESTED METER',
        accessor: 'suggestedMeter',
        sortType: 'basic'
      },
      {
        Header: 'METER LIMITS',
        columns: [
          {
            Header: 'MIN MEASURABLE',
            accessor: 'mlMinMeasurable',
            sortType: 'basic'
          },
          {
            Header: 'MAX MEASURABLE',
            accessor: 'mlMaxMeasurable',
            sortType: 'basic'
          },
        ],
      },
      {
        Header: 'METER MIN ACC FLOW @ 1.0%',
        accessor: 'meterMinAccFlow',
        sortType: 'basic'
      },
      {
        Header: 'MINIMUM ANALOG SPAN (4-20MA)',
        accessor: 'minAnalogSpan',
        sortType: 'basic'
      },
      {
        Header: 'RECOMMENDED FLUID SERVICE LIMITS',
        columns: [
          {
            Header: CustomTranslationText('XXSPECIFY_MMI_SR_TH1_PRMIN', props).toUpperCase(),
            accessor: 'minRecommended',
            sortType: 'basic'
          },
          {
            Header: CustomTranslationText('XXSPECIFY_MMI_SR_TH1_PRMAX', props).toUpperCase(),
            accessor: 'maxRecommended',
            sortType: 'basic'
          },
        ],
      },
      {
        Header: 'MODEL DESCRIPTION',
        accessor: 'modelDescription',
        sortType: 'basic'
      },
    ],
    []
  );
  const headers = {
    "pressure_drop": unitsLinePressureDropAbsolute(),
    "tube_velocity": tubeVelocity(),
    "density_accuracy": densityAccuracyUnits ? densityAccuracyUnits : '',
    "meter_limits": props.fluid_state.toLowerCase() !== 'gas' ? flowRateUnit.value ? (flowRateUnit.value).toUpperCase() : '' : gasFlowRateUnit.value ? (gasFlowRateUnit.value).toUpperCase() : '',
    "recommended_fluid_service_limits": props.fluid_state.toLowerCase() !== 'gas' ? flowRateUnit.value ? (flowRateUnit.value).toUpperCase() : '' : gasFlowRateUnit.value ? (gasFlowRateUnit.value).toUpperCase() : '',
    "line_size": 'inch',
    "Estimated_low_flow_cutoff": props.fluid_state.toLowerCase() !== 'gas' ? flowRateUnit.value ? (flowRateUnit.value).toUpperCase() : '' : gasFlowRateUnit.value ? (gasFlowRateUnit.value).toUpperCase() : '',
    "minimum_upstream_pressure": unitsLinePressureDropAbsolute()
  }

  const techCheck = search_parameters.select_technology.toLowerCase() === 'coriolis' ? <SizingResultsTableStructure columns={coriolis} data={sizingResultsTable} tableType="coriolis-table" headers={headers} results={sizingResults} props={props}count={count}checked={checkedFilter} is_cryo={isCryo()} />
    : search_parameters.select_technology.toLowerCase() === 'magnetic' ? <SizingResultsTableStructure columns={magnetic} data={sizingResultsTable} tableType="mag-table" headers={headers} results={sizingResults} props={props}count={count} checked={checkedFilter} />
    : search_parameters.select_technology.toLowerCase() === 'density' ? <SizingResultsTableStructure columns={viscosity} data={sizingResultsTable} tableType="viscosity-table" headers={headers} results={sizingResults} props={props}count={count} checked={checkedFilter}/>
    : search_parameters.select_technology.toLowerCase() === 'viscosity' ? <SizingResultsTableStructure columns={viscosity} data={sizingResultsTable} tableType="viscosity-table" headers={headers}results={sizingResults} props={props}count={count} checked={checkedFilter} />
    : search_parameters.select_technology.toLowerCase() === 'vortex' ? <SizingResultsTableStructure columns={vortex} data={sizingResultsTable} tableType="vortex-table" headers={headers} results={sizingResults} props={props}count={count} checked={checkedFilter}/>
    : <span />
    function getFilteredCodes(array, key, value) {
      return array.filter(function(e) {
        return e[key] == value;
      });
    }
  useEffect(() => {

    if (massValues.length !== 0 ) {
      const concatValues =[...new Set(massValues.map(elm => Object.keys(elm)).reduce((a, b) => a.concat(b), []))];
      let concat=concatValues.filter(data=>data!=="suggested meter")
      let checked= checkedFilter.filter(data=>data!=="Show All Meters")
      let filtered
      if(checked.length===0){
        filtered = tableAllData
        
      }else{
        if(concat.length===2){
          filtered = tableAllData.filter(elm =>
            checked.includes(elm[concat[0]])  && checked.includes(elm[concat[1]])
              );
        }else if(concat.length===1){
          filtered =tableAllData.filter(elm =>
          checked.includes(elm[concat[0]]) 
              );
        }else if(concat.length===3){
          filtered = tableAllData.filter(elm =>
            checked.includes(elm[concat[0]])  && checked.includes(elm[concat[1]])&&checked.includes(elm[concat[2]])
              );
        }else if(concat.length===4){
          filtered = tableAllData.filter(elm =>
            checked.includes(elm[concat[0]])  && checked.includes(elm[concat[1]])&&checked.includes(elm[concat[2]])&&checked.includes(elm[concat[3]])
              );
        }
      }
      if(search_parameters.select_technology.toLowerCase()==='vortex'||search_parameters.select_technology.toLowerCase()==='magnetic'){

        if (checkedFilter.includes(CustomTranslationText('XXSPECIFY_MMI_SR_LBL_FORMAG2', props))){
          ///No Operation
        }
        else{
          filtered=filtered.filter(data=>data.suggestedMeter!==CustomTranslationText("XXSPECIFY_MMI_MC_SUGGMTR_NO", props))
        }
      }
        setSizingResultsTable(filtered);
        props.sizingResultsData(filtered); 
        }
    
    
  }, [massValues]);

  const filterDeafultCheck = (checked, name) => {
      setMassValues(prevState => ([...prevState, { ["meterStyle"]: name }]));
      setCheckedFilter(checked);
  };



  useEffect(() => {
    let filtered
    let filterCheck = [];
    let allValues = ["FLANGED", "WAFER", "REDUCER", "DUAL", "QUAD"];
    if (tableAllData.length > 0) {
      if (search_parameters.flanged) {
        filterCheck.push('FLANGED');
        filterDeafultCheck(filterCheck, 'FLANGED');
      } else {
        let name = 'FLANGED';
        if (name) {
          toggleProductFilter(false, 'FLANGED', "meterStyle");
        }
      }
      if (search_parameters.wafer) {
        
        filterCheck.push("WAFER");
        filterDeafultCheck(filterCheck, "WAFER");
      } else {
        let name = checkedFilter.includes("WAFER");
        if (name) {
          toggleProductFilter(false, "WAFER", "meterStyle");
        }
      }
      if (search_parameters.reducer) {
        filterCheck.push("REDUCER");
        filterDeafultCheck(filterCheck, "REDUCER");
      } else {
        let name = checkedFilter.includes("DUAL");
        if (name) {
          toggleProductFilter(false, "REDUCER", "meterStyle");
        }
      }
      if (search_parameters.dual) {
        filterCheck.push("DUAL");
        filterDeafultCheck(filterCheck, "DUAL");
      } else {
        let name = checkedFilter.includes("DUAL");
        if (name) {
          toggleProductFilter(false, "DUAL", "meterStyle");
        }
      }
      if (search_parameters.quad) {
        filterCheck.push("QUAD");
        filterDeafultCheck(filterCheck, "QUAD");
      } else {
        let name = checkedFilter.includes("QUAD");
        if (name) {
          toggleProductFilter(false, "QUAD", "meterStyle");
        }
      }

      if(search_parameters.select_technology.toLowerCase()==='magnetic'){
        if (checkedFilter.includes(CustomTranslationText('XXSPECIFY_MMI_SR_LBL_FORMAG2', props))){
          ///No Operation
        }
        else{
          filtered=tableAllData.filter(data=>data.suggestedMeter!==CustomTranslationText("XXSPECIFY_MMI_MC_SUGGMTR_NO", props))
          setSizingResultsTable(filtered);
          props.sizingResultsData(filtered); 
        }
      }
    }
  }, [search_parameters.flanged, search_parameters.wafer, search_parameters.reducer, search_parameters.dual, search_parameters.quad, tableAllData]);

  useEffect(() => {
    setFamily(product_family_name)
  }, [product_family]);

  useEffect(() => {
    if (tableAllData.length > 0 && !props.display_all_sensors_no_filters && search_parameters.select_technology.toLowerCase()!=='vortex' && productFamilyFlag ) {
     let availableProductFamilies = []
     tableAllData.filter((ele, ind) => ind === tableAllData.findIndex(elem => elem.productFamily === ele.productFamily || ele.productFamily === null)).map(i=>{
        if(i.productFamily){
          availableProductFamilies.push(i.productFamily)
        }  
      })
      if (family !== "" && availableProductFamilies.indexOf(family) !== -1) {
        setCheckedFilter([family])
        setMassValues(prevState => ([...prevState, { ['productFamily'] : family }]));
      } else {
        setCheckedFilter([...checkedFilter])
      }
        setProductFamilyFlag(false)
    }
    
  }, [family, tableAllData]);

  const toggleProductFilter = (checked, name, filterHeader) => {
    if (checked) {
      if (filterHeader === "lineSize") {
        setCheckedFilter([...checkedFilter, name]);
        setMassValues(prevState => ([...prevState, { ['filterLineSize']: name }]));
      } else {

        setCheckedFilter([...checkedFilter, name]);
        setMassValues(prevState => ([...prevState, { [filterHeader]: name }]));
      }
    } else {
      if (filterHeader === "lineSize") {
        setCheckedFilter(checkedFilter.filter((e) => (e !== name)));
        setMassValues(massValues.filter(function (obj) {
          return obj['filterLineSize'] !== name;
        }));
      } else {
        setCheckedFilter(checkedFilter.filter((e) => (e !== name)));
        setMassValues(massValues.filter(function (obj) {
          return obj[filterHeader] !== name;
        }));
      }
    }
  }

 
 const ClearAll = async (id) => {
   setDensityAccuracyUnits();
   props.showMoreRatesData([])
   setTubeVelocityUnits();
   setCheckedFilter([]);
   setMassValues([]);
    if(id === 'button'){
     setFamily('')
    }
  }

  const closeFilterItems = (name) => {
    setCheckedFilter(checkedFilter.filter((e) => (e !== name)));
    setMassValues(massValues.filter(aa => Object.values(aa)[0] !== name));
    if(name === family){
      setFamily('')
    }
  }

  useEffect(() => {
    if (checkedFilter.length === 0 && search_parameters.select_technology.toLowerCase() !=='magnetic' && search_parameters.select_technology.toLowerCase() !=='vortex' ) {
      setSizingResultsTable(tableAllData);
      props.sizingResultsData(tableAllData);
    }else if(checkedFilter.length===0 && search_parameters.select_technology.toLowerCase()==='magnetic' && search_parameters.select_technology.toLowerCase() !=='vortex'){
        if (checkedFilter.includes(CustomTranslationText('XXSPECIFY_MMI_SR_LBL_FORMAG2', props))){
          ///No Operation
        }
        else{
          let filtered=tableAllData.length !==0? tableAllData.filter(data=>data.suggestedMeter!==CustomTranslationText("XXSPECIFY_MMI_MC_SUGGMTR_NO", props)):[]
          setSizingResultsTable(filtered);
          props.sizingResultsData(filtered); 
        }
      
    }


  }, [checkedFilter]);
 
  const FilterIcon = <FontAwesomeIcon icon={accordionMain !== 0 ? faMinus : faPlus} />;



  const FilterArray = [
    {
      "technology": "coriolis",
      "filterName": [
        {
          id: 1,
          title: <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_PRFAM" />,
          value: "product family",
        },
        {
          id: 2,
          title: <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_FLO_REF_A" />,
          value: "mass flow reference spec accuracy",
        },
        {
          id: 3,
          title: <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_DEN_REF_A" />,
          value: "density reference spec accuracy",
        },
        {
          id: 4,
          title: <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_WTMTRL" />,
          value: "wetted material",
        }
      ]
    }, {
      "technology": "vortex",
      "filterName": [
        {
          id: 1,
          title: <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_PRFAM" />,
          value: "product family",
        },
        {
          id: 2,
          title: <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_FT_MAG" />,
          value: "suggested meter",
        },
        {
          id: 3,
          title: < CustomTranslation data="XXSPECIFY_MMI_SR_LBL_FTLNSZE" />,
          value: "line size",
        },
        {
          id: 4,
          title: <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_METR" />,
          value: "meter style",
        }
      ]
    }, {
      "technology": "magnetic",
      "filterName": [
        {
          id: 1,
          title: <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_PRFAM" />,
          value: "product family",
        },
        {
          id: 2,
          title: <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_FT_MAG" />,
          value: "suggested meter",
        },
        {
          id: 3,
          title: < CustomTranslation data="XXSPECIFY_MMI_SR_LBL_FTLNSZE" />,
          value: "line size",
        },
        {
          id: 4,
          title: <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_METR" />,
          value: "meter style",
        }
      ]
    },
    {
      "technology": "density",
      "filterName": [
        {
          id: 1,
          title: <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_PRFAM" />,
          value: "product family",
        },
        {
          id: 2,
          title: <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_DEN_REF_A" />,
          value: "density reference spec accuracy",
        },
        {
          id: 3,
          title: <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_WTMTRL" />,
          value: "wetted material",
        }
      ]
    }
    ,
    {
      "technology": "viscosity",
      "filterName": [
        {
          id: 1,
          title: <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_PRFAM" />,
          value: "product family",
        },
        {
          id: 2,
          title: <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_DEN_REF_A" />,
          value: "density reference spec accuracy",
        },
        {
          id: 3,
          title: <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_WTMTRL" />,
          value: "wetted material",
        }
      ]
    }
  ];

  const filterLabel = CustomTranslationText('XXSPECIFY_MMI_PR_FLT', props).toUpperCase();

  const FilterMain = [
    {
      id: 1,
      title: filterLabel,
    }
  ]

  const ChildTogggle = (e) => {
    let event = e.target.dataset.event;
    setAccordionChild(accordionChild === Number(event) ? 0 : Number(event));
  };

  const FilterToggle = (e) => {
    let event = e.target.dataset.event;
    setAccordionMain(accordionMain === Number(event) ? 0 : Number(event));
  };

  return (
    <>
    <ErrorModal show={props.error_message} handleClose={handleErrorClose} handleShow={handleErrorShow} />
    {sizingResults.length === 0 ?
      <>
        <Row className="loader">
          <Col xl={12} md={12}>
            <CustomLoader phase={showLoader} loaderText="innerText" loaderClass="innerLoaderImg" subTitle={CustomTranslationText('XXSPECIFY_MMI_PR_PLS_WAIT', props)} />
          </Col>
        </Row>
      </>
      :  
      <>
        <Row>
          <Col xl={3} md={12}>
          <h5 ><CustomTranslation data='XXSPECIFY_MMI_TOUR_TITLE_5' /></h5>
          </Col>
          <Col xl={9} md={12} className="SizingMargin">
            <SizingResultsHeadingFields />
          </Col>
        </Row>
        <Row>
          <Col xl={3} md={12} className="FilterHeight">
            {FilterMain.map((item, idx) => (
              <Card key={idx} >
                <span onClick={item.FilterToggle} data-event={item.id}>
                  <span className="d-flex">
                    <div className="  mr-auto font-weight-bold CollapsibleParent">
                      <p>{item.title}</p>
                    </div>
                    <div
                      className="click-layer"
                      onClick={FilterToggle}
                      data-event={item.id}
                    ></div>
                    <div>
                      {FilterIcon}
                    </div>
                  </span>
                </span>

                <Collapse isOpen={accordionMain === item.id}>
                  <div className="BorderFilter">
                    {
                      checkedFilter.length > 0 ? (
                        <span key={item.id}>
                          {
                            checkedFilter.map((items, i) =>
                              <span className="selectednameshow" key={i}>{items}
                                <span onClick={(e) => closeFilterItems(items)} className="closeFilter"></span>
                              </span>
                            )
                          }
                          <span className="clearAll" onClick={()=>ClearAll('button')}><CustomTranslation data="XXSPECIFY_MMI_PR_CLR_ALL_FLT" /></span>
                        </span>) : <span />
                    }
                  </div>
                  {FilterArray.filter(tech => tech.technology === search_parameters.select_technology.toLowerCase()).map(
                    list =>
                   list.filterName.map((element, index) => {
                    return (
                      <>
                        <Card key={index}>
                          <span onClick={element.ChildTogggle} data-event={element.id}>
                            <span className="d-flex">
                              <div className="  mr-auto font-weight-bold CollapsibleChild">
                                <p>{element.value === "line size" ?
                                  <>{element.title} <span className="filterSubText">(inch)</span></> :
                                  element.value === "density reference spec accuracy" ?
                                    <>{element.title} <span className="filterSubText">({props.units_density?props.units_density.key:''})</span></> :
                                    element.title}</p>
                              </div>
                              <div
                                className="click-layer"
                                onClick={ChildTogggle}
                                data-event={element.id}
                              ></div>
                              <div>
                              <FontAwesomeIcon icon={accordionChild === element.id  ? faChevronDown : faChevronUp} color={variables.emersonGreen} />
                              </div>
                            </span>
                          </span>

                          <Collapse isOpen={accordionChild === element.id}>
                            {
                              element.value === "product family" ?
                                tableAllData !== '' ? tableAllData.filter((ele, ind) => ind === tableAllData.findIndex(elem => elem.productFamily === ele?.productFamily || ele?.productFamily === null)).map((name, idx) =>
                                  name.productFamily !== undefined?
                                  <span className="CheckBoxList" key={idx}>
                                    <CustomCheckbox
                                      checked={checkedFilter.length > 0 ? checkedFilter.indexOf(name.productFamily) > -1 : false}
                                      name={name.productFamily}
                                      id={name.productFamily}
                                      className="custom-checkbox"
                                      onChange={(e) => toggleProductFilter(e.target.checked, name.productFamily, "productFamily")}
                                      label={name.productFamily}
                                    />
                                  </span> :null
                                ) :
                                  <span /> :
                                element.value === "mass flow reference spec accuracy" ?
                                  tableAllData !== "" ? tableAllData.filter((ele, ind) => ind === tableAllData.findIndex(elem => elem.massNormal === ele.massNormal || ele.massNormal === null)).sort((a, b) => parseFloat(a.massNormal) - parseFloat(b.massNormal)).map((dmf, idx) =>
                                  dmf.massNormal !== "--" && dmf.massNormal !== undefined?
                                    <span className="CheckBoxList" key={idx} >
                                      <CustomCheckbox
                                        checked={checkedFilter.length > 0 ? checkedFilter.indexOf(dmf.massNormal) > -1 : false}
                                        name={dmf.massNormal}
                                        id={dmf.massNormal}
                                        className="custom-checkbox"
                                        onChange={(e) => toggleProductFilter(e.target.checked, dmf.massNormal, "massNormal")}
                                        label={dmf.massNormal}
                                      />
                                    </span>:null
                                  ) :
                                    <span /> :
                                  element.value === "density reference spec accuracy" ?
                                    tableAllData !== "" ? tableAllData.filter((ele, ind) => ind === tableAllData
                                      .findIndex(elem => elem.densityAccuracy === ele.densityAccuracy || ele.densityAccuracy === null || ele.densityAccuracy === '--'))
                                      .sort((a, b) => parseFloat(a.densityAccuracy) - parseFloat(b.densityAccuracy))
                                      .map((row, idx) =>
                                      row.densityAccuracy !== undefined && row.densityAccuracy !== '--'?
                                        <span className="CheckBoxList" key={idx}>
                                          <CustomCheckbox
                                            checked={checkedFilter.length > 0 ? checkedFilter.indexOf(row.densityAccuracy) > -1 : false}
                                            name={row.densityAccuracy}
                                            id={row.densityAccuracy}
                                            className="custom-checkbox"
                                            onChange={(e) => toggleProductFilter(e.target.checked, row.densityAccuracy, "densityAccuracy")}
                                            label={row.densityAccuracy}
                                          />
                                        </span>: null
                                      ) :
                                      <span /> :
                                    element.value === "wetted material" ?
                                      tableAllData !== "" ? tableAllData.filter((ele, ind) => ind === tableAllData
                                        .findIndex(elem => elem.wettedMaterial === ele.wettedMaterial || ele.wettedMaterial === null || ele.wettedMaterial === '--'))
                                        .sort((a, b) => parseFloat(a.wettedMaterial) - parseFloat(b.wettedMaterial))
                                        .filter(data => data.wettedMaterial !== "--")
                                        .map((row, idx) =>
                                          row.wettedMaterial !== undefined?
                                          <span className="CheckBoxList" key={idx}>
                                            <CustomCheckbox
                                              checked={checkedFilter.length > 0 ? checkedFilter.indexOf(row.wettedMaterial) > -1 : false}
                                              name={row.wettedMaterial}
                                              id={row.wettedMaterial}
                                              className="custom-checkbox"
                                              onChange={(e) => toggleProductFilter(e.target.checked, row.wettedMaterial, "wettedMaterial")}
                                              label={row.wettedMaterial}
                                            />
                                          </span>:null
                                        ) :
                                        <span /> :
                                      element.value === "line size" ?
                                        tableAllData !== "" ? tableAllData.filter((ele, ind) => ind === tableAllData
                                          .findIndex(elem => elem.filterLineSize === ele.filterLineSize || ele.filterLineSize === null || ele.filterLineSize === '--'))
                                          .sort((a, b) => parseFloat(a.filterLineSize) - parseFloat(b.filterLineSize))
                                          .map((row, idx) =>
                                          row.filterLineSize !== undefined?
                                            <span className="CheckBoxList" key={idx}>
                                              <CustomCheckbox
                                                checked={checkedFilter.length > 0 ? checkedFilter.indexOf(row.filterLineSize) > -1 : false}
                                                name={row.filterLineSize}
                                                id={row.filterLineSize}
                                                className="custom-checkbox"
                                                onChange={(e) => toggleProductFilter(e.target.checked, row.filterLineSize, "lineSize")}
                                                label={row.filterLineSize}
                                              />
                                            </span>:null
                                          ) :
                                          <span /> :
                                        element.value === "meter style" ?
                                          tableAllData !== "" ? tableAllData.filter((ele, ind) => ind === tableAllData
                                            .findIndex(elem => elem.meterStyle === ele.meterStyle || ele.meterStyle === null || ele.meterStyle === '--'))
                                            .sort((a, b) => parseFloat(a.meterStyle) - parseFloat(b.meterStyle))
                                            .map((row, idx) =>
                                            row.meterStyle !== undefined?
                                              <span className="CheckBoxList" key={idx}>
                                                <CustomCheckbox
                                                  checked={checkedFilter.length > 0 ? checkedFilter.indexOf(row.meterStyle) > -1 : false}
                                                  name={row.meterStyle}
                                                  id={row.meterStyle}
                                                  className="custom-checkbox"
                                                  onChange={(e) => toggleProductFilter(e.target.checked, row.meterStyle, "meterStyle")}
                                                  label={row.meterStyle}
                                                />
                                              </span>:null
                                            ) : <span />:
                                        element.value === "suggested meter" ?
                                            tableAllData !== "" ?
                                                <span className="CheckBoxList" key={idx}>
                                                  <CustomCheckbox
                                                    checked={checkedFilter.length > 0 ? checkedFilter.indexOf( CustomTranslationText('XXSPECIFY_MMI_SR_LBL_FORMAG2', props)) > -1 : false}
                                                    name={CustomTranslationText('XXSPECIFY_MMI_SR_LBL_FORMAG2', props)}
                                                    id={CustomTranslationText('XXSPECIFY_MMI_SR_LBL_FORMAG2', props)}
                                                    className="custom-checkbox"
                                                    onChange={(e) => toggleProductFilter(e.target.checked, CustomTranslationText('XXSPECIFY_MMI_SR_LBL_FORMAG2', props), "suggested meter")}
                                                    label={CustomTranslationText('XXSPECIFY_MMI_SR_LBL_FORMAG2', props)}
                                                  />
                                                </span>
                                               : <span />
                                          : <span />
                            }
                          </Collapse>
                        </Card>
                      </>
                    );
                  }))
                  }
                </Collapse>
              </Card>
            ))}
            <SizingResultsProductFamilyComparisons select_technology={select_technology} />
            <SizingResultsCorrosionGuide />
          </Col>
          <Col xl={9} md={12}>
            {
              sizingResultsTable ? techCheck : <span />
            }
          </Col>
        </Row >
        <Row>
          <Col xl={2} md={12}></Col>
          <Col xl={10} md={12}>
            {sizingResults.length === 0 &&props.sizing_results_data.length>0?
              <span />
              : <SizingResultsNavButtons 
               results={sizingResults}
               onClickSizingResults={props.onClickSizingResults} 
               densityAccuracyUnits={densityAccuracyUnits}/>
            }
          </Col>
        </Row>
  </>
    }
    </>
  );
}


const mapStateToProps = state => ({
  email:state.AppHeader.email,
  select_technology: state.MeasurementType.select_technology,
  density: state.MeasurementType.density,
  viscosity: state.MeasurementType.viscosity,
  normal_viscosity: state.ProcessVariables.normal_viscosity,
  min_flow_rate: state.ProcessVariables.min_flow_rate,
  normal_flow_rate: state.ProcessVariables.normal_flow_rate,
  max_flow_rate: state.ProcessVariables.max_flow_rate,
  full_flow_rate: state.ProcessVariables.full_flow_rate,
  min_line_pressure: state.ProcessVariables.min_line_pressure,
  normal_line_pressure: state.ProcessVariables.normal_line_pressure,
  max_line_pressure: state.ProcessVariables.max_line_pressure,
  full_line_pressure: state.ProcessVariables.full_line_pressure,
  min_temperature: state.ProcessVariables.min_temperature,
  normal_temperature: state.ProcessVariables.normal_temperature,
  max_temperature: state.ProcessVariables.max_temperature,
  full_temperature: state.ProcessVariables.full_temperature,
  units_temperature: state.ProcessVariables.units_temperature,
  min_ambient: state.ProcessVariables.min_ambient,
  normal_ambient: state.ProcessVariables.normal_ambient,
  max_ambient: state.ProcessVariables.max_ambient,
  full_ambient: state.ProcessVariables.full_ambient,
  min_density: state.ProcessVariables.min_density,
  normal_density: state.ProcessVariables.normal_density,
  max_density: state.ProcessVariables.max_density,
  min_viscosity: state.ProcessVariables.min_viscosity,
  max_viscosity: state.ProcessVariables.max_viscosity,
  units_ambient: state.ProcessVariables.units_ambient,
  units_viscosity: state.ProcessVariables.units_viscosity,
  units_vapor: state.ProcessVariables.units_vapor,
  hygienic: state.ApplicationRequirements.hygienic,
  calculate_density_variation: state.ProcessVariables.calculate_density_variation,
  k_flow_consistency_index: state.ProcessVariables.k_flow_consistency_index,
  n_flow_behavior: state.ProcessVariables.n_flow_behavior,
  fluid_state: state.FluidSelection.fluid_state,
  fluid_source: state.FluidSelection.fluid_source,
  tank: state.ApplicationRequirements.tank,
  in_line: state.ApplicationRequirements.in_line,
  flow_through_chamber_slipstream:
  state.ApplicationRequirements.flow_through_chamber_slipstream,
  saved_preferences: state.Preferences.saved_preferences,
  pick_fluids_from_db: state.FluidSelection.pick_fluids_from_db,
  fluid_service: state.FluidSelection.fluid_service,
  fluid_service_key: state.FluidSelection.fluid_service_key,
  sonic_velocity: state.ProcessVariables.sonic_velocity,
  sonic_velocity_map: state.ProcessVariables.sonic_velocity_map,
  units_sonic_velocity: state.ProcessVariables.units_sonic_velocity,
  show_more_rates_result_data: state.SizingResults.show_more_rates_result_data,
  request_sizing_results: state.SizingResults.request_sizing_results,
  units_line_pressure: state.ProcessVariables.units_line_pressure,

  units_density: state.ProcessVariables.units_density,
  units_flow_rate: state.ProcessVariables.units_flow_rate,
  hygienic_application: state.ApplicationRequirements.hygienic_application,
  units_data: state.DropdownData.units_data,
  error_message: state.ErrorMessage.error_message,
  line_size: state.ApplicationRequirements.line_size,
  non_newtonian_liquid: state.ProcessVariables.non_newtonian_liquid,
  min_vapor: state.ProcessVariables.min_vapor,
  flanged: state.ApplicationRequirements.flanged,
  wafer: state.ApplicationRequirements.wafer,
  reducer: state.ApplicationRequirements.reducer,
  quad: state.ApplicationRequirements.quad,
  pressure: state.ApplicationRequirements.pressure,
  dual: state.ApplicationRequirements.dual,
  process_line_size_units: state.ApplicationRequirements.process_line_size_units,
  line_size_units: state.ApplicationRequirements.line_size_units,
  process_line_size: state.ApplicationRequirements.process_line_size,
  pipe_id: state.ApplicationRequirements.pipe_id,
  tube_velocity_unit: state.SizingResults.tube_velocity_unit,
  units_gas_density: state.ProcessVariables.units_gas_density,
  gas_density: state.ProcessVariables.gas_density,
  get_sizing_data: state.MySizings.get_sizing_data,
  was_clicked: state.SizingResults.was_clicked,
  is_applied: state.SizingResults.is_applied,
  mass_flow: state.SizingResults.mass_flow,
  flow_accuracy: state.SizingResults.flow_accuracy,
  search_parameters: state.SizingResults.search_parameters,
  aplication_name_error: state.SizingResultErrorMessage.aplication_name_error,
  max_flow_acc: state.ProcessVariables.max_flow_acc,
  flow_rate_decimal: state.Preferences.flow_rate_decimal,
  temperature_decimal: state.Preferences.temperature_decimal,
  line_pressure_decimal: state.Preferences.line_pressure_decimal,
  all_other_decimal: state.Preferences.all_other_decimal,
  flow_rate_decimal_factor: state.Preferences.flow_rate_decimal_factor,
  line_pressure_decimal_factor: state.Preferences.line_pressure_decimal_factor,
  temperature_decimal_factor: state.Preferences.temperature_decimal_factor,
  units_gas_flow_rate: state.ProcessVariables.units_gas_flow_rate,
  query_params: state.Preferences.query_params,
  steam_pressure_temp: state.FluidSelection.steam_pressure_temp,
  selected_row_data: state.SizingResults.selected_row_data,
  display_all_sensors_no_filters:
  state.ApplicationRequirements.display_all_sensors_no_filters,
  translation_data: state.TranslationData.translation_data,
  compare_model_names: state.SizingResults.compare_model_names,
  high_process_noise_slurry:
  state.ApplicationRequirements.high_process_noise_slurry,
  hygienic_application:state.ApplicationRequirements.hygienic_application,
  density_specific_gravity: state.ProcessVariables.density_sg,
  compressibility: state.ProcessVariables.compressibility,
  base_compressibility: state.ProcessVariables.base_compressibility,
  molecular_weight: state.ProcessVariables.molecular_weight,
  specific_gravity: state.ProcessVariables.specific_gravity,
  units_density_heading: state.ProcessVariables.units_density_heading,
  units_gas_density_sg: state.ProcessVariables.units_gas_density_sg,
  sizing_results_data: state.SizingResults.sizing_results_data, 
  show_more_rates_data: state.SizingResults.show_more_rates_data,
  max_pressure_drop: state.ProcessVariables.max_pressure_drop,
  compare_disable_button: state.SizingResults.compare_disable_button,
  schedule: state.ApplicationRequirements.schedule_value,
  product_family_name: state.ApplicationRequirements.product_family_name,
  wetted_material: state.ApplicationRequirements.wetted_material,
  wetted_material_label: state.ApplicationRequirements.wetted_material_label,
  wetted_material_value: state.ApplicationRequirements.wetted_material_value,
  units_data: state.DropdownData.units_data,
  line_press_row: state.ProcessVariables.line_press_row_psig,
  density_accuracy: state.SizingResults.density_accuracy,
  temp_row_f: state.ProcessVariables.temp_row_f,
  product_family: state.ApplicationRequirements.product_family,
  
});

export default connect(
  mapStateToProps,
  {
    selectedRowData,
    selectedTechnology,
    requestSizingResults,
    sizingResultsData,
    errorMessage,
    normalFlowRate,
    normalLinePressure,
    normalTemperature,
    normalAmbient,
    lineSize,
    normalViscosity,
    displaySizingResults,
    pipeId,
    tubeVelocityUnit,
    gasDensity,
    unitsGasDensity,
    showMoreRatesData,
    wasClicked,
    getSizingData,
    sizingApplication,
    queryParams,
    compareDisableButton,
    productFamilyName,
    configWarningMsg,
    sonicVelocityWarningMsg,
    sonicVelocityEntered,
    ambTempWarningMsg,
    highTempWarningMsg,
    highTemperatureErrorMsg,
    resizingDiscardFlag,
    densityAccuracy
  }
)(SizingResultsTableData);
