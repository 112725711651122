import {
  FLUID_STATE,
  FLUID_SOURCE,
  REVERT_FLAG,
  PICK_FLUIDS_FROM_DB,
  PICK_FLUIDS_COOLPROPS_FLAG,
  PICK_GAS_FROM_DB,
  CONDUCTIVITY,
  FLUID_SERVICE,
  FLUID_SERVICE_KEY,
  CUSTOM_FLUID_NAME,
  STEAM_PRESSURE_TEMP,
  NATURAL_GAS_COMPOSE,
  NATURAL_GAS_NAME_BORDER_ERROR,
  NATURAL_GAS_COMPOSITION_NAME,
  COMPOSITION_METHOD,
  DATABASE_GASSES,
  RELATIVE_DENSITY,
  MOLE_PERCENT_CO2,
  HEATING_VALUE,
  HEATING_VALUE_DROPDOWN,
  MOLE_PERCENT_H2,
  MOLE_PERCENT_CO,
  MOLE_ARRAY_REDUX,
  TOTAL_MOLE,
  SELECTED_DATABASE_NATURAL_GAS,
  AVAILABLE_COMPONENTS,
  SELECTED_COMPONENTS,
  PICK_FLUIDS_FROM_DB_UNITS,
  CONDUCTIVITY_UNITS,
  FLUID_SERVICE_UNITS,
  FLUID_SELECTION_FLAG,
  FLUID_SELECTION_SECTION,
  COMPOSITION_METHOD_SECTION,
  FLUID_STATE_UNITS,
  FLUID_SOURCE_UNITS,
  NATURAL_GAS_COMPONENTS,
  CLEAR_FLUID_SELECTION,
  MOLE_PERCENT_N2,
  DATABASE_GASSES_LIST,
  IS_SELECTED,
  FLUID_SOURCE_ARRAY,
  FLUID_STATE_ARRAY,
  CUSTOM_BORDER_ERROR,
  COOLPROPS_VISC,
  MOLECULAR_WEIGHT,
  COMPOSITION_METHOD_FLAG
} from '../actionTypes';

const initialState = {
  is_selected:false,
  fluid_state: 'LIQUID',
  fluid_state_array:[],
  fluid_source_array:[],
  fluid_state_units: [],
  fluid_selection_section: {},
  composition_method_section: {},
  fluid_selection_flag: false,
  composition_method_flag: false,
  fluid_source: 'Database',
  revert_flag:false,
  fluid_source_units: '',
  pick_fluids_from_db: 'WATER',
  pick_fluids_coolprops_flag: true,
  pick_fluids_from_db_units: '',
  pick_gas_from_db: 'Gulf Coast Natural Gas',
  conductivity: 'Greater Than 100 Microsiemens/Cm',
  conductivity_units: '',
  fluid_service:'Normal (0-39 Ft/Sec Or 0-12 M/Sec)',
  fluid_service_key: 'NORMAL',
  fluid_service_units: '',
  custom_fluid_name: '',
  steam_pressure_temp: 'Superheated: Pressure and Temperature must be specified',
  natural_gas_compose: false,
  natural_gas_name_border_error: false,
  natural_gas_composition_name: '',
  composition_method: '',
  database_gasses: '',
  database_gasses_list:[],
  relative_density: '',
  mole_percent_CO2: '',
  heating_value: '',
  heating_value_dropdown: '',
  mole_percent_H2: '',
  mole_percent_N2: '',
  mole_percent_CO: '',
  available_components: [],
  selected_components: [],
  natural_gas_components: '',
  mole_array_redux: [],
  selected_database_natural_gas: [],
  custom_border_error: false,
  coolprops_visc:false,
  molecular_weight:'',
  total_mole:''
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FLUID_STATE: {
      return {
        ...state,
        fluid_state: action.payload.id
      }
    }
    case FLUID_STATE_ARRAY: {
      return {
        ...state,
        fluid_state_array: action.payload.id
      }
    }
    case FLUID_SOURCE_ARRAY: {
      return {
        ...state,
        fluid_source_array: action.payload.id
      }
    }
    case IS_SELECTED: {
      return {
        ...state,
        is_selected: action.payload.id
      }
    }
    case FLUID_SOURCE: {
      return {
        ...state,
        fluid_source: action.payload.id
      }
    }
    case REVERT_FLAG: {
      return {
        ...state,
        revert_flag: action.payload.id
      }
    }
    case PICK_FLUIDS_FROM_DB: {
      return {
        ...state,
        pick_fluids_from_db: action.payload.id
      }
    }
    case PICK_FLUIDS_COOLPROPS_FLAG: {
      return {
        ...state,
        pick_fluids_coolprops_flag: action.payload.id
      }
    }
    case MOLECULAR_WEIGHT: {
      return {
        ...state,
        molecular_weight: action.payload.id
      }
    }
    case TOTAL_MOLE: {
      return {
        ...state,
        total_mole: action.payload.id
      }
    }
    case COOLPROPS_VISC: {
      return {
        ...state,
        coolprops_visc: action.payload.id
      }
    }    
    case PICK_GAS_FROM_DB: {
      return {
        ...state,
        pick_gas_from_db: action.payload.id
      }
    }
    case CONDUCTIVITY: {
      return {
        ...state,
        conductivity: action.payload.id
      }
    }
    case FLUID_SERVICE: {
      return {
        ...state,
        fluid_service: action.payload.id
      }
    }
    case FLUID_SERVICE_KEY: {
      return {
        ...state,
        fluid_service_key: action.payload.id
      }
    }
    case CUSTOM_FLUID_NAME: {
      return {
        ...state,
        custom_fluid_name: action.payload.id
      }
    }
    case CUSTOM_BORDER_ERROR:{
      return{
        ...state,
        custom_border_error:action.payload.id
      }
    }
    case STEAM_PRESSURE_TEMP: {
      return {
        ...state,
        steam_pressure_temp: action.payload.id
      }
    }
    case NATURAL_GAS_COMPOSE: {
      return {
        ...state,
        natural_gas_compose: action.payload.id
      }
    }
    case NATURAL_GAS_NAME_BORDER_ERROR: {
      return {
        ...state,
        natural_gas_name_border_error: action.payload.id
      }
    }
    case NATURAL_GAS_COMPOSITION_NAME: {
      return {
        ...state,
        natural_gas_composition_name: action.payload.id
      }
    }
    case COMPOSITION_METHOD: {
      return {
        ...state,
        composition_method: action.payload.id
      }
    }
    case DATABASE_GASSES: {
      return {
        ...state,
        database_gasses: action.payload.id
      }
    }
    case DATABASE_GASSES_LIST: {
      return {
        ...state,
        database_gasses_list: action.payload.id
      }
    }
    case RELATIVE_DENSITY: {
      return {
        ...state,
        relative_density: action.payload.id
      }
    }
    case MOLE_PERCENT_CO2: {
      return {
        ...state,
        mole_percent_CO2: action.payload.id
      }
    }
    case MOLE_PERCENT_N2: {
      return {
        ...state,
        mole_percent_N2: action.payload.id
      }
    }
    case HEATING_VALUE: {
      return {
        ...state,
        heating_value: action.payload.id
      }
    }
    case HEATING_VALUE_DROPDOWN: {
      return {
        ...state,
        heating_value_dropdown: action.payload.id
      }
    }
    case MOLE_PERCENT_H2: {
      return {
        ...state,
        mole_percent_H2: action.payload.id
      }
    }
    case MOLE_PERCENT_CO: {
      return {
        ...state,
        mole_percent_CO: action.payload.id
      }
    }
    case MOLE_ARRAY_REDUX: {
      return {
        ...state,
        mole_array_redux: action.payload.id
      }
    }
    case SELECTED_DATABASE_NATURAL_GAS: {
      return {
        ...state,
        selected_database_natural_gas: action.payload.id
      }
    }
    case AVAILABLE_COMPONENTS: {
      return {
        ...state,
        available_components: action.payload.id
      }
    }
    case SELECTED_COMPONENTS: {
      return {
        ...state,
        selected_components: action.payload.id
      }
    }
    case PICK_FLUIDS_FROM_DB_UNITS: {
      return {
        ...state,
        pick_fluids_from_db_units: action.payload.id
      }
    }
    case CONDUCTIVITY_UNITS: {
      return {
        ...state,
        conductivity_units: action.payload.id
      }
    }
    case FLUID_SERVICE_UNITS: {
      return {
        ...state,
        fluid_service_units: action.payload.id
      }
    }
    case FLUID_SELECTION_FLAG:{
      return{
        ...state,
        fluid_selection_flag: action.payload.id
      }
    }

    case COMPOSITION_METHOD_FLAG:{
      return{
        ...state,
        composition_method_flag: action.payload.id
      }
    }

    case FLUID_SELECTION_SECTION:{
      return{
        ...state,
        fluid_selection_section: action.payload.id
      }
    }

    case COMPOSITION_METHOD_SECTION: {
      return{
        ...state,
        composition_method_section: action.payload.id
      }
    }

    case FLUID_STATE_UNITS: {
      return {
        ...state,
        fluid_state_units: action.payload.id
      }
    }
    case FLUID_SOURCE_UNITS: {
      return {
        ...state,
        fluid_source_units: action.payload.id
      }
    }
    case NATURAL_GAS_COMPONENTS: {
      return {
        ...state,
        natural_gas_components: action.payload.id
      }
    }
    case CLEAR_FLUID_SELECTION: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
