import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import './ShowMoreRates.scss';
import '../../styles/App.scss';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';
import { useTable, useSortBy, usePagination, useRowSelect } from "react-table";
const ShowMoreRatesTable = ({ columns, data, tableType, headers }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    toggleAllRowsSelected,
    state: { pageIndex, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );
  const firstPageRows = rows;
  const tableClasses = `show-more-rates-table ${tableType}`;
  return (
    <>
      <Table
        bordered
        hover
        responsive
        className={tableClasses}
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <span className="header-content">
                    <span className="span-center">
                      <TableHeader
                        heading={
                          typeof column.Header === "string"
                            ? column.Header
                            : undefined
                        }
                        headers={headers}
                      />
                    </span>
                  </span>
                  <span className="sort-arrow">
                    {typeof column.Header === "string" ? (
                      column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className="icon-down" />
                        ) : (
                          <span className="icon-up" />
                        )
                      ) : (
                        <span className="icon-up-down" />
                      )
                    ) : (
                      <span />
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={row.isSelected ? "selected-row" : ""}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <br />
    </>
  );
};

const TableHeader = ({ heading, headers }) => {
  const formatHeading = (heading) => {
    switch (heading) {
      case "FLOW RATE":
        return (
          <>
            <CustomTranslation data='XXSPECIFY_MMI_SHR_TBL_FLOWRATE' />
            <br />
            <span className="sub-text-uom">{`(${headers.flow_rate})`}</span>
          </>
        );
      case "FLOW RATEE":
        return (
          <>
            <CustomTranslation data='XXSPECIFY_MMI_SHR_TBL_FLOWRATE' />
            <br />
            <span className="sub-text-uom">{`(${headers.flow_rate})`}</span>
          </>
        );
      case "MASS FLOW ACCURACY":
        return (
          <>
            <CustomTranslation data='XXSPECIFY_MMI_SHR_TBL_MASFLOAC' />
          </>
        );
      case "PRESSURE DROP @ MIN DENSITY":
        return (
          <>
            <CustomTranslation data='XXSPECIFY_MMI_SR_PR_DRP_MN_DEN' />
          </>
        );
      case "VOLUMETRIC FLOW ACCURACY":
        return <><CustomTranslation data='XXSPECIFY_MMI_SR_VOL_FLO_ACC' /></>;
      case "PRESSURE DROP":
        return (
          <>
            <CustomTranslation data='XXSPECIFY_MMI_SHR_TBL_PREDROP' />
            <br />
            <span className="sub-text-uom">{`(${headers.pressure_drop})`}</span>
          </>
        );
      case "PRESSURE DROP @ MAX DENSITY":
        return (
          <>
           <CustomTranslation data='XXSPECIFY_MMI_SR_PR_DRP_MX_DEN' />
          </>
        );
      case "VELOCITY @ MIN DENSITY":
        return (
          <>
            <CustomTranslation data='XXSPECIFY_MMI_SR_VELO_MIX_DEN' />
          </>
        );
      case "VELOCITY":
        return (
          <>
            <CustomTranslation data='XXSPECIFY_MMI_SHR_TBL_VELOCITY' />
            <br />
            <span className="sub-text-uom">{headers.velocity}</span>
          </>
        );
      case "VELOCITY @ MAX DENSITY":
        return (
          <>
            <CustomTranslation data='XXSPECIFY_MMI_SR_VELO_MAX_DEN' />
          </>
        );
      case "REYNOLD`S NUMBER @ MIN DENSITY":
        return (
          <>
            <CustomTranslation data="XXSPECIFY_MMI_SR_RPE_NO_MIXDEN" />
          </>
        );
      case "REYNOLD`S NUMBER":
            
        return (
          <>
            <CustomTranslation data='XXSPECIFY_MMI_SHR_TBL_REYNOLDS' />
          </>
        );
      case "REYNOLD`S NUMBER @ MAX DENSITY":
        return (
          <>
          <CustomTranslation data='XXSPECIFY_MMI_SR_RPE_NO_MAXDEN' />
          </>
        );
        case "TUBE VELOCITY":
          return(
            <>
            <CustomTranslation data='XXSPECIFY_MMI_SR_TH1_TUBVEL' />
            <br />
            <span className="sub-text-uom">{headers.tube_velocity}</span>
            </>
          );
      default:
        return heading;
    }
  };

  return <>{formatHeading(heading)}</>;
};
const mapStateToProps = (state) => ({
  compare_sizing_results: state.SizingResults.compare_sizing_results,
  sizing_results_data: state.SizingResults.sizing_results_data,
});

export default connect(mapStateToProps, {})(ShowMoreRatesTable);
