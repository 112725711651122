import React, { useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import '../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import {
  selectBasicPage,
  selectTechnology,
  defaultTechnology,
  equipmentSelection,
  toggleFlanged,
  toggleWafer,
  toggleReducer,
  toggleHygienicApplication,
  toggleHighProcessNoiseSlurry,
  toggleHygienic,
  toggleDisplayAllSensorsNoFilters,
  toggleDual,
  toggleQuad,
  tooglePressure,
  lineSize,
  processLineSize,
  scheduleUnits,
  productFamily,
  triggerLevel,
  fluidState,
  fluidSource,
  pickFluidsFromDB,
  conductivityDropdown,
  fluidService,
} from '../redux/actions';
import { CustomRadiobutton } from '../components/CustomRadiobutton/CustomRadiobutton';
import CustomTranslation from '../components/CustomTranslation/CustomTranslation';
import CustomTranslationText from '../components/CustomTranslation/CustomTranslationText';

const SelectTechnology = (props) => {
  const { get_Sizing_formatted_Data } = props;

  const selectedTechnology = (tech) => {
    props.selectTechnology(tech);
  };

  const equipmentSelection = (tech) => {
    if (
      (tech === "Coriolis" || tech === "Density" || tech === "Viscosity") &&
      props.equipment_selection !== "Sensor Only / MVDSolo"
    ) {
      props.equipmentSelection(
        `${props.select_technology} Flow Meter (Includes Sensor and Transmitter)`
      );
    } else if (tech === "Magnetic" && props.equipment_selection !== "Sensor") {
      props.equipmentSelection("Magmeter System");
    } else if (tech === "Vortex" || !tech) {
      props.equipmentSelection("");
    }
  };

  const isDisabled = () => {
    if (props.checkBoxed.flow && !props.checkBoxed.density) {
      return ["Viscosity", "Density"];
    } else if (props.checkBoxed.flow && props.checkBoxed.density) {
      return ["Density", "Magnetic", "Viscosity", "Vortex"];
    } else if (
      !props.checkBoxed.flow &&
      props.checkBoxed.density &&
      !props.checkBoxed.viscosity
    ) {
      return ["Coriolis", "Magnetic", "Viscosity", "Vortex"];
    } else if (props.checkBoxed.viscosity) {
      return ["Coriolis", "Density", "Magnetic", "Vortex"];
    } else if (
      !props.checkBoxed.flow &&
      !props.checkBoxed.density &&
      !props.checkBoxed.viscosity
    ) {
      return ["Coriolis", "Density", "Magnetic", "Viscosity", "Vortex"];
    }
  };


  const defaultChecked = (defaultValue) => {
    if (!props.default_technology && props.select_technology !== defaultValue) {
      return false;
    }
    if (
      (props.checkBoxed.flow &&
        !props.checkBoxed.density &&
        defaultValue === "Coriolis") ||
      (props.checkBoxed.flow &&
        props.checkBoxed.density &&
        defaultValue === "Coriolis")
    ) {
      selectedTechnology(defaultValue);
      equipmentSelection(defaultValue);
      return true;
    } else if (
      !props.checkBoxed.flow &&
      props.checkBoxed.density &&
      !props.checkBoxed.viscosity &&
      defaultValue === "Density"
    ) {
      selectedTechnology(defaultValue);
      equipmentSelection(defaultValue);
      return true;
    } else if (props.checkBoxed.viscosity && defaultValue === "Viscosity") {
      selectedTechnology(defaultValue);
      equipmentSelection(defaultValue);
      return true;
    } else if (
      !props.checkBoxed.flow &&
      !props.checkBoxed.density &&
      !props.checkBoxed.viscosity
    ) {
      selectedTechnology("");
      equipmentSelection("");
      return false;
    } else {
      return false;
    }
  };

  const applicationRequirementsAutoChecked = (tech) => {
    if (tech === "Vortex") {
      props.toggleFlanged(true);
      props.toggleWafer(true);
      props.toggleReducer(true);
      props.toggleHygienicApplication(false);
      props.toggleHighProcessNoiseSlurry(false);
      props.toggleHygienic(false);
      props.toggleDisplayAllSensorsNoFilters(false);
    } else if (tech === "Magnetic") {
      props.toggleFlanged(false);
      props.toggleWafer(false);
      props.toggleReducer(false);
      props.toggleQuad(false);
      props.toggleDual(false);
      props.toggleHygienic(false);
      props.toggleDisplayAllSensorsNoFilters(false);
      props.tooglePressure(false);
    } else {
      props.toggleFlanged(false);
      props.toggleWafer(false);
      props.toggleReducer(false);
      props.toggleQuad(false);
      props.toggleDual(false);
      props.toggleHygienicApplication(false);
      props.toggleHighProcessNoiseSlurry(false);
      props.tooglePressure(false);
    }
  };

  // TODO: this is a duplicate of same function in MeasurementType, need to combine
  const clearDropdowns = () => {
    if (!props.flow) {
      props.lineSize("");
      props.processLineSize("Unknown*");
      props.scheduleUnits("");
      props.productFamily("All Sensors");
      props.triggerLevel("4");
    }
  };

  const isChecked = (event) => {
    // event.persist(); // TBD SyntheticEvent
    props.defaultTechnology(false);
    equipmentSelection(event.target.id);
    selectedTechnology(event.target.id);
    applicationRequirementsAutoChecked(event.target.id);
    magneticFluidSelection(event.target.id);
    clearDropdowns();
  };

  const magneticFluidSelection = (checked) => {
    if (checked === "Magnetic") {
      // default selections for magnetic
      props.conductivityDropdown("Greater Than 100 Microsiemens/Cm");
      props.fluidService("Normal (0-39 Ft/Sec Or 0-12 M/Sec)");
    } else {
      props.conductivityDropdown("");
      props.fluidService("");
    }
  };

  const radioInputButtons = (id, label) => {
    let checked;
    if (id === "Magnetic" || id === "Vortex") {
      checked = props.select_technology === id;
    } else {
      checked = defaultChecked(id);
    }

    return (
      <>
      
          <CustomRadiobutton
            type='radio'
            name='technology'
            id={id}
            onChange={(e) => isChecked(e)}
            checked={checked}
            disabled={isDisabled().includes(id)}
            className='custom-radiobutton'
            text={<CustomTranslation data={label} />}
          />

      </>
    );
  };

  return (
    <div className='select-technology'>
      <div className='title'>
        <CustomTranslation data='XXSPECIFY_MMI_MC_PANEL2_ST' />
      </div>
      <Container className='selection-container'>
        <Row>
          <Col md={12}lg={4} sm={12}>
          {radioInputButtons("Coriolis", "XXSPECIFY_MMI_MC_RDIO_CORIOLIS")}</Col>
          <Col md={12}lg={4}  sm={12}>{radioInputButtons("Density", "XXSPECIFY_MMI_MC_TXT_BRCDEN")}</Col>
          <Col md={12} lg={4} sm={12}>{radioInputButtons("Magnetic", "XXSPECIFY_MMI_MC_RDIO_MAGNETIC")}
          </Col>
        </Row>
        <Row>
          <Col md={12}lg={4}  sm={12}>
          {radioInputButtons("Viscosity", "XXSPECIFY_MMI_MC_RDIO_VSY")}</Col>
          <Col md={12}lg={4}  sm={12}> {radioInputButtons("Vortex", "XXSPECIFY_MMI_MC_RDIO_VORTEX")}
          </Col>
        </Row>
        <Row> 
          <Col md={12} lg={12} sm={12} className='row mt-2 tech-guide'>
            <a href={ CustomTranslationText('XXSPECIFY_MMI_TECH_GUIDE_DOC', props)} target="_blank" >
            <CustomTranslation data='XXSPECIFY_MMI_TECH_GUIDE_NAME'  /> </a> 
          </Col> 
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  select_technology: state.MeasurementType.select_technology,
  default_technology: state.MeasurementType.default_technology,
  equipment_selection: state.EquipmentSelection.equipment_selection,
  get_sizing_data: state.MySizings.get_sizing_data,
  get_Sizing_formatted_Data: state.MySizings.get_Sizing_formatted_Data,
  user_language: state.AppHeader.user_language,
  query_params: state.Preferences.query_params,
  translation_data: state.TranslationData.translation_data,
});

export default connect(mapStateToProps, {
  selectTechnology,
  defaultTechnology,
  equipmentSelection,
  toggleFlanged,
  toggleWafer,
  toggleReducer,
  toggleHygienicApplication,
  toggleHighProcessNoiseSlurry,
  toggleHygienic,
  toggleDisplayAllSensorsNoFilters,
  toggleDual,
  toggleQuad,
  tooglePressure,
  lineSize,
  processLineSize,
  scheduleUnits,
  productFamily,
  triggerLevel,
  fluidState,
  fluidSource,
  pickFluidsFromDB,
  conductivityDropdown,
  fluidService,
  selectBasicPage
})(SelectTechnology);
