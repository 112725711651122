import React from 'react';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';


const SizingResultsCorrosionGuide = () => {

  const openLink = () => {
    window.open('https://www.emerson.com/documents/automation/product-data-sheet-flow-density-viscosity-meters-corrosion-guide-micro-motion-en-62186.pdf',
      'CorrosionGuide', 'width=900,height=600'
    );
  }

  return (
    <>
      <CustomButton
        classNames="btn btn-corrosion-guide btn-white-fpa"
        label={<CustomTranslation data='XXSPECIFY_MMI_MC_BTN_GUIDE' /> }
        onClick={() => openLink()}
        size="lg"
      />
      <p className="corrosion-link-text">
        * <CustomTranslation data='XXSPECIFY_MMI_SR_LBL_COR_GUD' />
      </p>
    </>
  )
}

export default SizingResultsCorrosionGuide;
