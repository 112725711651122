import React, {useEffect} from 'react';
import {
  Col,
  Row,OverlayTrigger,Tooltip
} from 'react-bootstrap';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Alert from '../../images/Alert.png';
import { connect } from 'react-redux';
import FormatOppConditionsErrorMessage from '../../components/CustomTooltip/FormatOppConditionsErrorMessage';
import {
  sizingApplication,
  sizingService,
  projectName,
  ApplicationNameError,
  sizingResultsClicked
} from '../../redux/actions';
import CustomTranslationText from '../../components/CustomTranslation/CustomTranslationText';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';


const SizingResultsHeadingFields = (props) => {
 
  useEffect(() => {
   
    if (Object.keys(props.get_sizing_data).length > 0) {
      let data = props.get_sizing_data[0];
      props.sizingApplication(data.appl)
      props.sizingService(data.service);
      props.projectName(data.attribute1);
      props.sizingResultsClicked(false);
    }else{
      props.sizingApplication(document.getElementById('wc_tagName')?
      document.getElementById('wc_tagName').value !== "" ? document.getElementById('wc_tagName').value : props.application : props.application)
    }

  }, []);
  const handleBlur = (event) => {
    
 
        
      switch (event.target.id) {



        case 'sizing-results-application':
          if(event.target.value.length>0){
            props.ApplicationNameError(false)
          }
          break;
          default:
      }
    
  };

 const  sizingResultsInputField = (label, id, labelClassName, fieldClassName, size, disabled, borderRequired, borderError) => {
    let onchange;
    let value;
    const borderOutline =
      borderError && !disabled ? '2px solid #d31245' :
        borderRequired && !disabled ? '2px solid #00aa7e' : '2px solid #c4ced1';
    switch (label) {
      case CustomTranslationText('XXSPECIFY_MMI_SR_LBL_APLCTN', props).toUpperCase(): onchange = handleApplicationFieldChange; value = props.application; break;
      case CustomTranslationText('XXSPECIFY_MMI_MC_LBL_SERVICE', props).toUpperCase(): onchange = handleServiceFieldChange; value = props.sizing_service; break;
      case CustomTranslationText('XXSPECIFY_MMI_PR_PROJ_NAME', props).toUpperCase(): onchange = handleProjectNameFieldChange; value = props.project_name; break;
      default: return;
    }
    return (
      <>   {borderError ? ( <OverlayTrigger
      trigger={['hover', 'focus']}
      overlay={
        <Tooltip id={`tooltip-error-${id}`} className='error-tooltip'>
        <>
              <span className="left alert-icon">
                <img
                  src={Alert}
                  width={12}
                  height={12}
                  alt='Alert'
                />
              </span>
              <div className="title">
                <span><CustomTranslation data="XXSPECIFY_MMI_PR_ENT_MAND_FLD" /></span>
              </div>&nbsp;
              <div className="left">
                <p className='fontsize'>
                 {CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG1', props)}
                  <br />
                  
                  <br />
                  
                </p>
              </div>
            </>
        </Tooltip>
      }
    >
   
          <Col xl={2} md={2} sm={3} className='sizing-results-inputs-col'>
          <label className={labelClassName} htmlFor={id}>{label}</label>
          <input
            className={fieldClassName}
            type="text"
            id={id}
            value={value}
            onBlur={(e) => handleBlur(e)}
            onChange={onchange}
            style={{ border: borderOutline }}
          />
        </Col>
        </OverlayTrigger>):( <Col xl={2} md={2} sm={3} className='sizing-results-inputs-col'>
          <label className={labelClassName} htmlFor={id}>{label}</label>
          <input
            className={fieldClassName}
            type="text"
            id={id}
            value={value}
            onBlur={(e) => handleBlur(e)}
            onChange={onchange}
            style={{ border: borderOutline }}
          />
        </Col>)}
      </>
    );
  };

 const handleApplicationFieldChange = (event) => {props.sizingApplication(event.target.value)};
 const handleServiceFieldChange = event => props.sizingService(event.target.value);
 const handleProjectNameFieldChange = event => props.projectName(event.target.value);

    return (
      <Row className="heading-fields">
        {sizingResultsInputField(CustomTranslationText('XXSPECIFY_MMI_SR_LBL_APLCTN', props).toUpperCase(), 'sizing-results-application', 'sizing-results-field-label', 'sizing-results-field', 'sm', false, true,props.aplication_name_error)}
        {sizingResultsInputField(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_SERVICE', props).toUpperCase(), 'sizing-results-service', 'sizing-results-field-label', 'sizing-results-field', 'sm', false, false)}
        {sizingResultsInputField(CustomTranslationText('XXSPECIFY_MMI_PR_PROJ_NAME', props).toUpperCase(), 'sizing-results-project', 'sizing-results-field-label', 'sizing-results-field', 'sm', false, false)}
      </Row>
    )
  
}

const mapStateToProps = (state)=> ({
  application: state.SizingResults.application,
  sizing_service: state.SizingResults.sizing_service,
  sensor_tag: state.SizingResults.sensor_tag,
  transmitter_tag: state.SizingResults.transmitter_tag,
  project_name: state.SizingResults.project_name,
  aplication_name_error: state.SizingResultErrorMessage.aplication_name_error,
  translation_data: state.TranslationData.translation_data,
  get_sizing_data: state.MySizings.get_sizing_data,
});

export default connect(
  mapStateToProps,
  {
    sizingApplication,
    sizingService,
    projectName,
    ApplicationNameError,
    sizingResultsClicked
  }
)(SizingResultsHeadingFields);
