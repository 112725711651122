import { useEffect, useState } from 'react';
import axios from 'axios';
import { CALCULATIONS } from '../../constants/constants';

const CalculationCall = (props, calculationsCall, gasLawUserUpdate) => {
    const [Results, setResults] = useState('');
    const languageCode = props.user_language && props.user_language.value
    ? props.user_language.value
    : 'US';
    const getCompositionValues = (value,components) => {
        let return_value = components.filter(data => {
         return data.idValue === value
        });
        return return_value.length>0?return_value[0].mole:'';
      }; 
    useEffect(() => {
        
        let data = {};
        if(!props.clear_all_feild){
      data.flow_rate_min = props.min_flow_rate
            ? parseFloat(props.min_flow_rate)
            : '';
        data.flow_rate_norm = props.normal_flow_rate
            ? parseFloat(props.normal_flow_rate)
            : '';
        data.flow_rate_max = props.max_flow_rate
            ? parseFloat(props.max_flow_rate)
            : '';
        data.flow_rate_full = props.full_flow_rate
            ? parseFloat(props.full_flow_rate)
            : '';
    
        data.line_pressure_min = props.min_line_pressure_value !== ''? parseFloat(props.min_line_pressure_value): props.min_line_pressure
            ? parseFloat(props.min_line_pressure)
            : '';
        data.line_pressure_norm = props.normal_line_pressure_value !==''? parseFloat(props.normal_line_pressure_value): props.normal_line_pressure
            ? parseFloat(props.normal_line_pressure)
            : '';
        data.line_pressure_max = props.max_line_pressure_value !==''? parseFloat(props.max_line_pressure_value): props.max_line_pressure
            ? parseFloat(props.max_line_pressure)
            : '';
            
        data.line_pressure_fsd = props.full_line_pressure_value !==''? parseFloat(props.full_line_pressure_value):  props.full_line_pressure
            ? parseFloat(props.full_line_pressure)
            : '';
    
        data.temperature_min = props.min_temperature
            ? parseFloat(props.min_temperature)
            : '';
        data.temperature_norm = props.normal_temperature
            ? parseFloat(props.normal_temperature)
            : '';
        data.temperature_max = props.max_temperature
            ? parseFloat(props.max_temperature)
            : '';
    
        data.ambient_temp_min = props.min_ambient
            ? parseFloat(props.min_ambient)
            : '';
        data.ambient_temp_norm = props.normal_ambient
            ? parseFloat(props.normal_ambient)
            : '';
        data.ambient_temp_max = props.max_ambient
            ? parseFloat(props.max_ambient)
            : '';
        data.temperature_fsd = props.full_temperature
            ? parseFloat(props.full_temperature)
            : '';
        
        let flag=props.fluid_source.toLowerCase() === 'natural gas'?data.errors=[]:''
        data.density_variation_flag = props.calculate_density_variation;
        data.calculation = props.fluid_state.toLowerCase() === 'gas' && props.fluid_source.toLowerCase() === 'custom'?
         'customgas' :props.fluid_source.toLowerCase() === 'natural gas'?'naturalgas': props.fluid_state.toLowerCase() ;
        data.fluid_name =  props.fluid_state.toLowerCase() !=='steam'? props.fluid_source.toLowerCase() === 'custom'?
         props.custom_fluid_name.toLowerCase() :props.fluid_source.toLowerCase() === 'natural gas'?props.pick_gas_from_db: props.pick_fluids_from_db.toLowerCase(): 'water';
         data.coolprops_flag = props.pick_fluids_coolprops_flag === !undefined ?  props.pick_fluids_coolprops_flag : false ;
        data.coolprop_visc= props.coolprops_visc === !undefined ?  props.coolprops_visc : false ;
        data.flow_rate_units = props.fluid_state.toLowerCase()==="gas"?props.units_gas_flow_rate?props.units_gas_flow_rate.key:'KG/HR':props.units_flow_rate.key;
        data.fluid_source = props.fluid_state.toLowerCase() !=='steam'? props.fluid_source.toUpperCase(): 'DATABASE';
        data.line_pressure_units = props.units_line_pressure.key;
        data.temperature_units = props.units_temperature.key;
        data.technology = props.select_technology;
        data.ambient_temp_units = props.units_ambient.key;
        data.sonic_velocity = props.sonic_velocity ? parseFloat(props.sonic_velocity) :'';
        data.sonic_velocity_units = props.units_sonic_velocity.key ? props.units_sonic_velocity.key : 'M/SEC';
        data.density_units =  props.units_density_heading ==="Density"?props.units_density? props.units_density.key: '' :  
        props.fluid_state.toLowerCase() === 'gas' && props.fluid_source.toLowerCase() === 'custom'?
        props.units_gas_density_sg ? props.units_gas_density_sg.key:'':'';
        data.viscosity_units = props.units_viscosity ?props.units_viscosity.key:'';
        data.vapor_pressure_units = props.units_vapor ?props.units_vapor.key:'';
        data.language =props.query_params.language?props.query_params.language:languageCode;
        data.is_chk_high_pressure = props.pressure;
        data.viscosity_min_custom = props.min_viscosity&&props.fluid_source==="Custom"
            ? parseFloat(props.min_viscosity)
            : '';
        data.viscosity_norm_custom = props.normal_viscosity && parseFloat(props.normal_viscosity) > 0 &&props.fluid_source==="Custom"
            ? parseFloat(props.normal_viscosity)
            : '';
        data.viscosity_max_custom = props.max_viscosity&&props.fluid_source==="Custom"
            ? parseFloat(props.max_viscosity)
            : '';
        if (props.fluid_state.toLowerCase() === 'gas' && props.fluid_source.toLowerCase() === 'custom' && props.units_density_heading ==="Density" ) {
            data.density_min = props.min_density !== '' ? parseFloat(props.min_density) : '';
            data.density_norm = props.normal_density !== ''  ? parseFloat(props.normal_density) : '';
            data.density_max = props.max_density !== '' ? parseFloat(props.max_density) : '';
            data.compressibility = '';
            if (gasLawUserUpdate !== 'CLEAR' && gasLawUserUpdate !== undefined && gasLawUserUpdate !== '') {
                data.gasLawUserUpdate = gasLawUserUpdate;
            }

        }else if (props.fluid_state.toLowerCase() === 'gas' && props.fluid_source.toLowerCase() === 'custom' && props.units_density_heading ==="Molecular Weight" ) {
            data.molecular_weight= props.molecular_weight !==''? parseFloat(props.molecular_weight):'';
            data.compressibility = props.compressibility !== ''? parseFloat(props.compressibility):'';
            data.base_compressibility = props.base_compressibility !=="" ? parseFloat(props.base_compressibility):'';
            data.specific_gravity='';

            if (gasLawUserUpdate !== 'CLEAR' && gasLawUserUpdate !== undefined && gasLawUserUpdate !== '') {
                data.gasLawUserUpdate = gasLawUserUpdate;
            }

        }else if(props.fluid_state.toLowerCase() === 'gas' && props.fluid_source.toLowerCase() === 'custom' && props.units_density_heading ==="Specific Gravity" ){
            data.specific_gravity= props.specific_gravity !==''? parseFloat(props.specific_gravity):'';
            data.compressibility = props.compressibility !== ''? parseFloat(props.compressibility):'';
            data.base_compressibility = props.base_compressibility !=="" ? parseFloat(props.base_compressibility):'';
            data.molecular_weight="";
        }else if((props.fluid_state.toLowerCase() === 'liquid'||props.fluid_state.toLowerCase() === 'slurry') && props.fluid_source.toLowerCase() === 'custom' 
        && props.units_density_heading ==="Specific Gravity"){
            data.specific_gravity= props.specific_gravity !==''? parseFloat(props.specific_gravity):'';
            data.density_units = props.units_density? props.units_density.key: ''
        }
        data.composition_method =  props.composition_method;
        if (props.fluid_state.toLowerCase() === 'gas' &&props.fluid_source.toLowerCase()==='natural gas'&&props.composition_method!==''){
           if(props.composition_method ==='AGA Report No.8 Detail Characterization Method'||props.composition_method ==='ISO 12213, Molar Composition Method'){
            data.natural_gas_composition={
                fluid_name: props.natural_gas_composition_name,
                molecular_weight: props.molecular_weight,
                methane: getCompositionValues('methane',props.selected_components),
                nitrogen: getCompositionValues('nitrogen',props.selected_components),
                carbon_dioxide: getCompositionValues('carbon_dioxide',props.selected_components),
                ethane: getCompositionValues('ethane',props.selected_components),
                propane: getCompositionValues('propane',props.selected_components),
                water: getCompositionValues('water',props.selected_components),
                hydrogen_sulfide: getCompositionValues('hydrogen_sulfide',props.selected_components),
                hydrogen: getCompositionValues('hydrogen',props.selected_components),
                carbon_monoxide: getCompositionValues('carbon_monoxide',props.selected_components),
                oxygen: getCompositionValues('oxygen',props.selected_components),
                butane: getCompositionValues('butane',props.selected_components),
                n_butane: getCompositionValues(' n_butane',props.selected_components),
                pentane: getCompositionValues('pentane',props.selected_components),
                n_pentane: getCompositionValues('n_pentane',props.selected_components),
                n_hexane: getCompositionValues('n_hexane',props.selected_components),
                n_heptane: getCompositionValues('n_heptane',props.selected_components),
                n_octane: getCompositionValues('n_octane',props.selected_components),
                n_nonane: getCompositionValues('n_nonane',props.selected_components),
                n_decane: getCompositionValues('n_decane',props.selected_components),
                helium: getCompositionValues('helium',props.selected_components),
                argon: getCompositionValues('argon',props.selected_components),

            }
            data.custom_natural_gas_composition=true
           }else{
            data.natural_gas_composition={
                relative_density: props.relative_density,
                heating_value: props.heating_value,
                heating_value_dropdown: props.heating_value_dropdown,
                fluid_name: props.natural_gas_composition_name,
                molecular_weight: props.molecular_weight,
                methane: getCompositionValues('methane',props.selected_components),
                nitrogen: props.mole_percent_N2,
                carbon_dioxide: props.mole_percent_CO2,
                ethane: getCompositionValues('ethane',props.selected_components),
                propane: getCompositionValues('propane',props.selected_components),
                water: getCompositionValues('water',props.selected_components),
                hydrogen_sulfide: getCompositionValues('hydrogen_sulfide',props.selected_components),
                hydrogen: props.mole_percent_H2,
                carbon_monoxide: props.mole_percent_CO,
                oxygen: getCompositionValues('oxygen',props.selected_components),
                butane: getCompositionValues('butane',props.selected_components),
                n_butane: getCompositionValues(' n_butane',props.selected_components),
                pentane: getCompositionValues('pentane',props.selected_components),
                n_pentane: getCompositionValues('n_pentane',props.selected_components),
                n_hexane: getCompositionValues('n_hexane',props.selected_components),
                n_heptane: getCompositionValues('n_heptane',props.selected_components),
                n_octane: getCompositionValues('n_octane',props.selected_components),
                n_nonane: getCompositionValues('n_nonane',props.selected_components),
                n_decane: getCompositionValues('n_decane',props.selected_components),
                helium: getCompositionValues('helium',props.selected_components),
                argon: getCompositionValues('argon',props.selected_components),

            }
            data.custom_natural_gas_composition=true
           }
            
        }
        if (props.fluid_state.toLowerCase() === 'steam') {
            data.steam_pressure_temp =props.steam_pressure_temp==='Based on Saturated Temperature'?"XXSPECIFY_MMI_MC_RDIO_STEMP"
            :props.steam_pressure_temp==='Based on Saturated Pressure'?"XXSPECIFY_MMI_MC_RDIO_SPRES":
            "XXSPECIFY_MMI_MC_RDIO_SUPERHEA"
        }
        //data.viscosity_units = 'KG/M.HR';
        // if (props.base_reference_conditions) {
            data.ref_temperature = props.gas_temperature
            ? parseFloat(props.gas_temperature)
            : '';
            data.ref_temperature_units =
            props.units_gas_temperature !== undefined
                ? props.units_gas_temperature.key
                : '';
            data.ref_pressure = props.gas_pressure
            ? parseFloat(props.gas_pressure)
            : '';
            data.ref_pressure_units =
            props.units_gas_pressure !== undefined ? props.units_gas_pressure.key : '';
            data.ref_density = props.gas_density ? parseFloat(props.gas_density) : '';
            data.ref_density_units =
            props.units_gas_density !== undefined ? props.units_gas_density.key : '';
        //}
        
      if (calculationsCall) {
        const fetchData =() => {
          axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}${CALCULATIONS}`,
            headers: {
              'Content-Type': 'application/json',
            },
            xsrfHeaderName: 'X-CSRFToken',
            xsrfCookieName: 'csrftoken',
            data: data,
          })
            .then((response) => {
             setResults(response.data.data)
            
            })
            .catch((error) => {
              console.log(error);
             
            })        
        };
    
            fetchData()
        }}
    },[calculationsCall, props.normal_temperature_value])

   return Results
    
    
  

  
};

export default CalculationCall;