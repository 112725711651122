import React from 'react';
import {
  Container,
  Col,
  Row
} from 'react-bootstrap';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import MeasurementType from '../MeasurementType';
import RightNavList from './RightNavList';
import FooterNavPanel from './FooterNavPanel';
import EquipmentSelection from '../EquipmentSelection';
import { connect } from 'react-redux';
import ApplicationRequirements from '../ApplicationRequirements';
import FluidSelection from '../FluidSelection/FluidSelection';
import ProcessVariables from '../ProcessVariables/ProcessVariables';


const BasicPage = props => {
  const renderSelectedPanel = () => {
    switch (props.basic_page) {
      case 'measurementType':
        return <MeasurementType />;
      case 'equipmentSelection':
        return <EquipmentSelection />;
      case 'applicationRequirements':
        return <ApplicationRequirements />;
      case 'fluidSelection':
        return <FluidSelection />
      case 'processVariables':
        return <ProcessVariables />;
      default:
        return <MeasurementType />;
    }
  };

  return (
    <div className='basic-page'>
      <Container fluid={true}>
        <Row>
          <Col xl={8} lg={8} md={12}>
            {renderSelectedPanel()}
          </Col>
          <Col xl={4} lg={4} md={12} className='right-nav-column'>
            <RightNavList />
          </Col>
        </Row>
        <Row>
          <FooterNavPanel />
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = state => ({
  basic_page: state.BasicPageRightNavList.basic_page
});

export default connect(
  mapStateToProps,
  {}
)(BasicPage);
