import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import './input.scss';

export const CustomInputFieldNavPages = props => {
  const { name, id, onChange, value, onInput, disabled, borderOutlined, borderRequired, borderError } = props;

  const borderOutline =
    borderError && !disabled ? '2px solid #d31245' :
      borderRequired && !disabled ? '2px solid #00aa7e' :
        borderOutlined && !disabled ? '2px solid #c4ced1' : 'none';

  return (
    <>
      <InputGroup
        size="lg"
        className={name + " input-group input-group-lg"}
      >
        <FormControl
          className={name + " input-field"}
          disabled={disabled}
          style={{ border: borderOutline }}
          id={id}
          onChange={onChange}
          value={value}
          onInput={onInput}
        />
      </InputGroup>
    </>
  );
};
