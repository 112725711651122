import React, { useEffect, useState } from 'react';
import { Row,Dropdown,Modal } from 'react-bootstrap';
import '../../styles/App.scss';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BsChevronDoubleLeft, BsGearFill } from 'react-icons/bs';
import { useTable, useSortBy,useRowSelect } from 'react-table';
import { GET_SIZING } from '../../constants/constants';
import { CustomDropdownMySizings } from '../../components/CustomDropdown/CustomDropdownMySizings';
import { advancedLayout, getSizingData } from '../../redux/actions';
import CustomTranslationText from '../../components/CustomTranslation/CustomTranslationText';
import { Link } from 'react-router-dom';
import axios from 'axios';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';
import { CustomButton } from '../../components/CustomButton/CustomButton';
const MySizingsSearchResults = props => {
  const [mySizingResultsTable, setMySizingResultsTable] = useState('');
  const { clearMySizingsTable, results } = props;
  const insertColumn = (arr, index, newItem) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index)
  ];
  useEffect(() => {
    const mySizingResults = results ? results.map(item => {
      return newRow(item);
    }) : [];
    setMySizingResultsTable(mySizingResults);
  }, [results]);

  useEffect(() => {
    if (clearMySizingsTable) {
      setMySizingResultsTable('');
    }
  }, [clearMySizingsTable]);

  const handleMySizingsTimeframe = (key) =>{

  }
    //setMySizingsTimeframe(sizingsValues[key ? key : 0]);

  //TBD Hard coded values 
  const handleMySizing = (item) => {
    const data = {
      "header_id": 2455234,
      "line_id": 311175829,
      "sizing_header_id": 941318
    };
    props.setGetSizingRowInfo(data);
  }
  const dropdowns = (
    id,
    label,
    chosenOption,
    dropdownArray,
    eventHandler,
    width,
    required,
    resultsLabel
  ) => {
    const labelCss = resultsLabel ? '42px' : '6px';
    return (
      <CustomDropdownMySizings
        id={id}
        label={label}
        dropdownArray={dropdownArray}
        eventHandler={eventHandler}
        chosenOption={chosenOption}
        width={width}
        required={required}
        labelCss={labelCss}
      />
    );
  };
  const cellClick = (row) => {
    const data = {
      "transaction_id": row.values.transaction_id
    };
    props.setGetSizingRowInfo(data);
  };

  const copyClick = (row) => {
    const data = {
      "transaction_id": row.values.transaction_id,
      "email_id":props.email
        
    }
    const fetchData = async () => {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/mmipa/copy_sizing/`,
        headers: {
          'Content-Type': 'application/json',
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        data: data,
      })
        .then(function (response) {
          props.setTransactionId(response.data.Data.pa_reference_id)
          props.setShow(true);
        })
        .catch(function (error) {
          console.log('error', error);
        });
    };
    fetchData();
    //props.setGetSizingRowInfo(data);
  };
  const newRow = item => {

    if (item) {
      const dateValue = item.creation_date ? new Date(Date.parse(item.creation_date)) : '';
      const formattedDate = dateValue ? [dateValue.getFullYear(), `${dateValue.getMonth() + 1}`.padStart(2, '0'), `${dateValue.getDate()}`.padStart(2, '0')].join('-') : '';
      return {
        appl: item.appl,
        created_by: item.created_by,
        creation_date: item.creation_date,
        fluid: item.fluid,
        last_update_date: item.last_update_date,
        line_id: item.line_id,
        line_number: item.line_number,
        model_name: item.model_name,
        sizing_header_id: item.transaction_number,
        transaction_id: item.transaction_id,
        //actions: createButton(item)
      }
    }
  }

  function Table({ columns, data }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      toggleAllRowsSelected,
      rows,
      prepareRow,
    } = useTable(
      {
        columns,
        data,
      },
      useSortBy,
      hooks => {
        hooks.visibleColumns.push(columns => insertColumn(columns, 8, ...[
          {
            id: 'actions',
            Header: CustomTranslationText('XXSPECIFY_MMI_PR_ACT', props),
            Cell: ({ row }) => (
              <div>
                
                <Dropdown className="d-inline mx-2 MySizingInner">
        <Dropdown.Toggle id="dropdown-autoclose-true">
        <BsGearFill />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => copyClick(row)}><CustomTranslation data="XXSPECIFY_MMI_LBL_COPY" /></Dropdown.Item>
          <Dropdown.Item href="#">Generate Report</Dropdown.Item>
          
        </Dropdown.Menu>
      </Dropdown>
                {/* <button onClick={() => cellClick(row)} className="MySizingInner"><BsGearFill /><span className="MySizingBtn">resizing</span></button> */}
                
              </div>
            ),
          }
        ], ...columns))
        }
    );

    return (
      <>
 
        <table className="my-sizings-results-table" {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <TableHeaderCell heading={column.render('Header')} column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="table-body" {...getTableBodyProps()}>
            {rows.map(
              (row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        //<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                     cell.column.id === 'transaction_id'?
                        <td {...cell.getCellProps()} ><Link  onClick={() => cellClick(row)} >{cell.render('Cell')}</Link></td>:
                        <td {...cell.getCellProps()} >{cell.render('Cell')}</td>

                      )
                    })}
                  </tr>
                )
              }
            )}
          </tbody>
        </table>
      </>
    );
  }

  const columns = React.useMemo(
    () => [
      {
        Header: CustomTranslationText('XXSPECIFY_MMI_ID_AOL_REF', props),
        accessor: 'transaction_id',
      },
      {
        Header: CustomTranslationText('XXSPECIFY_MMI_PR_AOL_CRTD_BY', props),
        accessor: 'created_by',
      },
      {
        Header: CustomTranslationText('XXSPECIFY_MMI_PR_CRTD_DATE', props),
        accessor: 'creation_date',
      },
      {
        Header: CustomTranslationText('XXSPECIFY_MMI_PR_BASE_MDL', props),
        accessor: 'model_name',
      },
      {
        Header: CustomTranslationText('XXSPECIFY_MMI_PR_AOL_APP_NAME', props),
        accessor: 'appl',
      },
      {
        Header: CustomTranslationText('XXSPECIFY_MMI_PR_FLUID_NAME', props),
        accessor: 'fluid',
      },
      {
        Header: CustomTranslationText('XXSPECIFY_MMI_PR_QUOTE_ODR', props),
        accessor: 'sizing_header_id',
      },
      // {
      //   Header: CustomTranslationText('XXSPECIFY_MMI_PR_AOL_LINE_NO', props),
      //   accessor: 'line_id',
      // },
      // {
      //   Header: 'Actions',
      //   accessor: 'actions',
      // },
    ],
    []
  );

  const mySizingTable = mySizingResultsTable ? <Table columns={columns} data={mySizingResultsTable} />
    : columns ? <Table columns={columns} data={[]} />
      : <span />;

  return (
    <>
      <h5 className="search-results-title">{CustomTranslationText('XXSPECIFY_MMI_PR_SRCH_RESULTS', props)}</h5>
      <div className="actions-tool-text">{CustomTranslationText('XXSPECIFY_MMI_PR_ACT_TL', props)}</div>
      <div className="results-section">
        <Row>
          {mySizingTable}
        </Row>
      </div>
    </>
  );
};


const TableHeaderCell = ({ heading, column }) => {
  const arrow = column.isSorted
    ? column.isSortedDesc
      ? 'icon-down'
      : 'icon-up'
    : 'icon-up-down';

  return (
    <>
      <span className="wrap left">{heading}</span>
      {heading !== 'Actions' ? <span className={`right ${arrow}`} /> : <span />}
    </>
  )
}

const mapStateToProps = state => ({
  get_sizing_data: state.MySizings.get_sizing_data,
  translation_data: state.TranslationData.translation_data,
  email:state.AppHeader.email,
});

export default connect(mapStateToProps, {
  advancedLayout,
  getSizingData
})(MySizingsSearchResults);
