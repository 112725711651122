import React from 'react';
import { Dropdown } from 'react-bootstrap';
import './dropdown.scss';

export const CustomDropdownMySizings = (props) => {
  const {
    label,
    id,
    chosenOption,
    dropdownArray,
    eventHandler,
    width,
    required,
    labelCss,
  } = props;

  const borderOutline = required ? '2px solid #00aa7e' : 'none';

  return (
    <>
      {label ? (
        <span className='dropdown-title-left' style={{ marginLeft: labelCss }}>
          {label}
        </span>
      ) : (
          <span />
        )}
      <span>
        <Dropdown onSelect={eventHandler}>
          <Dropdown.Toggle
            id={id}
            className={'btn-dropdown-my-sizings'}
            variant={'none'}
            style={{ width: width, border: borderOutline }}
          >
            <span className='dropdown-text'>{chosenOption}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu className='dropdown-menu'>
            {dropdownArray.map((option, index) => (
              <Dropdown.Item
                key={index}
                eventKey={index}
                active={option.toUpperCase() === chosenOption.toUpperCase()}
              >
                {option.toUpperCase()}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </span>
    </>
  );
};
