import React, { useEffect, useState } from 'react';
import { Col, Container, Row, OverlayTrigger, Overlay, Tooltip } from 'react-bootstrap';
import '../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import { CustomRadiobutton } from '../components/CustomRadiobutton/CustomRadiobutton';
import {
  toggleHygienic,
  toggleDisplayAllSensorsNoFilters,
  toggleInLine,
  toggleFlowThroughChamberSlipstream,
  toggleTank,
  toggleHygienicApplication,
  toggleHighProcessNoiseSlurry,
  productFamily,
  productFamilyName,
  lineSize,
  lineSizeError,
  lineSizeLabel,
  processLineSize,
  processLineSizeLabel,
  scheduleDropdown,
  triggerLevel,
  toggleFlanged,
  toggleWafer,
  toggleReducer,
  toggleDual,
  toggleQuad,
  tooglePressure,
  pipeId,
  pipeIdUnits,
  errorMessage,
  selectBasicPage, 
  resizingFlag,
  fluidSelectionSection,
  wettedMaterial,
  wettedMaterialValue, 
  wettedMaterialLabel,
  processLineSizeUnits,
  sisApplication
} from '../redux/actions';
import axios from 'axios';
import CustomCheckbox from '../components/CustomCheckbox';
import { CustomDropdown } from '../components/CustomDropdown/CustomDropdown';
import jsonInputs from '../JSONForTesting/jsonInputs';
import { CustomInputField } from '../components/CustomInputField/CustomInputField';
import CustomTranslation from '../components/CustomTranslation/CustomTranslation';
import CustomTranslationText from '../components/CustomTranslation/CustomTranslationText';
import { CALCULATE_VALIDATE_PIPE_ID, DEFAULT_DECIMAL } from '../constants/constants';
import { decimalPlaceNumeric, fixToDecimalPlaces, reg } from '../UtilityFuntions/decimalFactor';
import ErrorModal from '../components/CustomModals/ErrorModal';


const ApplicationRequirements = props => {
  let inlineDisabled = false;
  let flowThroughDisabled = false;
  let linesizeDisabled = false;
  let tankDisabled = false;
  
  const allSizesVar = CustomTranslationText('XXSPECIFY_MMI_MC_DROPDN_ALSIZS',props);
  const allSensorsLabel = CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG113',props);
  const unknowProcessLineSize = CustomTranslationText('XXSPECIFY_MMI_MC_DROPDN_UNKNOW',props);
  const [productFamilyValue, setProductFamilyValue] = useState([]);
  const [productFamilyDisplay, setProductFamilyDisplay] = useState([]);
  const [productFamilyDisplayValue, setProductFamilyDisplayValue] = useState('All Sensors');
  const [lineSizeDisplay, setLineSizeDisplay] = useState([]);
  const [lineSizeValue, setLineSizeValue] = useState([]);
  const [lineSizeDropdownDisplay, setLineSizeDropdownDisplay] = useState(allSizesVar)
  const [processLineSizeDropdownDisplay, setProcessLineSizeDropdownDisplay] = useState(unknowProcessLineSize);
  const [processLineSizeDisplay, setProcessLineSizeDisplay] = useState([]);
  const [processLineSizeValue, setProcessLineSizeValue] = useState([]);
  const [optionsSchedule, setOptionsSchedule] = useState([]);
  const [decimalPlaces, setDecimalPlaces] = useState(DEFAULT_DECIMAL);
  const [pipeIdValue, setPipeIdValue] = useState('');
  const [callValidateAPI, setCallValidateAPI] = useState(false);
  const [redundancy, setRedundancy] = useState(false);
  const [radiosafety, setRadiosafety] = useState(false);
  const [processLineSizeSelect, setProcessLineSizeSelect] = useState('');
  const [scheduleSelect, setScheduleSelect] = useState('Unknown');
  const [unitSelect, setUnitSelect] = useState('IN');
  const [processLineSizeData, setProcessLineSizeData] = useState([]);
  const [lineSizeResizing, setLineSizeResizing] = useState('')
  const [materialDropdownDisplay, setMaterialDropdownDisplay] = useState('316L')
  const [materialDisplayArray, setMaterialDisplayArray] = useState([])
  const [materialDescriptionArray, setMaterialDescriptionArray] = useState([])
  const [materialValue, setMaterialValue] = useState([]);
  const [materialDefaultIndex, setMaterialDefaultIndex] = useState(0);
  const [displayAllSensors, setDisplayAllSensors] = useState(false);

  const handleClose = () => {
    setDisplayAllSensors(false);
  };

  useEffect(() => {
    setDecimalPlaces(props.saved_preferences ? decimalPlaceNumeric(props.saved_preferences.decimalfactor) : DEFAULT_DECIMAL)
    let indexWettedMaterial = materialValue.findIndex(x=>x===props.saved_preferences?.wetted_material)
    let index = indexWettedMaterial !== -1 ? indexWettedMaterial : 0
    setMaterialDefaultIndex(index)
  }, [props.saved_preferences]);

  useEffect(() => {
    if(materialDisplayArray.length > 0){
    props.wettedMaterial(materialDescriptionArray[materialDefaultIndex])
    props.wettedMaterialValue(materialValue[materialDefaultIndex])
    props.wettedMaterialLabel(materialDisplayArray[materialDefaultIndex])
    setMaterialDropdownDisplay(materialDisplayArray[materialDefaultIndex])
    }
  }, [materialDefaultIndex]);

  const languageCode = props.user_language && props.user_language.value
    ? props.user_language.value
    : 'US';

  ["schedule"].map((x) => x.value);
  const optionsTriggerLevel = jsonInputs.data
    .find((x) => x["trigger_level"])
  ["trigger_level"].map((x) => x.value);
  const optionsUnits = jsonInputs.data
    .find((x) => x["pipe_id_units"])
  ["pipe_id_units"].map((x) => x.label);
  const unitsData = jsonInputs.data
    .find((x) => x["pipe_id_units"])
  ["pipe_id_units"].map((x) => x);
  const dummyDataPipeId = [1.234, 2.345, 3.456, 4.567];

  useEffect(() => {
    if(props.query_params.prdType&&props.query_params.from_page==='PDP'){
      if(props.query_params.prdType ==='VOR'){
        toggleCheckbox(true,'flanged-checkbox');
        toggleCheckbox(true,'reducer-checkbox');
        toggleCheckbox(true,'wafer-checkbox');
      }
    }
  }, [])


  useEffect(() => {
    if (Object.keys(props.get_sizing_data).length>0) {
      let data=props.get_sizing_data[0]
     if (data.coriolis ==='Y'){
      toggleCheckbox(data.hedge==='Y',"hygienic-checkbox")
      toggleCheckbox(data.no_check==='Y',"display-sensors-checkbox") 
     }
    else{
      toggleCheckbox(data.mag_hygienic_appl==='Y',"hygienic-application-checkbox");
      toggleCheckbox(data.mag_highpress_noise==='Y','high-process-noise-checkbox');
      toggleCheckbox(data.dual==='Y','dual-checkbox');
      if(data.density==='Y'){
        toggleCheckbox(data.hedge==='Y',"hygienic-checkbox")
      }
      toggleCheckbox(data.no_check==='Y',"display-sensors-checkbox") 
      toggleCheckbox(data.quad==='Y','quad-checkbox');
      toggleCheckbox(data.flanged_wafer==='Y','flanged-checkbox');
      toggleCheckbox(data.reducer==='Y','reducer-checkbox');
      toggleCheckbox(data.wafer==='Y','wafer-checkbox');
      toggleCheckbox(data.is_chk_high_pressure==='Y', 'pressure-checkbox')
      toggleCheckbox(data.inline==='Y','inline-checkbox');
      toggleCheckbox(data.tank==='Y','tank-checkbox');
      toggleCheckbox(data.slipstream==='Y','flow-through-chamber-checkbox');
    }
    props.sisApplication(data.redundancy==='Y'?'redundancy':data.safety_certified==='Y'?'radio-safety':'')
   
    let index= productFamilyValue.findIndex(x=>x===data.sensor_selection);
    props.productFamily(data.sensor_selection ? data.sensor_selection : 'All Sensors');
    props.productFamilyName(productFamilyDisplay[index !== -1 ? index : 0 ]);
    setProductFamilyDisplayValue(productFamilyDisplay[index !== -1 ? index : 0]);

    let indexWettedMaterial =  materialDisplayArray.findIndex(x=>x===data.wetted_material)
    let indexMaterial = indexWettedMaterial !== -1 ? indexWettedMaterial : 0
    props.wettedMaterial(materialDescriptionArray[indexMaterial])
    props.wettedMaterialValue(materialValue[indexMaterial])
    props.wettedMaterialLabel(materialDisplayArray[indexMaterial])
    setMaterialDropdownDisplay(materialDisplayArray[indexMaterial])
    
    props.lineSize(data.pipeline_size)
    props.processLineSize(data.pipeline_size);
    props.scheduleDropdown(data.vortex_schedule)
    if(props.select_technology.toLowerCase()==='coriolis'|| props.select_technology.toLowerCase()==='density'||props.select_technology.toLowerCase()==='viscosity'){
      setLineSizeDropdownDisplay(props.process_line_size_units !==undefined? props.process_line_size_units.filter(c=>c.value===data.pipeline_size)[0]?props.process_line_size_units.filter(c=>c.value===data.pipeline_size)[0].label:data.pipeline_size:data.pipeline_size);
    }
    else{
      setProcessLineSizeDropdownDisplay(props.process_line_size_units !==undefined? props.process_line_size_units.filter(c=>c.value===data.pipeline_size)[0]?props.process_line_size_units.filter(c=>c.value===data.pipeline_size)[0].label:data.pipeline_size:data.pipeline_size);
    }

    setLineSizeResizing(data.pipeline_size)
    props.selectBasicPage('fluidSelection');
    setTimeout(() => {
      props.resizingFlag(true)  
    }, 4000);
    
  }
  }, [props.get_sizing_data]);




  useEffect(() => {
    let sortArray = [];
    let productFamilyDisplayArray = [];
    let productFamilyValueArray = [];
    if (props.product_family_units) {
      props.product_family_units.map((option, index) => {
        sortArray.push(option);
      });
      sortArray.sort((a, b) => a.value.localeCompare(b.value));
      sortArray.map((option) => {
        productFamilyDisplayArray.push(option.label)
        productFamilyValueArray.push(option.value)
      });

      let index =  productFamilyValueArray.findIndex(obj => obj === 'ALL_SENSORS');

      setProductFamilyDisplay(productFamilyDisplayArray);
      setProductFamilyValue(productFamilyValueArray)
      if(productFamilyValueArray.length > 0){
        let familyName = productFamilyDisplayArray[index] === 'All Sensors' ? CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG113',props) : productFamilyDisplayArray[index];
        setProductFamilyDisplayValue(familyName)
      }else{
        setProductFamilyDisplayValue(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG113',props))
      }
      
    }
  }, [props.product_family_units]);

  useEffect(() => {
    let lineSizeDisplayArray = [];
    let lineSizeValueArray = [];
    if (props.process_line_size_units) {
      setProcessLineSizeData(props.process_line_size_units);
      props.process_line_size_units.map((option, index) => {
        lineSizeDisplayArray.push(option.label);
        lineSizeValueArray.push(option.value)
      });
      setProcessLineSizeDropdownDisplay(unknowProcessLineSize)
      setProcessLineSizeDisplay(lineSizeDisplayArray);
      setProcessLineSizeValue(lineSizeValueArray)
      setLineSizeValue(lineSizeValueArray);
      setLineSizeDisplay(lineSizeDisplayArray);
    }
  }, [props.process_line_size_units]);


useEffect(() => {
  let wettedMaterialDisplayArray = [];
  let wettedMaterialValueArray = [];
  let wettedMatererialDescriptionArray = [];
  
  if(props.wetted_material_units){
    props.wetted_material_units.map((option, index)=>{
      wettedMaterialDisplayArray.push(option.label)
      wettedMaterialValueArray.push(option.value)
      wettedMatererialDescriptionArray.push(option.description)
    })
    setMaterialValue(wettedMaterialValueArray)
    setMaterialDisplayArray(wettedMaterialDisplayArray)
    setMaterialDescriptionArray(wettedMatererialDescriptionArray)
  }
}, [props.wetted_material_units])


useEffect(() => {

  if(props.resizing_flag){
    setTimeout(() => {
      if(props.select_technology.toLowerCase()==='coriolis'|| props.select_technology.toLowerCase()==='density'||props.select_technology.toLowerCase()==='viscosity'){
        if( lineSizeResizing ==='All Sizes'){
          setLineSizeDropdownDisplay(CustomTranslationText('XXSPECIFY_MMI_MC_DROPDN_ALSIZS',props))
        }else if(lineSizeResizing !=='All Sizes'){
          setLineSizeDropdownDisplay(props.process_line_size_units !==undefined && props.process_line_size_units !== '' ? props.process_line_size_units.filter(c=>c.value===lineSizeResizing)[0]?props.process_line_size_units.filter(c=>c.value===lineSizeResizing)[0].label:lineSizeResizing:lineSizeResizing) 
          props.resizingFlag(false)
        }
      }else{
        setProcessLineSizeDropdownDisplay(props.process_line_size_units !==undefined? props.process_line_size_units.filter(c=>c.value===lineSizeResizing)[0]?props.process_line_size_units.filter(c=>c.value===lineSizeResizing)[0].label:lineSizeResizing:lineSizeResizing);
        props.resizingFlag(false)
      }
      
    }, 1000);
  }
  
}, [props.resizing_flag, props.process_line_size_units])


  useEffect(() => {
    let scheduleArray = [];

    if (props.schedule_units) {
      props.schedule_units.map(option => {
        return scheduleArray.push(option.label);
      });
      setOptionsSchedule(scheduleArray);
    }
  }, [props.schedule_units]);

  useEffect(() => {
    if (callValidateAPI) {
      let process_line, schedule, units = '';
      processLineSizeData.filter(item => item.label === processLineSizeSelect).map(option => {
        process_line = option.value;
      });
      optionsSchedule.filter(item => item === scheduleSelect).map(option => {
        schedule = option.toUpperCase();
      });
      unitsData.filter(item => item.label === unitSelect).map(option => {
        units = option.value;
      });
      let data = {
        "language": props.query_params.language?props.query_params.language:languageCode,
        "pipeIdUnit": units,
        "processLineSizeVortex": process_line + "%",
        "scheduleVortex": schedule,
        "textPipeId": pipeIdValue
      };
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}${CALCULATE_VALIDATE_PIPE_ID}`,
        headers: {
          "Content-Type": "application/json",
        },
        xsrfHeaderName: "X-CSRFToken",
        xsrfCookieName: "csrftoken",
        data: data
      })
        .then((response) => {
          const success = response.data.data;
          if (success.length !== 0) {
            if (success[0].textPipeId !== null) {
              props.pipeId(success[0].textPipeId);
              setPipeIdValue(success[0].textPipeId);
            } else {
              setPipeIdValue('');
              props.pipeId(null);
            }
          } else {
            props.pipeId(null);
            setPipeIdValue('');
          }
        })
        .catch((error) => {
          if (!props.error_message) {
            props.errorMessage(true);
          }
          console.log("error", error);
        });
    }
  }, [callValidateAPI, processLineSizeSelect, scheduleSelect, unitSelect])

  useEffect(() => {
    let obj = props.fluid_selection_section
    if(!props.fluid_selection_flag){
      obj.flow = props.flow
      obj.product_family = props.product_family
      obj.product_family_label = props.product_family_name === 'All Sensors' ? CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG113',props) : props.product_family_name;
      obj.line_size = props.line_size
      obj.line_size_label = (props.line_size === 'All Sizes' || props.line_size === 'ALL_SIZES' ) ? CustomTranslationText('XXSPECIFY_MMI_MC_DROPDN_ALLSIZ',props) : props.line_size === 'Select Line Size' ? CustomTranslationText('XXSPECIFY_MMI_MC_LBL_LINE',props) : props.line_size_label;
      obj.process_line_size = props.process_line_size
      obj.process_line_size_label = props.process_line_size_label === 'Unknown*' ? CustomTranslationText('XXSPECIFY_MMI_MC_DROPDN_UNKNOW',props) : props.process_line_size_label;
      obj.wetted_material = props.wetted_material
      obj.wetted_material_value = props.wetted_material_value
      obj.wetted_material_label = props.wetted_material_label
      obj.sis_application=props.sis_application
    }
  }, [props.fluid_selection_flag])
  
  
useEffect(() => {
  if(props.fluid_selection_flag){
    props.productFamily(props.fluid_selection_section.product_family)
    props.lineSize(props.fluid_selection_section.line_size)
    props.processLineSize(props.fluid_selection_section.process_line_size)
    props.wettedMaterial(props.fluid_selection_section.wetted_material)
    props.wettedMaterialValue(props.fluid_selection_section.wetted_material_value)
    props.wettedMaterialLabel(props.fluid_selection_section.wetted_material_label)
    props.sisApplication(props.fluid_selection_section.sis_application)
    setTimeout(() => {
      setProductFamilyDisplayValue(props.fluid_selection_section.product_family_label)
      setLineSizeDropdownDisplay(props.fluid_selection_section.line_size_label)
      setProcessLineSizeDropdownDisplay(props.fluid_selection_section.process_line_size_label)
      setMaterialDropdownDisplay(props.fluid_selection_section.wetted_material_label)
    }, 300);
  }
}, [props.fluid_selection_flag])



useEffect(() => {
  if (Object.keys(props.reset_operating_conditions).length > 0) {
    //product family dropdown   
    let indexProductFamily= productFamilyValue.findIndex(x=>x===props.reset_operating_conditions.Fields.product_family);
    setProductFamilyDisplayValue(productFamilyDisplay[indexProductFamily !== -1? indexProductFamily:0])
    
    setTimeout(() => {
      //line size dropdown
    let indexLineSize = lineSizeValue.findIndex(x=>x===props.reset_operating_conditions.Fields.line_size)
    setLineSizeDropdownDisplay(lineSizeDisplay[indexLineSize !== -1?indexLineSize:0])
    }, 500);

    //SIS Application
    props.sisApplication(props.reset_operating_conditions.Fields.sis_application)

    //process line size dropdown
    let indexProcessLine = processLineSizeValue.findIndex(x=>x===props.reset_operating_conditions.Fields.process_line_size.toUpperCase())
    setProcessLineSizeDropdownDisplay(processLineSizeDisplay[indexProcessLine !== -1? indexProcessLine:processLineSizeDisplay.length-1])

    //wetted material dropdown
    let indexWettedMaterial =  materialDescriptionArray.findIndex(x=>x===props.reset_operating_conditions.Fields.wetted_material)
    props.wettedMaterialValue(materialValue[indexWettedMaterial !== -1?indexWettedMaterial:0]) 
    //ui 
    props.wettedMaterialLabel(materialDisplayArray[indexWettedMaterial !== -1? indexWettedMaterial:0])
    setMaterialDropdownDisplay(materialDisplayArray[indexWettedMaterial !== -1? indexWettedMaterial:0])

  }
}, [props.reset_operating_conditions])






  const handleProductFamilyChange = key => {
    props.productFamily(productFamilyValue[key ? key : 0]);
    setProductFamilyDisplayValue(productFamilyDisplay[key ? key : 0]);
    props.productFamilyName(productFamilyDisplay[key ? key : 0]);

    //If product family is equal to T series setting wetted material to any
    if(key===19){
      props.wettedMaterial(materialDescriptionArray[5])
      props.wettedMaterialValue(materialValue[5]) 
      props.wettedMaterialLabel(materialDisplayArray[5])
      setMaterialDropdownDisplay(materialDisplayArray[5])
    }

  }
  const handleLineSizeChange = key => {
    props.lineSize(lineSizeValue[key ? key : 0]);
    setLineSizeDropdownDisplay(lineSizeDisplay[key ? key : 0]);
    props.lineSizeLabel(lineSizeDisplay[key ? key : 0]);
    props.lineSizeError(false);
  };

  const handleWettedMaterial = key =>{
    
    props.wettedMaterial(materialDescriptionArray[key?key:0])
    props.wettedMaterialValue(materialValue[key?key:0]) 
    //ui 
    props.wettedMaterialLabel(materialDisplayArray[key? key:0])
    setMaterialDropdownDisplay(materialDisplayArray[key? key:0])
    
  }


  useEffect(() => {
    if(!props.flow && props.density){
      if(Object.keys(props.get_sizing_data).length===0){
        if(!props.tank){
          props.lineSize('Select Line Size')
        }else{
          props.lineSize('')
        }
        setLineSizeDropdownDisplay(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_LINE', props));
        //props.lineSizeError(false);
      }
      props.lineSizeError(false);
    }else if((!props.density && props.viscosity) || props.select_technology === "Viscosity"){
      if(Object.keys(props.get_sizing_data).length===0){
        if(!props.tank){
          props.lineSize('Select Line Size')
        }else{
          props.lineSize('')
        }
        setLineSizeDropdownDisplay(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_LINE', props));
      }
      props.lineSizeError(false);
    }else{
      if( props.select_technology ==='Coriolis'){
        setLineSizeDropdownDisplay(CustomTranslationText('XXSPECIFY_MMI_MC_DROPDN_ALLSIZ', props));
      props.lineSize('All Sizes');
      }else{
        setLineSizeDropdownDisplay(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_LINE', props));
        props.lineSize('Select Line Size');
      }
      
    }
   if(materialDisplayArray.length >0 && props.select_technology ==='Coriolis' && props.sizing_results.length===0){

    if(props.wetted_material_label === ''){
      props.wettedMaterial(materialDescriptionArray[materialDefaultIndex])
      props.wettedMaterialValue(materialValue[materialDefaultIndex])       
      props.wettedMaterialLabel(materialDisplayArray[materialDefaultIndex])
      setMaterialDropdownDisplay(materialDisplayArray[materialDefaultIndex])
    }
  }

  if(productFamilyDisplay.length > 0){
    let familyName = productFamilyDisplay[0] === 'All Sensors' ? CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG113',props) : productFamilyDisplay[0];
    if(props.resizing_flag){
      setProductFamilyDisplayValue(familyName)
    }
  }
    
  }, [props.select_technology,props.density,props.viscosity,props.flow,props.tank]);
  useEffect(() => {
    setLineSizeDropdownDisplay(CustomTranslationText('XXSPECIFY_MMI_MC_DROPDN_ALLSIZ', props))
    if(productFamilyDisplay.length>0){
      setProductFamilyDisplayValue(productFamilyDisplay[0]);
    }
    if(materialDisplayArray.length >0){
      props.wettedMaterial(materialDescriptionArray[materialDefaultIndex])
      props.wettedMaterialValue(materialValue[materialDefaultIndex]) 
      //ui 
      props.wettedMaterialLabel(materialDisplayArray[materialDefaultIndex])
      setMaterialDropdownDisplay(materialDisplayArray[materialDefaultIndex])
    }
  }, [props.clear_all_field]);


  const handleProcessLineSizeChange = key => {
    props.processLineSize(processLineSizeValue[key ? key : 0]);
    if (processLineSizeValue[key ? key : 0] !== "Unknown*") {
      setProcessLineSizeSelect(processLineSizeValue[key ? key : 0]);
      setProcessLineSizeDropdownDisplay(processLineSizeDisplay[key ? key : 0]);
      props.processLineSizeLabel(processLineSizeDisplay[key ? key : 0])
      setCallValidateAPI(true);
    }
    else {
      props.pipeId(null);
      setPipeIdValue('');
    }
  }


  if(props.density && props.fluid_state? props.fluid_state.toLowerCase() === 'gas':false){
    linesizeDisabled = true;
  }

  if (props.tank) {
    props.toggleInLine(false);
    props.toggleFlowThroughChamberSlipstream(false);
    props.lineSize("");
    //setLineSizeDropdownDisplay(allSizesVar)
    flowThroughDisabled = true;
    inlineDisabled = true;
    linesizeDisabled = true;
    tankDisabled = false;
  }
  if (props.flow_through_chamber_slipstream) {
    flowThroughDisabled = false;
    inlineDisabled = true;
    tankDisabled = true;
    linesizeDisabled = false;
  }
  if (props.in_line) {
    flowThroughDisabled = true;
    inlineDisabled = false;
    tankDisabled = true;
    linesizeDisabled = false;
  }

  const toggleCheckbox = (checked, id) => {   
    if(props.select_technology.toLowerCase()==='vortex')
    {
      if(props.sis_application==='redundancy'){
        setRedundancy(!redundancy) 
      }else if(props.sis_application==='radio-safety'){
        setRadiosafety(!radiosafety)}
      props.sisApplication('')

    }
    switch (id) {
      case "hygienic-checkbox":
        props.toggleHygienic(checked);
        if(checked){
          props.wettedMaterial(materialDescriptionArray[5])
          props.wettedMaterialValue(materialValue[5]) 
          props.wettedMaterialLabel(materialDisplayArray[5])
          setMaterialDropdownDisplay(materialDisplayArray[5])
        }
        break;
      case "display-sensors-checkbox":
        setDisplayAllSensors(checked);
        props.toggleDisplayAllSensorsNoFilters(checked);
        break;
      case "inline-checkbox":
        props.toggleInLine(checked);
        break;
      case "flow-through-chamber-checkbox":
        props.toggleFlowThroughChamberSlipstream(checked);
        break;
      case "tank-checkbox":
        props.toggleTank(checked);
        break;
      case "hygienic-application-checkbox":
        props.toggleHygienicApplication(checked);
        break;
      case "high-process-noise-checkbox":
        props.toggleHighProcessNoiseSlurry(checked);
        break;
      case "flanged-checkbox":
        props.toggleFlanged(checked);
        break;
      case "wafer-checkbox":
        props.toggleWafer(checked);
        break;
      case "reducer-checkbox":
        props.toggleReducer(checked);
        break;
      case "dual-checkbox":
        props.toggleDual(checked);
        break;
      case "quad-checkbox":
        props.toggleQuad(checked);
        break;
      case "pressure-checkbox":
        props.tooglePressure(checked);
        break;
      default: {
      }
    }
  };

  const checkboxes = (name, id, label, checked, disabled, md, lg, sm, showToolip, tooltipText) => {
    md = md ? md : 12;
    lg = lg ? lg : 6;
    sm = sm ? sm : 6

    return (
        <Col lg={lg} md={md} sm={6}>
        <CustomCheckbox
          checked={checked}
          name={name}
          id={id}
          label={label}
          onChange={(e) => toggleCheckbox(e.target.checked, id)}
          disabled={disabled}
          showToolip={showToolip}
          tooltipText={tooltipText}
        />
        
        </Col>
    );
  };

  const dropdowns = (
    title,
    id,
    text,
    dropdownArray,
    eventHandler,
    disabled,
    required,
    error
  ) => {
    text = !text ? id ==='product-family-dropdown'? allSensorsLabel: allSizesVar : text;
    return (
      <Col sm={12}>
        <CustomDropdown
          title={title}
          id={id}
          text={text}
          dropdownArray={dropdownArray}
          eventHandler={eventHandler}
          disabled={disabled}
          borderRequired={required}
          borderError={error}
        />
      </Col>
    );
  };

  const isChecked = (checked) => {
    if(checked==='redundancy'){
      props.sisApplication(!redundancy?checked:'')
      if(!redundancy){
        props.toggleFlanged(false);
        props.toggleWafer(false);
        props.toggleReducer(false);
        props.toggleDual(true);
        props.toggleQuad(true);
        setRedundancy(!redundancy)
        setRadiosafety(false)
      }else{
        props.toggleFlanged(true);
        props.toggleWafer(true);
        props.toggleReducer(true);
        props.toggleDual(false);
        props.toggleQuad(false);
         setRedundancy(!redundancy)
        //setRadiosafety(!radiosafety)
      }
      
      
    }else if(checked==='radio-safety'){
      props.sisApplication(!radiosafety?checked:'')
      if(!radiosafety){
        setRadiosafety(!radiosafety)
        props.toggleFlanged(true);
        props.toggleWafer(true);
        props.toggleReducer(true);
        props.toggleDual(true);
        props.toggleQuad(true);
        setRedundancy(false)
  }else{
        setRadiosafety(!radiosafety)
        props.toggleFlanged(true);
        props.toggleWafer(true);
        props.toggleReducer(true);
        props.toggleDual(false);
        props.toggleQuad(false);
        //setRedundancy(!redundancy)
  }
     
    }else{
      props.sisApplication('')
      props.toggleFlanged(true);
      props.toggleWafer(true);
      props.toggleReducer(true);
      props.toggleDual(false);
      props.toggleQuad(false);
    }
    
  };

  const disablePressureCheckbox = ()=>{
    const line_press = props.line_press_row !== undefined && props.line_press_row !== '' ? props.line_press_row.some(el => el >= 1450 ):false 
    if(line_press){
      props.tooglePressure(line_press);
    }   
    return line_press
  }
 
  const radioInputButtons = (id, label) => {
    return (
      <>
        <CustomRadiobutton
          text={label}
          type='radio'
          name='equipment-selection'
          id={id}
          className='custom-radiobutton'
          onClick={(e) => isChecked(e.target.id)}
          checked={props.sis_application===id}
        />
      </>
    );
  };
  const layoutConditional = () => {
    if (props.flow && props.select_technology === "Coriolis") {
      const required = props.density;

      return (
        <>
          <Row>
            {checkboxes(
              "hygienic",
              "hygienic-checkbox",
              <CustomTranslation data="XXSPECIFY_MMI_SR_CB21_HYG" />,
              props.hygienic
            )}
          </Row>
          <Row>
            {checkboxes(
              "display-sensors",
              "display-sensors-checkbox",
              <CustomTranslation data="XXSPECIFY_MMI_MC_CHKBX_NOFILTR" />,
              props.display_all_sensors_no_filters
            )}
          </Row>
          <hr />
          <Row>
            {dropdowns(
              <CustomTranslation data='XXSPECIFY_MMI_MC_SEL_SENSEL' />,
              "product-family-dropdown",
              productFamilyDisplayValue,
              productFamilyDisplay,
              handleProductFamilyChange
            )}
          </Row>
          <Row>
            {dropdowns(
              <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_PIPELINE' />,
              "line-size-dropdown",
              lineSizeDropdownDisplay,
              lineSizeDisplay,
              handleLineSizeChange,
              linesizeDisabled,
              required
            )}
          </Row>
          <Row>
            {dropdowns(
              <CustomTranslation data='XXSPECIFY_MMI_SR_LBL_WTMTRL' />,
              "wetted-material-dropdown",
              materialDropdownDisplay,
              materialDisplayArray,
              handleWettedMaterial,
              false,
              false
            )}
          </Row>
        </>
      );
    } else if (!props.flow && props.density) {
      return (
        <>
          <Row>
            {checkboxes(
              "hygienic",
              "hygienic-checkbox",
              <CustomTranslation data="XXSPECIFY_MMI_SR_CB21_HYG" />,
              props.hygienic,
              null,
              6
            )}
            {checkboxes(
              "display-sensors",
              "display-sensors-checkbox",
              <CustomTranslation data="XXSPECIFY_MMI_MC_CHKBX_NOFILTR" />,
              props.display_all_sensors_no_filters,
              null,
              6
            )}
          </Row>
          <Row>
            {checkboxes(
              "inline",
              "inline-checkbox",
              <CustomTranslation data="XXSPECIFY_MMI_MC_CHKBX_INLINE1" />,
              props.in_line,
              inlineDisabled,
              6
            )}
            {checkboxes(
              "flow-through-chamber",
              "flow-through-chamber-checkbox",
              <CustomTranslation data="XXSPECIFY_MMI_MC_CHKBX_STREAM" />,
              props.flow_through_chamber_slipstream,
              flowThroughDisabled,
              6
            )}
          </Row>
          <Row>
            {checkboxes(
              "tank",
              "tank-checkbox",
              <CustomTranslation data="XXSPECIFY_MMI_MC_CHKBX_TANK" />,
              props.tank,
              tankDisabled,
              6
            )}
          </Row>
          <hr />
          <Row>
            {dropdowns(
              <CustomTranslation data='XXSPECIFY_MMI_MC_SEL_SENSEL' />,
              "product-family-dropdown",
              productFamilyDisplayValue,
              productFamilyDisplay,
              handleProductFamilyChange
            )}
          </Row>
          <Row>
            {dropdowns(
              <CustomTranslation data='XXSPECIFY_MMI_SR_TBL_LINESIZE' />, 
              "line-size-dropdown",
              lineSizeDropdownDisplay,
              lineSizeDisplay,
              handleLineSizeChange,
              linesizeDisabled,
              props.tank?false:props.fluid_state==='Gas'?false:true,
              props.line_size_error
            )}
          </Row>
        </>
      );
    } else if ((!props.density && props.viscosity) || props.select_technology === "Viscosity") {
      return (
        <>
          <Row>
            {checkboxes(
              "display-sensors",
              "display-sensors-checkbox",
              <CustomTranslation data="XXSPECIFY_MMI_MC_CHKBX_NOFILTR" />,
              props.display_all_sensors_no_filters,
              null,
              6
            )}
            {checkboxes(
              "inline",
              "inline-checkbox",
              <CustomTranslation data="XXSPECIFY_MMI_MC_CHKBX_INLINE1" />,
              props.in_line,
              inlineDisabled,
              6
            )}
          </Row>
          <Row>
            {checkboxes(
              "flow-through-chamber",
              "flow-through-chamber-checkbox",
              <CustomTranslation data="XXSPECIFY_MMI_MC_CHKBX_STREAM" />,
              props.flow_through_chamber_slipstream,
              flowThroughDisabled,
              6
            )}
            {checkboxes(
              "tank",
              "tank-checkbox",
              <CustomTranslation data="XXSPECIFY_MMI_MC_CHKBX_TANK" />,
              props.tank,
              tankDisabled,
              6
            )}
          </Row>
          <hr />
          <Row>
            {dropdowns(
              <CustomTranslation data='XXSPECIFY_MMI_MC_SEL_SENSEL' />,
              "product-family-dropdown",
              productFamilyDisplayValue,
              productFamilyDisplay,
              handleProductFamilyChange
            )}
          </Row>
          <Row>
            {dropdowns(
              <CustomTranslation data='XXSPECIFY_MMI_SR_TBL_LINESIZE' />,
              "line-size-dropdown",
              lineSizeDropdownDisplay,
              lineSizeDisplay,
              handleLineSizeChange,
              linesizeDisabled,
              props.tank?false:props.fluid_state==='Gas'?false:true,
              props.line_size_error
            )}
          </Row>
        </>
      );
    } else if (props.select_technology === "Vortex") {
      return (
        <>
           <div className='equipment-selection'>
      <div  className='checkbox-title'>
      <CustomTranslation data="XXSPECIFY_MMI_LBL_SIS_App" />
      </div>
      
        <Row className='meter-styles'>
          <Col>{radioInputButtons("redundancy", <CustomTranslation data="XXSPECIFY_MMI_LBL_Redundancy" />)}</Col>
        </Row>
        <Row className='meter-styles'>
          <Col>{radioInputButtons("radio-safety", <CustomTranslation data="XXSPECIFY_MMI_LBL_Safety_Crtf" />)}</Col>
        </Row>
      
    </div>
    <br/>
          <div className='checkbox-title'> <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_METR" /></div>
          <Row className='meter-styles'>
            {checkboxes(
              "flanged",
              "flanged-checkbox",
              <CustomTranslation data="XXSPECIFY_MMI_SR_CB27_FLNG" />,
              props.flanged,
              null,
              6
            )}
            {checkboxes(
              "wafer",
              "wafer-checkbox",
              <CustomTranslation data="XXSPECIFY_MMI_SR_CB29_WAFER" />,
              props.wafer,
              null,
              6
            )}
          </Row>
          <Row className='meter-styles'>
            {checkboxes(
              "reducer",
              "reducer-checkbox",
              <CustomTranslation data="XXSPECIFY_MMI_MC_CHKBX_REDU" />,
              props.reducer,
              null,
              6
            )}
            {checkboxes("dual", "dual-checkbox", <CustomTranslation data="XXSPECIFY_MMI_MC_CHKBX_DUAL" />, props.dual, null, 6)}
          </Row>
          <Row className='meter-styles'>
            {checkboxes("quad", "quad-checkbox", <CustomTranslation data="XXSPECIFY_MMI_MC_CHKBX_QUAD" />, props.quad, null, 6)}
            {checkboxes('pressure', 'pressure-checkbox', <CustomTranslation data="XXSPECIFY_MMI_MC_LBL_HI_PRESS" />, 
            props.pressure, disablePressureCheckbox(), 6, 6, 6, disablePressureCheckbox(), CustomTranslationText('XXSPECIFY_MMI_MC_LBL_HP_MB_REQ',props))}
            
          </Row>
          <Row>
            {dropdowns(
              <CustomTranslation data="XXSPECIFY_MMI_MC_HEAD_VTXLINE" />,
              "process-line-size-dropdown",
              processLineSizeDropdownDisplay,
              processLineSizeDisplay,
              handleProcessLineSizeChange
            )}
          </Row>
          {/* TBD */}
          {/* <Row>
            {dropdowns(
              <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_LEVEL" />,
              "trigger-level-dropdown",
              props.trigger_level,
              optionsTriggerLevel,
              handleTriggerLevelChange
            )}
          </Row> */}
        </>
      );
    } else if (props.select_technology === "Magnetic") {
      return (
        <>
          <Row>
            {checkboxes(
              "hygienic-application",
              "hygienic-application-checkbox",
              <CustomTranslation data='XXSPECIFY_MMI_MC_CHKBX_HA' />,
              props.hygienic_application
            )}
          </Row>
          <Row>
            {checkboxes(
              "high-process-noise",
              "high-process-noise-checkbox",
              <CustomTranslation data='XXSPECIFY_MMI_MC_CHKBX_HPN' />,
              props.high_process_noise_slurry
            )}
          </Row>
          <hr />
          <Row>
            {dropdowns(
              <CustomTranslation data="XXSPECIFY_MMI_MC_HEAD_VTXLINE" />,
              "process-line-size-dropdown",
              processLineSizeDropdownDisplay,
              processLineSizeDisplay,
              handleProcessLineSizeChange
            )}
          </Row>
        </>
      );
    } else {
      return "";
    }
  };

  let displayAllWarningMsg = <p><span style={{color : 'red'}}><CustomTranslation data='XXSPECIFY_MMI_PR_WARNING' /></span><br/><CustomTranslation data='XXSPECIFY_MMI_LBL_DSP_ALL_WARN' /></p>

  return (
    <div className='application-requirements'>
      <div className='title'>
        <CustomTranslation data='XXSPECIFY_MMI_MC_PANEL3_APRQ' />
      </div>
      <Container className='selection-container'>
      <ErrorModal
        show={displayAllSensors}
        handleClose={handleClose}
        message={displayAllWarningMsg}
      />
        {layoutConditional()}
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  hygienic: state.ApplicationRequirements.hygienic,
   get_sizing_data: state.MySizings.get_sizing_data,
  display_all_sensors_no_filters:
    state.ApplicationRequirements.display_all_sensors_no_filters,
  in_line: state.ApplicationRequirements.in_line,
  flow_through_chamber_slipstream:
    state.ApplicationRequirements.flow_through_chamber_slipstream,
  tank: state.ApplicationRequirements.tank,
  hygienic_application: state.ApplicationRequirements.hygienic_application,
  high_process_noise_slurry:
    state.ApplicationRequirements.high_process_noise_slurry,
  product_family: state.ApplicationRequirements.product_family,
  line_size: state.ApplicationRequirements.line_size,
  line_size_label: state.ApplicationRequirements.line_size_label,
  wetted_material_units: state.ApplicationRequirements.wetted_material_units,
  wetted_material: state.ApplicationRequirements.wetted_material,
  wetted_material_value: state.ApplicationRequirements.wetted_material_value,
  wetted_material_label: state.ApplicationRequirements.wetted_material_label,
  line_size_error: state.ApplicationRequirements.line_size_error,
  process_line_size: state.ApplicationRequirements.process_line_size,
  process_line_size_label: state.ApplicationRequirements.process_line_size_label,
  schedule: state.ApplicationRequirements.schedule,
  trigger_level: state.ApplicationRequirements.trigger_level,
  flanged: state.ApplicationRequirements.flanged,
  wafer: state.ApplicationRequirements.wafer,
  reducer: state.ApplicationRequirements.reducer,
  dual: state.ApplicationRequirements.dual,
  quad: state.ApplicationRequirements.quad,
  pressure: state.ApplicationRequirements.pressure,
  pipe_id: state.ApplicationRequirements.pipe_id,
  pipe_id_units: state.ApplicationRequirements.pipe_id_units,
  product_family_units: state.ApplicationRequirements.product_family_units,
  line_size_units: state.ApplicationRequirements.line_size_units,
  resizing_flag: state.ApplicationRequirements.resizing_flag,
  process_line_size_units:
    state.ApplicationRequirements.process_line_size_units,
  flow: state.MeasurementType.flow,
  density: state.MeasurementType.density,
  viscosity: state.MeasurementType.viscosity,
  select_technology: state.MeasurementType.select_technology,
  schedule_units: state.ApplicationRequirements.schedule_units,
  schedule_value: state.ApplicationRequirements.schedule_value,
  user_language: state.AppHeader.user_language,
  query_params: state.Preferences.query_params,
  saved_preferences: state.Preferences.saved_preferences,
  fluid_state: state.FluidSelection.fluid_state,
  clear_all_field: state.ClearAllField.clear_all_field,
  translation_data: state.TranslationData.translation_data,
  fluid_selection_flag: state.FluidSelection.fluid_selection_flag,
  fluid_selection_section: state.FluidSelection.fluid_selection_section,
  sizing_results: state.SizingResults.sizing_results_data,
  reset_operating_conditions: state.ProcessVariables.reset_operating_conditions,
  product_family_name: state.ApplicationRequirements.product_family_name,
  sis_application: state.ApplicationRequirements.sis_application,
  line_press_row: state.ProcessVariables.line_press_row_psig
});

export default connect(mapStateToProps, {
  toggleHygienic,
  toggleDisplayAllSensorsNoFilters,
  toggleInLine,
  toggleFlowThroughChamberSlipstream,
  toggleTank,
  toggleHygienicApplication,
  toggleHighProcessNoiseSlurry,
  productFamily,
  productFamilyName,
  lineSize,
  lineSizeError,
  lineSizeLabel,
  processLineSize,
  processLineSizeLabel,
  scheduleDropdown,
  triggerLevel,
  toggleFlanged,
  toggleWafer,
  toggleReducer,
  toggleDual,
  toggleQuad,
  tooglePressure,
  pipeId,
  pipeIdUnits,
  errorMessage,
  selectBasicPage,
  resizingFlag,
  fluidSelectionSection, 
  wettedMaterial, 
  wettedMaterialValue, 
  wettedMaterialLabel,
  sisApplication
})(ApplicationRequirements);
