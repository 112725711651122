import React, { useEffect, useState, useRef ,useMemo} from 'react';
import {
  Container,
  Col,
  Row,
} from 'react-bootstrap';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CustomDropdown } from '../../components/CustomDropdown/CustomDropdown';
import { CustomDropdownTypehead2 } from '../../components/CustomDropdown/CustomDropdownTypeahead2';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  fluidSourceArray,
  fluidStateArray,
  fluidState,
  fluidSource,
  pickFluidsFromDB,
  pickGasFromDB,
  conductivityDropdown,
  fluidService,
  fluidServiceKey,
  customFluidName,
  steamPressureTemp,
  requestSizingResults,
  databaseGassesList,
  selectBasicPage,
  pickFluidsCoolpropsFlag,
  customBorderError,
  revertFlag,
  coolPropsVisc,
  fluidSelectionSection,
  fluidSelectionFlag,
  naturalGasCompose,
  naturalGasCompositionName,
  compositionMethod,
  sizingResultsClicked,
  relativeDensity,
  molePercentCO2,
  molePercentN2,
  heatingValue,
  molePercentH2,
  molePercentCO,
} from '../../redux/actions';
import jsonInputs from '../../JSONForTesting/jsonInputs';
import { CustomInputField } from '../../components/CustomInputField/CustomInputField';
import { CustomRadiobutton } from '../../components/CustomRadiobutton/CustomRadiobutton';
import GasComposition from './GasComposition';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';
import CustomTranslationText from '../../components/CustomTranslation/CustomTranslationText';
function usePrevious(value) {
  const ref = React.useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
const FluidSelection = (props) => {
  const [composeGas, setComposeGas] = useState(false);
  const [showGasButton, setShowGasButton] = useState(false);
  const [fluidStateValueArray, setFluidStateValueArray] = useState([]);
  const [fluidStateDisplay, setFluidStateDisplay] = useState([]);
  const [fluidStateDropdownDisplayValue, setFluidStateDropdownDisplayValue] =
   useState(CustomTranslationText('XXSPECIFY_MMI_SR_LBL_LIQ',props));
  const [fluidStateDisplayArrayTemp, setFluidStateDisplayArrayTemp] = useState([]);
  const [fluidSourceDisplayArray, setFluidSourceDisplayArray] = useState([]);
  const [fluidSourceDisplayArrayAux, setFluidSourceDisplayArrayAux] = useState([]);
  const [fluidSourceValueArray, setFluidSourceValueArray] = useState([]);
  const [fluidSourceValueArrayAux, setFluidSourceValueArrayAux] = useState([]);
  const [fluidSourceDisplay, setFluidSourceDisplay] = 
  useState(CustomTranslationText('XXSPECIFY_MMI_SR_LBL_DAT_BAS',props));
  const [selectedFluidsDBDisplay, setSelectedFluidsDBDisplay] =
   useState(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_WATER',props));
  const [resultsGetFluidsDBDisplay, setResultsGetFluidsDBDisplay] = useState([]);
  const [resultsGetFluidsDBValue, setResultsGetFluidsDBValue] = useState([]);
  const [resultsGetGasDBValue, setResultsGetGasDBValue] = useState([]);
  const [resultsConductivity, setResultsConductivity] = useState([]);
  const [resultsFluidService, setResultsFluidService] = useState([]);
  const [customFluidNameBorderError, setCustomFluidNameBorderError] = useState(false);
  const [showError, setShowError] = useState();
  const [resetFluidSource, setResetFluidSource] = useState(false)
  
  useEffect(() => {
    const fetchData = async () => {
      await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/mmipa/get_natural_gas_dropdown_list/`,
        headers: {
          'Content-Type': 'application/json',
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
      })
        .then(async (response) => {
          let ValueArray=[]
          response.data.data.map(async(option) => {
            await ValueArray.push(option.value)
          })
          await setResultsGetGasDBValue(ValueArray)
        })
        .catch((error) => {
          console.log('error', error);
        });
    };
    
      fetchData();
    if(props.fluid_source.toLowerCase() !== 'natural gas'){
      props.naturalGasCompose(false)
    }
  }, [props.fluid_source]);
  useEffect(() => {
    if(props.custom_fluid_name && props.custom_border_error === true){
      props.customBorderError(false);
    }

  }, [props.custom_border_error,])

  let tech=usePrevious(props.select_technology)

  useEffect(() => {
   if(props.clear_all_field){
    setFluidStateDropdownDisplayValue(fluidStateDisplayArrayTemp.length > 0 ? fluidStateDisplayArrayTemp[0] : CustomTranslationText('XXSPECIFY_MMI_SR_LBL_LIQ',props));
    setFluidSourceDisplay(CustomTranslationText('XXSPECIFY_MMI_SR_LBL_DAT_BAS',props))
    setSelectedFluidsDBDisplay(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_WATER',props))

    props.pickGasFromDB('Gulf Coast Natural Gas')
    props.compositionMethod("")
    props.naturalGasCompositionName('')
    props.customBorderError(false);
    //method gas 
    props.relativeDensity('')
    props.molePercentCO2('')
    props.molePercentN2('')
    props.heatingValue('')
    props.molePercentH2('')
    props.molePercentCO('')

    setTimeout(() => {
      setResetFluidSource(true)
    }, 500);
    
   }
   

  }, [props.clear_all_field]) 

  useEffect(() => {
    if(props.fluid_state_units? props.fluid_state_units.length>0:false){
    let fluidFilterArray  = [];
    let fluidValueArray = [];
    let fluidDisplayArray = [];
    if(props.select_technology === 'Coriolis' && props.density && props.flow){
      fluidFilterArray = props.fluid_state_units.filter(
        (item) => item.value !== 'GAS' && item.value !== 'STEAM'
      );
    }
    else if (
      props.select_technology === 'Coriolis' ||
      props.select_technology === 'Density' ||
      (props.select_technology === 'Viscosity' && props.density) ||
      props.select_technology === ''
    ) {
      
      fluidFilterArray = props.fluid_state_units ? props.fluid_state_units.filter(
        (item) => item.value !== 'STEAM'
      ): [];
    } else if (props.select_technology === 'Vortex') {
      fluidFilterArray = props.fluid_state_units.filter(
        (item) => item.value !== 'SLURRY'
      );
    } else if (
      props.select_technology === 'Magnetic' ||
      (props.select_technology === 'Viscosity' && !props.density)
    ) {
      fluidFilterArray = props.fluid_state_units.filter((item) => {
        return item.value !== 'GAS' && item.value !== 'STEAM';
      });
    } 
    //props.naturalGasCompose(false)
    fluidFilterArray.map((option, index) => {
      fluidDisplayArray.push(option.label)
      fluidValueArray.push(option.value)
    });
    setFluidStateDisplayArrayTemp(fluidDisplayArray)
    setFluidStateValueArray(fluidValueArray)
      setFluidStateDisplay(fluidDisplayArray)
      let fluidValue = props.fluid_state_units ? props.fluid_state_units.filter(
        (item) => item.label === fluidStateDropdownDisplayValue
      ): [];
      setFluidStateDropdownDisplayValue(fluidDisplayArray.includes(fluidStateDropdownDisplayValue)?
      fluidStateDropdownDisplayValue:fluidDisplayArray[0])
      props.fluidState(fluidDisplayArray.includes(fluidStateDropdownDisplayValue)?
      fluidValue[0].value:'Liquid'); }
  }, [props.fluid_state_units,props.select_technology, props.density]);


  useEffect(() => {
    if (props.fluid_source_units.length>0 && !props.clear_all_field && !resetFluidSource) {
      let fluidSourceDisplayArrayVar = [];
      let fluidSourceValueArrayVar = [];
      let fluidSourceDisplayArrayVarAux = [];
      let fluidSourceValueArrayVarAux = [];
      let fluidSourceDatabase = CustomTranslationText('XXSPECIFY_MMI_SR_LBL_DAT_BAS',props);
      let fluidSourceCustom = CustomTranslationText('XXSPECIFY_MMI_SR_LBL_CUSTOM',props);
      let preFilterDropdownArrayRef = [];
      let filteredDropdownArrayRef = [];
      props.fluid_source_units.map((option, index) => {
        preFilterDropdownArrayRef.push(option);
      });

      //back to sizings Arrays 
    
      preFilterDropdownArrayRef.map((option, index) => {
        fluidSourceValueArrayVarAux.push(option.default_message_text);
        fluidSourceDisplayArrayVarAux.push(option.message_text)
      })

      setFluidSourceDisplayArrayAux(fluidSourceDisplayArrayVarAux)
      setFluidSourceValueArrayAux(fluidSourceValueArrayVarAux)

      let fluid_source = preFilterDropdownArrayRef.filter(
        (item) => item.message_text === fluidSourceDisplay
      )
      if (props.select_technology.toLowerCase() === 'vortex' && props.fluid_state.toLowerCase() === 'steam') {
      filteredDropdownArrayRef = []
      props.fluidSource('Database')
      } else if (props.fluid_state.toLowerCase() === 'liquid') {
      filteredDropdownArrayRef = preFilterDropdownArrayRef.filter(
        (item) => item.message_name !=='XXSPECIFY_MMI_SR_LBL_NATGAS'
      );

      if((props.select_technology === 'Coriolis' || props.select_technology === 'Density')  && props.density && props.flow){
        props.naturalGasCompose(false);
      }

    } else if (props.fluid_state.toLowerCase() === 'slurry') {
      filteredDropdownArrayRef = preFilterDropdownArrayRef.filter(
        (item) => item.message_name !== 'XXSPECIFY_MMI_SR_LBL_DAT_BAS' && item.message_name !== 'XXSPECIFY_MMI_SR_LBL_NATGAS'
      )
    }  else if (props.fluid_state.toLowerCase() === 'gas') {
      filteredDropdownArrayRef = preFilterDropdownArrayRef;
    }

      filteredDropdownArrayRef.sort((a, b) => a.default_message_text.localeCompare(b.default_message_text))

      filteredDropdownArrayRef.map((option, index) => {
        fluidSourceValueArrayVar.push(option.default_message_text);
        fluidSourceDisplayArrayVar.push(option.message_text)
      })
      //props.naturalGasCompose(false)
      setFluidSourceDisplayArray(fluidSourceDisplayArrayVar);
      setFluidSourceValueArray(fluidSourceValueArrayVar)
     
      if (props.fluid_state.toLowerCase() === 'slurry'){
        setFluidSourceDisplay(fluidSourceCustom)
        props.fluidSource('Custom')
      } else{
        setFluidSourceDisplay(fluidSourceDisplayArrayVar.includes(fluidSourceDisplay)?
        fluidSourceDisplay:fluidSourceDatabase)
        props.fluidSource(fluidSourceDisplayArrayVar.includes(fluidSourceDisplay)?
        fluid_source[0].default_message_text:'Database')
        props.revertFlag(false)
      }
     }
     setResetFluidSource(false)
  }, [props.fluid_source_units, props.get_sizing_data, props.fluid_state,props.select_technology, resetFluidSource]);

  useEffect(() => {
      switch (props.fluid_service) {
        case 'Normal (0-39 ft/sec or 0-12 m/sec)':
          props.fluidServiceKey('NORMAL');
          break;
        case 'Abrasive Slurry (3-10 ft/sec or 0.9 ? 3.0 m/sec)':
          props.fluidServiceKey('ABRASIVE_SLURRY');
          break;
        case 'Non-abrasive Slurry (5-15 ft/sec or 1.5-4.6 m/sec)':
          props.fluidServiceKey('NON-ABRASIVE_SLURRY');
          break;
        case 'Preferred (2-20 ft/sec or 0.6 ? 6.1 m/sec)':
          props.fluidServiceKey('PREFERRED');
          break;
        default:
          break;
    }
  }, [props.fluid_service])

  useEffect(() => {
    if (props.pick_fluids_from_db_units) {
      let sortArray = [];
      let fluidsDisplayArray = [];
      let fluidsValueArray = [];
      props.pick_fluids_from_db_units.map((option) => {
        sortArray.push(option)
      });
      sortArray.sort((a, b) => a.value.localeCompare(b.value))
      sortArray.map((option) => {
        fluidsDisplayArray.push(option.label);
        fluidsValueArray.push(option.value)
      })
      setResultsGetFluidsDBDisplay(fluidsDisplayArray);
      setResultsGetFluidsDBValue(fluidsValueArray)
      setSelectedFluidsDBDisplay(fluidsDisplayArray[fluidsDisplayArray.length-2])
      props.pickFluidsFromDB('WATER')
    }
  }, [props.pick_fluids_from_db_units]);

  const getKey=(keyName, array = [])=>{
    return array.findIndex(n=> n.label === keyName)
  }

  useEffect(() => {
    if (Object.keys(props.get_sizing_data).length > 0) {
      props.naturalGasCompose(false);
      setShowGasButton(false);
      setComposeGas(false)
      let data = props.get_sizing_data[0];
     
      setShowGasButton(false);
      let fluid =
        data.fluid_state === 'LIQUID'
          ? 'Liquid'
          : data.fluid_state === 'GAS'
          ? 'Gas'
          : data.fluid_state === 'SLURRY'
          ? 'Slurry'
          : data.fluid_state === 'STEAM'
          ? 'Steam'
          : '';
      let source = data.fluid_source === 'FLUIDS' ? 'Database' 
          : data.fluid_source === 'SLURRYNAME'
          ? 'Custom'
          : data.fluid_source === 'GAS_FS'
          ? CustomTranslationText('XXSPECIFY_MMI_SR_LBL_NATGAS',props)
          : '';
      let conductivity =
        data.mag_cond_5_49_9 === 'Y'
          ? '5.0 - 49.9 microsiemens/cm'
          : data.mag_cond_50_99_9 === 'Y'
          ? '50.0 - 99.9 microsiemens/cm'
          : data.mag_cond_100 === 'Y'
          ? 'Greater than 100 microsiemens/cm'
          : '5.0 - 49.9 microsiemens/cm';
      let fluidservice =
        data.mag_fluid_service === 'NORMAL'
          ? 'Normal (0-39 ft/sec or 0-12 m/sec)'
          : data.mag_fluid_service === 'PREFERRED'
            ? 'Preferred (2-20 ft/sec or 0.6 ? 6.1 m/sec)'
            : data.mag_fluid_service === 'ABRASIVE SLURRY'
              ? 'Abrasive Slurry (3-10 ft/sec or 0.9 ? 3.0 m/sec)'
              : data.mag_fluid_service === 'NON-ABRASIVE SLURRY'
                ? 'Non-abrasive Slurry (5-15 ft/sec or 1.5-4.6 m/sec)'
                : 'Normal (0-39 ft/sec or 0-12 m/sec)';
      const fetchData = async () => {
        await props.fluidService(fluidservice);
        await props.fluidState(fluid ? fluid : 'LIQUID');

        //check the fluid state ui value
        if (fluid === 'Liquid') {
          await setFluidStateDropdownDisplayValue(CustomTranslationText('XXSPECIFY_MMI_MC_DROPDN_LIQ', props))
        } else if (fluid === 'Gas') {
          if (source === 'Natural Gas') {
            setShowGasButton(true);
            setComposeGas(data.ngas_composition_flag === 'False' ? false : true)
            props.naturalGasCompose(data.ngas_composition_flag === 'False' ? false : true);
            props.naturalGasCompositionName(data.fluid)
          }else {
            setShowGasButton(false);
            props.naturalGasCompose(false);
          }

            await setFluidStateDropdownDisplayValue(CustomTranslationText('XXSPECIFY_MMI_SR_LBL_GAS',props))
           }else if(fluid ==='Slurry'){
            await setFluidStateDropdownDisplayValue(CustomTranslationText('XXSPECIFY_MMI_MC_DROPDN_SLURRY',props))
           }else if(fluid === 'Steam'){
            await setFluidStateDropdownDisplayValue(CustomTranslationText('XXSPECIFY_MMI_MC_DROPDN_STEAM',props))
            props.steamPressureTemp(data ? data.fluid_source:'Superheated: Pressure and Temperature must be specified')
           }else{
            await setFluidStateDropdownDisplayValue(fluid?fluid:'LIQUID') 
           }
           
           //await setFluidStateDropdownDisplayValue(fluid?fluid:'LIQUID')
           
           if(source !==''){
           await props.fluidSource(source);
          }
          //check the fluid source ui value
           if(source ==='Database'){
            await setFluidSourceDisplay(CustomTranslationText('XXSPECIFY_MMI_SR_LBL_DAT_BAS',props)) 
           }else if(source === 'Custom'){
            await setFluidSourceDisplay(CustomTranslationText('XXSPECIFY_MMI_SR_LBL_CUSTOM',props))
           }else{
            if(source !==''){
              await setFluidSourceDisplay(source)
            }
            
           }
  
           await props.pickFluidsFromDB(source==='Natural Gas'?'WATER':data.fluid !== null?data.fluid:'WATER');
           if(source !== 'Custom'&& data.fluid !== null){
            try {
              let displayValue= props.pick_fluids_from_db_units.filter((option) => 
              data.fluid===option.value
            )
            
           await setSelectedFluidsDBDisplay(displayValue[0].label)
            } catch (error) {
              await setSelectedFluidsDBDisplay(data.fluid !== null&&source==='Natural Gas'?'WATER':data.fluid)
              await  props.pickGasFromDB(data.fluid)
            }
            
           }else{
            props.customFluidName(data.fluid); 
           }
            
           await props.conductivityDropdown(conductivity);
           await props.selectBasicPage('processVariables');
          }
          fetchData();
     /**cool Props for resizing */
      let key = getKey(data.fluid, props.pick_fluids_from_db_units)
      let test = resultsGetFluidsDBValue[key ? key : 0];
      let coolprops_flag = props.pick_fluids_from_db_units?.filter((option) =>
        test === option.value
      )
      props.pickFluidsCoolpropsFlag(coolprops_flag?.[0]?.coolprops_flag)
      props.coolPropsVisc(coolprops_flag[0]?.coolprops_flag)

    }
  }, [props.get_sizing_data]);

  useEffect(() => {
    let resultsArray = [];
    if (props.conductivity_units) {
      props.conductivity_units.map((results) => {
        resultsArray.push(results.label);
      });
      setResultsConductivity(resultsArray);
    }
  }, [props.conductivity_units]);

//here the useEffect to revert changes in the ui
useEffect(() => {
  if (Object.keys(props.reset_operating_conditions).length > 0) {
    if(props.reset_operating_conditions.Fields.fluid_source==='Custom'){
      setFluidSourceDisplay(CustomTranslationText('XXSPECIFY_MMI_SR_LBL_CUSTOM',props))
    }else if(props.reset_operating_conditions.Fields.fluid_source==='Database'){
      setFluidSourceDisplay(CustomTranslationText('XXSPECIFY_MMI_SR_LBL_DAT_BAS',props))
    }else  if(props.reset_operating_conditions.Fields.fluid_source==='Natural Gas'){
      setFluidSourceDisplay(CustomTranslationText('XXSPECIFY_MMI_SR_LBL_NATGAS',props))
    }

    if(!props.reset_operating_conditions.Fields.natural_gas_compose){
      props.naturalGasCompose(false)
      setComposeGas(false);
    }else{
      props.naturalGasCompose(true);
      setComposeGas(true);
    }

    if(props.reset_operating_conditions.Fields.fluid_state ==='LIQUID' || props.reset_operating_conditions.Fields.fluid_state === 'Liquid'){
      setFluidStateDropdownDisplayValue(CustomTranslationText('XXSPECIFY_MMI_MC_DROPDN_LIQ',props))
    }else if(props.reset_operating_conditions.Fields.fluid_state ==='GAS' || props.reset_operating_conditions.Fields.fluid_state ==='Gas'){
      setFluidStateDropdownDisplayValue(CustomTranslationText('XXSPECIFY_MMI_MC_DROPDN_GAS',props))
    }else if(props.reset_operating_conditions.Fields.fluid_state ==='SLURRY' || props.reset_operating_conditions.Fields.fluid_state ==='Slurry'){
      setFluidStateDropdownDisplayValue(CustomTranslationText('XXSPECIFY_MMI_MC_DROPDN_SLURRY',props))
    }else if(props.reset_operating_conditions.Fields.fluid_state ==='STEAM' || props.reset_operating_conditions.Fields.fluid_state ==='Steam'){
      setFluidStateDropdownDisplayValue(CustomTranslationText('XXSPECIFY_MMI_MC_DROPDN_STEAM',props))
    }

    if(resultsGetFluidsDBValue.length > 0 && props.reset_operating_conditions.Fields.pick_fluids_from_db !== '') {
      let fluidIndex = resultsGetFluidsDBValue.indexOf(props.reset_operating_conditions.Fields.pick_fluids_from_db)
      let displayFluid = resultsGetFluidsDBDisplay[fluidIndex !== -1 ? fluidIndex : 0]
      setSelectedFluidsDBDisplay(displayFluid)
    }
  }
}, [props.reset_operating_conditions])




  useEffect(() => {
    let resultsArray = [];
    if (props.fluid_service_units) {
      props.fluid_service_units.map((results) => {
        resultsArray.push(results.label);
      });
      setResultsFluidService(resultsArray.sort());
    }
  }, [props.fluid_service_units]);

  const handleFluidStateChange = (key) => {
   props.naturalGasCompose(false);

    setShowGasButton(false);
    if (!key) {
      key = 0;
    }
    setFluidStateDropdownDisplayValue(fluidStateDisplay[key])
    props.fluidState(fluidStateValueArray[key]);
    
  };
  const handleFluidSourceChange = (key) => {
    props.naturalGasCompose(false);
    setShowGasButton(false);
    if (!key) {
      key = 0;
    }
    if (fluidSourceDisplayArray[key] === CustomTranslationText('XXSPECIFY_MMI_SR_LBL_NATGAS',props)) {
      setShowGasButton(true);
    }
    setFluidSourceDisplay(fluidSourceDisplayArray[key])
    return props.fluidSource(fluidSourceValueArray[key]);
  };
  const handlePickFluidsFromDBChange = (key) =>{
    let test = resultsGetFluidsDBValue[key ? key : 0];
    let coolprops_flag= props.pick_fluids_from_db_units.filter((option) => 
      test===option.value
    )
    props.pickFluidsFromDB(resultsGetFluidsDBValue[key ? key : 0]);
    props.pickFluidsCoolpropsFlag(coolprops_flag[0].coolprops_flag)
    props.coolPropsVisc(coolprops_flag[0].coolprops_flag)
    setSelectedFluidsDBDisplay(resultsGetFluidsDBDisplay[key? key: 0])
  }

  const handlePickGasFromDBChange = (key) =>
    props.pickGasFromDB(resultsGetGasDBValue[key ? key : 0]);
  const handleConductivityChange = (key) =>
    props.conductivityDropdown(resultsConductivity[key ? key : 0]);
  const handleFluidServiceChange = (key) =>
    props.fluidService(resultsFluidService[key ? key : 0]);
  const handleCustomFluidNameChange = (event) =>{
    props.customFluidName(event.target.value);
   
   }
   
//set values of fluid selection inside redux to avoid those values to be losed

   useEffect(() => {
    let obj = props.fluid_selection_section
    if(!props.fluid_selection_flag){
        obj.fluid_state= props.fluid_state;
        obj.fluid_source=props.fluid_source;
        obj.pick_fluids_from_db= props.pick_fluids_from_db;
        obj.fluid_state_display=props.fluid_state;
        obj.fluid_source_display= props.fluid_source;
        obj.pick_fluids_from_db_display=props.pick_fluids_from_db;
        obj.natural_gas_compose=props.natural_gas_compose;
        obj.natural_gas_composition_name=props.natural_gas_composition_name;
        obj.composition_method=props.composition_method
      props.fluidSelectionSection(obj)
    }
    radioInputButtons('Superheated: Pressure and Temperature must be specified')
    props.steamPressureTemp('Superheated: Pressure and Temperature must be specified');

   },[props.clear_all_field,props.fluid_selection_flag])



    
  const FluidDropdown = (title, id, text, dropdownArray, eventHandler) => {
    const col = props.fluid_source === CustomTranslationText('XXSPECIFY_MMI_SR_LBL_NATGAS',props) ? 6 : 12;
    return (
      <>
        <Row>
          <Col md={col} sm={col} xs={11}>
            <CustomDropdown
              clear={props.clear_all_field}
              title={title}
              id={id}
              text={text}
              dropdownArray={fluidStateDisplay}
              eventHandler={eventHandler}
              active={text}
              width={275}
            />
          </Col>
          <Col md={col} sm={col} xs={col}>
            {props.fluid_source ===CustomTranslationText('XXSPECIFY_MMI_SR_LBL_NATGAS',props) &&
            title === CustomTranslationText('	XXSPECIFY_MMI_MC_LBL_FLUIDSURC',props) &&
            showGasButton
              ? composeGasButton()
              : ''}
          </Col>
        </Row>
      </>
    );
  };

  const FluidSourceDropdown = (title, id, dropdownArray, eventHandler) => {
    // TODO: is this needed for the button placement?
    const col = props.fluid_source.toLowerCase() === 'natural gas' ? 6 : 12;
   
    return (
      <>
        <Row>
          <Col md={col} sm={col} xs={11}>
            {fluidSourceDisplayArray.length > 0 ? (
              <CustomDropdown
                clear={props.clear_all_field}
                title={title}
                id={id}
                text={fluidSourceDisplay}
                dropdownArray={fluidSourceDisplayArray}
                eventHandler={eventHandler}
                active={
                  props.fluid_state.toLowerCase() === 'slurry' ? 'custom' : props.fluid_source
                }
                width={275}
              />
            ) : (
              <span />
              
            )}
          </Col>
          <Col md={col} sm={col}>
            {props.fluid_source.toLowerCase() === 'natural gas' && showGasButton
              ? composeGasButton()
              : ''}
          </Col>
        </Row>
      </>
    );
  };

  const Dropdowns = (
    title,
    id,
    text,
    dropdownArray,
    eventHandler,
    disabled,
    required,
    error
  ) => {
    const col = props.fluid_source.toLowerCase() === 'natural gas' ? 6 : 12;

    return (
      <>
        <Row>
          <Col md={col} sm={col} xs={11}>
            <CustomDropdown
              clear={props.clear_all_field}
              title={title}
              id={id}
              text={text}
              dropdownArray={dropdownArray}
              eventHandler={eventHandler}
              disabled={disabled}
              borderRequired={required}
              borderError={error}
              active={text}
              width={275}
            />
          </Col>
          <Col md={col} sm={col} xs={11}>
            {props.fluid_source.toLowerCase() === 'natural gas' &&
            title === CustomTranslationText('	XXSPECIFY_MMI_MC_LBL_FLUIDSURC',props) &&
            showGasButton
              ? composeGasButton()
              : ''}
          </Col>
        </Row>
      </>
    );
  };

  const handleComposeGasClick = (event) => {
    if (event.find((element) => element === 'composeClicked')) {
      setComposeGas(true);
      props.naturalGasCompose(true)
    } else {
      props.pickGasFromDB('Gulf Coast Natural Gas')
      setComposeGas(false);
      props.naturalGasCompose(false)
    }
  };

  const buttonComposeGasStyle = {
    width: '140px',
    borderRadius: 0,
    letterSpacing: '.5px',
    fontSize: '14px',
    height: '50px',
    marginTop: '33px',
    backgroundColor: '#00aa7e',
    border: 'none',
  };

  const composeGasButton = () => {
    return (
      <Row>
        <Col md={6} sm={6} xs={12}>
          <CustomButton
            classNames={`btn btn-compose-natual-gas ${
              props.natural_gas_compose ? 'back-button-text' : ''
            }`}
            label={
              props.natural_gas_compose  ? (
                'BACK'
              ) : (
                <CustomTranslation data='XXSPECIFY_MMI_COM_NAT_GAS' />
              )
            }
            size='lg'
            arrow={null}
            disabled={false}
            styles={buttonComposeGasStyle}
            toggle={true}
            type='checkbox'
            onChange={handleComposeGasClick}
          />
        </Col>
      </Row>
    );
  };

  // TODO: stub out of dropdown with search
  const dropdownTypeahead = (
    name,
    id,
    text,
    dropdownArray,
    eventHandler,
    disabled,
    required,
    error
  ) => {
    return (
      <Col sm={12}>
        <CustomDropdownTypehead2
          name={name}
          id={id}
          text={text}
          dropdownArray={dropdownArray}
          eventHandler={eventHandler}
          disabled={disabled}
          borderRequired={required}
          borderError={error}
        />
      </Col>
    );
  };

  const customFluidInputField = (
    name,
    id,
    labelClassName,
    fieldClassName,
    size,
    disabled,
    borderRequired,
  ) => {
    return (
      <>
        <Row>
          <Col md={12} sm={10} xs={11}>
            <CustomInputField
              name={name}
              size={size}
              disabled={disabled}
              borderRequired={borderRequired}
              borderError={!props.custom_fluid_name && props.custom_border_error === true} // TBD - border condition
              id={id}
              labelClassName={labelClassName}
              fieldClassName={fieldClassName}
              
              onChange={(e)=>handleCustomFluidNameChange(e)}
              value={props.custom_fluid_name}
              errorText={CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG56',props)}
              isError={showError}
            />
          </Col>
        </Row>
      </>
    );
  };

  const selectionOptions = () => {
    if (
      (props.fluid_state.toLowerCase() === 'liquid' && props.fluid_source.toLowerCase() !== 'custom') ||
       (props.fluid_state.toLowerCase() === 'gas' &&  (props.fluid_source.toLowerCase() === 'database' || !props.fluid_source)) || 
       (props.fluid_state.toLowerCase() === 'steam' && props.select_technology.toLowerCase() !== 'vortex')
    ) {
      return Dropdowns(
        <CustomTranslation data='XXSPECIFY_MMI_MC_RDIO_LIQDB' />,
        'pick-fluids-from-db-dropdown',
        selectedFluidsDBDisplay,
        resultsGetFluidsDBDisplay,
        handlePickFluidsFromDBChange,
        ( props.pick_fluids_from_db_units? props.pick_fluids_from_db_units.length > 0 ? false : true:true),
        true
      );
    } else if (
      ((props.fluid_state.toLowerCase() === 'liquid' || props.fluid_state.toLowerCase() === 'gas') &&
        props.fluid_source.toLowerCase() === 'custom') ||
      props.fluid_state.toLowerCase() === 'slurry'
    ) {
      return customFluidInputField(
        <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_ERRMSG56' />,
        'custom-fluid-name-inputfield',
        'custom-fluid-label',
        'custom-fluid-fieldname',
        'lg',
        false,
        true
      );
    } else if (
      props.fluid_state.toLowerCase() === 'gas' &&
      props.fluid_source.toLowerCase() === 'natural gas'
    ) {
      return Dropdowns(
        <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_ERRMSG61' />,
        'pick-gas-from-db-dropdown',
        props.pick_gas_from_db,
        resultsGetGasDBValue?resultsGetGasDBValue:[],
        handlePickGasFromDBChange,
        false,
        true
      );
    } else {
      return '';
    }
  };

  const fluidSourceDropown = FluidSourceDropdown(
    <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_FLUIDSURC' />,
    'fluid-source-dropdown',
    fluidSourceDisplayArray,
    handleFluidSourceChange
  );

  const onChange = (event) => {
    const name =
      event.target.id === 'superheated'
        ? 'Superheated: Pressure and Temperature must be specified'
        : event.target.id === 'temp'
        ? 'Based on Saturated Temperature'
        : 'Based on Saturated Pressure';
    props.steamPressureTemp(name);
  };

  const radioInputButtons = (name) => {
    const checked = props.steam_pressure_temp === name;
    const id =
      name === 'Superheated: Pressure and Temperature must be specified'
        ? 'superheated'
        : name === 'Based on Saturated Temperature'
        ? 'temp'
        : 'pressure';
    let name_Id =
      name === 'Superheated: Pressure and Temperature must be specified'
        ? 'XXSPECIFY_MMI_MC_RDIO_SUPERHEA'
        : (name === 'Based on Saturated Temperature'
        ? 'XXSPECIFY_MMI_MC_RDIO_STEMP'
        : 'XXSPECIFY_MMI_MC_RDIO_SPRES');
    return (
      <>
        <Col sm={12}>
          <CustomRadiobutton
            type='radio'
            name='steam'
            id={id}
            onChange={(e) => onChange(e)}
            checked={checked}
            disabled={false}
            className='custom-radiobutton'
            text={<CustomTranslation data={name_Id} />}
          />
        </Col>
      </>
    );
  };
  
  //Set those values that were store in redux on state and UI 

useEffect(() => {
    if(props.fluid_selection_flag){
      if(fluidStateValueArray.length > 0 && fluidSourceValueArray.length > 0 && resultsGetFluidsDBValue.length > 0){
        let fluidStateIndex = fluidStateValueArray.indexOf(props.fluid_selection_section.fluid_state)
        let displayState = fluidStateDisplay[fluidStateIndex !== -1 ? fluidStateIndex : 0]  
        let fluidSourceIndex = fluidSourceValueArrayAux.indexOf(props.fluid_selection_section.fluid_source)
        let displaySource = fluidSourceDisplayArrayAux[fluidSourceIndex !== -1 ? fluidSourceIndex : 0]
        let fluidIndex = resultsGetFluidsDBValue.indexOf(props.fluid_selection_section.pick_fluids_from_db)
        let displayFluid = resultsGetFluidsDBDisplay[fluidIndex !== -1 ? fluidIndex : 0]
        setFluidStateDropdownDisplayValue(displayState)
        setFluidSourceDisplay(displaySource)
        setSelectedFluidsDBDisplay(displayFluid)
      } 
      props.fluidState(props.fluid_selection_section.fluid_state)
      props.fluidSource(props.fluid_selection_section.fluid_source)
      props.pickFluidsFromDB(props.fluid_selection_section.pick_fluids_from_db)
      props.naturalGasCompose(props.fluid_selection_section.natural_gas_compose)
      props.naturalGasCompositionName(props.fluid_selection_section.natural_gas_composition_name);
      props.compositionMethod(props.fluid_selection_section.composition_method);
        
      if(props.fluid_selection_section.fluid_source ==='Natural Gas'){
        setShowGasButton(true)
      }
      setTimeout(() => {
        props.fluidSelectionFlag(false)  
      }, 5000);
      setTimeout(() => {
        props.sizingResultsClicked(false)
      }, 7000);
    }
}, [props.fluid_selection_flag])

  return (
    <>
      <div className='fluid-selection'>
        <div className='title'>
          <CustomTranslation data='XXSPECIFY_MMI_MC_PANEL4_FS' />
        </div>
        <Container className='selection-container'>
          <Row>
            <Col md={12} sm={12} xs={12}>
              {FluidDropdown(
                <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_FLUID' />,
                'fluid-state-dropdown',
                fluidStateDropdownDisplayValue,
                fluidStateDisplay,
                handleFluidStateChange
              )}
              {props.fluid_state.toLowerCase() !== 'steam' ?
              (fluidSourceDropown) : (<span className='test' />)}
              {props.fluid_state.toLowerCase() === 'steam' &&
              props.select_technology.toLowerCase() === 'vortex' ? (
                <ul className='steam-list'>
                  <li>{radioInputButtons('Superheated: Pressure and Temperature must be specified')}</li>
                  <li>{radioInputButtons('Based on Saturated Temperature')}</li>
                  <li>{radioInputButtons('Based on Saturated Pressure')}</li>
                </ul>
              ) : (
                <span />
              )}
              {!props.natural_gas_compose  ? selectionOptions() : <span />}
              {props.select_technology === 'Magnetic' ? (
                <>
                  {Dropdowns(
                    <CustomTranslation data='XXSPECIFY_MMI_MC_HEAD_CON' />,
                    'conductivity-dropdown',
                    props.conductivity,
                    resultsConductivity,
                    handleConductivityChange
                  )}
                  {Dropdowns(
                    <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_FSERVICE' />,
                    'fluid-service-dropdown',
                    props.fluid_service,
                    resultsFluidService,
                    handleFluidServiceChange
                  )}
                </>
              ) : (
                <span />
              )}
            </Col>
          </Row>
          {props.natural_gas_compose  ? <GasComposition /> : <span />}
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  fluid_state: state.FluidSelection.fluid_state,
  natural_gas_compose: state.FluidSelection.natural_gas_compose,
  fluid_source_array: state.FluidSelection.fluid_source_array,
  fluid_source: state.FluidSelection.fluid_source,
  revert_flag : state.FluidSelection.revert_flag,
  pick_fluids_from_db: state.FluidSelection.pick_fluids_from_db,
  pick_gas_from_db: state.FluidSelection.pick_gas_from_db,
  select_technology: state.MeasurementType.select_technology,
  density: state.MeasurementType.density,
  flow: state.MeasurementType.flow,
  conductivity: state.FluidSelection.conductivity,
  fluid_service: state.FluidSelection.fluid_service,
  custom_fluid_name: state.FluidSelection.custom_fluid_name,
  steam_pressure_temp: state.FluidSelection.steam_pressure_temp,
  fluid_state_units: state.FluidSelection.fluid_state_units,
  fluid_source_units: state.FluidSelection.fluid_source_units,
  pick_fluids_from_db_units: state.FluidSelection.pick_fluids_from_db_units,
  conductivity_units: state.FluidSelection.conductivity_units,
  fluid_service_units: state.FluidSelection.fluid_service_units,
  request_sizing_results: state.SizingResults.request_sizing_results,
  clear_all_field: state.ClearAllField.clear_all_field,
  get_sizing_data: state.MySizings.get_sizing_data,
  translation_data: state.TranslationData.translation_data,
  custom_border_error: state.FluidSelection.custom_border_error,
  fluid_selection_flag: state.FluidSelection.fluid_selection_flag,
  fluid_selection_section: state.FluidSelection.fluid_selection_section,
  reset_operating_conditions: state.ProcessVariables.reset_operating_conditions,
  natural_gas_composition_name:state.FluidSelection.natural_gas_composition_name,
composition_method: state.FluidSelection.composition_method,
});

export default connect(mapStateToProps, {
  fluidSourceArray,
  fluidStateArray,
  fluidState,
  fluidSource,
  pickFluidsFromDB,
  pickGasFromDB,
  conductivityDropdown,
  fluidService,
  customFluidName,
  steamPressureTemp,
  requestSizingResults,
  databaseGassesList,
  selectBasicPage,
  pickFluidsCoolpropsFlag,
  fluidServiceKey,
  customBorderError,  
  revertFlag,
  coolPropsVisc,
  fluidSelectionSection,
  fluidSelectionFlag,
  naturalGasCompose,
  naturalGasCompositionName,
  compositionMethod,
  sizingResultsClicked,
  relativeDensity,
  molePercentCO2,
  molePercentN2,
  heatingValue,
  molePercentH2,
  molePercentCO,
})(FluidSelection);
